import { Paragraph, Subheading } from 'components/Typography'
import { UserPermissions, hasAllPermissions, hasAnyPermission } from 'lib/authHelpers'
import React, { ReactNode } from 'react'
import { ScrollView } from 'react-native'
import { user } from 'store/authSlice'
import { useAppSelector } from 'store/hooks'
import { Sizing } from 'styles'

type AdminGuardProps = {
  requiredPermissions: UserPermissions[]
  requireAllPermissions?: boolean
  children: ReactNode
}

export const AdminGuard = (props: AdminGuardProps) => {
  const { children, requiredPermissions, requireAllPermissions } = props

  //Current user for determining admin
  const currentUser = useAppSelector(user)

  const isAuthorized = requireAllPermissions
    ? hasAllPermissions(currentUser, requiredPermissions)
    : hasAnyPermission(currentUser, requiredPermissions)

  return (
    <>
      {
        !isAuthorized ?
          <>
            <ScrollView>
              <Subheading style={{
                paddingVertical: Sizing.x30,
                paddingHorizontal: Sizing.x30,
              }}>
                {'You do not have access to this area'}
              </Subheading>
              <Paragraph>
                {'If you believe you should have access, lease speak to the system administrator regarding your role and permissions'}
              </Paragraph>
            </ScrollView>
          </>
          :
          <>
            {children}
          </>
      }
    </>
  )
}
