import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE } from 'lib/constants'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_21_SpouseDateOfBirth = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  //Setup form
  const formObj = useForm<{ birthDate: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      birthDate: workingCalculatorRequestData?.spouse?.birthDate || '',
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingCalculatorRequest({
      spouse: {
        ...workingCalculatorRequestData.spouse,
        birthDate: attributes.birthDate,
      }
    }))
    ampli.calculatorUsage({
      processStep: 'spouse_dob',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })
    navigation.navigate(nextScreen)
  }

  const isWeb = platformIsWeb()

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, when were they born?`}
      subHeading={'Your retirement planning timeline may change if your partner is older or younger'}
    >
    <ManagedDateInput
      name={'birthDate'}
      autoFocus={true}
      formObj={formObj}
      submitHandler={handleSubmit(onSubmit)}
      blurOnSubmit={false}
      required={true}
      mustBeInPast={true}
      notOlderThanYears={MAX_CLIENT_AGE}
      notYoungerThanYears={MIN_CLIENT_AGE}
      tryParseTwoDigitYear={true}
      showCurrentAgeMessage={true}
    />
    </ProcessScreen>
  )
}
