import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { FooterButton } from 'components/Utility/FooterButton'
import { NamedInformationButton } from 'components/Utility/InformationButton'
import { hexColorWithOpactity } from 'lib/colorHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { mainAppNavigate } from 'lib/RootNavigation'
import { getSuggestedStep, OnboardingPhase, OnboardingStateStep, OnboardingStateStepIdentifier, OnboardingStateStepStatus, useGuidanceContext } from 'providers/guidance.context'
import React, { useState } from 'react'
import { Image, Pressable, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { ProgressBar, ThemeProvider } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()

export const OnboardingStatusScreen = ({ route, navigation }) => {
  const { onboardingPhase }: { onboardingPhase: OnboardingPhase } = route?.params || {}

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const onboardingState = getOnboardingStateByPhase(onboardingPhase) || {}
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = onboardingState || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const { colors: themeColors } = Paper.darkThemeOnLightGrey

  const doneSteps = onboardingState?.completedSteps + onboardingState?.notRequiredSteps
  const totalSteps = onboardingState?.totalSteps
  const progress = totalSteps ? (doneSteps) / totalSteps : 0

  const noRemainingSteps = onboardingState?.remainingSteps === 0 || !suggestedStep

  type StepRowProps = {
    step: OnboardingStateStep
    isSuggested: boolean
    isLast: boolean
  }
  const StepRow = (props: StepRowProps) => {
    const { step, isLast, isSuggested } = props

    const [dialogVisible, setDialogVisible] = useState(false)

    const onPress =
      step.status === OnboardingStateStepStatus.NOT_STARTED ? step.startFunction
        : ![OnboardingStateStepStatus.COMPLETE, OnboardingStateStepStatus.DECLINED].includes(step.status) ? () => setDialogVisible(true)
          : undefined

    const disabledStatuses = [
      OnboardingStateStepStatus.DECLINED,
      OnboardingStateStepStatus.FAILED,
      OnboardingStateStepStatus.PENDING,
      OnboardingStateStepStatus.UNAVAILABLE
    ]

    const disabled = disabledStatuses.includes(step.status)

    return (
      <>
        <Pressable onPress={onPress}>
          <View style={localStyles.tableRowStyle}>
            <View style={[localStyles.itemContainer,
            !isLast ? [
              localStyles.itemContainerBorder,
              {
                borderBottomColor: hexColorWithOpactity(themeColors.themeType === 'dark' ? Colors.neutral.black : Colors.neutral.white, 0.5)
              }
            ] : {}
            ]}>
              <View style={localStyles.iconContainer}>
                {
                  step.status === OnboardingStateStepStatus.COMPLETE ? <MaterialCommunityIcons name="checkbox-marked-outline" size={Sizing.x30} color={Colors.brand.green2} />
                    : step.status === OnboardingStateStepStatus.DECLINED ? <MaterialCommunityIcons name="checkbox-marked-outline" size={Sizing.x30} color={Colors.brand.grey3} />
                      : step.status === OnboardingStateStepStatus.NOT_STARTED ? <MaterialCommunityIcons name="checkbox-blank-outline" size={Sizing.x30} color={Colors.brand.purple2} />
                        : step.status === OnboardingStateStepStatus.PENDING ? <MaterialCommunityIcons name="timer-outline" size={Sizing.x30} color={Colors.neutral.s400} />
                          : step.status === OnboardingStateStepStatus.FAILED ? <MaterialCommunityIcons name="alert-box-outline" size={Sizing.x30} color={Colors.brand.red2} />
                            : step.status === OnboardingStateStepStatus.UNAVAILABLE ? <MaterialCommunityIcons name="checkbox-blank-outline" size={Sizing.x30} color={Colors.brand.grey3} />
                              : step.status === OnboardingStateStepStatus.NOT_REQUIRED ? <MaterialCommunityIcons name="checkbox-blank-off-outline" size={Sizing.x30} color={Colors.brand.grey3} />
                                : <></>
                }
              </View>
              <View style={localStyles.labelContainer}>
                <Paragraph numberOfLines={1} style={[
                  localStyles.stepTitle,
                  disabled ? { color: themeColors.disabled } : undefined,
                  step.status === OnboardingStateStepStatus.NOT_STARTED ? { ...Typography.fontWeight.bold } : undefined,
                  isSuggested ? { color: themeColors.primary } : undefined
                ]}>
                  {step.title}
                </Paragraph>
              </View>
              <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Paragraph numberOfLines={1} style={[
                  localStyles.stepInfo,
                  disabled ? { color: themeColors.disabled } : undefined,
                  step.status === OnboardingStateStepStatus.NOT_STARTED ? { ...Typography.fontWeight.bold } : undefined,
                  isSuggested ? { color: themeColors.primary } : undefined
                ]}>
                  {step.info}
                </Paragraph>
              </View>
            </View>
          </View>
        </Pressable>
        <ConfirmationDialog
          visible={dialogVisible}
          title={
            step.status === OnboardingStateStepStatus.UNAVAILABLE ? 'Task not yet available'
              : step.status === OnboardingStateStepStatus.NOT_REQUIRED ? `Not required`
                : step.status === OnboardingStateStepStatus.PENDING ? `Pending`
                  : `We're sorry...`
          }
          content={
            step.status === OnboardingStateStepStatus.UNAVAILABLE ?
              'Previous tasks need to be completed to unlock this task.'
              : step.status === OnboardingStateStepStatus.NOT_REQUIRED
                ? `Based on previous tasks, this one is no longer required.`
                : step.status === OnboardingStateStepStatus.PENDING ?
                  step.id === OnboardingStateStepIdentifier.IDENTITY_VERIFICATION
                    ? `We'll be in touch about verifying your identity.`
                    : `This task is still pending.`
                  : step.status === OnboardingStateStepStatus.FAILED ?
                    step.id === OnboardingStateStepIdentifier.IDENTITY_VERIFICATION ? `We were unable to verify your identity.`
                      : `This task failed = please contact support.`
                    : 'Please contact support.'
          }
          onConfirm={() => setDialogVisible(false)}
        />
      </>
    )
  }

  return (
    <>
      {
        onboardingState ?
          <>
            <StatusBar barStyle={'dark-content'} />
            <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
              <SafeAreaView style={[
                backgroundStyles.pageContainer,
                backgroundStyles.heroBackground,
              ]}>
                <View style={[
                  layoutStyles.mainContentContainer,
                  localStyles.processIntroScreenContainer,
                ]}>
                  <ScrollView
                    contentContainerStyle={layoutStyles.scrollContainerContent}
                    keyboardShouldPersistTaps='handled'
                    showsVerticalScrollIndicator={true}
                  >
                    <View style={{
                      ...Flex.column.start,
                      paddingHorizontal: Sizing.x30,
                    }}>
                      <View>
                        {
                          onboardingState?.complete ?
                            <AppIllustration
                              filename={'thanks.png'}
                              style={{
                                width: '100%',
                                height: Sizing.x120,
                                resizeMode: 'contain',
                                alignSelf: 'center',
                              }}
                            />
                          : onboardingState?.imageSource ?
                            <Image
                              source={onboardingState?.imageSource}
                              style={{
                                width: '100%',
                                height: Sizing.x120,
                                resizeMode: 'contain',
                                alignSelf: 'center',
                              }}
                            />
                          :
                            <AppIllustration
                              filename={onboardingState?.illustrationFilename || 'info.png'}
                              style={{
                                width: '100%',
                                height: Sizing.x120,
                                resizeMode: 'contain',
                                alignSelf: 'center',
                              }}
                            />
                        }
                      </View>
                      <View>
                        <View style={localStyles.headlineContainer}>
                          <Headline adjustsFontSizeToFit numberOfLines={2}>{onboardingState?.title}</Headline>
                          <Subheading adjustsFontSizeToFit numberOfLines={3}>{onboardingState?.subTitle}</Subheading>
                          {
                            onboardingState?.titleInfoName ?
                              <NamedInformationButton name={onboardingState?.titleInfoName} />
                              : <></>
                          }
                        </View>
                        <View style={{
                          width: '70%',
                          alignSelf: 'center',
                        }}>
                          <ProgressBar
                            color={themeColors.progressBar}
                            style={[
                              localStyles.progressBar,
                              {
                                backgroundColor: themeColors.progressBarBackground,
                              }
                            ]}
                            progress={progress}
                          />
                          <Subheading>{`${doneSteps} / ${totalSteps}`}</Subheading>
                        </View>
                        <View style={{
                          paddingTop: Sizing.x0
                        }}>
                          {
                            onboardingState?.steps
                              ? onboardingState.steps.map((step, idx) => {
                                return (
                                  <StepRow
                                    key={idx}
                                    step={step}
                                    isLast={idx === onboardingState?.steps.length - 1}
                                    isSuggested={step.id === onboardingState?.suggestedStepId}
                                  />
                                )
                              })
                              : <></>
                          }
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                  {
                    noRemainingSteps ? <></> :
                      <FooterButton
                        mode='text'
                        onPress={() => mainAppNavigate(MainAppNavScreen.DASHBOARD)}
                      >
                        {'Back to Dashboard'}
                      </FooterButton>
                  }
                  {onboardingState?.complete || noRemainingSteps
                    ? <></>
                    : <Text style={{ color: Colors.neutral.s300 }}>{'Suggested Next Task:'}</Text>
                  }
                  <FooterButton
                    onPress={
                      onboardingState?.complete ? onboardingState?.completeAction
                        : noRemainingSteps ? () => mainAppNavigate(MainAppNavScreen.DASHBOARD)
                          : suggestedStep?.startFunction}
                  >
                    {
                      onboardingState?.complete ? onboardingState?.completeButtonTitle
                        : noRemainingSteps ? 'Back to Dashboard'
                          : suggestedStep?.title}
                  </FooterButton>
                </View>
              </SafeAreaView>
            </ThemeProvider>
          </>
          : <></>
        }
    </>
  )
}

const localStyles = StyleSheet.create({
  processIntroScreenContainer: {
    paddingTop: Sizing.x30,
  },
  headlineContainer: {
    paddingBottom: Sizing.x10,
  },
  subHeadingContainer: {
    paddingTop: Sizing.x20,
  },
  tableRowStyle: {},
  labelContainer: {
    ...Flex.override.fill,
  },
  iconContainer: {
    paddingHorizontal: Sizing.x10,
    alignSelf: 'center',
  },
  itemContainer: {
    paddingVertical: Sizing.x10,
    ...Flex.row.start,
  },
  itemContainerBorder: {
    borderBottomWidth: 0.5,
  },
  stepTitle: {
    ...Typography.fontSize.x15,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'left'
  },
  stepInfo: {
    ...Typography.fontSize.x10,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'right'
  },
  progressBar: {
    height: Sizing.x10,
    borderRadius: Sizing.x60,
  },
})