  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { Paragraph } from 'components/Typography'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { View } from 'react-native'
import { Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { useAppTheme } from 'styles/paper'
import { Button } from './Button'

type PensionProviderContactDetailSetProps = {
  formObj: UseFormReturn<any>
  name: string
}

const isWeb = platformIsWeb()

export const PensionProviderContactDetailSet = (props: PensionProviderContactDetailSetProps) => {
  const { formObj, name } = props
  const { control } = formObj
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name,
  })

  const { colors: themeColors } = useAppTheme()

  const fieldCount = fields.length

  return (
    <View style={layoutStyles.inputContainer}>
      <Paragraph>{`Add one or more sets of contact information to help users get in contact with other pension providers.`}</Paragraph>
      {
        fields.map((field, index) => {
          return (
            <View
              key={field.id}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <View style={{ flex: 1 }}>
                  <ManagedTextInput
                    name={`${name}.${index}.data.telephone`}
                    formObj={formObj}
                    label={'Telephone'}
                    placeholder={'Customer telephone number'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      required: false,
                      minLength: 5,
                      maxLength: 20,
                  }}/>
                  <ManagedTextInput
                    name={`${name}.${index}.data.email`}
                    formObj={formObj}
                    label={'Email'}
                    placeholder={'Customer email address'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email address"
                      },
                      required: false,
                      minLength: 2,
                      maxLength: 40,
                  }}/>
                  <ManagedTextInput
                    name={`${name}.${index}.data.contactUrl`}
                    formObj={formObj}
                    label={'Contact URL'}
                    placeholder={'Customer contact web address'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      pattern: {
                        value: /^(http|https):\/\/[^ "]+$/,
                        message: "Invalid URL (include http/https)"
                      },
                      required: false,
                  }}/>
                  <ManagedTextInput
                    name={`${name}.${index}.data.portalUrl`}
                    formObj={formObj}
                    label={'Portal URL'}
                    placeholder={'Customer online portal web address'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      pattern: {
                        value: /^(http|https):\/\/[^ "]+$/,
                        message: "Invalid URL (include http/https)"
                      },
                      required: false,
                  }}/>
                  <ManagedTextInput
                    name={`${name}.${index}.data.notes`}
                    formObj={formObj}
                    label={'Notes'}
                    placeholder={'Customer notes for this contact information'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      required: false,
                      minLength: 10,
                      maxLength: 200,
                  }}/>
                </View>
                <View style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: isWeb ? Sizing.x60 : undefined,
                }}>
                  <MaterialCommunityIcons name='delete-outline' size={Sizing.x20} color={themeColors.primary} onPress={() => remove(index) }/>
                </View>
              </View>
              { fieldCount === 1 || index === fieldCount - 1 ? <></> :
                <View style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}>
                  <MaterialCommunityIcons name='arrow-up-thin' size={Sizing.x20} color={themeColors.primary} onPress={() =>swap(index, index + 1)}/>
                  <MaterialCommunityIcons name='arrow-down-thin' size={Sizing.x20} color={themeColors.primary} onPress={() =>swap(index, index + 1)}/>
                </View>
              }
            </View>
          )
        })
      }
      <Button
        mode={'text'}
        onPress={() => append({ data: {}})}
      >{fieldCount === 0 ? `Add contact set`: `Add another set`}</Button>
    </View>
  )
}
