import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetUserFeaturesQuery } from 'store/apiSlice'
import { AccountDto } from 'store/dto/account.dto'
import { FeatureCode } from 'store/dto/feature.dto'
import { useAppDispatch } from 'store/hooks'
import { InvestmentTypeChoice, updateWorkingAccount } from 'store/tempDataSlice'
import { InvestmentTypeModalContent } from '../Components/InvestmentTypeModalContent'

export const InvestmentChoice_11_InvestmentTypeUnfollowChoice = ({ route, navigation }) => {
  const { nextScreen, contextAccount }: { nextScreen: string, contextAccount: AccountDto }  = route?.params || {}

  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()

  const isLoading = featuresIsLoading
  const error: any = featuresError

  const groupPortfolioChoiceDisabled = !featureIsEnabledForUser(features, FeatureCode.GROUP_PENSION)
  const assetAllocationChoiceDisabled = !featureIsEnabledForUser(features, FeatureCode.FUND_CHOICE)

  //Temp state
  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ investmentTypeChoice: InvestmentTypeChoice }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      investmentTypeChoice: undefined
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const { investmentTypeChoice } = attributes
    dispatch(updateWorkingAccount(attributes))
    navigation.navigate(
      investmentTypeChoice === InvestmentTypeChoice.ASSET_ALLOCATION
        ? 'AssetAllocation'
        : investmentTypeChoice === InvestmentTypeChoice.GROUP_PORTFOLIO
          ? 'GroupPortfolioChoice'
          : nextScreen
      )
  }

  let options: ManagedCardChoiceInputOption[] = [
    {
      value: InvestmentTypeChoice.PLAN,
      title: `Choose a plan`,
      description: `Choose a ready-made investment plan`,
      illustrationFilename: 'choose_your_jarvis_plan.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.PLAN}
        illustrationFilename={'choose_your_jarvis_plan.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      modalProceedLabel: 'Choose a Plan',
      modalCancelLabel: 'Go Back',
    },
    {
      value: InvestmentTypeChoice.ASSET_ALLOCATION,
      title: `Build your own`,
      description: `Build your own plan from individual funds`,
      illustrationFilename: 'control_your_financial_future.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.ASSET_ALLOCATION}
        illustrationFilename={'control_your_financial_future.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      disabled: assetAllocationChoiceDisabled,
      modalProceedLabel: 'Start Building',
      modalCancelLabel: 'Go Back',
    },
    {
      value: InvestmentTypeChoice.GROUP_PORTFOLIO,
      title: `Choose a managed portfolio`,
      description: `Choose a discretionary managed portfolio`,
      illustrationFilename: 'plan_retirement.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.GROUP_PORTFOLIO}
        illustrationFilename={'plan_retirement.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      disabled: groupPortfolioChoiceDisabled,
      modalProceedLabel: 'Choose a Portfolio',
      modalCancelLabel: 'Go Back',
    },
  ]

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchFeatures}
      buttonTitle={isValid ? 'Next' : 'Make Selection'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      showButton={true}
      headline={`Ok, how do you want to invest your Workplace Jar?`}
      subHeading={'Please consider your personal experience in making investments'}
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'investmentTypeChoice'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        options={options}
      />
    </ProcessScreen>
  )
}
