import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import React from 'react'
import { Image } from 'react-native'
import { useGetInvitesAsTargetQuery } from 'store/apiSlice'
import { Sizing } from 'styles'

export const SpouseAdd_01_InviteCheck = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  const { data: invitesAsTarget, error: invitesAsTargetError, isLoading: invitesAsTargetIsLoading, refetch: refetchInvitesAsTarget } = useGetInvitesAsTargetQuery()

  const isLoading = invitesAsTargetIsLoading
  const error: any = invitesAsTargetError

  const hasInvite = !!invitesAsTarget?.length
  const multipleInvites = !!invitesAsTarget && invitesAsTarget.length > 1
  const singleInvite = hasInvite && !multipleInvites ? invitesAsTarget[0] : undefined

  const invitorName = singleInvite?.from
  ? singleInvite?.from?.firstName && singleInvite?.from?.surname
    ? `${singleInvite?.from?.firstName} ${singleInvite?.from?.surname}`
    : singleInvite?.from?.email
  : 'someone'

  const goToSetup = attributes => {
    navigation.navigate(nextScreen)
  }

  const goToInvites = () => {
    navigation.navigate('InviteChoice')
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchInvitesAsTarget}
      buttonTitle={multipleInvites ? 'Review Invites' : 'Review Invite'}
      buttonAction={goToInvites}
      enableButton={true}
      headline={multipleInvites ? 'You have outstanding partner invites!' : 'You have an outstanding partner invite!'}
      subHeading={multipleInvites
        ? 'You have been invited by multiple people to connect accounts'
        : `You have been invited by ${invitorName} to connect accounts`
      }
      allowTextButton={true}
      textButtonTitle={'Add Partner Manually Instead'}
      textButtonAction={goToSetup}
      enableTextButton={true}
    >
       <AppIllustration
        filename={'accept_invitation.png'}
        style={[
          {
            width: '100%',
            height: Sizing.x200,
            resizeMode: 'contain',
            alignSelf: 'center'
          },
      ]} />
      <Paragraph>{
        multipleInvites
          ? `We recommend that you review the invites first, rather than adding your partner manually.`
          : `We recommend that you review invite first, rather than adding your partner manually.`
        }</Paragraph>
    </ModalProcessScreen>
  )
}
