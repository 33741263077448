import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { ProfileScreen } from './Screens/ProfileScreen'

export const ProfileSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Profile'}
    >
      <ProfileScreen />
    </SectionSimpleContainer>
  )
}

