import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { differenceInYears } from 'date-fns'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'
import { MAX_TARGET_RETIREMENT_AGE, MIN_TARGET_RETIREMENT_AGE } from 'lib/constants'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetRetirementProfileQuery } from 'store/apiSlice'
import { RetirementIncomeType } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome, workingIncome } from 'store/tempDataSlice'

export const RetirementIncomesAddIncome_04_Date = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const error: any = rpError || meError
  const isLoading = rpIsLoading || meIsLoading

  const workingIncomeData = useAppSelector(workingIncome)

  const incomeStartAgeRef = useRef()

  const isDefinedBenefit = workingIncomeData?.incomeType === RetirementIncomeType.DEFINED_BENEFIT
  const targetMeAge = isDefinedBenefit
    ? me.statePensionAge
    : retirementProfile?.targetRetirementAge || me.statePensionAge
  //Age to show
  const ageDifference = client?.isSpouse ? differenceInYears(new Date(me.birthDate), new Date(client?.birthDate)) : 0
  const relevantAge = targetMeAge + ageDifference

  //Setup form
  const formObj = useForm<{ incomeStartAge: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      incomeStartAge: relevantAge
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingIncome(attributes))
    navigation.navigate(nextScreen)
  }
  
  const minTargetRetirementAge = me ? deriveMinimumTargetRetirementAge(me) : MIN_TARGET_RETIREMENT_AGE

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={meError ? refetchMe : refetchRp}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How old will ${client?.isSpouse ? client?.firstName : `you`} be?`}
      subHeading={isDefinedBenefit
        ? `Set the age at which ${client?.isSpouse ? client?.firstName : `you`} will start receiving income from this pension`
        : `Set the age at which ${client?.isSpouse ? client?.firstName : `you`} will start receiving the income`
    
      }
    >
      <ManagedIntegerInput
        ref={incomeStartAgeRef}
        name={'incomeStartAge'}
        // autoFocus={true}
        formObj={formObj}
        // label={'Age'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        required={true}
        minValue={minTargetRetirementAge + ageDifference}
        maxValue={MAX_TARGET_RETIREMENT_AGE + ageDifference}
      />
    </ModalProcessScreen>
  )
}
