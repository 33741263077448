import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerHistoryContributionsListScreen } from './Screens/EmployerHistoryContributionsListScreen'

export const EmployerHistoryContributionSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Contribution History'}
    >
      <EmployerHistoryContributionsListScreen />
    </SectionSimpleContainer>
  )
}

