import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { JAR_NAME_ALL } from 'lib/constants'
import React from 'react'

export const SchemeEnrolmentSetContributionRequestAdd_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}

  const next = () => {
     navigation.navigate(nextScreen)
  } 
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'envelope_with_star.png'}
      headline={`Request a Contribution Amount change`}
      subHeading={`Make a request to your employer to change the amount being contributed`}
    >
    </ModalProcessIntroScreen>
  )
}
