import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { getClientGenderIllustrationFilename } from 'lib/generalHelpers'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { ampli } from 'src/ampli'
import { useGetSpouseQuery, useUpdateMeMutation } from 'store/apiSlice'

export const SpouseInviteIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: spouse, isLoading: spouseIsLoading, error: spouseError, refetch } = useGetSpouseQuery()

  const spouseHasAccount = spouse?.userId

  const [ updateClient] = useUpdateMeMutation()
  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)


  const next = () => {
    rootNavigate('SpouseInviteProcessStack', route?.params)
  }

  const refuse = () => {
    updateClient({
      onboardingFlags: {
        invitespouse: true,
      },
    })
    ampli.spouseInviteSetup({
      skipped: true,
    })
    hideDialog()
    goToOnboardingTasks(fromOnboarding)
  }

  return (      
    <ProcessIntroScreen
      isLoading={spouseIsLoading}
      error={spouseError}
      errorTryAgain={refetch}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      refuseButtonAction={fromOnboarding ? showDialog : undefined}
      refuseButtonText={`Don't want to connect?`}
      illustrationFilename={getClientGenderIllustrationFilename(spouse)}
      headline={spouseHasAccount ? `Let's ask ${spouse?.firstName} to link their Jarvis account` : `Let's get ${spouse?.firstName} invited to Jarvis`}
      subHeading={spouseHasAccount
        ? `If they accept, you will share information on your pensions and incomes`
        : `Once they accept, you will share information on your pensions and incomes`
      } 
    >
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`We'll tick this task off, but you can always invite ${spouse?.firstName || 'your partner'} from their avatar in the main menu`}
        onCancel={hideDialog}
        onConfirm={refuse}
        confirmLabel={'Complete task'}
      />
    </ProcessIntroScreen>
  )
}
