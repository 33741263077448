type Neutral =
  | "white"
  | "s100"
  | "s150"
  | "s200"
  | "s250"
  | "s300"
  | "s400"
  | "s500"
  | "s600"
  | "s700"
  | "s800"
  | "s900"
  | "black"
export const neutral: Record<Neutral, string> = {
  white: "#ffffff",
  s100: "#efeff6",
  s150: "#dfdfe6",
  s200: "#c7c7ce",
  s250: "#bbbbc2",
  s300: "#9f9ea4",
  s400: "#7c7c82",
  s500: "#515154",
  s600: "#38383a",
  s700: "#2d2c2e",
  s800: "#212123",
  s900: "#161617",
  black: "#000000",
}

type Brand = "blue1" | "blue2" | "blue3" | "blue4" | "green1" | "green2" | "green3" | "green4" | "grey1" | "grey2" | "grey3" | "grey4" | "purplex0" | "purple0" | "purple1" | "purple2" | "purple3" | "purple4" | "red1" | "red2" | "red3" | "red4" | "yellow1" | "yellow2" | "yellow3" | "yellow4"
export const brand: Record<Brand, string> = {
  blue1: "#014CD4",
  blue2: "#346FF6",
  blue3: "#53D6FF",
  blue4: "#F1FCFF",
  green1: "#357D17",
  green2: "#60BE39",
  green3: "#90E261",
  green4: "#DCECD3",
  grey1: "#3A3A3A",
  grey2: "#828181",
  grey3: "#CCCCCC",
  grey4: "#F4F4F4",
  purplex0: "#23023E",
  purple0: "#2C034E",
  purple1: "#4F028F",
  purple2: "#661AD7",
  purple3: "#C2A2F6",
  purple4: "#E6DFEE",
  red1: "#9A0B00",
  red2: "#C6362D",
  red3: "#FF9C92",
  red4: "#F9E9E8",
  yellow1: "#C79800",
  yellow2: "#F6CF48",
  yellow3: "#FBED62",
  yellow4: "#FEFAC9",
}

type Chart = "pink1" | "pink2" | "blue1" | "blue2" | "purple1" | "purple2" | "aqua1" | "aqua2" | "violet1" | "violet2" | "green1" | "green2"
export const chart: Record<Chart, string> = {
  pink1: "#FBD6E7",
  pink2: "#DE77A6",
  blue1: "#A3E7F8",
  blue2: "#2095B1",
  purple1: "#ECB9E1",
  purple2: "#5F1E51",
  aqua1: "#83E5CB",
  aqua2: "#4ABC9D",
  violet1: "#CC9EDA",
  violet2: "#2E0C38",
  green1: "#B6E2C8",
  green2: "#6DBA8D",
}


type Gradient = "grad_purple0" | "grad_purple1" | "grad_purple1_5" | "grad_purple2" | "grad_purple3"
export const gradient: Record<Gradient, string> = {
  grad_purple0: "#180429",
  grad_purple1: "#2C034E",
  grad_purple1_5: "#330757",
  grad_purple2: "#3A0565",
  grad_purple3: "#681CA8",
}

type Warning = "s400"
export const warning: Record<Warning, string> = {
  s400: "#cf9700",
}

export const applyOpacity = (hexColor: string, opacity: number): string => {
  // if (!hexColor || !opacity) {
  //   return undefined
  // }
  const red = parseInt(hexColor.slice(1, 3), 16)
  const green = parseInt(hexColor.slice(3, 5), 16)
  const blue = parseInt(hexColor.slice(5, 7), 16)

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

export const shadeColor = (hexColor: string, percent: number): string => {
  const redGamut: number = parseInt(hexColor.slice(1, 3), 16)
  const greenGamut: number = parseInt(hexColor.slice(3, 5), 16)
  const blueGamut: number = parseInt(hexColor.slice(5, 7), 16)

  const rgb: Array<number> = [redGamut, greenGamut, blueGamut]

  const toShadedGamut = (gamut: number): number => {
    return Math.floor(Math.min(gamut * (1 + percent / 100), 255))
  }

  const toHex = (gamut: number): string => {
    return gamut.toString(16).length === 1
      ? `0${gamut.toString(16)}`
      : gamut.toString(16)
  }

  const shadedRGB: Array<number> = rgb.map(toShadedGamut)
  const shadedHex: Array<string> = shadedRGB.map(toHex)

  const hexString: string = shadedHex.join("")

  return `#${hexString}`
}
