import React from 'react'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { useForm } from 'react-hook-form'
import { RetirementIncomeType } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome, workingIncome } from 'store/tempDataSlice'
import { getIncomeTypeOptions } from 'lib/retirementIncomeHelpers'
import { addRetirementIncomeClient } from 'store/uxSlice'

export const RetirementIncomesAddIncome_01_Type = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  
  const workingIncomeData = useAppSelector(workingIncome)

  //Setup form
  const formObj = useForm<{ incomeType: RetirementIncomeType }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      incomeType: workingIncomeData?.incomeType,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingIncome(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What kind of income is it?`}
    >
      <ManagedSimpleChoiceInput
        name={'incomeType'}
        formObj={formObj}
        options={getIncomeTypeOptions()}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
