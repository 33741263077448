import { Subheading } from 'components/Typography'
import React, { ReactNode, useRef } from 'react'
import { Animated, StyleSheet, View } from 'react-native'
import { Swipeable } from 'react-native-gesture-handler'
import { Colors, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { ScreenScrollFader } from 'components/Layout/ScreenScrollFader'

type DashboardSwipeableScreenProps = {
  children: ReactNode
  leftNavigationFunction?: any
  rightNavigationFunction?: any
  leftNavigationLabel?: string
  rightNavigationLabel?: string
}
export const DashboardSwipeableScreen = (props: DashboardSwipeableScreenProps) => {
  const { children, leftNavigationLabel, leftNavigationFunction, rightNavigationLabel, rightNavigationFunction } = props

  const swipeableRef = useRef(undefined)

  return (
    <View style={[
      backgroundStyles.pageContainer,
      backgroundStyles.dashboardBackground,
      {
        paddingBottom: Sizing.x0,
      }
    ]}>
      <View style={{
        ...layoutStyles.mainContentContainer,
        paddingBottom: Sizing.x0,
      }}>
        <Swipeable
          ref={swipeableRef}
          friction={2}
          leftThreshold={Sizing.x80}
          rightThreshold={Sizing.x80}
          overshootFriction={8}
          onSwipeableWillOpen={(direction) => {
            switch (direction) {
              case 'left':
                if (leftNavigationFunction) {
                  leftNavigationFunction()
                }
                swipeableRef?.current?.close()
                break
              case 'right':
                if (rightNavigationFunction) {
                  rightNavigationFunction()
                }
                swipeableRef?.current?.close()
                break
            }
          }}
          renderLeftActions={leftNavigationLabel ? (progress, dragX) => {
            const opacity = dragX.interpolate({
              inputRange: [0, Sizing.x80],
              outputRange: [0, 0.5],
            })
            return (
              <Animated.View style={[localStyles.swipeActionContainer, { opacity }]}>
                <View style={{ width: Sizing.x200 }}>
                  <Subheading style={{ color: Colors.brand.red4, transform: [{ rotate: '270deg' }] }}>{leftNavigationLabel}</Subheading>
                </View>
              </Animated.View>
            );
          } : undefined}
          renderRightActions={rightNavigationLabel ? (progress, dragX) => {
            const opacity = dragX.interpolate({
              inputRange: [Sizing.x80 * -1, 0],
              outputRange: [0.5, 0],
            })
            return (
              <Animated.View style={[localStyles.swipeActionContainer, { opacity }]}>
                <View style={{ width: Sizing.x200 }}>
                  <Subheading style={{ color: Colors.brand.red4, transform: [{ rotate: '90deg' }] }}>{rightNavigationLabel}</Subheading>
                </View>
              </Animated.View>
            );
          } : undefined}
        >
          <ScreenScrollFader backgroundColor={backgroundStyles.dashboardBackground.backgroundColor} />
          {
            children
          }
        </Swipeable>
      </View>
    </View>
  )
}

const localStyles = StyleSheet.create({
  swipeActionContainer: {
    width: Sizing.x80,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})