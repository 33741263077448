import { RetirementProfileSetup_00_CopyChoice, RetirementProfileSetup_01_TargetRetirementAge, RetirementProfileSetup_02_SpouseChoice, RetirementProfileSetup_03_LondonChoice, RetirementProfileSetup_04_LevelChoice, RetirementProfileSetup_05_FineTune, RetirementProfileSetup_99_Result } from "./Screens"
import React from 'react'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import { Portal } from "react-native-paper"

export const RetirementProfileSetupProcessStack = ({ route, navigation }) => {
  return (
    <Portal.Host>
    <ProcessStackBuilder
      navigatorId='RetirementProfileSetup'
      processTitle='Your Retirement'
      previousRoute="RetirementProfileSetupIntroScreen"
      nextRoute='RetirementProfileSetupHeroScreen'
      initialParams={route?.params}
      initialRouteName={route?.params?.initialRouteName}
      processScreens={[
        {
          navigationId: 'CopyChoice',
          component: RetirementProfileSetup_00_CopyChoice,
        },
        {
          navigationId: 'TargetRetirementAge',
          component: RetirementProfileSetup_01_TargetRetirementAge,
        },
        {
          navigationId: 'SpouseChoice',
          component: RetirementProfileSetup_02_SpouseChoice,
        },
        {
          navigationId: 'LondonChoice',
          component: RetirementProfileSetup_03_LondonChoice,
        },
        {
          navigationId: 'LevelChoice',
          component: RetirementProfileSetup_04_LevelChoice,
        },
        {
          navigationId: 'FineTune',
          component: RetirementProfileSetup_05_FineTune,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: RetirementProfileSetup_99_Result,
      }}
    />
    </Portal.Host>
  )
}