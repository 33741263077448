import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingSpouse, updateWorkingSpouse, workingInvite } from 'store/tempDataSlice'

export const SpouseInvite_08_Confirm = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingInviteData = useAppSelector(workingInvite)

  //Setup form
  const formObj = useForm<{
    confirmation: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      confirmation: []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj
  
  const onSubmit = attributes => {
    navigation.navigate(nextScreen)
  }

  const options: ManagedMultipleChoiceInputOption[] = [{
    value: 'yes',
    label: `I confirm`,
  }]

  const hasConfirmed = watch('confirmation').includes('yes')

  return (
    <ProcessScreen
      buttonTitle={hasConfirmed ? 'Send Invitation' : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmed}
      headline={`Data sharing agreement`}
      subHeading={`I have read the Partner Data Sharing information and agree to share my financial data with ${workingInviteData?.firstName}`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.SPOUSE_DATA_SHARING} buttonTitle={'About Partner Data Sharing'}/>}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'confirmation'}
        options={options}
      />
    </ProcessScreen>
  )
}
