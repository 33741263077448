import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ProcessScreen } from 'components/Layout'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetInviteIncomesQuery, useGetInviteQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptInvite, workingAcceptInvite } from 'store/tempDataSlice'

export const InviteAccept_03_Incomes = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingAcceptInviteData: any = useAppSelector(workingAcceptInvite)

  const { data: invite, error: inviteError, isLoading: inviteIsLoading, refetch: refetchInvites } = useGetInviteQuery(workingAcceptInviteData?.id, { skip: !workingAcceptInviteData?.id })
  const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchInviteIncomes } = useGetInviteIncomesQuery(workingAcceptInviteData?.id, { skip: !workingAcceptInviteData?.id })

  const refetchAll = () => {
    refetchInvites()
    refetchInviteIncomes()
  }

  const isLoading = inviteIsLoading || incomesIsLoading
  const error: any = inviteError || incomesError

  //Setup form
  const formObj = useForm<{
    incomeIdsToRetain: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      incomeIdsToRetain: workingAcceptInviteData?.incomeIdsToRetain
        ? workingAcceptInviteData?.incomeIdsToRetain
        : incomes
          ? incomes.map(income => income.id)
          : []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingAcceptInvite(attributes))
    navigation.navigate(nextScreen)
  }

  const options: ManagedMultipleChoiceInputOption[] = incomes ? incomes.map(income => {
    return {
      value: income.id,
      label: `${income.name} (${formatCurrencyAmount(income.annualIncomeAmount)}/year) `,
    }
  }) : []

  const count = watch('incomeIdsToRetain').length
  const hasSelected = count > 0

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={hasSelected ? `Retain ${count} Item${count > 1 ? 's' : ''}` : `Don't Retain Any`}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      headline={`Keep the incomes that ${invite?.from?.firstName || 'your partner'} added?`}
      subHeading={`Only deselect any you have already recorded in your Jarvis account`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_INCOMES} buttonTitle={`About Retirement Incomes`} />}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'incomeIdsToRetain'}
        options={options}
      />
    </ProcessScreen>
  )
}
