import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { createBranchLink } from 'lib/linkHelpers'
import React from 'react'
import { Image } from 'react-native'
import { AssetCategoryDto } from 'store/dto/asset-category.dto'
import { Sizing, Paper } from 'styles'

type AdminAssetCategoryListItemProp = {
  assetCategory: AssetCategoryDto
  onPressFunction?: () => void
}
export const AdminAssetCategoryListItem = (props: AdminAssetCategoryListItemProp) => {
  const { assetCategory, onPressFunction } = props
  const { code, logo, name, shortDescription } = assetCategory

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = code
  const subTitle = shortDescription
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
