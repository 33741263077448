import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { sumBy } from 'lodash'
import { useGetRetirementIncomesQuery, useGetSpouseQuery, useUpdateMeMutation } from 'store/apiSlice'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'

export const RetirementIncomeSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}

  const [reportingFinished, setReportingFinished] = useState(false)
  
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError, isFetching: incomesIsFetching, refetch: refetchIncomes } = useGetRetirementIncomesQuery()
  const [ updateClient, { data: client, error: clientError, isLoading: clientIsLoading } ] = useUpdateMeMutation()

  const isLoading = clientIsLoading
  const error: any = clientError

  //Save on enter
  useEffect(() => {
    saveClient()
  }, [])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        incomes: true,
      },
    })
  }

  //Amplitude reporting
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }
    if (!spouseIsLoading && incomes) {
      const clientIncomeCount = sumBy(incomes, function(asset: RetirementIncomeDto) {
        return asset.clientId === spouse?.id ? 0 : 1
      })
      const spouseIncomeCount = sumBy(incomes, function(asset: RetirementIncomeDto) {
        return asset.clientId === spouse?.id ? 1 : 0
      })
      ampli.retirementIncomeSetup({
        withSpouse: !!spouse,
        clientIncomeCount,
        spouseIncomeCount,
      })
      setReportingFinished(true)
    } else if (incomesError) {
      setReportingFinished(true)
    }
  }, [spouseIsLoading, incomes, incomesError, reportingFinished])

  //Navigate when client saved and reporting done
  useEffect(() => {
    if (client && reportingFinished) {
      end()
    }
  }, [client, reportingFinished])
    
  const end = () => {
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={saveClient}
      isLoading={isLoading}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
