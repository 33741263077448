import { first, isArray, isNil, round } from 'lodash'
import { ExtendedGender, Gender } from 'store/dto/base.dto'
import { ClientBaseDto } from 'store/dto/client.dto'

export const delay = async (time) => {
  return new Promise(resolve => setTimeout(resolve, time))
}

export const isValidCompanyNumber = (value: string) => {
  // const result = value.match(/^[0-9]{7,8}$/)
  
  //Regex for checking company numbers
  //Prefix of 2 characters, followed by 6 numbers
  //Valid prefixes:
  //Any 2 digits - England, Wales Limited company
  //SC - Scotland Limited company
  //NI - Northen Ireland Limited company

  const result = value.match(/^(SC|NI|\d{2})\d{6}$/)

  return result ? true : 'Invalid Company Number - please double check'
}

export const genderToExtendedGender = (gender: Gender): ExtendedGender => {
  return gender === Gender.MALE ? ExtendedGender.MALE : ExtendedGender.FEMALE
}

export const extendedGenderToGender = (extendedGender: ExtendedGender): Gender => {
  return [ExtendedGender.FEMALE, ExtendedGender.OTHER].includes(extendedGender) ? Gender.FEMALE : Gender.MALE
}

export const firstQueryParam = (stringOrArrayString: any): string => {
  return isArray(stringOrArrayString) ? first(stringOrArrayString) : stringOrArrayString
}

export const booleanToBooleanString = (value: boolean): string => {
  return value ? 'true' : 'false'
}

export const booleanStringToBoolean = (value: string): boolean => {
  return value === 'true'
}

export const formatCurrencyAmount = (
  amount: number,
  precision?: number,
): string => {
  const usePrecision = precision ? precision : 0
  const isWholeNumber = amount % 1 === 0
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: isWholeNumber ? 0 : usePrecision,
    maximumFractionDigits: usePrecision,
  })
  return `£${formatter.format(amount)}`
}

export const formatPercentageAmount = (
  amount: number,
  precision?: number
): string => {
  const usePrecision = precision ? precision : 0
  const cleanAmount = round(amount, 10)
  const isWholeNumber = cleanAmount % 1 === 0
    
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: isWholeNumber ? 0 : usePrecision,
    maximumFractionDigits: usePrecision,
  })
  return `${formatter.format(cleanAmount)}%`
}

export const getClientGenderIllustrationFilename = (client: ClientBaseDto) => {
  switch (client?.gender) {
    case Gender.FEMALE:
      return 'woman.png'
    default:
      return 'man.png'
  }
}

export const isNilOrEmptyString = (value: string): boolean => {
  return isNil(value) || value === ''
}

//Converts a string to an enum, only for string enums - returns undefined if no match
export const enumFromStringValue = <T>(enm: { [s: string]: T}, value: string): T | undefined => {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? value as unknown as T
    : undefined;
}