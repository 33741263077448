import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import React from 'react'
import { batch } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetup, updateWorkingContributionSetupPayment, updateWorkingLumpSumContributionPayment, workingContributionPayment, workingLumpSumContributionPayment } from 'store/tempDataSlice'
import { TaskList } from '../Components/TaskList'

export const MakeContributions_99_Result = ({ navigation, route }) => {
  const { onCompletion, contributionsData }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingContributionPaymentData = useAppSelector(workingContributionPayment)
  const workingLumpSumContributionPaymentData = useAppSelector(workingLumpSumContributionPayment)

  // //Close on save
  // useEffect(() => {
  //   close()
  // }, [])

  const close = () => {
    const actualMonthlyContributionAmount = workingContributionPaymentData?.amount
    const actualOneOffContributionAmount = workingLumpSumContributionPaymentData?.amount
    //Clean everything up
    batch(() => {
      dispatch(updateWorkingContributionSetup(undefined))
      dispatch(updateWorkingLumpSumContributionPayment(undefined))
      dispatch(updateWorkingContributionSetupPayment(undefined))
    })
    onCompletion(actualMonthlyContributionAmount, actualOneOffContributionAmount)
  }
  
  return (
    <ModalProcessResultScreen
      headline={'All done!'}
      subHeading={`You've completed the following tasks`}
      illustrationFilename={'task_list.png'} 
      buttonTitle={'Finish'}
      buttonAction={close}
      showButton={true}
    >
      <TaskList
        contributionsData={contributionsData}
        nextTask={undefined}
        nextTaskAction={() => {}}
        allComplete={true}
      />
    </ModalProcessResultScreen>
  )
}
