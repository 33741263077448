import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { BeneficiaryRelationship } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { Title } from 'store/dto/base.dto'

export const BeneficiariesAddBeneficiary_01_Relationship = ({
  route,
  navigation
}) => {
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const dispatch = useAppDispatch()

  const { nextScreen } = route?.params || {}

  const formObj = useForm<{ beneficiaryRelationship: BeneficiaryRelationship }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      beneficiaryRelationship: workingBeneficiaryData?.beneficiaryRelationship
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    const isCharity = attributes?.beneficiaryRelationship === BeneficiaryRelationship.CHARITY
    dispatch(updateWorkingBeneficiary({
      ...attributes,
      title: isCharity ? Title.MR : undefined
    }))
    navigation.navigate(isCharity ? 'Charity' : nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Tell us a bit about them`}
      subHeading={`Choose the best fit for their relationship to you`}
    >
      <ManagedSimpleChoiceInput
        name={'beneficiaryRelationship'}
        formObj={formObj}
        options={enumToAutocompleteOptions(BeneficiaryRelationship)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
