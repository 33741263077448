import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { EmployerContributionSetChoice, WrappedContributionRecord } from 'features/ModalProcesses/EmployerAddContributionJob/EmployerAddContributionJobModal'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import { compact } from 'lodash'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ScrollView, StyleSheet } from 'react-native'
import { Dialog, Portal, ThemeProvider } from "react-native-paper"
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { UnborderedTable } from '../../../../components/Utility/UnborderedTable'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { ContentDivider } from 'components/Layout/ContentDivider'

type selectedMemberIdsEmployerContributionConfirmDialogProps = {
  formObj: UseFormReturn<any>
  name: string
  onCancel: any
  onSubmit: any
  visible: boolean
}


export const EmployerContributionConfirmDialog = (props: selectedMemberIdsEmployerContributionConfirmDialogProps) => {
  const { visible, onCancel, onSubmit, formObj, name } = props || {}

  const { watch } = formObj
  const wrappedContributionRecords: WrappedContributionRecord[] = watch('wrappedContributionRecords')

  let countWithinOptOutWindow = 0
  let countNilReturn = 0
  let countMemberWithContributions = 0
  let totalEmployerContributions = 0
  let totalEmployeeContributions = 0

  wrappedContributionRecords.forEach(wcr => {
    const { data } = wcr
    const { choice, inOptOutWindow, employerContributionAmount, employeeContributionAmount } = data
    if (inOptOutWindow) {
      countWithinOptOutWindow++
    } else if (choice === EmployerContributionSetChoice.NIL) {
      countNilReturn++
    } else {
      countMemberWithContributions++
      totalEmployerContributions += employerContributionAmount
      totalEmployeeContributions += employeeContributionAmount
    }
  })

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  const constraintStyle: any = getScreenSizeConstraints()
  const maxContentHeight = getScreenAppHeight() - Sizing.x200

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <Dialog
          visible={visible}
          onDismiss={onCancel}
          style={{
            ...constraintStyle,
            minWidth: Sizing.x600,
          }}
          theme={theme}
        >
          <Dialog.Title style={{
            color: Colors.brand.purple1,
            textAlign: 'center',
          }}>{`Confirm Contribution Submission`}</Dialog.Title>
          <Dialog.Content
          style={{
            maxHeight: maxContentHeight,
          }}
          >
            <ScrollView
              nestedScrollEnabled
              style={layoutStyles.scrollContainer}
              contentContainerStyle={layoutStyles.scrollContainerContent}
              keyboardShouldPersistTaps='handled'
              showsVerticalScrollIndicator={true}
            >
              <Paragraph>{`Please review the summary below and confirm your submission.`}</Paragraph>
              <ContentDivider />
              <Subheading style={localStyles.subTitle}>{'Member Summary'}</Subheading>
              <UnborderedTable
                data={compact([
                  countWithinOptOutWindow ? {
                    label: `Within Opt Out Window`,
                    value: countWithinOptOutWindow
                  } : undefined,
                  countNilReturn ? {
                    label: `No Contributions`,
                    value: countNilReturn
                  } : undefined,
                  {
                    label: `Contributions Declared`,
                    value: countMemberWithContributions,
                  },
                  {
                    label: <Text style={{ fontWeight: '900' }}>{`Total Active Members`}</Text>,
                    value: <Text style={{ fontWeight: '900' }}>{countWithinOptOutWindow + countNilReturn + countMemberWithContributions}</Text>,
                  },
                ])}
                noContentDivider={true}
              />
              <ContentDivider />
              <Subheading style={localStyles.subTitle}>{'Financial Summary'}</Subheading>
              <UnborderedTable
                data={[
                  {
                    label: `Estimated Employer Contributions`,
                    value: formatCurrencyAmount(totalEmployerContributions, 2),
                  },
                  {
                    label: `Estimated Employee Contributions`,
                    value: formatCurrencyAmount(totalEmployeeContributions, 2),
                  },
                  {
                    label: <Text style={{ fontWeight: '900' }}>{`Estimated Total`}</Text>,
                    value: <Text style={{ fontWeight: '900' }}>{formatCurrencyAmount(totalEmployerContributions + totalEmployeeContributions, 2)}</Text>,
                  },
                ]}
                noContentDivider={true}
              />
            </ScrollView>
          </Dialog.Content>
          <Dialog.Actions>
            <Button mode='text' onPress={onCancel}>
              <Text style={localStyles.buttonText}>{'Cancel'}</Text>
            </Button>
            <Button mode='text' onPress={onSubmit}>
              <Text style={localStyles.buttonText}>{'Confirm'}</Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </ThemeProvider>
    </Portal>
  )
}

const localStyles = StyleSheet.create({
  buttonText: {
    color: Colors.brand.purple1
  },
  subTitle: {
    color: Colors.brand.purple2,
  },
})
