import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import { DrawerDescriptorMap, DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { DrawerNavigationState, ParamListBase } from '@react-navigation/native'
import { JarvisLogoType } from 'components/Brand/JarvisLogoType'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Subheading } from 'components/Typography'
import { SegmentBadge } from 'components/Typography/SegmentBadge'
import { AppContextSwitcher } from 'components/Utility/AppContextSwitcher'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { mainAppNavigate } from 'lib/RootNavigation'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import CustomDrawerItemList, { DrawerScreen } from 'navigation/components/CustomDrawerItemList'
import React, { useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import { Divider, Portal, ThemeProvider } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetMeQuery, useGetSpouseQuery } from 'store/apiSlice'
import { AppContext } from 'store/authSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

type MainAppStackDrawerContentProps = {
  screens: DrawerScreen[],
  screenGroupMap: any,
  state: DrawerNavigationState<ParamListBase>,
  descriptors: DrawerDescriptorMap,
  navigation: DrawerNavigationHelpers,
}
export const MainAppStackDrawerContent = (props: MainAppStackDrawerContentProps) => {
  const { screens, screenGroupMap, state, navigation, descriptors } = props

  const authUser = useSelector((state: any) => state.auth.user)
  const userHasClient = useSelector((state: any) => state.auth.userHasClient)

  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()
  //Skip if userHasClient === false so that it checks once (when undefined)
  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery(undefined, { skip: !user || userHasClient === false })
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, refetch: refetchSpouse } = useGetSpouseQuery(undefined, { skip: !client })

  const mePicture = client?.avatar || client?.picture
  const spousePicture = spouse?.avatar || spouse?.picture
    
  const insets = useSafeAreaInsets()

  const [showInfoModal, setShowInfoModal] = useState(null)

  const isWeb = platformIsWeb()

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightPurple : Paper.darkThemeOnLightGrey}>
      <View style={{
        backgroundColor: isWeb ? Colors.brand.purple4 : Colors.brand.grey4,
        flex: 1,
      }}>
      <DrawerContentScrollView
        {...props}
        style={{
          marginRight: isWeb ? Sizing.x0 : Sizing.x1,
        }}
      >
        <Pressable
          onPress={() => setShowInfoModal(true)}
          style={{
            paddingVertical: Sizing.x20,
            alignSelf: 'center',
            width: Sizing.x200,
        }}>
          <JarvisLogoType size={Sizing.x30} color='black' />
          <Portal>
            <AppInfoModal
              user={user}
              client={client}
              visible={showInfoModal}
              onDismiss={() => setShowInfoModal(false)}
            />
          </Portal>
        </Pressable>
        {
          client?.segment?.isPromotion ?
          <SegmentBadge
            style={{
              fontSize: Sizing.x10,
            }}
            segment={client?.segment}
            containerStyle={{
              paddingBottom: Sizing.x10,
            }}
          />
          : <></>
        }
        <AppContextSwitcher currentAppContext={AppContext.CLIENT} />
        <ContentDivider />
        <CustomDrawerItemList
          {...props}
          screenGroupMap={screenGroupMap}
        />
      </DrawerContentScrollView>
      <View style={{
          marginRight: isWeb ? Sizing.x0 : Sizing.x1,
          paddingBottom: insets.bottom,
      }}>
        <ContentDivider />
        <View style={{
          paddingVertical: Sizing.x10,
          ...Flex.row.center,
          }}>
            <Pressable
              style={localStyles.profileContainer}
              onPress={() => mainAppNavigate(MainAppNavScreen.PROFILE)}
            >
              {
                client ?
                  <View style={localStyles.smallProfileImageContainer}>
                    <Image source={{ uri: mePicture }} style={localStyles.smallProfileImage}/>
                  </View>
                :
                <View style={localStyles.smallProfileImageContainer}>
                  <View style={[localStyles.smallProfileImage, {
                    backgroundColor: Colors.neutral.s400,
                    borderRadius: scaleNormalizer(30),
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }]}>
                    <MaterialCommunityIcons name='account' size={Sizing.x40} color={Colors.neutral.white}/>
                  </View>
                </View>
              }

              <View>
                <Subheading>{client?.firstName}</Subheading>
              </View>
            </Pressable>
            <Pressable
              style={localStyles.profileContainer}
              onPress={() => mainAppNavigate(MainAppNavScreen.SPOUSE)}
            >
              {
              spouse ?
                <View style={localStyles.smallProfileImageContainer}>
                  <Image source={{ uri: spousePicture }} style={localStyles.smallProfileImage}/>
                </View>
                :
                <View style={localStyles.smallProfileImageContainer}>
                  <View style={[localStyles.smallProfileImage, {
                    backgroundColor: Colors.neutral.s400,
                    borderRadius: scaleNormalizer(30),
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }]}>
                    <MaterialCommunityIcons name='account-multiple-plus' size={Sizing.x40} color={Colors.neutral.white}/>
                  </View>
                </View>
              }
              <View>
              <Subheading>{spouse ? spouse?.firstName : 'Partner'}</Subheading>
            </View>
          </Pressable>
        </View>
      </View>
    </View>
    </ThemeProvider>
    )
  }

  const localStyles = StyleSheet.create({
    profileContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingHorizontal: Sizing.x10,
    },
    smallProfileImageContainer: {
    },
    smallProfileImage: {
      alignSelf: 'center',
      height: scaleNormalizer(60),
      width: scaleNormalizer(60),
      borderRadius: scaleNormalizer(30),
    },
  })
  