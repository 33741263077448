import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useCreateRetirementProfileMutation, useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { pick } from 'lodash'

export const RetirementProfileSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}
  const dispatch = useAppDispatch()

  const [reportingFinished, setReportingFinished] = useState(false)
  const workingRetirementProfileData: any = useAppSelector(workingRetirementProfile)

  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const [ createRetirementProfile, { data: retirementProfile, error: rpCreateError, isLoading: rpCreateIsLoading } ] = useCreateRetirementProfileMutation()

  const error = rpCreateError
  const isLoading = spouseIsLoading || rpCreateIsLoading

  //Save on entry
  useEffect(() => {
      save()
  }, [])

  //Navigate if successful
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }

    if (retirementProfile) {
      ampli.retirementProfileSetup({
        withSpouse: !!spouse,
        ...pick(retirementProfile, [
          'asCouple',
          'insideLondon',
          'baseBudgetLevel',
          'expensesNetTotalAmount',
          'targetRetirementAge',
        ])
      })
      setReportingFinished(true)
    }
  }, [retirementProfile, reportingFinished])

  //Navigate when retirementProfile saved and reporting done
  useEffect(() => {
    if (retirementProfile && reportingFinished) {
      end()
    }
  }, [retirementProfile, reportingFinished])

  const save = () => {
    Logger.info(`Creating RetirementProfile...`)
    createRetirementProfile(workingRetirementProfileData)
  }

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingRetirementProfile(undefined))
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={save}
      isLoading={isLoading}
      loadingMessage={rpCreateIsLoading ? ['Saving profile...'] : undefined}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
