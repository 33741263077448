/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/pensionjar/Jarvis/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'a188cf84c77d4e65ad676422e1eb2f5f',
  development: 'b18787c6661580c894a569d459561b88'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'frontend',
    versionId: '136bc174-90c6-4715-8f3c-c559f4ee6ecc'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: ReactNativeOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: ReactNativeOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: ReactNativeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  affiliateId?: string;
  /**
   * The name of the affiliate from which the client was sourced, or null.
   */
  affiliateName?: string;
  affiliateRef?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  age?: number;
  androidBuildNumber?: string;
  androidOtaDate?: string;
  androidOtaRevision?: string;
  authProvider?: string;
  birthDate?: string;
  /**
   * Whether or not the client has successfully completed identity verification
   */
  clientIsVerified?: boolean;
  /**
   * The date on which the client completed initial profile setup - null if not completed yet.
   */
  clientProfileCreatedDate?: string;
  /**
   * The date that the user account was created (i.e the date of the event "Client Signup"). N.B. The date should be in ISO format YYYY-MM-DDTHH:mm:ss. E.g July 6, 2023 11:44:36PM PST
   */
  createdAt?: string;
  email?: string;
  employmentStatus?: string;
  /**
   * Client's first name
   */
  firstName?: string;
  gender?: string;
  /**
   * Indicates whether or not the client has completed the onboarding process for adding assets.
   */
  hasCompletedAssetSetup?: boolean;
  /**
   * Indicates whether or not the client has completed the onboarding process for adding beneficiaries.
   */
  hasCompletedBeneficiarySetup?: boolean;
  /**
   * Indicates whether or not the client has completed the onboarding process for consolidation (irrespective of whether they have consolidated anything).
   */
  hasCompletedConsolidationSetup?: boolean;
  /**
   * Indicates whether or not the client has completed the onboarding process for contributions (irrespective of whether they have contributed anything)
   */
  hasCompletedContributionSetup?: boolean;
  /**
   * Indicates whether or not the client has completed the onboarding process for adding incomes.
   */
  hasCompletedIncomeSetup?: boolean;
  /**
   * Indicates whether or not the client has completed the onboarding process for adding a spouse (to which they could have said they have no spouse).
   */
  hasCompletedSpouseSetup?: boolean;
  hasSpouse?: boolean;
  initial_utm_campaign?: string;
  initial_utm_source?: string;
  iosBuildNumber?: string;
  iosOtaDate?: string;
  iosOtaRevision?: string;
  /**
   * The age the client would be at the date until which retirement needs to be funded. i.e. If a singleton, it is their life expectancy. If they have defined a spouse, it is the combined life expectancy, technically applied to the younger of the two. Note that can result in quite a high value, if the "other" person is much younger.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  lastSurvivorDeathAge?: number;
  /**
   * The date until which retirement needs to be funded.
   */
  lastSurvivorDeathDate?: string;
  maritalStatus?: string;
  /**
   * The most recent date on which a contribution was made to the personal pension
   */
  pensionContributionLastDate?: string;
  /**
   * The source of funds that the user has selected for contributions to their personal pension, either "personal" (from their personal cash) or "employer" (from their limited company). This affects the tax handling of their pension contributions.
   */
  pensionContributionSource?: string;
  /**
   * The total financial amount of contributions made to the user's personal pension, at pensionValuationDate
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pensionContributionTotalAmount?: number;
  /**
   * Date of creation for the user's personal pension.
   */
  pensionCreatedDate?: string;
  /**
   * The total financial value of the user's personal pension, at pensionValuationDate.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pensionCurrentValue?: number;
  /**
   * The investment strategy for the personal pension
   */
  pensionInvestmentStrategy?: string;
  /**
   * The monthly regular contribution amount that the user has configured for their personal pension.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pensionMonthlyContributionAmount?: number;
  /**
   * The name of the (investment) plan the user selected for their pension pension.
   */
  pensionPlanName?: string;
  /**
   * The most recent date on which an inward transfer was successfully completed.
   */
  pensionTransferInLastDate?: string;
  /**
   * The total financial amount of inward transfers made to the user's pension, at pensionValuationDate
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pensionTransferInTotalAmount?: number;
  /**
   * The date on which the pensionCurrentValue (and other financial items) was known. Depending on user activity, this could be a while ago.
   */
  pensionValuationDate?: string;
  preferEmail?: boolean;
  preferPush?: boolean;
  preferredOnboardingPhase?: string;
  /**
   * Whether or not the user selected to construct a retirement budget for a couple. Note this is technically separate to whether they actually have a spouse right now - there are edge cases where the 2 data items can conflict.
   */
  retirementBudgetAsCouple?: boolean;
  /**
   * The base level that the user selected when forming a budget - this is one of "minimum", "moderate", "comfortable" or "custom", where the last one indicates they have customized with various levels or custom amounts.
   */
  retirementBudgetBaseLevel?: string;
  /**
   * The total annual gross amount for their retirement budget - i.e. the income they would have to receive before tax to support the net budget.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  retirementBudgetGrossTotalAmount?: number;
  /**
   * Whether or not the user selected to construct a retirement budget for living in London.
   */
  retirementBudgetInsideLondon?: boolean;
  /**
   * The date on which the client last updated their retirement profile/budget.
   */
  retirementBudgetLastUpdatedDate?: string;
  /**
   * The total annual net amount for their retirement budget - i.e. the cost of their lifestyle
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  retirementBudgetNetTotalAmount?: number;
  /**
   * The date on which the client completed initial retirement profile setup - null if not completed yet.
   */
  retirementProfileCreatedDate?: string;
  /**
   * The short name the "pricing" segment/club that the user belongs to - e.g. early adopter benefits
   */
  segmentBadgeName?: string;
  segmentId?: string;
  /**
   * The full name the "pricing" segment/club that the user belongs to - e.g. early adopter benefits
   */
  segmentName?: string;
  spouseBirthDate?: string;
  spouseFirstName?: string;
  spouseGender?: string;
  /**
   * Indicates if the defined spouse is also a connected Jarvis client (i.e. has been invited and accepted connection)
   */
  spouseIsConnected?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  spouseStatePensionAmount?: number;
  spouseSurname?: string;
  spouseTitle?: string;
  /**
   * The age at which the client would first receive their state pension.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  statePensionAge?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  statePensionAmount?: number;
  /**
   * The date at which the client would first receive their state pension.
   */
  statePensionDate?: string;
  /**
   * Client's Last Name
   */
  surname?: string;
  /**
   * The age at which the client would like to retire, if possible.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  targetRetirementAge?: number;
  /**
   * The date at which the client would like to retire, if possible.
   */
  targetRetirementDate?: string;
  /**
   * The calculated number of years after targetRetirementDate, until lastSurvivorDeathDate.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  targetRetirementYearsAfter?: number;
  /**
   * The calculated number of years until targetRetirementDate.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  targetRetirementYearsUntil?: number;
  title?: string;
  /**
   * The count of other pensions that the user has added as Retirement Assets that are considered eligible for transfer into Jarvis.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  transferablePensionCount?: number;
  /**
   * The total monthly amount of regular contributions that the user has said are being paid into other pensions that the user has added as Retirement Assets and are considered eligible for transfer into Jarvis.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferablePensionMonthlyContributionAmount?: number;
  /**
   * The total financial value of other pensions that the user has added as Retirement Assets that are considered eligible for transfer into Jarvis.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferablePensionValue?: number;
  /**
   * The most recent date on which a contribution was made to the workplace pension.
   */
  workplacePensionContributionLastDate?: string;
  /**
   * The total financial amount of contributions made to the user's workplace pension, at workplacePensionValuationDate
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workplacePensionContributionTotalAmount?: number;
  /**
   * Date of creation for the user's workplace pension.
   */
  workplacePensionCreatedDate?: string;
  /**
   * The total financial value of the user's workplace pension, at workplacePensionValuationDate
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workplacePensionCurrentValue?: number;
  /**
   * The investment strategy for the workplace pension
   */
  workplacePensionInvestmentStrategy?: string;
  /**
   * The name of the (investment) plan the user selected for their workplace pension.
   */
  workplacePensionPlanName?: string;
  workplacePensionValuationDate?: string;
}

export interface BeneficiariesChangeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  beneficiaryCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  beneficiaryRelationships: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | add, delete, edit, proportions |
   */
  changeReason: "add" | "delete" | "edit" | "proportions";
}

export interface BeneficiariesSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  beneficiaryCount: number;
  skipped: boolean;
}

export interface CalculatorUsageProperties {
  affiliateCode?: string;
  affiliateRef?: string;
  loggedInUserId?: string;
  outcome?: string;
  processStep: string;
}

export interface ClientLoginProperties {
  authProvider: string;
}

export interface ClientSignupProperties {
  affiliateId?: string;
  affiliateRef?: string;
  authProvider: string;
  signupDate: string;
  userIsHeld: boolean;
}

export interface ContributionAddProperties {
  amount: string;
}

export interface ContributionChangeRegularProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  dayOfMonth: number;
}

export interface ContributionChangeSourceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource: "personal" | "employer";
}

export interface ContributionsSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actualMonthlyContributionAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actualOneOffContributionAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  goal?: string;
  manualSetup?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proposedMonthlyContributions?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proposedOneOffContribution?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proposedRetirementAge?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  proposedTargetRetirementAge?: number;
  skipped: boolean;
  surplusStrategy?: string;
}

export interface InviteAcceptProperties {
  email: string;
  invitorUserId: string;
}

export interface InviteDeclineProperties {
  email: string;
  invitorUserId: string;
}

export interface InviteResendProperties {
  email: string;
  invitorUserId: string;
}

export interface InviteRevokeProperties {
  email: string;
  invitorUserId: string;
}

export interface InviteSendProperties {
  email: string;
  invitorUserId: string;
}

export interface PlanChangeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  investmentAssetCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | plan, custom |
   */
  investmentChoice?: "plan" | "custom";
  investmentPlanId?: string;
  investmentPlanName?: string;
}

export interface PlanSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  investmentAssetCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | plan, custom |
   */
  investmentChoice?: "plan" | "custom";
  investmentPlanId?: string;
  investmentPlanName?: string;
  pensionOptOut: boolean;
}

export interface RetirementAssetCreateProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
}

export interface RetirementAssetDeleteProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
}

export interface RetirementAssetSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  clientAssetCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nonTransferablePensionCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  spouseAssetCount: number;
  withSpouse: boolean;
}

export interface RetirementAssetUpdateProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
}

export interface RetirementIncomeCreateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  annualIncomeAmount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  incomeStartAge?: number;
  incomeStartDate?: string;
  incomeType: string;
  isSpouse: boolean;
}

export interface RetirementIncomeDeleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  annualIncomeAmount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  incomeStartAge?: number;
  incomeStartDate?: string;
  incomeType: string;
  isSpouse: boolean;
}

export interface RetirementIncomeSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  clientIncomeCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  spouseIncomeCount: number;
  withSpouse: boolean;
}

export interface RetirementIncomeUpdateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  annualIncomeAmount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  incomeStartAge?: number;
  incomeStartDate?: string;
  incomeType: string;
  isSpouse: boolean;
}

export interface RetirementProfileSetupProperties {
  asCouple: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | minimum, moderate, comfortable, custom |
   */
  baseBudgetLevel: "minimum" | "moderate" | "comfortable" | "custom";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  expensesNetTotalAmount: number;
  insideLondon: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  targetRetirementAge: number;
  withSpouse: boolean;
}

export interface RetirementProfileUpdateProperties {
  asCouple: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | minimum, moderate, comfortable, custom |
   */
  baseBudgetLevel: "minimum" | "moderate" | "comfortable" | "custom";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  expensesNetTotalAmount: number;
  insideLondon: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  targetRetirementAge: number;
}

export interface SpouseCreateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age: number;
  birthDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  gender?: "Male" | "Female";
}

export interface SpouseDeleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age: number;
  birthDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  gender?: "Male" | "Female";
}

export interface SpouseUpdateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age: number;
  birthDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  gender?: "Male" | "Female";
}

export interface SpouseAddSetupProperties {
  skipped: boolean;
}

export interface SpouseInviteSetupProperties {
  skipped: boolean;
}

export interface StatementRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  periodInMonths?: number;
  statementType: string;
}

export interface StatePensionSetProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
  isSpouse: boolean;
}

export interface TransferAcknowledgeProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isRetransfer: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
  transferStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferValue: number;
  useTracing?: boolean;
}

export interface TransferApproveProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isRetransfer: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
  transferStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferValue: number;
  useTracing?: boolean;
}

export interface TransferCancelProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isRetransfer: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
  transferStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferValue: number;
  useTracing?: boolean;
}

export interface TransferInstructProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isRetransfer: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
  transferStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferValue: number;
  useTracing?: boolean;
}

export interface TransferPrepareProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isRetransfer: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
  useTracing?: boolean;
}

export interface TransferUsageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  executionCount?: number;
  isRetransfer?: boolean;
  outcome?: string;
  processStep: string;
  retirementAssetId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  tracingCount?: number;
}

export interface TransfersSetupProperties {
  skipped: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalTransferValue?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  transferredCount?: number;
}

export interface UserUpdateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  preferredNotificationMethods: string[];
}

export interface VerificationSetupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | pass, fail, refer |
   */
  amlStatus: "pass" | "fail" | "refer";
}

export interface BeneficiariesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  beneficiaryCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  beneficiaryRelationships: string[];
}

export interface ContributionSourceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource: "personal" | "employer";
}

export interface InviteProperties {
  email: string;
  invitorUserId: string;
}

export interface OneOffContributionProperties {
  amount: string;
}

export interface PortfolioProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  investmentAssetCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | plan, custom |
   */
  investmentChoice?: "plan" | "custom";
  investmentPlanId?: string;
  investmentPlanName?: string;
}

export interface RegularContributionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  dayOfMonth: number;
}

export interface RetirementAssetProperties {
  assetType: string;
  brandId?: string;
  brandName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | personal, employer |
   */
  contributionSource?: "personal" | "employer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentValue: number;
  employerEndDate?: string;
  employerId?: string;
  employerIsPublicSector?: boolean;
  employerName?: string;
  employerShortDuration?: boolean;
  employerStartDate?: string;
  isActiveEmpoyer?: boolean;
  isSpouse: boolean;
  isTransferable: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  monthlyContributionAmount?: number;
  pensionProviderBrandKey?: string;
  pensionProviderBrandName?: string;
  pensionProviderId?: string;
}

export interface RetirementIncomeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  annualIncomeAmount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  incomeStartAge?: number;
  incomeStartDate?: string;
  incomeType: string;
  isSpouse: boolean;
}

export interface RetirementProfileProperties {
  asCouple: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | minimum, moderate, comfortable, custom |
   */
  baseBudgetLevel: "minimum" | "moderate" | "comfortable" | "custom";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  expensesNetTotalAmount: number;
  insideLondon: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  targetRetirementAge: number;
}

export interface SpouseProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age: number;
  birthDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  gender?: "Male" | "Female";
}

export interface StatementProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  periodInMonths?: number;
  statementType: string;
}

export interface StatePensionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
  isSpouse: boolean;
}

export interface TransferProperties {
  transferStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  transferValue: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BeneficiariesChange implements BaseEvent {
  event_type = 'Beneficiaries Change';

  constructor(
    public event_properties: BeneficiariesChangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BeneficiariesSetup implements BaseEvent {
  event_type = 'Beneficiaries Setup';

  constructor(
    public event_properties: BeneficiariesSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalculatorUsage implements BaseEvent {
  event_type = 'Calculator Usage';

  constructor(
    public event_properties: CalculatorUsageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientLogin implements BaseEvent {
  event_type = 'Client Login';

  constructor(
    public event_properties: ClientLoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientLogout implements BaseEvent {
  event_type = 'Client Logout';
}

export class ClientSetup implements BaseEvent {
  event_type = 'Client Setup';
}

export class ClientSignup implements BaseEvent {
  event_type = 'Client Signup';

  constructor(
    public event_properties: ClientSignupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientUpdate implements BaseEvent {
  event_type = 'Client Update';
}

export class ContributionAdd implements BaseEvent {
  event_type = 'Contribution Add';

  constructor(
    public event_properties: ContributionAddProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContributionChangeRegular implements BaseEvent {
  event_type = 'Contribution ChangeRegular';

  constructor(
    public event_properties: ContributionChangeRegularProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContributionChangeSource implements BaseEvent {
  event_type = 'Contribution ChangeSource';

  constructor(
    public event_properties: ContributionChangeSourceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContributionsSetup implements BaseEvent {
  event_type = 'Contributions Setup';

  constructor(
    public event_properties: ContributionsSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FriendShare implements BaseEvent {
  event_type = 'Friend Share';
}

export class InviteAccept implements BaseEvent {
  event_type = 'Invite Accept';

  constructor(
    public event_properties: InviteAcceptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InviteDecline implements BaseEvent {
  event_type = 'Invite Decline';

  constructor(
    public event_properties: InviteDeclineProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InviteResend implements BaseEvent {
  event_type = 'Invite Resend';

  constructor(
    public event_properties: InviteResendProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InviteRevoke implements BaseEvent {
  event_type = 'Invite Revoke';

  constructor(
    public event_properties: InviteRevokeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InviteSend implements BaseEvent {
  event_type = 'Invite Send';

  constructor(
    public event_properties: InviteSendProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlanChange implements BaseEvent {
  event_type = 'Plan Change';

  constructor(
    public event_properties?: PlanChangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlanSetup implements BaseEvent {
  event_type = 'Plan Setup';

  constructor(
    public event_properties: PlanSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementAssetCreate implements BaseEvent {
  event_type = 'RetirementAsset Create';

  constructor(
    public event_properties: RetirementAssetCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementAssetDelete implements BaseEvent {
  event_type = 'RetirementAsset Delete';

  constructor(
    public event_properties: RetirementAssetDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementAssetSetup implements BaseEvent {
  event_type = 'RetirementAsset Setup';

  constructor(
    public event_properties: RetirementAssetSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementAssetUpdate implements BaseEvent {
  event_type = 'RetirementAsset Update';

  constructor(
    public event_properties: RetirementAssetUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementIncomeCreate implements BaseEvent {
  event_type = 'RetirementIncome Create';

  constructor(
    public event_properties: RetirementIncomeCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementIncomeDelete implements BaseEvent {
  event_type = 'RetirementIncome Delete';

  constructor(
    public event_properties: RetirementIncomeDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementIncomeSetup implements BaseEvent {
  event_type = 'RetirementIncome Setup';

  constructor(
    public event_properties: RetirementIncomeSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementIncomeUpdate implements BaseEvent {
  event_type = 'RetirementIncome Update';

  constructor(
    public event_properties: RetirementIncomeUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementProfileSetup implements BaseEvent {
  event_type = 'RetirementProfile Setup';

  constructor(
    public event_properties: RetirementProfileSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetirementProfileUpdate implements BaseEvent {
  event_type = 'RetirementProfile Update';

  constructor(
    public event_properties: RetirementProfileUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpouseCreate implements BaseEvent {
  event_type = 'Spouse Create';

  constructor(
    public event_properties: SpouseCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpouseDelete implements BaseEvent {
  event_type = 'Spouse Delete';

  constructor(
    public event_properties: SpouseDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpouseUpdate implements BaseEvent {
  event_type = 'Spouse Update';

  constructor(
    public event_properties: SpouseUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpouseAddSetup implements BaseEvent {
  event_type = 'SpouseAdd Setup';

  constructor(
    public event_properties: SpouseAddSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpouseInviteSetup implements BaseEvent {
  event_type = 'SpouseInvite Setup';

  constructor(
    public event_properties: SpouseInviteSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StatementRequest implements BaseEvent {
  event_type = 'Statement Request';

  constructor(
    public event_properties: StatementRequestProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StatePensionSet implements BaseEvent {
  event_type = 'StatePension Set';

  constructor(
    public event_properties: StatePensionSetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferAcknowledge implements BaseEvent {
  event_type = 'Transfer Acknowledge';

  constructor(
    public event_properties: TransferAcknowledgeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferApprove implements BaseEvent {
  event_type = 'Transfer Approve';

  constructor(
    public event_properties: TransferApproveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferCancel implements BaseEvent {
  event_type = 'Transfer Cancel';

  constructor(
    public event_properties: TransferCancelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferInstruct implements BaseEvent {
  event_type = 'Transfer Instruct';

  constructor(
    public event_properties: TransferInstructProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferPrepare implements BaseEvent {
  event_type = 'Transfer Prepare';

  constructor(
    public event_properties: TransferPrepareProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferUsage implements BaseEvent {
  event_type = 'Transfer Usage';

  constructor(
    public event_properties: TransferUsageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransfersSetup implements BaseEvent {
  event_type = 'Transfers Setup';

  constructor(
    public event_properties: TransfersSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserUpdate implements BaseEvent {
  event_type = 'User Update';

  constructor(
    public event_properties: UserUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VerificationSetup implements BaseEvent {
  event_type = 'Verification Setup';

  constructor(
    public event_properties: VerificationSetupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Beneficiaries Change
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Beneficiaries%20Change)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. beneficiaryCount)
   * @param options Amplitude event options.
   */
  beneficiariesChange(
    properties: BeneficiariesChangeProperties,
    options?: EventOptions,
  ) {
    return this.track(new BeneficiariesChange(properties), options);
  }

  /**
   * Beneficiaries Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Beneficiaries%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. beneficiaryCount)
   * @param options Amplitude event options.
   */
  beneficiariesSetup(
    properties: BeneficiariesSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new BeneficiariesSetup(properties), options);
  }

  /**
   * Calculator Usage
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Calculator%20Usage)
   *
   * Tracks the usage of the calculator feature within the application, capturing the processes involved in its utilization
   *
   * @param properties The event's properties (e.g. affiliateCode)
   * @param options Amplitude event options.
   */
  calculatorUsage(
    properties: CalculatorUsageProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalculatorUsage(properties), options);
  }

  /**
   * Client Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Client%20Login)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. authProvider)
   * @param options Amplitude event options.
   */
  clientLogin(
    properties: ClientLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientLogin(properties), options);
  }

  /**
   * Client Logout
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Client%20Logout)
   *
   * Owner: Julian Mclean
   *
   * @param options Amplitude event options.
   */
  clientLogout(
    options?: EventOptions,
  ) {
    return this.track(new ClientLogout(), options);
  }

  /**
   * Client Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Client%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param options Amplitude event options.
   */
  clientSetup(
    options?: EventOptions,
  ) {
    return this.track(new ClientSetup(), options);
  }

  /**
   * Client Signup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Client%20Signup)
   *
   * Event to track when a new client signs up for an account
   *
   * @param properties The event's properties (e.g. affiliateId)
   * @param options Amplitude event options.
   */
  clientSignup(
    properties: ClientSignupProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientSignup(properties), options);
  }

  /**
   * Client Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Client%20Update)
   *
   * Owner: Julian Mclean
   *
   * @param options Amplitude event options.
   */
  clientUpdate(
    options?: EventOptions,
  ) {
    return this.track(new ClientUpdate(), options);
  }

  /**
   * Contribution Add
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Contribution%20Add)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  contributionAdd(
    properties: ContributionAddProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContributionAdd(properties), options);
  }

  /**
   * Contribution ChangeRegular
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Contribution%20ChangeRegular)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  contributionChangeRegular(
    properties: ContributionChangeRegularProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContributionChangeRegular(properties), options);
  }

  /**
   * Contribution ChangeSource
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Contribution%20ChangeSource)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. contributionSource)
   * @param options Amplitude event options.
   */
  contributionChangeSource(
    properties: ContributionChangeSourceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContributionChangeSource(properties), options);
  }

  /**
   * Contributions Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Contributions%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. actualMonthlyContributionAmount)
   * @param options Amplitude event options.
   */
  contributionsSetup(
    properties: ContributionsSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContributionsSetup(properties), options);
  }

  /**
   * Friend Share
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Friend%20Share)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  friendShare(
    options?: EventOptions,
  ) {
    return this.track(new FriendShare(), options);
  }

  /**
   * Invite Accept
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Invite%20Accept)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  inviteAccept(
    properties: InviteAcceptProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteAccept(properties), options);
  }

  /**
   * Invite Decline
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Invite%20Decline)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  inviteDecline(
    properties: InviteDeclineProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteDecline(properties), options);
  }

  /**
   * Invite Resend
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Invite%20Resend)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  inviteResend(
    properties: InviteResendProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteResend(properties), options);
  }

  /**
   * Invite Revoke
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Invite%20Revoke)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  inviteRevoke(
    properties: InviteRevokeProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteRevoke(properties), options);
  }

  /**
   * Invite Send
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Invite%20Send)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  inviteSend(
    properties: InviteSendProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteSend(properties), options);
  }

  /**
   * Plan Change
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Plan%20Change)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. investmentAssetCount)
   * @param options Amplitude event options.
   */
  planChange(
    properties?: PlanChangeProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlanChange(properties), options);
  }

  /**
   * Plan Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Plan%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. investmentAssetCount)
   * @param options Amplitude event options.
   */
  planSetup(
    properties: PlanSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlanSetup(properties), options);
  }

  /**
   * RetirementAsset Create
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementAsset%20Create)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  retirementAssetCreate(
    properties: RetirementAssetCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementAssetCreate(properties), options);
  }

  /**
   * RetirementAsset Delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementAsset%20Delete)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  retirementAssetDelete(
    properties: RetirementAssetDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementAssetDelete(properties), options);
  }

  /**
   * RetirementAsset Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementAsset%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. clientAssetCount)
   * @param options Amplitude event options.
   */
  retirementAssetSetup(
    properties: RetirementAssetSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementAssetSetup(properties), options);
  }

  /**
   * RetirementAsset Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementAsset%20Update)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  retirementAssetUpdate(
    properties: RetirementAssetUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementAssetUpdate(properties), options);
  }

  /**
   * RetirementIncome Create
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementIncome%20Create)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. annualIncomeAmount)
   * @param options Amplitude event options.
   */
  retirementIncomeCreate(
    properties: RetirementIncomeCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementIncomeCreate(properties), options);
  }

  /**
   * RetirementIncome Delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementIncome%20Delete)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. annualIncomeAmount)
   * @param options Amplitude event options.
   */
  retirementIncomeDelete(
    properties: RetirementIncomeDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementIncomeDelete(properties), options);
  }

  /**
   * RetirementIncome Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementIncome%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. clientIncomeCount)
   * @param options Amplitude event options.
   */
  retirementIncomeSetup(
    properties: RetirementIncomeSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementIncomeSetup(properties), options);
  }

  /**
   * RetirementIncome Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementIncome%20Update)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. annualIncomeAmount)
   * @param options Amplitude event options.
   */
  retirementIncomeUpdate(
    properties: RetirementIncomeUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementIncomeUpdate(properties), options);
  }

  /**
   * RetirementProfile Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementProfile%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. asCouple)
   * @param options Amplitude event options.
   */
  retirementProfileSetup(
    properties: RetirementProfileSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementProfileSetup(properties), options);
  }

  /**
   * RetirementProfile Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/RetirementProfile%20Update)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. asCouple)
   * @param options Amplitude event options.
   */
  retirementProfileUpdate(
    properties: RetirementProfileUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RetirementProfileUpdate(properties), options);
  }

  /**
   * Spouse Create
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Spouse%20Create)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. age)
   * @param options Amplitude event options.
   */
  spouseCreate(
    properties: SpouseCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpouseCreate(properties), options);
  }

  /**
   * Spouse Delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Spouse%20Delete)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. age)
   * @param options Amplitude event options.
   */
  spouseDelete(
    properties: SpouseDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpouseDelete(properties), options);
  }

  /**
   * Spouse Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Spouse%20Update)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. age)
   * @param options Amplitude event options.
   */
  spouseUpdate(
    properties: SpouseUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpouseUpdate(properties), options);
  }

  /**
   * SpouseAdd Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/SpouseAdd%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. skipped)
   * @param options Amplitude event options.
   */
  spouseAddSetup(
    properties: SpouseAddSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpouseAddSetup(properties), options);
  }

  /**
   * SpouseInvite Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/SpouseInvite%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. skipped)
   * @param options Amplitude event options.
   */
  spouseInviteSetup(
    properties: SpouseInviteSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpouseInviteSetup(properties), options);
  }

  /**
   * Statement Request
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Statement%20Request)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. periodInMonths)
   * @param options Amplitude event options.
   */
  statementRequest(
    properties: StatementRequestProperties,
    options?: EventOptions,
  ) {
    return this.track(new StatementRequest(properties), options);
  }

  /**
   * StatePension Set
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/StatePension%20Set)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  statePensionSet(
    properties: StatePensionSetProperties,
    options?: EventOptions,
  ) {
    return this.track(new StatePensionSet(properties), options);
  }

  /**
   * Transfer Acknowledge
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Acknowledge)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  transferAcknowledge(
    properties: TransferAcknowledgeProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferAcknowledge(properties), options);
  }

  /**
   * Transfer Approve
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Approve)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  transferApprove(
    properties: TransferApproveProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferApprove(properties), options);
  }

  /**
   * Transfer Cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Cancel)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  transferCancel(
    properties: TransferCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferCancel(properties), options);
  }

  /**
   * Transfer Instruct
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Instruct)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  transferInstruct(
    properties: TransferInstructProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferInstruct(properties), options);
  }

  /**
   * Transfer Prepare
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Prepare)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. assetType)
   * @param options Amplitude event options.
   */
  transferPrepare(
    properties: TransferPrepareProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferPrepare(properties), options);
  }

  /**
   * Transfer Usage
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfer%20Usage)
   *
   * Tracks the usage of the calculator feature within the application, capturing the processes involved in its utilization
   *
   * @param properties The event's properties (e.g. executionCount)
   * @param options Amplitude event options.
   */
  transferUsage(
    properties: TransferUsageProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferUsage(properties), options);
  }

  /**
   * Transfers Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Transfers%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. skipped)
   * @param options Amplitude event options.
   */
  transfersSetup(
    properties: TransfersSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransfersSetup(properties), options);
  }

  /**
   * User Update
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/User%20Update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. preferredNotificationMethods)
   * @param options Amplitude event options.
   */
  userUpdate(
    properties: UserUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserUpdate(properties), options);
  }

  /**
   * Verification Setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/pensionjar/Jarvis/events/main/latest/Verification%20Setup)
   *
   * Owner: Julian Mclean
   *
   * @param properties The event's properties (e.g. amlStatus)
   * @param options Amplitude event options.
   */
  verificationSetup(
    properties: VerificationSetupProperties,
    options?: EventOptions,
  ) {
    return this.track(new VerificationSetup(properties), options);
  }
}

export const ampli = new Ampli();
