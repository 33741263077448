import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { getSuggestedStep, OnboardingPhase, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetAssetQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { AccountDto, AccountType } from 'store/dto/account.dto'
import { concat } from 'lodash'
import { getPersonalPension } from 'lib/accountHelpers'
import { Logger } from 'lib/logger'

export const InvestmentChoiceHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding, contextAccount, accountId, accountDescriptor, usePersonalPensionInvestmentChoice }: { fromOnboarding: OnboardingPhase, contextAccount: AccountDto, accountId: string, accountDescriptor: string, usePersonalPensionInvestmentChoice: boolean } =  route?.params || {}

  const { data: accounts, isLoading: accountsIsLoading, isFetching: accountsIsFetching, error: accountsError } = useGetUserAccountsQuery()
    
  const updatedAccount = accounts ? accounts.find(account => {
    return account.id === accountId
  }) : undefined

  const isFollow = usePersonalPensionInvestmentChoice
  const isGroupPortfolio = updatedAccount?.groupPortfolio
  const isInvestmentPlan = !isGroupPortfolio && updatedAccount?.investmentPlan
  const isSingleFund = !isInvestmentPlan && !isInvestmentPlan && updatedAccount?.assetAllocation?.length === 1
  const singleFundAssetId = isSingleFund ? updatedAccount?.assetAllocation[0]?.assetId : undefined

  const { data: singleFundAsset, isLoading: singleFundAssetIsLoading, error: singleFundAssetError, refetch: refetchSingleFundAsset } = useGetAssetQuery(singleFundAssetId, { skip: !singleFundAssetId })
  const singleFundName = singleFundAsset?.shortName

  const isLoading = accountsIsLoading || accountsIsFetching || singleFundAssetIsLoading
  const error: any = accountsError || singleFundAssetError

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = concat(
    contextAccount ? [
      { 
        illustrationFilename: isFollow
          ? 'man_saving_in_piggy_bank'
          : !isGroupPortfolio && !isInvestmentPlan && !isSingleFund
            ? 'control_your_financial_future'
            : undefined,
        imageSource: isFollow
          ? undefined
          :  isGroupPortfolio
            ? { uri: updatedAccount?.groupPortfolio?.logo}
            : isInvestmentPlan
              ? { uri: updatedAccount?.investmentPlan?.logo}
              : isSingleFund
                ? { uri: singleFundAsset?.logo }
                : undefined,
        headline: `All sorted!`,
        subHeading: isFollow
          ? `You've set your Workplace Jar to follow your Personal Jar investment strategy!`
          : isGroupPortfolio
            ? `You've switched to our '${updatedAccount?.groupPortfolio?.name}' Managed Portfolio!`
            : isInvestmentPlan
              ? `You've switched to our '${updatedAccount?.investmentPlan?.name}' Plan!`
              : isSingleFund
                ? `You've chosen to invest in '${singleFundName}'`
                : `You've chosen to invest in a mix of our available funds`,
      },
      { 
        illustrationFilename: 'relax_for_now.png',
        headline: `We'll reinvest existing assets`,
        subHeading: `You can leave this with us now to rebalance your portfolio`,
      }, 
      { 
        illustrationFilename: 'regular_contributions.png',
        headline: `About future contributions`,
        subHeading: `Any new contributions will be automatically be invested in line with your new choice`,
      }, 
    ] : [
      {
        illustrationFilename: 'relax_for_now.png',
        headline: `You're all set!`,
        subHeading: `You've just taken the first step to a financial future you can feel confident about`,
      },
      {
        illustrationFilename: !isGroupPortfolio && !isInvestmentPlan && !isSingleFund
          ? 'control_your_financial_future'
          : undefined,
        imageSource: isGroupPortfolio
          ? { uri: updatedAccount?.groupPortfolio?.logo}
          : isInvestmentPlan
            ? { uri: updatedAccount?.investmentPlan?.logo}
            : isSingleFund
              ? { uri: singleFundAsset?.logo }
              : undefined,
        headline: isGroupPortfolio
          ? updatedAccount?.groupPortfolio?.name
          : isInvestmentPlan
            ? updatedAccount?.investmentPlan?.name
            : isSingleFund
              ? `Single Fund`
              : `Bespoke Portfolio`,
        subHeading: isGroupPortfolio
          ? `You've chosen our '${updatedAccount?.groupPortfolio?.name}' Managed Portfolio!`
          : isInvestmentPlan
            ? `You've chosen our ${updatedAccount?.investmentPlan?.name} Plan!`
            : isSingleFund
              ? `You've chosen to invest in '${singleFundName}'`
              : `You've chosen to invest in a mix of our available funds`,
      },
      {
        illustrationFilename: 'regular_contributions.png',
        headline: `Contributions will be invested accordingly`,
        subHeading: `Your contributions will be automatically invested in line with this choice, but you can change this at any time`,
      },
    ]
  )

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
