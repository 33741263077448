  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Paragraph } from 'components/Typography'
import React, { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { DataTable, Portal } from 'react-native-paper'
import { GroupOrganizationRole, GroupSchemeRole } from 'store/dto/group-organization.dto'
import { Colors, Sizing } from 'styles'
import { GroupOrganizationUserRoleAssertion } from '../AdminGroupOrganizationSetUserRolesModal'
import { AdminGroupOrganizationUserRolesEditDialog } from './AdminGroupOrganizationUserRolesEditDialog'

type AdminGroupOrganizationUserRolesTableRowProps = {
  formObj: UseFormReturn<any>
  name: string
  index: number
}

export const AdminGroupOrganizationUserRolesTableRow = (props: AdminGroupOrganizationUserRolesTableRowProps) => {
  const { formObj, name, index } = props
  const { control, watch, setValue, trigger, getFieldState } = formObj
  const userRoleAssertion: GroupOrganizationUserRoleAssertion = watch(`${name}.${index}`)
  const { userName, userEmail, userId, organizationRole, schemeRole } = userRoleAssertion

  const [showEditModal, setShowEditModal] = useState(false)

  const hasNoAccess = !organizationRole && !schemeRole
  const isOrgAdmin = organizationRole === GroupOrganizationRole.ORG_ADMIN
  const isSchemeAdmin = schemeRole === GroupSchemeRole.SCHEME_MANAGER
  const hasFullAccess = isOrgAdmin && isSchemeAdmin
  const organizationRoleFieldState = getFieldState(`${name}.${index}.organizationRole`)
  const schemeRoleFieldState = getFieldState(`${name}.${index}.schemeRole`)
  const organizationRoleIsDirty = organizationRoleFieldState.isDirty
  const schemeRoleIsDirty = schemeRoleFieldState.isDirty
  const hasChanges = organizationRoleIsDirty || schemeRoleIsDirty

  const color =
    hasChanges ? Colors.brand.purple1
      : undefined
  
  const fontWeight = 
    hasChanges ? 'bold'
      : undefined

  const iconName =
    hasNoAccess ? 'account-cancel-outline'
    : hasFullAccess ? 'account-tie-hat-outline'
    : isOrgAdmin || isSchemeAdmin ? 'account-tie-outline'
    : 'account-filter-outline'

  const handleEdit = (
    organizationRole?: GroupOrganizationRole,
    schemeRole?: GroupSchemeRole,
  ) => { 
    if (organizationRole) {
      setValue(`${name}.${index}.organizationRole`, organizationRole, { shouldDirty: true, shouldValidate: true })
    }
    if (schemeRole) {
      setValue(`${name}.${index}.schemeRole`, schemeRole, { shouldDirty: true, shouldValidate: true })
    }
    setShowEditModal(undefined)
  }

  return (
    <>
      <DataTable.Row onPress={() => setShowEditModal(true)}>
        <DataTable.Cell style={{ flex: 1 }}>
          <View style={localStyles.cellWrap}>
            <View style={{ width: Sizing.x40 }}>
              <MaterialCommunityIcons
                name={iconName as any}
                size={Sizing.x25} />
            </View>
            <Paragraph style={{
              textAlign: 'left',
            }}>{`${userName}`}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={localStyles.cellWrap}>
            <Paragraph style={{
              textAlign: 'left',
            }}>{userEmail}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell> 
        <View style={{
          ...localStyles.cellWrap,
          justifyContent: 'flex-end',
        }}>
            <Paragraph style={{
              textAlign: 'right',
              color: schemeRoleIsDirty ? Colors.brand.purple1 : undefined,
              fontWeight: schemeRoleIsDirty ? 'bold' : undefined,
            }}>
              {schemeRole || '(None)'}
            </Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={{
            ...localStyles.cellWrap,
            justifyContent: 'flex-end',
          }}>
            <Paragraph style={{
              textAlign: 'right',
              color: organizationRoleIsDirty ? Colors.brand.purple1 : undefined,
              fontWeight: organizationRoleIsDirty ? 'bold' : undefined,
            }}>
              {organizationRole || '(None)'}
            </Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={{
            ...localStyles.cellWrap,
            justifyContent: 'space-between',
          }}>
            <Paragraph style={{
              textAlign: 'left',
              color,
              fontWeight,
            }}>
              {hasChanges ? `Roles modified` : ``}
            </Paragraph>
            {
              <MaterialCommunityIcons name={'square-edit-outline'} size={Sizing.x20} color={Colors.brand.purple1}/>
            }
          </View>
        </DataTable.Cell>
      </DataTable.Row>
      {
        showEditModal ?
          <Portal>
            <AdminGroupOrganizationUserRolesEditDialog
              visible={showEditModal}
              onClose={() => setShowEditModal(false)}
              onSave={handleEdit}
              userRoleAssertion={userRoleAssertion}
            />
          </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  cellWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Sizing.x5,
    flex: 1,
  },
})


