import { buildAddressDescription } from 'lib/addressHelpers'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import React, { useState } from 'react'
import { useGetNationalitiesQuery } from 'store/apiSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingClient } from 'store/tempDataSlice'

export const ClientVerification_01_Shortcut = ({ route, navigation }) => {
  const { nextScreen, client }: { nextScreen: string, client: ClientMeDto }   = route?.params || {}

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const dispatch = useAppDispatch()

  //Build autocomplete options, putting GB first
  const { data: nationalities, error, isLoading, refetch } = useGetNationalitiesQuery()

  const existingNationality = nationalities && client ? nationalities.find(nationality => {
    return nationality.id === client?.nationality
  }) : undefined
  
  const takeShortcut = () => {
    dispatch(updateWorkingClient({
      nationality: client?.nationality,
      addressDetail: client?.addressDetail,
      maritalStatus: client?.maritalStatus,
      employmentStatus: client?.employmentStatus,
      nationalInsuranceNo: client?.nationalInsuranceNo,
    }))
    hideDialog()
    navigation.navigate('Results')
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const tableData: UnborderedTableRow[] = [
    {
      label: `Nationality`,
      value: <Text style={{ fontWeight: '900', textAlign: 'right' }}>{existingNationality?.name}</Text>,
    },
    {
      label: `Address`,
      value: <Text style={{ fontWeight: '900', textAlign: 'right' }}>{buildAddressDescription(client?.addressDetail, false)}</Text>,
    },
    {
      label: `Marital Status`,
      value: <Text style={{ fontWeight: '900', textAlign: 'right' }}>{client?.maritalStatus}</Text>,
    },
    {
      label: `Employment Status`,
      value: <Text style={{ fontWeight: '900', textAlign: 'right' }}>{client?.employmentStatus}</Text>,
    },
    {
      label: `National Insurance No`,
      value: <Text style={{ fontWeight: '900', textAlign: 'right' }}>{formatNationalInsuranceNumber(client?.nationalInsuranceNo)}</Text>,
    },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Everything is correct'}
      buttonAction={showDialog}
      enableButton={true}
      headline={`Here are the details we already have`}
      subHeading={'Please check through carefully and confirm'}
      allowTextButton={true}
      textButtonTitle={`I need to adjust something`}
      textButtonAction={next}
    >
      <UnborderedTable
        data={tableData}
      />
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`These details are all correct - use them to attempt to verify my identity electronically`}
        onCancel={hideDialog}
        onConfirm={takeShortcut}
      />
    </ProcessScreen>
  )
}
