import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingRetirementAsset, workingClient } from 'store/tempDataSlice'

export const RetirementAssetTransfer_26_PersonalDetailsPreviousSurname = ({ route, navigation }) => {
  const { nextScreen, client, asset, isRetransfer }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const tracingRequested = workingRetirementAssetData?.tracingRequested

  //Setup form
  const formObj = useForm<{ previousSurname: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      previousSurname: workingClientData?.previousSurname || client?.previousSurname,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_name_confirm',
      isRetransfer,
      outcome: 'success',
      retirementAssetId: asset?.id
    })
    navigation.navigate(tracingRequested ? nextScreen : 'Result')
  }

  return (
    <ModalProcessScreen
      buttonTitle={tracingRequested ? `Next` : `Finish`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And your previous surname`}
      subHeading={`Again, the legal name by which the old provider would know you`}
    >
      <ManagedTextInput
        name={'previousSurname'}
        autoFocus={true}
        formObj={formObj}
        placeholder={'Enter previous surname'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }} />
    </ModalProcessScreen>
  )
}
