import { ScreenScrollFader } from 'components/Layout/ScreenScrollFader'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'

export type MainAppScreenProps = {
  children?: any
  contentStyle?: StyleProp<ViewStyle>
}

export const MainAppScreen = (props: MainAppScreenProps) => {
  const { children, contentStyle } = props

  const insets = useSafeAreaInsets()

  return (
    <>
      <View style={[
        backgroundStyles.pageContainer,
        backgroundStyles.dashboardBackground,
      ]}>
        <View style={[
          layoutStyles.mainContentContainer,
          {
            paddingBottom: insets.bottom,
          },
          contentStyle,
        ]}>
          <ScreenScrollFader backgroundColor={backgroundStyles.dashboardBackground.backgroundColor}/>
          {
            children
          }
        </View>
      </View>
    </>
  )
}
