import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Button } from 'components/Utility/Button'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGetStateBenefitQuery } from 'store/apiSlice'
import { ClientAnyDto } from 'store/dto/client.dto'
import { Colors } from 'styles'

export const RetirementIncomesAddStatePension_01_Income = ({ route, navigation }) => {
  const { nextScreen, onDismiss, client }: { nextScreen: string, onDismiss: any, client: ClientAnyDto }  = route?.params || {}
  
  const { data: stateBenefit, error, isLoading, refetch } = useGetStateBenefitQuery({
    birthDate: client?.birthDate,
    gender: client?.gender,
  })


  //Setup form
  const formObj = useForm<{ statePensionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionAmount: client?.statePensionAmount || stateBenefit?.maxAmount || 0,
    },
  })
  const { handleSubmit, trigger, watch, setValue, formState: { isValid} } = formObj

  //Set default values when stateBenefit is loaded
  useEffect(() => {
    if (stateBenefit) {
      setValue('statePensionAmount', stateBenefit?.maxAmount, { shouldDirty: true })
    }
  }, [stateBenefit])
  
  const onSubmit = attributes => {
    navigation.navigate(nextScreen, attributes)
  }

  const setFullAmount = () => {
    setValue('statePensionAmount', stateBenefit?.maxAmount, { shouldDirty: true })
    trigger('statePensionAmount')
  }

  const isFullAmount = watch('statePensionAmount') === stateBenefit?.maxAmount

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={refetch}
      isLoading={isLoading}
      buttonTitle={'Add State Pension'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How much ${client?.isSpouse ? `does ${client?.firstName}` : `are you`} expect to receive?`}
      subHeading={`The maximum amount ${client?.isSpouse ? `they` : `you`} could receive is ${formatCurrencyAmount(stateBenefit?.maxAmount)}`}
    >
      <ManagedCurrencyInput
        name={'statePensionAmount'}
        autoFocus={true}
        formObj={formObj}
        // label={'Eligible State Pension'}
        placeholder={'Expected amount'}
        required={true}
        minValue={1}
        maxValue={stateBenefit?.maxAmount}
        blurOnSubmit={false}
      />
      {
        !isFullAmount ?
        <Button color={Colors.brand.red3} mode='text' onPress={setFullAmount} >Set full amount</Button>
        : <></>
      }
    </ModalProcessScreen>
  )
}
