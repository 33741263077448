import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { SurplusStrategy } from 'providers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSuggestion } from 'store/tempDataSlice'

export const Contributions_30_StrategyChoiceIncreaseSurplus = ({ route, navigation }) => {
  const { nextScreen, existingTargetRetirementAge, existingRegularContribution } = route.params

  const existingRegularAmount = existingRegularContribution?.amount || 0

  const dispatch = useAppDispatch()

  const formObj = useForm<{ surplusStraregy: SurplusStrategy }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {}
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const strategyOptions: ManagedSimpleChoiceItem[] = [
    {
      value: SurplusStrategy.INCREASE_REGULAR,
      label: existingRegularAmount ? `Increase Monthly Contributions` : `Make Monthly Contributions`
    },
    {
      value: SurplusStrategy.LUMP_SUM,
      label: `Make a One-off Contribution`
    },
    {
      value: SurplusStrategy.RETIRE_LATER,
      label: `Retire Later than ${existingTargetRetirementAge}`
    },
  ]

  const onSubmit = (attributes) => {
    dispatch(updateWorkingSuggestion(attributes))
    navigation.navigate(nextScreen)
  }

  return (    
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`How do you want to create surplus capital?`}
      subHeading={'Jarvis can suggestions options based on your approach'}
    >  
    <ManagedSimpleChoiceInput
      name={'surplusStrategy'}
      formObj={formObj}
      options={strategyOptions}
      submitHandler={handleSubmit(onSubmit)}
      required={true}
    />
    </ProcessScreen>

  )
}