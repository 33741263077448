import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { PensionProviderModalContent, PensionProviderModalContentSection } from 'components/Utility/PensionProviderModalContent'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetRetirementAssetQuery, useGetUserFeaturesQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { ProviderInfoButton } from '../Components/ProviderInfoButton'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import { FeatureCode } from 'store/dto/feature.dto'
import { Text } from 'components/Typography/Text'
import { RaindropInfo } from 'components/Utility/RaindropInfo'
import { ampli } from 'src/ampli'

export const RetirementAssetTransfer_11_Reference = ({ route, navigation }) => {
  const { nextScreen, asset, isRetransfer }: { nextScreen: string, asset: RetirementAssetDto, isRetransfer: boolean }  = route?.params || {}
  const [showDialog, setShowDialog] = useState(false)

  const alreadyHadReference = asset?.externalProviderReference

  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()

  const [updateAssetReference, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = featuresError || latestAssetError || brandsError || providersError || updateAssetError
  const isLoading = featuresIsLoading || latestAssetIsLoading || latestAssetIsFetching || brandsIsLoading || providersIsLoading || updateAssetIsLoading
  
  const isWorkplace = asset?.assetType === RetirementAssetType.WORKPLACE

  const assetProvider = providers && latestAsset ? providers.find(provider => {
    return provider.id === latestAsset?.pensionProviderId
  }) : undefined

  const assetProviderPrimaryBrand = assetProvider && brands ? brands.find(brand => {
    return brand.key === assetProvider.primaryBrandKey
  }) : undefined

  const providerImageSource = assetProviderPrimaryBrand?.logo ? { uri: assetProviderPrimaryBrand?.logo } : require('assets/icons/default_provider.png')

  const referenceFormatInformation = assetProvider?.referenceFormatInformation
  const noFormatInformation = isEmpty(referenceFormatInformation)

  const referenceSummary: string[] = referenceFormatInformation?.summary ? referenceFormatInformation?.summary.split('|') : []
  const singleLineSummary = referenceSummary.length === 1

  //Setup form
  const formObj = useForm<{ externalProviderReference: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      externalProviderReference: workingRetirementAssetData?.externalProviderReference || asset?.externalProviderReference,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: false,
    }))
    //Skip save if no changed...
    if (asset?.externalProviderReference === attributes?.externalProviderReference) {
      end()
    } else {
      Logger.info(`Updating asset reference...`)
      updateAssetReference({
        id: asset?.id,
        tracingRequested: false,
        externalProviderReference: attributes?.externalProviderReference,
      })
    }
  }

  //Navigate on save result
  useEffect(() => {
    if (updatedAsset) {
      end()
    }
  }, [updatedAsset])

  const end = () => {
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_reference',
      isRetransfer,
      outcome: 'success',
      retirementAssetId: asset?.id
    })
    navigation.navigate(nextScreen)
  }

  const goToResult = () => {
    setShowDialog(false)
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_reference',
      isRetransfer,
      outcome: 'fail',
      retirementAssetId: asset?.id
    })
    navigation.navigate('Result')
  }

  //Previously traced if has any sort of tracingStatus
  const previouslyTraced = asset?.tracingStatus
  const tracingEnabledForUser = featureIsEnabledForUser(features, FeatureCode.TRACING)
  const tracingRouteAvailable = tracingEnabledForUser && !previouslyTraced && !isRetransfer

  const goToTracing = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: true,
    }))
    setShowDialog(false)
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_reference',
      isRetransfer,
      outcome: 'to_tracing',
      retirementAssetId: asset?.id
    })
    navigation.navigate('PersonalDetailsIntro')
  }

  const externalProviderReference = watch('externalProviderReference')
  const noChange = externalProviderReference && externalProviderReference === asset?.externalProviderReference

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : undefined}
      error={error}
      errorTryAgain={latestAssetError ? refetchLatestAsset : !features ? refetchFeatures : !brands ? refetchBrands : !providers ? refetchProviders : handleSubmit(onSubmit)}
      buttonTitle={isRetransfer
        ? noChange
          ? `Yes, It's Correct`
          : `Change Reference`
        : `Confirm`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={isRetransfer
        ? 'Is your pension reference correct?'
        : alreadyHadReference
          ? 'Confirm your pension reference'
          : `What's your pension reference?`
      }
      subHeading={isRetransfer
        ? `Please check you have entered it exactly as shown by your old provider`
        : `We need to identify your reference number for ${assetProvider?.name}`
      }
      allowTextButton={!externalProviderReference}
      textButtonTitle={isRetransfer ? `I don't know it` : `I can't find it`}
      textButtonAction={() => setShowDialog(true)}
    >
      <ManagedTextInput
        name={'externalProviderReference'}
        autoFocus={false}
        formObj={formObj}
        returnKeyType={'next'}
        placeholder={'Enter reference'}
        autoCapitalize={'characters'}
        blurOnSubmit={true}
        informationMessage={noFormatInformation
          ? `Typically 6-16 characters long, and could contain numbers and/or letters, sometimes with separating dashes or slashes`
          : singleLineSummary
            ? referenceSummary[0]
            : `See below for typical formats`
        }
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      {
        assetProvider ?
          <View style={{ paddingTop: Sizing.x20 }}>
            <ProviderInfoButton
              modalContent={<PensionProviderModalContent
                provider={assetProvider}
                imageSource={providerImageSource}
                sections={[
                  PensionProviderModalContentSection.REFERENCE,
                  PensionProviderModalContentSection.CONTACT,
                  PensionProviderModalContentSection.GENERAL,
                ]}
              />}
            >{`Reference & Contact Details`}</ProviderInfoButton>
            <Paragraph>{`You can also check any recent emails or documents for your reference number, or contact the provider directly.`}</Paragraph>
            <Image source={providerImageSource} style={{
              width: scaleNormalizer(70),
              height: scaleNormalizer(70),
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
          </View>
          : <></>
      }
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={tracingRouteAvailable ? 'We can find your pension for you!' : 'We need your pension reference'}
        illustrationFilename={'support.png'}
        content={tracingRouteAvailable
          ? <Text>
              {`The fastest way to get your ${isWorkplace ? 'workplace' : 'personal'} pension transferred is to find the details from any emails/documents, or by contacting your old employer.\n\nHowever, we understand this isn't always possible or easy, so our team of experts can help find your pension for you, based on a few simple questions.\n\n`}
              <RaindropInfo />
            </Text>
          : isRetransfer
            ? 'We can only reattempt the transfer if we know your personal pension reference.\n\nIf you want to give up on the transfer, close this process and choose to Abandon Transfer from the main list.'
            : 'We can only transfer your pension if we know your personal pension reference.\n\nIf you prefer, you can save here and come back later once you have the details.'
        }
        confirmLabel={tracingRouteAvailable ? `Use Finding Service` : isRetransfer ? `Close` : `Save Here`}
        cancelLabel={`Try again`}
        onConfirm={tracingRouteAvailable ? goToTracing : goToResult}
      />
    </ModalProcessScreen>
  )
}
