import { ProcessScreen } from 'components/Layout'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { InformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { loginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { Sizing } from 'styles'

export const Authentication_10_HandOffIntro = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}

  const loginConfig = useAppSelector(loginParams)
  const { authType } = loginConfig || {}
  const isLogin = authType === AUTHTYPE.LOGIN

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const informationTexts: string[] = [
    `The Jarvis App is available for Android and iOS devices and gives you everything you need to plan and prepare for your retirement in the palm of your hand.`,
    `We will soon be launching our equivalent web app for use in the browser, but we're just applying the final touches.`,
    `We can transition you to our Jarvis App in just a couple of clicks by scanning a QR Code here, and then confirming your email on your mobile device.`,
  ]

  return (
    <ProcessScreen
      showButton={true}
      buttonTitle={`Let's Go!`}
      buttonAction={next}
      enableButton={true}
      headline={isLogin
        ? `Ok, let's get you transitioned to our mobile app`
        : `Ok, let's get you started with our mobile app`
      }
      subHeading={isLogin
        ? `We can get you logged in with couple of clicks`
        : `We can get the app downloaded and your account created in a couple of clicks`
      }
      subHeadingInfo={<InformationButton title={`Jarvis Mobile App`} texts={informationTexts} illustrationFilename={'store_assets_and_incomes.png'} buttonTitle={`What does this mean?`} />}
    >
      <AppIllustration
        filename={'store_budget.png'}
        style={{
          marginTop: Sizing.x20,
          width: Sizing.x200,
          height: Sizing.x200,
          alignSelf: 'center',
        }}
        resizeMode={'contain'}
      />
    </ProcessScreen>
  )
}
