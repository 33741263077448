import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from "store/dto/account.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from "store/tempDataSlice"
import { SchemeEnrolmentChangeForecastTreatment_00_Intro, SchemeEnrolmentChangeForecastTreatment_01_Choice, SchemeEnrolmentChangeForecastTreatment_02_CustomFrequency, SchemeEnrolmentChangeForecastTreatment_99_Result } from "./Screens"
import { SchemeEnrolmentChangeForecastTreatment_03_CustomEmployerAmount } from "./Screens/SchemeEnrolmentChangeForecastTreatment_03_CustomEmployerAmount"

export type SchemeEnrolmentChangeForecastTreatmentProcessStackProps = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  onDismiss: any
  resultHandlerFunction?: any  
}

export const SchemeEnrolmentChangeForecastTreatmentProcessStack = (props: SchemeEnrolmentChangeForecastTreatmentProcessStackProps) => {
  const { enrolment, onDismiss, resultHandlerFunction } = props

  const dispatch = useAppDispatch()

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingSchemeEnrolmentRequest(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={!!enrolment}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Discard this request?`}
      processTitle='Request Contribution Amount'
      initialRouteName='Intro'
      initialParams={{
        onDismiss,
        resultHandlerFunction,
        enrolment,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Request',
          component: SchemeEnrolmentChangeForecastTreatment_00_Intro,
        },
        {
          navigationId: 'Choice',
          title: 'Choice',
          component: SchemeEnrolmentChangeForecastTreatment_01_Choice,
        },
        {
          navigationId: 'CustomFrequency',
          title: 'CustomFrequency',
          component: SchemeEnrolmentChangeForecastTreatment_02_CustomFrequency,
        },
        {
          navigationId: 'CustomAmount',
          title: 'CustomAmount',
          component: SchemeEnrolmentChangeForecastTreatment_03_CustomEmployerAmount,
        },
        {
          navigationId: 'Results',
          title: '',
          component: SchemeEnrolmentChangeForecastTreatment_99_Result,
        },
      ]}
    />
  )
}

