import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { employerAppNavigate } from 'lib/RootNavigation'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { countBy, keys, concat } from 'lodash'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetCurrentUserQuery, useGetGroupSchemeJobsQuery, useGetGroupSchemePaymentsQuery, useGetGroupSchemeRequestsQuery } from 'store/apiSlice'
import { GroupSchemeRequestStatus } from 'store/dto/account.dto'
import { useAppSelector } from 'store/hooks'
import { currentGroupScheme } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'
import { GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { userCanConfirmPaymentsForScheme, userCanExecutePaymentsForScheme, userCanManageJobsForScheme, userCanManageRequestsForScheme } from 'lib/groupSchemeHelpers'

export const EmployerDashboardScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)

  const userCanManageRequests = userCanManageRequestsForScheme(currentScheme)
  const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  const userCanConfirmPayments = userCanConfirmPaymentsForScheme(currentScheme)
  const userCanExecutePayments = userCanExecutePaymentsForScheme(currentScheme)
  const userCanUtilizePayments = userCanConfirmPayments || userCanExecutePayments

  const [refreshing, setRefreshing] = useState(false)

  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()

  const { data: requests, isLoading: requestsIsLoading, isFetching: requestsIsFetching, error: requestsError, refetch: refetchRequests } = useGetGroupSchemeRequestsQuery({
    groupSchemeId: currentScheme?.id,
    status: GroupSchemeRequestStatus.PENDING, 
  }, { skip: !currentScheme || !userCanManageRequests })
  
  const { data: enrolmentJobs, isLoading: enrolmentJobsIsLoading, isFetching: enrolmentJobsIsFetching, error: enrolmentJobsError, refetch: refetchEnrolmentJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.MEMBER,
  }, { skip: !currentScheme || !userCanManageJobs })
  
  const { data: contributionJobs, isLoading: contributionJobsIsLoading, isFetching: contributionJobsIsFetching, error: contributionJobsError, refetch: refetchContributionJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.CONTRIBUTION,
  }, { skip: !currentScheme || !userCanManageJobs })
  
  const { data: payments, isLoading: paymentsIsLoading, isFetching: paymentsIsFetching, error: paymentsError, refetch: refetchPayments } = useGetGroupSchemePaymentsQuery({
    groupSchemeId: currentScheme?.id,
    incomplete: true
  }, { skip: !currentScheme || !userCanUtilizePayments })

  const isLoading = !currentScheme || requestsIsLoading || enrolmentJobsIsLoading || contributionJobsIsLoading || paymentsIsLoading || userIsLoading
  const isFetching = requestsIsFetching || enrolmentJobsIsFetching || contributionJobsIsFetching || paymentsIsFetching
  const error: any = requestsError || enrolmentJobsError || contributionJobsError || paymentsError || userError

  const { colors: themeColors } = Paper.useAppTheme()

  const onRefresh = async () => {
    setRefreshing(true)
    refetchRequests()
    refetchEnrolmentJobs()
    refetchContributionJobs()
    refetchPayments()
    refetchUser()
    setRefreshing(false)
  }

  const enrolmentJobCounts = enrolmentJobs ? countBy(enrolmentJobs, 'status') : {}
  const contributionJobCounts = contributionJobs ? countBy(contributionJobs, 'status') : {}
  const paymentCounts = payments ? countBy(payments, 'status') : {}
  const requestCounts = requests ? countBy(requests, 'requestType') : {}

  const convertCountsToTableData = countData => {
    return keys(countData).map(key => {
      return {
        label: key,
        count: countData[key],
      }
    })
  }

  const statsList = () => {
    return concat(
      userCanManageJobs ? [
        { title: 'Active Enrolment Jobs', data: [{ tableData: convertCountsToTableData(enrolmentJobCounts), linkTitle: 'Go to Enrolments', linkScreen: EmployerAppNavScreen.ENROLMENTS }] },
        { title: 'Active Contribution Jobs', data: [{ tableData: convertCountsToTableData(contributionJobCounts), linkTitle: 'Go to Contributions', linkScreen: EmployerAppNavScreen.CONTRIBUTIONS }] },
      ] : [],
      userCanUtilizePayments ? [
        { title: 'Current Payments', data: [{ tableData: convertCountsToTableData(paymentCounts), linkTitle: 'Go to Payments', linkScreen: EmployerAppNavScreen.PAYMENTS }] },
      ] : [],
      userCanManageRequests ? [
        { title: 'Open Member Requests', data: [{ tableData: convertCountsToTableData(requestCounts), linkTitle: 'Go to Requests', linkScreen: EmployerAppNavScreen.REQUESTS }] },
      ] : [],
    )
  }

  const renderItem = ({ item }) => {
    let total = 0
    const data: UnborderedTableRow[] = []
    if (item?.tableData) {
      item.tableData.forEach(row => {
        total += row.count
        data.push({
          label: row.label,
          value: row.count,
          linkFunction: item?.linkScreen ? () => employerAppNavigate(item?.linkScreen) : undefined,
        })
      })
      data.push({
        label: 'Total',
        value: total,
        isTotal: true,
        linkFunction: item?.linkScreen ? () => employerAppNavigate(item?.linkScreen) : undefined,
      })
    }
    return (
      <View style={{ paddingBottom: Sizing.x30 }}>
        <UnborderedTable
          data={data}
        />
        { item?.linkScreen ?
          <Button
            mode='text'
            onPress={() => employerAppNavigate(item?.linkScreen)}
            color={themeColors.accent}
          >
            {item?.linkTitle || 'Go'}
          </Button>
          : <></>
        }
      </View>
    )
  }

  return (
    <MainAppScreen>
      {
        isLoading || isFetching ? <Loading message={['Preparing your dashboard...']} /> : error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data} /> :
          <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              <OptionalSectionList
                sections={statsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={``}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
