export interface NationalityDto {
  id: string
  type: string
  name: string
  adjective: string
}

export interface StateBenefitDto {
  id: string
  type: string
  age: number
  maxAmount: number
}

export interface StatePensionDto {
  id: string
  fromDate: string
  toDate: string
  maxAmount: number
}


export interface LifeExpectancyDto {
  id: string
  type: string
  age: number
}

export interface BudgetDto {
  id: number
  type: string
  name: string
  levels: BudgetLevel[]
}

export interface BudgetLevel {
  id: BudgetLevelIdentifier
  name: string
  value: number
  summary: string
  description: string
}

export enum BudgetLevelIdentifier {
  MINIMUM = 'minimum',
  MODERATE = 'moderate',
  COMFORTABLE = 'comfortable',
  CUSTOM = 'custom',
}

export interface CedingProviderDto {
  id: string
  name: string
  status: string
  supportsOrigo: boolean
  origoId: string
}

export interface EmployerDto {
  identifier: string
  legalName: string
  isLocalAuthority: boolean
}

export interface ContributionBankAccountDto {
  id: string
  type: string
  sortCode: string
  accountNumber: string
}

export interface SampleFileDto {
  id: string
  type: string
  downloadUrl: string
}

export interface SampleFileDto {
  id: string
  type: string
  downloadUrl: string
}
