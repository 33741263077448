import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'

import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { goToMainAppStack } from 'lib/RootNavigation'
import { formatNationalInsuranceNumber, unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { JAR_NAME_ALL, NATIONAL_INSURANCE_NO_MASK } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import { useCheckPendingEnrolmentNiNumberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const SchemeEnrolmentSetup_02_Verify = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}
  
  const { groupScheme } = enrolment || {}
  const { organizationName } = groupScheme || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
  const [matches, setMatches] = useState(undefined)

  const dispatch = useAppDispatch()
  // const [cursorPosition, setCursorPosition] = useState(0)

  //Setup form
  const formObj = useForm<{ nationalInsuranceNo: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: workingSetupSchemeEnrolmentData?.nationalInsuranceNo
        ? formatNationalInsuranceNumber(workingSetupSchemeEnrolmentData?.nationalInsuranceNo)
        : formatNationalInsuranceNumber(client?.nationalInsuranceNo)
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo)
    }))
    navigation.navigate(nextScreen)
  }

  //Check the NI number matches only if 9 characters
  const { data: niCheckResult, error, isLoading, isFetching, refetch } = useCheckPendingEnrolmentNiNumberQuery({
    id: enrolment.id,
    nationalInsuranceNo: niNumberToCheck,
  }, { skip: !niNumberToCheck})
  
  const nationalInsuranceNo = watch('nationalInsuranceNo')
  
  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setMatches(undefined)
    setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
  }, [nationalInsuranceNo])

  //Force refetch when NI number to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (niNumberToCheck) {
      refetch()
    }    
  }, [niNumberToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (error || isLoading || isFetching) {
      setMatches(undefined)
    } else if (niCheckResult) {
      setMatches(niCheckResult.matches)
    }
  }, [niCheckResult, error, isLoading, isFetching])

  //Trigger validation when available changes
  useEffect(() => {
    trigger('nationalInsuranceNo')
  }, [matches])

  const isValidNiNumber = (value: string) => {
    const result = value.match(/^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-D]$/)
    return result ? true : 'Invalid NI Number - please double check'
  }

  const isMatchingNiNumber = () => {
    if (matches === undefined) {
      return 'Validating...'
    }
    return matches ? true : `No match.`
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Please verify your identity`}
      subHeading={`To link this employer, please confirm your National Insurance Number`}
      allowTextButton={matches === false}
      textButtonTitle={`Back to Dashboard`}
      textButtonAction={goToMainAppStack}
    >
      <ManagedTextInput
        name={'nationalInsuranceNo'}
        autoFocus={true}
        formObj={formObj}
        // label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        // onSelectionChange={(event) => {
        //   if (event?.nativeEvent?.selection?.start) {
        //     setCursorPosition(event?.nativeEvent?.selection?.start)
        //   }
        // }}
        // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
        // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
        autoCapitalize={'characters'}
        forceCapitals={true}
        blurOnSubmit={true}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
            isMatchingNiNumber,
          }
      }} />
      {
        matches === false ?
        <Paragraph>{`The National Insurance Number you have entered does not match the one provided by ${organizationName}. If you are sure that you have entered it correctly, please contact them to check their records.`}</Paragraph>
        : <></>
      }
    </ProcessScreen>
  )
}
