import { ManagedProportionInput } from 'components/Inputs/ManagedProportionInput'
import { ModalProcessScreen } from 'components/Layout'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { concat, fill, floor, last, round } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetAssetsQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAssetAllocations, workingAssetAllocations } from 'store/tempDataSlice'

export const InvestmentAssetAdd_04_Adjust = ({ route, navigation }) => {
  const { nextScreen, onDismiss } = route?.params || {}
  const dispatch = useAppDispatch()
  const workingAssetAllocationsData = useAppSelector(workingAssetAllocations)

  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()

  const { existingAllocations, newAllocation } = workingAssetAllocationsData || {}

  const newAllocationAsset = assets ? assets.find(asset => {
    return asset.id === newAllocation?.assetId
  }) : undefined
  const newAllocationAssetName = newAllocationAsset ? newAllocationAsset?.shortName : 'Unknown'

  //Create array of proportion values, adding zero for the new allocation
  const startingProportions = existingAllocations ? concat(existingAllocations.map(allocation => {
    return allocation.proportion
  }), [0]) : []

  const labels = assets && existingAllocations ? concat(existingAllocations.map(allocation => {
    const asset = assets.find(asset => {
      return asset.id === allocation.assetId
    })
    return asset?.shortName || `Unknown`
  }), [newAllocationAssetName]) : []

  const formObj = useForm<{ proportions: number[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proportions: startingProportions || [],
    }
  })
  const { getValues, setValue, handleSubmit, trigger, watch, formState: { isValid } } = formObj

  const equalizeValues = () => {
    const proportions = watch('proportions')
    const length = proportions?.length || 0
    const otherLength = length - 1
    if (otherLength < 1) {
      return
    }
    const equalShare = floor((1 / length), 2) // Nearest whole number
    const lastShare = 1 - (equalShare * otherLength)
    const newProportions = concat(fill(Array(otherLength), equalShare), [lastShare])
    setValue('proportions', newProportions )
    trigger('proportions')
  }

  const onSubmit = attributes => {
    const updatedExistingAllocations = existingAllocations ? existingAllocations.map((allocation, idx) => {
      return {
        ...allocation,
        proportion: round(attributes.proportions[idx], 2),
      }
    }) : []

    //Update the working data
    dispatch(updateWorkingAssetAllocations({
      existingAllocations: updatedExistingAllocations,
      newAllocation: {
        assetId: newAllocation?.assetId,
        proportion: round(last(attributes.proportions),2)
      },
    }))

    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      isLoading={assetsIsLoading}
      loadingMessage={['Loading funds...']}
      error={assetsError}
      errorTryAgain={refetchAssets}
      errorCancel={onDismiss}
      buttonTitle={'Set Proportions'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`Decide how much each will receive`}
      allowTextButton={true}
      textButtonAction={equalizeValues}
      textButtonTitle={'Equalize Proportions'}
    >
      <ManagedProportionInput
        formObj={formObj}
        name={'proportions'}
        labels={labels}
        labelStyle={{
          fontSize: scaleNormalizer(15),
        }}
        labelsNumberOfLines={2}
        minValue={0.01}
        maxValue={1}
        informationMessage={'Overall total should be 100%'}
      />
    </ModalProcessScreen>
  )
}

