import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { RetirementLifestyleScreen } from './Screens/RetirementLifestyleScreen'

export const RetirementLifestyleSectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={`Retirement Lifestyle`}
    >
      <RetirementLifestyleScreen />
    </SectionSimpleContainer>
  )
}