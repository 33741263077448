import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedAutoCompleteMultipleInput } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedSimpleChoiceInput } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { PensionProviderContactDetailSet } from 'components/Utility/PensionProviderContactDetailSet'
import { PensionProviderReferenceFormatInformationSet } from 'components/Utility/PensionProviderReferenceFormatInformationSet'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { compact, orderBy } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddPensionProviderMutation, useGetPensionBrandsQuery } from 'store/apiSlice'
import { PensionProviderContactDetail, PensionProviderReferenceFormatDetail, PensionProviderRelationship, PensionProviderRelationshipType, WrappedPensionProviderContactDetail, WrappedPensionProviderReferenceFormatDetail } from 'store/dto/pension-provider.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddPensionProviderVisible, showAdminAddPensionProviderVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'

enum SuggestionPensionTypeChoice {
  PERSONAL = 'Personal Pensions Only',
  WORKPLACE = 'Workplace Pensions Only',
  BOTH = 'Both',
  NEITHER = 'Neither',
} 

export const AddPensionProvidersModal = () => {
  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()

    const [addPensionProviderMutation, { data: addedPensionProvider, isLoading: addPensionProviderIsLoading, error: addPensionProviderError, reset: addPensionProviderReset }] = useAddPensionProviderMutation()
  
    const { data: brands, isLoading: brandsIsLoading, error: brandsError, refetch: refetchBrands } = useGetPensionBrandsQuery()
  
    //Show required fields when brands loaded
    useEffect(() => {
      if (brands) {
        trigger()
      }
    }, [brands])

    const formObj = useForm<{
      origoId: string,
      name: string,
      description: string,
      notes: string,
      primaryBrandKey: string,
      suggestionPensionType: SuggestionPensionTypeChoice,
      websiteUrl?: string,
      relationshipKeys?: string[],
      contactInformationNotes: string,
      wrappedContactDetails: WrappedPensionProviderContactDetail[]
      referenceFormatInformationSummary: string,
      wrappedReferenceInformationFormats: WrappedPensionProviderReferenceFormatDetail[]
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        suggestionPensionType: SuggestionPensionTypeChoice.BOTH,
        relationshipKeys: [],
        wrappedContactDetails: [{ data: {}}],
        referenceFormatInformationSummary: '',
        wrappedReferenceInformationFormats: [{ data: {}}],
      },
    })
  
    //Form refs for focussing
    const origoIdRef = useRef(null)
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
    const notesRef = useRef(null)
    const websiteUrlRef = useRef(null)
    const contactInformationNotesRef = useRef(null)
    const referenceFormatInformationSummaryRef = useRef(null)
  
    const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    const onSubmit = async attributes => {
      const {
        relationshipKeys,
        contactInformationNotes,
        wrappedContactDetails,
        referenceFormatInformationSummary,
        wrappedReferenceInformationFormats,
        suggestionPensionType,
        ...remaining
      } = attributes
      const relationships: PensionProviderRelationship[] = relationshipKeys.map(key => {
        return {
          relationshipType: PensionProviderRelationshipType.BRAND,
          source: 'internal',
          sourceId: key,
        }
      })
      const suggestionsAttributes = {
        isEnabled: suggestionPensionType !== SuggestionPensionTypeChoice.NEITHER,
        providesWorkplacePensions: [SuggestionPensionTypeChoice.NEITHER, SuggestionPensionTypeChoice.WORKPLACE].includes(suggestionPensionType),
        providesPersonalPensions: [SuggestionPensionTypeChoice.NEITHER, SuggestionPensionTypeChoice.PERSONAL].includes(suggestionPensionType),
      }
      const contactDetails: PensionProviderContactDetail[] = compact(wrappedContactDetails.map(wrapped => {
        const { telephone, email, contactUrl, portalUrl, notes } = wrapped?.data || {}      
        return telephone || email || contactUrl || portalUrl || notes ? wrapped.data : undefined
      }))
      const formats: PensionProviderReferenceFormatDetail[] = compact(wrappedReferenceInformationFormats.map(wrapped => {
        const { pattern, example } = wrapped?.data || {}      
        return pattern && example ? wrapped.data : undefined
      }))
      addPensionProviderMutation({
        relationships,
        contactInformation: {
          notes: contactInformationNotes,
          contactDetails,
        },
        referenceFormatInformation: {
          summary: referenceFormatInformationSummary,
          formats,
        },
        ...suggestionsAttributes,
        ...remaining
      })
    }
  
    useEffect(() => {
      if (addedPensionProvider) {
        close()
      }
    }, [addedPensionProvider])
  
    const close = () => {
      dispatch(setShowAdminAddPensionProviderVisible(false))
    }
  
    const isLoading = brandsIsLoading || addPensionProviderIsLoading
    const error: any = brandsError || addPensionProviderError
  
    //Build autocomplete options
    const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
      return {
        value: brand.key,
        label: brand.name,
        description: brand.description,
        icon: brand?.logo
        ? () => <Image source={{ uri: brand?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
      }
    }) : []
  
    const suggestionPensionType = watch('suggestionPensionType')
  
    return (
      <ModalEditScreen
        formTitle='Add New Pension Provider'
        onDismiss={() => dispatch(setShowAdminAddPensionProviderVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new pension provider?'}
        error={error}
        errorTryAgain={addPensionProviderError ? handleSubmit(onSubmit) : refetchBrands}
        errorCancel={addPensionProviderError ? addPensionProviderReset : close}
        isLoading={isLoading}
        loadingMessage={['Saving provider...']}
        buttonTitle={'Create Pension Provider'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => descriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 100,
        }}/>
        <ManagedTextInput
          ref={descriptionRef}
          name={'description'}
          formObj={formObj}
          label={'Description'}
          placeholder={'Optional description shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => origoIdRef.current?.focus()}
          rules={{
            required: false,
            minLength: 2,
            maxLength: 100,
        }}/>
        <ManagedTextInput
          ref={origoIdRef}
          name={'origoId'}
          formObj={formObj}
          label={'Origo ID'}
          placeholder={'Numeric Origo ID'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => notesRef.current?.focus()}
          rules={{
            required: true,
            minLength: 1,
            maxLength: 6,
          }}/>
        <ManagedTextInput
          ref={notesRef}
          name={'notes'}
          formObj={formObj}
          label={'Notes (internal)'}
          placeholder={'Internal notes'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 1,
            maxLength: 200,
        }}/>
        <ManagedAutoCompleteInput
          name={'primaryBrandKey'}
          formObj={formObj}
          label={'Primary Pension Brand'}
          modalTitle={'Select Brand'}
          placeholder={'Select a pension brand to use for logo'}
          searchPlaceholder={'Search for a pension brand'}
          dataSet={brandOptions}
          required={true}
        />
        <Subheading>{'Brand Linkage'}</Subheading>
        <ManagedSimpleChoiceInput
          name={'suggestionPensionType'}
          formObj={formObj}
          options={enumToAutocompleteOptions(SuggestionPensionTypeChoice)}
          required={true}
        />
        <ManagedAutoCompleteMultipleInput
          name={'relationshipKeys'}
          formObj={formObj}
          label={'Linked Pension Brands'}
          modalTitle={'Select brands'}
          placeholder={'Select linked pension brands'}
          searchPlaceholder={'Search for pension brands'}
          dataSet={brandOptions}
          required={false}
          disabled={suggestionPensionType === SuggestionPensionTypeChoice.NEITHER}
        />
        <Subheading>{'Contact Information'}</Subheading>
        <ManagedTextInput
          ref={websiteUrlRef}
          name={'websiteUrl'}
          formObj={formObj}
          label={'Website URL'}
          placeholder={'Link to provider website'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => contactInformationNotesRef.current?.focus()}
          autoCapitalize={'none'}
          keyboardType={'url'}
          rules={{
            pattern: {
              value: /^(http|https):\/\/[^ "]+$/,
              message: "Invalid URL (include http/https)"
            },
            required: false,
          }}/>
        <ManagedTextInput
          ref={contactInformationNotesRef}
          name={'contactInformationNotes'}
          formObj={formObj}
          label={'Notes'}
          placeholder={'Contact information notes visible to end users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoCapitalize={'none'}
          keyboardType={'url'}
          rules={{
            required: false,
            minLength: 1,
            maxLength: 200,
          }}/>
          <PensionProviderContactDetailSet
            formObj={formObj}
            name={'wrappedContactDetails'}
          />
          <Subheading>{'Reference Format Information'}</Subheading>
          <ManagedTextInput
            ref={referenceFormatInformationSummaryRef}
            name={'referenceFormatInformationSummary'}
            formObj={formObj}
            label={'Format Summary'}
            placeholder={'Summary information describing the potential format or formats of pension references.'}
            returnKeyType={'next'}
            blurOnSubmit={false}
            rules={{
              required: false,
              minLength: 1,
              maxLength: 500,
            }}/>
          <PensionProviderReferenceFormatInformationSet
            formObj={formObj}
            name={'wrappedReferenceInformationFormats'}
          />
      </ModalEditScreen>
    )
  }
  
  