import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getModelChangeMessages } from 'lib/loadingHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import { concat } from 'lodash'
import { useGuidanceContext } from 'providers'
import React from 'react'
import { useGetInvitesQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { InviteStatus } from 'store/dto/invite.dto'
import { Typography } from 'styles'

export const OnboardingPhaseContributeHeroScreen = ({ route, navigation }) => {

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching, currentViableAgeError, refetchCurrentViableAge } = useGuidanceContext()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching, refetch: refetchProfile } = useGetRetirementProfileQuery()

  const isLoading = currentViableAgeIsLoading || currentViableAgeIsFetching || rpIsLoading
  const error: any = currentViableAgeError
  
  const targetRetirementAge = retirementProfile?.targetRetirementAge

  const targetRetirementAgeIsMet = currentViableAge?.output?.targetRetirementAgeIsMet

  const carouselScreens: ProcessHeroCarouselData[] = concat([
    {
      illustrationFilename: 'thanks.png',
      headline: `Great work!`,
      subHeading: `You're all set up to contribute to your future`,
    },
  ],
  currentViableAge ?
    targetRetirementAgeIsMet ? [
      {
        illustrationFilename: 'relax_for_now.png',
        headline: `You're already on track!`,
        subHeading: `With your Target Retirement Age predicted to be met, you can think about the next steps`,
      },
      {
        illustrationFilename: 'gamified_approach.png',
        headline: `Look out for Suggestions`,
        subHeading: `Keep an eye on your Jarvis Dashboard for suggestions on how to save more or retire earlier`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.GOALS} buttonTitle={'About Jarvis Goals'} />
      },
    ]
    :
    [
      {
        illustrationFilename: 'clock.png',
        headline: `There's still a little more to do...`,
        subHeading:
          <Subheading>
            {`Your current plan doesn't yet get you to your target age of `}
            <Subheading style={Typography.fontWeight.bold}>{targetRetirementAge}</Subheading>
          </Subheading>,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.TARGET_RETIREMENT_AGE} buttonTitle={'About Target Age'} />
      },
      {
        illustrationFilename: 'path_to_freedom.png',
        headline: `Keep refining to get there`,
        subHeading: `Keep an eye on your Jarvis Dashboard for suggestions on how to close the gap`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.GOALS} buttonTitle={'About Jarvis Goals'} />
      },
    ]
  : [
    {
      illustrationFilename: 'regular_contributions.png',
      headline: `You've made a good start`,
      subHeading: `By making your first contribution, you've cleared the first hurdle`,
    },
    {
      illustrationFilename: 'financial_forecasting.png',
      headline: `But don't stop now!`,
      subHeading: `Complete the 'Plan Your Retirement' card to unlock Jarvis' superpowers`,
    },
    {
      illustrationFilename: 'gamified_approach.png',
      headline: `Know exactly how much to save`,
      subHeading: `Jarvis can help you achieve your retirement goals, showing you the optimum amount to save`,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.FINANCIAL_FORECASTING} buttonTitle={'What do you mean?'} />
    },
  ])

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      loadingMessage={getModelChangeMessages()}
      error={error}
      errorTryAgain={refetchCurrentViableAge}
      errorCancel={goToDashboard}
      carouselScreens={carouselScreens}
      onFinish={goToDashboard}
      finishButtonLabel={'Return to my Dashboard'}
    />
  )
}
