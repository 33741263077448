import { intersection, isArray, keys, merge, some } from 'lodash'
import { RootState } from 'store'
import { envVariables } from 'environment'
import { pickBy, identity } from 'lodash'

export const buildQuery = (data) => {
	return new URLSearchParams(pickBy(data, identity)).toString()
}

export const entityContainsAttributes = (entity: any, attributes: string[]) => {
  return (some(intersection(attributes, keys(entity))))
}

export const addHeaders = (headers, { getState }) => {
  const auth = (getState() as RootState).auth
  const token = auth?.user?.token
  const impersonation = auth?.user?.impersonation
  const clientHash = auth?.clientHash
  if (token) {
    // Logger.debug({ token }, 'Token used in addHeaders')
    headers.set('authorization', `Bearer ${token}`)
  }
  //Client hasj for semi-public endpoints
  if (clientHash) {
    headers.set('x-api-key', clientHash)
  }
  //Impersonation
  if (impersonation) {
    headers.set('impersonate-user-id', impersonation?.userId)
  }
  return headers
}

//Returns flattened structures from JSONAPI structure, either an array or single
export const extractData = (response) => {
  const data = response?.data
  if (!data) {
    return {}
  }
  return isArray(data) ? data.map(item => {
    return {
      id: item.id,
      type: item.type,
      ...item.attributes,
    }
  }) : {
    id: data.id,
    type: data.type,
    ...data.attributes,
  }
}

//Creates JSONAPI compliant structure for sending to backend, either array or single
export const wrapData = (attributes, type) => {
  return {
    data: isArray(attributes)
    ? attributes.map(a => {
        return {
          type,
          attributes,
        }
      })
    : {
        type,
        attributes: attributes || {},
      }    
  }
}

export const addEntityToList = (draft: any[], entity: any) => {
  Object.assign(draft, draft.push(entity))
}

export const addEntity = (draft: any, create: any) => {
  Object.assign(draft, create)
}

export const mergeEntity = (draft: any, update: any) => {
  const merged = merge(draft, update)
  Object.assign(draft, merged)
}

export const updateEntityInListById = (draft: any[], entity: any) => {
  const modifiedDraft = draft.map(item => {
     return item.id === entity.id ? {
      ...item,
      ...entity,
    } : item
  })
  Object.assign(draft, modifiedDraft)
}

export const removeEntityInListById = (draft: any[], entity: any) => {
  const modifiedDraft = draft.filter(item => {
     return item.id !== entity.id
  })
  Object.assign(draft, modifiedDraft)
}

export const createOptimisticBaseDatedEntity = (attributes: any, additional?: any) => {
  const extraAttributes = additional || {}
  return {
    id: undefined,
    createdAt: undefined,
    udpatedAt: undefined,
    ...attributes,
    ...extraAttributes,
  }
}