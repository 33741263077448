import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetOpenSchemeInviteByIdQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EntryPointSchemeInviteScreen = ({ route, navigation }) => {
  const { preferredSchemeInviteId } = route.params || {}

  const [showDownloadModal, setShowDownloadModal] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const dispatch = useAppDispatch()

  const { data: openSchemeInvite, isLoading: openSchemeInviteIsLoading, isFetching: openSchemeInviteIsFetching, error: openSchemeInviteError } = useGetOpenSchemeInviteByIdQuery(preferredSchemeInviteId, { skip: !preferredSchemeInviteId })
  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  //Set loginParams emailAddress when get an openSchemeInvite without a user
  useEffect(() => {
    if (openSchemeInvite && userError) {
      dispatch(setLoginParams({
        emailAddress: openSchemeInvite?.email,
      }))
    }
  }, [openSchemeInvite, userError])

  const { groupScheme } = openSchemeInvite || {}
  const { logo, organizationDisplayName } = groupScheme || {}

  const isLoading = openSchemeInviteIsLoading || openSchemeInviteIsFetching || userIsLoading || userIsFetching

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      buttonTitle={
        user && openSchemeInvite && !isWeb ? `Respond to Invite`
        : user ? `Back to Jarvis`
        : `Get Started`
      }
      buttonAction={
        user && openSchemeInvite && !isWeb ? () => rootNavigate('SchemeInviteAcceptIntroScreen', { schemeInvite: openSchemeInvite })
        : user ? goToMainAppStack
        : () => rootNavigate('NewUserCarouselScreen')
      }
      showButton={true}
      cancelButtonText={
        user && openSchemeInvite && !isWeb ? `Back to Jarvis`
        : isWeb ? `Download Mobile App`
        : undefined
      }
      cancelButtonAction={
        user && openSchemeInvite && !isWeb ? goToMainAppStack
        : isWeb ? () => setShowDownloadModal(true)
        : undefined
      }
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      imageAction={() => setShowInfoModal(true)}
      headline={openSchemeInvite
        ? `Welcome ${openSchemeInvite?.metadata?.firstName} ${openSchemeInvite?.metadata?.surname}!`
        : `Sorry, your link is no longer valid`
      }
      subHeading={openSchemeInvite
        ? `You've been invited to join a Jarvis Workplace Pension`
        : `You may have already responded to this invite`
      }
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        {
          logo
            ? <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }}
              />
            : <AppIllustration filename={'store_retirement_meter.png'} style={{
                width: Sizing.x200,
                height: Sizing.x200,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
        }
      </View>
      <Paragraph>{
        openSchemeInvite ? `${organizationDisplayName} has invited you to join their workplace scheme`
        : isWeb ? `You can still create a Jarvis account here and we'll give you the links to download our app for Android or iOS.`
        : `You can still create a Jarvis account and enjoy all the benefits of knowing exactly when you can retire.`
      }</Paragraph>
      <Portal>
        <AppInfoModal
          user={user}
          client={undefined}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
          isUnauthenticated={!user}
        />
      </Portal>
      {
        isWeb
          ? <Portal>
              <WebAppDownloadModal
                visible={showDownloadModal}
                onDismiss={() => setShowDownloadModal(false)}
              />
            </Portal>
          : <></>
      }
    </ProcessIntroScreen>
  )
}
