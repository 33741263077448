import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { MAX_ASSET_CURRENT_VALUE } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingPreviousPension, workingPreviousPension } from 'store/tempDataSlice'

export const SchemeEnrolmentPreviousPensionAdd_02_Value = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingPreviousPensionData = useAppSelector(workingPreviousPension)

  //Setup form
  const formObj = useForm<{ currentValue: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      currentValue: workingPreviousPensionData?.currentValue,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingPreviousPension(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What's the current pension value?`}
      subHeading={`It's okay if you don't know the exact amount - just give an estimate`}
    >
      <ManagedCurrencyInput
        name={'currentValue'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        // label={'Current Value'}
        placeholder={'Enter current value'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={0}
        maxValue={MAX_ASSET_CURRENT_VALUE}
        blurOnSubmit={false}
      />
    </ModalProcessScreen>
  )
}
