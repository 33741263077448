import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginParams, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { usePasswordlessStartMutation } from 'store/passwordlessSlice'
import { updateWorkingRegistration } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { openInbox } from 'react-native-email-link'

export const Authentication_01_HandOffInitiation = ({ route, navigation }) => {
  const dispatch = useDispatch()

  const loginConfig = useAppSelector(loginParams)
  const { autoLogin, authType } = loginConfig || {}
  
  const [noEmail, setNoEmail] = useState(false)
  const isLogin = authType === AUTHTYPE.LOGIN

  //On load, look for lastUserEmail
  useEffect(()=>{
    if (autoLogin) {
      dispatch(updateWorkingRegistration({ email: autoLogin }))
      doPasswordlessStart({ email: autoLogin })
    } else {
      setNoEmail(true)
    }
  },[])

  //State
  const [doPasswordlessStart, { data: passwordlessResult, error: passwordlessStartError, isLoading: passwordlessStartIsLoading }] = usePasswordlessStartMutation()
  
  // //On passwordless result, navigate
  // useEffect(()=>{
  //   if (passwordlessResult) {
  //     navigation.navigate('Submission')
  //   }
    
  // },[passwordlessResult])

  const next = () => {
    navigation.navigate('Submission')
  }

  const restart = () => {
    dispatch(setLoginParams(undefined))
    rootNavigate('AuthenticationProcessStack')
  }

  return (
    <ProcessScreen
      isLoading={passwordlessStartIsLoading}
      buttonTitle={passwordlessStartError || noEmail
          ? isLogin
            ? 'Login'
            : 'Create Account'
          : 'Enter Code Manually'
      }
      buttonAction={passwordlessStartError || noEmail
        ? restart
        : next
      }
      headline={passwordlessStartError || noEmail
        ? `Sorry, something went wrong`
        : `Nice to see you again!`
      }
      subHeading={passwordlessStartError || noEmail
        ? `Please ${isLogin ? 'login' : 'create an account'} to continue`
        : `We've sent a verification email to: ${autoLogin}`
      }
      allowTextButton={!passwordlessStartError && !noEmail}
      textButtonAction={() => openInbox()}
      textButtonTitle={'Open email'}
    >
      {
        passwordlessStartError || noEmail
          ? <AppIllustration
              filename={'magnifying_glass.png'}
              style={{
                width: Sizing.x200,
                height: Sizing.x200,
                alignSelf: 'center',
              }}
              resizeMode={'contain'}
            />
          : <>
              <AppIllustration
                filename={'invite.png'}
                style={{
                  marginBottom: Sizing.x10,
                  width: Sizing.x150,
                  height: Sizing.x150,
                  alignSelf: 'center',
                }}
                resizeMode={'contain'}
              />
              <Subheading>{`Open your email and either tap the magic link in the email on this device, or press below to enter the code manually.`}</Subheading>
            </>
      }
    </ProcessScreen>
  )
}
