import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { useGuidanceContext } from 'providers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, WorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'

export const Contributions_90_ManualSetup = ({ route, navigation }) => {
  const { nextScreen, existingRegularContribution, existingContributionConfiguration } = route.params

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

    const dispatch = useAppDispatch()

  const existingRegularAmount = existingRegularContribution?.amount || 0

  const workingSuggestionData = useAppSelector(workingSuggestion)

  //Setup form
  const formObj = useForm<{
    manualSetupSelections: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      manualSetupSelections: workingSuggestionData?.manualSetupSelections || []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj

  const options: ManagedMultipleChoiceInputOption[] = [
    {
      value: 'lump',
      label: `Make One-off Contribution`,
    },
    {
      value: 'regular',
      label: existingRegularAmount ? `Change Monthly Contributions` : `Start Monthly Contributions`,
    },
  ]

  const onSubmit = (attributes) => {
    const { manualSetupSelections } = attributes
    const payload: Partial<WorkingSuggestion> = {}

    if (manualSetupSelections.includes('regular')) {
      payload.proposedMonthlyContributions = 0
    }

    if (manualSetupSelections.includes('lump')) {
      payload.proposedOneOffContribution = 0
    }

    dispatch(updateWorkingSuggestion(payload))
    navigation.navigate('Execute')
  }

  const doCancel = () => {
    hideDialog()
    dispatch(updateWorkingSuggestion({
      cancelRegularContributions: true,
    }))  
    navigation.navigate('Execute')
  }

  // const doChangeSource = () => {
  //   dispatch(updateWorkingSuggestion({
  //     changeRegularContributions: true,
  //   }))  
  //   navigation.navigate('Execute')
  // }

  const count = watch('manualSetupSelections').length
  const hasSelected = count > 0

  return (    
    <ProcessScreen
      buttonTitle={hasSelected ? 'Next' : 'Select above'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={hasSelected}
      headline={`Ok, what do you want to do?`}
      subHeading={'Choose the types of contribution to make today'}
      allowTextButton={existingRegularAmount}
      textButtonTitle={existingRegularAmount ? `Cancel regular contributions` : undefined}
      textButtonAction={showDialog}
      // Disabled for now - if we want to add change source WITHIN this flow then we have to
      // make changes to the Hero screens which currently assume that you have actually made a contribution of some kind.
      // Changing contribution source should only happen outside of this flow (e.g. from contributions area)
      // Also, the personal/business choice seems to be skipped when this was enabled, so need to work out how
      // to ensure that appears in Make Contributions sub-flow
      // allowTextButton={existingRegularAmount || existingContributionConfiguration}
      // textButtonTitle={existingRegularAmount ? `Cancel regular contributions` : existingContributionConfiguration ? `Chaneg contribution source` : undefined}
      // textButtonAction={existingRegularAmount ? showDialog : existingContributionConfiguration ? doChangeSource : () => {}}
    >
    <ManagedMultipleChoiceInput
      formObj={formObj}
      name={'manualSetupSelections'}
      options={options}
    />
    {
      existingRegularAmount
        ? <Paragraph>{`You current monthly contributions are ${formatCurrencyAmount(existingRegularAmount, 2)}`}</Paragraph>        
        : <></>
    }
    <ConfirmationDialog
      visible={dialogVisible}
      title={'Are you sure?'}
      content={`Cancelling your regular contributions is likely to have a significant impact on your retirement age`}
      onCancel={hideDialog}
      onConfirm={doCancel}
      confirmLabel={'Cancel Anyway'}
    />
    </ProcessScreen>

  )
}