import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { BulletItem } from 'components/Typography/BulletItem'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useGetSignatureQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingBulkTransfer } from 'store/tempDataSlice'

export const BulkTransfer_02_ConsolidationInfo = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const dispatch = useAppDispatch()
  const workingBulkTransferData = useAppSelector(workingBulkTransfer)

  const { data: meSignature, isLoading: meSignatureIsLoading, refetch: refetchSignature } = useGetSignatureQuery()
  
  const isLoading = meSignatureIsLoading

  const tracingCount = workingBulkTransferData?.tracingCount
  const executionCount = workingBulkTransferData?.executionCount

  const next = () => {
    //Go to signature if includes tracing and no client signature, else confirmation
    if (tracingCount > 0 && !meSignature?.signature) {
      navigation.navigate(nextScreen)
    } else if (tracingCount > 0) {
      navigation.navigate('TracingConfirmation')
    } else {
      navigation.navigate('TransferConfirmation')
    }
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      buttonTitle={'Continue'}
      buttonAction={next}
      enableButton={true}
      headline={`Pension Consolidation`}
      subHeading={`Please read the following important information`}
    >
      <BulletItem style={localStyles.text}>{`Jarvis allows you to consolidate eligible contribution-based pensions into your Jarvis SIPP`}</BulletItem>
      <BulletItem style={localStyles.text}>{`According to the FCA, it is generally not advisable to transfer Defined Benefit pensions as they offer lifelong income with minimal risk`}</BulletItem>
      <BulletItem style={localStyles.text}>{`Pension services are provided by Jarvis. If you wish to move your pension, you should discuss this with your financial adviser to check that this is right for you. If you do not have a financial adviser, you can find one at www.unbiased.co.uk. A financial adviser may charge for their advice`}</BulletItem>
      <BulletItem style={localStyles.text}>{`Whilst most contribution-based pensions are eligible, there are some cases where we do not accept inward transfers, as this would unlikely be in your best interest`}</BulletItem>
      <BulletItem style={localStyles.text}>{`This includes ‘active’ workplace pensions where your employer is making contributions on your behalf`}</BulletItem>
      <BulletItem style={localStyles.text}>{`Please note that the value of investments can go down as well as up, so you may not get back what you invest`}</BulletItem>
    </ProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  text: {
    textAlign: 'left'
  },
})