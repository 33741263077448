import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { getActionDate } from "lib/dateHelpers"
import React from 'react'
import { ScrollView, View } from "react-native"
import { useUpdateGroupSchemeEnrolmentRequestMutation } from "store/apiSlice"
import { GroupSchemeRequestStatus, GroupSchemeRequestType, SchemeEnrolmentChangeRequestDto } from "store/dto/account.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { FooterButton } from '../../../../components/Utility/FooterButton'
import { BulletItem } from "components/Typography/BulletItem"
import { formatCurrencyAmount } from "lib/generalHelpers"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"

const isWeb = platformIsWeb()

type RequestInfoModalProps = {
  request: SchemeEnrolmentChangeRequestDto
  visible: boolean
  onDismiss: any
}

export const RequestInfoModal = (props: RequestInfoModalProps) => {
  const { onDismiss, visible, request } = props
  const { requestType, status, createdAt, actionDate, actionMessage, data } = request || {}
  const  {employerContributionAmount, contributionFrequency } = data || {}

  const [cancelRequest, { isLoading: cancelRequestIsLoading, error: cancelRequestError }] = useUpdateGroupSchemeEnrolmentRequestMutation()

  const handleCancel = async () => {
    cancelRequest({
      enrolmentId: request.enrolmentId,
      requestId: request.id,
      status: GroupSchemeRequestStatus.CANCELLED,
    })
    onDismiss()
  }

  const title = requestType === GroupSchemeRequestType.OTHER
    ? 'Other Request'
    :  requestType === GroupSchemeRequestType.OPT_OUT
    ? 'Opt Out Decision'
    : `Request to ${requestType}`
  const statusDescription =
    status === GroupSchemeRequestStatus.COMPLETED ? `Your employer has stated that they have completed your request`
    : status === GroupSchemeRequestStatus.PENDING ? `Your employer has not yet actioned your request`
    : status === GroupSchemeRequestStatus.REJECTED ? `Your employer has rejected your request`
    : status === GroupSchemeRequestStatus.CANCELLED ? `You have cancelled this request`
    : ''

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >
          <View style={layoutStyles.cardModalContentContainer}>
            <View style={{ paddingBottom: Sizing.x20 }}>
              <Headline>{title}</Headline>
            </View>
            <ContentDivider />
            <Headline style={{ color: Colors.brand.purple1 }}>{status}</Headline>
            <Paragraph>({statusDescription})</Paragraph>
            <Subheading>{`Requested: ${getActionDate(createdAt, true)}`}</Subheading>
            {
              status === GroupSchemeRequestStatus.COMPLETED ?
              <>
                <Subheading>{`Actioned: ${getActionDate(actionDate, true)}`}</Subheading>
              </>
              : status === GroupSchemeRequestStatus.REJECTED ?
              <>
                <Subheading>{`Rejected: ${getActionDate(actionDate, true)}`}</Subheading>
                <Subheading>{`Reason: ${actionMessage}`}</Subheading>
              </>
              : <></>
            }
            {
              requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
                ? <>
                    <ContentDivider />
                    <Subheading>{`Request Details`}</Subheading>
                    <BulletItem style={{ textAlign: 'left'}}>{`Gross Amount: ${formatCurrencyAmount(employerContributionAmount, 2)}`}</BulletItem>
                    <BulletItem style={{ textAlign: 'left'}}>{`Frequency: ${contributionFrequency}`}</BulletItem>
                  </>
                : <></>
            }
          </View>
        </ScrollView>
        {
          status === GroupSchemeRequestStatus.PENDING ?
            <FooterButton
              onPress={handleCancel}
              disabled={!!cancelRequestIsLoading}
              mode={'text'}
              confirmation={{
                requiresConfirmation: true,
                dialogTitle: 'Are you sure?',
                dialogContent: 'This will mark the request as cancelled, so that your employer will know to no longer action it.',
              }}
            >{requestType === GroupSchemeRequestType.OPT_OUT ? 'Cancel Opt Out' : 'Cancel Request'}</FooterButton>
          : <></>
        }
        <FooterButton onPress={onDismiss}>{'Close'}</FooterButton>
      </View>

    </CardModal>
  )
}
