import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton } from 'components/Utility/InformationButton'
import { differenceInYears } from 'date-fns'
import { DashboardMeter } from 'features/Dashboard/Components/DashboardMeter'
import { calculateAgeNow } from 'lib/dateHelpers'
import React from 'react'
import { BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { useAppSelector } from 'store/hooks'
import { workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_51_ViableAge = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const {
    withSpouse,
    client,
    timeline,
    baseBudgetLevel,
    viableRetirementAge,
    asset,
    income,
    terms,
  } = workingCalculatorRequestData || {}

  const {
    totalCurrentValue,
    totalMonthlyContributionAmount,
  } = asset || {}
  
  const {
    statePensionAmount,
    spouseStatePensionAmount,
  } = income || {}

  const {
    ageAtLastSurvivorDeathDate,
    retirementDate,
    statePensionAge,
    birthDate,
    spouseBirthDate,
  } = timeline || {}

  const hasSpouse = !!spouseBirthDate

  const clientAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(birthDate),
  )

  const spouseAgeAtLastStatePensionDate = hasSpouse ? differenceInYears(
    new Date(retirementDate),
    new Date(spouseBirthDate),
  ) : undefined

  const difference = viableRetirementAge - clientAgeAtRetirementDate
  const targetAgeIsClientStatePensionAge = statePensionAge === clientAgeAtRetirementDate
  const achievableAgeSameAsDeathAge = viableRetirementAge === ageAtLastSurvivorDeathDate
  const onLowestBudget = baseBudgetLevel === BudgetLevelIdentifier.MINIMUM
  const hasCurrentSavings = !!totalCurrentValue
  const hasMonthlyContributions = !!totalMonthlyContributionAmount
  const hasAnySavings = hasCurrentSavings || hasMonthlyContributions
  const statePensionTotal = statePensionAmount + spouseStatePensionAmount
  const hasStatePension = statePensionTotal > 0
  const hasMultipleStatePensions = statePensionAmount > 0 && spouseStatePensionAmount > 0

  const backToBudget = () => {
    navigation.navigate(`Budget`)
  }
  
  const backToAssets = () => {
    navigation.navigate(`TotalAssets`)
  }

  const features: FeatureBoxRow[] =
    achievableAgeSameAsDeathAge
      //Can't retire until death
      ? hasAnySavings
        //Has some savings
        ? [
            { label: `Set your personal target retirement age` },
            { label: `See exactly how much more to save to achieve it` },
          ]
        //Has no savings
        : [
            { label: `Set your personal target retirement age` },
            { label: `See exactly how much to save now to achieve it` },
          ]
      : difference === 0
        //Retire at state pension
        ? [
            { label: `Set your personal target retirement age` },
            { label: `Know exactly how much to save now to achieve it` }
          ]
        : difference > 0
          //Can't retire until after state pension
          ? [
              { label: `See how close you are to achieving your target retirement age` },
              { label: `Know exactly how much to save to close the gap` }
            ]
          //Can retire before state pension
          : [
              { label: `Set an ambitious target retirement age` },
              { label: `Know exactly how much to save to achieve it` }
            ]

  const informationTexts: string[] = []

  if (achievableAgeSameAsDeathAge) {
    if (hasAnySavings) {
      informationTexts.push(`Unfortunately, your current savings and contributions are unlikely to grow sufficiently to support your desired retirement lifestyle before age ${ageAtLastSurvivorDeathDate}`)
    } else {
      informationTexts.push(`Unfortunately, without any savings, you cannot support your desired retirement lifestyle before age ${ageAtLastSurvivorDeathDate}`)
    }
    informationTexts.push(`To retire with this lifestyle, you would need to put away additional retirement savings to build a pot from which to draw income in retirement.`)
  } else if (difference === 0) {
    if (targetAgeIsClientStatePensionAge) {
      informationTexts.push(`You're right on track to retire in line with your UK state pension age of ${statePensionAge}${hasSpouse ? `, when your partner would be ${spouseAgeAtLastStatePensionDate}` : ``}.`)
    } else {
      informationTexts.push(`You're right on track to retire in line with your partner's UK state pension age of ${spouseAgeAtLastStatePensionDate}, when you would be ${clientAgeAtRetirementDate}.`)
    }
    informationTexts.push(`To retire any earlier, you would need to put away additional retirement savings to cover the period of time until your state pension${hasMultipleStatePensions ? 's are' : ' is'} available.`)
  } else if (difference > 0) {
    if (targetAgeIsClientStatePensionAge) {
      informationTexts.push(`You're about ${difference} years away from being able to retire at your UK state pension age of ${statePensionAge}.`)
    } else {
      informationTexts.push(`You're about ${difference} years away from being able to retire at your partner's UK state pension age of ${spouseAgeAtLastStatePensionDate}, (when you would be ${clientAgeAtRetirementDate}).`)
    }
    informationTexts.push(`Your current savings are not predicted to grow sufficiently to enable you to retire at that time, and need additional time to grow, so that they then last until you would be ${ageAtLastSurvivorDeathDate}`)
    informationTexts.push(`To retire any earlier, you would need to put away additional retirement savings to cover the period of time until your state pension${hasMultipleStatePensions ? 's are' : ' is'} available.`)
  } else {
    if (targetAgeIsClientStatePensionAge) {
      informationTexts.push(`You're able to retire about ${difference * -1} years before your UK state pension age of ${statePensionAge}.`)
    } else {
      informationTexts.push(`You're able to retire about ${difference * -1} years before your partner's state pension age of ${spouseAgeAtLastStatePensionDate}, (when you would be ${clientAgeAtRetirementDate}).`)
    }
    informationTexts.push(`Putting away additional retirement savings could mean you can retire even earlier.`)
  }

  const informationTitle = `Timeline Explained`

  const informationIllustationFilename = withSpouse
    ? 'combined_retirement_timeline.png'
    : 'retirement_timeline.png'

  const targetAge = viableRetirementAge < clientAgeAtRetirementDate ? viableRetirementAge : clientAgeAtRetirementDate

  return (
    <ProcessScreen
      buttonTitle={`Continue`}
      buttonAction={next}
      enableButton={true}
      allowTextButton={achievableAgeSameAsDeathAge}
      textButtonAction={onLowestBudget ? backToAssets : backToBudget}
      textButtonTitle={onLowestBudget ? 'Update my savings' : 'Reduce my lifestyle'}
      headline={`Here's how that looks in your timeline`}
      subHeading={achievableAgeSameAsDeathAge
        ? `You won't be able to retire until age ${viableRetirementAge}`
        : difference > 0
          ? targetAgeIsClientStatePensionAge
            ? `You should be able to retire around age ${viableRetirementAge}, about ${difference} year${difference === 1 ? '' : 's'} after your UK state pension age`
            : `You should be able to retire around age ${viableRetirementAge}, about ${difference} year${difference === 1 ? '' : 's'} after your partner's UK state pension age`
          : `You should be able to retire at around age ${viableRetirementAge}`
      }
      subHeadingInfo={<InformationButton title={informationTitle} texts={informationTexts} illustrationFilename={informationIllustationFilename} buttonTitle={`How is this calculated?`} />}
      footerInfo={<FeatureBox data={features} />}
    >
      <DashboardMeter
        viableAge={viableRetirementAge}
        currentAge={calculateAgeNow(birthDate)}
        targetAge={targetAge}
        expectedDeathAge={ageAtLastSurvivorDeathDate}
        withLabels={true}
      />
    </ProcessScreen>
  )
}