import { join } from 'lodash'
import Intercom, { IntercomContent, Space } from 'platform-lib/intercom'
import { Platform } from 'react-native'
import { ClientMeDto } from 'store/dto/client.dto'
import { UserDto } from 'store/dto/user.dto'
import { platformIsIos, platformIsWeb } from 'lib/platformHelpers'
import { Logger } from 'lib/logger'

//@ts-ignore
const Visibility = Intercom.Visibility

const isWeb = platformIsWeb()
const isIos = platformIsIos()

export const loginUnidentifiedToIntercom = async () => {
  if (isWeb) { return }
  Logger.info(`### Login unidentified user to Intercom...`)
  await Intercom.loginUnidentifiedUser()
}

export const loginToIntercom = async (
  user: UserDto,
  unreadCountFunction: Function,
  ) => {
  if (isWeb) { return }
  const hash = Platform.select({
    ios: user.intercomHashes.ios,
    android: user.intercomHashes.android,
    web: user.intercomHashes.web,
  })
  //Call to loginUnidentifiedUser is to prevent a fatal crash on iOS devices:
  //[NSNull code]: unrecognized selector sent to instance 0x21037bf98
  //Not called on Android as it causes a non-fatal error:
  //Failed to register user. We already have a registered user. If you are attempting to register a new user, call logout() before this. If you are attempting to register a
  if (isIos) {
    Logger.info(`### (iOS) Temporarily login unidentified Intercom user...`)
    await Intercom.loginUnidentifiedUser()
  }
  Logger.info(`### Logging out any other Intercom user...`)
  await Intercom.logout()
  Logger.info(`### Attempting to login Intercom user...`)
  await Intercom.setUserHash(hash)
  const name = user?.metadata?.firstName && user?.metadata?.surname
    ? `${user?.metadata?.firstName} ${user?.metadata?.surname}`
    : undefined
  await Intercom.loginUserWithUserAttributes({
    userId: user.id,
    email: user.email,
  })
  const userUpdate = {
    userId: user.id,
    email: user.email,
    name,
    //Included here as there appears to be a race condition with the sync from
    //Amplitude to Intercom where the fisrt update (Client Login) fails, presumably
    //because Amplitude checks for the user id, Intercom says it is not there,
    //Ampitude then tries to create it, by which point Intercom has just created it
    //and then declares a conflict
    customAttributes: {
      createdAt: user.createdAt,
      authProvider: user.providerId.split('|', 1)[0],
    }    
  }
  Logger.info({ userUpdate }, `### Attempting to update Intercom user basic details...`)
  await Intercom.updateUser(userUpdate)

  //Get and set unread message count with callback function
  const unread = await getIntercomUnreadConversationCount()
  Logger.info(`### Setting initial Intercom unread message count`, unread)
  unreadCountFunction(unread)

  //Add unread count listener
  const countListener = addIntercomUnreadCountListener(
    (response) => {
      const newUnread = response.count as number
      Logger.info(`### Updating Intercom unread message count`, newUnread)
      unreadCountFunction(newUnread)
    }
  )
  return () => {
    Logger.info(`### Removing intercom count listener`)
    countListener.remove()
  }

  // Logger.debug(`Logged user into Intercom: ${user.id}`)
}

export const logoutFromIntercom = async () => {
  if (isWeb) { return }
  Logger.info(`Logout from Intercom...`)
  Intercom.logout()
}

export const openIntercom = async () => {
  if (isWeb) { return }
  await Intercom.present()
}

export const showIntercomHelpCenter = async () => {
  if (isWeb) { return }
  await Intercom.presentSpace(Space.helpCenter)
}

export const showIntercomHelpCenterCollection = async (collectionId: string) => {
  if (isWeb) { return }
  await Intercom.fetchHelpCenterCollection(collectionId)
}

export const showIntercomHelpCenterArticle = async (articleId: string) => {
  if (isWeb) { return }
  await Intercom.presentContent(IntercomContent.articleWithArticleId(articleId))
}

export const searchIntercomHelpCenter = async (searchTerms: string[]): Promise<any[]> => {
  if (isWeb) { return }
  const quoted = searchTerms.map(term => {
    return `"${term}"`
  })
  const searchTerm = join(quoted, ',')

  if (searchTerm === '') {
    return []
  }

  let results: any[] = []
  //Seems to =have incorrect typing: HelpCenterArticleSearchResult instead of HelpCenterArticleSearchResult[]
  const result: any = await Intercom.searchHelpCenter(searchTerm)
  if (result.length) {
    results = result
  }
  return results
}

export const showIntercomLauncher = async (show: boolean) => {
  if (isWeb) { return }
  await Intercom.setLauncherVisibility(show ? Visibility.VISIBLE : Visibility.GONE)  
}

export const composeIntercomMessage = async (initialMessage?: string) => {
  if (isWeb) { return }
  await Intercom.presentMessageComposer(initialMessage)
}

export const composeIntercomMessageForUnregistered = async (initialMessage?: string) => {
  if (isWeb) { return }
  await loginUnidentifiedToIntercom()
  await composeIntercomMessage(initialMessage)
}

export const getIntercomUnreadConversationCount = async (): Promise<number> => {
  return Intercom.getUnreadConversationCount()
}

export const addIntercomUnreadCountListener = (
  callback: (response: { count?: number; visible: boolean }) => void
) => {
  if (isWeb) { return }

  // const eventName = Platform.select({
  //   ios: 'IntercomUnreadConversationCountDidChangeNotification',
  //   default: 'IntercomUnreadCountDidChange',
  // })
  //@ts-ignore
  return Intercom.addEventListener('IntercomUnreadConversationCountDidChangeNotification', callback)
}