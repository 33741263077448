import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { ModelGoalIdentifier, OnboardingPhase, useGuidanceContext } from 'providers'
import React from 'react'
import { ampli } from 'src/ampli'
import { useGetMeQuery, useGetUserAccountsQuery, useGetRetirementProfileQuery, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSuggestion } from 'store/tempDataSlice'

export const ContributionsIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding, goal }: { fromOnboarding: OnboardingPhase, goal: ModelGoalIdentifier } =  route?.params || {}

  const { currentViableAge, modelAvailable } = useGuidanceContext()

  const dispatch = useAppDispatch()

  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
    
  const personalPension = getPersonalPension(accounts)

  const isLoading = clientIsLoading || accountsIsLoading || rpIsLoading
  const error: any = clientError || accountsError

  const [ updateClient] = useUpdateMeMutation()
  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  useFocusEffect(() => {
    dispatch(updateWorkingSuggestion(undefined))
  })

  const refetchAll = () => {
    refetchClient()
    refetchAccounts()
    refetchRp()
  }

  const next = () => {
    const params = {
      ...route?.params || {},
      modelAvailable,
      existingContributionConfiguration: client?.contributionConfiguration,
      existingRegularContribution: personalPension?.regularContribution,
      existingTargetRetirementAge: retirementProfile?.targetRetirementAge,
      originalOnboardingFlags: client?.onboardingFlags,
    }
    rootNavigate('ContributionsProcessStack', params)
  }

  const refuse = () => {
    updateClient({
      onboardingFlags: {
        contribute: true,
      },
    })
    ampli.contributionsSetup({
      skipped: true,
    })
    hideDialog()
    goToOnboardingTasks(fromOnboarding)
  }

  const hasContributions = personalPension?.regularContribution?.amount || personalPension?.currentValue || personalPension?.pendingContributionValue

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      refuseButtonAction={fromOnboarding ? showDialog : undefined}
      refuseButtonText={`Don't want to contribute yet?`}
      illustrationFilename={'regular_contributions.png'}
      headline={fromOnboarding
        ? `Set Up Contributions`
        : goal === ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE ? `Achieve Your Retirement Age`
        : goal === ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE ? `Reduce Retirement Age`
        : goal === ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS ? `Increase Legacy Surplus`
        : goal === ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE ? `Retire Even Earlier`
        : hasContributions
          ? `Make or Change Contributions`
          : `Start Contributing`
      }
      subHeading={fromOnboarding
        ? `Get started with a one-off or set up regular contributions to your ${JAR_NAME_PERSONAL}`
        : goal === ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE ? `Review options for achieving your target retirement age`
        : goal === ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE ? `Review options for reducing your retirement age`
        : goal === ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS ? `Review options for leaving surplus capital to loved ones`
        : goal === ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE ? `Review options for retiring even earlier than your current target`
        : hasContributions
          ? `Make one-off or modify regular contributions to your ${JAR_NAME_PERSONAL}`
          : `Get started with a one-off or set up regular contributions to your ${JAR_NAME_PERSONAL}`
      }
    >
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`We'll tick this task off, but you can always set up regular or one-off contributions in the Contributions area`}
        onCancel={hideDialog}
        onConfirm={refuse}
        confirmLabel={'Complete task'}
      />
    </ProcessIntroScreen>
  )
}
