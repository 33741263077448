import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { goToDashboard } from 'lib/RootNavigation'
import React from 'react'
import { Paragraph } from 'components/Typography'
import { Image } from 'react-native'
import { Sizing } from 'styles'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { AppIllustration } from 'components/Utility/AppIllustration'

export const InvestmentChoice_02_Sorry = ({ route, navigation }) => {

  return (
    <ProcessScreen
      buttonTitle={'Return to Dashboard'}
      buttonAction={goToDashboard}
      enableButton={true}
      allowTextButton={true}
      textButtonTitle={'Contact Support'}
      textButtonAction={() => composeIntercomMessage('')}
      headline={`We're sorry...`}
      subHeading={`Unfortunately we cannot offer you a ${JAR_NAME_PERSONAL} at this time`}
    >
      <Paragraph>{`You must meet all criteria listed on the previous page to open a ${JAR_NAME_PERSONAL} at this time.`}</Paragraph>
      <AppIllustration
        filename={'support.png'} style={[
        {
          width: '100%',
          height: Sizing.x200,
          resizeMode: 'contain',
          alignSelf: 'center'
        },
      ]} />
      <Paragraph>{'Please contact support if you want to discuss further.'}</Paragraph>
    </ProcessScreen>
  )
}
