import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { EnrolmentOptOut_01_Information, EnrolmentOptOut_02_Verify, EnrolmentOptOut_03_Confirm, EnrolmentOptOut_99_Result } from './Screens'

export const EnrolmentOptOutProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='EnrolmentOptOut'
      processTitle='Pension Scheme Opt Out'
      previousRoute='EnrolmentOptOutIntroScreen'
      nextRoute='EnrolmentOptOutHeroScreen'
      initialRouteName={route?.params?.initialRouteName}
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Information',
          component: EnrolmentOptOut_01_Information,
        },
        {
          navigationId: 'NationalInsuranceNo',
          component: EnrolmentOptOut_02_Verify,
        },
        {
          navigationId: 'Declarations',
          component: EnrolmentOptOut_03_Confirm,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: EnrolmentOptOut_99_Result,
      }}
    />
  )
}
