import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetOpenInviteByIdQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EntryPointPartnerScreen = ({ route, navigation }) => {
  const { preferredInviteId } = route.params || {}
  
  const [showDownloadModal, setShowDownloadModal] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const dispatch = useAppDispatch()

  const { data: openInvite, isLoading: openInviteIsLoading, isFetching: openInviteIsFetching, error: openInviteError } = useGetOpenInviteByIdQuery(preferredInviteId, { skip: !preferredInviteId })
  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  //Set loginParams emailAddress when get an invite without a user
  useEffect(() => {
    if (openInvite && userError) {
      dispatch(setLoginParams({
        emailAddress: openInvite?.email,
      }))
    }
  }, [openInvite, userError])

  const isLoading = openInviteIsLoading || openInviteIsFetching || userIsLoading || userIsFetching

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      buttonTitle={
        user && openInvite && !isWeb ? `Respond to Invite`
        : user ? `Back to Jarvis`
        : `Get Started`
      }
      buttonAction={
        user && openInvite && !isWeb ? () => rootNavigate('InviteAcceptIntroScreen')
        : user ? goToMainAppStack
        : () => rootNavigate('NewUserCarouselScreen')
      }
      showButton={true}
      cancelButtonText={
        user && openInvite && !isWeb ? `Back to Jarvis`
        : isWeb ? `Download Mobile App`
        : undefined
      }
      cancelButtonAction={
        user && openInvite && !isWeb ? goToMainAppStack
        : isWeb ? () => setShowDownloadModal(true)
        : undefined
      }
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      imageAction={() => setShowInfoModal(true)}
      headline={openInvite
        ? `Welcome ${openInvite?.firstName} ${openInvite?.surname}!`
        : `Sorry, your link is no longer valid`
      }
      subHeading={openInvite
        ? `You've been invited to join Jarvis!`
        : `You may have already responded to this invite`
      }
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        <AppIllustration
          filename={openInvite
            ? 'envelope_with_star.png'
            : 'magnifying_glass.png'
          }
          style={{
            width: Sizing.x200,
            height: Sizing.x200,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
      </View>
      <Paragraph>{
        openInvite ? `${openInvite?.from?.firstName} ${openInvite?.from?.surname} has invited you to plan your retirement together`
        : isWeb ? `You can still create a Jarvis account here and we'll give you the links to download our app for Android or iOS.`
        : `You can still create a Jarvis account and enjoy all the benefits of knowing exactly when you can retire.`
      }</Paragraph>
      <Portal>
        <AppInfoModal
          user={user}
          client={undefined}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
          isUnauthenticated={!user}
        />
      </Portal>
      {
        isWeb
          ? <Portal>
              <WebAppDownloadModal
                visible={showDownloadModal}
                onDismiss={() => setShowDownloadModal(false)}
              />
            </Portal>
          : <></>
      }
    </ProcessIntroScreen>
  )
}
