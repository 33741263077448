import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Logger } from 'lib/logger'
import { identity, pickBy } from 'lodash'
import React, { ComponentProps, forwardRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Keyboard, Platform, TextStyle, View } from 'react-native'
import { MaskService, TextInputMask } from 'react-native-masked-text'
import { TextInput } from 'react-native-paper'
import { Sizing, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedCurrencyInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name: string
  customRules?: any
  withSubUnit?: boolean
  required?: boolean
  forceZeroValueOnEmpty?: boolean
  minValue?: number
  maxValue?: number
  submitHandler?: any
  informationMessage?: string
  informationMessageIsError?: boolean
  clearButtonFunction?: Function
}
 
export const ManagedCurrencyInput = forwardRef((props: ManagedCurrencyInputProps, ref: any) => {
  const { returnKeyType, disabled, formObj, customRules, clearButtonFunction, informationMessage, informationMessageIsError, name, withSubUnit, required, forceZeroValueOnEmpty, minValue, maxValue, label, style, submitHandler} = props
  const { control, setValue, trigger, formState: { isValid, errors } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const clearValue = () => {
    setValue(name, 0, { shouldDirty: true, shouldValidate: true })
    trigger(name)
    if (clearButtonFunction) {
      clearButtonFunction()
    }
  }

  const options = {
    precision: withSubUnit ? 2 : 0,
    separator: '.',
    delimiter: ',',
    unit: '£'
  }

  const centered = !label

  const textInputStyles: TextStyle = centered ? { textAlign: 'center' } : {}

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    theme: { colors: { text: themeColors.inputText }},
    mode: 'flat',
    style: [Typography.defined.input, textInputStyles, style],
    error: hasError,
    render: renderProps => {
      return <TextInputMask
      {...renderProps}
      type={'money'}
      options={options}
      ref={ref}
      returnKeyType={getBestReturnKeyType({ returnKeyType }, isValid)}
      />
      }
  }

  const getMaskedValue = (value: number): string => {
    const formattedValue = withSubUnit ? `${value.toString()}00` : value.toString()
    return MaskService.toMask('money', formattedValue, options)
  }

  const isNoBiggerThan = (value: number) => {
    return value <= maxValue ? true : `Cannot be more than ${getMaskedValue(maxValue)}`
  }
  
  const isNoSmallerThan = (value: number) => {
    return value >= minValue ? true : `Cannot be less than ${getMaskedValue(minValue)}`
  }

  const customRulesToUse = customRules ? customRules : {}

  const customValidatorObj: any = {
    ...pickBy({
      isNoSmallerThan: minValue ? isNoSmallerThan : undefined,
      isNoBiggerThan: maxValue ? isNoBiggerThan : undefined,    
    }, identity),
    ...customRulesToUse,
  }

  const getBestReturnKeyType = (textInputProps: ComponentProps<typeof TextInput>, isValid: boolean) => {
    const { returnKeyType } = textInputProps
    //Always use 'done' for numeric keypad on iOS when valid
    if (Platform.OS === 'ios') {
      return isValid ? 'done' : undefined
    }
    return returnKeyType ? returnKeyType : 'done'
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          required: required || false,
          validate: customValidatorObj,
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          const stringValue: string =value? value.toFixed(withSubUnit ? 2 : 0):'0'
          return (
          <>            
            <TextInput
              {...textInputProps}
              value={stringValue}
              onBlur={onBlur}
              onChangeText={text => {
                const floatValue = text ?
                  parseFloat(text.replace(/[,£]/g, "")) 
                  : forceZeroValueOnEmpty
                    ? 0
                    : undefined
                onChange(floatValue)
              }}
              onSubmitEditing={() => {
                if (submitHandler) {
                  try {
                    submitHandler()
                  } catch(e) {
                    Logger.error(`Submit handler failed`, e)
                  }
                } else {
                  Keyboard.dismiss()
                }
              }}
              left={
                value && !disabled && centered ?
                //@ts-ignore
                <TextInput.Icon tabIndex={-1} size={Sizing.x20} icon={''} />
              : undefined
              }
              right={
                value && !disabled ?
                //@ts-ignore
                  <TextInput.Icon tabIndex={-1} size={Sizing.x20} icon={'close-circle'} color={themeColors.primary} onPress={clearValue} forceTextInputFocus={false} />
                : undefined
              }
            />
            <InputErrorMessages formObj={formObj} name={name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
          </>
        )}}
        name={name}
      />
    </View>
  )
})