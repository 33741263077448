import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetGroupSchemePaymentsQuery } from 'store/apiSlice'
import { GroupSchemePaymentCollectionStatus, GroupSchemePaymentStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerPaymentDataId } from 'store/uxSlice'
import { Flex } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerPaymentListItem } from '../Components/EmployerPaymentListItem'

export const EmployerPaymentsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)

  const { data: payments, isLoading: paymentsIsLoading, error: paymentsError, isFetching: paymentsIsFetching, refetch: refetchPayments } = useGetGroupSchemePaymentsQuery({
    groupSchemeId: currentScheme?.id,
    incomplete: true
  }, { skip: !currentScheme })
  const [refreshing, setRefreshing] = useState(false)

  const dispatch = useAppDispatch()

  const handleShowPaymentDetails = (id: string) => {
    dispatch(setCurrentEmployerPaymentDataId(id))
  }

  const paymentsList = () => {
    let failed = []
    let awaitingConfirmation = []
    let awaitingPayment = []
    let paid = []
    if (payments) {
      payments.map((data, id) => {
        if (data.collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS || data.collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED) {
          failed.push(data)
        } else if (data.status === GroupSchemePaymentStatus.AWAITING_CONFIRMATION) {
          awaitingConfirmation.push(data)
        } else if (data.status === GroupSchemePaymentStatus.AWAITING_PAYMENT) {
          awaitingPayment.push(data)
        } else if (data.status === GroupSchemePaymentStatus.DECLARED || data.status === GroupSchemePaymentStatus.PAID) {
          paid.push(data)
        } 
      })
      return [
        { title: 'Failed Collection', data: failed },
        { title: 'Awaiting Confirmation', data: awaitingConfirmation },
        { title: 'Awaiting Payment', data: awaitingPayment },
        { title: 'Paid', data: paid },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerPaymentListItem
        payment={item}
        onPressFunction={() => handleShowPaymentDetails(item?.id)}
      />
    )
  }

  const isLoading = paymentsIsLoading || paymentsIsFetching
  const error: any = paymentsError

  const onRefresh = () => {
    setRefreshing(true)
    refetchPayments()
    setRefreshing(false)
  }

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding payments...')} /> :
        error ?  <ErrorScreen errorTryAgain={refetchPayments} error={error?.data} /> : 
          <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              <OptionalSectionList
                sections={paymentsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`No current payments`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
