import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { MAX_INCOME_ANNUAL_AMOUNT } from 'lib/constants'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { RetirementIncomeType } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome, workingIncome } from 'store/tempDataSlice'

export const RetirementIncomesAddIncome_02_Income = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  const workingIncomeData = useAppSelector(workingIncome)

  const annualIncomeAmountRef = useRef()

  //Setup form
  const formObj = useForm<{ annualIncomeAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      annualIncomeAmount: workingIncomeData?.annualIncomeAmount,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingIncome(attributes))
    if (workingIncomeData?.incomeType === RetirementIncomeType.OTHER ) {
      navigation.navigate(nextScreen)
    } else if (workingIncomeData?.incomeType === RetirementIncomeType.DEFINED_BENEFIT ) {
      navigation.navigate('Date')
    } else {
      navigation.navigate('Name')
    }    
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How much income will it provide?`}
      subHeading={workingIncomeData?.incomeType === RetirementIncomeType.PROPERTY
      ? `The gross annual income that ${client?.isSpouse ? `they` : `you`} will make (after all costs)`
      : workingIncomeData?.incomeType === RetirementIncomeType.EMPLOYMENT
        ? `The gross annual income that ${client?.isSpouse ? `they` : `you`} will receive, before tax`
        : `The gross annual income that ${client?.isSpouse ? `they` : `you`} will receive`
    }
    >
      <ManagedCurrencyInput
        ref={annualIncomeAmountRef}
        name={'annualIncomeAmount'}
        returnKeyType="next"
        autoFocus={true}
        formObj={formObj}
        // label={'Annual Income Amount'}
        placeholder={`Enter annual amount`}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={0}
        maxValue={MAX_INCOME_ANNUAL_AMOUNT}
        blurOnSubmit={true}
      />
    </ModalProcessScreen>
  )
}
