import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getActionDate } from 'lib/dateHelpers'
import { getMessageBodyPreview, getMessageIconName } from 'lib/messageHelpers'
import React from 'react'
import { MessageDto, MessageStatus } from 'store/dto/message.dto'
import { Sizing, Paper } from 'styles'

type MessageListItemProp = {
  message: MessageDto
  onPressFunction?: () => void
}
export const MessageListItem = (props: MessageListItemProp) => {
  const { message, onPressFunction } = props
  const { subject, payload, status, created } = message

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName: any = getMessageIconName(message)
  const bodyPreview = getMessageBodyPreview(message)

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={subject}
      titleStyle={status === MessageStatus.UNREAD ? { color: themeColors.accent } : undefined}
      titleRight={getActionDate(created)}
      subTitle={bodyPreview}
      subTitleRight={payload?.id ? <MaterialCommunityIcons name={'paperclip'} size={Sizing.x20}  color={themeColors.primary} /> : undefined}
    />
  )
}
