import { Headline, Paragraph, Subheading } from "components/Typography"
import { BulletItem } from "components/Typography/BulletItem"
import { ProviderContactDetail } from "features/ModalProcesses/RetirementAssetTransfer/Components/ProviderContactDetail"
import { scaleNormalizer } from "lib/scaleHelpers"
import { isEmpty } from 'lodash'
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { PensionProviderDto } from "store/dto/pension-provider.dto"
import { Colors, Paper, Flex, Sizing } from "styles"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { UnborderedTable, UnborderedTableRow } from "./UnborderedTable"
import { ContentDivider } from "components/Layout/ContentDivider"

export enum PensionProviderModalContentSection {
  GENERAL,
  CONTACT,
  REFERENCE,
}

type PensionProviderModalContentProps = {
  provider: PensionProviderDto
  imageSource: any
  sections?: PensionProviderModalContentSection[]
}

export const PensionProviderModalContent = (props: PensionProviderModalContentProps) => {
  const { provider, imageSource, sections } = props
  const {
    name,
    description,
    notes,
    websiteUrl,
    contactInformation,
    referenceFormatInformation,
  } = provider || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const includeSections = sections || [
    PensionProviderModalContentSection.CONTACT,
    PensionProviderModalContentSection.REFERENCE,
    PensionProviderModalContentSection.GENERAL,
  ]

  const contactDetails = contactInformation?.contactDetails || []
  const referenceSummary: string[] = referenceFormatInformation?.summary ? referenceFormatInformation?.summary.split('|') : []
  const formats = referenceFormatInformation?.formats || []
  const formatTableData: UnborderedTableRow[] = [
    {
      isHeader: true,
      label: 'Pattern',
      value: 'Example',
    }
  ]
  formats.forEach(format => {
    formatTableData.push({
      label: format?.pattern,
      value: format?.example,
    })
  })

  function GeneralSection() {
    return (
      <>
        <Subheading style={localStyles.subTitle}>{'General Information'}</Subheading>
        {
          !websiteUrl && !notes ?
          <Paragraph style={localStyles.text}>{`We don't have any additional information for ${provider?.name} at present.`}</Paragraph>
          : <></>
        }
        { notes ?
          <Paragraph style={localStyles.text}>{notes}</Paragraph>
          : <></>
        }
        {
          websiteUrl ? 
            <ExternalLinkButton
              url={websiteUrl}
              style={{
                alignSelf: 'flex-start'
              }}
            >{'Website'}
          </ExternalLinkButton>
          : <></>
        }
        <ContentDivider />
      </>
    )
  }

  function ContactSection() {
    return (
      <>
        <Subheading style={localStyles.subTitle}>{'Contact Information'}</Subheading>
        {
          isEmpty(contactInformation) ?
            <>
              <Paragraph style={localStyles.text}>{`We don't yet have any contact information for ${provider?.name}, but we're busy working to build up information on as many pension providers as possible.`}</Paragraph>
              <Paragraph style={localStyles.text}>{`Try searching online for your provider's website and looking for contact information.`}</Paragraph>
            </>
            : <></>
        }
        { contactInformation?.notes ?
          <Paragraph style={localStyles.text}>{contactInformation?.notes}</Paragraph>
          : <></>
        }
        {
          contactDetails.length > 1 ?
            <>
              <Paragraph style={localStyles.text}>{`We have a few possible contacts for ${name}.`}</Paragraph>
              <ContentDivider />
            </>
            : <></>
        }
        {
          contactDetails.length ? contactDetails.map((detail, idx) => {
            const { notes, telephone, email, contactUrl, portalUrl } = detail
            return (
              <ProviderContactDetail contactDetail={detail} key={idx} />
            )
          })
          : <></>
        }
        {
          !contactDetails.length ? 
            <ContentDivider />
            : <></>
        }
      </>
    )
  }

  function ReferenceSection() {
    return (
      <>
        <Subheading style={localStyles.subTitle}>{'Pension Reference Information'}</Subheading>
        {
          isEmpty(referenceFormatInformation) ?
          <>
            <Paragraph style={localStyles.text}>{`We don't yet have any specific information on pension reference formats for ${provider?.name}, but we're busy working to build up information on as many pension providers as possible.`}</Paragraph>
            <Paragraph style={localStyles.text}>{`Generally references are 6-16 characters long, and could contain numbers and/or letters, sometimes with separating dashes or slashes.`}</Paragraph>
          </>
          : <>
            { referenceSummary.length ?
                <>
                  {
                    referenceSummary.map((line, idx) => {
                      return (
                        <Paragraph key={idx} style={localStyles.text}>{line}</Paragraph>
                      )
                    })
                  }
                  <ContentDivider />
                </>
              : <></>
            }

            {
              formats.length?
                <>
                  <Paragraph style={localStyles.text}>{formats.length > 1
                    ? `Here's some examples of how pension references from ${name} could look.`
                    : `Here's how a pension reference from ${name} could look.`
                  }</Paragraph>
                  <UnborderedTable
                    data={formatTableData}
                  />
                  <Paragraph style={localStyles.text}>Pattern Information:</Paragraph>
                  <BulletItem style={localStyles.text}>'a' means any letter</BulletItem>
                  <BulletItem style={localStyles.text}>'d' means any number</BulletItem>
                  <BulletItem style={localStyles.text}>'x' means a single letter or number</BulletItem>
                  <BulletItem style={localStyles.text}>'...' means any quantity of letters or numbers</BulletItem>
                  <BulletItem style={localStyles.text}>Uppercase letters, numbers or any other symbol mean exactly that character</BulletItem>
                </>
                : <></>
            }

          </>
        }
        <ContentDivider />
      </>
    )
  }

  return (
    <>
      <View style={{
        alignSelf: 'center',
      }}>
        <Image source={imageSource} style={{
            width: scaleNormalizer(100),
            height: scaleNormalizer(100),
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
      </View>
      <Headline style={localStyles.title}>{name}</Headline>
      <Subheading style={localStyles.subTitle}>{description}</Subheading>
      
      <ContentDivider />

      {
        includeSections.map((section, idx) => {
          return(
            <View key={idx}>
              {
                section === PensionProviderModalContentSection.GENERAL
                ? <GeneralSection />
                : section === PensionProviderModalContentSection.CONTACT
                ? <ContactSection />
                : section === PensionProviderModalContentSection.REFERENCE
                ? <ReferenceSection />
                : <></>
              }
            </View>
          )
        })
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  sliderOuterContainer: {
    width: '100%',
  },
  sliderContainer: {
    width: '100%',
    height: Sizing.x70,
    alignItems: 'center',
  },
  sliderTrack: {
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(8),
    borderRadius: scaleNormalizer(4)
  },
  sliderMarkerOuter: {
    marginTop: scaleNormalizer(7),
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(40),
    width: scaleNormalizer(40),
    borderRadius: scaleNormalizer(20)
  },
  sliderMarkerInner: {
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.purple2,
    height: scaleNormalizer(30),
    width: scaleNormalizer(30),
    borderRadius: Sizing.x15,
  },
  sliderMarkerIcon: {
    padding: scaleNormalizer(-8),
    margin: scaleNormalizer(-8)
  },
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})