import { generateColor, middleColor } from 'lib/colorHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { concat, sumBy } from 'lodash'
import Victory from 'platform-lib/victory'
import React from 'react'
import { View } from 'react-native'
import { Colors, Paper, Sizing } from 'styles'

const VictoryPie = Victory.VictoryPie
const VictoryLegend = Victory.VictoryLegend
const VictoryLabel = Victory.VictoryLabel

export interface PieChartDataSet {
  categories: PieChartCategory[]
}

export interface PieChartCategory {
  name: string
  items: PieChartItem[]
}

export interface PieChartItem {
  x: string
  y: string | number
}

type PieChartWithLegendProps = {
  dataSet: PieChartDataSet
}

type PieChartProps = {
  items: PieChartItem[]
  colors: string[]
}

type PieChartLegendProps = {
  legends: any[]
}

interface CategoryColorSpec {
  start: string
  end: string
}

const CATEGORY_COLORS: CategoryColorSpec[] = [
  { start: Colors.chart.pink1, end: Colors.chart.pink2 },
  { start: Colors.chart.blue1, end: Colors.chart.blue2 },
  { start: Colors.chart.purple1, end: Colors.chart.purple2 },
  { start: Colors.chart.aqua1, end: Colors.chart.aqua2 },
  { start: Colors.chart.violet1, end: Colors.chart.violet2 },
  { start: Colors.chart.green1, end: Colors.chart.green2 },
]

export const generatePieChartInputs = (categories: PieChartCategory[], labelColor?: string): {
  items: PieChartItem[],
  colors: string[],
  legends: any[],
} => {
    //Calculate overall total "y" value
    const total = sumBy(categories, function(category: PieChartCategory) {
      return sumBy(category.items, 'y')
    })    
  
  //Build data sets
  let items: PieChartItem[] = []
  let colors: string[] = []
  let legends: any[] = []
  categories.forEach((category, idx) => {
    //Items
    // const labelledItems = category.items.map(item => {
    //   return {
    //     ...item,
    //     label: idx + 1,
    //   }
    // })
    items = concat(items, category.items)
    //Colors
    const colorIndex = idx % CATEGORY_COLORS.length
    const colorSpec = CATEGORY_COLORS[colorIndex]
    colors = concat(colors, generateColor(colorSpec.start, colorSpec.end, category.items.length))
    //Legends
    const categoryPercentage = 100 * sumBy(category.items, 'y') / total
    const legendItem: any = {
      name: `${category.name} - ${formatPercentageAmount(categoryPercentage, 1)}`,
      labels: {
        fill: labelColor,
      },
      symbol: {
        fill: middleColor(colorSpec.start, colorSpec.end),
        type: 'square',
      }
    }
    legends = concat(legends, legendItem)
  })

  return {
    items,
    colors,
    legends,
  }
}

export const PieChart = (props: PieChartProps) => {
  const { items, colors } = props

  return (
    <VictoryPie
      data={items}
      labels={() => null}
      innerRadius={Sizing.x40}
      animate={true}
      colorScale={colors}
    />
  )
}

export const PieChartLegend = (props: PieChartLegendProps) => {
  const { legends } = props

  return (
    <>
      <VictoryLegend
        height={scaleNormalizer(legends.length * 35) + 20}
        title={``}
        centerTitle
        orientation={`vertical`}
        data={legends}
      />
  </>
  )
}

export const PieChartWithLegend = (props: PieChartWithLegendProps) => {
  const { dataSet } = props || {}
  const categories = dataSet?.categories || []
  
const { colors: themeColors } = Paper.useAppTheme()


  const { items, colors, legends } = generatePieChartInputs(categories, themeColors.primary)

  return (
    <>
      <PieChart
        items={items}
        colors={colors}
      />
    <View style={{ paddingHorizontal: Sizing.x30 }}>
      <VictoryLegend
        height={scaleNormalizer(categories.length * 35) + 20}
        title={``}
        centerTitle
        orientation={`vertical`}
        data={legends}
      />
    </View>
  </>
  )
}