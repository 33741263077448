import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { ContributionsScreen } from './Screens/ContributionsScreen'

export const ContributionsSectionScreen = ({ route }) => {
  const { accountId } = route?.params || {}

  return (
    <SectionSimpleContainer
      sectionTitle={'Contributions'}
    >
      <ContributionsScreen
        accountId={accountId}
      />
    </SectionSimpleContainer>
  )
}