import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { hasAnyPermission, UserPermissions } from 'lib/authHelpers'
import { MAX_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT, MIN_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { user } from 'store/authSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingLumpSumContributionPayment, workingLumpSumContributionPayment } from 'store/tempDataSlice'
import { floor } from 'lodash'
import { useGetMeQuery } from 'store/apiSlice'

export const MakeContributions_12_LumpSumCustomAmount = ({ route, navigation }) => {
  const dispatch = useAppDispatch()
  const { nextScreen, contributionsData }  = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}
  
  const [showDialog, setShowDialog] = useState(false)  

  const workingLumpSumContributionPaymentData = useAppSelector(workingLumpSumContributionPayment)

  const { data: client, isLoading, error, refetch } = useGetMeQuery()

  const workingAmount = workingLumpSumContributionPaymentData?.amount
  const suggestedAmount = contributionsData?.newOneOffContribution

  const [isDisabled, setIsDisabled] = useState(!!suggestedAmount)

  const formObj = useForm<{
    amount: number
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: workingAmount || suggestedAmount
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid } } = formObj
  
  const lumpSumContributionAmount = watch('amount')

  const unlockFields = () => {
    setIsDisabled(false)
    setShowDialog(false)
  }

  const onSubmit = attributes => {
    dispatch(updateWorkingLumpSumContributionPayment(attributes))
    navigation.navigate(nextScreen)
  }
  
  const personalContribution = existingContributionConfiguration?.source === ContributionSource.PERSONAL
  const factor = personalContribution ? 1 + PERSONAL_TAX_RELIEF_FACTOR : 1

  const currentUser = useAppSelector(user)
  const isAdmin = hasAnyPermission(currentUser, [UserPermissions['contribute:any']])
  const minValue = isDisabled || isAdmin
    ? undefined
    : client?.segment?.minLumpSumAmount || MIN_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT

  const maxValue = floor(MAX_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT / (personalContribution ? (1 + PERSONAL_TAX_RELIEF_FACTOR) : 1))

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={suggestedAmount && isDisabled ? 'Confirm' : 'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={suggestedAmount && isDisabled ? `Confirm contribution amount` : 'How much would you like to contribute?'}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.PENSION_ANNUAL_ALLOWANCE} buttonTitle={'About Annual Allowances'}/>}
      textButtonTitle={suggestedAmount ? `Change Amount` : undefined}
      textButtonAction={suggestedAmount ? () => setShowDialog(true) : undefined}
      allowTextButton={suggestedAmount && isDisabled}
    >
      <ManagedCurrencyInput
        disabled={isDisabled}
        name={'amount'}
        formObj={formObj}
        placeholder={'Enter lump sum amount'}
        autoFocus={!suggestedAmount}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={minValue}
        maxValue={maxValue}
        blurOnSubmit={true}
        returnKeyType={"next"}
        withSubUnit={isDisabled ? true : false}
        informationMessage={personalContribution && lumpSumContributionAmount ? `Including government topup: ${formatCurrencyAmount(lumpSumContributionAmount * factor, 2)}` : undefined}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={'If you change the contribution amount, you may not achieve the planned goal'}
        onConfirm={unlockFields}
      />
    </ModalProcessScreen>
  )
}

