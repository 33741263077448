import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { JAR_NAME_ALL } from 'lib/constants'
import React from 'react'

export const StatementRequest_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, invite }  = route?.params || {}

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (    
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      headline={`Request a Statement`}
      illustrationFilename={'set_your_budget.png'} 
      subHeading={`Request an up-to-date statement for your ${JAR_NAME_ALL}`}
    >
    </ModalProcessIntroScreen>
  )
}
