import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { rootNavigate } from 'lib/RootNavigation'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useOptOutPendingEnrolmentByIdMutation } from 'store/apiSlice'
import { OptOutSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppSelector } from 'store/hooks'
import { workingEnrolmentOptOut } from 'store/tempDataSlice'

export const EnrolmentOptOut_99_Result = ({ route, navigation }) => {
  const { nextRoute, enrolmentId }: { nextRoute: string, enrolmentId: string }  = route?.params || {}
  const workingEnrolmentOptOutData = useAppSelector(workingEnrolmentOptOut)
    
  const {
    nationalInsuranceNo,
  } = workingEnrolmentOptOutData || {}

  const [ optOut, { data: optOutResult, error: optOutError, isLoading: optOutIsLoading } ] = useOptOutPendingEnrolmentByIdMutation()

  //Save or navigate on enter
  useEffect(() => {
    doOptOut()
  }, [])

  const doOptOut = () => {
    if (!workingEnrolmentOptOutData) {
      return
    }
    Logger.info(`Submitting opt out ...`)
    const body: OptOutSchemeEnrolmentDto = {
      id: enrolmentId,
      nationalInsuranceNo,
    }
    optOut(body)
  }

  //Navigate when report requested
  useEffect(() => {
    if (optOutResult) {
      end()
    }
  }, [optOutResult])

  const end = () => {
    rootNavigate(nextRoute, route?.params)
    //Reset for the next go...
    navigation.popToTop()
  }

  return (
    <ProcessScreen
      buttonTitle={''}
      error={optOutError}
      errorTryAgain={doOptOut}
      isLoading={optOutIsLoading}
      loadingMessage={['Submitting your decision...']}
      buttonAction={() => {}}
      showButton={false}
    />
  )
}
