import { ManagedSimpleChoiceInput } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeForecastTreatment_02_CustomFrequency = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}

  const dispatch = useAppDispatch()

  const { groupScheme } = enrolment || {}
  const { defaultContributionFrequency } = groupScheme || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  //Setup form
  const formObj = useForm<{ customPayrollFrequency: GroupSchemeEnrolmentPayrollFrequency }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      customPayrollFrequency: workingSetupSchemeEnrolmentData?.customPayrollFrequency || defaultContributionFrequency
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingSetupSchemeEnrolment(attributes))
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={`Next`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={true}
      headline={`How do you want to estimate pension contributions?`}
      subHeading={`This is the basis for the average pension contribution amount`}
    >
      <ManagedSimpleChoiceInput
        name={'customPayrollFrequency'}
        formObj={formObj}
        options={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency, undefined, [
          GroupSchemeEnrolmentPayrollFrequency.WEEKLY,
          GroupSchemeEnrolmentPayrollFrequency.MONTHLY,
          GroupSchemeEnrolmentPayrollFrequency.ANNUALLY,
        ])}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
