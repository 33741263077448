import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Subheading } from 'components/Typography'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Checkbox } from 'react-native-paper'
import { Flex, Paper, Sizing } from 'styles'
import { Button } from './Button'
import { useForm } from 'react-hook-form'

export type BulkSelectToolbarProps = {
  alwaysEnabled?: boolean
  enabled: boolean
  anySelected: boolean
  enableFunction: Function
  cancelFunction: Function
  selectAllFunction: Function
  unselectAllFunction: Function
  bulkActionFunction: Function
  bulkActionTitle: string
  bulkActionIconName?: string
  hideSelectAllCheckbox?: boolean
}

const isWeb = platformIsWeb()
const iconWidth = isWeb ? Sizing.x40 : Sizing.x30

export const BulkSelectToolbar = (props: BulkSelectToolbarProps) => {
  const { alwaysEnabled, enabled, anySelected, enableFunction, cancelFunction, selectAllFunction, unselectAllFunction, bulkActionFunction, bulkActionTitle, bulkActionIconName, hideSelectAllCheckbox } = props
  const { colors: themeColors } = Paper.useAppTheme()
  
  const formObj = useForm<{
    selectAll: boolean
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      selectAll: false
    },
  })
  
  const { handleSubmit, watch, reset, setValue } = formObj

  const handleSelectAll = () => {
    setValue('selectAll', true)
    selectAllFunction()
  }

  const handleUnselectAll = () => {
    setValue('selectAll', false)
    unselectAllFunction()
  }

  const selectAll = watch('selectAll')

  return (
    <View style={{
      ...Flex.row.between,
      alignItems: 'center',
    }}>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingHorizontal: Sizing.x2,
      }}>
        {
          alwaysEnabled || enabled
            ? <>
                <View style={{
                  width: iconWidth,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  }}>
                    {
                      hideSelectAllCheckbox ? <></> :
                        <Checkbox.Android
                          color={themeColors.accent}
                          uncheckedColor={themeColors.primary}
                          status={selectAll ? 'checked' : 'unchecked'}
                          onPress={() => {
                            if (selectAll) {
                              handleUnselectAll()
                            } else {
                              handleSelectAll()
                            }
                          }}
                        />
                      }
                </View>
              </>
            : <></>
        }
      </View>
      <View style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}>

        {
          enabled
            ? <Button
                mode={'text'}
                disabled={!anySelected}
                onPress={() => bulkActionFunction()}
                //@ts-ignore
                icon={() => <MaterialCommunityIcons name={bulkActionIconName || 'check-circle-outline'} size={Sizing.x20} color={themeColors.primary} />}
              >
                {bulkActionTitle}
              </Button>
            : <></>
        }
        {
          alwaysEnabled ? <></> :
          <Button
            mode={'text'}
            onPress={enabled ? () => cancelFunction() : () => enableFunction()}
            icon={() => <MaterialCommunityIcons name={enabled ? 'cancel': 'checkbox-multiple-outline'} size={Sizing.x20} color={themeColors.primary} />}
          >
            {enabled ? 'Cancel' : 'Bulk Select'}
          </Button>
        }
      </View>
    </View>
  )
}
