import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { getIncomeCategory, getIncomeIconName, RetirementIncomeCategory, sortIncomes } from 'lib/retirementIncomeHelpers'
import { max } from 'lodash'
import React, { useRef } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppSelector } from 'store/hooks'
import {
  onIncomeSpouseView
} from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

const Tab = createMaterialTopTabNavigator()

export enum IncomeTabScreenName {
  CLIENT = 'CLIENT',
  SPOUSE = 'SPOUSE',
}

interface RetirementIncomesListProps {
  addIncomeStateFunction: Function
  editIncomeStateFunction: Function
  addStatePensionStateFunction: Function
  editStatePensionStateFunction: Function
  addSpouseStateFunction: Function
  onNavigateFunction: Function
}

export const RetirementIncomesList = (props: RetirementIncomesListProps) => {
  const { addIncomeStateFunction, editIncomeStateFunction, addStatePensionStateFunction, editStatePensionStateFunction, addSpouseStateFunction, onNavigateFunction } = props

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const error: any = meError || incomesError
  const isLoading = meIsLoading || incomesIsLoading || spouseIsLoading

  const { colors: themeColors } = Paper.useAppTheme()

  const onSpouseTab = useAppSelector(onIncomeSpouseView)

  const incomeCount = incomes?.length || 0

  const clientIncomes = incomes.filter(income => {
    return income.clientId === me?.id
  })

  const clientIncomeCount = clientIncomes?.length || 0
  const spouseIncomeCount = incomeCount - clientIncomeCount
  const tableRows = max([clientIncomeCount, spouseIncomeCount]) + 2

  //Calculate navigator height
  //Tabs, table rows and top padding
  const rawNavigatorHeight = Sizing.x50 + (Sizing.x50 * tableRows) + Sizing.x20
  const navigatorHeight =
    error || isLoading ? Sizing.x400 //Loading
    : !spouse ? max([rawNavigatorHeight, Sizing.x300]) //Min height for spouse add
    : rawNavigatorHeight
    
  type IncomeListProps = {
    client: ClientBaseDto
    incomes: RetirementIncomeDto[]
  }
  const IncomeList = (props: IncomeListProps) => {
    const { client, incomes } = props
    
    const clientIncomes = incomes.filter((income) => {
      return income.clientId === client?.id
    })
  
    const scrollRef = useRef()

    return (
      <View style={localStyles.listContainer}>
        {client?.statePensionAmount ? (
          <StatePensionRow
            client={client}
            onPress={() => editStatePensionStateFunction(client)}
          />
        ) : (
          <>
            { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
                <AddRow
                  title={'Add state pension'}
                  onPress={() => addStatePensionStateFunction(client)}
                /> : <></>
            }
          </>
        )}
        {clientIncomes ? (
          sortIncomes(clientIncomes).map((income, idx) => {
            return (
              <RetirementIncomeRow
                key={idx}
                income={income}
                onPress={() => editIncomeStateFunction(income)}
              />
            )
          })
        ) : (
          <></>
        )}
        { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
          <AddRow
            title={'Add an income'}
            onPress={() => addIncomeStateFunction(client)}
          /> : <></>
        }
      </View>
    )
  }
  
  type AddRowProps = {
    title: string
    onPress?: any
  }
  const AddRow = (props: AddRowProps) => {
    const { title, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer}>
            <MaterialCommunityIcons
              name={'plus-circle'}
              size={Sizing.x20}
              color={themeColors.primary}
            />
          </View>
          <View>
            <Subheading>{title}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading> </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }
  

  type StatePensionRowProps = {
    client: ClientBaseDto
    onPress?: any
  }
  const StatePensionRow = (props: StatePensionRowProps) => {
    const { client, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const iconName: any = getIncomeIconName(RetirementIncomeCategory.STATE)
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{'State Pension'}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>
              {formatCurrencyAmount(client?.statePensionAmount)}
            </Subheading>
          </View>
        </Pressable>
        <ContentDivider />        
      </View>
    )
  }
  
  type RetirementIncomeRowProps = {
    income: RetirementIncomeDto
    onPress?: any
  }
  const RetirementIncomeRow = (props: RetirementIncomeRowProps) => {
    const { income, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const incomeCategory = getIncomeCategory(income.incomeType)
    const iconName: any = getIncomeIconName(incomeCategory, income.incomeType)
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={income?.id ? onPress : undefined}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{income.name}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>
              {formatCurrencyAmount(income.annualIncomeAmount)}
            </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }
  
  const ClientScreen = () => {
    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchIncomes()
    }

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your incomes...')}/> : <></>
            }
          </View>
          :
          <IncomeList
            client={me}
            incomes={incomes}
          />
        }
      </>
    )
  }
  
  const SpouseScreen = () => {
    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchIncomes()
    }

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your incomes...')} /> : <></>
            }
          </View>
          : spouse ?
          <IncomeList
            client={spouse}
            incomes={incomes}
          />
          :
          <View style={{...Flex.column.start }} >
            <View style={{
              marginTop: Sizing.x30,
              marginHorizontal: Sizing.x15,
            }}>
              <Paragraph style={{textAlign: 'center', paddingVertical: Sizing.x20}}>Add your partner to start creating your shared retirement universe together</Paragraph>
              <MaterialCommunityIcons style={{ alignSelf: 'center' }} onPress={() => addSpouseStateFunction(true)} name={'plus-circle'} size={Sizing.x60} color={Colors.brand.red3}/>
              <Button mode='text' onPress={() => addSpouseStateFunction(true)} color={Colors.brand.red3}>Add Partner</Button>
            </View>
          </View>
  
        }
      </>
    )
  }
  
  return (
    <View style={{ height: navigatorHeight }}>
      <Tab.Navigator
        screenListeners={{
          state: (e) => onNavigateFunction(e.data)
        }}
        initialRouteName={onSpouseTab ? IncomeTabScreenName.SPOUSE : IncomeTabScreenName.CLIENT}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }}
      >
        <Tab.Screen
          name={IncomeTabScreenName.CLIENT}
          component={ClientScreen}
          options={{ title: 'You' }}
        />
        <Tab.Screen
          name={IncomeTabScreenName.SPOUSE}
          component={SpouseScreen}
          options={{ title: spouse ? spouse?.firstName : 'Partner' }}
        />
      </Tab.Navigator>
    </View>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
    ...Flex.override.fill,
  },
  header: {
    ...Flex.row.between
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10
  },
  rowContainer: {
    height: Sizing.x50,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start
  },
  amountContainer: {
    ...Flex.override.right
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between
  }
})
