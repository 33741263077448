import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { unformatSortCode, formatSortCode } from 'lib/clientHelpers'
import { SORTCODE_MASK } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetup, workingContributionSetup } from 'store/tempDataSlice'

export const MakeContributions_04_SortCode = ({ route, navigation }) => {
  const dispatch = useAppDispatch()

  const { nextScreen, contributionsData } = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}

  const workingContributionData = useAppSelector(workingContributionSetup)

  const { bankDetails } = workingContributionData || {}

  const formObj = useForm<{ sortCode: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      sortCode: workingContributionData?.bankDetails?.sortCode
        ? formatSortCode(workingContributionData?.bankDetails?.sortCode)
          : undefined
    }
  })
  const {
    handleSubmit,
    formState: { isValid }
  } = formObj

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const onSubmit = attributes => {
    dispatch(updateWorkingContributionSetup({ bankDetails: { ...bankDetails, sortCode: unformatSortCode(attributes.sortCode) } }))
    next()
  }
  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`What's your bank sort code?`}
      subHeading={workingContributionData?.source === ContributionSource.PERSONAL
        ? `For your personal account`
        : `For your business bank account`
      }
    >
      <ManagedTextInput
        name={'sortCode'}
        autoFocus={true}
        returnKeyType={"next"}
        keyboardType={'numeric'}
        formObj={formObj}
        mask={{
          type: 'custom',
          options: {
            mask: SORTCODE_MASK,
          }
        }}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        rules={{

          required: true,
          minLength: {
            value: 8,
            message: 'Must be 6 characters'
          },
          maxLength: {
            value: 8,
            message: 'Must be 6 characters'
          },

        }} />
    </ModalProcessScreen>
  )
}

