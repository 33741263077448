import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Subheading } from "components/Typography"
import { Button } from "components/Utility/Button"
import { Logger } from "lib/logger"
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer, scaleUnnormalizer } from "lib/scaleHelpers"
import React, { useRef, useState } from 'react'
import { View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import SignatureScreen from 'react-native-signature-canvas'
import { ClientSignatureDto } from "store/dto/client.dto"
import { Colors, Flex, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import * as signatureBackground from './signatureBackground.json'

type ClientSignatureModalProps = {
  clientSignature: ClientSignatureDto
  visible: boolean
  onDismiss: any
  onSave: Function
}

export const ClientSignatureModal = (props: ClientSignatureModalProps) => {
  const { clientSignature, onDismiss, onSave, visible } = props

  // const [isValid, setIsValid] = useState(undefined)
  const [signature, setSignature] = useState(false)

  const signRef = useRef()

  const insets = useSafeAreaInsets()
  const screenHeight = getScreenAppHeight()
  const screenWidth = getScreenAppWidth()
  const modalHeight = screenHeight - insets.top - insets.bottom
  
  const signaturePadWidth = screenWidth - Sizing.x100
  const signaturePadHeight = modalHeight - scaleUnnormalizer(Sizing.x120)

  const handleEnd = () => {
    signRef.current.readSignature()
  }

  const handleOk = (data) => {
    // checkDimensions(data)
    setSignature(data)
  }

  const handleClear = () => {
    signRef.current.clearSignature();
    setSignature(false)
  }

  // Called after ref.current.getData()
  const handleSubmit = () => {
    onSave(signature)
  }
  
  const webStyle = `.m-signature-pad--footer
    .save {
        display: none;
    }
    .clear {
        display: none;
    }

    .m-signature-pad--body {
      position: absolute;
      border: 0px solid #f4f4f4;
      width: ${signaturePadHeight}px;
      height: ${signaturePadWidth}px;
    }

    .m-signature-pad {
      position: absolute;
      font-size: 10px;
      border: 0px solid #e8e8e8;
      background-color: #fff;
    }

    body, html {
      font-family: Helvetica, Sans-Serif;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  `

  const bgSrc = signatureBackground.base64

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={{...
          Flex.column.start,
          ...Flex.override.fill,
        }}>
          <View style={{
           ...Flex.column.end,
           ...Flex.override.fill,
          }}>
            <View style={{
              backgroundColor: Colors.neutral.white,
              borderRadius: Sizing.x10,
              paddingVertical: Sizing.x10,
              paddingHorizontal: Sizing.x10,
              width: signaturePadWidth + Sizing.x60,
              height: signaturePadHeight + Sizing.x20,
              alignSelf: 'center',
            }}>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                overflow: 'hidden',
              }}>
                <View style={{
                  //Margin adjustment is needed her because iOS doesn't support borderRightWidth
                  //So we use borderWidth for dotted line and set margins to hide others
                  width: Sizing.x40,
                  height: signaturePadHeight + scaleNormalizer(4), //Added to allow for margin adjustment 
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderWidth: Sizing.x1,
                  margin: Sizing.x2 * -1,
                  marginRight: Sizing.x2,
                  borderStyle: 'dashed',
                }}>
                  <Subheading style={{
                    width: signaturePadHeight,
                    transform: [{ rotate: '90deg' }]
                  }}>
                    {`Please sign above the line`}
                    </Subheading>
                </View>
                <View style={{
                  width: signaturePadWidth,
                  height: signaturePadHeight,
                  alignSelf: 'center',
                  justifyContent: 'center',
                }}>
                  <SignatureScreen
                    ref={signRef}
                    // bgSrc={bgSrc}
                    // bgHeight={signaturePadHeight}
                    // bgWidth={signaturePadWidth}
                    // dataURL={dataUrl}
                    onOK={handleOk}
                    onEnd={handleEnd}
                    webStyle={webStyle}
                    autoClear={false}
                    descriptionText={''}
                    trimWhitespace={true}
                    rotated={true}
                    penColor={Colors.brand.purple1}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={{
            ...Flex.override.bottom,
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: Sizing.x50,
          }}>
            <Button onPress={handleClear} disabled={!signature} mode={'text'} color={Colors.brand.red2} >{'Clear'}</Button>
            <Button onPress={handleSubmit} disabled={!signature} mode={'text'} color={Colors.brand.purple1}>{'Save'}</Button>
            {/* <FooterButton onPress={handleClear} mode={'text'} >{'Clear'}</FooterButton>
            <FooterButton onPress={handleSubmit} disabled={!signature} mode={'contained'} >{'Save Signature'}</FooterButton> */}
          </View>
        </View>
      </>
    </CardModal>
  )
}
