import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { format, formatISO } from 'date-fns'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { getSuggestedStep, OnboardingPhase, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { Typography } from 'styles'

export const RetirementProfileSetupHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: retirementProfile, isLoading: rpIsLoading, error: rpError } = useGetRetirementProfileQuery()

  const isLoading = clientIsLoading || spouseIsLoading || rpIsLoading
  const error: any = clientError || rpError

  const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0
  const yearsUntilRetirement = client ? retirementProfile?.targetRetirementAge - clientAge : 0
  const yearsInRetirement = client && retirementProfile ? client?.lastSurvivorDeathAge - retirementProfile.targetRetirementAge : 0

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'set_your_budget.png',
      headline: `Excellent!`,
      subHeading: `You've taken the most important step: you've defined the kind of lifestyle you want to retire into`,
    },
    {
      illustrationFilename: 'clock.png',
      headline: `The clock is ticking...`,
      subHeading:
        <Subheading>
          {`If you can achieve it, you're seeking to retire in about `}
          <Subheading style={Typography.fontWeight.bold}>{`${yearsUntilRetirement} year${yearsUntilRetirement === 1 ? '' : 's'}`}</Subheading>
          {`, in ${retirementProfile ? format(new Date(retirementProfile?.targetRetirementDate), 'yyyy') : ''}`}
        </Subheading>,
    },
    {
      illustrationFilename: spouse
        ? 'combined_retirement_timeline.png'
        : 'retirement_timeline.png',
      headline: `Retirement Timeline`,
      subHeading:
        <Subheading>
        {`Based on your ${spouse ? 'combined ' : ''}life expectancy, you would have about `}
        <Subheading style={Typography.fontWeight.bold}>{`${yearsInRetirement} years `}</Subheading>
        {`in retirement`}
    </Subheading>,
    subHeadingInfo: spouse
     ? <NamedInformationButton name={NamedInformation.COMBINED_LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
     : <NamedInformationButton name={NamedInformation.LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
    },
    {
      illustrationFilename: 'combined_retirement_timeline.png',
      headline: `But how to get there?`,
      subHeading: `Jarvis can help you understand how close you are to your goals and how to unlock your financial freedom`,
    },
  ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
