import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useLazyGetPensionBrandsQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminPensionBrandDataId, setShowAdminAddPensionBrandVisible } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { AdminPensionBrandListItem } from '../Components/AdminPensionBrandListItem'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminPensionBrandsListScreen = () => {
  const [getPensionBrandsQuery, { data: brands, isLoading: brandsIsLoading, error: brandsError, isFetching: brandsIsFetching }] = useLazyGetPensionBrandsQuery()
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowPensionBrandDetails = (id: string) => {
    dispatch(setCurrentAdminPensionBrandDataId(id))
  }

  const brandsList = () => {
    let all = []
    if (brands) {
      brands.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <AdminPensionBrandListItem
        brand={item}
        onPressFunction={() => handleShowPensionBrandDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{ search: string, raindropId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  const { handleSubmit, reset } = formObj

  const onSubmit = attributes => {
    getPensionBrandsQuery(attributes)
  }

  const isLoading = brandsIsLoading || brandsIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const handleAddPensionBrand = () => {
    dispatch(setShowAdminAddPensionBrandVisible(true))
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      raindropId: '',
    })
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:system']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding brands...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for brand'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of key, name, description'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedTextInput
                      name={'raindropId'}
                      label={'Raindrop ID'}
                      autoFocus={false}
                      formObj={formObj}
                      placeholder={'Enter full Raindrop UUID'}
                      blurOnSubmit={false}
                      rules={{
                        minLength: 32,
                        maxLength: 32,
                      }} />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
              >
                <OptionalSectionList
                  noRefresh={true}
                  sections={brandsList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a brand`}
                />
              </ScrollView>
              <FooterButton
                onPress={handleAddPensionBrand}
              >
                {'Add New Brand'}
              </FooterButton>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
