import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { RetirementIncomesScreen } from './Screens/RetirementIncomesScreen'

export const RegularIncomeSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Retirement Incomes'}
    >
      <RetirementIncomesScreen />
    </SectionSimpleContainer>
  )
}

