import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { InvestmentTypeChoice } from "store/tempDataSlice"
import { Colors, Sizing } from "styles"
import { JAR_NAME_GROUP, JAR_NAME_PERSONAL } from "lib/constants"
import { ContentDivider } from "components/Layout/ContentDivider"
import { AppIllustration } from "components/Utility/AppIllustration"

type InvestmentTypeModalContentProps = {
  investmentType: InvestmentTypeChoice
  illustrationFilename?: string
  imageSource?: any
  groupPortfolioChoiceDisabled: boolean
  assetAllocationChoiceDisabled: boolean
}

export const InvestmentTypeModalContent = (props: InvestmentTypeModalContentProps) => {
  const { investmentType, imageSource, illustrationFilename, groupPortfolioChoiceDisabled, assetAllocationChoiceDisabled } = props

  const isFollow = investmentType === InvestmentTypeChoice.FOLLOW_PERSONAL
  const isUnfollow = investmentType === InvestmentTypeChoice.UNFOLLOW_PERSONAL
  const isGroupPortfolio = investmentType === InvestmentTypeChoice.GROUP_PORTFOLIO
  const isPlan = investmentType === InvestmentTypeChoice.PLAN

  const title = isFollow
    ? 'Simplified Investment'
    : isUnfollow
      ? 'Separate Strategies'
      : isGroupPortfolio
        ? groupPortfolioChoiceDisabled
          ? 'Coming Soon!'
          : `Managed Portfolios`
        : isPlan
          ? `Ready-made Plans`
          : assetAllocationChoiceDisabled
            ? 'Coming Soon!'
            : `Build your own Plan`

  const shortDescription = isFollow
    ? `Set your ${JAR_NAME_GROUP} to follow your ${JAR_NAME_PERSONAL} investment strategy`
    : isUnfollow
      ? `Invest your ${JAR_NAME_GROUP} using a different strategy from your ${JAR_NAME_PERSONAL}`
      : isGroupPortfolio
        ? `Choose from our range of discretionary managed portfolios`
        : isPlan
          ? `Choose from our range of ready-made investment plans`
          : `Build your own plan from individual funds`

  const longDescription = isFollow
    ? `By setting your ${JAR_NAME_GROUP} to use the same investment strategy as your ${JAR_NAME_PERSONAL}, you'll simplify future changes - we'll ensure that whichever plan, portfolio or self-built portfolio you choose is automatically applied to both jars.`    
    : isUnfollow
      ? `By separating your investment strategies for your ${JAR_NAME_GROUP} and your ${JAR_NAME_PERSONAL}, you'll be able to pick a different way of investing for each.`
      : isGroupPortfolio
        ? groupPortfolioChoiceDisabled
          ? `We'll shortly be offering the ability to pick from a range of discretionary managed portfolios, designed for workplace pension, but available to all!`
          : `Expore a range of discretionary managed portfolios, designed for our workplace pension, but available to all!`
        : isPlan
          ? `If you're new to investing, our pre-built plans offer a simple way to select an investment strategy based on your personal risk appetite.`
          : assetAllocationChoiceDisabled
            ? `We'll shortly be offering the ability to pick from a wider range of funds in the market, or even build a portfolio from a mix. For now, please choose from one of our ready-made investment plans, and you'll be able to switch as soon as we've launched the new offering!`
            : `For more experienced investors, explore our full range of available funds, and either invest in one, or build a portfolio from a mix.`

  return (
    <>
      <Headline style={localStyles.title}>{title}</Headline>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{shortDescription}</Subheading>
      <View style={{
        alignSelf: 'center',
      }}>
        {
          imageSource
            ? <Image source={imageSource} style={[
                {
                  width: scaleNormalizer(200),
                  height: scaleNormalizer(200),
                  resizeMode: 'contain',
                  alignSelf: 'center',
                },
              ]} />
            : <AppIllustration filename={illustrationFilename || 'info.png'} style={[
                {
                  width: scaleNormalizer(200),
                  height: scaleNormalizer(200),
                  resizeMode: 'contain',
                  alignSelf: 'center',
                },
              ]} />
        }
      </View>
      <Paragraph style={localStyles.text}>{longDescription}</Paragraph>
      <Paragraph style={localStyles.text}>{`With Jarvis, you can change your investment strategy any any time, including choosing from our pre-built plans or our discretionary managed portfolios, or tweaking your own portfolio.`}</Paragraph>
      <Paragraph style={localStyles.text}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Paragraph>
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})