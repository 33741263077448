import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem } from 'components/Inputs/ManagedAutoCompleteInput'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { orderBy, startCase } from 'lodash'
import React from 'react'
import { RetirementAssetTransferAttempt, RetirementAssetTransferDto, RetirementAssetTransferStatus } from 'store/dto/retirement-asset.dto'
import { Colors, Paper, Sizing } from 'styles'

type AdminTransferListItemProp = {
  transfer: RetirementAssetTransferDto
  providerOption: AutoCompleteItem
  onPressFunction?: () => void
}
export const AdminTransferListItem = (props: AdminTransferListItemProp) => {
  const { transfer, providerOption, onPressFunction } = props
  const { clientFirstName, clientSurname, clientEmail, transferStatus, transferAttempts, currentValue, transferStaleDays } = transfer
  const { icon, label } = providerOption || {}

  const sortedAttempts = orderBy(transferAttempts, ['updatedAt'], ['desc'])
  const currentTransferAttempt: RetirementAssetTransferAttempt = sortedAttempts.length ? sortedAttempts[0] : undefined

  const { colors: themeColors } = Paper.useAppTheme()

  const subTitle = `${clientFirstName} ${clientSurname} (${clientEmail})`
  const titleRight = currentTransferAttempt
    ? formatCurrencyAmount(currentTransferAttempt?.transferValue, 2)
    : formatCurrencyAmount(currentValue, 2)
  
  const staleStatuses = [
    RetirementAssetTransferStatus.INITIATED,
    RetirementAssetTransferStatus.ACTIVE,
    RetirementAssetTransferStatus.REJECTED,
    RetirementAssetTransferStatus.MODIFIED,
    RetirementAssetTransferStatus.WITH_SUPPORT,
  ]
  const daysText = staleStatuses.includes(transferStatus)
    ? ` (Stale for ${transferStaleDays} day${transferStaleDays === 1 ? '' : 's'})`
    : ``
  const subTitleRight = `${startCase(transferStatus)}${daysText}` 

  return (
    <DetailedListItem
      icon={icon ? icon() : <MaterialCommunityIcons name={'dots-horizontal'} size={Sizing.x30} color={Colors.neutral.black} />}
      onPress={onPressFunction}
      title={label ? label : '<Unknown Provider>'}
      titleRight={titleRight}
      subTitle={subTitle}
      subTitleRight={<Text>{subTitleRight}</Text>}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
