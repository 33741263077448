import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { hexColorWithOpactity } from 'lib/colorHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import React, { ReactNode } from 'react'
import { Clipboard, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useAppDispatch } from 'store/hooks'
import { setSnackbarData } from 'store/uxSlice'
import { Colors, Paper, Flex, Sizing, Typography } from 'styles'

const isWeb = platformIsWeb()

export type UnborderedTableProps = {
  error?: any
  isLoading?: boolean
  isFetching?: boolean
  data: UnborderedTableRow[]
  itemContainerStyle?: StyleProp<ViewStyle>
  noContentDivider?: boolean
}

export type UnborderedTableRow = {
  label: string | ReactNode  
  subLabel?: string | ReactNode  
  value: string | ReactNode
  subValue?: string | ReactNode  
  valueEditIcon?: boolean
  isLoading?: boolean
  isFetching?: boolean
  linkFunction?: any
  iconSource?: React.ReactNode
  isHeader?: boolean
  isTotal?: boolean
  copyableValue?: string | boolean
}

export const UnborderedTable = (props: UnborderedTableProps) => {
  const { error, isLoading, isFetching, data, itemContainerStyle, noContentDivider } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const dispatch = useAppDispatch()

  const copyToClipboard = async (text) => {
    await Clipboard.setString(text)
    dispatch(setSnackbarData({
      message: `Copied: "${text}"`,
      iconName: 'content-copy',
    }))
  }
  
  return (
    <View style={localStyles.container}>
      {
        isLoading || isFetching ? <Loading /> : error ? <ErrorScreen error={error?.data} />
          :
              <View style={localStyles.tableContainer}>
              {
                data.map((row, idx) => {
                  const { copyableValue, value } = row
                  const copyText = copyableValue === true ? value : copyableValue || ""
                  return (
                    <Pressable key={idx} onPress={row.linkFunction}>
                      <View style={localStyles.tableRowStyle}>
                        <View style={[
                          localStyles.itemContainer,
                          itemContainerStyle,
                          // idx < data.length - 1 ? [
                          //   localStyles.itemContainerBorder,
                          //   {
                          //     borderBottomColor: hexColorWithOpactity(themeColors.themeType === 'dark' ? Colors.neutral.black : Colors.neutral.white, 0.5)
                          //   }
                          //  ] : {}
                        ]}>
                          { row.iconSource ?
                            <View style={localStyles.iconContainer}>
                              {row.iconSource}
                            </View>
                            : <></>
                          }
                          <View style={localStyles.labelContainer}>
                            <Paragraph numberOfLines={1} style={[localStyles.tableItemLabel,
                            row.isHeader ? localStyles.tableItemHeader : {},
                            row.isTotal ? localStyles.tableItemTotal : {},
                            ]}>{row.label}</Paragraph>
                            {
                              row.subLabel ? <Paragraph style={localStyles.tableItemSubLabel} >{row.subLabel}</Paragraph> : <></>
                            }
                          </View>
                          { row.valueEditIcon ?
                            <View style={localStyles.iconContainer}>
                              <MaterialCommunityIcons name={'pencil-outline'} size={Sizing.x15} color={themeColors.primary} />
                            </View>
                            : <></>
                          }
                          <View style={localStyles.copyableValueContainer}>
                            {
                              row.copyableValue
                                ? <View style={localStyles.copyContainer}>
                                    <MaterialCommunityIcons onPress={() => copyToClipboard(copyText)} name={'content-copy'} size={Sizing.x15} color={Colors.brand.purple2} />
                                  </View>
                                : <></>
                            }
                            <Pressable
                              onPress={row.copyableValue ? () => copyToClipboard(copyText) : row.linkFunction}                              
                              style={localStyles.valueContainer}
                            >
                              <Paragraph style={[localStyles.tableItemValue,
                                row.isHeader ? localStyles.tableItemHeader : {},
                                row.isTotal ? localStyles.tableItemTotal : {},
                              ]}>
                                { row.isLoading || row.isFetching ? <Loading size={Sizing.x15}/> : row.value }
                                {
                                  row.subLabel
                                    ? row.isLoading || row.isFetching
                                      ? <></>
                                      : <Paragraph style={localStyles.tableItemSubLabel} >{row.subValue}</Paragraph>
                                    : <></>
                                }
                              </Paragraph>
                            </Pressable>
                          </View>

                        </View>
                        {
                          !noContentDivider && idx < data.length - 1 ? <ContentDivider /> : <></>
                        }
                      </View>
                    </Pressable>
                  )
                })
              }
            </View>
          }
    </View>
  )
}

const localStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    // maxWidth: isWeb ? scaleNormalizer(800) : undefined
  },
  tableRowStyle: {},
  labelContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    ...Flex.override.fill,
    textAlign: 'left',
    // maxWidth: '50%',
  },
  iconContainer: {
    paddingHorizontal: Sizing.x10,
    alignSelf: 'center',
  },
  copyContainer: {
    alignSelf: 'center',
    ...Flex.override.right,
  },
  itemContainer: {
    paddingVertical: Sizing.x10,
    ...Flex.row.start,
  },
  itemContainerBorder: {
    borderBottomWidth: 0.5,
  },
  navContainer: {
    ...Flex.row.center
  },
  copyableValueContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    maxWidth: '50%',
  },
  valueContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
    ...Flex.override.right,
    maxWidth: '100%',
  },
  tableItemLabel: {
    ...Typography.fontSize.x15,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'left'
  },
  tableItemSubLabel: {
    paddingTop: Sizing.x0,
    ...Typography.fontSize.x10,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'left'
  },
  tableItemValue: {
    ...Typography.fontSize.x15,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'right',
  },
  tableItemHeader: {
    fontFamily: 'LabGrotesque-Regular',
  },
  tableItemTotal: {
    fontFamily: 'LabGrotesque-Medium',
  }
})