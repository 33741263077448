import { concat, last, take, indexOf } from 'lodash'
import React from 'react'
import { View } from 'react-native'
import { MakeContributionsData, MakeContributionsStepIdentifier } from 'store/uxSlice'
import { TaskRow, TaskRowProps } from './TaskRow'

export type TaskListProps = {
  contributionsData: MakeContributionsData
  nextTask: MakeContributionsStepIdentifier
  nextTaskAction: any
  allComplete?: boolean
}

export const TaskList = (props: TaskListProps) => {
  const { contributionsData, nextTask, nextTaskAction, allComplete } = props
  const { makeContributionSteps, existingContributionConfiguration, existingRegularContribution } = contributionsData || {}

  const nextTaskIndex = indexOf(makeContributionSteps, nextTask)

  const allOrderedSteps = [
    MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE,
    MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION,
    MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION,
    MakeContributionsStepIdentifier.TARGET_AGE,
    MakeContributionsStepIdentifier.CANCEL_CONTRIBUTIONS,
  ]

  const orderedSteps = allOrderedSteps.filter(step => {
    return makeContributionSteps.includes(step)
  })

  const getStepTitle = (identifier: MakeContributionsStepIdentifier): string => {
    switch (identifier) {
      case MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE:
        return existingContributionConfiguration ? 'Change Contribution Source' : 'Set Up Contribution Source'
      case MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION:
        return 'Make One-Off Contribution'
      case MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION:
        return existingRegularContribution?.amount ? 'Change Monthly Contributions' : 'Set Up Monthly Contributions'
      case MakeContributionsStepIdentifier.TARGET_AGE:
        return 'Update Target Retirement Age'
      case MakeContributionsStepIdentifier.CANCEL_CONTRIBUTIONS:
        return 'Cancel Monthly Contributions'
      default:
        return `Unknown Task`
    }
  }

  const newTasks: TaskRowProps[] = []

  orderedSteps.forEach((orderedStep, idx) => {
    if (makeContributionSteps.includes(orderedStep)) {
      newTasks.push({
        identifier: orderedStep,
        title: getStepTitle(orderedStep),
        isLast: false,
        isNext: idx === nextTaskIndex,
        isComplete: allComplete || idx < nextTaskIndex, 
        nextTaskAction: idx === nextTaskIndex ? nextTaskAction : undefined,
      })
    }
  })

  //Set isLast on the final item
  const tasks: TaskRowProps[] = newTasks.length
  ? concat(take(newTasks, newTasks.length - 1), [{
      ...last(newTasks),
      isLast: true
    }])
  : newTasks

    return (
      <View>
        {
          tasks.map((taskProps, idx) => {
            return <TaskRow
              key={idx}
              {...taskProps}
            />
          })
        }
      </View>
    )    
}
