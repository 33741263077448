import { FeatureCode, FeatureStatus, UserFeatureDto } from 'store/dto/feature.dto'

export const featureIsAvailable = (features: UserFeatureDto[], featureCode: FeatureCode): boolean => {
  const feature = features ? features.find(feature => {
    return feature.code === featureCode
  }) : undefined
  return feature && feature?.status !== FeatureStatus.DISABLED
}

export const featureIsEnabledForUser = (features: UserFeatureDto[], featureCode: FeatureCode): boolean => {
  const feature = features ? features.find(feature => {
    return feature.code === featureCode
  }) : undefined
  return !!feature?.enabled
}
