import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { useGetMeQuery, useGetSpouseQuery } from 'store/apiSlice'
import { BeneficiaryRelationship } from 'store/dto/account.dto'

export const BeneficiariesAddBeneficiary_02_FirstName = ({
  route,
  navigation
}) => {
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const dispatch = useAppDispatch()
  const {nextScreen}  = route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, refetch: refetchSpouse } = useGetSpouseQuery()

  const isLoading = clientIsLoading || spouseIsLoading
  const error = clientError

  const isPartner = [BeneficiaryRelationship.PARTNER, BeneficiaryRelationship.WIFE, BeneficiaryRelationship.HUSBAND].includes(workingBeneficiaryData?.beneficiaryRelationship)

  const formObj = useForm<{firstName: string}>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName:
      workingBeneficiaryData?.firstName
        ? workingBeneficiaryData?.firstName
        : isPartner
          ? spouse
            ? spouse?.firstName
            : ''
          : ''
    }
  })  
  const { handleSubmit, formState: {isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingBeneficiary(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      isLoading={isLoading}
      error={error}
      errorTryAgain={clientError ? refetchClient : refetchSpouse}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What's their first name?`}
      subHeading={'Their legal name as it appears on their passport or ID'}
    >
      <ManagedTextInput
        placeholder={'Their legal first name'}
        name={'firstName'}
        returnKeyType="next"
        autoFocus={true}
        blurOnSubmit={false}
        formObj={formObj}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20
        }}
      />
    </ModalProcessScreen>
  )
}
