import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { getModelChangeMessages } from 'lib/loadingHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import { concat } from 'lodash'
import { useGuidanceContext } from 'providers'
import React from 'react'
import { useGetBeneficiariesQuery, useGetSpouseQuery } from 'store/apiSlice'

export const OnboardingPhaseFamilyHeroScreen = ({ route, navigation }) => {

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching } = useGuidanceContext()
  const { data: beneficiaries, error: beneficiariesError, isLoading: beneficiariesIsLoading, isFetching: beneficiariesIsFetching, refetch: refetchBeneficiaries } = useGetBeneficiariesQuery()

  const isLoading = beneficiariesIsLoading || beneficiariesIsFetching || currentViableAgeIsLoading || currentViableAgeIsFetching
  const error = beneficiariesError
  
  const nominations = beneficiaries?.nominations || []
  const hasBeneficiaries = nominations.length > 0

  const carouselScreens: ProcessHeroCarouselData[] = concat([
    {
      illustrationFilename: 'great_partnership.png',
      headline: `You've got everything organised`,
      subHeading: `You can feel confident you've thought everything through`,
    },
  ],
  hasBeneficiaries ? [
    {
      illustrationFilename: 'choose_beneficiaries.png',
      headline: `Know your wishes are recorded`,
      subHeading: nominations?.length > 1
        ? `You can view and manage your beneficiaries in the Legacy tab on the Dashboard`
        : `You can always add further beneficiaries in the Legacy tab on the Dashboard`
    },
    {
      illustrationFilename: 'magnifying_glass.png',
      headline: `Keep an eye on inhertance`,
      subHeading: currentViableAge
        ? `If Jarvis predicts you may have surplus capital, we will also show you how much each could receive`
        : `Complete the 'Plan Your Retirement' card to additionally see how much they could inherit`,
    },
  ] : [{
    illustrationFilename: 'choose_beneficiaries.png',
    headline: `If things change...`,
    subHeading: `If your circumstances change, you can add beneficiaries in the Legacy tab on the Dashboard`,
  }]
  )

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      loadingMessage={getModelChangeMessages()}
      error={error}
      errorTryAgain={refetchBeneficiaries}
      errorCancel={goToDashboard}
      carouselScreens={carouselScreens}
      onFinish={goToDashboard}
      finishButtonLabel={'Return to my Dashboard'}
    />
  )
}
