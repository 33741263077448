import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerEnrolmentsListScreen } from './Screens/EmployerEnrolmentsListScreen'

export const EmployerEnrolmentsSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Enrol Members'}
    >
      <EmployerEnrolmentsListScreen />
    </SectionSimpleContainer>
  )
}

