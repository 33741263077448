import { useFocusEffect } from '@react-navigation/native'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Headline, Paragraph } from 'components/Typography'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { useGetMeQuery, useGetSpouseQuery, useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, updateWorkingSpouse, workingClient, workingSpouse } from 'store/tempDataSlice'
import { Colors, Sizing } from 'styles'

export const RetirementIncomeSetup_05_StatePensionSave = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const dispatch = useAppDispatch()

  const workingClientData = useAppSelector(workingClient)
  const workingSpouseData = useAppSelector(workingSpouse)

  const [allDataSaved, setAllDataSaved] = useState(false)

  useFocusEffect(() => {
    if (workingClientData === undefined && workingSpouseData === undefined) {
      setAllDataSaved(true)
    }
  })

  const { data: me, error: meError, isLoading: meIsLoading, isFetching: meIsFetching } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const [ updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading } ] = useUpdateMeMutation()
  const [ updateSpouse, { data: updatedSpouse, error: spouseUpdateError, isLoading: spouseUpdateIsLoading } ] = useUpdateSpouseMutation()

  const isLoading = meIsLoading || spouseIsLoading || clientUpdateIsLoading || spouseUpdateIsLoading || spouseIsFetching || meIsFetching
  const error: any = meError || clientUpdateError || spouseUpdateError

  //Save on enter
  useEffect(() => {
    if (workingClientData?.statePensionAmount !== undefined) {
      saveClient()
    } else if (workingSpouseData?.statePensionAmount !== undefined) {
      saveSpouse()
    } else {
      end()
    }
  }, [])

  //Save spouse (if needed) when client saved, else end
  useEffect(() => {
    if (updatedClient) {
      if (workingSpouseData?.statePensionAmount !== undefined) {
        saveSpouse()
      } else {
        end()
      }
      
    }
  }, [updatedClient])

  //End if successful
  useEffect(() => {
    if (updatedSpouse) {
      end()
    }
  }, [updatedSpouse])
  
  const saveClient = () => {
    Logger.info(`Updating Client state pension...`)
    updateClient({
      statePensionAmount: workingClientData?.statePensionAmount,
    })
  }

  const saveSpouse = () => {
    Logger.info(`Updating Spouse state pension...`)
    updateSpouse({
      statePensionAmount: workingSpouseData?.statePensionAmount,
    })
  }
    
  const end = () => {
    ampli.statePensionSet({
      amount: workingClientData?.statePensionAmount,
      isSpouse: false,
    })
    if (updatedSpouse) {
      ampli.statePensionSet({
        amount: workingSpouseData?.statePensionAmount,
        isSpouse: true,
      })  
    }
    batch(() => {
      dispatch(updateWorkingClient(undefined))
      dispatch(updateWorkingSpouse(undefined))
    })
    navigation.navigate(nextScreen)
  }

  const backToStatePensions = () => {
    setAllDataSaved(false)
    navigation.navigate('StatePensionChoice')
  }

  return (
    <ProcessScreen
      headline={allDataSaved ? `Modify state pension${spouse ? 's' : ''}?` : undefined}
      subHeading={allDataSaved ? 'Do you want to go back and make changes?' : undefined}
      allowTextButton={allDataSaved}
      textButtonTitle={allDataSaved ? 'Leave as-is' : ''}
      textButtonAction={allDataSaved ? end : undefined}
      buttonTitle={allDataSaved ? 'Make Changes' : ''}
      error={error}
      errorTryAgain={updatedClient ? saveSpouse : saveClient}
      isLoading={isLoading}
      loadingMessage={clientUpdateIsLoading || spouseUpdateIsLoading ? ['Saving...'] : undefined}
      buttonAction={allDataSaved ? backToStatePensions : () => {}}
      showButton={allDataSaved}
    >
      <Paragraph>{'Your State Pension'}</Paragraph>
      <Headline style={{ color: Colors.brand.red3 }}>{formatCurrencyAmount(me?.statePensionAmount)}</Headline>
      {
        spouse
          ? <>
              <Paragraph style={{paddingTop: Sizing.x30}}>{`${spouse?.firstName}'s State Pension`}</Paragraph>
              <Headline style={{ color: Colors.brand.red3 }}>{formatCurrencyAmount(spouse?.statePensionAmount)}</Headline>
            </>
          : <></>
      }
    </ProcessScreen>
  )
}
