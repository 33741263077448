import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Text } from 'components/Typography/Text'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import React from 'react'
import { ClientMeDto } from 'store/dto/client.dto'
import { UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingClient } from 'store/tempDataSlice'

export const ClientSetup_01_Shortcut = ({ route, navigation }) => {
  const { nextScreen, existingClient, metadata }: { nextScreen: string, existingClient: ClientMeDto, metadata: UserMetadata } = route?.params || {}

  const dispatch = useAppDispatch()

  const takeShortcut = () => {
    if (!existingClient && metadata) { 
      dispatch(updateWorkingClient({
        title: metadata?.title,
        firstName: metadata?.firstName,
        surname: metadata?.surname,
        gender: metadata?.gender,
        birthDate: metadata?.birthDate,
      }))
    }
    navigation.navigate('Terms')
  }

  const next = () => {
    dispatch(updateWorkingClient(metadata))
    navigation.navigate(nextScreen)
  }

  const tableData: UnborderedTableRow[] = [
    {
      label: `Title`,
      value: <Text style={{ fontWeight: '900' }}>{metadata?.title}</Text>,
    },
    {
      label: `Legal First Name`,
      value: <Text style={{ fontWeight: '900' }}>{metadata?.firstName}</Text>,
    },
    {
      label: `Legal Surname`,
      value: <Text style={{ fontWeight: '900' }}>{metadata?.surname}</Text>,
    },
    {
      label: `Gender`,
      value: <Text style={{ fontWeight: '900' }}>{metadata?.gender}</Text>,
    },
    {
      label: `Birth Date`,
      value: <Text style={{ fontWeight: '900' }}>{format(new Date(metadata?.birthDate), 'dd/MM/yyyy')}</Text>,
    },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Everything looks good'}
      buttonAction={takeShortcut}
      enableButton={true}
      headline={`Do all these details look correct?`}
      subHeading={'Please check through carefully and confirm '}
      allowTextButton={true}
      textButtonTitle={`Something isn't quite right`}
      textButtonAction={next}
    >
      <UnborderedTable
        data={tableData}
      />
    </ProcessScreen>
  )
}
