import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { Logger } from 'lib/logger'
import { default as React, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editAvatarClient, setEditAvatarClient } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'

export const ClientAvatarEditModal = () => {
  return (
  <ModalEditWrap
    screen={<ScreenContent
    />}
  />
)
}

const ScreenContent = () => {

  const client = useAppSelector(editAvatarClient)

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditAvatarClient(undefined))

  const visible = !!useAppSelector(editAvatarClient)
  const onDismiss = globalDismiss

  const isSpouse = client?.isSpouse
  
  const [ updateMe, { data: updatedMe, isLoading: updateMeIsLoading, error: updateMeError, reset: updateMeReset } ] = useUpdateMeMutation()
  const [ updateSpouse, { data: updatedSpouse, isLoading: updateSpouseIsLoading, error: updateSpouseError, reset: updateSpouseReset } ] = useUpdateSpouseMutation()

  //Setup form
  const formObj = useForm<{
    avatar: string | null
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      avatar: client?.avatar,
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj
  
  const onSubmit = attributes => {
    const payload = {
      avatar: attributes?.avatar ? attributes?.avatar : null
    }

    if (isSpouse) {
      Logger.info({ payload }, `Updating spouse avatar...`)
      updateSpouse(payload)
    } else {
      Logger.info({ payload }, `Updating client avatar...`)
      updateMe(payload)
    }
  }

  useEffect(() => {
    if (updatedMe || updatedSpouse) {
      close()
    }
  }, [updatedMe, updatedSpouse])
  

  const close = () => {
    onDismiss()
  }

  const handleImagePick = (base64: string) => {
    setValue('avatar', base64, { shouldDirty: true}) 
    trigger('avatar')
  }

  const handleClearImage = () => {
    setValue('avatar', null, { shouldDirty: true})
  }
  

  const isLoading = updateMeIsLoading || updateSpouseIsLoading
  const error: any = updateMeError || updateSpouseError
  
  const avatar = watch('avatar')

  return (
      <ModalEditScreen
        formTitle={'Change Profile Picture'}
        onDismiss={onDismiss}
        isDirty={isDirty}
        dismissDialogText={'Discard changes for your profile picture?'}
        error={error}
        errorTryAgain={handleSubmit(onSubmit)}
        errorCancel={updateSpouseError ? updateSpouseReset : updateMeError ? updateMeReset : undefined}
        isLoading={isLoading}
        loadingMessage={['Saving...']}
        buttonTitle={'Save'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <View style={{
            alignItems: 'center',
            paddingBottom: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x200,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: avatar || client?.picture }}
                style={{
                  borderRadius: Sizing.x200,
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            {
              avatar ?
                <Button mode={'text'} onPress={handleClearImage}>{'Reset to Default Image'}</Button>
                : <Paragraph>{'(Showing default profile picture)'}</Paragraph>
            }

          </View>
          <ImagePickerButton
              disabled={false}
              mode={'contained'}
              successHandler={handleImagePick}
              allowsEditing={true}
              quality={1}
            >{avatar ? `Change Image` : `Choose Image`}</ImagePickerButton>
      </ModalEditScreen>
  )
}
