import { Paragraph } from "components/Typography"
import { EmailLinkButton } from "components/Utility/EmailLinkButton"
import { ExternalLinkButton } from "components/Utility/ExternalLinkButton"
import { TelephoneLinkButton } from "components/Utility/TelephoneLinkButton"
import React from 'react'
import { PensionProviderContactDetail } from "store/dto/pension-provider.dto"
import { Sizing } from "styles"
import { StyleSheet } from 'react-native'
import { ContentDivider } from "components/Layout/ContentDivider"

type ProviderContactDetailProps = {
  contactDetail: PensionProviderContactDetail
}
export const ProviderContactDetail = (props: ProviderContactDetailProps) => {
  const { contactDetail } = props
  const { notes, telephone, email, contactUrl, portalUrl } = contactDetail

  return (
    <>
      { notes ?
        <Paragraph style={localStyles.text}>{notes}</Paragraph>
        : <></>
      }
      { telephone ?
        <TelephoneLinkButton
          telephone={telephone}
          style={{
            alignSelf: 'flex-start'
          }}
        >{telephone}</TelephoneLinkButton>
        : <></>
      }
      { telephone ?
        <EmailLinkButton
          email={email}
          style={{
            alignSelf: 'flex-start'
          }}
        >{telephone}</EmailLinkButton>
        : <></>
      }
      { portalUrl ?
        <ExternalLinkButton
          directToBrowser={true}
          url={portalUrl}
          style={{
            alignSelf: 'flex-start'
          }}
        >{'Online Portal'}</ExternalLinkButton>
        : <></>
      }
      { contactUrl ?
        <ExternalLinkButton
          url={contactUrl}
          style={{
            alignSelf: 'flex-start'
          }}
        >{'Contact Information'}</ExternalLinkButton>
        : <></>
      }
      <ContentDivider />
    </>
  )
}

const localStyles = StyleSheet.create({
  text: {
    textAlign: 'left',
  },
})