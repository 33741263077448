import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { MessagesScreen } from './Screens/MessagesScreen'

export const MessagesSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Documents'}
    >
      <MessagesScreen />
    </SectionSimpleContainer>
  )
}
