import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation, useVerifyMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}
  const dispatch = useAppDispatch()

  const [reportingFinished, setReportingFinished] = useState(false)

  const workingClientData: any = useAppSelector(workingClient)
  const [ updateClient, { data: client, error: clientUpdateError, isLoading: clientUpdateIsLoading } ] = useUpdateMeMutation()
  const [ verifyClient, { data: verifiedClient, error: verifyError, isLoading: verifyIsLoading } ] = useVerifyMeMutation()

  const isLoading = clientUpdateIsLoading || verifyIsLoading
  const error: any = clientUpdateError || verifyError

  //Save on enter
  useEffect(() => {
    saveClient()
  }, [])

  //Verify client when saved
  useEffect(() => {
    if (client) {
      verify()
    }
  }, [client])

  //Amplitude reporting
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }

    if (verifiedClient) {
      ampli.verificationSetup({
        amlStatus: verifiedClient?.amlStatus
      })
      setReportingFinished(true)
    }
  }, [verifiedClient, reportingFinished])

  //Navigate when verified reporting done
  useEffect(() => {
    if (verifiedClient && reportingFinished) {
      end()
    }
  }, [verifiedClient, reportingFinished])

  const saveClient = () => {
    Logger.info(`Updating client...`)
    updateClient(workingClientData)
  }

  const verify = () => {
    Logger.info(`Verifying client...`)
    verifyClient()
  }

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingClient(undefined))
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={client ? verify : saveClient}
      isLoading={isLoading}
      loadingMessage={[`Verifying your identity...`]}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
