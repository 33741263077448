import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import React from 'react'

export const SchemeEnrolmentPreviousPensionAdd_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}

  const next = () => {
     navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'record_an_existing_pension.png'}
      headline={`Add your previous workplace pension`}
      subHeading={`This helps Jarvis build an accurate forecast for your retirement`}
    >
    </ModalProcessIntroScreen>
  )
}
