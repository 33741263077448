import { Platform } from 'react-native'

export const platformIsWeb = () => {
  return Platform.OS === 'web'
}

export const platformIsIos = () => {
  return Platform.OS === 'ios'
}

export const platformIsAndroid = () => {
  return Platform.OS === 'android'
}
