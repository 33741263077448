import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'

export const RetirementIncomesAddIncome_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, client }  = route?.params || {}
  
  const next = () => {
    navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (      
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'capture_retirement_income.png'} 
      headline={`Add a retirement income ${client?.isSpouse ? `${client?.firstName} expects` : 'you expect'} to receive`}
      subHeading={`This could be a defined benefit pension, rental income, or any other regular payment`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_INCOMES} />}
    >
    </ModalProcessIntroScreen>
  )
}
