import { Logger } from 'lib/logger'

export const refreshUsingFunctions = async (
  refreshFunctions: Function[],
  refreshState: boolean,
  refreshStateFunction: Function) => {
  if (refreshState) {
    Logger.debug(`Already refreshing...`)
    return
  }
  // Logger.debug(`Refreshing...`)
  refreshStateFunction(true)
  await Promise.all(
    refreshFunctions.map(async func => {
      await func()
    })
  ).then(() => {
    // Logger.debug(`...done`)
    refreshStateFunction(false)
  }
  ).catch(e => {
    Logger.warn(
      `Error whilst attempting refresh: ${e}`,
    )
    refreshStateFunction(false)
  })
}