import { BaseDatedDto, Gender, Title } from './base.dto'
import { ClientClientSegmentDto } from './client.dto'
import { GroupPortfolioDto } from './group-portfolio.dto'
import { GroupSchemeEnrolmentPayrollFrequency, PublicGroupSchemeDto } from './group-scheme.dto'
import { InvestmentPlanDto } from './investment-plan.dto'
import { UserMetadata } from './user.dto'

export enum BeneficiaryRelationship {
  WIFE = 'WIFE',
  HUSBAND = 'HUSBAND',
  DAUGHTER = 'DAUGHTER',
  SON = 'SON',
  PARTNER = 'PARTNER',
  CHARITY = 'CHARITY',
  OTHER = 'OTHER'
}
export enum BeneficiaryRoute {
  ADD_ROUTE = 'ADD_ROUTE',
  EDIT_ROUTE = 'EDIT_ROUTE',
  ADJUST_ROUTE = 'ADJUST_ROUTE'
}

export interface Nomination {
  title: Title
  firstName: string
  surname: string
  beneficiaryRelationship: BeneficiaryRelationship
  beneficiaryProportion: number
}

export interface RegularContribution {
  amount: number
  dayOfMonth: number,
  paymentToExecute?: PaymentToExecute
}

export enum AccountStatus {
  ACTIVE = 'Active',
  CLOSED = 'Closed',
}

export enum AccountType {
  GROUP_PENSION = 'Group Pension',
  PERSONAL_PENSION = 'Personal Pension',
}

export interface PublicPendingSchemeEnrolmentDto extends BaseDatedDto {
  type: string
  name: string
  groupScheme: PublicGroupSchemeDto
  isVerified: boolean
  enrolmentStatus: GroupSchemeEnrolmentStatus
  employeeId?: string
  employeeEmail?: string
  employmentStartDate?: string
  enrolmentDate?: string
  enrolmentEndDate?: string
  contributionForecastTreatment?: GroupSchemeEnrolmentContributionForecastTreatment
  autoEnrolmentOptOutDate?: string
  autoEnrolmentWindowOptOutDate?: string
  metadata: UserMetadata
  clientOptOutDate?: string
}

export interface AccountDto extends BaseDatedDto {
  type: string
  clientId: string
  secclAccountId: string
  accountType: AccountType
  status: AccountStatus
  currentValue: number
  pendingContributionValue: number
  pendingTaxReclaimValue: number
  valuationDate: string
  investmentPlanId?: string
  investmentPlan?: InvestmentPlanDto
  regularContribution?: RegularContribution
  assetAllocation?: AssetAllocationDto[]
  valueReport: any
  groupPortfolioId?: string
  groupPortfolio?: GroupPortfolioDto
  usePersonalPensionInvestmentChoice?: boolean
  groupSchemeInitiated: boolean
  groupSchemeEnrolments: GroupSchemeEnrolmentDto[]
  segmentId: string
  segment: ClientClientSegmentDto

}

export interface AssetAllocationDto {
  assetId: string
  proportion: number
}


export interface CreateAccountDto {
  investmentPlanId?: string
  groupPortfolioId?: string
  assetAllocation?: AssetAllocationDto[]
  termsAgreed: boolean
  pensionOptOut: boolean
  usePersonalPensionInvestmentChoice?: boolean
}

export interface UpdateAccountDto {
  id: string
  investmentPlanId?: string
  groupPortfolioId?: string
  assetAllocation?: AssetAllocationDto[]
  regularContribution?: RegularContribution | null
  usePersonalPensionInvestmentChoice?: boolean
}

export interface OptOutSchemeEnrolmentDto {
  id: string
  nationalInsuranceNo: string
}

export interface VerifySchemeEnrolmentDto extends UpdateSchemeEnrolmentDto {
  nationalInsuranceNo: string
  previousPension?: VerifySchemeEnrolmentPreviousPensionDto
  changeRequests?: CreateSchemeEnrolmentChangeRequestDto[]
}

export interface VerifySchemeEnrolmentPreviousPensionDto {
  name: string
  externalProviderReference: string
  currentValue: number
  pensionProviderBrandKey?: string
  pensionProviderBrandName?: string
}
export interface BeneficiariesDto {
  nominations: Nomination[]
}

export interface UpdateBeneficiariesDto {
  nominations: Nomination[]
}

export interface ContributionsQueryDto {
  transactionDateFrom?: string
  transactionDateTo?: string
  status?: AccountTransactionStatus
  linkId?: string
  accountId?: string
  companyRegNo?: string
}

export enum AccountTransactionStatus {
  FUTURE = 'Future', //Fake status, used only for showing future regular contributions
  PENDING = 'Pending',
  PLACED = 'Placed',
  COMPLETED = 'Completed',
  DELETED = 'Deleted',
}

export interface PensionTransferQueryDto {
  transactionDateFrom?: Date
  transactionDateTo?: Date
  status?: AccountTransactionStatus
  trasferStatus?: PensionTransferStatus
}

export enum PensionTransferStatus {
  WITH_PREVIOUS_PROVIDER = 'With Previous Provider',
  ONBOARDING_ASSETS = 'Onboarding Assets',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected'
}

export enum PaymentMethod {
  DIRECT_DEBIT = 'Direct Debit',
  BANK_TRANSFER = 'Bank Transfer',
  STANDING_ORDER = `Standing Order`,
  TRANSFER = `Transfer`,
  BULK_BANK_TRANSFER = `Bulk Bank Transfer`,
}

export enum TransactionType {
  CASH = 'Cash',
  CASH_TRANSFER = 'Cash Transfer',
  NOTIONAL = 'Notional',
  ORDER = 'Order',
  PAYMENT = 'Payment',
  PRODUCT_TRANSFER = 'Product Transfer',
  STOCK_TRANSFER = 'Stock Transfer',
}

export enum TransactionSubType {
  //Payment
  DEPOSIT = 'Deposit',
  WITHDRAWAL = 'Withdrawal',
  EMPLOYER = 'Employer',
  EMPLOYEE = 'Employee',
  TAX_RECLAIM = 'Tax Reclaim',
  //Product Transfer
  CASH = 'Cash',
  TRANSFER = 'Transfer',
  // IN_SPECIE = 'In specie',
  //Order
  // LIMIT = 'Limit',
  // AT_BEST = 'At Best',
}
export interface PaymentToExecute {
  code: string
  state: string
  nonce: string
  id_token: string
}
export interface ContributionDto {
  id: string
  accountId: string
  accountName?: string //Used locally for display, not from API
  employerName?: string //Used locally for display, not from API
  accountType: AccountType
  transactionType: TransactionType
  transactionSubType: TransactionSubType
  movementType: TransactionMovementType
  currency: string
  amount: number
  displayDate: string
  transactionDate: string
  status: AccountTransactionStatus
  method: PaymentMethod
  isRecurring: boolean
  intendedSettlementDate?: string
  completedDate?: string
  linkId?: string
  providerRef?: string
  companyRegNo?: string
}

export interface CreateContributionAuthDto {
  amount: number
  state: string | any,
  nonce: string | any,
  firstPaymentDate?: string
}

export interface CreateContributionDto {
  amount: number
  paymentToExecute: PaymentToExecute
  accountId: string //Not required by backend, but used for frontend cache invalidation
}

export interface PensionTransferDto extends ContributionDto {
  transferStatus: PensionTransferStatus
  transferValue?: number
}
export interface TransfersListQueryDto {
  fromDate?: string
  toDate?: string
}

export enum TransactionMovementType {
  IN = 'In',
  OUT = 'Out',
}

export interface ContributionIllustrationRequestDto {
  investmentAmount: number
}

export interface ContributionsIllustrationDto {
  currency: string
  investmentAmount: number
  finalAmount: number
  totalInitialCharges: number
  totalOngoingCharges: number
  totalTransactionCharges: number
  totalCharges: number
  totalChargesPercent: number
  totalGrowth: number
  illustrationPeriodMonths: number
  illustrationGrowthRate: number
}

export interface PensionValueReportDto {
  openingValue: number
  contributionsTotal: number
  taxReclaimTotal: number
  paymentsOutTotal: number
  chargesTotal: number
  incomeTotal: number
  transfersInTotal: number
  transfersOutTotal: number
  realisedGrowthTotal: number
  retainedGrowthTotal: number
  closingValue: number
  lastContributionDate: string | null
  lastTransferInDate: string | null
}

export enum GroupSchemeEnrolmentStatus {
  ACTIVE = 'Active',
  OPTED_OUT = 'Opted Out',
  INACTIVE = 'Inactive',
}

export enum GroupSchemeEnrolmentSearchStatus {
  ANY = 'Any',
  ACTIVE = 'Active',
  OPTED_OUT = 'Opted Out',
  INACTIVE = 'Inactive',
}

export enum GroupSchemeEnrolmentContributionForecastTreatment {
  NONE = 'None',
  LAST_PERIOD = 'Last Period',
  CUSTOM = 'Custom',
}

export enum GroupSchemeEnrolmentEnrolmentEndReason {
  LEFT_EMPLOYEMENT = 'Left Employment',
  CEASED_MEMBERSHIP = 'Ceased Membership',
  DECEASED = 'Deceased',
}

export interface GroupSchemeEnrolmentDto {
  id: string
  name: string
  isVerified: boolean
  enrolmentStatus: GroupSchemeEnrolmentStatus
  accountId: string
  groupScheme: PublicGroupSchemeDto
  employeeId?: string
  employeeEmail?: string
  employmentStartDate?: string
  enrolmentDate?: string
  enrolmentEndDate?: string
  enrolmentEndReason?: GroupSchemeEnrolmentEnrolmentEndReason
  autoEnrolmentWindowOptOutDate?: string
  autoEnrolmentOptOutDate?: string
  contributionForecastTreatment: GroupSchemeEnrolmentContributionForecastTreatment
  customEmployerContributionAmount?: number
  customEmployeeContributionAmount?: number
  customPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  lastPensionableEarningsAmount?: number
  lastEmployerContributionAmount?: number
  lastEmployeeContributionAmount?: number
  lastEmployerContributionPercentage?: number
  lastEmployeeContributionPercentage?: number
  lastPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  clientOptOutDate?: string
}

export interface UpdateSchemeEnrolmentDto {
  id: string
  name?: string
  contributionForecastTreatment?: GroupSchemeEnrolmentContributionForecastTreatment
  customEmployerContributionAmount?: number
  customEmployeeContributionAmount?: number
  customPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
}

export interface PublicPendingSchemeEnrolmentEmployeeDto {
  id: string
  email: string
  employmentStartDate: string
  enrolmentDate: string
  title: Title
  firstName: string
  surname: string
  gender: Gender
  birthDate: string
}

export enum GroupSchemeRequestType {
  OPT_OUT = 'Opt Out',
  OPT_IN = 'Opt In',
  CEASE_MEMBERSHIP = 'Cease Membership',
  SET_GROSS_CONTRIBUTION = 'Set Contribution Amount',
  OTHER = 'Other',
}

export enum GroupSchemeEnrolmentChangeRequestSearchType {
  ANY = 'Any',
  OPT_OUT = 'Opt Out',
  OPT_IN = 'Opt In',
  CEASE_MEMBERSHIP = 'Cease Membership',
  SET_GROSS_CONTRIBUTION = 'Set Contribution Amount',
  OTHER = 'Other',
}


export enum GroupSchemeRequestStatus {
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export enum GroupSchemeEnrolmentChangeRequestSearchStatus {
  ANY = 'Any',
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export interface SchemeEnrolmentChangeRequestDto {
  type: string
  id: string
  enrolmentId: string
  requestType: GroupSchemeRequestType
  status: GroupSchemeRequestStatus
  data: any
  actionDate?: Date
  actionMessage?: string
  createdAt: string
  updatedAt: string
}

export interface CreateSchemeEnrolmentChangeRequestDto {
  id: string
  requestType: GroupSchemeRequestType
  data: any
}

export interface UpdateSchemeEnrolmentChangeRequestDto {
  enrolmentId: string
  requestId: string
  status: GroupSchemeRequestStatus
  actionMessage?: string
}
