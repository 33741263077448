import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { Divider } from 'react-native-paper'
import { Paper, Sizing } from 'styles'
import { applyOpacity } from 'styles/colors'

export type ContentDividerProps = {
  style?: StyleProp<ViewStyle>
}

export const ContentDivider = (props: ContentDividerProps) => {
  const { style } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const opacity = themeColors.themeType === 'light' ? 0.4 : 0.2
  const backgroundColor = applyOpacity(themeColors.divider, opacity)

  return (
    <Divider style={[
      {
        backgroundColor,
        marginVertical: Sizing.x5,
        marginHorizontal: Sizing.x10
      },
      style
    ]} />
  )
}
