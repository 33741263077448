import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGetSpouseQuery, useGetStateBenefitQuery, useUpdateSpouseMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse, workingSpouse } from 'store/tempDataSlice'
import { shareMessage } from 'lib/shareHelpers'
import { Paper } from 'styles'

export const RetirementIncomeSetup_03_SpouseStatePensionChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()
  const workingSpouseData = useAppSelector(workingSpouse)

  const { data: spouse, error: spouseError, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: stateBenefit, error: stateBenefitError, isLoading: stateBenefitIsLoading, refetch: refetchBenefits } = useGetStateBenefitQuery({
    birthDate: spouse?.birthDate,
    gender: spouse?.gender,
  }, {
    skip: !spouse
  })

  const refetchAll = () => {
    refetchSpouse()
    refetchBenefits()
  }

  const isLoading = spouseIsLoading || stateBenefitIsLoading
  const error: any = spouseError || stateBenefitError

  const getBestChoice = () => {
    return spouse?.statePensionAmount === stateBenefit?.maxAmount ? 'full' : spouse?.statePensionAmount ? 'partial' : undefined
  }

  //Setup form
  const formObj = useForm<{ statePensionChoice: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionChoice: workingSpouseData?.statePensionChoice || getBestChoice()
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const statePensionChoice = attributes.statePensionChoice
    const statePensionAmount =
      statePensionChoice === 'full'
        ? stateBenefit?.maxAmount
        : statePensionChoice === 'partial'
          ? undefined
          : 0
    dispatch(updateWorkingSpouse({
      statePensionChoice,
      statePensionAmount,
    }))
    //Navigate based on choice
    if (statePensionChoice === 'partial') {
      navigation.navigate(nextScreen) //Go to next screen to customise
    } else {
      navigation.navigate('StatePensionSave') //Skip to save
    } 
  }

  const statePensionOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 'full',
      label: `Full amount (${formatCurrencyAmount(stateBenefit?.maxAmount)} p.a.)`
    },
    {
      value: 'partial',
      label: `Yes, but only some`
    },
    {
      value: 'none',
      label: `No`,
    },
  ]

  const forecastUrl = 'https://www.gov.uk/check-state-pension'
  const shareLink = async () => {
    await shareMessage({
      message: `Please check your State Pension Forecast at: ${forecastUrl}`,
      title: 'State Pension Forecast'
    })
  }

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={refetchAll}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Does ${spouse?.firstName} expect to get a UK state pension?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.STATE_PENSION} buttonTitle={'Learn More'}/>}
    >
      <ManagedSimpleChoiceInput
        name={'statePensionChoice'}
        formObj={formObj}
        options={statePensionOptions}
        required={true}
        submitHandler={handleSubmit(onSubmit)}
      />
      <Paragraph>{`The annual amount they could receive depends on their National Insurance contribution history. The amount and conditions are set by the UK Government and policy may change before or after retirement.`}</Paragraph>
      <Paragraph>{`A free online service exists to check your personal forecast. Note that this requires the individual to sign in using a Government Gateway account.`}</Paragraph>
      <Button mode='text' color={themeColors.accent} onPress={shareLink}>{'Send link to your partner'}</Button>
    </ProcessScreen>
  )
}
