import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { ClientBaseDto } from "store/dto/client.dto"
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome } from "store/tempDataSlice"
import { addRetirementIncomeClient, setAddRetirementIncomeClient } from 'store/uxSlice'
import { RetirementIncomesAddIncome_00_Intro, RetirementIncomesAddIncome_01_Type, RetirementIncomesAddIncome_02_Income, RetirementIncomesAddIncome_03_DateChoice, RetirementIncomesAddIncome_04_Date, RetirementIncomesAddIncome_05_Name, RetirementIncomesAddIncome_99_Result } from "./Screens"

export type RetirementIncomesAddIncomeProcessStackProps = {
  client: ClientBaseDto
  onDismiss?: any
}

export const RetirementIncomesAddIncomeProcessStack = (props: RetirementIncomesAddIncomeProcessStackProps) => {
  const { client } = props

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setAddRetirementIncomeClient(undefined))

  const visible = !!client
  const onDismiss = props.onDismiss || globalDismiss

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingIncome(undefined))
  }, []
  )
  
  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding this retirement income?`}
      processTitle='Add Retirement Income'
      initialRouteName='Intro'
      initialParams={{
        client,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Income',
          component: RetirementIncomesAddIncome_00_Intro,
        },
        {
          navigationId: 'Type',
          title: 'Type',
          component: RetirementIncomesAddIncome_01_Type,
        },
        {
          navigationId: 'Income',
          title: 'Income',
          component: RetirementIncomesAddIncome_02_Income,
        },
        {
          navigationId: 'DateChoice',
          title: 'Date Choice',
          component: RetirementIncomesAddIncome_03_DateChoice,
        },
        {
          navigationId: 'Date',
          title: 'Date',
          component: RetirementIncomesAddIncome_04_Date,
        },
        {
          navigationId: 'Name',
          title: 'Name',
          component: RetirementIncomesAddIncome_05_Name,
        },
        {
          navigationId: 'Results',
          title: '',
          component: RetirementIncomesAddIncome_99_Result,
        },
      ]}
    />
  )
}

