import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatISO } from 'date-fns'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAccount, workingAccount } from 'store/tempDataSlice'

const DRAWDOWN_MESSAGE_MIN_AGE = 50

export const InvestmentChoice_20_OptOut = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const { data: client, isLoading, error, refetch } = useGetMeQuery()

  //Temp state
  const dispatch = useAppDispatch()
  const workingAccountData = useAppSelector(workingAccount)

  //Setup form
  const formObj = useForm<{ pensionOptOut: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionOptOut: workingAccountData?.pensionOptOut === true ? 'yes' : workingAccountData?.pensionOptOut === false ? 'no' : undefined
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const pensionOptOut = attributes.pensionOptOut === 'yes'
    dispatch(updateWorkingAccount({
      pensionOptOut,
    }))
    const clientCurrentAge = calculateAgeAtDate(formatISO(new Date()), client?.birthDate)
    if (clientCurrentAge >= DRAWDOWN_MESSAGE_MIN_AGE) {
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate('Terms')
    }
  }

  const optOutOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 'yes',
      label: `Yes`
    },
    {
      value: 'no',
      label: 'No',
    }
  ]

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Just to check...`}
      subHeading={`Are you opening this pension due to opting out of your current workplace pension?`}
      subHeadingNumberOfLines={3}
      subHeadingInfo={<NamedInformationButton buttonTitle={'Why do you need to know?'} name={NamedInformation.PENSION_OPT_OUT} />}
    >
      <ManagedSimpleChoiceInput
        name={'pensionOptOut'}
        formObj={formObj}
        options={optOutOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
