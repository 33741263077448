import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { buildAddressDescription } from 'lib/addressHelpers'
import { isEqual } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_21_PersonalDetailsAddressChoice = ({ route, navigation }) => {
  const { nextScreen, client, asset }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  
  const addressMatches = () => {
     return isEqual(asset?.transferAddress, client?.addressDetail)
  }

  //Setup form
  const formObj = useForm<{ addressChoice: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      addressChoice: workingRetirementAssetData?.addressChoice
        ? workingRetirementAssetData?.addressChoice
        : asset?.transferAddress
          ? addressMatches()
          : undefined
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const update = attributes
    //Use client address
    if (attributes.addressChoice) {
      update.transferAddress = client?.addressDetail
    }
    dispatch(updateWorkingRetirementAsset({
      ...update,
    }))
    
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_address_choice',
      isRetransfer: false,
      outcome: attributes.addressChoice ? 'current' : 'different',
      retirementAssetId: asset?.id
    })
    //Skip address screens if using current client address
    navigation.navigate(attributes.addressChoice
      ? 'PersonalDetailsPreviousNameChoice'
      : nextScreen
    )
  }

  const addressOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No, a different one'
    }
  ]

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Were you at the same address?`}
      subHeading={`Would the pension provider have your current address on record?`}
    >
      <ManagedSimpleChoiceInput
        name={'addressChoice'}
        formObj={formObj}
        options={addressOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      <Subheading>{'Your Current Address'}</Subheading>
      <Paragraph>{buildAddressDescription(client?.addressDetail, false)}</Paragraph>
    </ModalProcessScreen>
  )
}
