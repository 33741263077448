import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { envVariables } from 'environment'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Sizing, Typography } from 'styles'
import { ExternalLinkButton } from "./ExternalLinkButton"
import { ExternalLinkText } from './ExternalLinkText'

export const MoneyHubInfo = () => {

  const { moneyhubTermsUrl, moneyhubPrivacyUrl } = envVariables

  return (
    <>
      <Text style={localStyles.text}>{`Payments processed via Moneyhub`}</Text>
      <Text style={localStyles.smallText}>
        {`Moneyhub Financial Technology Limited (Company No. 06909772) is authorised and regulated by the Financial Conduct Authority (FCA) as a Payments Initiation Service Provider, reference no. 809360. `}
        <ExternalLinkText style={localStyles.smallText} url={moneyhubTermsUrl}>Terms & Conditions</ExternalLinkText> 
        {' '}
        <ExternalLinkText style={localStyles.smallText} url={moneyhubPrivacyUrl}>Privacy Policy</ExternalLinkText> 

      </Text>
    </>
  )
}

const localStyles = StyleSheet.create({
  text: {
    textAlign: 'left',
    paddingBottom: Sizing.x5,
  },
  smallText: {
    textAlign: 'left',
    paddingBottom: Sizing.x10,
    ...Typography.fontSize.x10
  },
})
