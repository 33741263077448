import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { StatementsScreen } from './Screens/StatementsScreen'

export const StatementsSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Statements'}
    >
      <StatementsScreen />
    </SectionSimpleContainer>
  )
}
