import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { UserDto } from 'store/dto/user.dto'
import { Sizing, Paper } from 'styles'

type AdminUserListItemProp = {
  user: UserDto
  onPressFunction?: () => void
}
export const AdminUserListItem = (props: AdminUserListItemProp) => {
  const { user, onPressFunction } = props
  const { email, metadata, isBlocked } = user

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 'account-lock'
  const title = metadata?.firstName && metadata?.surname
    ? `${metadata?.firstName} ${metadata?.surname}`
    : metadata?.firstName || metadata?.surname || 'Unknown Name'
  const titleRight = isBlocked === false ? 'Unblocked' : isBlocked === true ? 'Blocked' : 'Held'
  const titleRightStyle = {
    color: isBlocked === false ? themeColors.primary : isBlocked === true ? themeColors.error : themeColors.accent
  }

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={titleRight}
      titleRightStyle={titleRightStyle}
      subTitle={email}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
    />
  )
}
