import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { View } from 'react-native'
import { Text } from "react-native-paper"
import { Sizing, Paper, Typography } from "styles"
import { TypographyProps } from './typography.props'

export const BulletItem = (props: TypographyProps) => {

  const styles = [
    Typography.defined.paragraph,
    props.style,
  ]

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingRight: Sizing.x30,
    }}>
      <MaterialCommunityIcons name="menu-right" size={Sizing.x20} color={themeColors.accent} />
      <Text {...props} allowFontScaling={false} style={styles}>
        {props.children}
      </Text>
    </View>
  )
}