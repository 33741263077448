import ModalProcessStackBuilder, { ModalProcessStackBuilderScreen } from 'navigation/stacks/ModalProcessStackBuilder'
import React, { useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingContributionSetup } from 'store/tempDataSlice'
import { MakeContributionsData, MakeContributionsStepIdentifier, setMakeContributionsData } from 'store/uxSlice'
import { MakeContributions_00_ContributionSetupIntro, MakeContributions_01_ContributionSource, MakeContributions_02_CompanyNumber, MakeContributions_03_Bank, MakeContributions_04_SortCode, MakeContributions_05_AccountNumber, MakeContributions_09_SetupSave, MakeContributions_10_LumpSumIntro, MakeContributions_11_LumpSumSuggestAmount, MakeContributions_12_LumpSumCustomAmount, MakeContributions_13_LumpSumCostDisclosure, MakeContributions_14_LumpSumConfirm, MakeContributions_15_LumpSumManualSetup, MakeContributions_19_LumpSumSave, MakeContributions_20_RegularIntro, MakeContributions_21_RegularSuggestAmount, MakeContributions_22_RegularCustomAmount, MakeContributions_23_RegularDayOfMonth, MakeContributions_24_RegularCostDisclosure, MakeContributions_25_RegularConfirm, MakeContributions_26_RegularManualSetup, MakeContributions_29_RegularSave, MakeContributions_30_TargetRetirementAgeIntro, MakeContributions_39_TargetRetirementAgeSave, MakeContributions_40_CancelRegularContributionIntro, MakeContributions_49_CancelRegularContributionSave, MakeContributions_99_Result } from './Screens'

export type MakeContributionsProcessStackProps = {
  contributionsData: MakeContributionsData
  onDismiss?: any
  onCompletion: any
}

export const MakeContributionsProcessStack = (props: MakeContributionsProcessStackProps) => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setMakeContributionsData(undefined))

  const { contributionsData, onCompletion } = props || {}

  const { makeContributionSteps, existingRegularContribution } = contributionsData || {}

  const onDismiss = props.onDismiss || globalDismiss

  const screens: ModalProcessStackBuilderScreen[] = []

  //Add contribution setup if not already done or requested
  if (makeContributionSteps.includes(MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE)) {
    screens.push({
      navigationId: 'ContributionSetupIntro',
      title: 'Contribution Setup Intro',
      component: MakeContributions_00_ContributionSetupIntro,
    })
    screens.push({
      navigationId: 'ContributionSource',
      title: 'Contribution Source',
      component: MakeContributions_01_ContributionSource,
    })
    screens.push({
      navigationId: 'CompanyNumber',
      title: 'Company Number',
      component: MakeContributions_02_CompanyNumber,
    })
    screens.push({
      navigationId: 'Bank',
      title: 'Bank',
      component: MakeContributions_03_Bank,
    })
    screens.push({
      navigationId: 'SortCode',
      title: 'Sort Code',
      component: MakeContributions_04_SortCode,
    })
    screens.push({
      navigationId: 'AccountNumber',
      title: 'Account Number',
      component: MakeContributions_05_AccountNumber,
    })
    screens.push({
      navigationId: 'SetupSave',
      title: 'Save Setup',
      component: MakeContributions_09_SetupSave,
    })
  }

  //Add Cancel Monthly Contributions screens
  if (makeContributionSteps.includes(MakeContributionsStepIdentifier.CANCEL_CONTRIBUTIONS)) {
    screens.push({
      navigationId: 'CancelRegularContributionIntro',
      title: 'Cancel Monthly Contribution Intro',
      component: MakeContributions_40_CancelRegularContributionIntro,
    })
    screens.push({
      navigationId: 'CancelRegularContributionSave',
      title: 'Cancel Monthly Contributions',
      component: MakeContributions_49_CancelRegularContributionSave,
    })
  }

  //Add Lump sum screens
  if (makeContributionSteps.includes(MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION)) {
    screens.push({
      navigationId: 'LumpSumIntro',
      title: 'Lump Sum Intro',
      component: MakeContributions_10_LumpSumIntro,
    })

    //Suggest amount if not a specific amount
    if (!contributionsData?.newOneOffContribution) {
      screens.push({
        navigationId: 'LumpSumSuggestAmount',
        title: 'Lump Sum Suggest Amount',
        component: MakeContributions_11_LumpSumSuggestAmount,
      })
    }
    screens.push({
      navigationId: 'LumpSumCustomAmount',
      title: 'Lump Sum Custom Amount',
      component: MakeContributions_12_LumpSumCustomAmount,
    })
    screens.push({
      navigationId: 'LumpSumCostDisclosure',
      title: 'Lump Sum Cost Disclosure',
      component: MakeContributions_13_LumpSumCostDisclosure,
    })
    screens.push({
      navigationId: 'LumpSumConfirm',
      title: 'Lump Sum Confirm',
      component: MakeContributions_14_LumpSumConfirm,
    })
    screens.push({
      navigationId: 'LumpSumManual',
      title: 'Lump Sum Manual',
      component: MakeContributions_15_LumpSumManualSetup,
    })
    screens.push({
      navigationId: 'LumpSumSave',
      title: 'Lump Sum Save',
      component: MakeContributions_19_LumpSumSave,
    })
  }

  //Add Regular contribution screens
  if (makeContributionSteps.includes(MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION)) {
    //Suggest amount if not a specific amount
    screens.push({
      navigationId: 'RegularIntro',
      title: 'Regular Intro',
      component: MakeContributions_20_RegularIntro,
    })

    //Suggest amount if not a specific amount
    if (!contributionsData?.newMonthlyContributions) {
      screens.push({
        navigationId: 'RegularSuggestAmount',
        title: 'Regular Suggest Amount',
        component: MakeContributions_21_RegularSuggestAmount,
      })
    }
    screens.push({
      navigationId: 'RegularCustomAmount',
      title: 'Regular Custom Amount',
      component: MakeContributions_22_RegularCustomAmount,
    })
    screens.push({
      navigationId: 'RegularDayOfMonth',
      title: 'Regular Day Of Month',
      component: MakeContributions_23_RegularDayOfMonth,
    })
    screens.push({
      navigationId: 'RegularCostDisclosure',
      title: 'Regular Cost Disclosure',
      component: MakeContributions_24_RegularCostDisclosure,
    })
    screens.push({
      navigationId: 'RegularConfirm',
      title: 'Regular Confirm',
      component: MakeContributions_25_RegularConfirm,
    })
    screens.push({
      navigationId: 'RegularManual',
      title: 'Regular Manual',
      component: MakeContributions_26_RegularManualSetup,
    })
    screens.push({
      navigationId: 'RegularSave',
      title: 'Regular Save',
      component: MakeContributions_29_RegularSave,
    })
  }

  //Add Change Target Retirement Age screnes
  if (makeContributionSteps.includes(MakeContributionsStepIdentifier.TARGET_AGE) && contributionsData?.newTargetRetirementAge) {
    screens.push({
      navigationId: 'TargetRetirementAgeIntro',
      title: 'Target Retirement Age Intro',
      component: MakeContributions_30_TargetRetirementAgeIntro,
    })
    screens.push({
      navigationId: 'TargetRetirementAgeSave',
      title: 'Update Retirement Target',
      component: MakeContributions_39_TargetRetirementAgeSave,
    })
  }

  //Always have results
  screens.push({
    navigationId: 'Results',
    title: 'Results',
    component: MakeContributions_99_Result,
  })

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingContributionSetup(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={!!contributionsData}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel making contributions?`}
      processTitle='Contributions'
      initialRouteName='Intro'
      initialParams={{
        contributionsData,
        onCompletion,        
      }}
      screens={screens}
    />
  )
}
