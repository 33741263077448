import React from 'react'
import { AdminGroupPortfoliosListScreen } from './Screens/AdminGroupPortfolioListScreen'
import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'

export const AdminGroupPortfolioSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Group Portfolios'}
    >
      <AdminGroupPortfoliosListScreen />
    </SectionSimpleContainer>
  )
}

