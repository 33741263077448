import React from 'react'
import { View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { Colors, Flex, Paper } from 'styles'
import { DashboardSection, DashboardSectionProps } from './DashboardSection'

type DashboardSectionSetProps = {
  sections: DashboardSectionProps[]
}

const SECTION_THEMES: ReactNativePaper.Theme[] = [
  Paper.darkThemeOnLightGrey,
  Paper.darkTheme,
  Paper.darkThemeOnLightPurple,
]

export const DashboardSectionSet = (props: DashboardSectionSetProps) => {

  const { sections } = props
  const sectionCount = SECTION_THEMES.length
  let lastThemeIndex = undefined

  const visibleSections = sections.filter(section => {
    return section.visible
  })

  return (
    <View style={{
      ...Flex.column.start, 
      ...Flex.override.fill,
      }}>
      {
        visibleSections.map((sectionProps, idx) => {
          const themeIndex = idx % sectionCount
          const previousThemeIndex = lastThemeIndex
          lastThemeIndex = themeIndex
          const previousColor = previousThemeIndex === undefined ? Colors.brand.purplex0 : SECTION_THEMES[previousThemeIndex].colors.pageBackground
          return (
            <ThemeProvider theme={SECTION_THEMES[themeIndex]} key={idx}>
              <DashboardSection
                key={idx}
                {...sectionProps}
                previousColor={previousColor}
              />
            </ThemeProvider>
          )
        })
      }
    </View>
  )
}
