import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { MAX_ASSET_CURRENT_VALUE } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest, workingSchemeEnrolmentRequest } from 'store/tempDataSlice'
import { lowerCase } from 'lodash'

export const SchemeEnrolmentSetContributionRequestAdd_01_Amount = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}
  const { groupScheme } = enrolment || {}
  const { defaultContributionFrequency } = groupScheme || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingSchemeEnrolmentRequestData = useAppSelector(workingSchemeEnrolmentRequest)
  const { data } = workingSchemeEnrolmentRequestData || {}

  //Setup form
  const formObj = useForm<{ employerContributionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerContributionAmount: data?.employerContributionAmount,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const { employerContributionAmount } = attributes
    dispatch(updateWorkingSchemeEnrolmentRequest({
      requestType: GroupSchemeRequestType.SET_GROSS_CONTRIBUTION,
      data: {
        employerContributionAmount,
        contributionFrequency: defaultContributionFrequency,
      }
    }))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How much do you want to be contributed`}
      subHeading={`The gross amount for each ${lowerCase(defaultContributionFrequency)} payroll run`}
    >
      <ManagedCurrencyInput
        name={'employerContributionAmount'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        // label={'Current Value'}
        placeholder={'Enter contribution amount'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={0}
        maxValue={100000}
        blurOnSubmit={false}
      />
    </ModalProcessScreen>
  )
}
