import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { JarvisLogoType } from 'components/Brand/JarvisLogoType'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export type BiometricLockScreenProps = {
  biometricsCancelled: boolean
  retryBiometrics: any
  doLogout: any
}

export const BiometricLockScreen = (props: BiometricLockScreenProps) => {
  const { biometricsCancelled, doLogout, retryBiometrics } = props || {}

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={layoutStyles.mainContentContainer} >
        <View style={{
          ...Flex.override.fill,
          ...Flex.column.center,
        }}>
          {
            biometricsCancelled ? <JarvisLogoType size={Sizing.x70} color={'white'} />
            : <Loading message={['Waiting for biometrics...']} useHolidayGraphics={true}/>
          }
        </View>
        <View style={{ ...Flex.override.bottom }}>
          {
            biometricsCancelled ? 
              <>
                <FooterButton
                  mode='text'
                  color={Colors.brand.red3}
                  onPress={doLogout}>
                  {'Logout'}
                </FooterButton>
                <FooterButton
                  onPress={retryBiometrics}>
                  {'Retry Biometrics'}
                </FooterButton>
              </>
            : <></>
          }
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}
