import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { Image, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetAffiliateByCodeQuery, useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext, clientHash } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EntryPointAffiliateScreen = ({ route, navigation }) => {
  const { affiliateCode } = route.params || {}

  const useCalculator = !!useAppSelector(clientHash)

  const [showDownloadModal, setShowDownloadModal] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const dispatch = useAppDispatch()

  const { data: affiliate, isLoading: affiliateIsLoading, isFetching: affiliateIsFetching, error: affiliateError } = useGetAffiliateByCodeQuery(affiliateCode, { skip: !affiliateCode })
  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })
  
  const { logo, message, name, isInternal } = affiliate || {}


  const isLoading = affiliateIsLoading || affiliateIsFetching || userIsLoading || userIsFetching
  
  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      buttonTitle={
        user ? `Back to Jarvis`
        : `Get Started`
      }
      buttonAction={
        user ? goToMainAppStack
        : () => rootNavigate('NewUserCarouselScreen')
      }
      showButton={true}
      cancelButtonText={
        isWeb ? `Download Mobile App`
        : undefined
      }
      cancelButtonAction={
        isWeb ? () => setShowDownloadModal(true)
        : undefined
      }
      refuseButtonAction={
        !user && useCalculator ? () => rootNavigate('CalculatorIntroScreen', { allowCancel: true })
        : undefined
      }
      refuseButtonText={
        !user && useCalculator ? 'Try the quick calculator first'
        : undefined
      }
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      imageAction={() => setShowInfoModal(true)}
      headline={
        affiliate
          ? isInternal ? 'Pensions without the guesswork'
          : `Partner of ${name}`
        : `Sorry, your affiliate link is no longer valid`
      }
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        {
          logo
            ? <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }}
              />
              : <AppIllustration filename={'store_retirement_meter.png'} style={{
                width: Sizing.x200,
                height: Sizing.x200,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
        }
      </View>
      <Paragraph>{
        affiliate
          ? isInternal
            ? `Design your pension plan, know how much to save each month, and retire with confidence.`
            : message
              ? message
              : `${name} has partnered with Jarvis!`
          : isWeb
            ? `You can still create a Jarvis account here and we'll give you the links to download our app for Android or iOS.`
            : `You can still create a Jarvis account and enjoy all the benefits of knowing exactly when you can retire.`
      }</Paragraph>
      <Portal>
        <AppInfoModal
          user={user}
          client={undefined}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
          isUnauthenticated={!user}
        />
      </Portal>
      {
        isWeb
          ? <Portal>
              <WebAppDownloadModal
                visible={showDownloadModal}
                onDismiss={() => setShowDownloadModal(false)}
              />
            </Portal>
          : <></>
      }
    </ProcessIntroScreen>
  )
}
