import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { useGetMeQuery } from 'store/apiSlice'

export const ClientVerificationIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}
  
  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()

  const next = () => {
    rootNavigate('ClientVerificationProcessStack', { ...route?.params, client })
  }
  
  return (
    <ProcessIntroScreen
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      cancelButtonText={fromOnboarding ? 'Back to Tasks' : 'Back To Dashboard'}
      illustrationFilename={'verify_your_identity.png'}
      headline={`Verify Your Identity`}
      subHeading={`By entering a few details we can usually verify you instantly - you will need your UK National Insurance Number to hand`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.AML} buttonTitle={'Why do I need to do this?'} />}
    >      
    </ProcessIntroScreen>
  )
}
