import { JarvisIcon } from 'components/Brand/JarvisIcon'
import { SegmentInfoModal } from 'components/Utility/SegmentInfoModal'
import React, { ComponentProps, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { Portal, Text } from 'react-native-paper'
import { ClientClientSegmentDto } from 'store/dto/client.dto'
import { Colors, Flex, Sizing } from "styles"
import { Button } from '../Utility/Button'
import { TypographyProps } from './typography.props'

const DEFAULT_SIZE = Sizing.x15

export type SegmentBadgeProps = TypographyProps & {
  segment: ClientClientSegmentDto
  withoutInfoModal?: boolean
  containerStyle?: StyleProp<ViewStyle>
}

export const SegmentBadge = (props: SegmentBadgeProps) => {
  const { segment, withoutInfoModal, style, containerStyle } = props
  const size = style?.fontSize || DEFAULT_SIZE

  const [showInfoModal, setShowInfoModal] = useState(null)

  
  return (
    <Pressable
      style={containerStyle}
      onPress={withoutInfoModal ? undefined : () => setShowInfoModal(true)}
    >
      <View style={[localStyles.badge, {
        paddingVertical: size / 2,
        paddingHorizontal: size,
      }]}>
        <View style={localStyles.iconContainer}>
        <JarvisIcon
          size={size}
        ></JarvisIcon>
        </View>
        <Text
          {...props}
          allowFontScaling={true}
          style={[style,
          {
            fontSize: size
          },
          {
            color: Colors.neutral.white,          
          }]}
        >
          {segment?.badgeName}
        </Text>
      </View>
      <Portal>
        <SegmentInfoModal
          segment={segment}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
        />
      </Portal>
    </Pressable>
  )
}

const localStyles = StyleSheet.create({
  badge: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.brand.red3,
    paddingVertical: Sizing.x10,
    paddingHorizontal: Sizing.x20,
    borderRadius: Sizing.x100,
    alignSelf: 'center',
  },
  iconContainer: {
    paddingRight: Sizing.x10,
  }
})