import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminBankConnectionsListScreen } from './Screens/AdminBankConnectionListScreen'

export const AdminBankConnectionSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Bank Connections'}
    >
      <AdminBankConnectionsListScreen />
    </SectionSimpleContainer>
  )
}

