import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { MakeContributionsProcessStack } from 'features/ModalProcesses/MakeContributions/MakeContributionsProcessStack'
import { goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { getPersonalPension } from 'lib/accountHelpers'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'
import { MakeContributionsData, MakeContributionsStepIdentifier } from 'store/uxSlice'

export const Contributions_99_Execute = ({ route, navigation }) => {
  const { existingContributionConfiguration, existingRegularContribution, nextRoute } = route.params || {}
    
  const dispatch = useAppDispatch()

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)

  const [makeContributions, setMakeContributions] = useState<MakeContributionsData>(undefined)
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
    
  const workingSuggestionData = useAppSelector(workingSuggestion)

  //Launch modal process on entry
  useEffect(() => {
    setMakeContributions(undefined)
    launchMakeContributions()
  }, [])

  const onCompletion = (actualMonthlyContributionAmount: number, actualOneOffContributionAmount: number) => {
    setMakeContributions(undefined)
    dispatch(updateWorkingSuggestion(undefined))
    ampli.contributionsSetup({
      skipped: false,
      actualMonthlyContributionAmount,
      actualOneOffContributionAmount,
      manualSetup: workingSuggestionData?.manualSetupSelections ? workingSuggestionData.manualSetupSelections.length > 0 : false,
      ...pick(workingSuggestionData, [
        'goal',
        'contributionSource',
        'proposedOneOffContribution',
        'proposedMonthlyContributions',
        'proposedRetirementAge',
        'proposedTargetRetirementAge',
        'surplusStrategy',
      ])
    })
    rootNavigate(nextRoute, {
      ...route?.params || {},
      ...pick(workingSuggestionData, [
        'contributionSource',
        'cancelRegularContributions',
        'proposedOneOffContribution',
        'proposedMonthlyContributions',
        'proposedTargetRetirementAge',
      ])
    })
  }

  const handleDismiss = () => {
    setMakeContributions(undefined)
    dispatch(updateWorkingSuggestion(undefined))
    setIsCancelled(true)
  }

  const close = () => {
    setMakeContributions(undefined)
    setIsCancelled(true)
    dispatch(updateWorkingSuggestion(undefined))
    goToMainAppStack()
  }

  const launchMakeContributions = () => {
    if (makeContributions === undefined) {
      let newMonthlyContributions, newOneOffContribution, newTargetRetirementAge
      let makeContributionSteps: MakeContributionsStepIdentifier[] = []

      //Include contribution source setup if:
      //1. Explicitly requested (changeRegularContributions)
      //2. No existing config and either one or both of proposedOneOffContribution and proposedMonthlyContributions
      if (workingSuggestionData?.changeRegularContributions
            || (!existingContributionConfiguration && (
              workingSuggestionData?.proposedOneOffContribution !== undefined
              || workingSuggestionData?.proposedMonthlyContributions !== undefined
            ))) {
        makeContributionSteps.push(MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE)
      }

      if (workingSuggestionData?.cancelRegularContributions) {
        makeContributionSteps.push(MakeContributionsStepIdentifier.CANCEL_CONTRIBUTIONS)
      } else {
        if (workingSuggestionData?.proposedOneOffContribution !== undefined) {
          makeContributionSteps.push(MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION)
          if (workingSuggestionData?.proposedOneOffContribution > 0) {
            newOneOffContribution = workingSuggestionData?.proposedOneOffContribution
          }
        }
        if (workingSuggestionData?.proposedMonthlyContributions !== undefined) {
          makeContributionSteps.push(MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION)
          if (workingSuggestionData?.proposedMonthlyContributions > 0) {
            newMonthlyContributions = workingSuggestionData?.proposedMonthlyContributions
          }
        }
        if (workingSuggestionData?.proposedTargetRetirementAge) {
          makeContributionSteps.push(MakeContributionsStepIdentifier.TARGET_AGE)
          newTargetRetirementAge = workingSuggestionData?.proposedTargetRetirementAge
        }
      }
      const data: MakeContributionsData = {
        account: personalPension,
        existingContributionConfiguration,
        existingRegularContribution,
        makeContributionSteps,
        newContributionSource: workingSuggestionData?.contributionSource,
        newOneOffContribution,
        newMonthlyContributions,
        newTargetRetirementAge,
      }
      setMakeContributions(data)
    } else {
      Logger.debug(`Already launched...`)
    }
  }

  return (
    <>
      {
        makeContributions ?
        <MakeContributionsProcessStack
          contributionsData={makeContributions}
          onDismiss={handleDismiss}
          onCompletion={onCompletion}
        />
        :
        <ProcessScreen
          buttonTitle={'Finish'}
          buttonAction={close}
          showButton={true}
          headline={isCancelled ? `Ok, maybe another time` : ''}
          subHeading={isCancelled ? `You can return at any time to get suggestions on how to achieve your financial freedom` : ''}
    
        >
        </ProcessScreen>      
      }
    </>
  )
}