import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout'
import { formatPercentageAmount } from 'lib/generalHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary } from 'store/tempDataSlice'
import { addBeneficiaryData } from 'store/uxSlice'

export const BeneficiariesAddBeneficiary_06_Split = ({ route, navigation }) => {
  const addBeneficiaryDataData = useAppSelector(addBeneficiaryData)
  const { allNominations } = addBeneficiaryDataData || {}
  const dispatch = useAppDispatch()
  const { nextScreen } = route?.params || {}

  const ratio = allNominations ? 1 / (allNominations?.length + 1) : 1

  const formObj = useForm<{ split: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      split: undefined
    }
  })
  const { handleSubmit, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingBeneficiary({
      ...attributes,
      evenSplit: attributes.split,
    }))
    //Navigate to results for even split or next screen (adjust)
    navigation.navigate(attributes.split ? 'Results' : nextScreen)
  }

  const softOnSubmit = attributes => {
    dispatch(updateWorkingBeneficiary({
      ...attributes,
      evenSplit: attributes.split,
    }))
    //Do nothing for even split or next screen (adjust)
    if (attributes.split) {
      //Do nothing, wait for user to click button
    } else {
      navigation.navigate(nextScreen)
    }
  }

  const planOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes, ${formatPercentageAmount(ratio * 100, 2)} each`
    },
    {
      value: false,
      label: 'No, I want to adjust it'
    },
  ]

  const evenSplit = watch('split')

  return (
    <ModalProcessScreen
      buttonTitle={isValid ? evenSplit ? 'Save Beneficiary' : 'Next' : 'Make a Selection'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`Split equally between everyone?`}
      subHeading={`Do you want to split residual savings equally between your beneficiaries?`}
    >
      <ManagedSimpleChoiceInput
        name={'split'}
        formObj={formObj}
        options={planOptions}
        required={true}
        submitHandler={handleSubmit(softOnSubmit)}
      />
    </ModalProcessScreen>
  )
}
