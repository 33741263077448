import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { Paragraph, Subheading } from "components/Typography"
import React, { useState } from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native'
import { InviteDto } from 'store/dto/invite.dto'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from "styles/common"
import QRCode from 'react-native-qrcode-svg'
import { Button } from "./Button"
import { FooterButton } from 'components/Utility/FooterButton'
import { shareMessage } from "lib/shareHelpers"
import { createBranchLink } from "lib/linkHelpers"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"

type InviteShareModalProps = {
  invite: InviteDto
  visible: boolean
  onDismiss: any
}

const isWeb = platformIsWeb()

export const InviteShareModal = (props: InviteShareModalProps) => {
  const { invite, visible, onDismiss } = props
  
  const shareUrl =  createBranchLink('partner-invite', {
    preferredInviteId: invite?.id,
    '~campaign': 'in_app',
  })

  const title = `Jarvis: Pensions without the guesswork`
  const message = `Connect with me on Jarvis so we can plan our retirement together!. Use this link to get access: ${shareUrl}`

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
            </View>
            <Subheading>{`Ask your partner to scan the QR code below to download the app and connect accounts`}</Subheading>
            <View style={{
                alignSelf: 'center',
                paddingVertical: Sizing.x20
              }}>
              <QRCode
                value={shareUrl}
                size={Sizing.x200}
              />
            </View>
            <ContentDivider />
            <Paragraph>{`Alternatively, you can share the link with them via email, chat or any other app:`}</Paragraph>
            <Button mode='text' onPress={() => shareMessage({ message, title })}>{`Share Partner Invitation Link`}</Button>
          </ScrollView>
          <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}