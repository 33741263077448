import { Logger } from 'lib/logger'
import React from 'react'
import { WebView } from 'react-native-webview'

export const GenericWebView = ({ uri = `google.com` }) => {
    let webview = null
    return (
        <WebView
            source={{
                uri
            }}
            ref={ (ref) => { webview = ref } }
            onNavigationStateChange={(webStateView) => {
              Logger.debug(`onNavigationStateChange`, webStateView.url)
            }}
        />
    )
}
