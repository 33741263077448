import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { useGuidanceContext } from 'providers'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useGetRetirementProfileQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'

export const Contributions_20_AgeChoiceReduceVRA = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching, currentViableAgeError, refetchCurrentViableAge } = useGuidanceContext()

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const proposedRetirementAgeRef = useRef()

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const isLoading = rpIsLoading || currentViableAgeIsLoading || currentViableAgeIsFetching
  const error: any = rpError || currentViableAgeError

  //Setup form
  const formObj = useForm<{ proposedRetirementAge: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proposedRetirementAge: workingSuggestionData?.proposedRetirementAge || currentViableAge?.output?.viableRetirementAge,
    },
  })
  const { handleSubmit, trigger, setValue, formState: { isValid, isDirty } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSuggestion(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={currentViableAgeError ? refetchCurrentViableAge : refetchRp}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What age shall we aim for?`}
      subHeading={`You can currently retire at ${currentViableAge?.output?.viableRetirementAge} and your target is ${retirementProfile?.targetRetirementAge}`}
    >
      <ManagedIntegerInput
        ref={proposedRetirementAgeRef}
        name={'proposedRetirementAge'}
        formObj={formObj}
        returnKeyType="next"
        placeholder={'Enter age'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        required={true}
        minValue={retirementProfile?.targetRetirementAge}
        maxValue={currentViableAge?.output?.viableRetirementAge}
        informationMessage={`Pick an age between ${retirementProfile?.targetRetirementAge} and ${currentViableAge?.output?.viableRetirementAge}`}
      />
    </ProcessScreen>
  )
}
