import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'
import { MIN_TARGET_RETIREMENT_AGE } from 'lib/constants'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetRetirementProfileQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'

export const Contributions_40_AgeChoiceReduceTRA = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const proposedTargetRetirementAgeRef = useRef()

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const isLoading = meIsLoading || rpIsLoading
  const error: any = meError || rpError

  //Setup form
  const formObj = useForm<{ proposedTargetRetirementAge: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proposedTargetRetirementAge: workingSuggestionData?.proposedTargetRetirementAge || retirementProfile?.targetRetirementAge,
    },
  })
  const { handleSubmit, trigger, setValue, formState: { isValid, isDirty } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSuggestion(attributes))    
    navigation.navigate(nextScreen)
  }

  const minTargetRetirementAge = me ? deriveMinimumTargetRetirementAge(me) : MIN_TARGET_RETIREMENT_AGE

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={meError ? refetchMe : refetchRp}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What age shall we target now?`}
      subHeading={`Your current target is ${retirementProfile?.targetRetirementAge}`}
    >
      <ManagedIntegerInput
        ref={proposedTargetRetirementAgeRef}
        name={'proposedTargetRetirementAge'}
        formObj={formObj}
        returnKeyType="next"
        placeholder={'Enter age'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        required={true}
        minValue={minTargetRetirementAge}
        maxValue={retirementProfile?.targetRetirementAge}
        informationMessage={`Pick an age between ${minTargetRetirementAge} and ${retirementProfile?.targetRetirementAge}`}
      />
    </ProcessScreen>
  )
}
