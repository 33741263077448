import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { InformationButton } from 'components/Utility/InformationButton'
import { rootNavigate } from 'lib/RootNavigation'
import { MAX_ASSET_REGULAR_CONTRIBUTIONS, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const Calculator_43_TotalMonthlyContributions = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}
  
  const linkConfig = useAppSelector(linkParams)

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const [showDialog, setShowDialog] = useState(false)

  const {
    asset,
  } = workingCalculatorRequestData || {}

  const {
    totalCurrentValue
  } = asset || {}

  const withSpouse = workingCalculatorRequestData?.withSpouse

  //Setup form
  const formObj = useForm<{ totalMonthlyContributionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      totalMonthlyContributionAmount: workingCalculatorRequestData?.asset?.totalMonthlyContributionAmount
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const { totalMonthlyContributionAmount } = attributes
    dispatch(updateWorkingCalculatorRequest({
      asset: {
        ...workingCalculatorRequestData.asset,
        totalMonthlyContributionAmount,
      },
    }))

    ampli.calculatorUsage({
      processStep: 'contributions_total',
      outcome: 'provided',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    navigation.navigate(nextScreen)
  }

  const skip = () => {
    setShowDialog(false)
    dispatch(updateWorkingCalculatorRequest({
      asset: {
        ...workingCalculatorRequestData.asset,
        totalMonthlyContributionAmount: 0,
      },
    }))

    ampli.calculatorUsage({
      processStep: 'contributions_total',
      outcome: 'none',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    navigation.navigate(nextScreen)
  }

  const isWeb = platformIsWeb()

  const informationTexts: string[] = [
    `${totalCurrentValue ? `Along with the retirement savings you already have, ` : `Although you don't yet have any retirement savings, `}putting money away regularly is the best way to grow your retirement pot and prepare for your retirement.`,
    `This can dramatically increase the size of your retirement pot in the future, and have a big impact on the age from which you can support ${withSpouse ? `yourselves ` : `yourself `}in retirement.`,
    `For simplicity, this calculator needs an estimated total amount of pension contributions (and any other retirement savings) that you are making each month.`,
  ]

  if(withSpouse) {
    informationTexts.push(`As you are retiring with a partner, this should be the overall total amount between you.`)
  }

  informationTexts.push(`You should include contributions to any workplace and personal pensions, or monthly savings into investments that you consider to be part of your retirement planning.`)
  informationTexts.push(`You should NOT include pension contributions you are making to pensions based on defined benefits, such as government final salary schemes.`)
  informationTexts.push(`Please estimate the total gross amount going into your pensions - for personal pensions you should include the ${formatPercentageAmount(PERSONAL_TAX_RELIEF_FACTOR * 100)} government tax relief, and for workplace pensions, it should be the total amount contributed between yourself and your employer.`)
  informationTexts.push(`The full Jarvis app allows you to individually record the contributions for any pensions you record, building an accurate view of your assets and how they could grow.`)

  return (
    <ProcessScreen
      buttonTitle={'See Results'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      allowTextButton={true}
      textButtonAction={totalCurrentValue ? skip : () => setShowDialog(true)}
      textButtonTitle={totalCurrentValue
        ? `${withSpouse ? `We're` : `I'm`} not regularly saving right now`
        : `${withSpouse ? `We` : `I`} haven't started yet`
      }
      headline={totalCurrentValue ? `Finally, how much do you ${withSpouse ? `both ` : ``}save monthly for retirement?` : `Ok, but have ${withSpouse ? `either of ` : ``}you recently started saving?`}
      subHeading={`${withSpouse ? `Between you, record ` : `Record `}the amount you are saving each month for retirement`}
      subHeadingInfo={<InformationButton title={`Gross Monthly Savings`} texts={informationTexts} illustrationFilename={'regular_contributions.png'} buttonTitle={`How do I calculate this?`} />}
    >
      <ManagedCurrencyInput
        name={'totalMonthlyContributionAmount'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        placeholder={'Estimated total monthly contributions'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={1}
        maxValue={MAX_ASSET_REGULAR_CONTRIBUTIONS}
        blurOnSubmit={false}
        informationMessage={`If you don't know exactly, a rough estimate is fine`}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`Please confirm you want to continue with no monthly savings.\n\nRemember to consider all pension contributions and regular savings to other retirement assets${withSpouse ? ', including those owned by your partner. ' : '. '}\n\nEven if you have only just started saving, we can show you how this would grow over time to support your retirement.\n\nYou don't need the exact amount - an estimate of the montly total is fine!`}
        confirmLabel={'Continue'}
        onConfirm={skip}
      />
    </ProcessScreen>
  )
}
