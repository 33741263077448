import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useAddRetirementAssetMutation } from 'store/apiSlice'
import { CreateRetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_99_Result = ({ navigation, route }) => {
  const { onDismiss, client, forTransfer }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingRetirementAssetData: any = useAppSelector(workingRetirementAsset)
  const [ addAsset, { data: newAsset, error: newAssetError, isLoading: newAssetIsLoading } ] = useAddRetirementAssetMutation()
  const error = newAssetError
  const isLoading = newAssetIsLoading

  const close = () => {
    dispatch(updateWorkingRetirementAsset(undefined))
    
    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_finish',
        isRetransfer: false,
        outcome: 'success',
      })
    }
    
    onDismiss()
  }

  //Save on entry
  useEffect(() => {
    saveAsset()
  }, [])

  const saveAsset = () => {
    Logger.info(`Adding asset...`)
    if (workingRetirementAssetData) {
      const payload: CreateRetirementAssetDto = {
        ...workingRetirementAssetData,
        clientId: client?.id,
      }
      addAsset(payload)
    }
  }

  //Close on save
  useEffect(() => {
    if (newAsset) {
      ampli.retirementAssetCreate({
        ...pick(newAsset, [
          'assetType',
          'isTransferable',
          'pensionProviderId',
          'currentValue',
          'monthlyContributionAmount',
          'contributionSource',
          'employerId',
          'employerName',
          'employerIsPublicSector',
          'pensionProviderBrandKey',
          'pensionProviderBrandName',
        ]),
        isSpouse: client?.isSpouse,
      })
      close()
    }
  }, [newAsset])


  return (
    <ModalProcessResultScreen
      headline={``}
      buttonTitle={'Continue'}
      error={error}
      errorTryAgain={saveAsset}
      errorCancel={() => navigation.goBack()}
      isLoading={isLoading}
      loadingMessage={['Saving asset...']}
      buttonAction={close}
      showButton={false}
    >
    </ModalProcessResultScreen>
  )
}
