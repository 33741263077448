import { ManagedProportionInput } from 'components/Inputs/ManagedProportionInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { concat, fill, floor, round } from 'lodash'
import { default as React } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Headline } from 'react-native-paper'
import { useGetAssetsQuery } from 'store/apiSlice'
import { AssetAllocationDto } from 'store/dto/account.dto'
import { Sizing } from 'styles'

export type InvestmentAssetAdjustAllocationModalProps = {
  assetAllocation: AssetAllocationDto[]
  onDismiss: Function
  onAdjustFunction: Function  
}

export const InvestmentAssetAdjustAllocationModal = (props: InvestmentAssetAdjustAllocationModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: InvestmentAssetAdjustAllocationModalProps) => {

  const { assetAllocation, onDismiss, onAdjustFunction } = props

  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetAssetsQuery()

  //Create array of proportion values
  const startingProportions = assetAllocation ? assetAllocation.map(allocation => {
    return allocation?.proportion
  }) : []

  const labels = assetAllocation ? assetAllocation.map(allocation => {
    const asset = assets ? assets.find(asset => {
      return allocation.assetId === asset.id
    }) : undefined

    return asset?.shortName || ``
  }) : []

  const formObj = useForm<{ proportions: number[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proportions: startingProportions,
    }
  })
  const { getValues, setValue, handleSubmit, trigger, watch, formState: { isValid } } = formObj

  const equalizeValues = () => {
    const proportions = watch('proportions')
    const length = proportions.length
    const otherLength = length - 1
    if (otherLength < 1) {
      return
    }
    const equalShare = floor((1 / length), 2) // Nearest whole number
    const lastShare = 1 - (equalShare * otherLength)
    const newProportions = concat(fill(Array(otherLength), equalShare), [lastShare])
    setValue('proportions', newProportions )
    trigger('proportions')
  }

  const onSubmit = async attributes => {
    const updatedAllocations = assetAllocation.map((allocation, idx) => {
      return {
        ...allocation,
        proportion: round(attributes.proportions[idx], 2),
      }
    })
    
    onAdjustFunction(updatedAllocations)
  }

  return (
    <ModalEditScreen
      formTitle={'Adjust Allocation'}
      onDismiss={onDismiss}
      isLoading={assetsIsLoading}
      loadingMessage={['Loading funds...']}
      error={assetsError}
      errorTryAgain={refetchAssets}
      errorCancel={onDismiss}
      buttonTitle={'Save Allocations'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      allowTextButton={true}
      textButtonAction={equalizeValues}
      textButtonTitle={'Equalise Allocations'}
    >
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Headline>{`Set how much to invest in each fund`}</Headline>
      </View>
      <ManagedProportionInput
        formObj={formObj}
        name={'proportions'}
        labels={labels}
        labelStyle={{
          fontSize: scaleNormalizer(15),
        }}
        labelsNumberOfLines={2}
        minValue={0.01}
        maxValue={1}
        informationMessage={'Overall total should be 100%'}
      />
    </ModalEditScreen>
  )
}
