import ModalProcessStackBuilder from 'navigation/stacks/ModalProcessStackBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { statementRequestVisible, setStatementRequestVisible } from 'store/uxSlice'
import { StatementRequest_00_Intro, StatementRequest_01_DateRangeChoice, StatementRequest_99_Result } from './Screens'

export const StatementRequestProcessStack = () => {

  const dispatch = useAppDispatch()
  
  const visible = useAppSelector(statementRequestVisible)

  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={() => dispatch(setStatementRequestVisible(false))}
      dismissConfirmationMessage={`Cancel statement request?`}
      processTitle='Request Statement'
      initialRouteName='Intro'
      screens={[
        {
          navigationId: 'Intro',
          title: 'Intro',
          component: StatementRequest_00_Intro,
        },
        {
          navigationId: 'DateRangeChoice',
          title: 'Date Range',
          component: StatementRequest_01_DateRangeChoice,
        },
        {
          navigationId: 'Results',
          title: 'Results',
          component: StatementRequest_99_Result,
        },        
      ]}
    />
  )
}
