import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { goToOnboardingTasks } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ContributionGuidanceType, updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'
import { GuidanceGuidedModalContent } from '../Components/GuidanceGuidedModalContent'
import { GuidanceManualModalContent } from '../Components/GuidanceManualModalContent'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { Paper } from 'styles'

export const Contributions_02_GuidanceChoice = ({ route, navigation }) => {
  const { nextScreen, existingRegularContribution, modelAvailable} = route.params

  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const existingRegularAmount = existingRegularContribution?.amount

  const formObj = useForm<{
    guidanceType: ContributionGuidanceType
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      guidanceType: workingSuggestionData?.guidanceType
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const guidedTitle = 'Use Jarvis Superpowers'
  const guidedDescription = 'Pick a goal and find the right amount to achieve it'
  const guidedIllustrationFilename = 'gamified_approach.png'

  const manualTitle = 'Decide Yourself'
  const manualDescription = existingRegularAmount
    ? 'Make a one-off or change regular contributions'
    : 'Make a one-off or set up regular contributions'
  const manualIllustrationFilename = 'create_profile.png'

  let options: ManagedCardChoiceInputOption[] = [{
      value: ContributionGuidanceType.GUIDED,
      title: guidedTitle,
      description: guidedDescription,
      illustrationFilename: guidedIllustrationFilename,
      modalContentFunction: () => <GuidanceGuidedModalContent
        modelAvaiable={modelAvailable}
        title={guidedTitle}
        description={guidedDescription}
        illustrationFilename={guidedIllustrationFilename}
      />,
      modalProceedLabel: 'Proceed',
      modalCancelLabel: 'Go Back',
      disabled: !modelAvailable
    },
    {
      value: ContributionGuidanceType.MANUAL,
      title: manualTitle,
      description: manualDescription,
      illustrationFilename: manualIllustrationFilename,
      modalContentFunction: () => <GuidanceManualModalContent
        modelAvaiable={modelAvailable}
        title={manualTitle}
        description={manualDescription}
        illustrationFilename={manualIllustrationFilename}
      />,
      modalProceedLabel: 'Proceed',
      modalCancelLabel: 'Go Back',
    },
  ]

  const onSubmit = (attributes) => {
    dispatch(updateWorkingSuggestion(attributes))
    if (attributes.guidanceType === ContributionGuidanceType.GUIDED) {
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate('ManualSetup')
    }
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`How do you want to decide the amount?`}
      subHeading={'Jarvis can help you decide how much to contribute'}
      // allowTextButton={!modelAvailable}
      // textButtonTitle={`Go to Plan Your Retirement`}
      // textButtonAction={showDialog}
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'guidanceType'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        options={options}
      />
      {
        modelAvailable ? <></> :
        <>
          <Paragraph>{`To use Jarvis Superpowers, first complete 'Plan Your Retirement'.`}</Paragraph>
          <Button
            mode={'text'}
            color={themeColors.accent}
            onPress={showDialog}
          >{`Go to Plan Your Retirement`}</Button>
        </>
      }
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`Exit contribution setup and go to 'Plan Your Retirement' now?\n\nOnce completed, simply return to contributions to take advantage of the Jarvis Superpowers.`}
        onCancel={hideDialog}
        onConfirm={() => goToOnboardingTasks(OnboardingPhase.PLAN)}
        confirmLabel={'Go Now'}
      />
    </ProcessScreen>

  )
}