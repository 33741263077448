import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput, isSpecialValue } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { orderBy } from 'lodash'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { ampli } from 'src/ampli'
import { useGetPensionBrandsQuery } from 'store/apiSlice'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Colors, Sizing, Flex } from 'styles'

export const RetirementAssetsAddAsset_04_ProviderBrand = ({ route, navigation }) => {
  const { nextScreen, client, forTransfer }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const isWorkplace = workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE

  //Form refs for focussing
  const providerRef = useRef()

  //Setup form
  const formObj = useForm<{ pensionProviderBrandKey: string, pensionProviderBrandName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderBrandKey: workingRetirementAssetData?.pensionProviderBrandKey,
      pensionProviderBrandName: workingRetirementAssetData?.pensionProviderBrandName,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,      
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
    }))

    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_brand',
        isRetransfer: false,
        outcome: 'success',
      })
    }

    next()
  }

  const skip = () => {
    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_brand',
        isRetransfer: false,
        outcome: 'skip',
      })
    }

    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const pensionProviderBrandKey = watch('pensionProviderBrandKey')
  const pensionProviderBrandName = watch('pensionProviderBrandName')
  const hasValue = !!(pensionProviderBrandName && pensionProviderBrandName !== '')

  //Build autocomplete options
  const { data: brands, error, isLoading, refetch } = useGetPensionBrandsQuery()
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={refetch}
      isLoading={isLoading}
      loadingMessage={['Fetching pension brands...']}
      buttonTitle={hasValue ? `Next` : `Make a Selection`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasValue}
      headline={workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE
        ? `Who did they use for ${client?.isSpouse ? `their` : `your`} pension?`
        : `With whom did ${client?.isSpouse ? `they` : `you`} set up ${client?.isSpouse ? `their` : `your`} pension?`
      }
      allowTextButton={isWorkplace}
      textButtonAction={skip}
      textButtonTitle={`I don't know / Not listed`}
    >

      <ManagedAutoCompleteInput
        name={'pensionProviderBrandKey'}
        placeholder={'Tap to search or enter...'}
        allowFreeText={true}
        textCaptureFieldName={'pensionProviderBrandName'}
        unknownItemUseDescription={'Use this name'}
        unknownItemSelectedDescription={'Name specified by you'}
        formObj={formObj}
        modalTitle={'Find/Enter Pension Brand'}
        required={true}
        dataSet={brandOptions}
        />
    </ModalProcessScreen>
  )
}
