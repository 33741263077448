import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { useGetCurrentUserQuery, useGetInvitesAsTargetQuery, useGetNationalitiesQuery } from 'store/apiSlice'

export const SpouseAdd_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}

  const { data: invitesAsTarget, error: invitesAsTargetError, isLoading: invitesAsTargetIsLoading, refetch: refetchInvitesAsTarget } = useGetInvitesAsTargetQuery()

  const isLoading = invitesAsTargetIsLoading
  const error: any = invitesAsTargetError

  const next = () => {
    navigation.navigate(hasInvite ? nextScreen : 'Consent')
  }
  
  const hasInvite = !!invitesAsTarget?.length
  const multipleInvites = !!invitesAsTarget && invitesAsTarget.length > 1
  
  //Preload data
  useGetCurrentUserQuery()
  useGetNationalitiesQuery()

  return (    
    <ModalProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchInvitesAsTarget}
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'add_your_partner.png'} 
      headline={`Tell us about your partner`}
      subHeading={`This helps you you build a complete picture of your retirement planning`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.SPOUSE_ADDITION} buttonTitle={`Why should I do this?`} />}
    >
    </ModalProcessIntroScreen>
  )
}
