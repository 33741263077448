import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { InformationButton, InformationModalLink } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { StatePensionChoice, updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_40_StatePensions = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const {
    timeline,
    withSpouse,
    } = workingCalculatorRequestData || {}
  const { statePensionMaxAmount } = timeline || {}

  //Setup form
  const formObj = useForm<{ statePensionChoice: StatePensionChoice }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionChoice: workingCalculatorRequestData?.statePensionChoice
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const statePensionChoice = attributes.statePensionChoice
    dispatch(updateWorkingCalculatorRequest({
      statePensionChoice,
      income: {
        statePensionAmount: statePensionChoice !== StatePensionChoice.NONE ? statePensionMaxAmount : 0,
        spouseStatePensionAmount: statePensionChoice === StatePensionChoice.BOTH ? statePensionMaxAmount : 0,
      }
    }))

    ampli.calculatorUsage({
      processStep: 'state_pension_choice',
      outcome: statePensionChoice,
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    navigation.navigate(nextScreen)
  }

  const statePensionOptions: ManagedSimpleChoiceItem[] = withSpouse ? [
    {
      value: StatePensionChoice.BOTH,
      label: `Yes, both of us`
    },
    {
      value: StatePensionChoice.ONE,
      label: `Only one of us`
    },
    {
      value: StatePensionChoice.NONE,
      label: `No, neither of us`,
    },
  ] : [
    {
      value: StatePensionChoice.ONE,
      label: `Yes`
    },
    {
      value: StatePensionChoice.NONE,
      label: `No`,
    },
  ]

  const informationTexts: string[] = [
    `If you have made sufficient National Insurance Contributions (NICs) during your working life, you are likely to be eligible for a state pension from the UK government.`,
    `The amount you could receive depends on your National Insurance record - you'll generally need 35 qualifying years to get the full state pension, and at least 10 to get anything.`,
    `To find out how much you state pension could me, use the goverment website to check your State Pension forecast.`,
    `For simplicity, this calculator lets you select a full state pension or none - the full Jarvis app allows you to set a partial amount that you can base on your predicted amount from the UK government online checker. It also allows you to record other incomes such as pensions based on defined benefits or government final salary schemes.`
  ]

  const informationLinks: InformationModalLink[] = [
    {
      title: `About the New State Pension`,
      url: `https://www.gov.uk/new-state-pension`,
    },
    {
      title: 'Check Your State Pension',
      url: 'https://www.gov.uk/check-state-pension',
    }
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, let's consider incomes in retirement`}
      subHeading={`Do you ${withSpouse ? 'both ' : ''}expect receive the full UK state pension (currently ${formatCurrencyAmount(statePensionMaxAmount, 0)}${withSpouse ? ' each' : ''})?`}
      subHeadingInfo={<InformationButton title={`UK State Pensions`} texts={informationTexts} links={informationLinks} illustrationFilename={'record_an_existing_pension.png'} buttonTitle={`About State Pensions`} />}
    >
      <ManagedSimpleChoiceInput
        name={'statePensionChoice'}
        formObj={formObj}
        options={statePensionOptions}
        required={true}
        submitHandler={handleSubmit(onSubmit)}
      />
    </ProcessScreen>
  )
}