import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatUkDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React from 'react'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppSelector } from 'store/hooks'
import { workingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentSetContributionRequestAdd_02_Confirm = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}

  //Temp state
  const workingSchemeEnrolmentRequestData = useAppSelector(workingSchemeEnrolmentRequest)
  const { data } = workingSchemeEnrolmentRequestData || {}
  const { employerContributionAmount, contributionFrequency } = data || {}

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={enrolment.isVerified ? `Confirm & Finish` : 'Confirm'}
      buttonAction={next}
      enableButton={true}
      headline={enrolment.isVerified
        ? `Ok, we're ready to send to your employer`
        : `Ok, that's everything we need`
      }
      subHeading={`Please check the details below and confirm`}
    >
      <UnborderedTable
        data={[
          {
            label: `Request Type`,
            value: `Set Contributions`
          },
          {
            label: `Gross Amount`,
            value: formatCurrencyAmount(employerContributionAmount, 2),
          },
          {
            label: `Frequency`,
            value: contributionFrequency,
          },
          {
            label: `Request Date`,
            value: formatUkDate(new Date())
          },
        ]}
      />
      {
        !enrolment.isVerified
          ? <Paragraph>{`Your request will be sent when you have finished setting up this employer.`}</Paragraph>
          : <></>
      }
      <Paragraph>{`Depending on your employment circumstances, your employer may need to contact you about this request.`}</Paragraph>
    </ModalProcessScreen>
  )
}
