import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { envVariables } from 'environment'
import { goToDashboard, rootNavigate } from 'lib/RootNavigation'
import { getInviteName } from 'lib/inviteHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React from 'react'
import { useGetCurrentUserQuery, useGetInviteQuery, useGetInvitesAsTargetQuery, useGetSpouseQuery } from 'store/apiSlice'
import { Paper } from 'styles'

export const InviteAcceptIntroScreen = ({ route, navigation }) => {
  const { invitation } = route.params || {}

  const { marketingUrl, appUrl } = envVariables

  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: invites, error: invitesError, isLoading: invitesIsLoading, isFetching: invitesIsFetching, refetch: refetchInvites } = useGetInvitesAsTargetQuery()
  const { data: invite, error: inviteError, isLoading: inviteIsLoading, isFetching: inviteIsFetching, refetch: refetchInvite } = useGetInviteQuery(invitation, { skip: !invitation })

  //@ts-ignore
  const inviteIsGone = inviteError?.data?.code === 404
  //@ts-ignore
  const inviteIsForOther = inviteError?.data?.code === 403

  const isLoading = userIsLoading || invitesIsLoading || inviteIsLoading || spouseIsLoading
  const error: any = userError || invitesError

  const hasInvite = !!invites?.length
  const multipleInvites = !!invites && invites.length > 1
  const singleInvite = hasInvite && !multipleInvites ? invites[0] : undefined
  const hasRealSpouse = !!spouse?.userId
  const ready = !inviteIsGone && !inviteIsForOther && hasInvite && !hasRealSpouse

  const next = () => {
    rootNavigate('InviteAcceptProcessStack', { invitation })
  }

  const refetchAll = () => {
    refetchUser()
    refetchSpouse()
    refetchInvites()
    refetchInvite()
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const inviteChecking = invitesIsLoading || invitesIsFetching

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      loadingMessage={getLookingLoadingMessages('Finding invites...')}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={ready ? multipleInvites ? 'View Invites' : 'View Invite' : 'Back to Dashboard'}
      buttonAction={ready ? next : goToDashboard}
      showButton={true}
      cancelButtonAction={ready
        ? goToDashboard
        : inviteChecking
          ? () => {}
          : refetchInvites
      }
      cancelButtonText={ready
        ? undefined
        : inviteChecking
          ? 'Checking...'
          : 'Check again for invites'
      }
      illustrationFilename={'invite.png'}
      headline={inviteIsGone || inviteIsForOther
        ? `This invitation is no longer available`
        : hasRealSpouse
          ? `Your account is already linked with a partner`
          : hasInvite
            ? multipleInvites
              ? `You have multiple partner invitations`
              : 'You have a partner invitation'
            : user?.preferredInviteId
              ? `This invitation is no longer available`
              : `You have no partner invitations at the moment`
      }
      subHeading={inviteIsGone || inviteIsForOther
        ? `The invitation may have been revoked or is expired`
        : hasRealSpouse
          ? `You cannot accept another invitation without unlinking accounts with your current partner`
          : hasInvite
            ? multipleInvites
              ? `Multiple people have invited you to link Jarvis accounts and plan your retirement together`
              : `You have been invited by ${getInviteName(singleInvite)} to link your Jarvis accounts and plan your retirement together`
            : user?.preferredInviteId
              ? `The invitation may have been revoked or is expired`
              : `You don't currently have any open invitations`
      }
    >
    </ProcessIntroScreen>
  )
}
