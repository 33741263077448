import React, { ReactNode, useEffect } from 'react'
import { Dialog, Paragraph, Portal, ThemeProvider } from "react-native-paper"
import { Button } from 'components/Utility/Button'
import { Colors, Paper, Sizing } from 'styles'
import { Text } from 'components/Typography/Text'
import { Image, Keyboard, ScrollView, StyleSheet } from 'react-native'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { AppIllustration } from './AppIllustration'
import { layoutStyles } from 'styles/common'

type ConfirmationDialogProps = {
  visible: boolean
  title?: string
  illustrationFilename?: string
  imageSource?: any
  content?: string | ReactNode
  onCancel?: any
  onConfirm: any
  cancelLabel?: string
  confirmLabel?: string
}
export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { visible, title, imageSource, illustrationFilename, content, onCancel, onConfirm, cancelLabel, confirmLabel } = props || {}

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  //Always force keyboard dismissal
  //This avoid scenario on iOS where user cannot reach the modal buttons because
  //the keyboard is open and they also cannot dismiss it
  useEffect(() => {
    if (visible) {
      Keyboard.dismiss()
    }
  }, [visible])

  const constraintStyle: any = getScreenSizeConstraints()
  const maxContentHeight = getScreenAppHeight() - Sizing.x200

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <Dialog
          visible={visible}
          onDismiss={onCancel}
          style={constraintStyle}
          theme={theme}
        >
          <Dialog.Title style={{
            color: Colors.brand.purple1,
          }}>{title || `Are you sure?`}</Dialog.Title>
          { content ?
            <Dialog.Content
            style={{
              maxHeight: maxContentHeight,
            }}
            >
              <ScrollView
                nestedScrollEnabled
                style={layoutStyles.scrollContainer}
                contentContainerStyle={layoutStyles.scrollContainerContent}
                keyboardShouldPersistTaps='handled'
                showsVerticalScrollIndicator={true}
              >
                {
                  imageSource
                    ? <Image source={imageSource} style={[
                        {
                          width: Sizing.x100,
                          height: Sizing.x100,
                          resizeMode: 'contain',
                          alignSelf: 'center'
                        },
                      ]} />
                    : illustrationFilename ?
                      <AppIllustration filename={illustrationFilename} style={[
                        {
                          width: Sizing.x100,
                          height: Sizing.x100,
                          resizeMode: 'contain',
                          alignSelf: 'center'
                        },
                      ]} />
                  : <></>
                }
                <Paragraph>{content}</Paragraph>
              </ScrollView>
            </Dialog.Content>
            : <></>
          }
          <Dialog.Actions>
            { onCancel ?
              <Button mode='text' onPress={onCancel}><Text style={
                localStyles.buttonText
              }>{cancelLabel || 'Cancel'}</Text></Button>
              : <></>
            }
            <Button mode='text' onPress={onConfirm}><Text style={
              localStyles.buttonText
            }>{confirmLabel || 'Ok'}</Text></Button>
          </Dialog.Actions>
        </Dialog>
      </ThemeProvider>
    </Portal>
  )
}

const localStyles = StyleSheet.create({
  buttonText: {
    color: Colors.brand.purple1
  },
})


