import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { PensionInvestmentInformationCard } from 'components/Utility/PensionInvestmentInformationCard'
import { getPersonalPension } from 'lib/accountHelpers'
import React from 'react'
import { useGetMeQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { AccountDto } from 'store/dto/account.dto'

export const InvestmentChoice_15_FollowPersonalPension = ({ route, navigation }) => {
  const { nextScreen, contextAccount } : { nextScreen: string, contextAccount: AccountDto } = route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchMe } = useGetMeQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const isLoading = clientIsLoading || accountsIsLoading
  const error: any = clientError || accountsError

  const personalPension = getPersonalPension(accounts)

  const goBackToInvestmentTypeChoice = () => {
    navigation.navigate('InvestmentTypeChoice')
  }

  const next = attributes => {
    navigation.navigate(contextAccount ? 'Terms' : 'OptOut')
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Loading plans...']}
      error={error}
      errorTryAgain={accountsError ? refetchAccounts : refetchMe}
      buttonTitle={'Confirm'}
      buttonAction={next}
      enableButton={true}
      showButton={true}
      headline={`Ok, this is how your Personal Jar is invested`}
      subHeading={`Do you want to follow this strategy?`}
      allowTextButton={true}
      textButtonTitle={'Choose a different strategy'}
      textButtonAction={goBackToInvestmentTypeChoice}
    >
      <PensionInvestmentInformationCard
        account={personalPension}
        investmentReferenceAccount={personalPension}
        showAsGlobalDecision={false}
        client={client}
        includePreamble={true}
      />
    </ProcessScreen>
  )
}
