import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Title } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse, workingSpouse } from 'store/tempDataSlice'

export const SpouseAdd_05_Title = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingSpouseData = useAppSelector(workingSpouse)

  //Setup form
  const formObj = useForm<{ title: Title }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: workingSpouseData?.title
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingSpouse(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What title do they normally use?`}
      subHeading={`If it's not listed, just pick the best fit`}

    >
      <ManagedSimpleChoiceInput
        name={'title'}
        formObj={formObj}
        options={enumToAutocompleteOptions(Title)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
