import ModalProcessStackBuilder from 'navigation/stacks/ModalProcessStackBuilder'
import React from 'react'
import { ClientBaseDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { setAddStatePensionClient } from 'store/uxSlice'
import { RetirementIncomesAddStatePension_00_Intro, RetirementIncomesAddStatePension_01_Income, RetirementIncomesAddStatePension_99_Result } from "./Screens"

export type RetirementIncomesAddStatePensionProcessStackProps = {
  client: ClientBaseDto
  onDismiss?: any
}

export const RetirementIncomesAddStatePensionProcessStack = (props: RetirementIncomesAddStatePensionProcessStackProps) => {
  const { client } = props

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setAddStatePensionClient(undefined))

  const visible = !!client
  const onDismiss = props.onDismiss || globalDismiss

  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding state pension?`}
      processTitle='Add State Pension'
      initialRouteName='Intro'
      initialParams={{
        client,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add State Pension',
          component: RetirementIncomesAddStatePension_00_Intro,
        },
        {
          navigationId: 'Income',
          title: 'Eligible Amount',
          component: RetirementIncomesAddStatePension_01_Income,
        },
        {
          navigationId: 'Results',
          title: '',
          component: RetirementIncomesAddStatePension_99_Result,
        },
      ]}
    />
)
}

