import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { RetirementBudgetTab } from 'components/Layout/RetirementBudgetTab'
import { GROSS_TOTAL_BUDGET_ID, NET_TOTAL_BUDGET_ID } from 'lib/constants'
import React, { useState } from 'react'
import { useGetBudgetsQuery, useGetRetirementProfileQuery } from 'store/apiSlice'
import { BudgetDto, BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { uniqBy } from 'lodash'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { BudgetTable } from 'components/Utility/BudgetTable'

const DEFAULT_BUDGET_LEVEL = BudgetLevelIdentifier.MODERATE

export const ChangeRetirementBudget_03_LevelChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  let selectedLevel = DEFAULT_BUDGET_LEVEL

  let categoryBudgets: BudgetDto[] = []

  const dispatch = useAppDispatch()

  const [currentLevel, setCurrentLevel] = useState<BudgetLevelIdentifier>(selectedLevel)

  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const { data: budgets, error: budgetsError, isLoading: budgetsIsLoading, refetch: refetchBudgets } = useGetBudgetsQuery({
    asCouple: workingRetirementProfileData?.asCouple,
    insideLondon: workingRetirementProfileData?.insideLondon,
  }, { skip: !workingRetirementProfileData })
  
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const isLoading = budgetsIsLoading || rpIsLoading
  const error: any = budgetsError || rpError

  const hasMixedBudget = retirementProfile && uniqBy(retirementProfile?.expenses, 'selectedLevel').length > 1 

  const [showLevels, setShowLevels] = useState(!hasMixedBudget)

  //Extract budgets when available
  if (budgets) {
    const temp = []
    budgets.forEach(budget => {
      if (budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID) {
        temp.push(budget)
      }
    })
    categoryBudgets = temp
  }

  const netTotalBudget = budgets ? budgets.find(budget => {
    return budget.id === NET_TOTAL_BUDGET_ID
  }) : undefined

  const onSubmit = () => {
    const expenses = budgets ? budgets.filter(budget => {
      return budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID
    }).map(budget => {
      //Extract budget level
      const budgetLevel = budget.levels.find(l => {
        return l.id === currentLevel
      })
      //Construct expense item
      return {
        id: budget.id,
        selectedLevel: budgetLevel?.id || BudgetLevelIdentifier.CUSTOM, 
        selectedCost: budgetLevel?.value || 0,
      }
    }) : []
    //Update the working retirement profile
    dispatch(updateWorkingRetirementProfile({
      baseBudgetLevel: currentLevel,
      expenses,
    }))
    //Navigate
    navigation.navigate(nextScreen)
  }

  const keepCustomLevels = () => {
    const expenses = budgets ? budgets.filter(budget => {
      return budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID
    }).map(budget => {
      //Extract budget level using existing level from retirement profile
      const rpExpense = retirementProfile?.expenses.find(expense => {
        return expense.id === budget.id
      })
      const rpLevel = rpExpense?.selectedLevel || currentLevel
      const budgetLevel = budget.levels.find(l => {
        return l.id === rpLevel
      })
      //Construct expense item
      const isCustom = rpExpense.selectedLevel === BudgetLevelIdentifier.CUSTOM
      return {
        id: budget.id,
        selectedLevel: isCustom ? BudgetLevelIdentifier.CUSTOM : budgetLevel?.id || BudgetLevelIdentifier.CUSTOM, 
        selectedCost: isCustom ? rpExpense.selectedCost : budgetLevel?.value || 0,
      }
    }) : []
    //Update the working retirement profile
    dispatch(updateWorkingRetirementProfile({
      expenses,
    }))
    //Navigate
    navigation.navigate(nextScreen)
  }

  const updateLevelFromState = (e) => {
    const index = e?.state?.index
    const routeNames = e?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    selectedLevel = currentName
    setCurrentLevel(selectedLevel)
  }

  const findClosestLevel = () => {
    const levels = netTotalBudget?.levels

    //Find using level if available
    if (retirementProfile?.baseBudgetLevel) {
      const exactMatch = levels.find(level => {
        return level.id === retirementProfile?.baseBudgetLevel
      })
      if (exactMatch) {
        return exactMatch.id
      }
    }
    let bestMatch = levels[0]
    //See if any other levels are closer
    for (let i = 1; i < levels.length; i++) {
      const currentDiff = Math.abs((bestMatch.value) - retirementProfile?.expensesNetTotalAmount)
      const thisDiff = Math.abs((levels[i].value) - retirementProfile?.expensesNetTotalAmount)
      if (thisDiff < currentDiff) {
        bestMatch = levels[i]
      }
    }
    return bestMatch.id
  }

  const initialLevel = retirementProfile && budgets ? findClosestLevel() : DEFAULT_BUDGET_LEVEL
  
  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Getting budget options...']}
      error={error}
      errorTryAgain={budgetsError ? refetchBudgets : refetchRp}
      buttonTitle={showLevels ? 'Choose' : `Retain existing category levels`}
      buttonAction={showLevels ? onSubmit : keepCustomLevels}
      showButton={true}
      enableButton={true}
      headline={showLevels ? `First, choose a high-level budget`: `Retain existing category levels?`}
      subHeading={showLevels ? undefined : `Your existing budget uses different levels per category`}
      subHeadingInfo={showLevels ? <NamedInformationButton name={NamedInformation.RETIREMENT_BUDGET} /> : undefined}
      allowTextButton={hasMixedBudget}
      textButtonTitle={showLevels ? 'Retain existing category levels' : `Start afresh from base budget`}
      textButtonAction={showLevels ? () => setShowLevels(false) : () => setShowLevels(true)}
    >
      {
        // retirementProfile && budgets ?
        showLevels ? 
          <RetirementBudgetTab
            netTotalBudget={netTotalBudget}
            categoryBudgets={categoryBudgets}
            initialLevel={initialLevel}
            stateListenerFunction={updateLevelFromState}
          />
        : <BudgetTable
            isLoading={isLoading}
            rows={budgets ? budgets.map(budget => {
              return {
                budget,
              }
            }) : []}
            expenses={retirementProfile?.expenses}
            netMonthlyTotal={0}
            showLevels={true}
          />
      }
    </ModalProcessScreen>
  )
}
