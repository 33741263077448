import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { useAddRetirementIncomeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome, workingIncome } from 'store/tempDataSlice'
import { pick } from 'lodash'
import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'

export const RetirementIncomesAddIncome_99_Result = ({ navigation, route }) => {
  const { onDismiss, client }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingIncomeData: any = useAppSelector(workingIncome)
  const [ addIncome, { data: income, error, isLoading } ] = useAddRetirementIncomeMutation()

  const close = () => {
    batch(() => {
      dispatch(updateWorkingIncome(undefined))
      onDismiss()
    })
  }

  //Save on enter
  useEffect(() => {
    saveIncome()
  }, [])

  const saveIncome = () => {
    if (workingIncomeData) {
      Logger.info(`Adding income...`)
      addIncome({
        ...workingIncomeData,
        clientId: client?.id,
      })
    }
  }


  //Close on save
  useEffect(() => {
    if (income) {
      ampli.retirementIncomeCreate({
        ...pick(income, [
          'incomeType',
          'annualIncomeAmount',
          'incomeStartDate',
          'incomeStartAge',
        ]),
        isSpouse: client?.isSpouse,
      })
      close()
    }
  }, [income])


  return (
    <ModalProcessResultScreen
      headline={``}
      buttonTitle={'Continue'}
      error={error}
      errorTryAgain={saveIncome}
      errorCancel={() => navigation.goBack()}
      isLoading={isLoading}
      loadingMessage={['Saving income...']}
      buttonAction={close}
      showButton={false}
    >
    </ModalProcessResultScreen>
  )
}
