import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/Layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingInvite, workingInvite } from 'store/tempDataSlice'

export const SpouseInvite_01_CheckDetails = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingInviteData = useAppSelector(workingInvite)

  const { data: spouse, isLoading: spouseIsLoading, error: spouseError, refetch } = useGetSpouseQuery()

  //Setup form
  const formObj = useForm<{checkDetailsChoice: boolean}>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      checkDetailsChoice: workingInviteData?.checkDetailsChoice
    }
  });
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingInvite({
      ...attributes,
      firstName: spouse?.firstName,
      surname: spouse?.surname,
    }))
    if (attributes.checkDetailsChoice) {    
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate(`Email`)
    }
  }
  
  const checkOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: `Yes`
    },
    {
      value: true,
      label: 'No'
    }
  ];

  return (
    <ProcessScreen
      isLoading={spouseIsLoading}
      error={spouseError}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Is your partner's name correct?`}
      subHeading={`We currently have the name '${spouse?.firstName} ${spouse?.surname}'`}
    >
      <ManagedSimpleChoiceInput
        name={'checkDetailsChoice'}
        formObj={formObj}
        options={checkOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
