import { BulkTransfer_01_Assets, BulkTransfer_05_TransferConfirmation, BulkTransfer_03_Signature, BulkTransfer_99_Result, BulkTransfer_04_TracingConfirmation, BulkTransfer_02_ConsolidationInfo } from './Screens'
import React from 'react'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'

export const BulkTransferProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='BulkTransfer'
      processTitle='Consolidate Pensions'
      previousRoute='BulkTransferIntroScreen'
      nextRoute='BulkTransferHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Assets',
          component: BulkTransfer_01_Assets,
        },
        { 
          navigationId: 'ConsolidationInfo',
          component: BulkTransfer_02_ConsolidationInfo,
        },
        {
          navigationId: 'Signature',
          component: BulkTransfer_03_Signature,
        },
        {
          navigationId: 'TracingConfirmation',
          component: BulkTransfer_04_TracingConfirmation,
        },
        {
          navigationId: 'TransferConfirmation',
          component: BulkTransfer_05_TransferConfirmation,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: BulkTransfer_99_Result,
      }}
    />
  )
}
