import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from "store/dto/account.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from "store/tempDataSlice"
import { SchemeEnrolmentChangeStatusRequestAdd_00_Intro, SchemeEnrolmentChangeStatusRequestAdd_01_Info, SchemeEnrolmentChangeStatusRequestAdd_02_Confirm, SchemeEnrolmentChangeStatusRequestAdd_99_Result } from "./Screens"

export type SchemeEnrolmentChangeStatusRequestAddProcessStackProps = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  requestType: GroupSchemeRequestType
  onDismiss: any
  resultHandlerFunction?: any  
}

export const SchemeEnrolmentChangeStatusRequestAddProcessStack = (props: SchemeEnrolmentChangeStatusRequestAddProcessStackProps) => {
  const { resultHandlerFunction, onDismiss, enrolment, requestType } = props

  const dispatch = useAppDispatch()

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingSchemeEnrolmentRequest(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={!!enrolment}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Discard this request?`}
      processTitle={requestType === GroupSchemeRequestType.OPT_IN
        ? 'Request Opt In'
        : requestType === GroupSchemeRequestType.OPT_OUT
        ? 'Request Opt Out'
        : 'Request to Leave Scheme'}
      initialRouteName='Intro'
      initialParams={{
        onDismiss,
        resultHandlerFunction,
        enrolment,
        requestType,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Request',
          component: SchemeEnrolmentChangeStatusRequestAdd_00_Intro,
        },
        {
          navigationId: 'Info',
          title: 'Info',
          component: SchemeEnrolmentChangeStatusRequestAdd_01_Info,
        },
        {
          navigationId: 'Confirm',
          title: 'Confirm',
          component: SchemeEnrolmentChangeStatusRequestAdd_02_Confirm,
        },
        {
          navigationId: 'Results',
          title: '',
          component: SchemeEnrolmentChangeStatusRequestAdd_99_Result,
        },
      ]}
    />
  )
}

