import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'

export const SchemeEnrolmentChangeStatusRequestAdd_01_Info = ({ route, navigation }) => {
  const { nextScreen, onDismiss, enrolment, requestType }: { nextScreen: string, onDismiss: any, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, requestType: GroupSchemeRequestType } = route?.params || {}
  const { groupScheme } = enrolment || {}
  const { organizationName } = groupScheme || {}

  const { autoEnrolmentWindowOptOutDate, enrolmentEndDate } = enrolment

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={requestType === GroupSchemeRequestType.OPT_IN
        ? `About Opting In to Workplace Schemes`
        : requestType === GroupSchemeRequestType.OPT_OUT
        ? `About Opting Out of Workplace Schemes`
        : `About Leaving Workplace Schemes`
      }
      subHeading={requestType === GroupSchemeRequestType.OPT_OUT
        ? `Your employer should follow the UK Government regulations`
        : `We can make a request to your employer to action your request`
      }
      subHeadingInfo={<NamedInformationButton name={NamedInformation.AUTOMATIC_ENROLMENT} buttonTitle={'About Automatic Enrolment'} />}
    >
      {
        requestType  === GroupSchemeRequestType.OPT_IN
          ? <>
          <Subheading>{`You previously left the scheme ${enrolmentEndDate ? `on ${formatUkDate(new Date(enrolmentEndDate))}` : ''}.`}</Subheading>
          <Paragraph>{`We can send a request to ${organizationName} for you to opt back in to their workplace scheme.`}</Paragraph>
          </>
          : requestType  === GroupSchemeRequestType.OPT_OUT
          ? <>
              <Subheading>{`You are still within your 'Opt Out' period ${autoEnrolmentWindowOptOutDate ? `until ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))}` : ''}.`}</Subheading>
              <Paragraph>{`You can choose to opt out of this workplace scheme up until this date, which means:`}</Paragraph>
              <BulletItem style={{ textAlign: 'left' }}>{`You will lose the right to pension contributions from your employer`}</BulletItem>
              <BulletItem style={{ textAlign: 'left' }}>{`You may have a lower income when you retire`}</BulletItem>
              <BulletItem style={{ textAlign: 'left' }}>{`Your employer must return any pension contributions you have made.`}</BulletItem>
            </>
          : <>
              <Subheading>{`You are no longer within your 'Opt Out' period.`}</Subheading>
              <Paragraph>{`We can therefore send a request to ${organizationName} for you to leave their workplace scheme.`}</Paragraph>
            </>
      }
    </ModalProcessScreen>
  )
}
