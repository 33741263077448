import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import { concat } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Dialog, Portal, ThemeProvider } from "react-native-paper"
import { GroupOrganizationRole, GroupSchemeRole } from 'store/dto/group-organization.dto'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { UnborderedTable } from '../../../../components/Utility/UnborderedTable'
import { GroupOrganizationUserRoleAssertion } from '../AdminGroupOrganizationSetUserRolesModal'

type AdminGroupOrganizationUserRolesEditDialogProps = {
  userRoleAssertion: GroupOrganizationUserRoleAssertion
  onClose: any
  onSave: any
  visible: boolean
}


export const AdminGroupOrganizationUserRolesEditDialog = (props: AdminGroupOrganizationUserRolesEditDialogProps) => {
  const { visible, onClose, onSave, userRoleAssertion } = props || {}
  const { userName, userEmail, organizationRole, schemeRole, userId } = userRoleAssertion || {}

  const formObj = useForm<{
    organizationRole: GroupOrganizationRole,
    schemeRole: GroupSchemeRole,
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      organizationRole,
      schemeRole,
    },
  })

  const { handleSubmit, getFieldState, watch, formState: { isValid, errors } } = formObj

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  const constraintStyle: any = getScreenSizeConstraints()
  const maxContentHeight = getScreenAppHeight() - Sizing.x200

  const onSubmit = (attributes) => {
    const { organizationRole, schemeRole } = attributes
    const changedOrgRole = getFieldState('organizationRole').isDirty ? organizationRole : undefined
    const changedSchemeRole = getFieldState('schemeRole').isDirty ? schemeRole : undefined
    onSave(
      changedOrgRole,
      changedSchemeRole
    )
  }

  const currentSchemeRole = watch('schemeRole')

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <Dialog
          visible={visible}
          onDismiss={onClose}
          style={{
            ...constraintStyle,
            minWidth: Sizing.x600,
          }}
          theme={theme}
        >
          <Dialog.Title style={{
            color: Colors.brand.purple1,
            textAlign: 'center',
          }}>{`Modify Roles`}</Dialog.Title>
          <Dialog.Content
          style={{
            maxHeight: maxContentHeight,
          }}
          >
            <ScrollView
              nestedScrollEnabled
              style={layoutStyles.scrollContainer}
              contentContainerStyle={layoutStyles.scrollContainerContent}
              keyboardShouldPersistTaps='handled'
              showsVerticalScrollIndicator={true}
            >
              <UnborderedTable
                data={[
                  {
                    label: `Name`,
                    value: `${userName}`
                  },
                  {
                    label: `Email`,
                    value: userEmail,
                    copyableValue: true,
                  },
                ]}
                noContentDivider={true}
              />
              <View style={{height: Sizing.x300 }}>
                <ManagedAutoCompleteInput
                  name={'schemeRole'}
                  formObj={formObj}
                  label={'Scheme Role (Applies to all schemes)'}
                  selectOnlyMode={true}
                  dataSet={enumToAutocompleteOptions(GroupSchemeRole)}
                  required={true}
                  informationMessage={currentSchemeRole ? 'Supercedes any role granted for an individual scheme' : undefined}
                />
                <ManagedAutoCompleteInput
                  name={'organizationRole'}
                  formObj={formObj}
                  label={'Organization Role'}
                  selectOnlyMode={true}
                  dataSet={enumToAutocompleteOptions(GroupOrganizationRole)}
                  required={true}
                />
              </View>
            </ScrollView>
          </Dialog.Content>
          <Dialog.Actions>
            <Button mode='text' onPress={onClose}>
              <Text style={localStyles.buttonText}>{'Cancel'}</Text>
            </Button>
            <Button mode='text' onPress={handleSubmit(onSubmit)}>
              <Text style={localStyles.buttonText}>{'Save'}</Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </ThemeProvider>
    </Portal>
  )
}

const localStyles = StyleSheet.create({
  buttonText: {
    color: Colors.brand.purple1
  },
})


