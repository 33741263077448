import { envVariables } from 'environment'
import { LAST_GROUP_SCHEME_KEY_NAME } from './constants'
import { getJsonItemFromStore, removeJsonItemFromStore, setJsonItemInStore } from './localStoreHelpers'

const { envName } = envVariables

export const getLastGroupSchemeKeyName = () => {
  return `${envName}-${LAST_GROUP_SCHEME_KEY_NAME}`
}
export const lastGroupSchemeKeyName = getLastGroupSchemeKeyName()


export const getLastGroupSchemeId = async () => {
  return getJsonItemFromStore(lastGroupSchemeKeyName)
}

export const setLastGroupSchemeId = async (groupSchemeId) => {
  await setJsonItemInStore(lastGroupSchemeKeyName, groupSchemeId)
}

// Clears the auth token set in secure store
export const clearLastGroupSchemeId = async () => {
  await removeJsonItemFromStore(lastGroupSchemeKeyName)
}
