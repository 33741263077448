import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { Paragraph, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import { rootNavigate } from "lib/RootNavigation"
import { createBranchLink } from 'lib/linkHelpers'
import { shareMessage } from "lib/shareHelpers"
import React from 'react'
import { Clipboard, ScrollView, View } from "react-native"
import QRCode from 'react-native-qrcode-svg'
import { clientHash } from "store/authSlice"
import { UserDto } from "store/dto/user.dto"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setSnackbarData } from "store/uxSlice"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { Button } from "./Button"
import { platformIsWeb } from "lib/platformHelpers"

type ShareModalProps = {
  user: UserDto
  visible: boolean
  onDismiss: any
  campaign?: string
}

const isWeb = platformIsWeb()

export const ShareModal = (props: ShareModalProps) => {
  const { visible, onDismiss, campaign, user } = props
  const dispatch = useAppDispatch()

  const useCalculator = !!useAppSelector(clientHash)

  const copyToClipboard = async (text) => {
    await Clipboard.setString(text)
    dispatch(setSnackbarData({
      message: `Copied: "${text}"`,
      iconName: 'content-copy',
    }))
  }

  const openCalculator = () => {
    onDismiss()
    rootNavigate('CalculatorIntroScreen', { allowCancel: true, loggedInUserId: user?.id })
  }

  const shareUrl = createBranchLink('share', {
    '~campaign': campaign,
  })

  const title = `Jarvis: Pensions without the guesswork`
  const message = `I'm using Jarvis to secure my financial freedom. Use this link to get access: ${shareUrl}`

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
            </View>
            <Subheading>{`Ask your friend to scan the QR code below to download the app and get started`}</Subheading>
            <View style={{
                alignSelf: 'center',
                paddingVertical: Sizing.x20
              }}>
              <QRCode
                value={shareUrl}
                size={Sizing.x200}
              />
            </View>
            {
              useCalculator ?
                <>
                  <Paragraph>{`Or let them try the quick calculator:`}</Paragraph>
                  <Button
                    mode={'text'}
                    onPress={openCalculator}>
                    {'Try Quick Calculator'}
                  </Button>
                </>
                : <></>
            }

            <Paragraph>{`Alternatively, share it via any other app:`}</Paragraph>
            <Button mode='text' onPress={() => shareMessage({ message, title })}>{`Share link`}</Button>
            {/* <Button mode='text' onPress={() => copyToClipboard(shareUrl)}>{`Copy to clipboard`}</Button> */}
          </ScrollView>
          <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}