import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { GroupOrganizationDto } from 'store/dto/group-organization.dto'
import { Paper, Sizing } from 'styles'

type AdminGroupOrganizationListItemProp = {
  groupOrganization: GroupOrganizationDto
  onPressFunction?: () => void
}
export const AdminGroupOrganizationListItem = (props: AdminGroupOrganizationListItemProp) => {
  const { groupOrganization, onPressFunction } = props
  const { name, companyNo, status, logo, apiKey, apiDisabled } = groupOrganization

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 'briefcase'
  const titleRight = status
  const subTitle = companyNo
  const apiStatus = apiKey && !apiDisabled
  ? `API Access Enabled`
  : apiKey && apiDisabled
  ? `API Access Disabled`
  : `No API Access`
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{apiStatus}</Text>}
    />
  )
}
