import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'

export const SpouseAdd_02_Consent = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Setup form
  const formObj = useForm<{ consentAgreed: string[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      consentAgreed: []
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    navigation.navigate(nextScreen)
  }

  const options: ManagedMultipleChoiceInputOption[] = [{
    value: 'yes',
    label: `I confirm`,
  }]

  const hasConfirmed = watch('consentAgreed').includes('yes')

  return (
    <ModalProcessScreen
      buttonTitle={hasConfirmed ? 'Next' : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmed}
      headline={`Before we start...`}
      subHeading={`We take data protection seriously and need to check you have permission`}      
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'consentAgreed'}
        options={options}
      />
      <Paragraph>{`I confirm I have explicit permission from my partner to use their data to build a picture of our joint retirement plan.`}</Paragraph>
    </ModalProcessScreen>
  )
}
