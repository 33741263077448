import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { differenceInYears } from 'date-fns'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'
import { MAX_INCOME_ANNUAL_AMOUNT, MAX_TARGET_RETIREMENT_AGE } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useDeleteRetirementIncomeMutation, useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery, useUpdateRetirementIncomeMutation } from 'store/apiSlice'
import { RetirementIncomeDto, RetirementIncomeType } from 'store/dto/retirement-income.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditRetirementIncome } from 'store/uxSlice'

export type RetirementAssetsEditIncomeModalProps = {
  income: RetirementIncomeDto
  onDismiss?: any
}

export const RetirementIncomesEditIncomeModal = (props: RetirementAssetsEditIncomeModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: RetirementAssetsEditIncomeModalProps) => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditRetirementIncome(undefined))

  const income = props.income
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  const handleDelete = () => {
    Logger.info(`Deleting income...`)
    ampli.retirementIncomeDelete({
      ...pick(income, [
        'incomeType',
        'annualIncomeAmount',
        'incomeStartDate',
        'incomeStartAge',
      ]),
      isSpouse,
    })
    deleteIncome(income.id)
    onDismiss()
  }

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()

  const [ updateIncome, { data: updatedIncome, error: updateIncomeError, isLoading: updateIncomeIsLoading, reset: updateIncomeReset } ] = useUpdateRetirementIncomeMutation()
  const [ deleteIncome ] = useDeleteRetirementIncomeMutation()
  
  const error: any = rpError || meError || updateIncomeError
  const isLoading = rpIsLoading || meIsLoading || spouseIsLoading || updateIncomeIsLoading

  //Disabled if spouse with a real user
  const isSpouse = income?.clientId === spouse?.id
  const spouseControlled = isSpouse && !!spouse?.userId  

  const belongsToSpouse = spouse && income?.clientId === spouse?.id

  const targetMeAge = retirementProfile?.targetRetirementAge || me?.statePensionAge
  //Age to show
  const ageDifference = belongsToSpouse ? differenceInYears(new Date(me?.birthDate), new Date(spouse?.birthDate)) : 0
  const relevantAge = targetMeAge + ageDifference

  //Form refs for focussing
  const nameRef = useRef()
  // const incomeTypeRef = useRef()
  const annualIncomeAmountRef = useRef()
  const incomeStartAgeRef = useRef()

  //Setup form
  const formObj = useForm<{
    name: string
    incomeType: RetirementIncomeType
    annualIncomeAmount: number
    incomeStartAge: number,
    age: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, trigger, reset, formState: { isDirty, isValid},watch } = formObj
  
  //Re-initialize form when have asset
  useEffect(() => {
    if (income) {
      reset({
        name: income?.name,
        incomeType: income?.incomeType,
        annualIncomeAmount: income?.annualIncomeAmount,
        incomeStartAge: income?.incomeStartAge || retirementProfile?.targetRetirementAge,
        age: income?.incomeStartAge ? 'no' : 'yes'
      })
    }
  }, [income])
  
  const onSubmit = attributes => {
    Logger.info(`Updating income...`)
    const { incomeStartAge, ...remaining } = attributes
    const payload = {
      id: income.id,
      ...remaining,
      incomeStartAge: ageWatch === 'yes' ? null : incomeStartAge
    }
    updateIncome(payload)
  }

  //Close on save result
  useEffect(() => {
    if (updatedIncome) {
      ampli.retirementIncomeUpdate({
        ...pick(updatedIncome, [
          'incomeType',
          'annualIncomeAmount',
          'incomeStartDate',
          'incomeStartAge',
        ]),
        isSpouse: belongsToSpouse,
      })
      onDismiss()
    }
  }, [updatedIncome])

  const ageWatch=watch('age')
  const ageOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 'yes',
      label: `Yes`
    },
    {
      value: 'no',
      label: 'No'
    }
  ]

  useEffect(() => {
    if (ageWatch) {
      trigger('age')
    }
  }, [ageWatch])

  const minTargetRetirementAge = deriveMinimumTargetRetirementAge(me)

  return (
    <ModalEditScreen
      formTitle='Update Income'
      onDismiss={onDismiss}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this income?'}
      onDelete={spouseControlled ? undefined : handleDelete}
      deleteDialogText='Really delete this income?'
      isLoading={isLoading}
      loadingMessage={updateIncomeIsLoading ? ['Saving income...'] : undefined}
      error={error}
      errorTryAgain={updateIncomeError ? handleSubmit(onSubmit) : rpError ? refetchRp : meError ? refetchMe : undefined}
      errorCancel={updateIncomeError ? updateIncomeReset : onDismiss}
      buttonTitle={spouseControlled ? 'Close' : 'Save'}
      buttonAction={spouseControlled ? onDismiss : handleSubmit(onSubmit)}
      showButton={true}
      enableButton={spouseControlled || (isDirty && isValid && !updateIncomeIsLoading)}
    >
      {
        spouseControlled
        ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'incomeType'}
        formObj={formObj}
        label={'Income Type'}
        selectOnlyMode={true}
        required={true}
        disabled={true}
        dataSet={enumToAutocompleteOptions(RetirementIncomeType)}
      />
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Enter a name for this income'}
        blurOnSubmit={false}
        disabled={spouseControlled}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      <ManagedCurrencyInput
        ref={annualIncomeAmountRef}
        name={'annualIncomeAmount'}
        formObj={formObj}
        label={'Annual Income Amount'}
        placeholder={'The amount received each year'}
        required={true}
        minValue={0}
        maxValue={MAX_INCOME_ANNUAL_AMOUNT}
        blurOnSubmit={false}
        disabled={spouseControlled}
      />
      {
        income?.incomeType === RetirementIncomeType.OTHER
        ? <>
            <Paragraph>{`Will the income have started when ${belongsToSpouse ? 'they' : 'you'} retire?`}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'age'}
              formObj={formObj}
              options={ageOptions}
              required={true}
              disabled={spouseControlled}
            />
          </>
        : <></>
      }
     {
      [RetirementIncomeType.OTHER, RetirementIncomeType.DEFINED_BENEFIT].includes(income?.incomeType)
      && ageWatch === 'no'
      ? <>
          <Paragraph>{'Age when income starts'}</Paragraph>
            <ManagedIntegerInput
              ref={incomeStartAgeRef}
              name={'incomeStartAge'}
              formObj={formObj}
              label={'Starting Age'}
              placeholder={'Enter age at which this income will start'}
              blurOnSubmit={false}
              minValue={minTargetRetirementAge + ageDifference}
              maxValue={MAX_TARGET_RETIREMENT_AGE + ageDifference}
              disabled={spouseControlled}
            />
          </>
      : <></>
      }
    </ModalEditScreen>
  )
}