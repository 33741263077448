import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { getHeadlineAndSubHeading } from 'lib/contributionFlowHelpers'
import React from 'react'
import { MakeContributionsStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'

export const MakeContributions_30_TargetRetirementAgeIntro = ({route, navigation}) => {
  const {nextScreen, onDismiss, contributionsData }  = route?.params || {} 

  const { makeContributionSteps } = contributionsData || {}
  
  const next = () => {
    navigation.navigate(nextScreen)
  }

  const { headline, subHeading, buttonTitle } = getHeadlineAndSubHeading(makeContributionSteps, MakeContributionsStepIdentifier.TARGET_AGE)
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={buttonTitle}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={headline}
      subHeading={subHeading}
    >
      <TaskList
        contributionsData={contributionsData}
        nextTask={MakeContributionsStepIdentifier.TARGET_AGE}
        nextTaskAction={next}
      />
    </ModalProcessIntroScreen>
  )
}

