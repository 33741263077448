import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { AddBeneficiaryProcessStack } from 'features/ModalProcesses/BeneficiaryAdd/AddBeneficiaryProcessStack'
import { AdjustProportionModal } from 'features/ModalProcesses/BeneficiaryAdjustProportion/AdjustProportionModal'
import { EditBeneficiaryEditModal } from 'features/ModalProcesses/BeneficiaryEdit/EditBeneficiaryEditModal'
import { isArray } from 'lodash'
import React from 'react'
import { useGetBeneficiariesQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { addBeneficiaryData, adjustBeneficiaryProportionData, editBeneficiaryData, setAdjustBeneficiaryProportionData } from 'store/uxSlice'
import { BeneficiariesList } from '../Components/BeneficiariesList'

export const BeneficiariesSetup_01_Beneficiaries = ({ route, navigation }) => {
  const { nextScreen } =  route?.params || {}
  
  const dispatch = useAppDispatch()

  const addBeneficiary = useAppSelector(addBeneficiaryData)
  const editBeneficiary = useAppSelector(editBeneficiaryData)
  const adjustBeneficiary = useAppSelector(adjustBeneficiaryProportionData)

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: beneficiaries, isLoading: beneficiariesIsLoading, error: beneficiariesError, isFetching: beneficiariesIsFetching, refetch: refetchBeneficiaries } = useGetBeneficiariesQuery()

  const nominations = beneficiaries?.nominations || []
  const hasBeneficiaries = nominations && isArray(nominations) && nominations.length > 0
  const hasMultipleBeneficiaries = nominations && isArray(nominations) && nominations.length > 1

  const onSubmit = () => {
    navigation.navigate(nextScreen)
  }

  const handleAdjustRoute = () => {
    dispatch(setAdjustBeneficiaryProportionData({
      nominations,
    }))
  }
    
  return (
    <>
      {
        addBeneficiary ?
        <AddBeneficiaryProcessStack />
        : editBeneficiary ?
        <EditBeneficiaryEditModal />
        : adjustBeneficiary ?
        <AdjustProportionModal />
        :
        <ProcessScreen
          isLoading={beneficiariesIsLoading}
          error={beneficiariesError}
          errorTryAgain={refetchBeneficiaries}
          buttonTitle={'Finish'}
          buttonAction={onSubmit}
          enableButton={!beneficiariesIsLoading && !beneficiariesIsFetching && !!hasBeneficiaries}
          showButton={true}
          allowTextButton={!beneficiariesIsLoading && !beneficiariesIsFetching && hasMultipleBeneficiaries}
          textButtonTitle={hasMultipleBeneficiaries ? 'Adjust proportions' : ``}
          textButtonAction={hasMultipleBeneficiaries ? handleAdjustRoute : undefined}
          headline={`Choose your beneficiaries`}
          subHeading={`You can choose up to 4 people or charities`}
        >
          <BeneficiariesList />
        </ProcessScreen>
      }
    </>
  )
}