import { ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { concat, orderBy } from 'lodash'
import { ClientMeDto, ClientSpouseDto } from "store/dto/client.dto"
import { PensionTransferStatus } from 'store/dto/account.dto'
import { RetirementAssetDto, RetirementAssetTracingStatus, RetirementAssetTransferAttempt, RetirementAssetTransferStatus, RetirementAssetType, RetirementAssetUntransferableReason } from "store/dto/retirement-asset.dto"

export enum RetirementAssetCategory {
  PENSIONS,
  SAVINGS,
  OTHER,
}

export const clientAddressableUntransferableReasons: RetirementAssetUntransferableReason[] = [
  RetirementAssetUntransferableReason.CLIENT_DECLARED,
  RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH,
  RetirementAssetUntransferableReason.MISSING_DATA,
  RetirementAssetUntransferableReason.MISSING_EXECUTION_DATA,
  RetirementAssetUntransferableReason.PUBLIC_SECTOR,
  RetirementAssetUntransferableReason.WORKPLACE_CONTRIBUTIONS,
]

export const untransferableReasonsNeedingEdit: RetirementAssetUntransferableReason[] = [
  RetirementAssetUntransferableReason.CLIENT_DECLARED,
  RetirementAssetUntransferableReason.PUBLIC_SECTOR,
  RetirementAssetUntransferableReason.WORKPLACE_CONTRIBUTIONS,
]

export const getAssetTypeOptions = (pensionsOnly?: boolean): ManagedSimpleChoiceItem[] => {
  let output: ManagedSimpleChoiceItem[] = [
    {
      value: RetirementAssetType.PERSONAL,
      label: 'A personal pension',
    },
    { 
      value: RetirementAssetType.WORKPLACE,
      label: 'A workplace pension'
    },
  ]
  if (!pensionsOnly) {
    const extra: ManagedSimpleChoiceItem[] = [
      {
        value: RetirementAssetType.ISA,
        label: 'An ISA',
      },
      { 
        value: RetirementAssetType.INVESTMENT,
        label: 'Another investment'
      },
      { 
        value: RetirementAssetType.BUSINESS_SALE,
        label: 'Sale of a business'
      },
      { 
        value: RetirementAssetType.OTHER,
        label: 'Something else'
      },
    ]
    output = concat(output, extra)
  }
  return output
}

export const isLockedForTransfer = (asset: RetirementAssetDto): boolean => {
  return asset?.transferStatus
    && asset?.transferStatus !== RetirementAssetTransferStatus.ABANDONED
    && asset?.transferStatus !== RetirementAssetTransferStatus.TRACING_FAILED
}

export const getAssetCategory = (assetType: RetirementAssetType): RetirementAssetCategory => {
  if ([RetirementAssetType.PERSONAL, RetirementAssetType.WORKPLACE].includes(assetType)) {
    return RetirementAssetCategory.PENSIONS
  } else if ([RetirementAssetType.ISA, RetirementAssetType.INVESTMENT].includes(assetType)) {
    return RetirementAssetCategory.SAVINGS
  }
  return RetirementAssetCategory.OTHER
}

export const getAssetIconName = (assetType: RetirementAssetType, assetCategory?: RetirementAssetCategory): string => {
  switch (assetType) {
    case RetirementAssetType.WORKPLACE:
      return 'office-building'
    case RetirementAssetType.PERSONAL:
      return 'account'
    case RetirementAssetType.ISA:
      return 'piggy-bank-outline'
    case RetirementAssetType.INVESTMENT:
      return 'piggy-bank'
    case RetirementAssetType.BUSINESS_SALE:
      return 'cash-register'
  }
  return 'dots-horizontal'
}

export const getAssetTransferStatusIconName = (asset: RetirementAssetDto, currentTransferAttempt: RetirementAssetTransferAttempt): string => {
  switch (asset?.transferStatus) {
    case RetirementAssetTransferStatus.TRACING:
      if (isUnacknowledgedCompletedTrace(asset)) {
        if (asset.tracingStatus === RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE) {
          return 'stop-circle-outline'
        } else if (asset.tracingStatus === RetirementAssetTracingStatus.FOUND_DELAY) {
          return 'timer-outline'
        } else if (asset.tracingStatus === RetirementAssetTracingStatus.FOUND_NEEDS_DECISION) {
          return 'progress-question'
        } else if (asset.tracingStatus === RetirementAssetTracingStatus.INVALID) {
          return 'alert-circle-outline'
        } else if (asset.tracingStatus === RetirementAssetTracingStatus.NOT_FOUND) {
          return 'archive-off-outline'
        } else {
          return 'dots-horizontal'
        }
      }
      return 'archive-search-outline'
    case RetirementAssetTransferStatus.INITIATED:
      return 'timer-sand'
    case RetirementAssetTransferStatus.ACTIVE:
      if (currentTransferAttempt?.transferStatus === PensionTransferStatus.ONBOARDING_ASSETS) {
        return 'bank-transfer-in'
      } else {
        return 'timer-sand'
      }
    case RetirementAssetTransferStatus.COMPLETED:
      if (asset.completedTransferAcknowledged) {
        return 'check-circle-outline'
      }
      else if (asset.monthlyContributionAmount) {
        return 'progress-question'
      } else {
        return 'alert-circle-outline'
      }
    case RetirementAssetTransferStatus.REJECTED:
      return 'alert-circle-outline'
    case RetirementAssetTransferStatus.MODIFIED:
      return 'alert-circle-outline'
    case RetirementAssetTransferStatus.ABANDONED:
      return 'cancel'
    case RetirementAssetTransferStatus.WITH_SUPPORT:
      return 'face-agent'
    default:
      return 'dots-horizontal'
  }
}

export const suggestName = (assetType: RetirementAssetType, brandName: string, employerName: string) => {
  switch (assetType) {
    case RetirementAssetType.PERSONAL:
      if (brandName) {
        return `${brandName} Pension`
      }
      return 'Personal Pension'
    case RetirementAssetType.WORKPLACE:
      if (employerName) {
        return `${employerName} Pension`
      }
      if (brandName) {
        return `${brandName} Pension`
      }
      return 'Workplace Pension'
    case RetirementAssetType.ISA:
      return 'ISA'
    case RetirementAssetType.INVESTMENT:
      return 'Investment'
    case RetirementAssetType.BUSINESS_SALE:
      return 'Sale of Business'
    case RetirementAssetType.OTHER:
      return 'Other Asset'
    default:
      return ''
  }
}

export const filterEditableAssets = (assets: RetirementAssetDto[], client: ClientMeDto, spouse: ClientSpouseDto) => {
  const spouseIsUser = !!spouse?.userId
  return assets && client ? assets.filter(asset => {
    //Don't include if spouse is real and asset doesn't belong to client
    if (spouseIsUser && asset?.clientId !== client?.id) {
      return false
    }

    //Don't include if has a transferStatus that isn't ABANDONED or TRACING_FAILED
    if (asset?.transferStatus
      && asset?.transferStatus !== RetirementAssetTransferStatus.ABANDONED
      && asset?.transferStatus !== RetirementAssetTransferStatus.TRACING_FAILED
      ) {
      return false
    }

    return true
  }) : []
}

export const filterAssetsInScopeForTransfer = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets && client ? assets.filter(asset => {
    //Don't include if not owned by client
    if (asset?.clientId !== client?.id) {
      return false
    }

    //Don't include if has a transferStatus that isn't ABANDONED or TRACING_FAILED
    if (asset?.transferStatus
      && asset?.transferStatus !== RetirementAssetTransferStatus.ABANDONED
      && asset?.transferStatus !== RetirementAssetTransferStatus.TRACING_FAILED
      ) {
      return false
    }
    //Include if no untransferableReason
    if (!asset?.untransferableReason) {
      return true
    }

    return clientAddressableUntransferableReasons.includes(asset?.untransferableReason)
  }) : []
}

export const filterRejectedOrModifiedPensionAssets = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isRejectedOrModifiedTransfer(asset)
  }) : []
}

export const filterRetransferablePensionAssets = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isRejectedOrAbandonedTransfer(asset)
  }) : []
}

export const filterRetransferModifiedAssets = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isModifiedForRetransfer(asset)
  }) : []
}

export const filterActiveOrRejectedTransferAssets = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isActiveOrRejectedTransfer(asset)
  }) : []
}

export const filterUnacknowledgedCompletedTransfers = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isUnacknowledgedCompletedTransfer(asset)
  }) : []
}

export const filterUnacknowledgedCompletedTraces = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isUnacknowledgedCompletedTrace(asset)
  }) : []
}

export const filterTransferableCompletedTraces = (assets: RetirementAssetDto[], client: ClientMeDto) => {
  return assets ? assets.filter(asset => {
    return asset?.clientId === client?.id && isTransferableCompletedTrace(asset)
  }) : []
}

export const isOfTransferableType = (asset: RetirementAssetDto) => {
  const transferableTypes = [RetirementAssetType.PERSONAL, RetirementAssetType.WORKPLACE]
  return transferableTypes.includes(asset?.assetType)
}

export const isActiveOrRejectedTransfer = (asset: RetirementAssetDto) => {
  const activeStatuses = [
    RetirementAssetTransferStatus.TRACING,
    RetirementAssetTransferStatus.INITIATED,
    RetirementAssetTransferStatus.ACTIVE,
    RetirementAssetTransferStatus.WITH_SUPPORT,
    RetirementAssetTransferStatus.REJECTED, //Included because user has to abandon to treat as a new transfer
  ]
  return activeStatuses.includes(asset?.transferStatus)
}

export const isRejectedOrModifiedTransfer = (asset: RetirementAssetDto) => {
  const rejectedStatuses = [
    RetirementAssetTransferStatus.REJECTED,
    RetirementAssetTransferStatus.MODIFIED,
  ]
  return rejectedStatuses.includes(asset?.transferStatus)
}

export const isUnacknowledgedCompletedTransfer = (asset: RetirementAssetDto) => {
  return asset?.transferStatus === RetirementAssetTransferStatus.COMPLETED &&
    asset?.completedTransferAcknowledged === null
}

export const isUnacknowledgedCompletedTrace = (asset: RetirementAssetDto) => {
  const unacknowledgedStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_DELAY,
    RetirementAssetTracingStatus.FOUND_NEEDS_DECISION,
    RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE,
    RetirementAssetTracingStatus.INVALID,
    RetirementAssetTracingStatus.NOT_FOUND,
  ]
  return unacknowledgedStatuses.includes(asset?.tracingStatus)
}

export const isFoundTrace = (asset: RetirementAssetDto) => {
  const foundStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_DELAY,
    RetirementAssetTracingStatus.FOUND_NEEDS_DECISION,
    RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE,
  ]
  return foundStatuses.includes(asset?.tracingStatus)
}

export const isTransferableCompletedTrace = (asset: RetirementAssetDto) => {
  const transferableStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_DELAY,
    RetirementAssetTracingStatus.FOUND_NEEDS_DECISION,
  ]
  return transferableStatuses.includes(asset?.tracingStatus)
}

export const isRejectedOrAbandonedTransfer = (asset: RetirementAssetDto) => {
  const rejectedStatuses = [
    RetirementAssetTransferStatus.REJECTED,
    RetirementAssetTransferStatus.ABANDONED,
    RetirementAssetTransferStatus.MODIFIED,
  ]
  return rejectedStatuses.includes(asset?.transferStatus)
}

export const isModifiedForRetransfer = (asset: RetirementAssetDto) => {
  return asset?.transferStatus === RetirementAssetTransferStatus.MODIFIED
}

export const isModifiedAndReadyForRetransfer = (asset: RetirementAssetDto) => {
  return isModifiedForRetransfer(asset) && (!asset?.untransferableReason || asset?.untransferableReason === RetirementAssetUntransferableReason.TRANSFER_ACTIVE)
}

export const isReadyForTransferExecution = (asset: RetirementAssetDto) => {
  return !!asset?.pensionProviderId && !!asset?.externalProviderReference && !!asset?.transferAddress
}

export const sortAssets = (assets : RetirementAssetDto[]): RetirementAssetDto[] => {
  const typeOrder = Object.values(RetirementAssetType)
  return orderBy(assets, [function(a) { return typeOrder.indexOf(a.assetType) }, 'currentValue'], ['asc', 'desc'])
}