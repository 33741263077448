import { Text } from 'components/Typography/Text'
import { removeImpersonatedUser } from 'lib/authHelpers'
import React, { useState } from 'react'
import { Pressable, StyleSheet } from "react-native"
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { Colors, Sizing } from "styles"
import { ConfirmationDialog } from './ConfirmationDialog'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export const ImpersonationBanner = () => {

  const authUser = useSelector((state: any) => state.auth.user)
  const impersonation = authUser?.impersonation
  
  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const dispatch = useAppDispatch()

  const endImpersonation = async () => {
    removeImpersonatedUser(authUser, dispatch)
  }

  return (
    <Pressable
      onPress={showDialog}
      style={{
        // paddingTop: insets.top,
        backgroundColor: Colors.brand.blue2,
        marginHorizontal: Sizing.x30,
        marginBottom: Sizing.x10,
        borderRadius: Sizing.x10,
        paddingHorizontal: Sizing.x10,
        height: Sizing.x30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ color: Colors.neutral.white }}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
      >
          {`Impersonating: ${impersonation?.userEmail}`}
      </Text>
      <MaterialCommunityIcons name={'close'} size={Sizing.x15} color={Colors.neutral.white} />
      <ConfirmationDialog
        visible={dialogVisible}
        title={'End Impersonation?'}
        content={`End impersonation session and switch back into the admin console?`}
        onCancel={hideDialog}
        onConfirm={endImpersonation}
      />
    </Pressable >
  )
}

const localStyles = StyleSheet.create({
  footer: {    
    marginBottom: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: Sizing.x5,
    paddingBottom: Sizing.x5,
  },
  infoContainer: {
    paddingHorizontal: Sizing.x30,
  },
})