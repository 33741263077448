import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput, isSpecialValue } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { orderBy } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery } from 'store/apiSlice'
import { PublicGroupSchemeDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingPreviousPension, workingPreviousPension } from 'store/tempDataSlice'
import { Colors, Flex, Sizing } from 'styles'

export const SchemeEnrolmentPreviousPensionAdd_01_ProviderBrand = ({ route, navigation }) => {
  const { nextScreen, groupScheme }: { nextScreen: string, groupScheme: PublicGroupSchemeDto }  = route?.params || {}

  const workingPreviousPensionData = useAppSelector(workingPreviousPension)
 
  const { data: brands, error, isLoading, refetch } = useGetPensionBrandsQuery()
 
  const {
    previousPensionProviderBrandKey,
    previousPensionProviderBrandName,
    organizationName
  } = groupScheme || {}

  const alreadyHadBrandName = !!previousPensionProviderBrandName
  const alreadyHadBrandKey = !!previousPensionProviderBrandKey

  //Temp state
  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ pensionProviderBrandKey: string, pensionProviderBrandName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderBrandKey: workingPreviousPensionData?.pensionProviderBrandKey || previousPensionProviderBrandKey,
      pensionProviderBrandName: workingPreviousPensionData?.pensionProviderBrandName || previousPensionProviderBrandName,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingPreviousPension({
      ...attributes,
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
    }))
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const pensionProviderBrandKey = watch('pensionProviderBrandKey')
  const pensionProviderBrandName = watch('pensionProviderBrandName')
  const hasValue = !!(pensionProviderBrandName && pensionProviderBrandName !== '')

  const selectedProviderBrand = brands ? brands.find(brand => {
    return brand.key === pensionProviderBrandKey
  }) : undefined

  const sameBrand = alreadyHadBrandKey &&previousPensionProviderBrandKey !== pensionProviderBrandKey

  //Build autocomplete options
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={refetch}
      isLoading={isLoading}
      loadingMessage={['Fetching pension brands...']}
      buttonTitle={alreadyHadBrandName
        ? sameBrand
          ? 'Change'
          : 'Confirm'
        : pensionProviderBrandName
          ? `Confirm`
          : `Make a Selection`
      }
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasValue}
      headline={alreadyHadBrandName
        ? `Let's check your previous pension provider`
        : `Who did your employer use for your pension?`
      }
      subHeading={alreadyHadBrandName
        ? `Your employer said they previously used ${previousPensionProviderBrandName}`
        : `You can ask your employer or check for any pension correspondence`
      }
      allowTextButton={!pensionProviderBrandKey}
      textButtonAction={next}
      textButtonTitle={`Skip for now`}
    >
      <ManagedAutoCompleteInput
        name={'pensionProviderBrandKey'}
        allowFreeText={true}
        placeholder={'Tap to search or enter...'}
        textCaptureFieldName={'pensionProviderBrandName'}
        unknownItemUseDescription={'Use this name'}
        unknownItemSelectedDescription={'Name specified by you'}
        formObj={formObj}
        modalTitle={'Find/Enter Pension Brand'}
        required={false}
        dataSet={brandOptions}
      />
      {
        selectedProviderBrand?.logo
          ? <View style={{
              paddingTop: Sizing.x20,
            }}>
              <Image source={{ uri: selectedProviderBrand?.logo} } style={{
                width: Sizing.x150,
                height: Sizing.x150,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            </View>
          : <></>
      }
    </ModalProcessScreen>
  )
}
