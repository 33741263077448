import { MaterialIcons } from '@expo/vector-icons'
import MultiSlider from '@ptomasroos/react-native-multi-slider'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { floor, min, range, round } from 'lodash'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ClientClientSegmentDto } from 'store/dto/client.dto'
import { Colors, Flex, Sizing } from 'styles'
import { UnborderedTable } from './UnborderedTable'
import { ContentDivider } from 'components/Layout/ContentDivider'

type ChargeCalculation = {
  platformRate: number
  fundRate: number
  totalRate: number
  platformAmount: number
  fundAmount: number
  totalAmount: number
}

const MAX_SLIDER_VALUE = 500000
const SLIDER_VALUE_STEP = 5000
const DEFAULT_SLIDER_VALUE = 50000

type ChargeCalculatorSliderProps = {
  accountDescriptor: string
  segment: ClientClientSegmentDto
  annualFeePercentage: number
}

export const ChargeCalculatorSlider = (props: ChargeCalculatorSliderProps) => {
  const { accountDescriptor, segment, annualFeePercentage } = props

  const platformChargeTiers = segment?.platformCharge?.tiers || []
  const discount = segment?.platformChargeDiscount || 1

  const calculateApplicableCharge = (value): ChargeCalculation => {
    if (value === 0) {
      return {
        fundRate: 0,
        platformRate: 0,
        totalRate: 0,
        fundAmount: 0,
        platformAmount: 0,
        totalAmount: 0,
      }
    }
    let totalAmount = 0
    platformChargeTiers.forEach(tier => {
      const { fromAmount, toAmount, rate } = tier
      const discountedRate = floor(rate * discount, 2)
      if (fromAmount < value) {
        const chargeable = (toAmount ? min([toAmount, value]) : value) - fromAmount
        const charge = chargeable * ((discountedRate + annualFeePercentage) / 100)
        totalAmount += charge
      }
    })
    const totalRate = round((totalAmount / value) * 100, 2)
    const platformRate = totalRate - annualFeePercentage
    const fundAmount = value * (annualFeePercentage / 100)
    const platformAmount = totalAmount - fundAmount
    return {
      fundRate: annualFeePercentage,
      platformRate,
      totalRate,
      fundAmount,
      platformAmount,
      totalAmount,
    }
  }

  const [sliderValue, setSliderValue] = useState(DEFAULT_SLIDER_VALUE)
  const [chargeCalculations, setChargeCalculations] = useState<ChargeCalculation>(calculateApplicableCharge(DEFAULT_SLIDER_VALUE))

  const onValuesChange = position => {
    setSliderValue(position[0])
    const calcs = calculateApplicableCharge(position[0])
    setChargeCalculations(calcs)
  }

  function SliderMarker() {
    return (
      <View style={localStyles.sliderMarkerOuter}>
        <View style={localStyles.sliderMarkerInner}>
          <MaterialIcons
            name="keyboard-arrow-left"
            size={Sizing.x25}
            color={Colors.neutral.white}
            style={localStyles.sliderMarkerIcon}
          />
          <MaterialIcons
            name="keyboard-arrow-right"
            size={Sizing.x25}
            color={Colors.neutral.white}
            style={localStyles.sliderMarkerIcon}
          />
        </View>
      </View>
    )
  }

  return (
    <>
      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Charges Calculator'}</Subheading>
      <Paragraph style={localStyles.text}>{`Use the slider below to see the total applicable annual charge for different fund values.`}</Paragraph>
      <Paragraph>{`Value of your ${accountDescriptor}:`}</Paragraph>
      <Headline style={{fontWeight: '900'}}>{formatCurrencyAmount(sliderValue,0)}</Headline>
      <MultiSlider
        values={[sliderValue]}
        onValuesChange={onValuesChange}
        optionsArray={range(0, MAX_SLIDER_VALUE, SLIDER_VALUE_STEP)}
        sliderLength={getScreenAppWidth() * 3 / 4}
        customMarker={SliderMarker}
        unselectedStyle={localStyles.sliderTrack}
        selectedStyle={localStyles.sliderTrack}
        containerStyle={localStyles.sliderContainer}
      />
      <Paragraph>{'Total Applicable Annual Charges:'}</Paragraph>
      <Headline style={{fontWeight: '900'}}>{formatPercentageAmount(chargeCalculations?.totalRate || 0, 2)}</Headline>
      <Paragraph style={{ textAlign: 'left' }}>{'Consisting of:'}</Paragraph>
      <UnborderedTable
        data={[
          {
            label: `Platform charges (${formatPercentageAmount(chargeCalculations?.platformRate || 0, 2)})`,
            value: <Text>{formatCurrencyAmount(chargeCalculations?.platformAmount || 0, 2)}</Text>
          },
          {
            label: `Fund charges (${formatPercentageAmount(chargeCalculations?.fundRate || 0, 2)})`,
            value: <Text>{formatCurrencyAmount(chargeCalculations?.fundAmount || 0, 2)}</Text>
          },
          {
            label: `Total`,
            value: formatCurrencyAmount(chargeCalculations?.totalAmount || 0, 2),
            isTotal: true
          }
        ]}
      />
      <Paragraph style={localStyles.smallText}>{`* These charges are applicable to the proportion of your pension invested in this fund. When building your own portfolio from multiple funds, different fund charges may apply to different proportions of your investment. However, platform charges will be calculated on the overall total.`}</Paragraph>
    </>
  )
}

const localStyles = StyleSheet.create({
  sliderOuterContainer: {
    width: '100%',
  },
  sliderContainer: {
    width: '100%',
    height: Sizing.x70,
    alignItems: 'center',
  },
  sliderTrack: {
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(8),
    borderRadius: scaleNormalizer(4)
  },
  sliderMarkerOuter: {
    marginTop: scaleNormalizer(7),
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(40),
    width: scaleNormalizer(40),
    borderRadius: scaleNormalizer(20)
  },
  sliderMarkerInner: {
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.purple2,
    height: scaleNormalizer(30),
    width: scaleNormalizer(30),
    borderRadius: Sizing.x15,
  },
  sliderMarkerIcon: {
    padding: scaleNormalizer(-8),
    margin: scaleNormalizer(-8)
  },
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})