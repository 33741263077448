import React from 'react'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { suggestName } from 'lib/retirementIncomeHelpers'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingIncome, workingIncome } from 'store/tempDataSlice'

export const RetirementIncomesAddIncome_05_Name = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingIncomeData = useAppSelector(workingIncome)

  //Setup form
  const formObj = useForm<{ name: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: workingIncomeData?.name || suggestName(workingIncomeData?.incomeType)
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingIncome(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Save Income'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Give it a memorable name`}
    >
      <ManagedTextInput
        name={'name'}
        formObj={formObj}
                returnKeyType="next"

        // label={'Name'}
        placeholder={'Enter a name for this income'}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ModalProcessScreen>
  )
}
