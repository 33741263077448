import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetStatsClientQuery, useGetStatsTransferQuery, useGetStatsUserQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { adminAppNavigate } from 'lib/RootNavigation'
import { AdminAppNavScreen } from 'lib/navigationHelpers'
import { Sizing, Paper, Flex } from 'styles'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'

const CLIENT_STAT_GROUP_BY = 'amlStatus'
const USER_BLOCK_STAT_GROUP_BY = 'blockStatus'
const USER_AFFILIATE_STAT_GROUP_BY = 'affiliate'
const TRANSFER_STAT_GROUP_BY = 'transferStatus'

const isWeb = platformIsWeb()

export const AdminDashboardScreen = () => {
  const { data: clientStats, isLoading: clientStatsIsLoading, error: clientStatsError, isFetching: clientStatsIsFetching, refetch: refetchClientStats } = useGetStatsClientQuery({ groupBy: CLIENT_STAT_GROUP_BY })
  const { data: transferStats, isLoading: transferStatsIsLoading, error: transferStatsError, isFetching: transferStatsIsFetching, refetch: refetchTransferStats } = useGetStatsTransferQuery({ groupBy: TRANSFER_STAT_GROUP_BY })
  const { data: userBlockStats, isLoading: userBlockStatsIsLoading, error: userBlockStatsError, isFetching: userBlockStatsIsFetching, refetch: refetchUserBlockStats } = useGetStatsUserQuery({ groupBy: USER_BLOCK_STAT_GROUP_BY })
  const { data: userAffiliateStats, isLoading: userAffiliateStatsIsLoading, error: userAffiliateStatsError, isFetching: userAffiliateStatsIsFetching, refetch: refetchUserAffiliateStats } = useGetStatsUserQuery({ groupBy: USER_AFFILIATE_STAT_GROUP_BY })
  const [refreshing, setRefreshing] = useState(false)

  const isLoading = clientStatsIsLoading || transferStatsIsLoading || userBlockStatsIsLoading || userAffiliateStatsIsLoading
  const error = clientStatsError || transferStatsError || userBlockStatsError || userAffiliateStatsError

  const { colors: themeColors } = Paper.useAppTheme()

  const onRefresh = async () => {
    setRefreshing(true)
    await refetchClientStats()
    await refetchTransferStats()
    await refetchUserBlockStats()
    await refetchUserAffiliateStats()
    setRefreshing(false)
  }

  const statsList = () => {
    if (clientStats && userBlockStats) {
      return [
        { title: 'Client AML Status', data: [{ tableData: clientStats, linkTitle: 'Go to Clients', linkScreen: AdminAppNavScreen.CLIENTS }] },
        { title: 'Transfer Status', data: [{ tableData: transferStats, linkTitle: 'Go to Transfers', linkScreen: AdminAppNavScreen.TRANSFERS }] },
        { title: 'User Affiliate Atttribution', data: [{ tableData: userAffiliateStats, linkTitle: 'Go to Affiliates', linkScreen: AdminAppNavScreen.AFFILIATES }] },
        { title: 'User Block Status', data: [{ tableData: userBlockStats, linkTitle: 'Go to Users', linkScreen: AdminAppNavScreen.USERS }] },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    let total = 0
    const data: UnborderedTableRow[] = []
    if (item?.tableData) {
      item.tableData.forEach(row => {
        total += row.count
        data.push({
          label: row.label,
          value: row.count,
          linkFunction: item?.linkScreen ? () => adminAppNavigate(item?.linkScreen) : undefined,
        })
      })
      data.push({
        label: 'Total',
        value: total,
        isTotal: true,
        linkFunction: item?.linkScreen ? () => adminAppNavigate(item?.linkScreen) : undefined,
      })
    }
    return (
      <View style={{ paddingBottom: Sizing.x30 }}>
        <UnborderedTable
          data={data}
        />
        { item?.linkScreen ?
          <Button
            mode='text'
            onPress={() => adminAppNavigate(item?.linkScreen)}
            color={themeColors.accent}
          >
            {item?.linkTitle || 'Go'}
          </Button>
          : <></>
        }
      </View>
    )
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:stat']]}
      >
        {
          isLoading ? <Loading message={['Preparing admin dashboard...']} /> : error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data} /> :
            <View style={ Flex.column.start } >
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
              >
                <OptionalSectionList
                  sections={statsList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={``}
                />
              </ScrollView>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
