import { ampli } from "src/ampli"
import { ClientMeDto } from "store/dto/client.dto"
import { UserDto } from "store/dto/user.dto"
import { Logger } from "lib/logger"
import { pick, merge } from 'lodash'
import { calculateAgeAtDate } from "./dateHelpers"
import { formatISO } from "date-fns"
import { RetirementAssetDto } from "store/dto/retirement-asset.dto"
import { NotificationMethod } from "store/dto/notification.dto"

export const identifyAmplitudeUser = (user: UserDto, client?: ClientMeDto) => {
  // Logger.debug(`Amplitude user identification...`)
  const { email, providerId, affiliateId, affiliateRef, createdAt, preferredNotificationMethods } = user || {}
  const userProps = user ? {
    email,
    authProvider: providerId.split('|', 1)[0],
    affiliateId,
    affiliateRef,
    createdAt,
    preferEmail: !preferredNotificationMethods || preferredNotificationMethods.includes(NotificationMethod.EMAIL),
    preferPush: !preferredNotificationMethods || preferredNotificationMethods.includes(NotificationMethod.PUSH),
  } : {}
  const clientProps = client ? {
    clientId: client?.id,
    ...pick(client || {}, [
      'gender',
      'birthDate',
      'maritalStatus',
      'employmentStatus',
      'segmentId',
    ]),
    segmentName: client?.segment?.name,
    age: client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : undefined
  } : {}

  const properties = merge(userProps, clientProps)
  Logger.debug({
    userId: user?.id,
    properties,
  }, `Identifying user`)
  ampli.identify(user?.id, properties)
}

export const getAmplitudeRetirementAssetAttributes = (asset: RetirementAssetDto) => {
  return pick(asset, [
    'assetType',
    'isTransferable',
    'pensionProviderId',
    'currentValue',
    'monthlyContributionAmount',
    'contributionSource',
    'employerId',
    'employerName',
    'employerIsPublicSector',
    'pensionProviderBrandKey',
    'pensionProviderBrandName',
    'employerStartDate',
    'employerEndDate',
    'employerShortDuration',
    'isActiveEmployer',
  ])
}