import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { useGetPensionBrandsQuery, useLazyGetPensionProvidersQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminPensionProviderDataId, setShowAdminAddPensionProviderVisible } from 'store/uxSlice'
import { Paper, Sizing, Flex, Colors } from 'styles'
import { AdminPensionProviderListItem } from '../Components/AdminPensionProviderListItem'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { orderBy } from 'lodash'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminPensionProvidersListScreen = () => {
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const [getPensionProvidersQuery, { data: providers, isLoading: providersIsLoading, error: providersError, isFetching: providersIsFetching }] = useLazyGetPensionProvidersQuery()
  const [refreshing, setRefreshing] = useState(false)

  //Build autocomplete options
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []
  
  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowPensionProviderDetails = (id: string) => {
    dispatch(setCurrentAdminPensionProviderDataId(id))
  }

  const providersList = () => {
    let all = []
    if (providers) {
      providers.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    const brandOption = brandOptions ? brandOptions.find(option => {
      return option.value === item.primaryBrandKey
    }) : undefined
    return (
      <AdminPensionProviderListItem
        provider={item}
        brandOption={brandOption}
        onPressFunction={() => handleShowPensionProviderDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{ search: string, origoId: string, primaryBrandKey: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      origoId: '',
      primaryBrandKey: '',
    },
  })

  const { handleSubmit, reset } = formObj

  const onSubmit = attributes => {
    getPensionProvidersQuery(attributes)
  }

  const isLoading = providersIsLoading || providersIsFetching || brandsIsLoading

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const handleAddPensionProvider = () => {
    dispatch(setShowAdminAddPensionProviderVisible(true))
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      origoId: '',
      primaryBrandKey: '',
    })
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:system']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding providers...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for provider'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of key, name, description'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedTextInput
                      name={'origoId'}
                      label={'Origo ID'}
                      autoFocus={false}
                      formObj={formObj}
                      placeholder={'Enter full Origo ID'}
                      blurOnSubmit={false}
                      rules={{
                        minLength: 1,
                        maxLength: 6,
                      }} />
                    <ManagedAutoCompleteInput
                      name={'primaryBrandKey'}
                      label={'Primary Pension Brand'}
                      formObj={formObj}
                      dataSet={brandOptions}
                    />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
              >
                <OptionalSectionList
                  noRefresh={true}
                  sections={providersList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a provider`}
                />
              </ScrollView>
              <FooterButton
                onPress={handleAddPensionProvider}
              >
                {'Add New Provider'}
              </FooterButton>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
