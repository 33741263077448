import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'
import { MAX_TARGET_RETIREMENT_AGE, MIN_TARGET_RETIREMENT_AGE } from 'lib/constants'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetStateBenefitQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'

export const RetirementProfileSetup_01_TargetRetirementAge = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const targetRetirementAgeRef = useRef()

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: stateBenefit, error: benefitsError, isLoading: benefitsIsLoading, refetch: refetchBenefits } = useGetStateBenefitQuery({
    birthDate: me?.birthDate,
    gender: me?.gender,
  }, { skip: !me })

  const error: any = meError || benefitsError
  const isLoading = meIsLoading || benefitsIsLoading

  //Setup form
  const formObj = useForm<{ targetRetirementAge: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      targetRetirementAge: workingRetirementProfileData?.targetRetirementAge || stateBenefit?.age,
    },
  })
  const { handleSubmit, trigger, setValue, formState: { isValid, isDirty } } = formObj

  // Set default values when stateBenefit is loaded
  useEffect(() => {
    if (stateBenefit) {
      setValue('targetRetirementAge', stateBenefit?.age, { shouldDirty: true })
      trigger('targetRetirementAge')
    }
  }, [stateBenefit])
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementProfile(attributes))
    navigation.navigate(nextScreen)
  }

  const minTargetRetirementAge = me ? deriveMinimumTargetRetirementAge(me) : MIN_TARGET_RETIREMENT_AGE

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={meError ? refetchMe : benefitsError ? refetchBenefits : refetchMe}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`At what age do you want to retire?`}
      subHeading={`Think about when you want to stop working and enjoy more freedom`}
    >
      <ManagedIntegerInput
        ref={targetRetirementAgeRef}
        name={'targetRetirementAge'}
        // autoFocus={true}
        formObj={formObj}
                returnKeyType="next"

        // label={'Target Retirement Age'}
        placeholder={'Enter age'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        required={true}
        minValue={minTargetRetirementAge}
        maxValue={MAX_TARGET_RETIREMENT_AGE}
        informationMessage={`Your state pension age is ${stateBenefit?.age}`}
      />
    </ProcessScreen>
  )
}
