import { Headline, Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { FooterInfo } from 'components/Utility/FooterInfo'
import { Loading } from 'components/Utility/Loading'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { KeyboardAvoidingView, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { ContentDivider } from './ContentDivider'
import { ScreenScrollFader } from './ScreenScrollFader'

export type ModalProcessScreenButtonProps = {
  buttonTitle: string
  buttonAction: any
  footerInfo?: React.ReactNode
  showButton?: boolean
  enableButton?: boolean
  allowTextButton?: boolean
  textButtonTitle?: string
  textButtonAction?: any
  enableTextButton?: boolean
 }

export type ModalProcessScreenProps = ModalProcessScreenButtonProps & {
  children?: any
  error?: any
  errorTryAgain?: any
  errorCancel?: any
  isLoading?: boolean
  loadingMessage?: string[]
  headline?: string
  subHeading?: string
  subHeadingInfo?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
}

const isWeb = platformIsWeb()

export const ModalProcessScreen = (props: ModalProcessScreenProps) => {

  const { children, error, isLoading, loadingMessage, headline, subHeading, subHeadingInfo, buttonTitle, buttonAction, showButton, enableButton, errorTryAgain, errorCancel, allowTextButton, textButtonAction, textButtonTitle, enableTextButton, containerStyle, footerInfo } = props

  //Calculate the keyboard offset
  const insets = useSafeAreaInsets()
  const headerHeight = Sizing.x90 //See layout.ts modalProcessHeaderContainer style
  const processScreenContainerPadding = Sizing.x0 //No top padding above KeyboardAvoidingView
  const keyboardVerticalOffset = headerHeight + processScreenContainerPadding + insets.top

  const { colors: themeColors } = Paper.useAppTheme()

  const buttonDisabled = enableButton === false
  const textButtonDisabled = enableTextButton === false

  return (<>
    <View style={[
      layoutStyles.mainContentContainer,
      containerStyle ? containerStyle : {},
    ]} >
      {
        //Error/loading
        error || isLoading ?
          <>
            <ContentDivider style={{ height: Sizing.x2 }} />
            <View style={{
              ...Flex.column.start,
              paddingHorizontal: Sizing.x30,
            }}>
              <View style={{ ...Flex.override.fill }}>
                {
                  error ? <ErrorScreen
                    errorTryAgain={errorTryAgain}
                    errorCancel={errorCancel}
                    error={error?.data}
                    noDashboardButton={true}
                    noLogoutButton={true}
                    />
                  : isLoading ? <Loading message={loadingMessage} useHolidayGraphics={true} /> : <></>
                }
              </View>
              <View style={{ height: Sizing.x90 }}></View>
            </View>
          </>
          :
          <View style={{
            ...Flex.column.start,
            // backgroundColor: 'yellow',
          }}>
            <ContentDivider style={{ height: Sizing.x2 }} />
            {/* <Paragraph>Helo</Paragraph> */}
            <View style={{
              ...Flex.override.fill,
              paddingHorizontal: Sizing.x5,
              // backgroundColor: 'green',
            }}>
              <KeyboardAvoidingView
                style={{...Flex.override.fill }}
                behavior={'padding'}
                keyboardVerticalOffset={keyboardVerticalOffset}
              >
                {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
                  <ScreenScrollFader backgroundColor={Colors.brand.grey4} height={Sizing.x20}/>
                  <ScrollView
                    nestedScrollEnabled
                    style={layoutStyles.scrollContainer}
                    contentContainerStyle={layoutStyles.scrollContainerContent}
                    keyboardShouldPersistTaps='handled'
                    showsVerticalScrollIndicator={true}
                  >
                    <View style={{ height: Sizing.x20 }}></View>
                    <View style={{
                      ...Flex.column.start,
                      ...Flex.override.fill,
                      paddingHorizontal: Sizing.x25,
                    }}>
                      {headline ?
                        <View style={localStyles.headlineContainer}>
                          {headline ? <Headline adjustsFontSizeToFit numberOfLines={2}>{headline}</Headline> : <></>}
                        </View>
                        : <></>
                      }
                      {subHeading || subHeadingInfo ?
                        <View style={localStyles.subHeadingContainer}>
                          {subHeading
                            ? <Subheading adjustsFontSizeToFit numberOfLines={3}>{subHeading}</Subheading>
                            : <></>
                          }
                          {subHeadingInfo}
                        </View>
                        : <></>
                      }
                      <View style={{
                        flex: 1,
                      }}>
                        {
                          children
                        }
                        <View style={{ height: Sizing.x40 }}></View>
                      </View>
                    </View>
                  </ScrollView>
                <ScreenScrollFader backgroundColor={Colors.brand.grey4} height={Sizing.x40} bottomEnd={true}/>
                {/* </TouchableWithoutFeedback> */}
              </KeyboardAvoidingView>
            </View>
            <ContentDivider style={{ height: Sizing.x2 }} />
            <View style={{ ...Flex.override.bottom }}>
            { footerInfo ?
                <FooterInfo>
                  {footerInfo}
                </FooterInfo>
                : <></>
              }
            </View>
            <View style={{ ...Flex.override.bottom }}>
              {allowTextButton ? <FooterButton
                mode='text'
                onPress={textButtonAction}
                disabled={textButtonDisabled}
              >{textButtonTitle}</FooterButton> : <></>}
              {showButton === undefined || showButton ?
                <FooterButton
                  onPress={buttonAction}
                  disabled={buttonDisabled}
                >{buttonTitle}</FooterButton>

                : <></>
              }
            </View>
          </View>
      }
    </View>
  </>
  )
}

const localStyles = StyleSheet.create({
  headlineContainer: {
    paddingBottom: Sizing.x20,
  },
  subHeadingContainer: {
    paddingBottom: Sizing.x10,
  }
})