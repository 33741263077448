//Ages
export const MAX_CLIENT_AGE = 100
export const MIN_CLIENT_AGE = 18
//Target retirement ages
export const MAX_TARGET_RETIREMENT_AGE = 99
export const MIN_TARGET_RETIREMENT_AGE = 55
//Masks
export const COMPANY_MASK = 'SS999999'
export const SORTCODE_MASK = '99-99-99'
export const ACCOUNT_NO_MASK = '99999999'
export const NATIONAL_INSURANCE_NO_MASK = 'AA 99 99 99 A'
export const PAYE_TAX_REF_MASK = '999/SSSSSSSSSS'
export const CORPORATION_TAX_REF_MASK = '9999999999'
//Budgets
export const NET_TOTAL_BUDGET_ID = 1
export const GROSS_TOTAL_BUDGET_ID = 8
//Tax
export const PERSONAL_TAX_RELIEF_FACTOR = 0.25
//Contributions
export const REGULAR_CONTRIBUTIONS_AMOUNT_INCREASE_FACTOR = 1.2
export const DEFAULT_REGULAR_CONTRIBUTIONS_AMOUNT = 500
export const DEFAULT_LUMP_SUM_CONTRIBUTIONS_AMOUNT = 10000
export const MIN_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT = 1000
export const MAX_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT = 40000
export const LUMP_SUM_REQUIRED_RATIO = 6
export const MIN_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT = 500
export const MIN_CUSTOM_REGULAR_CONTRIBUTIONS_AMOUNT = 50
//Next two should generally be in sync
export const ANNUAL_ALLOWANCE_AMOUNT = 60000
export const MAX_CUSTOM_LUMP_SUM_CONTRIBUTIONS_AMOUNT = 60000
export const MAX_CUSTOM_REGULAR_CONTRIBUTIONS_AMOUNT = 5000
//Models & Scenarios
export const MAX_PARALLEL_SCENARIOS = 10
export const MODEL_HORIZON_YEARS = 75
//Assets
export const MAX_ASSET_CURRENT_VALUE = 2000000
export const MAX_ASSET_REGULAR_CONTRIBUTIONS = 10000
//Incomes
export const MAX_INCOME_ANNUAL_AMOUNT = 100000
//Budgets
export const MAX_BUDGET_CUSTOM_CATEGORY_AMOUNT = 5000
//Transfers
export const FOUND_PENSION_HOLD_DAYS = 7
export const PENDING_TRANSFER_CONSIDERED_STALE_WEEKS = 3
export const FOUND_PENSION_DECISION_MIN_VALUE = 100000
//Investments
export const INVESTMENT_CHOICE_MAX_FUND_COUNT = 10

//Storage keys
export const LINK_PARAMS_KEY_NAME = 'jarvis-link-params'
export const JWT_KEY_NAME = 'jarvis-jwt'
export const LAST_USER_EMAIL_KEY_NAME = 'jarvis-last-user-email'
export const LAST_USER_CONTEXT_KEY_NAME = 'jarvis-last-user-context'
export const ANALYTICS_KEY_NAME = 'jarvis-analytics-data'
export const LAST_GROUP_SCHEME_KEY_NAME = 'jarvis-last-group-scheme'

//Layout
export const SCREEN_MIN_WIDTH_FOR_COLUMNS = 500
export const MIN_WIDTH_FOR_PERMANENT_MENU = 800

export const YAHOO_QUOTE_BASE_URL = `https://uk.finance.yahoo.com/quote/`

//Schemes
export const WORKPLACE_SCHEME_NAME = `Jarvis Workplace Scheme`

//Jars
export const JAR_NAME = 'jar'
export const JAR_NAME_ALL = 'Jarvis Pension'
export const JAR_NAME_PERSONAL = 'Personal Jar'
export const JAR_NAME_GROUP = 'Workplace Jar'

//PAPDIS upload
export const PAPDIS_UPLOAD_FILE_TYPES = ['csv', 'zip']

//Auto Enrolment
export const AUTO_ENROLMENT_MIN_EMPLOYER_CONTRIBUTION_PERCENTAGE = 3
export const AUTO_ENROLMENT_MIN_TOTAL_CONTRIBUTION_PERCENTAGE = 8
export const MAX_WORKPLACE_CONTRIBUTION_AMOUNT = 99999.99

//Debounce
export const DEFAULT_DEBOUNCE_MS = 300