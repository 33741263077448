import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { addBeneficiaryData } from 'store/uxSlice'
import { Keyboard } from 'react-native'

export const BeneficiariesAddBeneficiary_05_Charity = ({ route, navigation }) => {
  const addBeneficiaryDataData = useAppSelector(addBeneficiaryData)
  const dispatch = useAppDispatch()
  const { nextScreen } = route?.params || {}
  
  const formObj = useForm<{ firstName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: ''
    }
  })
  const { handleSubmit, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingBeneficiary({
      ...attributes,
      surname: attributes.firstName
    }))
    //If we already have nominations, go to next screen (split) else results
    navigation.navigate(addBeneficiaryDataData?.allNominations?.length ? nextScreen : 'Results')
  }

  const softOnSubmit = attributes => {
    dispatch(updateWorkingBeneficiary({
      ...attributes,
      surname: attributes.firstName
    }))
    //If we already have nominations, go to split else do nothng
    if (addBeneficiaryDataData?.allNominations?.length) {
      navigation.navigate(nextScreen)
    } else {
      //Final screen, don't navigate, wait for user to confirm with button
      Keyboard.dismiss()
    }
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What is the charity called?`}
      subHeading={`Please enter the the legal name of the charity`}
    >
      <ManagedTextInput
        placeholder={'The charity legal name'}
        name={'firstName'}
        autoFocus={true}
        blurOnSubmit={true}
        formObj={formObj}
        submitHandler={handleSubmit(softOnSubmit)}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20
        }}
      />
    </ModalProcessScreen>
  )
}
