import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/Layout'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppDispatch } from 'store/hooks'
import { Paper, Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EntryPointEmployerScreen = ({ route, navigation }) => {

  const dispatch = useAppDispatch()

  const [showDownloadModal, setShowDownloadModal] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  const { colors: themeColors } = Paper.useAppTheme()

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.EMPLOYER, user, dispatch, false)
  })
  const goToLogin = () => {
    dispatch(setLoginParams({
      authType: AUTHTYPE.LOGIN,
    }))
    rootNavigate('AuthenticationProcessStack')
  }

  return (
    <ProcessIntroScreen
      buttonTitle={
        user ? 'Return to Employer Portal'
        : 'Employer Login'
      }
      buttonAction={
        user ? () => rootNavigate('EmployerAppStack')
        : goToLogin
      }
      showButton={true}
      cancelButtonText={'Go to Jarvis Client App'}
      cancelButtonAction={() => changeAppContext(AppContext.CLIENT, user, dispatch, true)}
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      imageAction={() => setShowInfoModal(true)}
      headline={'Employer Portal'}
      subHeading={`Manage your Jarvis Workplace Pension schemes`}
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        <AppIllustration
          filename={'plan_retirement.png'}
          style={{
            width: Sizing.x300,
            height: Sizing.x300,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
      </View>
      <Portal>
        <AppInfoModal
          user={user}
          client={undefined}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
          isUnauthenticated={!user}
        />
      </Portal>
      {
        isWeb
          ? <Portal>
              <WebAppDownloadModal
                visible={showDownloadModal}
                onDismiss={() => setShowDownloadModal(false)}
              />
            </Portal>
          : <></>
      }
    </ProcessIntroScreen>
  )
}
