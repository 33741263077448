import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton, InformationModalLink } from 'components/Utility/InformationButton'
import { addYears, differenceInYears, isSameDay, subDays } from 'date-fns'
import { DashboardMeter } from 'features/Dashboard/Components/DashboardMeter'
import { calculateAgeNow } from 'lib/dateHelpers'
import React, { useEffect } from 'react'
import { useLazyGetCalculatorTimelineQuery } from 'store/apiSlice'
import { CalculatorTimelineRequestDto } from 'store/dto/calculator.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_23_CombinedLifeExpectancy = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const [ getCalculatorTimeline, { data: timeline, error: timelineError, isLoading: timelineIsLoading }] = useLazyGetCalculatorTimelineQuery()

  const {
    lifeExpectancy,
    lastSurvivorDeathDate,
    lastSurvivorDeathAge,
    ageAtLastSurvivorDeathDate,
    spouseAgeAtLastSurvivorDeathDate,
    retirementDate,
    statePensionDate,
    statePensionAge,
    spouseStatePensionDate,
    spouseStatePensionAge,
    birthDate,
    spouseBirthDate,
  } = timeline || {}

  const isLoading = timelineIsLoading || !timeline
  const error: any = timelineError

  //Get calculator timeline on load
  useEffect(() => {
    getTimeline()
  }, [])

  const getTimeline = () => {
    const request: CalculatorTimelineRequestDto = {
      client: workingCalculatorRequestData?.client,
      spouse: workingCalculatorRequestData?.spouse,
    }
    getCalculatorTimeline(request, false)
  }

  const next = () => {
    dispatch(updateWorkingCalculatorRequest({
      timeline,
    }))
    navigation.navigate(nextScreen)
  }

  const clientIsYoungerBy = differenceInYears(new Date(birthDate), new Date(spouseBirthDate))
  const sameAge = clientIsYoungerBy === 0
  const samePensionDate = statePensionDate === spouseStatePensionDate
  const clientIsYounger = clientIsYoungerBy > 0

  const clientAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(birthDate),
  )

  const spouseAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(spouseBirthDate),
  )

  const features: FeatureBoxRow[] = [
    { label: `Link accounts with a partner to plan together`},
    { label: `See how your combined forces can help you retire earlier`},
  ]

  const informationTexts: string[] = [
    `Jarvis derives life expectancy from UK mortality tables, taking the most likely (50% probability) outcome, which sets the future date until which you will need to support yourself.`,
    `Combined Life Expectancy means YOUR age at the point which you would still be expected to remain alive.`,
    `Due to way probabilities work, this age is generally higher than your individual life expectancy - you effectively have 2 "chances" between you to live longer.`,
    `If your partner is younger than you, the age YOU would be when they reach THEIR life expectancy could be significantly higher.`,
  ]

  if (!sameAge) {
    informationTexts.push(`When planning for your retirement with a partner, the safest approach is to ensure you can support your lifestyle for as long as one of you still survives.`)
    if (clientIsYounger) {
      informationTexts.push(`Your retirement planning needs to run until you reach age ${lastSurvivorDeathAge}, at which point your partner would be ${spouseAgeAtLastSurvivorDeathDate}.`)
    } else {
      informationTexts.push(`As your partner is younger that you, your retirement planning needs to run until they reach age ${lastSurvivorDeathAge}, and at this point you would be ${ageAtLastSurvivorDeathDate}.`)
    }
  }

  if (!samePensionDate) {
    informationTexts.push(`In terms of retirement, couples often want to retire at the same time, and so for simplicity, we'll assume that you'll retire when, if eligible, the first of you would receive the UK State Pension, which is is when you are aged ${clientAgeAtRetirementDate}, and your partner is aged ${spouseAgeAtRetirementDate}.`)
  }

  informationTexts.push(`Please note these figures are for an average person in the UK and may be affected by personal health conditions and family history.`)
  
  const informationLinks: InformationModalLink[] = samePensionDate ? undefined : [
    {
      title: `About the New State Pension`,
      url: `https://www.gov.uk/new-state-pension`,
    },
    {
      title: 'Check Your State Pension',
      url: 'https://www.gov.uk/check-state-pension',
    }
  ]

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Updating your timeline...']}
      error={error}
      errorTryAgain={getTimeline}
      buttonTitle={'Continue'}
      buttonAction={next}
      enableButton={true}
      headline={`Ok, that changes your timeline a little`}
      subHeadingNumberOfLines={3}
      subHeading={`Your combined life expectancy is ${lastSurvivorDeathAge}, so your timeline now runs until ${sameAge ? 'you reach this age' : `${clientIsYounger ? `you reach this age` : `your partner reaches this age`}`}`}
      subHeadingInfo={<InformationButton title={`Joint Timeline Explained`} texts={informationTexts} links={informationLinks} illustrationFilename={'combined_retirement_timeline.png'} buttonTitle={`How is this calculated?`} />}
      footerInfo={<FeatureBox data={features} />}
    >
      <DashboardMeter
        viableAge={clientAgeAtRetirementDate}
        currentAge={calculateAgeNow(birthDate)}
        targetAge={clientAgeAtRetirementDate}
        expectedDeathAge={ageAtLastSurvivorDeathDate}
        withLabels={true}
      />  
    </ProcessScreen>
  )
}