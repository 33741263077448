import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { Sizing } from 'styles'
import { ActionCard } from './ActionCard'
import { ShareModal } from './ShareModal'
import { UserDto } from 'store/dto/user.dto'

type ShareCardProps = {
  user: UserDto
  campaign?: string
}

export const ShareCard = (props: ShareCardProps) => {
  const { campaign, user } = props

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  return (
    <>
      <View style={localStyles.cardContainer}>
        <ActionCard
          illustrationFilename={'megaphone.png'}
          title={`Spread the word`}
          description={`Tell your colleagues, friends and family about Jarvis`}
          size={'small'}
          onPress={showModal}
          buttonLabel={''}
        />
      </View>
      <Portal>
        <ShareModal
          user={user}
          campaign={campaign}
          onDismiss={hideModal}
          visible={modalVisible}
        />
      </Portal>
    </>
  )
}

const localStyles = StyleSheet.create({
  cardContainer: {
    paddingTop: Sizing.x10,
    paddingHorizontal: Sizing.x30,
  },
})