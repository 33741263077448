import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import React from 'react'

export const BulkRetransferHeroScreen = ({ route, navigation }) => {
  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'support.png',
      headline: `Ok, back to us`,
      subHeading: `Our team will make another attempt to track down these pensions based on the new information`,
    },
    {
      illustrationFilename: 'simplify_your_portfortlio.png',
      headline: `Next steps`,
      subHeading: `When we've confirmed the pension with the old provicer, we'll start the process of transferring it over to your ${JAR_NAME_PERSONAL}`,
    },
    {
      illustrationFilename: 'clock.png',
      headline: `Bear with us`,
      subHeading: `It can take 2-6 weeks to transfer pensions from old providers`,
    },
  ]

  return (
    <ProcessHeroScreen
      carouselScreens={carouselScreens}
      onFinish={goToMainAppStack}
      finishButtonLabel={'Finished'}
    />
  )
}
