import { Headline, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import React, { useRef, useState } from 'react'
import { Image, StatusBar, StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import { AppIllustration } from 'components/Utility/AppIllustration'

export type ProcessHeroProps = {
  isLoading?: boolean
  loadingMessage?: string[]
  error?: any
  errorTryAgain?: any
  errorCancel?: any
  carouselScreens: ProcessHeroCarouselData[]
  onFinish?: any
  finishButtonLabel?: string
  finishButtonDisabled?: boolean
  finishButtonExplanation?: string
  cancelButtonAction?: any
  cancelButtonText?:string
  autoPlay?: boolean
}

export type ProcessHeroCarouselData = {
  illustrationFilename?: string
  imageSource?: any
  headline: string
  subHeading?: string | React.ReactNode
  subHeadingInfo?: React.ReactNode
}

export const ProcessHeroScreen = (props: ProcessHeroProps) => {
  const { isLoading, loadingMessage, error, errorTryAgain, errorCancel, carouselScreens, onFinish, finishButtonLabel, cancelButtonAction, cancelButtonText, autoPlay, finishButtonDisabled, finishButtonExplanation } = props

  const [carouselComplete, setCarouselComplete] = useState(false)

  const sliderWidth = getScreenAppWidth()
  
  const carouselHeight = getScreenAppHeight() - Sizing.x300

  const carouselRef = useRef()

  const snapToNext = () => {
    //@ts-ignore
    carouselRef?.current?.next()
  }

  const onCompletion = () => {
    setCarouselComplete(true)
  }

  const renderItem = ({ item, index }) => {
    const { illustrationFilename, imageSource, headline, subHeading, subHeadingInfo } = item
    return (
      <View style={{
        // paddingHorizontal: Sizing.x40,
      }}>
        <View style={{
          alignSelf: 'center',
          paddingVertical: Sizing.x10,
        }}>
          {
            illustrationFilename ?
              <AppIllustration filename={illustrationFilename} style={{
                width: scaleNormalizer(250),
                height: scaleNormalizer(250),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            : imageSource ?
              <Image source={imageSource} style={{
                width: scaleNormalizer(250),
                height: scaleNormalizer(250),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            : <></>
          }
        </View>
        <View style={{
          alignSelf: 'center',
          paddingBottom: Sizing.x10,
          paddingHorizontal: Sizing.x30,
        }}>
          {headline ?
            <Headline numberOfLines={2} adjustsFontSizeToFit={true}>{headline}</Headline>
            : <></>
          }
        </View>
        <View style={{
          alignSelf: 'center',
          paddingHorizontal: Sizing.x30,
        }}>
          {
            subHeading ?
              <Subheading numberOfLines={3} adjustsFontSizeToFit={true}>{subHeading}</Subheading>
              : <></>
          }
          {
            subHeadingInfo ? subHeadingInfo : <></>
          }
        </View>
      </View>
    )
  }
  const viewConstraints: any = getScreenWidthConstraints()
  
  return (
    <>
      <StatusBar barStyle={'dark-content'} />
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        {
          error || isLoading ? <Loading message={loadingMessage} messageNoLoop={true} useHolidayGraphics={true} /> :
            <>
              <SafeAreaView style={[
                backgroundStyles.pageContainer,
                backgroundStyles.heroBackground,
              ]}>
                {
                  //Error/loading
                  error || isLoading ?
                    <View style={{
                      ...Flex.column.start,
                      paddingHorizontal: Sizing.x30,
                    }}>
                      <View style={[
                        Flex.override.fill,
                        viewConstraints,
                      ]}>
                        {
                          error ? <ErrorScreen
                            errorTryAgain={errorTryAgain}
                            errorCancel={errorCancel}
                            error={error?.data}
                            />
                          : isLoading ? <Loading message={loadingMessage} useHolidayGraphics={true} /> : <></>
                        }
                      </View>
                      <View style={{ height: Sizing.x90 }}></View>
                    </View>
                    :
                  <View style={[
                    layoutStyles.mainContentContainer,
                    localStyles.processHeroScreenContainer,
                  ]}>
                    <ManagedCarousel
                      ref={carouselRef}
                      width={sliderWidth}
                      data={carouselScreens}
                      renderItem={renderItem}
                      onCompletion={onCompletion}
                      height={carouselHeight}
                      autoPlay={autoPlay}
                      autoPlayInterval={6000}
                    />
                    {
                      carouselComplete && cancelButtonAction ? <FooterButton
                        mode={'text'}
                        onPress={cancelButtonAction}
                      >{
                        cancelButtonText || 'Cancel'}
                      </FooterButton>
                      : <></>
                    }
                    { carouselComplete && finishButtonExplanation
                      ? <Text style={{ color: Colors.neutral.s300 }}>{finishButtonExplanation}</Text>
                      : <></>
                    }              
                    {
                      onFinish ? <FooterButton
                        onPress={carouselComplete ? onFinish : snapToNext}
                        disabled={finishButtonDisabled}
                      >{
                        carouselComplete ? finishButtonLabel || 'Back to Dashboard' : 'Next'}
                      </FooterButton>
                      : <></>
                    }
                  </View>
                }
              </SafeAreaView>
          </>
        }
      </ThemeProvider>
    </>
  )
}

const localStyles = StyleSheet.create({
  processHeroScreenContainer: {
    paddingTop: Sizing.x30,
  },
})