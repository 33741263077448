import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Flex, Sizing } from 'styles'

type DashboardItemProps = {
  error?: any
  isLoading?: boolean
  isFetching?: boolean
  children: React.ReactNode
}
export const DashboardItem = (props: DashboardItemProps) => {
  const { error, isLoading, isFetching, children } = props
  return (
    <View style={localStyles.dashboardItemContainer}>
      {
        isLoading || isFetching ? <Loading /> : error ? <ErrorScreen noDashboardButton={true} iconOnly={true} error={error?.data} /> : children
      }
    </View>          
  )
}

const localStyles = StyleSheet.create({
  dashboardItemContainer: {
    alignItems: 'center',
    width: '100%',
    // paddingHorizontal: Sizing.x30,
  },
})