import { AnyAction, combineReducers, configureStore, Reducer } from "@reduxjs/toolkit"
import authReducer from 'store/authSlice'
import tempDataReducer, { tempDatainitialState } from './tempDataSlice'
import uxReducer, { uxInitialState } from './uxSlice'
import { api } from "./apiSlice"
import { passwordless } from "./passwordlessSlice"
import { clearCachedToken } from "lib/authHelpers"
// import { persistStore, persistReducer } from 'redux-persist'
// import AsyncStorage from '@react-native-async-storage/async-storage'
// import createSecureStore from '@neverdull-agency/expo-unlimited-secure-store'
// import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"

// const isWeb = platformIsWeb()
// const storage = isWeb ? AsyncStorage : createSecureStore()

// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: autoMergeLevel2,
//   whitelist: ['api']
// }

const combinedReducer = combineReducers({
  auth: authReducer,
  tempData: tempDataReducer,
  ux: uxReducer,
  [api.reducerPath]: api.reducer,
  [passwordless.reducerPath]: passwordless.reducer,
})

const combinedMiddleware = [
  api.middleware,
  passwordless.middleware,
]

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/logoutUser") {
    clearCachedToken()
    state = {
      auth: {
        user: {
          localToken: false,
          data: null,
          loggingIn: false,
          token: null,
          refreshToken: null,
          error: null,
          impersonation: null,
        },
        haveAppUser: false,
      },
      ux: uxInitialState,
      tempData: tempDatainitialState,
    } as RootState
  }
  return combinedReducer(state, action);
}

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({            
      serializableCheck: { ignoredPaths: ['api'] },
    }).concat(combinedMiddleware)
})

// export type RootState = ReturnType<typeof persistedReducer>
export type RootState = ReturnType<typeof combinedReducer>

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

// export const persistor = persistStore(store)

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
