import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ProcessHeader } from 'components/Layout/ProcessHeader'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Portal, ThemeProvider } from 'react-native-paper'
import { useAppDispatch } from 'store/hooks'
import { Colors, Flex, Paper } from 'styles'

const Stack = createNativeStackNavigator()

export type ProcessStackBuilderProps = {
  navigatorId: string
  processTitle: string
  initialRouteName?: string
  initialParams?: any
  // introScreen: ProcessStackBuilderScreen
  processScreens: ProcessStackBuilderScreen[]
  resultScreen: ProcessStackBuilderScreen
  presentation?: "modal" | "transparentModal" | "containedModal" | "containedTransparentModal" | "fullScreenModal" | "formSheet" | "card"
  previousRoute: string
  nextRoute?: string
  removeProgressBar?:boolean
}

export type ProcessStackBuilderScreen = {
  navigationId: string
  component: React.ComponentType<any>
  title?: string
}

export const ProcessStackBuilder = (props: ProcessStackBuilderProps) => {
  const { navigatorId, previousRoute, nextRoute, processScreens, resultScreen, processTitle, initialRouteName, initialParams, presentation,removeProgressBar } = props
  let stackScreens: Stack.Screen[] = []
  const totalScreenCount = processScreens.length

  //Hide header
  const dispatch = useAppDispatch()

  const baseInitialParams = {
    previousRoute,
    nextRoute,
  }

  //Build stackScreens from process screens
  stackScreens = processScreens.map((processScreen, idx) => {
    const currentScreenPosition = idx + 1
    const nextScreen = idx === totalScreenCount - 1 ? resultScreen.navigationId : processScreens[currentScreenPosition].navigationId
    return <Stack.Screen
      key={idx}
      name={processScreen.navigationId}
      component={processScreen.component}
      initialParams={{
        nextScreen,
        ...baseInitialParams,
        ...initialParams,
      }}
      options={{
        headerShown: false,
      }}
    />
  })

  const processNavigationRef = createNavigationContainerRef() as any

  const isWeb = platformIsWeb()

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightGrey : Paper.lightTheme}>
      <Portal.Host>
      <ProcessHeader
        sectionName={processTitle}
        navigationRef={processNavigationRef}
        previousRoute={previousRoute}
        removeProgressBar={removeProgressBar}
        routeParams={{
          ...baseInitialParams,
          ...initialParams,
        }}
      />
      <NavigationContainer
        documentTitle={{
          enabled: false,
        }}      
        ref={processNavigationRef}
        independent={true}
      >
        <Stack.Navigator
          id={navigatorId}
          screenOptions={{
            animation: 'slide_from_right',
            presentation: presentation || 'card',
            headerShadowVisible: false,
            headerStyle: {          
              backgroundColor: Colors.neutral.white,
            },
          }}
          initialRouteName={initialRouteName}
        >
          <Stack.Group>
            { stackScreens.map((screen, idx) => { return screen })
            }
          </Stack.Group>
          <Stack.Screen
            key='Result'
            name={resultScreen.navigationId}
            component={resultScreen.component}
            initialParams={{
              ...baseInitialParams,
              ...initialParams,
            }}
            options={{
              headerShown: false,
              gestureEnabled: false,
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
      </Portal.Host>
    </ThemeProvider>
  )
}
