import { MaterialCommunityIcons } from '@expo/vector-icons'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from 'lib/appInfoHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { Colors, Flex, Sizing } from 'styles'

export type UserBlockedScreenProps = {
  doLogout: any
}

export const UserBlockedScreen = (props: UserBlockedScreenProps) => {
  const { doLogout } = props || {}
  
  const { data: user, isLoading, error, refetch } = useGetCurrentUserQuery()
  const isWeb = platformIsWeb()

  //PA-1657 REMOVE
  // //Go back to loading when the user exists (from try again button)
  // useEffect(() => {
  //   if (user) {
  //     navigation.replace('Loading')
  //   }
  // }, [user]
  // )
  
  const sendErrorViaIntercom = async () => {
    const informationArray = getDiagnosticInformation(user)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
    ])
    let initialMessage = `----- Jarvis User Blocked -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Please add any additional information about this error below and hit the send button!\n`
    initialMessage += `---------------------------------------\n`
    await composeIntercomMessage(initialMessage)
  }

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={{
        ...Flex.column.center,
        alignItems: 'center'
      }}>
        <View style={{
          alignItems: 'center',
          paddingHorizontal: Sizing.x20,
          width: '100%',
        }}>
          <MaterialCommunityIcons name={'block-helper'} size={Sizing.x100} color={Colors.brand.red3} />
          <View style={{ paddingVertical: Sizing.x20 }}>
            <Headline>{`Your Jarvis account has been blocked`}</Headline>
            <Subheading>{`Please contact support`}</Subheading>
          </View>
          <Button
            style={{paddingBottom: Sizing.x20}}
            mode={'text'}
            color={Colors.brand.red3}
            onPress={doLogout}
          >{`Logout`}</Button>
          { !isWeb ?
            <Button
              mode={'contained'}
              onPress={sendErrorViaIntercom}
            >{`Contact Support`}
            </Button>
            : <></>
          }
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}
