import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { DEFAULT_DEBOUNCE_MS } from 'lib/constants'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Dialog, Portal, ThemeProvider } from "react-native-paper"
import { useAddGroupOrganizationUserMutation, useGetGroupOrganizationUsersQuery, useGetUsersQuery } from 'store/apiSlice'
import { UserDto } from 'store/dto/user.dto'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { useDebounce } from 'use-debounce'

type AdminGroupOrganizationUserAddDialogProps = {
  organizationId: string
  onClose: any
  onSave: any
  visible: boolean
}

export const AdminGroupOrganizationUserAddDialog = (props: AdminGroupOrganizationUserAddDialogProps) => {
  const { visible, onClose, organizationId, onSave } = props || {}

  const [emailToCheck, setEmailToCheck] = useState<string>(undefined)
  const [debouncedEmailToCheck] = useDebounce(emailToCheck, DEFAULT_DEBOUNCE_MS)
  const [matchingUsers, setMatchingUsers] = useState<UserDto[] | false>(undefined)

  const { data: groupOrgUsers, isLoading: groupOrgUsersIsLoading, error: groupOrgUsersError, refetch: refetchOrgUsers } = useGetGroupOrganizationUsersQuery(organizationId)

  const { data: users, isLoading: usersIsLoading, error: usersError, isFetching: usersIsFetching, refetch: refetchUsers } = useGetUsersQuery({
    email: debouncedEmailToCheck,
  }, { skip: !debouncedEmailToCheck})

  const [addOrganizationUser, { data: addedUser, isLoading: addUserIsLoading, error: addUserError }] = useAddGroupOrganizationUserMutation()

  const isLoading = groupOrgUsersIsLoading || addUserIsLoading
  const error: any = groupOrgUsersError || addUserError

  const handleAddUser = (
    email: string,
    firstName: string,
    surname: string,
  ) => {
    addOrganizationUser({
      organizationId,
      email,
      firstName,
      surname,
    })
  }

  useEffect(() => {
    if (addedUser) {
      onSave(addedUser)
      onClose()
    }
  }, [addedUser])

  const formObj = useForm<{
    firstName: string
    surname: string
    email: string
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      surname: '',
      email: '',
    },
  })

  const firstNameRef = useRef(null)
  const surnameRef = useRef(null)
  const emailRef = useRef(null)

  const { handleSubmit, setValue, setError, trigger, watch, formState: { isValid, errors } } = formObj

  const email = watch('email')

  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setMatchingUsers(undefined)
    setEmailToCheck(email && (/\S+@\S+\.\S+/).test(email) ? email : undefined)
  }, [email])

  //Force refetch when email changes to check
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (debouncedEmailToCheck) {
      refetchUsers()
    }
  }, [debouncedEmailToCheck])

  //Update matchingUsers based on check result
  useEffect(() => {  
    if (usersError || usersIsLoading || usersIsFetching) {
      setMatchingUsers(undefined)
    } else if (users) {
      setMatchingUsers(users)
    }
  }, [users, usersError, usersIsLoading, usersIsFetching])

  //Trigger validation when matchingUsers changes
  useEffect(() => {
    if (matchingUsers && matchingUsers?.length) {
      const first = matchingUsers[0]
      setValue('firstName', first.metadata?.firstName || 'Unknown')
      setValue('surname', first.metadata?.surname || 'Unknow')
    }
    trigger()
  }, [matchingUsers])

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  const constraintStyle: any = getScreenSizeConstraints()
  const maxContentHeight = getScreenAppHeight() - Sizing.x200

  const onSubmit = (attributes) => {
    const { email, firstName, surname } = attributes
    handleAddUser(email, firstName, surname)
  }

  const matchingUser = matchingUsers === undefined
    ? undefined
    : matchingUsers && matchingUsers?.length
      ? matchingUsers[0]
      : false

  const isNotExistingOrgUser = () => {
    if (usersIsLoading) {
      return 'Checking...'
    }
    const existingOrgUser = groupOrgUsers ? groupOrgUsers.find(groupOrgUser => {
      return matchingUser && groupOrgUser.id === matchingUser.id
    }) : undefined
    return existingOrgUser ? `User has already been added to organization.` : true
  }

  let informationMessage: string
  let informationMessageIsError = false
  if (matchingUser === false) {

  } else if (matchingUser) {
    const validationError = isNotExistingOrgUser()
    if (validationError && validationError !== true) {
      informationMessage = validationError
      informationMessageIsError = true
    } else {
      informationMessage = `Found existing Jarvis user!`
    }
  }

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <Dialog
          visible={visible}
          onDismiss={onClose}
          style={{
            ...constraintStyle,
            minWidth: Sizing.x600,
          }}
          theme={theme}
        >
          <Dialog.Title style={{
            color: Colors.brand.purple1,
            textAlign: 'center',
          }}>{`Add User to Organization`}</Dialog.Title>
          <Dialog.Content
          style={{
            maxHeight: maxContentHeight,
          }}
          >
            {
              isLoading ? <Loading message={addUserIsLoading ? ['Adding user'] : undefined} /> :
              error ?  <ErrorScreen errorTryAgain={addUserError ? handleAddUser : refetchOrgUsers} error={error?.data} /> : 
              <>
                <ScrollView
                  nestedScrollEnabled
                  style={layoutStyles.scrollContainer}
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  keyboardShouldPersistTaps='handled'
                  showsVerticalScrollIndicator={true}
                >
                  <View style={{height: Sizing.x300 }}>
                    <ManagedTextInput
                      ref={emailRef}
                      name={'email'}
                      keyboardType='email-address'
                      formObj={formObj}
                      label={'Email Address'}
                      placeholder={'Their email address'}
                      autoCapitalize={'none'}
                      returnKeyType={'next'}
                      blurOnSubmit={false}
                      informationMessage={informationMessage}
                      informationMessageIsError={informationMessageIsError}
                      rules={{
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email address"
                        },
                        required: true,
                        minLength: 2,
                        maxLength: 40,
                        validate: {
                          isNotExistingOrgUser,
                        }
                    }} />
                    <ManagedTextInput
                      ref={firstNameRef}
                      name={'firstName'}
                      formObj={formObj}
                      label={'First Name'}
                      placeholder={'First name of new user'}
                      returnKeyType={'next'}
                      blurOnSubmit={false}
                      submitHandler={() => surnameRef.current?.focus()}
                      disabled={!!matchingUser}
                      rules={{
                        required: matchingUser === false,
                        minLength: 3,
                        maxLength: 40,
                    }}/>
                    <ManagedTextInput
                      ref={surnameRef}
                      name={'surname'}
                      formObj={formObj}
                      label={'Surname'}
                      placeholder={'Surname of new user'}
                      returnKeyType={'next'}
                      blurOnSubmit={false}
                      submitHandler={() => emailRef.current?.focus()}
                      disabled={!!matchingUser}
                      rules={{
                        required: matchingUser === false,
                        minLength: 3,
                        maxLength: 40,
                    }}/>
                  </View>
                </ScrollView>
              </>
            }
          </Dialog.Content>
          <Dialog.Actions>
            <Button mode='text' onPress={onClose} disabled={addUserIsLoading || !!addUserError}>
              <Text style={localStyles.buttonText}>{'Cancel'}</Text>
            </Button>
            <Button mode='text' onPress={handleSubmit(onSubmit)} disabled={!isValid || addUserIsLoading || !!addUserError || usersIsLoading || !!usersError}>
              <Text style={localStyles.buttonText}>{'Add User'}</Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </ThemeProvider>
    </Portal>
  )
}

const localStyles = StyleSheet.create({
  buttonText: {
    color: Colors.brand.purple1
  },
})


