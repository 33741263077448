import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeRequestStatus, GroupSchemeRequestType } from 'store/dto/account.dto'
import { GroupSchemeRequestDto } from 'store/dto/group-scheme.dto'
import { Colors, Paper, Sizing } from 'styles'

type EmployerHistoryRequestListItemProp = {
  request: GroupSchemeRequestDto
  onPressFunction?: () => void
}
export const EmployerHistoryRequestListItem = (props: EmployerHistoryRequestListItemProp) => {
  const { request, onPressFunction } = props
  const { status, requestType, actionDate, actionMessage, enrolment, client, data } = request
  const { firstName, surname, nationalInsuranceNo } = client || {}
  const { employeeEmail, employeeId } = enrolment || {}
  const { nationalInsuranceNo: dataNationalInsuranceNo } = data || {}
  const bestNationalInsuranceNumber = nationalInsuranceNo || dataNationalInsuranceNo

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName =
    requestType === GroupSchemeRequestType.OPT_OUT
    ? 'account-cancel-outline'
    : requestType === GroupSchemeRequestType.OPT_IN
    ? 'account-arrow-left-outline'
    : requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP
    ? 'account-arrow-right-outline'
    : requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
    ? 'account-cash-outline'
    : 'account-cog-outline'
  const title = `${firstName} ${surname}`
  const titleRight =
    requestType === GroupSchemeRequestType.OTHER
    ? 'Other Request'
    : `Request to ${requestType}` 
  const subTitle = enrolment
    ? `${employeeId} / ${formatNationalInsuranceNumber(bestNationalInsuranceNumber)} / ${employeeEmail}` 
    : formatNationalInsuranceNumber(bestNationalInsuranceNumber)

  const titleRightStyle = {
    color: themeColors.primary
  }
  const subTitleRight =
    status === GroupSchemeRequestStatus.COMPLETED
    ? `Actioned ${actionDate ? formatUkDate(new Date(actionDate)) : ''}`
    : status === GroupSchemeRequestStatus.REJECTED
    ? `Rejected ${actionDate ? formatUkDate(new Date(actionDate)) : ''}`
    : status === GroupSchemeRequestStatus.CANCELLED
    ? `Cancelled by Member`
    : ''

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={titleRight}
      titleRightStyle={titleRightStyle}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{subTitleRight}</Text>}
    />
  )
}
