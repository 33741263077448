import { Text } from 'components/Typography/Text'
import React from 'react'
import { View } from 'react-native'
import { layoutStyles } from 'styles/common'

type NotificationDotProps = {
  top?: number
  bottom?: number
  right?: number
  left?: number
  count?: number
}

export const NotificationDot = (props: NotificationDotProps) => {
  const { top, bottom, left, right, count } = props

  const notificationLabel =
    count > 9 ? '9+'
    : count === 0 ? ''
    : count || undefined

  return (
    <View style={{
      ...layoutStyles.notificationDot,
      top,
      bottom,
      right,
      left,
    }}>
      <Text style={layoutStyles.notificationDotText}>{notificationLabel}</Text>
    </View>
  )
}
