import type {
  Content,
  HelpCenterCollectionContent,
  IntercomType,
  MetaData,
  UserAttributes,
} from "@intercom/intercom-react-native";
import { EmitterSubscription } from "react-native";

export enum Space {
  home = "HOME",
  helpCenter = "HELP_CENTER",
  messages = "MESSAGES"
}

export const IntercomContent: any = {
  articleWithArticleId: function (articleId: string): Promise<any> {
    throw new Error("Function not implemented.");
  },
}

const webImplementation: IntercomType = {
  fetchHelpCenterCollections: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  searchHelpCenter: function (
    term: string
  ): Promise<any> {
    throw new Error("Function not implemented.");
  },
  fetchHelpCenterCollection: function (
    id: string
  ): Promise<HelpCenterCollectionContent> {
    throw new Error("Function not implemented.");
  },
  getUnreadConversationCount: function (): Promise<number> {
    throw new Error("Function not implemented.");
  },
  hideIntercom: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  logEvent: function (
    eventName: string,
    metaData?: MetaData
  ): Promise<boolean> {
    return Promise.resolve(true);
  },
  logout: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  loginUserWithUserAttributes: function (
    params: Partial<{ email: string; userId: string }>
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  loginUnidentifiedUser: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setBottomPadding: function (bottomPadding: number): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setInAppMessageVisibility: function (
    visibility: "GONE" | "VISIBLE"
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setLauncherVisibility: function (
    visibility: "GONE" | "VISIBLE"
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setLogLevel: function (
    logLevel:
      | "ASSERT"
      | "DEBUG"
      | "DISABLED"
      | "ERROR"
      | "INFO"
      | "VERBOSE"
      | "WARN"
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setUserHash: function (hash: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  updateUser: function (params: UserAttributes): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  handlePushMessage: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  sendTokenToIntercom: function (token: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  addEventListener: function (
    event:
      | "IntercomUnreadConversationCountDidChangeNotification"
      | "IntercomHelpCenterDidShowNotification"
      | "IntercomHelpCenterDidHideNotification"
      | "IntercomWindowDidHideNotification"
      | "IntercomWindowDidShowNotification",
    callback: (response: { count?: number; visible: boolean }) => void
  ): EmitterSubscription {
    throw new Error("Function not implemented.");
  },
  present: function(): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  presentSpace: function(space: Space): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  presentContent: function(content: Content): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  presentMessageComposer: function(initialMessage?: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  setNeedsStatusBarAppearanceUpdate: function(): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  registerIdentifiedUser: function (
    params: Partial<{ email: string; userId: string }>
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  registerUnidentifiedUser: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayArticle: function (articleId: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayCarousel: function (carouselId: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayHelpCenter: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayHelpCenterCollections: function (
    collections?: string[]
  ): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayMessageComposer: function (initialMessage?: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated The method should not be used
   */
  displayMessenger: function (): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  /**
   * @deprecated `displaySurvey` is deprecated and will be removed in a future release.  Use `presentContent` instead.
   */
  displaySurvey: function (surveyId: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  }
};

export default webImplementation;