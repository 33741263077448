import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { ComponentProps, useState } from 'react'
import { Portal } from "react-native-paper"
import { Sizing, Paper } from 'styles'
import { Button } from './Button'
import { ShareModal } from './ShareModal'
import { UserDto } from 'store/dto/user.dto'

type ShareButtonProps = ComponentProps<typeof Button> & {
  user: UserDto
  campaign?: string
  onDismiss?: any
}

export const ShareButton = (props: ShareButtonProps) => {
  const { user, campaign, children, onDismiss } = props

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const handleDismiss = () => {
    hideModal()
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <>
      <Button
        {...props}
        mode='text'
        icon={() => <MaterialCommunityIcons name={'share-variant'} size={Sizing.x20} color={themeColors.accent} />}
        onPress={showModal}
      >
        {children}
      </Button>
      <Portal>
        <ShareModal
          user={user}
          campaign={campaign}
          onDismiss={handleDismiss}
          visible={modalVisible}
        />
      </Portal>
    </>
  )
}