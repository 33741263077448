import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { useGetSpouseQuery } from 'store/apiSlice'

export const RetirementProfileSetupIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: spouse, isLoading: spouseIsLoading, refetch } = useGetSpouseQuery()
  const hasRealSpouse = spouse && spouse?.userId

  const next = () => {
    if (hasRealSpouse) {
      rootNavigate('RetirementProfileSetupProcessStack', route?.params) //Go to start of process for copy choice
    } else {
      const params = route?.params || {}
      rootNavigate('RetirementProfileSetupProcessStack', { ...params, initialRouteName: 'TargetRetirementAge' }) //Skip to target retirement age
    }    
  }
  
  //Preload data

  return (      
    <ProcessIntroScreen
      isLoading={spouseIsLoading}
      errorTryAgain={refetch}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      cancelButtonText={fromOnboarding ? 'Back to Tasks' : 'Back To Dashboard'}
      illustrationFilename={'set_your_budget.png'}
      headline={`Define Retirement Lifestyle`}
      subHeading={`Answer a few questions about how and when you want to retire, and define a budget tailored to your personal circumstances`}
    > 
    </ProcessIntroScreen>
  )
}
