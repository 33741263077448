import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminTransfersListScreen } from './Screens/AdminTransfersListScreen'

export const AdminTransfersSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Transfers'}
    >
      <AdminTransfersListScreen />
    </SectionSimpleContainer>
  )
}

