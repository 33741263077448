import { RetirementIncomeSetup_01_StatePensionChoice, RetirementIncomeSetup_02_StatePensionAmount, RetirementIncomeSetup_03_SpouseStatePensionChoice, RetirementIncomeSetup_04_SpouseStatePensionAmount, RetirementIncomeSetup_05_StatePensionSave, RetirementIncomeSetup_06_Incomes, RetirementIncomeSetup_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'

export const RetirementIncomeSetupProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='RetirementIncomeSetup'
      processTitle='Retirement Incomes'
      previousRoute='RetirementIncomeSetupIntroScreen'
      nextRoute='RetirementIncomeSetupHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'StatePensionChoice',
          component: RetirementIncomeSetup_01_StatePensionChoice,
        },
        { 
          navigationId: 'StatePensionAmount',
          component: RetirementIncomeSetup_02_StatePensionAmount,
        },
        { 
          navigationId: 'SpouseStatePensionChoice',
          component: RetirementIncomeSetup_03_SpouseStatePensionChoice,
        },
        { 
          navigationId: 'SpouseStatePensionAmount',
          component: RetirementIncomeSetup_04_SpouseStatePensionAmount,
        },  
        { 
          navigationId: 'StatePensionSave',
          component: RetirementIncomeSetup_05_StatePensionSave,
        },        
        { 
          navigationId: 'Incomes',
          component: RetirementIncomeSetup_06_Incomes,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: RetirementIncomeSetup_99_Result,
      }}
    />
  )
}
