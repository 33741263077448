import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import React from 'react'
import { RequestsList } from '../Components/RequestsList'

export const Enrolments_03_Requests = ({ route, navigation }) => {
  const { enrolmentId }: { enrolmentId: string } = route?.params || {}

  return (
    <MainAppScreen>
      <RequestsList
        enrolmentId={enrolmentId}
      />
    </MainAppScreen>
  )
}