import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { envVariables } from 'environment'
import * as WebBrowser from 'expo-web-browser'
import { LoginConnection, setUserWithToken, userIsLoggingIn } from 'lib/authHelpers'
import { Logger } from 'lib/logger'
import { platformIsWeb } from 'lib/platformHelpers'
import Auth0 from 'platform-lib/auth0'
import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { AppContext, linkParams, loginParams, setUser } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { Colors, Sizing } from 'styles'

const useAuth0 = Auth0.useAuth0

WebBrowser.maybeCompleteAuthSession()

export const Authentication_02_Choice = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}
  
  const loginConfig = useAppSelector(loginParams)
  const { authType } = loginConfig || {}

  const appContext = useSelector((state: any) => state.auth.appContext)

  const dispatch = useDispatch()

  const linkConfig = useAppSelector(linkParams)

  const { auth0Scopes, auth0CustomScheme, appUrl } = envVariables

  const authUser = useSelector((state: any) => state.auth.user)
  const [loginError, setLoginError] = useState(undefined)

  // @ts-ignore 
  const { loginWithRedirect, getCredentials, clearCredentials, clearSession, authorize } = useAuth0()

  //Function to initiate auth0 login
  const login = async (connection: LoginConnection) => {
    Logger.info(`Login with: ${connection}`)
    if (!isWeb) {
      Logger.info(`Not web - updating user...`)
      dispatch(
        setUser({
          localToken: false,
          loginMethod: undefined,
          data: null,
          loggingIn: true,
          token: null,
          refreshToken: null,
          error: `Logging in...`,
          impersonation: null,
        })
      )
    }
      
    if (isWeb) {
      const appState =  {
        appContext,
        ...linkConfig,
      }
      Logger.info({ appState }, `Web login...`)
      const result = await loginWithRedirect({
        // @ts-ignore 
        connection,
        appState,
      })
      Logger.info({ result }, `Web login result`)

    } else {
      const ephemeralSession = Platform.select({
        ios: true,
        default: false,
      })
      try {
        await authorize({
          scope: auth0Scopes,
          connection,
        },
        {
          ephemeralSession,
          customScheme: isWeb ? undefined : auth0CustomScheme,
        })
        let token
        try {
          token = await getCredentials(auth0Scopes)
        } catch (e) {
          Logger.debug(`Call to getCredentials rejected.`)
        }
        if(token) {
          //Always clear credentials - this is required on iOS as do cannot
          //clearSession when using ephemeralSession and we need to get rid of the
          //undeylying session, otherwise it is still possible to log back in after
          //explicit app logout by starting and cancelling google auth
          await clearCredentials()
          
          //clearSession as it interferes with our own token storage/management to auto-refreshing
          //if the Auth0 store becomes out of sync with our store
          if (Platform.OS === 'android') {
            Logger.debug(`Got token, cleaning up Auth0 session...`)
            await clearSession({
              customScheme: isWeb ? undefined : auth0CustomScheme
            })
          } else {
            Logger.debug(`Got token, skipping Auth0 session cleanup (ios ephemeral)...`)
          }
          await setUserWithToken(token, connection, dispatch)
        } else {
          Logger.error(`Could not get authentication token`)
          setLoginError(`Could not authenticate - please contact support`)
          dispatch(
            setUser({
              localToken: false,
              loginMethod: undefined,
              data: null,
              loggingIn: false,
              token: null,
              refreshToken: null,
              error: `Login failed (no token).`,
              impersonation: null,
            })
          )
        }
      } catch (e) {
        Logger.error(`Login failed: ${JSON.stringify(e)}`)
        setLoginError(`Could not authenticate - please contact support`)
        dispatch(
          setUser({
            localToken: false,
            loginMethod: undefined,
            data: null,
            loggingIn: false,
            token: null,
            refreshToken: null,
            error: `Login failed.`,
            impersonation: null,
          })
        )
      }  
    }
  }

  const isLogin = authType === AUTHTYPE.LOGIN ? true : false
  const loggingIn = userIsLoggingIn(authUser)

  const isWeb = platformIsWeb()

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      isLoading={loggingIn}
      showButton={false}
      buttonTitle={''}
      buttonAction={() => {}}
      enableButton={false}
      headline={
        appContext === AppContext.ADMIN ? `Administration Portal Login`
        : appContext === AppContext.EMPLOYER ? `Employer Portal Login`
        : isLogin
          ? `Choose how to login`
          : `Choose a way to create an account`
      }
      subHeading={
        appContext === AppContext.ADMIN ? `Always use your @pensionjar.com email address`
        : appContext === AppContext.EMPLOYER ? `Use your work/organisation email address`
        : `Get a magic link via email, or use your Google/Apple account`}
    >
      <View style={localStyles.buttonContainer}>
        <Button
          style={isWeb ? { width: Sizing.x300 } : undefined}
          icon={'email-outline'}
          mode={'contained'}
          onPress={next}>{'Email Magic Link'}
        </Button>
      </View>
      <View style={localStyles.buttonContainer}>
        <Button
          style={isWeb ? { width: Sizing.x300 } : undefined}
          icon={'google'}
          mode={'contained'}
          onPress={() => login(LoginConnection.GOOGLE)}>{'Google Account'}
        </Button>
      </View>
      <View style={localStyles.buttonContainer}>
        <Button
          style={isWeb ? { width: Sizing.x300 } : undefined}
          icon={'apple'}
          mode={'contained'}
          onPress={() => login(LoginConnection.APPLE)}>{'Apple Account'}
        </Button>
      </View>
      {
        appContext !== AppContext.CLIENT ? <></>
        : isLogin ?
        <View style={{ paddingTop: Sizing.x20 }}>
          <Paragraph style={{ alignSelf: 'center' }}>{`If using your Google/Apple account, the associated email must match that on your Jarvis account.`}</Paragraph>
        </View>
        : <View style={{ paddingTop: Sizing.x20 }}>
          <Paragraph style={{ alignSelf: 'center' }}>{`Please note that if registering with your Apple account, and you select the "Hide my Email" feature, you will not be able to login in future via other methods.`}</Paragraph>
        </View>
      }
      {authUser.error ? <Paragraph style={{ color: Colors.brand.red2, alignSelf: 'center' }}>{authUser.error}</Paragraph> : <></>}
      {loginError ? <Paragraph style={{ color: Colors.brand.red2, alignSelf: 'center' }}>{loginError}</Paragraph> : <></>}
    </ProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingTop: Sizing.x20,
  }
})