  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Paragraph } from 'components/Typography'
import { ContributionRecord, EmployerContributionSetChoice, WrappedContributionRecord } from 'features/ModalProcesses/EmployerAddContributionJob/EmployerAddContributionJobModal'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { Checkbox, DataTable, Portal } from 'react-native-paper'
import { Colors, Paper, Sizing } from 'styles'
import { EmployerContributionEditDialog } from './EmployerContributionEditDialog'
import { ManagedSimpleChoiceInput } from 'components/Inputs/ManagedSimpleChoiceInput'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { MAX_WORKPLACE_CONTRIBUTION_AMOUNT } from 'lib/constants'
import { applyOpacity } from 'styles/colors'

type EmployerContributionTableRowProps = {
  formObj: UseFormReturn<any>
  name: string
  index: number
  bulkSelectMode: boolean
}

export const EmployerContributionTableRow = (props: EmployerContributionTableRowProps) => {
  const { formObj, name, index, bulkSelectMode } = props
  const { control, watch, setValue, trigger } = formObj
  const wrappedContributionRecord: WrappedContributionRecord = watch(`${name}.${index}`)
  const { id, selected, data: contributionRecord } = wrappedContributionRecord

  const [showEditModal, setShowEditModal] = useState(false)

  const {
    firstName,
    surname,
    nationalInsuranceNo,
    employerContributionAmount,
    employeeContributionAmount,
    lastEmployeeContributionAmount,
    lastEmployerContributionAmount,
    choice,
    inOptOutWindow,
  } = contributionRecord

  const hasLastAmounts = lastEmployeeContributionAmount || lastEmployerContributionAmount
  const amountsAreNew = (
    employeeContributionAmount !== lastEmployeeContributionAmount ||
    employerContributionAmount !== lastEmployerContributionAmount
  )

  const isNilReturn = choice === EmployerContributionSetChoice.NIL
  const needsEdit = choice === EmployerContributionSetChoice.NEW && !employeeContributionAmount && !employerContributionAmount

  const color =
    isNilReturn ? Colors.neutral.s200
    : needsEdit ? Colors.brand.red2
    : amountsAreNew ? Colors.brand.purple1
    : undefined
  
  const fontWeight = 
    isNilReturn ? undefined
    : needsEdit ? undefined
    : amountsAreNew ? 'bold'
    : undefined

  const iconName =
    inOptOutWindow ? 'account-cancel-outline'
    : isNilReturn ? 'account-remove-outline'
    : needsEdit ? 'account-alert-outline'
    : amountsAreNew ? 'account-edit-outline'
    : 'account-check-outline'

  const handleEdit = (
    choice: EmployerContributionSetChoice,
    employerContributionAmount: number,
    employeeContributionAmount: number,
  ) => {
    setValue(`${name}.${index}.data.choice`, choice, { shouldDirty: true, shouldValidate: true })
    setValue(`${name}.${index}.data.employerContributionAmount`, employerContributionAmount, { shouldDirty: true, shouldValidate: true })
    setValue(`${name}.${index}.data.employeeContributionAmount`, employeeContributionAmount, { shouldDirty: true, shouldValidate: true })  
    setShowEditModal(undefined)
  }

  const atLeastOneAmountRequired = (value: string) => {
    const cotributionRecord: ContributionRecord = watch(`${name}.${index}.data`)
    const { choice, employerContributionAmount, employeeContributionAmount } = cotributionRecord
    if (choice === EmployerContributionSetChoice.NEW && !employerContributionAmount && !employeeContributionAmount) {
      return false
    } else {
      return true
    }
  }
  
  const unselectRecord = () => {
    setValue(`${name}.${index}.selected`, false)
  }
  
  const selectRecord = () => {
    setValue(`${name}.${index}.selected`, true)
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const opacity = selected ? themeColors.themeType === 'light' ? 0.2 : 0.1 : undefined
  const backgroundColor = selected ? applyOpacity(themeColors.divider, opacity) : undefined

  return (
    <>
      <View style={{ display: 'none' }}>
        <ManagedSimpleChoiceInput
          formObj={formObj}
          name={`${name}.${index}.data.choice`}
          options={enumToAutocompleteOptions(EmployerContributionSetChoice)}
          customRules={{
            atLeastOneAmountRequired,
          }}
        />
        <ManagedCurrencyInput
          name={`${name}.${index}.data.employerContributionAmount`}
          formObj={formObj}
          minValue={0}
          maxValue={MAX_WORKPLACE_CONTRIBUTION_AMOUNT}
        />
        <ManagedCurrencyInput
          name={`${name}.${index}.data.employeeContributionAmount`}
          formObj={formObj}
          minValue={0}
          maxValue={MAX_WORKPLACE_CONTRIBUTION_AMOUNT}
        />
      </View>
      <DataTable.Row onPress={bulkSelectMode
          ? inOptOutWindow
            ? undefined
            : selected
              ? unselectRecord
              : selectRecord
            : () => setShowEditModal(true)}
        style={{ backgroundColor }}  
      >
        <DataTable.Cell style={{ flex: 1 }}>
          <View style={localStyles.cellWrap}>
            <View style={{ width: Sizing.x40 }}>
              {
                bulkSelectMode
                  ? inOptOutWindow ? <></> : <Checkbox.Android
                      color={Colors.brand.purple1}
                      uncheckedColor={Colors.brand.purple1}
                      status={selected ? 'checked' : 'unchecked'}
                    />
                  : <MaterialCommunityIcons
                      name={iconName}
                      size={Sizing.x25}
                      color={isNilReturn ? Colors.neutral.s200
                        : needsEdit ? Colors.brand.red2
                        : amountsAreNew ? Colors.brand.purple1
                        : undefined
                      } />
              }
            </View>
            <Paragraph style={{
              textAlign: 'left',
              color: isNilReturn ? Colors.neutral.s200 : undefined,
            }}>{`${firstName} ${surname}`}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={localStyles.cellWrap}>
            <Paragraph style={{
              textAlign: 'left',
              color: isNilReturn ? Colors.neutral.s200 : undefined,
            }}>{formatNationalInsuranceNumber(nationalInsuranceNo)}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell> 
        <View style={{
          ...localStyles.cellWrap,
          justifyContent: 'flex-end',
        }}>
            <Paragraph style={{
              textAlign: 'right',
              color,
              fontWeight,
            }}>
              {
                choice === EmployerContributionSetChoice.NIL ? `---`
                : needsEdit ? ``
                : formatCurrencyAmount(employerContributionAmount, 2)
              }
            </Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={{
            ...localStyles.cellWrap,
            justifyContent: 'flex-end',
          }}>
            <Paragraph style={{
              textAlign: 'right',
              color,
              fontWeight,
            }}>
              {
                choice === EmployerContributionSetChoice.NIL ? `---`
                : needsEdit ? ``
                : formatCurrencyAmount(employeeContributionAmount, 2)
              }
            </Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={{
            ...localStyles.cellWrap,
            justifyContent: 'space-between',
          }}>
            <Paragraph style={{
              textAlign: 'left',
              color,
              fontWeight,
            }}>
              {
                inOptOutWindow ? `Within Opt Out Window`
                : isNilReturn ? `No contributions`
                : needsEdit ? `Please enter initial amounts`
                : amountsAreNew && hasLastAmounts ? `Updated amounts`
                : ``
              }
            </Paragraph>
            {
              inOptOutWindow ? <></> : <MaterialCommunityIcons name={'square-edit-outline'} size={Sizing.x20} color={needsEdit ? Colors.brand.red2 : Colors.brand.purple1}/>
            }            
          </View>
        </DataTable.Cell>
      </DataTable.Row>
      {
        showEditModal ?
          <Portal>
            <EmployerContributionEditDialog
              visible={showEditModal}
              onClose={() => setShowEditModal(false)}
              onSave={handleEdit}
              contributionRecord={contributionRecord}
            />
          </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  cellWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Sizing.x5,
    flex: 1,
  },
})


