import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'

export const RetirementIncomeSetupIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const isLoading = clientIsLoading || spouseIsLoading || incomesIsLoading
  const error: any = clientError || incomesError

  const hasLinkedSpouse = spouse && spouse?.userId
  const hasOwnIncomes = client && incomes ? incomes.filter(income => { return income?.clientId === client?.id}).length : false

  const next = () => {
    rootNavigate('RetirementIncomeSetupProcessStack', route?.params)
  }
  
  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={clientError ? refetchClient : refetchIncomes}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      cancelButtonText={fromOnboarding ? 'Back to Tasks' : 'Back To Dashboard'}
      illustrationFilename={'capture_retirement_income.png'}
      headline={hasLinkedSpouse && hasOwnIncomes ? `Review incomes in retirement` : `Capture your incomes in retirement`}
      subHeading={`Incomes such as state and defined benefit pensions income immediately reduce the amount you need to save.`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_INCOMES} />}
    >
      {
        hasLinkedSpouse
          ? hasOwnIncomes
            ? <Paragraph>{`${spouse?.firstName} has already added some on your behalf, but it's best to quickly review to check they are accurate`}</Paragraph>
            : <Paragraph>{`${spouse?.firstName} has already added their retirement incomes, but you may have more you wish to add`}</Paragraph>
            : <></>
      }
    </ProcessIntroScreen>
  )
}
