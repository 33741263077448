import { Logger } from 'lib/logger'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import React, { ComponentProps } from 'react'
import { Platform, View } from 'react-native'
import { Modal, ThemeProvider } from "react-native-paper"
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Paper, Sizing } from "styles"
import { layoutStyles } from 'styles/common'

export type CardModalProps = ComponentProps<typeof Modal>
export const CardModal = (props: CardModalProps) => {

  const { children, style } = props

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  const insets = useSafeAreaInsets()
  const screenHeight = getScreenAppHeight()
  const modalHeight = getScreenAppHeight() - insets.top - insets.bottom

  const constraintStyle: any = getScreenSizeConstraints()

  return (
    <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
      <Modal
        {...props}
        contentContainerStyle={{
          ...layoutStyles.cardModalContainer,
          height: modalHeight,
          paddingHorizontal: Sizing.x10,
          backgroundColor: isWeb ? Colors.neutral.white : undefined,
          ...constraintStyle,
        }}
        theme={theme}
      >
        <View
          style={{
              height: '100%',
              borderRadius: Sizing.x10,
              backgroundColor: isWeb ? Colors.neutral.white : Colors.brand.grey4,
            }
          }
          >
            {children}
        </View>
      </Modal>
    </ThemeProvider>
  )
}
