import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from "store/dto/account.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from "store/tempDataSlice"
import { SchemeEnrolmentSetContributionRequestAdd_00_Intro, SchemeEnrolmentSetContributionRequestAdd_01_Amount, SchemeEnrolmentSetContributionRequestAdd_02_Confirm, SchemeEnrolmentSetContributionRequestAdd_99_Result } from "./Screens"

export type SchemeEnrolmentSetContributionRequestAddProcessStackProps = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  onDismiss: any
  resultHandlerFunction?: any  
}

export const SchemeEnrolmentSetContributionRequestAddProcessStack = (props: SchemeEnrolmentSetContributionRequestAddProcessStackProps) => {
  const { enrolment, onDismiss, resultHandlerFunction } = props

  const dispatch = useAppDispatch()

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingSchemeEnrolmentRequest(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={!!enrolment}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Discard this request?`}
      processTitle='Request Contribution Amount'
      initialRouteName='Intro'
      initialParams={{
        onDismiss,
        resultHandlerFunction,
        enrolment,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Request',
          component: SchemeEnrolmentSetContributionRequestAdd_00_Intro,
        },
        {
          navigationId: 'Amount',
          title: 'Amount',
          component: SchemeEnrolmentSetContributionRequestAdd_01_Amount,
        },
        {
          navigationId: 'Confirm',
          title: 'Confirm',
          component: SchemeEnrolmentSetContributionRequestAdd_02_Confirm,
        },
        {
          navigationId: 'Results',
          title: '',
          component: SchemeEnrolmentSetContributionRequestAdd_99_Result,
        },
      ]}
    />
  )
}

