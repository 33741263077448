import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useLazyGetAssetsQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminAssetDataId, setShowAdminAddAssetVisible } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { AdminAssetListItem } from '../Components/AdminAssetListItem'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminAssetsListScreen = () => {
  const [getAssetsQuery, { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching }] = useLazyGetAssetsQuery()
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowAssetDetails = (id: string) => {
    dispatch(setCurrentAdminAssetDataId(id))
  }

  const assetsList = () => {
    let all = []
    if (assets) {
      assets.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <AdminAssetListItem
        asset={item}
        onPressFunction={() => handleShowAssetDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{ search: string, isin: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  const { handleSubmit, reset } = formObj

  const onSubmit = attributes => {
    getAssetsQuery(attributes)
  }

  const isLoading = assetsIsLoading || assetsIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const handleAddAsset = () => {
    dispatch(setShowAdminAddAssetVisible(true))
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      isin: '',
    })
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:investmentChoice']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding assets...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for asset'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of name or asset manager'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedTextInput
                      name={'isin'}
                      label={'ISIN'}
                      autoFocus={false}
                      formObj={formObj}
                      placeholder={'Enter full ISIN'}
                      blurOnSubmit={false}
                      autoCapitalize={'characters'}
                      rules={{
                        pattern: {
                          value: /^[A-Z]{2}[0-9A-Z]{9}[0-9]{1}$/,
                          message: "Invalid ISIN"
                        }
                      }} />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
              >
                <OptionalSectionList
                  noRefresh={true}
                  sections={assetsList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for an asset`}
                />
              </ScrollView>
              <FooterButton
                onPress={handleAddAsset}
              >
                {'Add New Asset'}
              </FooterButton>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
