import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminUsersListScreen } from './Screens/AdminUsersListScreen'

export const AdminUserSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Users'}
    >
      <AdminUsersListScreen />
    </SectionSimpleContainer>
  )
}

