import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getGroupSchemeJobListInfo } from 'lib/groupSchemeJobHelpers'
import React from 'react'
import { Checkbox } from 'react-native-paper'
import { GroupSchemeJobDto, GroupSchemeJobStatus } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type EmployerContributionListItemProp = {
  job: GroupSchemeJobDto
  bulkSelectMode: boolean
  selected: boolean
  onPressFunction?: () => void
}
export const EmployerContributionListItem = (props: EmployerContributionListItemProp) => {
  const { job, bulkSelectMode, selected, onPressFunction } = props
  
  const { colors: themeColors } = Paper.useAppTheme()

  const { iconName, description, formatString, uploadedByString, message } = getGroupSchemeJobListInfo(job, false)
  const isSelectable = job?.status === GroupSchemeJobStatus.COMPLETED

  const titleRightStyle = {
    color: themeColors.primary
  }

  return (
    <DetailedListItem
      selected={isSelectable && selected}
      icon={isSelectable && bulkSelectMode
        ? <Checkbox.Android
            color={themeColors.accent}
            uncheckedColor={themeColors.primary}
            status={selected ? 'checked' : 'unchecked'}
          />
        : <MaterialCommunityIcons name={iconName as any} size={Sizing.x25} color={themeColors.primary} />
      }
      onPress={onPressFunction}
      title={description}
      titleRight={formatString}
      titleRightStyle={titleRightStyle}
      subTitle={uploadedByString}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{message}</Text>}
    />
  )
}
