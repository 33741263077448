import { scaleNormalizer } from "lib/scaleHelpers"

type Layout =
  | "x0"
  | "x1"
  | "x2"
  | "x3"
  | "x5"
  | "x6"
  | "x7"
  | "x10"
  | "x12"
  | "x15"
  | "x20"
  | "x25"
  | "x30"
  | "x40"
  | "x50"
  | "x60"
  | "x70"
  | "x80"
  | "x90"
  | "x100"
  | "x110"
  | "x120"
  | "x130"
  | "x140"
  | "x150"
  | "x200"
  | "x300"
  | "x350"
  | "x400"
  | "x500"
  | "x600"
export const layout: Record<Layout, number> = {
  x0: scaleNormalizer(0),
  x1: scaleNormalizer(1),
  x2: scaleNormalizer(2),
  x3: scaleNormalizer(3),
  x5: scaleNormalizer(5),
  x6: scaleNormalizer(6),
  x7: scaleNormalizer(7),
  x10: scaleNormalizer(10),
  x12: scaleNormalizer(12),
  x15: scaleNormalizer(15),
  x20: scaleNormalizer(20),
  x25: scaleNormalizer(25),
  x30: scaleNormalizer(30),
  x40: scaleNormalizer(40),
  x50: scaleNormalizer(50),
  x60: scaleNormalizer(60),
  x70: scaleNormalizer(70),
  x80: scaleNormalizer(80),
  x90: scaleNormalizer(90),
  x100: scaleNormalizer(100),
  x110: scaleNormalizer(110),
  x120: scaleNormalizer(120),
  x130: scaleNormalizer(130),
  x140: scaleNormalizer(140),
  x150: scaleNormalizer(150),
  x200: scaleNormalizer(200),
  x300: scaleNormalizer(300),
  x350: scaleNormalizer(350),
  x400: scaleNormalizer(400),
  x500: scaleNormalizer(500),
  x600: scaleNormalizer(600),
}

export const x0 = layout.x0
export const x1 = layout.x1
export const x2 = layout.x2
export const x3 = layout.x3
export const x5 = layout.x5
export const x6 = layout.x6
export const x7 = layout.x7
export const x10 = layout.x10
export const x12 = layout.x12
export const x15 = layout.x15
export const x20 = layout.x20
export const x25 = layout.x25
export const x30 = layout.x30
export const x40 = layout.x40
export const x50 = layout.x50
export const x60 = layout.x60
export const x70 = layout.x70
export const x80 = layout.x80
export const x90 = layout.x90
export const x100 = layout.x100
export const x110 = layout.x110
export const x120 = layout.x120
export const x130 = layout.x130
export const x140 = layout.x140
export const x150 = layout.x150
export const x200 = layout.x200
export const x300 = layout.x300
export const x350 = layout.x350
export const x400 = layout.x400
export const x500 = layout.x500
export const x600 = layout.x600
