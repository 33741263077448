import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatUkDate } from 'lib/dateHelpers'
import { isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import React from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeStatusRequestAdd_02_Confirm = ({ route, navigation }) => {
  const { nextScreen, onDismiss, enrolment, requestType }: { nextScreen: string, onDismiss: any, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, requestType: GroupSchemeRequestType } = route?.params || {}

  const dispatch = useAppDispatch()

  const withinOptOutWindow = isWithinOptOutWindow(enrolment)

  const next = () => {
    dispatch(updateWorkingSchemeEnrolmentRequest({
      requestType,
      data: {},
    }))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={enrolment.isVerified ? `Confirm & Finish` : `Continue`}
      buttonAction={next}
      enableButton={true}
      headline={`Ok, here's what we've got`}
      subHeading={`Please check the details below and confirm`}
    >
      <UnborderedTable
        data={[
          {
            label: requestType === GroupSchemeRequestType.OPT_OUT
              ? `Decision`
              : `Request Type`,
            value: requestType === GroupSchemeRequestType.OPT_IN
            ? `Opt In`
            : requestType === GroupSchemeRequestType.OPT_OUT
            ? `Opt Out`
            : `Leave Scheme`
          },
          {
            label: requestType === GroupSchemeRequestType.OPT_OUT
              ? `Decision Date`
              : `Request Date`,
            value: formatUkDate(new Date())
          },
        ]}
      />
      <Paragraph>{`Depending on your employment circumstances, your employer may need to contact you about this request.`}</Paragraph>
    </ModalProcessScreen>
  )
}
