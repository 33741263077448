import React from 'react'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { MAX_ASSET_CURRENT_VALUE } from 'lib/constants'
import { ampli } from 'src/ampli'

export const RetirementAssetsAddAsset_10_Value = ({ route, navigation }) => {
  const { nextScreen, forTransfer }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ currentValue: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      currentValue: workingRetirementAssetData?.currentValue,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset(attributes))

    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_value',
        isRetransfer: false,
        outcome: 'success',
      })
    }
    
    navigation.navigate(nextScreen)
  }

  const isPension = [RetirementAssetType.PERSONAL, RetirementAssetType.WORKPLACE].includes(workingRetirementAssetData?.assetType)
  const isSavings = [RetirementAssetType.ISA, RetirementAssetType.INVESTMENT].includes(workingRetirementAssetData?.assetType)

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={isPension
        ? `Last up: what's the current pension value?`
        : isSavings ? `What's the current value?`
        : `What will it be worth when you retire, in today's terms?`
      }
      subHeading={isPension || isSavings
        ? `It's okay if you don't know the exact amount - just give an estimate`
        : `We recommend entering the most conservative value you expect`
      }
    >
      <ManagedCurrencyInput
        name={'currentValue'}
        autoFocus={true}
        returnKeyType="next"

        formObj={formObj}
        // label={'Current Value'}
        placeholder={'Enter current value'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={0}
        maxValue={MAX_ASSET_CURRENT_VALUE}
        blurOnSubmit={false}
      />
    </ModalProcessScreen>
  )
}
