type AnyDataType =
  | string
  | boolean
  | number
  | null
  | undefined
  | AnyDataArray
  | AnyDataObject;
interface AnyDataArray extends Array<AnyDataType> {}
interface AnyDataObject extends Record<string, AnyDataType> {}

export interface BranchParams {
  "~channel"?: string;
  "~feature"?: string;
  "~tags"?: string[];
  "~campaign"?: string;
  "~stage"?: string;
  "~creation_source"?: number;
  "~referring_link"?: string;
  "~id"?: string;
  "+match_guaranteed": boolean;
  "+referrer"?: string;
  "+phone_number"?: string;
  "+is_first_session": boolean;
  "+clicked_branch_link": boolean;
  "+click_timestamp"?: number;
  "+url"?: string;
  "+rn_cached_initial_event"?: boolean;
  [data: string]: AnyDataType;
}

export type BranchEventParams = {
  transactionID?: string;
  currency?: string;
  revenue?: string | number;
  shipping?: string | number;
  tax?: string | number;
  coupon?: string;
  affiliation?: string;
  description?: string;
  searchQuery?: string;
  alias?: string;
  customData?: Record<string, string>;
};



export type ATTAuthorizationStatus =
  | "authorized"
  | "denied"
  | "undetermined"
  | "restricted";

export interface BranchSubscriptionEventError extends BranchSubscriptionEventBase {
  error: string;
}
export interface BranchSubscriptionEventSuccess extends BranchSubscriptionEventBase {
  error: null | undefined;
  params: BranchParams;
}
export type BranchSubscriptionEvent =
  | BranchSubscriptionEventError
  | BranchSubscriptionEventSuccess;
export interface BranchOpenStartEvent {
  uri: string;
  cachedInitialEvent?: boolean;
}

export interface BranchShareSheetOptions {
  messageHeader?: string;
  messageBody?: string;
  emailSubject?: string;
  title?: string;
  text?: string;
}

export interface BranchLinkProperties {
  alias?: string;
  campaign?: string;
  feature?: string;
  channel?: string;
  stage?: string;
  tags?: string[];
}

export interface BranchLinkControlParams {
  $fallback_url?: string;
  $desktop_url?: string;
  $ios_url?: string;
  $ipad_url?: string;
  $android_url?: string;
  $samsung_url?: string;
}

interface BranchSubscriptionEventBase {
  params: BranchParams | undefined;
  error: string | null | undefined;
  uri: string | undefined;
}
type BranchSubscribeCallback = (event: BranchSubscriptionEvent) => void;
interface BranchSubscribeOptions {
  onOpenComplete: BranchSubscribeCallback;
  onOpenStart?: (event: BranchOpenStartEvent) => void;
}
type BranchUnsubscribe = () => void;
type BranchSubscribe = (
  options: BranchSubscribeCallback | BranchSubscribeOptions
) => BranchUnsubscribe;

interface BranchUniversalObjectOptions {
  locallyIndex?: boolean;
  publiclyIndex?: boolean;
  canonicalUrl?: string;
  title?: string;
  contentDescription?: string;
  contentImageUrl?: string;
  contentMetadata?: {
    price?: number | string;
    contentSchema?: any; // TODO
    quantity?: number;
    sku?: string;
    productName?: string;
    productBrand?: string;
    productCategory?: any; // TODO
    productVariant?: string;
    condition?: any; // TODO
    currency?: string;
    ratingAverage?: number;
    ratingCount?: number;
    ratingMax?: number;
    addressStreet?: string;
    addressCity?: string;
    addressRegion?: string;
    addressCountry?: string;
    addressPostalCode?: string;
    latitude?: number;
    longitude?: number;
    imageCaptions?: string[];
    customMetadata?: Record<string, string>;
  };
}
interface BranchShareSuccess {
  completed: true;
  error: null;
  channel: string;
}

interface BranchShareFailure {
  completed: false;
  error: null | string;
  channel: null;
}

interface BranchUniversalObject {
  ident: string;
  showShareSheet: (
    shareOptions?: BranchShareSheetOptions,
    linkProperties?: BranchLinkProperties,
    controlParams?: BranchLinkControlParams
  ) => Promise<BranchShareSuccess | BranchShareFailure>;
  generateShortUrl: (
    linkProperties?: BranchLinkProperties,
    controlParams?: BranchLinkControlParams
  ) => Promise<{ url: string }>;
  logEvent: (eventName: string, params?: BranchEventParams) => Promise<null>;
  release: () => void;
}

interface BranchQRCodeSettings {
  codeColor?: string;
  backgroundColor?: string;
  centerLogo?: string;
  width?: number;
  margin?: number;
  imageFormat?: string;
}

interface Branch {
  subscribe: BranchSubscribe;
  initSessionTtl?: number;
  skipCachedEvents: () => void;
  disableTracking: (disable: boolean) => void;
  isTrackingDisabled: boolean;
  getLatestReferringParams: (synchronous?: boolean) => Promise<BranchParams>;
  getFirstReferringParams: () => Promise<BranchParams>;
  lastAttributedTouchData: (
    attributionWindow?: number
  ) => Promise<BranchParams>;
  setIdentity: (identity: string) => void;
  setIdentityAsync: (identity: string) => Promise<BranchParams>;
  setRequestMetadata: (key: string, value: string) => void;
  addFacebookPartnerParameter: (name: string, value: string) => void;
  clearPartnerParameters: () => void;
  logout: () => void;
  openURL: (url: string, options?: { newActivity?: boolean }) => void;
  createBranchUniversalObject: (
    identifier: string,
    options: BranchUniversalObjectOptions
  ) => Promise<BranchUniversalObject>;
  handleATTAuthorizationStatus: (
    ATTAuthorizationStatus: ATTAuthorizationStatus
  ) => void;
  getBranchQRCode: (
    settings: BranchQRCodeSettings,
    branchUniversalObject: BranchUniversalObjectOptions,
    linkProperties: BranchLinkProperties,
    controlParams: BranchLinkControlParams
  ) => Promise<string>;
  setPreInstallCampaign: (campaign: string) => void;
  setPreInstallPartner: (partner: string) => void;
}

const branch: Branch = {
  subscribe: function (options) { return () => { } },
  initSessionTtl: 0,
  skipCachedEvents: function () { return () => { } },
  disableTracking: function (disable: boolean) { return () => { } },
  isTrackingDisabled: false,
  getLatestReferringParams: async function (synchronous?: boolean): Promise<BranchParams> {
    return {
      "+match_guaranteed": false,
      "+is_first_session": false,
      "+clicked_branch_link": false,
    }
  },
  getFirstReferringParams: async function (): Promise<BranchParams> {
    return {
      "+match_guaranteed": false,
      "+is_first_session": false,
      "+clicked_branch_link": false,
    }
  },
  lastAttributedTouchData: async function (attributionWindow?: number): Promise<BranchParams> {
    return {
      "+match_guaranteed": false,
      "+is_first_session": false,
      "+clicked_branch_link": false,
    }
  },
  setIdentity: function (identity: string) { },
  setIdentityAsync: async function (identity: string): Promise<BranchParams> {
    return {
      "+match_guaranteed": false,
      "+is_first_session": false,
      "+clicked_branch_link": false,
    }
  },
  setRequestMetadata: function (key: string, value: string) { },
  addFacebookPartnerParameter: function (name: string, value: string) { },
  clearPartnerParameters: function () { },
  logout: function () { },
  openURL: function (url: string, options?: { newActivity?: boolean }) { },
  createBranchUniversalObject: async function (
    identifier: string,
    options: BranchUniversalObjectOptions
  ): Promise<BranchUniversalObject> {
    return {
      ident: 'dummy',
      showShareSheet: async function (
        shareOptions?: BranchShareSheetOptions,
        linkProperties?: BranchLinkProperties,
        controlParams?: BranchLinkControlParams
      ): Promise<BranchShareSuccess | BranchShareFailure> {
        return {
          completed: true,
          error: null,
          channel: 'dummy',
        }
      },
      generateShortUrl: async function (
        linkProperties?: BranchLinkProperties,
        controlParams?: BranchLinkControlParams
      ): Promise<{ url: string }> {
        return {
          url: 'dummy'
        }
      },
      logEvent: async function (eventName: string, params?: BranchEventParams): Promise<null> {
        return null
      },
      release: function () {}
    }
  },
  handleATTAuthorizationStatus: function (
    ATTAuthorizationStatus: ATTAuthorizationStatus
  ) {},
  getBranchQRCode: async function (
    settings: BranchQRCodeSettings,
    branchUniversalObject: BranchUniversalObjectOptions,
    linkProperties: BranchLinkProperties,
    controlParams: BranchLinkControlParams
  ): Promise<string> {
    return 'dummy'
  },
  setPreInstallCampaign: function (campaign: string) {},
  setPreInstallPartner: function (partner: string) {},
}

export default branch