import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { ComponentProps } from 'react'
import { Linking } from 'react-native'
import { Sizing, Paper } from 'styles'
import { Button } from './Button'

type TelephoneLinkButtonProps = ComponentProps<typeof Button> & {
  telephone: string
}

export const TelephoneLinkButton = (props: TelephoneLinkButtonProps) => {
  const { telephone, children } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const openPhone = () => { 
      Linking.openURL(`tel:${telephone}`)
  }

  return (
    <>
      <Button
        {...props}
        labelStyle={{
          textDecorationLine: 'underline',
        }}
        mode='text'
        icon={() => <MaterialCommunityIcons name={'phone-outline'} size={Sizing.x20} color={themeColors.accent} />}
        onPress={openPhone}
      >{children}</Button>
    </>
  )
}