import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { Button } from 'react-native-paper'
import { useAddGroupPortfolioMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddGroupPortfolioVisible, showAdminAddGroupPortfolioVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddGroupPortfoliosModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
  //Show required fields on load
  useEffect(() => {
    trigger()
  }, [])


    const dispatch = useAppDispatch()

    const [addGroupPortfolioMutation, { data: addedGroupPortfolio, isLoading: addGroupPortfolioIsLoading, error: addGroupPortfolioError, reset: addGroupPortfolioReset }] = useAddGroupPortfolioMutation()
  
    const formObj = useForm<{
      name: string,
      description: string,
      investmentObjectives: string,
      manager: string,
      allowedAsDefaultInvestment: boolean,
      riskRating: number,
      riskRatingScaleMax: number,
      logo: string,
      priority: number,
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: '',
        description: '',
        investmentObjectives: '',
        manager: 'Pension Jar Limited',
        allowedAsDefaultInvestment: undefined,
        riskRating: undefined,
        riskRatingScaleMax: 7,
        logo: '',
        priority: undefined,
      },
    })
    //Form refs for focussing
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
    const investmentObjectivesRef = useRef(null)
    const priorityRef = useRef(null)
    const managerRef = useRef(null)
    const riskRatingRef = useRef(null)
    const riskRatingScaleMaxRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
    const riskRatingScaleMax = watch('riskRatingScaleMax')
  
    const onSubmit = async attributes => {
      addGroupPortfolioMutation({
        ...attributes,
        //TODO - PA-1720 - build UI to manage these
        assetAllocation: [],
        cashAllocation: 1,
      })
    }
  
    useEffect(() => {
      if (addedGroupPortfolio) {
        close()
      }
    }, [addedGroupPortfolio])
  
    const close = () => {
      dispatch(setShowAdminAddGroupPortfolioVisible(false))
    }
  
    const isLoading = addGroupPortfolioIsLoading
    const error: any = addGroupPortfolioError
    
    const allowedAsDefaultInvestmentOptions: ManagedSimpleChoiceItem[] = [
      {
        value: true,
        label: 'Available as default',
      },
      {
        value: false,
        label: `Not Available as default`,
      },
    ]
  
    return (
      <ModalEditScreen
        formTitle={'Add New Group Portfolio'}
        onDismiss={() => dispatch(setShowAdminAddGroupPortfolioVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new group portfolio?'}
        error={error}
        errorTryAgain={addGroupPortfolioError ? handleSubmit(onSubmit) : undefined}
        errorCancel={addGroupPortfolioError ? addGroupPortfolioReset : close}
        isLoading={isLoading}
        loadingMessage={['Saving group portfolio...']}
        buttonTitle={'Create Group Portfolio'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name visible to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => descriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 30,
          }}/>
        <ManagedTextInput
          ref={descriptionRef}
          name={'description'}
          formObj={formObj}
          label={'Description'}
          placeholder={'Description for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => investmentObjectivesRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 200,
          }}/>
        <ManagedTextInput
          ref={investmentObjectivesRef}
          name={'investmentObjectives'}
          formObj={formObj}
          label={'Investment Objectives'}
          placeholder={'Investment Objectives for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => priorityRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 300,
          }}/>
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
        <Subheading>{'Available as default for Group Schemes?'}</Subheading>
        <ManagedSimpleChoiceInput
          name={'allowedAsDefaultInvestment'}
          formObj={formObj}
          options={allowedAsDefaultInvestmentOptions}
          required={true}
        />
        <Paragraph>{'Priority (for display order)'}</Paragraph>
        <ManagedIntegerInput
          ref={priorityRef}
          name={'priority'}
          formObj={formObj}
          label={'Priority'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => managerRef.current?.focus()}
          required={true}
          minValue={1}
          maxValue={1000}
        />
        <ManagedTextInput
          ref={managerRef}
          name={'manager'}
          formObj={formObj}
          label={'Portfolio Manager'}
          placeholder={'Name of company managing the portfolio'}
          returnKeyType={'done'}
          blurOnSubmit={true}
          submitHandler={() => riskRatingRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 50,
        }}/>
        <Paragraph>{'Risk Rating'}</Paragraph>
        <ManagedIntegerInput
          ref={riskRatingRef}
          name={'riskRating'}
          formObj={formObj}
          label={'Risk Rating'}
          submitHandler={() => riskRatingScaleMaxRef.current?.focus()}
          returnKeyType={'next'}
          blurOnSubmit={false}
          required={true}
          minValue={1}
          maxValue={riskRatingScaleMax}
        />
        <Paragraph>{'Risk Rating Out Of'}</Paragraph>
        <ManagedIntegerInput
          ref={riskRatingScaleMaxRef}
          name={'riskRatingScaleMax'}
          formObj={formObj}
          label={'Risk Rating Out Of'}
          returnKeyType={'done'}
          blurOnSubmit={true}
          required={true}
          minValue={5}
          maxValue={10}
        />
      </ModalEditScreen>
    )
  }
    