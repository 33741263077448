import { BulkRetransfer_01_Assets, BulkRetransfer_02_Confirmation, BulkRetransfer_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'

export const BulkRetransferProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='BulkRetransfer'
      processTitle='Resolve Transfers'
      previousRoute='BulkRetransferIntroScreen'
      nextRoute='BulkRetransferHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Assets',
          component: BulkRetransfer_01_Assets,
        },
        {
          navigationId: 'Confirmation',
          component: BulkRetransfer_02_Confirmation,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: BulkRetransfer_99_Result,
      }}
    />
  )
}
