import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { BudgetTable } from 'components/Utility/BudgetTable'
import { BudgetInfoButton } from 'features/ModalProcesses/RetirementBudgetChange/Components/BudgetInfoButton'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { sumBy, truncate } from 'lodash'
import React from 'react'
import { Image } from 'react-native'
import { View } from 'react-native'
import { BudgetDto, BudgetLevel, BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { RetirementProfileCategoryBudget, RetirementProfileDto } from 'store/dto/retirement-profile.dto'
import { Colors, Flex, Sizing, Typography, Paper } from 'styles'
import { layoutStyles } from 'styles/common'
import { AppIllustration } from 'components/Utility/AppIllustration'

interface RetirementBudgetTabProps {
  netTotalBudget: BudgetDto
  categoryBudgets: BudgetDto[]
  initialLevel: BudgetLevelIdentifier
  stateListenerFunction: any
}

export const RetirementBudgetTab = (props: RetirementBudgetTabProps) => {
  const screenWidth = getScreenAppWidth()
  const isWeb = platformIsWeb()
  
  const useHorizontalLayout = isWeb && screenWidth >= 600

  let { netTotalBudget, categoryBudgets, stateListenerFunction, initialLevel } = props
  const { colors: themeColors } = Paper.useAppTheme()
  const Tab = createMaterialTopTabNavigator()

  const getBaseExpensesForLevel = (level: BudgetLevel): RetirementProfileCategoryBudget[] => {
    return categoryBudgets.map(category => {
      const categoryLevel = category.levels.find(catLevel => {
        return catLevel.id === level.id
      })
      return {
        id: category.id,
        selectedLevel: categoryLevel.id,
        selectedCost: categoryLevel.value
      }
    })
  }

  
  function BudgetButton(props: { level: any })  {
    const { level } = props || {}
    
    const baseCosts = getBaseExpensesForLevel(level)
    const netMonthlyTotal = sumBy(baseCosts, 'selectedCost') / 12

    return (
      <View style={{
        alignSelf: useHorizontalLayout ? 'flex-start' : 'center',
        flexDirection: useHorizontalLayout ? 'row' : undefined,
        justifyContent: useHorizontalLayout ? 'flex-start' : undefined,
        marginLeft: useHorizontalLayout ? Sizing.x10 * -1 : undefined, //Hack to bring button with icon over to the left
      }}>
        <BudgetInfoButton

          modalTitle={level?.id}
          modalContent={
            <View style={layoutStyles.cardModalContentContainer}>
              <View style={{ paddingBottom: Sizing.x20 }}>
                <Headline>{level?.name} Lifestyle</Headline>
              </View>
              <Subheading style={{textAlign: 'left'}}>{level?.description}</Subheading>

              <BudgetTable
                rows={categoryBudgets.map(budget => {
                  return {
                    budget,
                  }
                })}
                expenses={baseCosts}
                netMonthlyTotal={netMonthlyTotal}
              />
            </View>
          }
        >
          {'Lifestyle Details'}
        </BudgetInfoButton>
      </View>
    )
  }

  
  function BudgetImage(props: { level: BudgetLevel }) {
    const { level } = props || {}
    const identifier = level?.id
    const illustrationFilename =
      identifier === BudgetLevelIdentifier.MINIMUM ? 'budget_minimum.png'
      : identifier === BudgetLevelIdentifier.MODERATE ? 'budget_moderate.png'
      : identifier === BudgetLevelIdentifier.COMFORTABLE ? 'budget_comfortable.png'
      : 'spare_cash.png'

    return (
      <View style={{
        flexDirection: 'row',
        justifyContent: useHorizontalLayout ? 'flex-end' : 'center',
      }}>
        <AppIllustration
          filename={illustrationFilename}
          style={{
            width: useHorizontalLayout ? scaleNormalizer(150) : scaleNormalizer(100),
            height: useHorizontalLayout ? scaleNormalizer(150) : scaleNormalizer(100),
            alignSelf: useHorizontalLayout ? 'flex-end' : 'center',
            paddingRight: useHorizontalLayout ? Sizing.x30 : undefined,
          }}
          resizeMode={'contain'}
        />
      </View>
    )
  }

  const LevelTabScreen = ({ route, navigation }) => {
    const { level } = route.params

    return (
      <>
        { useHorizontalLayout
          ?
            <View style={{
              paddingTop: Sizing.x30,
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              alignSelf: 'center',
            }}>
              <View style={{
                ...Flex.row.between,
              }}>
                <View style={{
                  ...Flex.column.center,
                  width: '60%',
                }}>
                  <Subheading style={{ textAlign: 'left', paddingBottom: Sizing.x10 }} >{level.summary}</Subheading>
                  <Headline style={{ textAlign: 'left', paddingBottom: Sizing.x10, color: themeColors.accent }} >{formatCurrencyAmount(level.value / 12)} / month</Headline>
                  <Paragraph numberOfLines={1} style={{ textAlign: 'left', fontSize: scaleNormalizer(12) }} >{level.description}</Paragraph>
                  {/* <Subheading style={{ paddingBottom: Sizing.x10 }} >({formatCurrencyAmount(level.value)} / year)</Subheading> */}
                  <BudgetButton level={level} />
                </View>
                <View style={{
                  ...Flex.column.start,
                  width: '40%',
                }}>
                  <BudgetImage level={level}/>
                </View>
              </View>
            </View>
          :
            <>
              <View style={{ ...Flex.column.start, alignItems: 'center', paddingTop: Sizing.x20 }}>
                <BudgetImage level={level}/>
                <Subheading style={{ paddingBottom: Sizing.x10 }} >{level.summary}</Subheading>
                <Headline style={{ paddingBottom: Sizing.x10, color:  themeColors.accent }} >{formatCurrencyAmount(level.value / 12)} / month</Headline>
                <Paragraph numberOfLines={1} style={{ fontSize: scaleNormalizer(12) }} >{level.description}</Paragraph>
                {/* <Subheading style={{ paddingBottom: Sizing.x10 }} >({formatCurrencyAmount(level.value)} / year)</Subheading> */}
                <BudgetButton level={level} />
              </View>
            </>
        }
      </>
    )
  }
  
  return (
    <View style={{ height: scaleNormalizer(340) }}>
      <Tab.Navigator
        screenListeners={{
          state: (e) => stateListenerFunction(e.data)
        }}
        initialRouteName={initialLevel}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          swipeEnabled: true,
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarLabelStyle: {
            ...Typography.fontSize.x10,
          },
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarItemStyle: {
            paddingHorizontal: Sizing.x0,
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }}
      >
        {
          netTotalBudget ? netTotalBudget.levels.map((level, idx) => {
            return (
              <Tab.Screen key={idx} name={level.id} component={LevelTabScreen} initialParams={{ level }} />
            )
          }) : <></>
        }
      </Tab.Navigator>
    </View>
  )
}

