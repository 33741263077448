import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from 'lib/appInfoHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { AmlStatus } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'
import { Colors } from 'styles'

export const Contributions_01_ContributionSource = ({ route, navigation }) => {
  const { nextScreen } = route.params

  const dispatch = useAppDispatch()
  
  const { data: client, isLoading, error, refetch } = useGetMeQuery()

  const businessBlocked = client?.contributionBusinessAmlStatus === AmlStatus.FAIL

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const formObj = useForm<{ contributionSource: ContributionSource }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contributionSource: workingSuggestionData?.contributionSource || businessBlocked ? ContributionSource.PERSONAL : undefined,
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const sourceOptions: ManagedSimpleChoiceItem[] = [
    {
      value: ContributionSource.PERSONAL,
      label: `Your personal funds`,
    },
    {
      value: ContributionSource.EMPLOYER,
      label: 'Your limited company',
      disabled: businessBlocked,
    }
  ]

  const onSubmit = (attributes) => {
    dispatch(updateWorkingSuggestion(attributes))
    navigation.navigate(nextScreen)
  }

  const contactSupport = async () => {
    const informationArray = getDiagnosticInformation(undefined, client)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
    ])
    let initialMessage = `----- Jarvis Limited Company Contributions -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Client limited company verification required.\n`
    initialMessage += `---------------------------------------\n`
    await composeIntercomMessage(initialMessage)
  }

  return (    
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`Before we get started...`}
      subHeading={'We just need to know from where you will make contributions'}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.CONTRIBUTION_SOURCE} />}
    >  
    <ManagedSimpleChoiceInput
      name={'contributionSource'}
      formObj={formObj}
      options={sourceOptions}
      submitHandler={handleSubmit(onSubmit)}
      required={true}
    />
    <Paragraph>{'The source of funds affects the tax handling for the money you invest.'}</Paragraph>
    {
      businessBlocked
        ? <>
            <Paragraph>{`You're not currently approved to make contributions from your limited company. Please contact support for more information.`}</Paragraph>
            <Button
              mode={'text'}
              color={Colors.brand.red3}
              onPress={contactSupport}
            >
              {'Contact Support'}
            </Button>
          </>
        : <></>
    }
    </ProcessScreen>

  )
}