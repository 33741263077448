import { scaleNormalizer } from 'lib/scaleHelpers'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Paper, Sizing } from 'styles'

interface DashboardMeterProp {
  currentAge: number
  targetAge: number
  viableAge: number
  expectedDeathAge: number
  withLabels?: boolean
}

export const DashboardMeter = (props: DashboardMeterProp) => {
  const { viableAge, currentAge, expectedDeathAge, targetAge, withLabels } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const dataList = () => {
    let ageList = []
    if (+currentAge && +expectedDeathAge) {
      for (let i = currentAge; i <= expectedDeathAge; i++) {
        ageList.push(i)
      }
    }
    return ageList
  }
  const ageData = dataList()

  const greyColor = Colors.brand.grey3
  const darkGreyColor = Colors.brand.grey2
  const redColor = '#E22323'
  const greenColor = '#2EC105'

  const meter = ageData?.map((item, idx) => {
    return (
      <View key={idx} style={{
        alignItems: "center",
        marginTop: Sizing.x20,
        position: 'relative',
        flex: 1,
      }}>
        <View style={
            //Less than target - always grey, short/long for divisible by 5
            item < targetAge
              ? [{backgroundColor: greyColor}, item % 5 === 0 ? localStyles.longVerticalLine : localStyles.shortVerticalLine]
            //Target - red if not met, otherwise green, marker length
            : item === targetAge
              ? [{backgroundColor: viableAge !== targetAge ? redColor : greenColor}, localStyles.markerVerticalLine]
            //Inbetween target and viable - always red, short/long for divisible by 5
            : item > targetAge && item < viableAge
              ? [{backgroundColor: redColor}, item % 5 === 0 ? localStyles.longVerticalLine : localStyles.shortVerticalLine]
            //Viable - always geen, marker length
            : item === viableAge
              ? [{backgroundColor: greenColor}, localStyles.markerVerticalLine]
            //Otherwise after viable - always grey, short/long for divisible by 5
            : [{backgroundColor: greyColor}, item % 5 === 0 ? localStyles.longVerticalLine : localStyles.shortVerticalLine]
            } />
        {item % 10 === 0 ? <Text style={{ marginLeft: Sizing.x20, width: Sizing.x40, color: greyColor, margin: 0, marginTop: Sizing.x10, }}>{item}</Text> : <Text style={{ opacity: 0 }}>{item}</Text>}
      </View>
    )
  })
  const viableAgeFlex = () => {
    if (viableAge !== targetAge) {
      const value = ageData?.filter((data) => {
        return data > targetAge && data <= viableAge
      })
      return value.length
    }
    return 0
  }
  const targetAgeFlex = () => {
    const value = ageData?.filter((data) => {
      return data <= targetAge
    })
    return value.length
  }

  const totalYears = ageData?.length
  const targetAgeLength = targetAgeFlex()
  const viableAgeLength = viableAgeFlex()
  const remainingPortionLength = ageData?.length - (viableAgeLength) - targetAgeLength

  const targetAgeProportion = targetAgeLength / totalYears
  const viableAgeProportion = viableAgeLength / totalYears
  const remainingPortionProportion = remainingPortionLength / totalYears

  return (
    <View>
      {
        withLabels
          ?
          <View style={{ flexDirection: 'row', paddingBottom: Sizing.x10 }}>
            <View style={{
              flex: targetAgeLength - 0.5, //Adjustment because scale is space-around the views - needs improvement
            }}>
              {
                targetAgeProportion > 0.15 //Approximate proportion needed for 'Work'
                  ? <Text numberOfLines={1} style={{ fontSize: scaleNormalizer(13), textAlign: 'center', color: darkGreyColor, paddingRight: Sizing.x5 }}>{'Work'}</Text>  
                  : <></>
              }
            </View>
            <View style={{
              flex: viableAgeLength, //Adjustment because scale is space-around the views - needs improvement
            }}>
              {
                viableAgeProportion > 0.11 //Approximate proportion needed for 'Gap'
                  ? <Text numberOfLines={1} style={{ fontSize: scaleNormalizer(13), textAlign: 'center', color: redColor, paddingRight: Sizing.x5 }}>{'Gap'}</Text>  
                  : <></>
              }
            </View>
            <View style={{
              flex: remainingPortionLength + 0.5, //Adjustment because scale is space-around the views - needs improvement
            }}>
              {
                remainingPortionProportion > 0.2 //Approximate proportion needed for 'Retire'
                  ? <Text numberOfLines={1} style={{ fontSize: scaleNormalizer(13), textAlign: 'center', color: greenColor, paddingLeft: Sizing.x5 }}>{'Retire'}</Text>  
                  : <></>
              }          
              
            </View>
          </View>
        : <></>
      }

      <View style={{ ...localStyles.barstyle, flexDirection: "row", backgroundColor: greyColor }}>
        <View style={{
          borderTopLeftRadius: Sizing.x10,
          borderBottomLeftRadius: Sizing.x10,
          flex: targetAgeLength - 0.5, //Adjustment because scale is space-around the views - needs improvement
          overflow: 'hidden',
          backgroundColor: greyColor,
        }}/>
        <View style={{
          flex: viableAgeLength,
          overflow: 'hidden',
          backgroundColor: redColor,
        }}/>
        <View style={{
          borderTopRightRadius: Sizing.x10,
          borderBottomRightRadius: Sizing.x10,
          flex: remainingPortionLength + 0.5, //Adjustment because scale is space-around the views - needs improvement
          overflow: 'hidden',
          backgroundColor: greenColor,
        }}/>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        {meter}
      </View>
    </View>
  )
}


const localStyles = StyleSheet.create({
  shortVerticalLine: {
    height: Sizing.x5,
    width: Sizing.x1,
    margin: 0,
  },
  longVerticalLine: {
    height: Sizing.x10,
    width: Sizing.x1,
    margin: 0,
  },
  markerVerticalLine: {
    height: Sizing.x30,
    width: Sizing.x2,
    // position: 'absolute',
    top: -Sizing.x20,
    marginBottom: -Sizing.x20,
  },

  // targetVerticalLine: {
  //   height: Sizing.x30,
  //   width: Sizing.x1,
  //   backgroundColor: greenColor,
  //   margin: 0,
  //   position: 'absolute',
  //   top: -Sizing.x20
  // },
  // shortRedVerticalLine: {
  //   height: Sizing.x5,
  //   width: Sizing.x1,
  //   backgroundColor: redColor,
  //   margin: 0,
  // },
  // longRedVerticalLine: {
  //   height: Sizing.x10,
  //   width: Sizing.x1,
  //   backgroundColor: redColor,
  //   margin: 0,
  // },
  // viableVerticalLine: {
  //   height: Sizing.x30,
  //   width: Sizing.x1,
  //   backgroundColor: redColor,
  //   margin: 0,
  //   position: 'absolute',
  //   top: -Sizing.x20
  // },
  barstyle: {
    padding: 0,
    margin: 0,
    borderRadius: Sizing.x10,
    width: '100%',
    height: Sizing.x20 - Sizing.x3,
  }
})
