import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { YAHOO_QUOTE_BASE_URL } from 'lib/constants'
import { formatUkDate } from 'lib/dateHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Clipboard, Image, View } from 'react-native'
import { useGetAssetCategoriesQuery, useGetAssetQuery, useUpdateAssetFinancialDataMutation, useUpdateAssetMetadataMutation, useUpdateAssetMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAssetDataId, setCurrentAdminAssetDataId, setSnackbarData } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditAssetsModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentAsset = useAppSelector(currentAdminAssetDataId)

  const currentAssetId = useAppSelector(currentAdminAssetDataId)

  const { data: asset, isLoading: assetIsLoading, error: assetError, refetch: refetchAsset } = useGetAssetQuery(currentAssetId, { skip: !currentAssetId } )
  const [updateAsset, { data: updatedAsset, isLoading: assetUpdateIsLoading, error: assetUpdateError, reset: assetUpdateReset }] = useUpdateAssetMutation()
  const [updateFinancialData, { isLoading: assetRefreshApiKeyIsLoading, error: assetRefreshApiKeyError }] = useUpdateAssetFinancialDataMutation()
  const [updateMetadata, { isLoading: assetRevokeApiKeyIsLoading, error: assetRevokeApiKeyError }] = useUpdateAssetMetadataMutation()

  const { data: assetCategories, isLoading: assetCategoriesIsLoading, error: assetCategoriesError, refetch: refetchAssetCategories } = useGetAssetCategoriesQuery()

  const formObj = useForm<{
    shortName: string
    riskRating: number
    riskRatingScaleMax: number
    isEnabled: boolean
    assetTypeName: string
    logo: string
    assetCategoryCodes: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, register, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')
  const riskRatingScaleMax = watch('riskRatingScaleMax')

  useEffect(() => {
    if (asset) {
      reset({
        shortName: asset?.shortName,
        riskRating: asset?.riskRating,
        riskRatingScaleMax: asset?.riskRatingScaleMax,
        isEnabled: asset?.isEnabled,
        assetTypeName: asset?.assetTypeName,
        logo: asset?.logo,
        assetCategoryCodes: asset ? asset?.assetCategories.map(category => {
          return category.code
        }) : [],
      })
    }
  }, [asset])

  //Form refs for focussing
  const shortNameRef = useRef(null)
  const riskRatingRef = useRef(null)
  const riskRatingScaleMaxRef = useRef(null)
  const assetTypeNameRef = useRef(null)

  const onSubmit = async attributes => {
    await updateAsset({ id: asset.id, ...attributes })
  }

  useEffect(() => {
    if (updatedAsset) {
      close()
    }
  }, [updatedAsset])

  const close = () => {
    dispatch(setCurrentAdminAssetDataId(undefined))
  }

  const handleUpdateFinancialData = () => {
    updateFinancialData(asset?.id)
  }

  const handleUpdateMetadata = () => {
    updateMetadata(asset?.id)
  }

  const isLoading = assetIsLoading ||  assetUpdateIsLoading || assetCategoriesIsLoading || assetRefreshApiKeyIsLoading || assetRevokeApiKeyIsLoading
  const error: any = assetError || assetUpdateError || assetCategoriesError

  const { colors: themeColors } = Paper.useAppTheme()

  const shortName = asset?.shortName

  const yahooUrl = `${YAHOO_QUOTE_BASE_URL}${asset?.yahooSymbol}`

  const isEnabledOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Available in Fund Choice',
    },
    {
      value: false,
      label: `Not Available in Fund Choice`,
    },
  ]

  const categoryOptions: ManagedMultipleChoiceInputOption[] = assetCategories ? assetCategories.map(assetCategory => {
    return {
      value: assetCategory.code,
      label: assetCategory.name,
    }
  }) : []

  return (
    <ModalEditScreen
      formTitle={'Edit Asset Details'}
      onDismiss={() => dispatch(setCurrentAdminAssetDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this asset?'}
      error={error}
      errorTryAgain={assetUpdateError ? handleSubmit(onSubmit) : assetError ? refetchAsset : refetchAssetCategories}
      errorCancel={assetUpdateError ? assetUpdateReset : close}
      isLoading={isLoading}
      loadingMessage={assetUpdateIsLoading ? ['Saving asset...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: asset?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: shortName,
          },
          {
            label: `ISIN`,
            value: asset?.isin,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Financial Information`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Yahoo Symbol`,
            value: asset?.yahooSymbol,
            copyableValue: true,
          },
          {
            label: `Full Name`,
            value: asset?.longName,
          },
          {
            label: `Fund Manager`,
            value: asset?.manager,
          },
          {
            label: `Track Record Reference Date`,
            value: asset?.trackRecordReferenceDate ? formatUkDate(new Date(asset?.trackRecordReferenceDate)) : '',
          },
          {
            label: `Holdings Reference Date`,
            value: asset?.holdingsReferenceDate ? formatUkDate(new Date(asset?.holdingsReferenceDate)) : '',
          },
        ]}
        noContentDivider={true}
      />
      <ExternalLinkButton
        url={yahooUrl}
      >
        {`More Information`}
      </ExternalLinkButton>
      <Button
        onPress={handleUpdateFinancialData}
        mode={'text'}
        confirmation={{
          requiresConfirmation: true,
          dialogTitle: 'Are you sure?',
          dialogContent: 'Updating the financial data will immediately publish new data from Yahoo within the Jarvis app.'
        }}
      >
        {'Update Financial Data'}
      </Button>
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Charges & Information`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `SECCL Identifier`,
            value: asset?.secclId,
            copyableValue: true,
          },
          {
            label: `Annual Fee Percentage`,
            value: asset?.annualFeePercentage,
          },
          {
            label: `Initial Fee Percentage`,
            value: asset?.initialFeePercentage,
          },
        ]}
        noContentDivider={true}
      />
      <ExternalLinkButton
        url={asset?.kiidDocumentUrl}
        directToBrowser={true}
      >
        {`Fund Factsheet`}
      </ExternalLinkButton>
      <Button
        onPress={handleUpdateMetadata}
        mode={'text'}
        confirmation={{
          requiresConfirmation: true,
          dialogTitle: 'Are you sure?',
          dialogContent: 'Updating the metadata will immediately publish any new charges and Factsheet from SECCL within the Jarvis app.'
        }}
      >
        {'Update Meta Data'}
      </Button>
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <Paragraph>{'Availability'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'isEnabled'}
        formObj={formObj}
        options={isEnabledOptions}
        required={true}
      />
      <ManagedTextInput
        ref={shortNameRef}
        name={'shortName'}
        formObj={formObj}
        label={'Short Name'}
        placeholder={'Name used for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => assetTypeNameRef.current?.focus()}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 50,
      }}/>
      <ManagedTextInput
        ref={assetTypeNameRef}
        name={'assetTypeName'}
        formObj={formObj}
        label={'Asset Type'}
        placeholder={'Asset Type used for display grouping'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 50,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{`Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}>
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Risk Rating'}</Paragraph>
      <ManagedIntegerInput
        ref={riskRatingRef}
        name={'riskRating'}
        formObj={formObj}
        label={'Risk Rating'}
        submitHandler={() => riskRatingScaleMaxRef.current?.focus()}
        returnKeyType={'next'}
        blurOnSubmit={false}
        required={true}
        minValue={1}
        maxValue={riskRatingScaleMax}
      />
      <Paragraph>{'Risk Rating Out Of'}</Paragraph>
      <ManagedIntegerInput
        ref={riskRatingScaleMaxRef}
        name={'riskRatingScaleMax'}
        formObj={formObj}
        label={'Risk Rating Out Of'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        required={true}
        minValue={5}
        maxValue={10}
      />
      <Paragraph>{'Asset Categories'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'assetCategoryCodes'}
        options={categoryOptions}
      />
    </ModalEditScreen>
  )
}


