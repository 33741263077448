import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton } from 'components/Utility/InformationButton'
import { differenceInYears } from 'date-fns'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { startCase } from 'lodash'
import React from 'react'
import { Image } from 'react-native'
import { useAppSelector } from 'store/hooks'
import { workingCalculatorRequest } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const Calculator_31_BudgetResults = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const {
    timeline,
    baseBudgetLevel,
    netBudgetTotalAmount,
    grossBudgetTotalAmount,
  } = workingCalculatorRequestData || {}

  const {
    ageAtLastSurvivorDeathDate,
    retirementDate,
    birthDate,
  } = timeline || {}

  const clientAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(birthDate),
  )
  
  const yearsInRetirement = ageAtLastSurvivorDeathDate - clientAgeAtRetirementDate
  const totalRetirementCost = yearsInRetirement * grossBudgetTotalAmount

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const features: FeatureBoxRow[] = [
    { label: `Explore how much different retirment lifestyles might cost` },
    { label: `Fine tune your personal retirement budget` },
  ]

  const informationTexts: string[] = [
    `You have chosen the '${startCase(baseBudgetLevel)}' budget level, which means a net annual spend of ${formatCurrencyAmount(netBudgetTotalAmount, 0)}, after tax.`,
    `This requires a gross income, before tax of ${formatCurrencyAmount(grossBudgetTotalAmount, 0)}.`,
    `Whilst income tax rates may change, today's taxes provide a good indication for the future.`,
    `Jarvis always shows you money in today's terms as this is the easiest to understand; whilst the actual amount of British pounds you will spend in the future is likely to be more (due to inflation), it would be the equivalent of the figures shown in today's terms.`,
  ]

  return (
    <ProcessScreen
      buttonTitle={`Continue`}
      buttonAction={next}
      enableButton={true}
      headline={`Ok, your budget of ${formatCurrencyAmount(netBudgetTotalAmount, 0)} needs a gross income of ${formatCurrencyAmount(grossBudgetTotalAmount, 0)}`}
      subHeading={`This takes into account the income tax rate you would need to pay during retirement`}
      subHeadingInfo={<InformationButton title={`Budget Calculations Explained`} texts={informationTexts} illustrationFilename={'calculator.png'} buttonTitle={`How is this calculated?`} />}
      subHeadingNumberOfLines={3}
      footerInfo={<FeatureBox data={features} />}
    >
      <AppIllustration filename={'spare_cash.png'} style={{
        width: Sizing.x100,
        height: Sizing.x100,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      <Paragraph>{`If you retire at ${clientAgeAtRetirementDate}, and have ${yearsInRetirement} years in retirement, this would need a pre-tax total of ${formatCurrencyAmount(totalRetirementCost, 0)}`}</Paragraph>
    </ProcessScreen>
  )
}
