import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { InformationButton } from 'components/Utility/InformationButton'
import { MAX_ASSET_CURRENT_VALUE } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_42_TotalAssets = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)
  
  const [showDialog, setShowDialog] = useState(false)

  const withSpouse = workingCalculatorRequestData?.withSpouse

  //Setup form
  const formObj = useForm<{ totalCurrentValue: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      totalCurrentValue: workingCalculatorRequestData?.asset?.totalCurrentValue
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const { totalCurrentValue } = attributes
    dispatch(updateWorkingCalculatorRequest({
      asset: {
        ...workingCalculatorRequestData.asset,
        totalCurrentValue,
      },
    }))

    ampli.calculatorUsage({
      processStep: 'asset_total',
      outcome: 'provided',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    navigation.navigate(nextScreen)
  }

  const skip = () => {
    setShowDialog(false)
    dispatch(updateWorkingCalculatorRequest({
      asset: {
        ...workingCalculatorRequestData.asset,
        totalCurrentValue: 0,
      },
    }))
    
    ampli.calculatorUsage({
      processStep: 'asset_total',
      outcome: 'none',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    navigation.navigate(nextScreen)
  }

  const informationTexts: string[] = [
    `To predict the age when you can retire in the future, we need to consider the savings you have already made in pensions and other investments. These savings should grow over time, and during retirement they can then be used to fund your lifestyle.`,
    `Put simply, the more money you put away today, and the larger that retirement pot grows, the sooner you would be able to retire and rely on your pot to last for as long as you are likely to need it.`,
    `For simplicity, this calculator needs an estimated current total value of ALL of your pensions and other retirement savings.`,
  ]

  if(withSpouse) {
    informationTexts.push(`As you are retiring with a partner, this should be the overall total you have between you.`)
  }

  informationTexts.push(`You should include any pensions you already have, except any that are based on defined benefits, such as government final salary schemes. You should also include any other savings or investments that are not in pensions, but that you consider to be part of your retirement planning, where you intend to use the capital to fund your retirement.`)
  informationTexts.push(`For most people, you should NOT include your primary residence, as you will still need somewhere to live during retirement.`)
  informationTexts.push(`The full Jarvis app allows you to individually record all of your pensions and retirement assets so you have a central log for everything. It also enables you to consolidate eligible pensions into your Jarvis SIPP, simplifying your planning and giving you complete visibility on your future.`)

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      allowTextButton={true}
      textButtonAction={() => setShowDialog(true)}
      textButtonTitle={`${withSpouse ? `We` : `I`} haven't started yet`}
      headline={`Ok, let's consider ${withSpouse ? `both of ` : ``}your existing pensions`}
      subHeading={`${withSpouse ? `Between you, how ` : `How `}much have you saved in pensions and other retirement assets?`}
      subHeadingInfo={<InformationButton title={`About Retirement Savings`} texts={informationTexts} illustrationFilename={'capture_old_pensions.png'} buttonTitle={`What should I include?`} />}
    >
      <ManagedCurrencyInput
        name={'totalCurrentValue'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        placeholder={'Estimate total current value'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={1}
        maxValue={MAX_ASSET_CURRENT_VALUE}
        blurOnSubmit={false}
        informationMessage={`If you don't know exactly, a rough estimate is fine`}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`Please confirm you want to continue with no pensions or retirement assets.\n\nRemember to consider all pensions and savings you are keeping for retirement${withSpouse ? ', including those owned by your partner. ' : '. '}\n\nYou don't need the exact amount - an estimate of the total value is sufficient!`}
        confirmLabel={'Continue'}
        onConfirm={skip}
      />
    </ProcessScreen>
  )
}
