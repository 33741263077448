import { OnboardingPhase } from "providers"
import { BaseDatedDto, Gender, Title } from "./base.dto"
import { FeatureDto } from "./feature.dto"
import { NotificationMethod } from "./notification.dto"

export interface UserDto extends BaseDatedDto {
  providerId: string
  email: string
  isBlocked: boolean
  isBeta: boolean
  metadata?: UserMetadata
  affiliateId: string
  affiliateCode?: string
  affiliateRef?: string
  affiliateClientRef?: string
  picture: string
  preferredInviteId?: string
  preferredSchemeInviteId?: string
  preferredSchemeEnrolmentId?: string
  intercomHashes: {
    ios: string
    android: string
    web: string
  }
  gpak: string
  featureOverrides: FeatureDto[]
  preferredNotificationMethods?: NotificationMethod[]
  isGroupOrganizationUser?: boolean
  groupOrganizationIds?: string[]
  preferredOnboardingPhase?: OnboardingPhase

}

export interface UserSupportAccessDto {
  supportAccessUntilDate: string
}

export interface CreateUserMeDto {
  affiliateCode?: string
  affiliateRef?: string
  affiliateClientRef?: string
  preferredInviteId?: string
  preferredSchemeInviteId?: string
  preferredSchemeEnrolmentId?: string
  preferredOnboardingPhase?: OnboardingPhase
}

export class MeAddTokenDto {
  method: NotificationMethod
  token: string
}

export interface UserMetadata {
  preRegistered?: boolean
  title?: Title
  gender?: Gender
  firstName?: string
  surname?: string
  birthDate?: string
}

export interface UpdateUserMetadata {
  preRegistered?: boolean
  title?: Title
  gender?: Gender
  firstName?: string
  surname?: string
  birthDate?: string
}

export interface CreateUserDto {
  email: string
  isBlocked?: boolean
  metadata?: UpdateUserMetadata
  isBeta?: boolean
  affiliateCode?: string
  affiliateRef?: string
  affiliateClientRef?: string
  overrideFeatureCodes?: string[]
  groupOrganizationIds?: string[]
}

export interface UpdateUserDto {
  id: string
  email?: string
  isBlocked?: boolean
  metadata?: UpdateUserMetadata
  isBeta?: boolean
  overrideFeatureCodes?: string[]
  groupOrganizationIds?: string[]
}

export interface UpdateCurrentUserDto {
  isBeta?: boolean
  metadata?: UpdateUserMetadata
  affiliateCode?: string
  affiliateRef?: string
  affiliateClientRef?: string
  preferredInviteId?: string
  preferredSchemeInviteId?: string
  preferredSchemeEnrolmentId?: string
  preferredNotificationMethods?: NotificationMethod[]
  preferredOnboardingPhase?: OnboardingPhase
}

export interface UpdateCurrentUserSupportAccessDto {
  supportAccessUntilDate: string
}

export interface UserFilterDto {
  isBlocked?: boolean
  search?: string
  email?: string
}

export enum UserSearchBlockStatus {
  BLOCKED = 'Blocked',
  NOTBLOCKED = 'Unblocked',
  NEEDS_DECISION = 'Held',
  ANY = 'Any'
}