import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { getInvestmentChoiceDataForAccount, getPersonalPension } from 'lib/accountHelpers'
import { isNotFoundError } from 'lib/errorHelpers'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { Image } from 'react-native'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { AccountDto, AccountType } from 'store/dto/account.dto'
import { Sizing } from 'styles'

export const InvestmentChoiceIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding, contextAccount }: { fromOnboarding: OnboardingPhase, contextAccount: AccountDto } =  route?.params || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const investmentChoiceDataForAccount = accounts && contextAccount ? getInvestmentChoiceDataForAccount(contextAccount, accounts) : undefined

  const { investmentReferenceAccount } = investmentChoiceDataForAccount || {}

  const next = () => {
    rootNavigate('InvestmentChoiceProcessStack', {
      fromOnboarding,
      contextAccount,
      investmentReferenceAccount,
    })
  }

  return (
    <ProcessIntroScreen
      isLoading={accountsIsLoading}
      error={accountsError && !isNotFoundError(accountsError)}
      errorTryAgain={refetchAccounts}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      cancelButtonText={fromOnboarding ? 'Back to Tasks' : 'Back To Dashboard'}
      illustrationFilename={'choose_your_jarvis_plan.png'}
      headline={contextAccount
        ? `Change your investment strategy`
        : `Choose your Jarvis Plan`
      }
      subHeading={contextAccount
        ? `Changing your investment strategy will trigger a reinvestment of your funds`
        : `Take the first step to financial wellbeing with a flexible personal pension`
      }
    >
      <Image
        source={require('assets/external/fscs-logo1-purp.png')}
        style={{
          marginTop: Sizing.x20,
          width: Sizing.x80,
          height: Sizing.x80,
          alignSelf: 'center',
        }}
        resizeMode={'contain'}
      />
    </ProcessIntroScreen>
  )
}
