import { AutoCompleteItem } from "components/Inputs/ManagedAutoCompleteInput"
import { orderBy, sortBy } from 'lodash'
import { AffiliateDto } from "store/dto/affiliate.dto"
import { NationalityDto } from "store/dto/reference-data.dto"

export const getNationalityAutocompleteOptions = (nationalities: NationalityDto[]): AutoCompleteItem[] => {
  return nationalities ? sortBy(nationalities.map(nationality => {
    return {
      value: nationality.id,
      label: nationality.name,
    }
  }), (item) => item.value === 'GB' ? 0 : 1)  : []
}

export const getAffliliateAutoCompleteOptions = (affiliates: AffiliateDto[]): AutoCompleteItem[] => {
  return affiliates ? orderBy(affiliates.map(affiliate => {
    return {
      value: affiliate.id,
      label: affiliate.name,
    }
  }), ['name'], ['asc']) : []
}

