import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ModalProcessResultCarousel, ModalProcessResultCarouselData } from 'components/Utility/ModalProcessResultCarousel'
import { addYears, differenceInYears, format, isAfter } from 'date-fns'
import { getClientGenderIllustrationFilename } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect, useRef, useState } from 'react'
import { ampli } from 'src/ampli'
import { useAcceptInviteMutation, useCreateSpouseMutation, useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptInvite, updateWorkingSpouse, workingAcceptInvite, workingRetirementProfile, workingSpouse } from 'store/tempDataSlice'
import { pick, concat } from 'lodash'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { Typography } from 'styles'
import { Subheading } from 'components/Typography'
import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'

export const SpouseAdd_99_Result = ({ navigation, route }) => {
  const { onDismiss, useInvite, declinedAll }: { onDismiss: any, useInvite?: boolean, declinedAll?: boolean } = route?.params || {}
  const dispatch = useAppDispatch()

  const workingSpouseData: any = useAppSelector(workingSpouse)
  const workingAcceptInviteData: any = useAppSelector(workingAcceptInvite)

  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const [spouseUpdateDone, setSpouseUpdateDone] = useState(false)

  const { data: client, error: clientError, isLoading: clientIsLoading, isFetching: clientIsFetching } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching } = useGetRetirementProfileQuery()

  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading, reset: clientUpdateReset }] = useUpdateMeMutation()
  const [createSpouse, { data: createdSpouse, error: spouseCreateError, reset: spouseCreateReset }] = useCreateSpouseMutation()
  const [acceptInvite, { data: acceptedInvite, isLoading: acceptIsLoading, error: acceptError, reset: acceptReset } ] = useAcceptInviteMutation()

  const isLoading = !spouseUpdateDone || clientIsLoading || clientIsFetching || spouseIsLoading || spouseIsFetching || rpIsLoading || clientUpdateIsLoading || acceptIsLoading
  const error: any = spouseCreateError || clientError || clientUpdateError || acceptError

  //Save client on enter
  useEffect(() => {
    saveClient()
  }, [])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: useInvite
        ? {
            addspouse: true,
            invitespouse: true,
            acceptedinvite: true,
          }
        : {
            addspouse: true,
          },
    })
  }

  //When client saved, create spouse if needed, else clear data
  useEffect(() => {
    if (updatedClient) {
      if (useInvite) {
        if (workingAcceptInviteData) {
          accept()
        } else {
          clearData()  
        }
      } else {
        if (workingSpouseData) {
          saveSpouse()
        } else {
          clearData()
        }
      }
    }
  }, [updatedClient])

  const accept = () => {
    Logger.info(`Accepting invite...`)
    acceptInvite(workingAcceptInviteData)
  }

  const saveSpouse = () => {
    Logger.info(`Creating Spouse...`)
    createSpouse(workingSpouseData)
  }

  //Report amplitude when spouse created
  useEffect(() => {
    if (createdSpouse) {
      ampli.spouseCreate(pick(createdSpouse, [
        'gender',
        'birthDate',
        'age',
      ]))
      clearData()
    }
  }, [createdSpouse])

  //Report amplitude when invite accepted
  useEffect(() => {
    if (acceptedInvite) {
      ampli.inviteAccept({
        email: acceptedInvite?.email,
        invitorUserId: acceptedInvite?.userId,
      })
      clearData()
    }
  }, [acceptedInvite])

  const clearData = () => {
    setSpouseUpdateDone(true)
    dispatch(updateWorkingSpouse(undefined))
    dispatch(updateWorkingAcceptInvite(undefined))
  }

  const useStatePension = !retirementProfile && !workingRetirementProfileData?.targetRetirementAge
  const retirementAge = useStatePension ? client?.statePensionAge : retirementProfile ? retirementProfile.targetRetirementAge : workingRetirementProfileData.targetRetirementAge

  const sameAges = client?.lastSurvivorDeathAge === spouse?.lastSurvivorDeathAge

  const carouselData: ModalProcessResultCarouselData[] = declinedAll
    ? [
      {
        illustrationFilename: 'info.png',
        headline: `Ok, we'll leave it for now`,
        subHeading: `You can add a partner at any time if your circumstances change`,
      },
    ] : concat(
      [
        {
          illustrationFilename: getClientGenderIllustrationFilename(spouse),
          headline: `A Great Partnership!`,
          subHeading: useInvite
            ? `We've linked your Jarvis account with ${spouse?.firstName ? spouse?.firstName : 'your partner'}'s`
            : `That's all we need to know about ${spouse ? spouse?.firstName : 'your partner'}`,
        },
        {
          illustrationFilename: 'combined_retirement_timeline.png',
          headline: `Life Expectancy`,
          subHeading:
            <Subheading>
              {`Based on both of your ages and genders, your combined life expectancy is around `}
              <Subheading style={Typography.fontWeight.bold}>{`${client?.lastSurvivorDeathAge} years`}</Subheading>
            </Subheading>,
          subHeadingInfo: <NamedInformationButton name={NamedInformation.COMBINED_LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
        },
        {
          illustrationFilename: 'joint_planning.png',
          headline: `Joint Retirement Planning`,
          subHeading:
            sameAges
              ? <Subheading>
                {`Your Timeline will run until `}
                <Subheading style={Typography.fontWeight.bold}>{`${format(new Date(client?.lastSurvivorDeathDate), 'MMMM yyyy')}`}</Subheading>
                {`, when you would both be `}
                <Subheading style={Typography.fontWeight.bold}>{client?.lastSurvivorDeathAge}</Subheading>
              </Subheading>
              : <Subheading>
                {`Your Timeline will run until `}
                <Subheading style={Typography.fontWeight.bold}>{`${format(new Date(client?.lastSurvivorDeathDate), ' MMMM yyyy')}`}</Subheading>
                {`, when you would be aged `}
                <Subheading style={Typography.fontWeight.bold}>{client?.lastSurvivorDeathAge}</Subheading>
                {`, and ${spouse?.firstName} `}
                <Subheading style={Typography.fontWeight.bold}>{spouse?.lastSurvivorDeathAge}</Subheading>
              </Subheading>,
          subHeadingInfo: <NamedInformationButton name={NamedInformation.JOINT_PLANNING} buttonTitle={'How is this decided?'} />
        },
        {
          illustrationFilename: 'relax_for_now.png',
          headline: `Retirement Timeline`,
          subHeading:
            <Subheading>
              {`Based on your ${useStatePension ? 'state pension age' : 'target retirement age'} of ${retirementAge}, this would give you around `}
              <Subheading style={Typography.fontWeight.bold}>{`${client?.lastSurvivorDeathAge - retirementAge} years`}</Subheading>
              {` in retirement`}
            </Subheading>,
        },
      ], useInvite ? [] :
      [
        {
          illustrationFilename: 'get_partner_involved.png',
          headline: `Get ${spouse?.firstName} Involved!`,
          subHeading: `Jarvis lets you work together - look for the suggestion to invite ${spouse?.firstName} in the Dashboard`,
          subHeadingInfo: <NamedInformationButton name={NamedInformation.SPOUSE_DATA_SHARING} buttonTitle={'About Partner Linking'} />
        },
      ],
    ) 

  const [carouselComplete, setCarouselComplete] = useState(false)
  const carouselRef = useRef()

  const snapToNext = () => {
    //@ts-ignore
    carouselRef?.current?.next()
  }

  const onCompletion = () => {
    setCarouselComplete(true)
  }

  return (
    <ModalProcessResultScreen
      headline={``}
      error={error}
      errorTryAgain={spouseCreateError ? saveSpouse : clientUpdateError ? saveClient : acceptError ? accept : undefined}
      errorCancel={spouseCreateError ? spouseCreateReset : clientUpdateError ? clientUpdateReset : acceptError ? acceptReset : () => navigation.goBack()}
      isLoading={isLoading}
      loadingMessage={!spouseUpdateDone ? useInvite ? ['Linking accounts...'] :  ['Saving partner...'] : undefined}
      buttonAction={carouselComplete ? onDismiss : snapToNext}
      buttonTitle={carouselComplete ? 'Finish' : 'Next'}
    >
      <ModalProcessResultCarousel
        ref={carouselRef}
        data={carouselData}
        onCompletion={onCompletion}
      />
    </ModalProcessResultScreen>
  )
}
