import { MainAppHeader } from 'components/Layout/MainAppHeader'
import { NamedInformation } from 'components/Utility/InformationButton'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { Flex, Paper } from 'styles'

interface SectionSimpleContainerProps {
  children: React.ReactNode
  sectionTitle: string
  sectionTitleInfo?: NamedInformation
}
export const SectionSimpleContainer = (props: SectionSimpleContainerProps) => {

  const isWeb = platformIsWeb()

  const { sectionTitle, sectionTitleInfo, children } = props

  return (
    <ThemeProvider theme={ isWeb ? Paper.darkTheme : Paper.lightTheme }>
      <View style={{ ...Flex.column.start }}>
        <MainAppHeader
          sectionTitle={sectionTitle}
          sectionTitleInfo={sectionTitleInfo}
        />
        {children}
      </View>
    </ThemeProvider>
  )
}
