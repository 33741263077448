import React, { ComponentProps } from 'react'
import { Image } from 'react-native'

type JarvisLogoTypeProps = Omit<ComponentProps<typeof Image>, 'source'> & {
  size: number
  color?: "white" | "black"  
}
export const JarvisLogoType = (props: JarvisLogoTypeProps) => {
  const { color, size, style } = props

  const jarvisLogo = color === 'black'
    ? require('assets/brand/jarvis-logo-blk-4x.png')
    : require('assets/brand/jarvis-logo-wht-4x.png')

  return (
    <Image source={jarvisLogo} style={[
      {
        height: size,
        width: size * 4,
        resizeMode: 'contain',
        alignSelf: 'center',
      },
      style
    ]} />
  )
}