import { concat } from 'lodash'

export const getAppLoadingMessages = (): string[] => {
  return [
    'Getting things ready...',
    'Attaching wheels...',
    'Waking up the hamsters...',
    'Feeding them lettuce...',
    'Showing them to the wheel...',
    'Giving it a little push...',
  ]
}

export const getAppCreateUserMessages = (): string[] => {
  return [
    'Preparing your account...',
  ]
}


export const getModelGenerationMessages = (): string[] => {
  return [
    `Calculating your retirement age...`,
    `Crunching the numbers...`,
    `Still working on it...`,
    `Checking the maths...`,
    `Carrying the one...`,
    `Giving it a final check...`,
  ]
}

export const getModelChangeMessages = (): string[] => {
  return [
    `Crunching the numbers...`,
    `Still working on it...`,
    `Checking the maths...`,
    `Carrying the one...`,
    `Giving it a final check...`,
  ]
}

export const getScenarioGenerationMessages = (): string[] => {
  return [
    `Generating suggestions...`,
    `This may take a short time...`,
    `Trying different options...`,
    `Optimising results...`,
    `Still working on it...`,
    `Checking the maths...`,
    `Please bear with us...`,
    `Carrying the one...`,
    `Giving it a final check...`,
  ]
}


export const getHelpCenterLoadingMessages = (): string[] => {
  return [
    `Searching articles...`,
    `Finding relevant ones...`,
    `Looking for any we've missed...`,
  ]
}

export const getLoginLoadingMessages = (): string[] => {
  return [
    'Logging you in...',
    'Preparing the app...',
  ]
}

export const getLogoutLoadingMessages = (): string[] => {
  return [
    'Logging you out...',
    'Clearing the decks...',
    'Wiping the slate clean...',
   ]
}

export const getOnboardingPlanMessages = (): string[] => {
  return [
    'Preparing your checklist...',
    'Checking on progress...',
    'Finalizing tasks...'
   ]
}

export const getLookingLoadingMessages = (first?: string): string[] => {
  const base = first ? [first] : []
  const generic = [
    'Still looking...',
    'Checking under the carpet...',
    'And behind the sofa...',
  ]
  return concat(base, generic)
}
