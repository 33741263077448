import React, { ComponentProps } from 'react'
import { StyleSheet, View } from "react-native"
import { Flex, Sizing } from "styles"
import { Button } from './Button'
import { launchImageLibraryAsync, MediaTypeOptions, ImageInfo } from 'expo-image-picker'
import { Logger } from 'lib/logger'
import { omit } from 'lodash'
import { platformIsWeb } from 'lib/platformHelpers'

type ImagePickerButtonProps = ComponentProps<typeof Button> & {
  successHandler: Function,
  cancelHandler?: Function,
  allowedFileExtensions?: ImageExtension[]
  mediaTypes?: MediaTypeOptions,
  allowsEditing?: boolean,
  aspect?: [number, number],
  quality?: number,
}

type ImageExtension = 'jpg' | 'jpeg' | 'png'

const ALL_EXTENSIONS  = [
  'png',
  'jpg',
  'jpeg',
]

const JPEG_EXTENSIONS  = [
  'jpg',
  'jpeg',
]

export const ImagePickerButton = (props: ImagePickerButtonProps) => {
  const { children, mode, successHandler, cancelHandler, allowedFileExtensions, mediaTypes, allowsEditing, aspect, quality } = props
  
  const doCancel = (alertMessage?: string) => {
    if (alertMessage) {
      alert(alertMessage)
    }
    if (cancelHandler) {
      cancelHandler()
    }
    return
  } 

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await launchImageLibraryAsync({
      mediaTypes,
      base64: true,
      allowsEditing,
      aspect: [1, 1],
      quality: 1,
    })

    //Return if canceled (and call handler if configured)
    if (result.canceled) {
      return doCancel()
    }

    const { assets } = result
    const firstAsset = assets.length ? assets[0] : undefined
    const isWeb = platformIsWeb()

    if (!firstAsset) {
      return doCancel()
    }

    const { uri, base64, type } = firstAsset

    //For web, we directly get the uri as base64 and cannot detect the image type
    let base64Result
    if (isWeb) {
      base64Result = uri
    } else {
      //For native, check the file extension against allowed types
      const fileExtension= uri.split('.').pop()
      const allowedFileExtensionsStrings = allowedFileExtensions
        ? allowedFileExtensions.map(ext => ext.toString())
        : ALL_EXTENSIONS
  
      if (!allowedFileExtensionsStrings.includes(fileExtension)) {
        return doCancel(`Please choose a file of one of the following types: ${allowedFileExtensionsStrings.join(', ')}`)
      }
  
      //Assume PNG
      let base64Prefix = 'data:image/png;base64,'
      if (JPEG_EXTENSIONS.includes(fileExtension)) {
        base64Prefix = 'data:image/jpeg;base64,'
      }
      
      base64Result = `${base64Prefix}${base64}`
    }

    successHandler(base64Result)
  }

  return (
    <View style={localStyles.footer}>
      <View style={localStyles.buttonContainer}>
        <Button
          {...props}
          onPress={pickImage}
          mode={mode || 'contained'}
        >
          {children}
        </Button>
      </View>
    </View>
  )
}

const localStyles = StyleSheet.create({
  footer: {    
    ...Flex.override.bottom,
    ...Flex.column.start,
    height: Sizing.x60,
    paddingTop: Sizing.x5,
    paddingBottom: Sizing.x5,
  },
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
})