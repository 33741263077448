export interface BaseDto {
  id: string
  type: string
}
export enum AUTHTYPE{
  LOGIN='login',
  REGISTER='register'

}
  
export interface BaseDatedDto extends BaseDto {
  createdAt: string
  updatedAt: string,
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}


export enum ExtendedGender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum Title {
  MR = 'Mr',
  MRS = 'Mrs',
  MISS = 'Miss',
  DR = 'Dr',
  MS = 'Ms',
  PROFESSOR = 'Professor',
}

export enum ContributionSource {
  PERSONAL = 'personal',
  EMPLOYER = 'employer',
}

export type Address = {
  building: string
  street1: string
  town: string
  country: string
  postCode: string
}

export enum ActionType{
  CANCEL='cancel',
  CHANGE='change'
}

export interface SimpleUserDto {
  id: string
  firstName?: string
  surname?: string
  email?: string
}