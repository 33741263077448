import { Logger } from 'lib/logger'
import { Share } from 'react-native'
import { ampli } from 'src/ampli'

type ShareMessageProps = {
  message: string
  title?: string
  onComplete?: Function
  onCancel?: Function
}

export const shareMessage = async (props: ShareMessageProps) => {
  const { message, title, onCancel, onComplete } = props
  try {
    const result = await Share.share({
      title,
      message,
    })

    ampli.friendShare()

    if (onComplete && result.action === Share.sharedAction) {
      onComplete()
    } else if (onCancel && result.action === Share.dismissedAction) {
      onCancel()
    }
  } catch (error) {
    Logger.error(`Error sharing`, error.message)
  }

}