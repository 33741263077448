import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminAffiliatesListScreen } from './Screens/AdminAffiliateListScreen'

export const AdminAffiliateSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Affiliates'}
    >
      <AdminAffiliatesListScreen />
    </SectionSimpleContainer>
  )
}

