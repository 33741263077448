import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminGroupSchemesListScreen } from './Screens/AdminGroupSchemeListScreen'

export const AdminGroupSchemeSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Group Schemes'}
    >
      <AdminGroupSchemesListScreen />
    </SectionSimpleContainer>
  )
}

