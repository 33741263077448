import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { formatISO, startOfDay, subDays, subMonths } from 'date-fns'
import { getPersonalPension } from 'lib/accountHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useGetUserAccountsQuery, useRequestValuationStatementMutation } from 'store/apiSlice'
import { AccountType } from 'store/dto/account.dto'
import { ValuationStatementRequestQueryDto } from 'store/dto/message.dto'

export const StatementRequest_01_DateRangeChoice = ({ route, navigation }) => {
  const { nextScreen, onDismiss, invite }  = route?.params || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  
  const personalPension = getPersonalPension(accounts)

  const [requestValuationStatement, { data: requestValuationResponse, error: requestError, isLoading: requestIsLoading }] = useRequestValuationStatementMutation()

  const isLoading = accountsIsLoading || requestIsLoading
  const error: any = accountsError

  const requestStatement = (months?: number) => {
    const toDate = startOfDay(subDays(new Date(), 1)) //Yesterday
    const fromDate = months ? subMonths(toDate, months) : startOfDay(new Date(personalPension?.createdAt))
    const params: ValuationStatementRequestQueryDto = {
      fromDate: formatISO(fromDate, { representation: 'date'}),
      toDate: formatISO(toDate, { representation: 'date'}),
    }
    requestValuationStatement(params)
  }

  //Setup form
  const formObj = useForm<{ dateRangeChoice: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  const { handleSubmit, setValue, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const dateRangeChoice = attributes.dateRangeChoice
    //Navigate based on choice
    if (dateRangeChoice) {
      requestStatement(dateRangeChoice)
    } else {
      requestStatement()
    } 
  }

  const dateRangeOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 6,
      label: `Last 6 months`
    },
    {
      value: 12,
      label: `Last 12 months`
    },
    {
      value: false,
      label: `All time`,
    },
  ]

  //Navigate after request
  useEffect(() => {
    if (requestValuationResponse) {
      const selectedPeriod = watch('dateRangeChoice')
      ampli.statementRequest({
        statementType: 'valuation',
        periodInMonths: selectedPeriod ? parseInt(selectedPeriod) : undefined,
      })
      navigation.navigate(nextScreen)
    }
  }, [requestValuationResponse])

  return (    
    <ModalProcessScreen
      error={error}
      errorTryAgain={accountsError ? refetchAccounts : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={requestIsLoading ? ['Requesting statement...'] : undefined}
      buttonTitle={'Request Statement'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Pick a time range`}
      subHeading={`The generated statement will cover the range requested up to and including yesterday`}
    >
    <ManagedSimpleChoiceInput
      name={'dateRangeChoice'}
      formObj={formObj}
      options={dateRangeOptions}
      required={true}
    />
    </ModalProcessScreen>
  )
}
