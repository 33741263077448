import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { useGetMeQuery, useGetSpouseQuery } from 'store/apiSlice'
import { BeneficiaryRelationship } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'

export const BeneficiariesAddBeneficiary_03_Surname = ({ route, navigation }) => {
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const dispatch = useAppDispatch()
  const { nextScreen } = route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, refetch: refetchSpouse } = useGetSpouseQuery()

  const isLoading = clientIsLoading || spouseIsLoading
  const error = clientError

  const isPartner = [BeneficiaryRelationship.PARTNER, BeneficiaryRelationship.WIFE, BeneficiaryRelationship.HUSBAND].includes(workingBeneficiaryData?.beneficiaryRelationship)
  const isChild = [BeneficiaryRelationship.DAUGHTER, BeneficiaryRelationship.SON].includes(workingBeneficiaryData?.beneficiaryRelationship)

  const formObj = useForm<{ surname: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      surname:
        workingBeneficiaryData?.surname
          ? workingBeneficiaryData?.surname
          : isPartner
            ? spouse
              ? spouse?.surname
              : client?.surname
            : isChild
              ? client?.surname
            : ''
    }
  })  
  const { handleSubmit, formState: { isValid } } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingBeneficiary(attributes))
    navigation.navigate(nextScreen)
  }
  
  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      isLoading={isLoading}
      error={error}
      errorTryAgain={clientError ? refetchClient : refetchSpouse}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And their surname?`}
      subHeading={'Again, as it appears on their passport or ID'}
    >
      <ManagedTextInput
        placeholder={'Their legal surname'}
        name={'surname'}
        returnKeyType="next"
        autoFocus={true}
        blurOnSubmit={true}
        formObj={formObj}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20
        }}
      />
    </ModalProcessScreen>
  )
}
