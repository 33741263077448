import { ProcessScreen } from 'components/Layout'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useAcceptInviteMutation, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptInvite, workingAcceptInvite } from 'store/tempDataSlice'

export const InviteAccept_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}

  const dispatch = useAppDispatch()

  const workingAcceptInviteData: any = useAppSelector(workingAcceptInvite)

  const [acceptInvite, { data: acceptedInvite, isLoading: acceptIsLoading, error: acceptError} ] = useAcceptInviteMutation()
  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()

  const isLoading = acceptIsLoading || clientUpdateIsLoading
  const error: any = acceptError || clientUpdateError

  //Save on enter
  useEffect(() => {
    if (workingAcceptInviteData) {
      accept()
    }
  }, [])

  const accept = () => {
    Logger.info(`Accepting invite...`)
    acceptInvite(workingAcceptInviteData)
  }

  //Save client when accepted
  useEffect(() => {
    if (acceptedInvite) {
      ampli.inviteAccept({
        email: acceptedInvite?.email,
        invitorUserId: acceptedInvite?.userId,
      })
      saveClient()
    }
  }, [acceptedInvite])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        addspouse: true,
        invitespouse: true,
        acceptedinvite: true,
      },
    })
  }

  //End when client saved
  useEffect(() => {
    if (updatedClient) {
      end()
    }
  }, [updatedClient])

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingAcceptInvite(undefined))
    rootNavigate(nextRoute, route?.params)
  }

  return (
    <ProcessScreen
      buttonTitle={''}
      buttonAction={() => {}}
      showButton={false}
      error={error}
      errorTryAgain={acceptError ? accept : saveClient}
      isLoading={isLoading}
      loadingMessage={['Linking accounts...']}
    />
  )
}
