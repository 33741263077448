import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { createBranchLink } from 'lib/linkHelpers'
import React from 'react'
import { Image } from 'react-native'
import { FeatureDto } from 'store/dto/feature.dto'
import { Sizing, Paper } from 'styles'

type AdminFeatureListItemProp = {
  feature: FeatureDto
  onPressFunction?: () => void
}
export const AdminFeatureListItem = (props: AdminFeatureListItemProp) => {
  const { feature, onPressFunction } = props
  const { code, name, description, status } = feature

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <DetailedListItem
    icon={<MaterialCommunityIcons name={'list-status'} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={name}
      titleRight={status}
      subTitle={description}
      subTitleRight={<Text>{code}</Text>}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
