import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { ClientMeDto } from "store/dto/client.dto"
import { RetirementAssetDto, RetirementAssetTransferStatus } from "store/dto/retirement-asset.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingRetirementAsset } from "store/tempDataSlice"
import { setPrepareForTransferRetirementData } from 'store/uxSlice'
import { RetirementAssetTransfer_00_Intro, RetirementAssetTransfer_01_RetransferAssistance, RetirementAssetTransfer_02_ProviderBrand, RetirementAssetTransfer_03_ProviderSuggestions, RetirementAssetTransfer_04_ProviderList, RetirementAssetTransfer_10_ReferenceIntro, RetirementAssetTransfer_11_Reference, RetirementAssetTransfer_20_PersonalDetailsIntro, RetirementAssetTransfer_21_PersonalDetailsAddressChoice, RetirementAssetTransfer_22_PersonalDetailsTransferAddress, RetirementAssetTransfer_23_PersonalDetailsSetAddress, RetirementAssetTransfer_24_PersonalDetailsPreviousNameChoice, RetirementAssetTransfer_25_PersonalDetailsPreviousFirstName, RetirementAssetTransfer_26_PersonalDetailsPreviousSurname, RetirementAssetTransfer_30_TracingIntro, RetirementAssetTransfer_31_TracingProvider, RetirementAssetTransfer_32_TracingReference, RetirementAssetTransfer_33_TracingEmployerStartDate, RetirementAssetTransfer_34_TracingEmployerEndDate, RetirementAssetTransfer_35_TracingEmployerShortPeriod, RetirementAssetTransfer_99_Result } from "./Screens"

export type RetirementAssetTransferProcessStackProps = {
  asset: RetirementAssetDto
  client: ClientMeDto
  onDismiss?: any
}

export const RetirementAssetTransferProcessStack = (props: RetirementAssetTransferProcessStackProps) => {
  const { asset, client } = props
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setPrepareForTransferRetirementData(undefined))

  const visible = !!asset && !!client
  const onDismiss = props.onDismiss || globalDismiss

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingRetirementAsset(undefined))
    }, [])


  const isRetransfer = asset?.transferStatus !== null && asset?.transferStatus !== RetirementAssetTransferStatus.TRACING_FAILED
  const isFailedTrace = asset?.transferStatus === RetirementAssetTransferStatus.TRACING_FAILED

  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel preparing this pension?`}
      processTitle='Prepare Pension'
      initialRouteName={'Intro'}
      initialParams={{
        asset,
        client,
        isRetransfer,
        isFailedTrace,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Intro',
          component: RetirementAssetTransfer_00_Intro,
        },
        {
          navigationId: 'RetransferAssistance',
          title: 'RetransferAssistance',
          component: RetirementAssetTransfer_01_RetransferAssistance,
        },
        {
          navigationId: 'ProviderBrand',
          title: 'ProviderBrand',
          component: RetirementAssetTransfer_02_ProviderBrand,
        },
        {
          navigationId: 'ProviderSuggestions',
          title: 'ProviderSuggestions',
          component: RetirementAssetTransfer_03_ProviderSuggestions,
        },
        {
          navigationId: 'ProviderList',
          title: 'ProviderList',
          component: RetirementAssetTransfer_04_ProviderList,
        },
        {
          navigationId: 'ReferenceIntro',
          title: 'ReferenceIntro',
          component: RetirementAssetTransfer_10_ReferenceIntro,
        },
        {
          navigationId: 'Reference',
          title: 'Reference',
          component: RetirementAssetTransfer_11_Reference,
        },
        {
          navigationId: 'PersonalDetailsIntro',
          title: 'PersonalDetailsIntro',
          component: RetirementAssetTransfer_20_PersonalDetailsIntro,
        },
        {
          navigationId: 'PersonalDetailsAddressChoice',
          title: 'PersonalDetailsAddressChoice',
          component: RetirementAssetTransfer_21_PersonalDetailsAddressChoice,
        },
        {
          navigationId: 'PersonalDetailsTransferAddress',
          title: 'PersonalDetailsTransferAddress',
          component: RetirementAssetTransfer_22_PersonalDetailsTransferAddress,
        },
        {
          navigationId: 'PersonalDetailsSetAddress',
          title: 'PersonalDetailsSetAddress',
          component: RetirementAssetTransfer_23_PersonalDetailsSetAddress,
        },
        {
          navigationId: 'PersonalDetailsPreviousNameChoice',
          title: 'PersonalDetailsPreviousNameChoice',
          component: RetirementAssetTransfer_24_PersonalDetailsPreviousNameChoice,
        },
        {
          navigationId: 'PersonalDetailsPreviousFirstName',
          title: 'PersonalDetailsPreviousFirstName',
          component: RetirementAssetTransfer_25_PersonalDetailsPreviousFirstName,
        },
        {
          navigationId: 'PersonalDetailsPreviousSurname',
          title: 'PersonalDetailsPreviousSurname',
          component: RetirementAssetTransfer_26_PersonalDetailsPreviousSurname,
        },
        {
          navigationId: 'TracingIntro',
          title: 'TracingIntro',
          component: RetirementAssetTransfer_30_TracingIntro,
        },
        {
          navigationId: 'TracingProvider',
          title: 'TracingProvider',
          component: RetirementAssetTransfer_31_TracingProvider,
        },
        {
          navigationId: 'TracingReference',
          title: 'TracingReference',
          component: RetirementAssetTransfer_32_TracingReference,
        },
        {
          navigationId: 'TracingEmployerStartDate',
          title: 'TracingEmployerStartDate',
          component: RetirementAssetTransfer_33_TracingEmployerStartDate,
        },
        {
          navigationId: 'TracingEmployerEndDate',
          title: 'TracingEmployerEndDate',
          component: RetirementAssetTransfer_34_TracingEmployerEndDate,
        },
        {
          navigationId: 'TracingEmployerShortPeriod',
          title: 'TracingEmployerShortPeriod',
          component: RetirementAssetTransfer_35_TracingEmployerShortPeriod,
        },
        {
          navigationId: 'Result',
          title: '',
          component: RetirementAssetTransfer_99_Result,
        },
      ]}
    />
  )
}

