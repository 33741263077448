import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminPensionProvidersListScreen } from './Screens/AdminPensionProviderListScreen'

export const AdminPensionProviderSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Pension Providers'}
    >
      <AdminPensionProvidersListScreen />
    </SectionSimpleContainer>
  )
}

