import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerHistoryEnrolmentsListScreen } from './Screens/EmployerHistoryEnrolmentsListScreen'

export const EmployerHistoryEnrolmentSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Enrolment History'}
    >
      <EmployerHistoryEnrolmentsListScreen />
    </SectionSimpleContainer>
  )
}

