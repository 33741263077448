import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetInvitesQuery } from 'store/apiSlice'
import { InviteStatus } from 'store/dto/invite.dto'

export const SpouseInviteHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: invitesAsInvitor, error: invitesAsInvitorError, isLoading: invitesAsInvitorIsLoading } = useGetInvitesQuery()

  const isLoading = invitesAsInvitorIsLoading
  const error: any = invitesAsInvitorError

  const openInvite = invitesAsInvitor ? invitesAsInvitor.find(invite => {
    return invite.status === InviteStatus.OPEN
  }) : undefined

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = [
    {    
      illustrationFilename: 'invite.png',  
      headline: `Away it goes!`,
      subHeading: `We've sent an invitation email to ${openInvite ? openInvite?.firstName : 'them'}`,
    },
    { 
      illustrationFilename: 'joint_planning.png',  
      headline: `What's Next?`,
      subHeading: `If ${openInvite ? openInvite?.firstName : 'they'} accepts, your accounts will be linked and their financial data incorporated into your Jarvis planning`,
    },
  ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
