import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ActionCard, ActionCardProps } from 'components/Utility/ActionCard'
import { Loading } from 'components/Utility/Loading'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { OnboardingStateStepIdentifier, useGuidanceContext } from 'providers'
import React, { ReactNode } from 'react'
import { ScrollView, View } from 'react-native'
import { Sizing } from 'styles'

type OnboardingGuardProps = {
  explanation: string
  subExplanation?: string
  onboardingSteps: OnboardingStateStepIdentifier[]
  children: ReactNode
  additionalContent?: ReactNode
  size?: 'small' | 'large'
}

export const OnboardingGuard = (props: OnboardingGuardProps) => {

  const sliderWidth = getScreenAppWidth()
  const { explanation, subExplanation, onboardingSteps, additionalContent, children, size } = props

  const { suggestedOnboardingPhase, getOutstandingOnboardingStepActionCardProps } = useGuidanceContext()

  const outstandingTasks: ActionCardProps[] = getOutstandingOnboardingStepActionCardProps(onboardingSteps, size)
  const hasTasks = !!outstandingTasks?.length

  const renderOnboardingCard = ({ item, index }) => {
    const props: ActionCardProps = item
    const { size, buttonLabel, title, description, imageSource, illustrationFilename, onPress } = props
    return (
      <View style={{ paddingHorizontal: Sizing.x30 }}>
        <ActionCard
          imageSource={imageSource}
          illustrationFilename={illustrationFilename}
          title={title}
          description={description}
          size={size}
          onPress={onPress}
          buttonLabel={buttonLabel}
        />
      </View>
    )
  }

  return (
    <>
      {
        suggestedOnboardingPhase === undefined ? <Loading /> :
        hasTasks ?
          <>
            <ScrollView>
              <Subheading style={{
                paddingTop: Sizing.x30,
                paddingHorizontal: Sizing.x15,
              }}>
                {explanation}
              </Subheading>
              {
                subExplanation
                  ? <Paragraph style={{
                    paddingTop: Sizing.x15,
                    paddingHorizontal: Sizing.x15,
                  }}>
                      {subExplanation}
                    </Paragraph>
                  : <></>
              }
              <View style={{ height: Sizing.x20 }}></View>
              <View>
                <ManagedCarousel
                  loop={false}
                  width={sliderWidth}
                  height={size === 'small' ? Sizing.x120 : Sizing.x400}
                  data={outstandingTasks}
                  renderItem={renderOnboardingCard}
                />
              </View>
            </ScrollView>
            {
              additionalContent
            }
          </>
          :
          <>
            {children}
          </>
      }
    </>
  )
}
