import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import React, { useEffect } from 'react'
import { Image } from 'react-native'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppSelector } from 'store/hooks'
import { workingCalculatorRequest } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const Calculator_60_Jarvis = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  //Report progress
  useEffect(()=>{
    ampli.calculatorUsage({
      processStep: 'jarvis',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })
  },[])
  
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const {
    sendReport
  } = workingCalculatorRequestData || {}

  const next = () => {
    navigation.navigate(sendReport ? 'Results' : nextScreen)
  }

  const features: FeatureBoxRow[] = [
    { label: `Complete, easy-to-understand retirement planning in a single app` },
    { label: `Save personally for your future, and receive contributions from any employer` },
    { label: `Know exactly how much to save to achieve your desired retirement lifestyle` },
    { label: `Live forecasting and intelligent suggestions to get you on track` },
    { label: `Quickly and easily consolidate existing pensions into Jarvis` },
    { label: `Link accounts with a partner to plan and save together`},
  ]

  return (
    <ProcessScreen
      buttonTitle={sendReport ? 'Send My Report' : 'Continue'}
      buttonAction={next}
      enableButton={true}
      headline={`So how does Jarvis help?`}
      subHeading={`The Jarvis Lifetime Pension lets you map out your retirement and understand how to get there more quickly`}
      subHeadingNumberOfLines={3}
    >
      <AppIllustration filename={'gamified_approach.png'} style={{
        width: Sizing.x100,
        height: Sizing.x100,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      <FeatureBox data={features} noTitle={true} />
    </ProcessScreen>
  )
}
