import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'
import { MAX_TARGET_RETIREMENT_AGE, MIN_TARGET_RETIREMENT_AGE } from 'lib/constants'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import { useGuidanceContext } from 'providers'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { useGetMeQuery, useGetRetirementProfileQuery, useUpdateRetirementProfileMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editRetirementAgeVisible, setEditRetirementAgeVisible } from 'store/uxSlice'
import { Sizing } from 'styles'

export const RetirementTargetAgeEditModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const visible = useAppSelector(editRetirementAgeVisible)

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching } = useGuidanceContext()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()  
  const [updateRetirementProfile, { data: updatedRp, error: updatedRpError, isLoading: updatedRpIsLoading }] = useUpdateRetirementProfileMutation()

  const isLoading = meIsLoading || rpIsLoading || currentViableAgeIsLoading || updatedRpIsLoading
  const error: any = meError || rpError || updatedRpError

  //Form refs for focussing
  const targetRetirementAgeRef = useRef()

  //Setup form
  const formObj = useForm<{
    targetRetirementAge: number
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      targetRetirementAge: retirementProfile?.targetRetirementAge
    },
  })
  const { handleSubmit, reset, formState: { isDirty, isValid} } = formObj

  //Re-initialize form when have asset
  useEffect(() => {
    if (retirementProfile) {
      reset({
        targetRetirementAge: retirementProfile?.targetRetirementAge
      })
    }
  }, [retirementProfile])
  
  const onSubmit = attributes => {
    Logger.info(`Updating retirement age...`)
    updateRetirementProfile({
      ...attributes,
    })
  }

  //Close on save result
  useEffect(() => {
    if (updatedRp) {
      ampli.retirementProfileUpdate({
        ...pick(updatedRp, [
          'asCouple',
          'insideLondon',
          'expensesNetTotalAmount',
          'targetRetirementAge',
          'baseBudgetLevel',
        ]),
      })
      dispatch(setEditRetirementAgeVisible(false))
    }
  }, [updatedRp])

  const minTargetRetirementAge = me ? deriveMinimumTargetRetirementAge(me) : MIN_TARGET_RETIREMENT_AGE

  return (
    <ModalEditScreen
      formTitle={'Change Target Age'}
      onDismiss={() => dispatch(setEditRetirementAgeVisible(false))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes to your target age?'}
      isLoading={isLoading}
      loadingMessage={updatedRpIsLoading ? ['Saving target age...'] : undefined}
      error={error}
      errorTryAgain={meError ? refetchMe : rpError ? refetchRp : handleSubmit(onSubmit)}
      buttonTitle={'Done'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Headline>{'At what age do you want to retire?'}</Headline>
      </View>
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Subheading>{'This age is used by Jarvis when building financial forecasts'}</Subheading>
        <NamedInformationButton name={NamedInformation.TARGET_RETIREMENT_AGE} />
      </View>
      <ManagedIntegerInput
        ref={targetRetirementAgeRef}
        name={'targetRetirementAge'}
        formObj={formObj}
        label={'Target Age'}
        placeholder={'Enter age at which you would like to retire'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        minValue={minTargetRetirementAge}
        maxValue={MAX_TARGET_RETIREMENT_AGE}
      />
        {
          currentViableAge ?
          <>
            <Paragraph>{`Your current achievable age is ${currentViableAge.output.viableRetirementAge}`}</Paragraph>
          </>
          : <></>
        }
    </ModalEditScreen>
  )
}
