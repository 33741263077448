import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetupPayment, workingContributionPayment } from 'store/tempDataSlice'

const STATIC_AMOUNTS = [500,1000,2000]

export const MakeContributions_21_RegularSuggestAmount = ({ route, navigation }) => {
  const dispatch = useAppDispatch()
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}
  const { existingContributionConfiguration, existingRegularContribution } = contributionsData || {}

  const workingContributionPaymentData = useAppSelector(workingContributionPayment)

  //Setup form
  const formObj = useForm<{ amountChoice: number | null }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amountChoice: workingContributionPaymentData?.amountChoice
    },
  })
  const { handleSubmit, trigger, setValue, watch, formState: { isValid} } = formObj

  const percentage = `${PERSONAL_TAX_RELIEF_FACTOR * 100}%`

  const existingRegularAmount = existingRegularContribution?.amount || 0

  const onSubmit = attributes => {
    //Navigate based on choice
    if (attributes.amountChoice === 0) {
      navigation.navigate(nextScreen) //Go to next screen to customise
    } else {
      dispatch(updateWorkingContributionSetupPayment({
        amount: attributes.amountChoice
      }))
      navigation.navigate('RegularDayOfMonth') //Skip custom amount
    } 
  }

  const personalContribution = existingContributionConfiguration?.source === ContributionSource.PERSONAL

  const amountOptions: ManagedSimpleChoiceItem[] = STATIC_AMOUNTS.map(amount => {
    return {
      value: amount,
      label: `${formatCurrencyAmount(amount)}`
    }    
  })
  amountOptions.push({
    value: 0,
    label: `A different amount`
  })

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={'How much do you want to contribute monthly?'}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.PENSION_ANNUAL_ALLOWANCE} buttonTitle={'About Annual Allowances'}/>}
    >
      <ManagedSimpleChoiceInput
        name={'amountChoice'}
        formObj={formObj}
        options={amountOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      {
        existingRegularAmount
          ? <Paragraph>{`You current monthly contributions are ${formatCurrencyAmount(existingRegularAmount, 2)}`}</Paragraph>        
          : <></>
      }
      <Paragraph>
        {personalContribution ? `As you have chosen to make contributions from your personal funds, you will receive an additional ${percentage} from the UK government` : ''}
      </Paragraph>
    </ModalProcessScreen>
  )
}

