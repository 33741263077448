import { ManagedSimpleChoiceInput } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Title } from 'store/dto/base.dto'
import { UserDto, UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_04_Title = ({ route, navigation }) => {
  const { nextScreen, user, metadata }: { nextScreen: string, user: UserDto, metadata: UserMetadata } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ title: Title }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: workingClientData?.title || metadata?.title || user?.metadata?.title 
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Which title do you normally use?`}
      subHeading={`If you can't see it, just pick the closest fit`}
      
    >
      <ManagedSimpleChoiceInput
        name={'title'}
        formObj={formObj}
        options={enumToAutocompleteOptions(Title)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
