import { MaterialCommunityIcons } from "@expo/vector-icons"
import { buildAddressDescription } from 'lib/addressHelpers'
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import { getActionDate } from "lib/dateHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import { orderBy } from 'lodash'
import React from 'react'
import { Image, View } from "react-native"
import { ScrollView } from 'react-native'
import { RetirementAssetDto, RetirementAssetTransferAttempt, RetirementAssetTransferStatus } from "store/dto/retirement-asset.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { StyleSheet } from "react-native"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from 'lib/platformHelpers'
import { AppIllustration } from "components/Utility/AppIllustration"

const isWeb = platformIsWeb()

type TransferLogModalProps = {
  asset: RetirementAssetDto
  visible: boolean
  onDismiss: any
}

export const TransferLogModal = (props: TransferLogModalProps) => {
  const { asset, visible, onDismiss } = props || {}

  const { currentValue, transferAttempts } = asset || {}

  const sortedAttempts = orderBy(transferAttempts, ['updatedAt'], ['desc'])

  const currentTransferAttempt: RetirementAssetTransferAttempt = sortedAttempts.length ? sortedAttempts[0] : undefined

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}          
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <AppIllustration filename={'record_an_existing_pension.png'} style={{
                  width: Sizing.x150,
                  height: Sizing.x150,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }} />
              </View>
            </View>

            <Headline>{'Transfer Log'}</Headline>
            <ContentDivider />
            {
              currentTransferAttempt ?
              <>
                <Subheading style={localStyles.subTitle}>{`Previous Provider Details`}</Subheading>
                <Paragraph>{currentTransferAttempt?.previousProviderName || 'Unknown'}</Paragraph>
                <Paragraph>{`Reference: ${currentTransferAttempt?.previousProviderRef || 'Unknown'}`}</Paragraph>
                <Paragraph>{`Address: ${currentTransferAttempt?.transferAddress ? buildAddressDescription(currentTransferAttempt?.transferAddress) : 'Unknown'}`}</Paragraph>
                <ContentDivider />
                <Subheading style={localStyles.subTitle}>{`Value`}</Subheading>
                <Paragraph>{`Original Estimate: ${formatCurrencyAmount(currentValue)}`}</Paragraph>
                <Paragraph>{`${asset?.transferStatus === RetirementAssetTransferStatus.COMPLETED ? `Transferred Amount` : `Current Estimate`}: ${formatCurrencyAmount(currentTransferAttempt?.transferValue, 2)}`}</Paragraph>
                <ContentDivider />
                <Subheading style={localStyles.subTitle}>{`Status Updates`}</Subheading>
                {
                  currentTransferAttempt?.statusChanges?.map(((data, idx) => (
                    <>
                      <Paragraph key={idx} style={{textAlign: 'left'}}>{getActionDate(data?.statusDate, true)}: {data?.transferStatus || data?.status} - {data?.description}</Paragraph>
                    </>
                  )))
                }
              </>
              : <></>
            }
          </ScrollView>
          <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  subTitle: {
    paddingTop: Sizing.x10,
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
})