import { BaseDatedDto } from "./base.dto"

export enum RetirementIncomeType {
  DEFINED_BENEFIT = 'defined_benefit',
  PROPERTY = 'property',
  EMPLOYMENT = 'employment',
  OTHER = 'other',
}

export interface RetirementIncomeDto extends BaseDatedDto {
  id: string
  type: string
  name: string
  clientId: string
  incomeType: RetirementIncomeType
  annualIncomeAmount: number
  incomeStartDate?: string
  incomeStartAge?: number
}

export interface CreateRetirementIncomeDto {
  id: string
  clientId: string
  name: string
  incomeType: RetirementIncomeType
  annualIncomeAmount: number
  incomeStartAge?: number
}

export interface UpdateRetirementIncomeDto {
  id: string
  name?: string
  incomeType?: RetirementIncomeType
  annualIncomeAmount?: number
  incomeStartAge?: number
}