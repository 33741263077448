import { addDays, isAfter, startOfDay } from 'date-fns'
import { GroupSchemeEnrolmentDto, GroupSchemeEnrolmentStatus, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { CreateGroupSchemeJobRecordMemberDto, GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'

export const deriveAvailableStatusChangeRequestType = (enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, pendingRequestTypes: GroupSchemeRequestType[]): GroupSchemeRequestType => {
  const { enrolmentStatus } = enrolment || {}
  if (enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE) {
    const pendingOptOut = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_OUT)
    const pendingCease = pendingRequestTypes.includes(GroupSchemeRequestType.CEASE_MEMBERSHIP)
    return pendingOptOut || pendingCease
      ? undefined
      : isWithinOptOutWindow(enrolment)
        ? GroupSchemeRequestType.OPT_OUT
        : GroupSchemeRequestType.CEASE_MEMBERSHIP
  } else {
    const pendingOptIn = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_IN)
    return pendingOptIn ? undefined : GroupSchemeRequestType.OPT_IN
  }
}

export const isWithinOptOutWindow = (enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto | GroupSchemeMemberDto ): boolean => {
  const { autoEnrolmentWindowOptOutDate } = enrolment || {}
  const now = new Date()
  const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
  return !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)
}

export const mapMemberToEnrolmentJobMember = (member: GroupSchemeMemberDto ): CreateGroupSchemeJobRecordMemberDto => {
  const {
    id,
    firstName,
    surname,
    title,
    gender,
    birthDate,
    nationalInsuranceNo,
    addressDetail,
    enrolmentStatus,
    autoEnrolmentOptOutDate,
    autoEnrolmentWindowOptOutDate,
    employeeEmail,
    employeeId,
    employmentStartDate,
    enrolmentDate,
    enrolmentEndDate,
    enrolmentEndReason,
  } = member
  return {
    memberId: id,
    firstName,
    surname,
    title,
    gender,
    birthDate,
    nationalInsuranceNo,
    addressDetail,
    autoEnrolmentWindowOptOutDate,
    employeeEmail,
    employeeId,
    employmentStartDate,
    enrolmentDate,
    autoEnrolmentOptOutDate: enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT ? autoEnrolmentOptOutDate : null,
    enrolmentEndDate: enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? enrolmentEndDate : null,
    enrolmentEndReason: enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? enrolmentEndReason : null,
  }
}