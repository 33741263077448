import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { map } from 'lodash'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Colors, Sizing } from 'styles'
import { ContentDivider } from 'components/Layout/ContentDivider'

type RiskRatingProps = {
  riskRating: number
  riskRatingScaleMax: number
}

export const RiskRating = (props: RiskRatingProps) => {
  const { riskRating, riskRatingScaleMax } = props
  
  const riskRatingArray = map(new Array(riskRatingScaleMax || 0), (el, i) => i === riskRating - 1)

  return (
    <>
      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Risk and Reward Level'}</Subheading>
      <Paragraph style={localStyles.text}>{`Where this plan sits on the scale of risk and potential reward:`}</Paragraph>
      <View style={{ paddingVertical: Sizing.x10 }}>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
          <View style={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
            <Text style={{ ...localStyles.smallText, textAlign: 'left' }}>{'Lower risk'}</Text>
          </View>
          <View style={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
            <Text style={{ ...localStyles.smallText, textAlign: 'right' }}>{'Higher risk'}</Text>
          </View>
        </View>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          {
            riskRatingArray ? riskRatingArray.map((value, idx) => {
              return (
                <View
                  key={idx}
                  style={{
                    marginVertical: Sizing.x5,
                    flexGrow: 1,
                    borderColor: Colors.neutral.black,
                    borderWidth: Sizing.x1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: value ? Colors.neutral.black : undefined,
                  }}
                >
                  <Subheading style={{
                    ...localStyles.subTitle,
                    color: value ? Colors.neutral.white : Colors.neutral.black
                  }}>{idx + 1}</Subheading>
                </View>
              )
            }) : <></>
          }
        </View>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
          <View style={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
            <Text style={{ ...localStyles.smallText, textAlign: 'left' }}>{'Typically lower rewards'}</Text>
          </View>
          <View style={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
            <Text style={{ ...localStyles.smallText, textAlign: 'right' }}>{'Typically higher rewards'}</Text>
          </View>
        </View>
      </View>
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})