import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset } from "store/tempDataSlice"
import { SchemeEnrolmentPreviousPensionAdd_00_Intro, SchemeEnrolmentPreviousPensionAdd_01_ProviderBrand, SchemeEnrolmentPreviousPensionAdd_02_Value, SchemeEnrolmentPreviousPensionAdd_03_Reference, SchemeEnrolmentPreviousPensionAdd_04_Name, SchemeEnrolmentPreviousPensionAdd_99_Result } from "./Screens"
import { PublicGroupSchemeDto } from "store/dto/group-scheme.dto"

export type SchemeEnrolmentPreviousPensionAddProcessStackProps = {
  groupScheme: PublicGroupSchemeDto
  visible: boolean
  onComplete: any
  onDismiss: any
}

export const SchemeEnrolmentPreviousPensionAddProcessStack = (props: SchemeEnrolmentPreviousPensionAddProcessStackProps) => {
  const { onComplete, groupScheme, onDismiss, visible } = props

  const dispatch = useAppDispatch()

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingRetirementAsset(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding this pension?`}
      processTitle='Add Previous Pension'
      initialRouteName='Intro'
      initialParams={{
        groupScheme,
        onComplete,
        onDismiss,
        visible,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Asset',
          component: SchemeEnrolmentPreviousPensionAdd_00_Intro,
        },
        {
          navigationId: 'Brand',
          title: 'Brand',
          component: SchemeEnrolmentPreviousPensionAdd_01_ProviderBrand,
        },
        {
          navigationId: 'Value',
          title: 'Current Value',
          component: SchemeEnrolmentPreviousPensionAdd_02_Value,
        },
        {
          navigationId: 'Reference',
          title: 'Reference',
          component: SchemeEnrolmentPreviousPensionAdd_03_Reference,
        },
        {
          navigationId: 'Name',
          title: 'Name',
          component: SchemeEnrolmentPreviousPensionAdd_04_Name,
        },
        {
          navigationId: 'Results',
          title: '',
          component: SchemeEnrolmentPreviousPensionAdd_99_Result,
        },
      ]}
    />
  )
}

