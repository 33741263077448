import React from 'react'
import { View } from 'react-native'
import { Typography } from 'styles'
import Row from './Row'
import { useGetBeneficiariesQuery } from 'store/apiSlice'
import { BeneficiaryRelationship, Nomination } from 'store/dto/account.dto'
import { Loading } from 'components/Utility/Loading'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { useAppDispatch } from 'store/hooks'
import { setAddBeneficiaryData, setEditBeneficiaryData } from 'store/uxSlice'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'

export const BeneficiariesList = () => {
  const dispatcher = useAppDispatch()
  const { data: beneficiaries, error: beneficiariesError, isLoading: beneficiariesIsLoading } = useGetBeneficiariesQuery()

  const nominations = beneficiaries?.nominations || []

  const handleEditRoute = (id: number, currentNomination: Nomination) => {
    dispatcher(
      setEditBeneficiaryData({
        editIndex: id,
        allNominations: nominations,
        currentNomination,
      })
    )
  }

  const handleAddRoute = () => {
    dispatcher(setAddBeneficiaryData({
      allNominations: nominations
    }))
  }

  const beneficiaryRows = nominations?.map((data: Nomination, id: number) => {
    return (
      <Row
        pressable={true}
        key={id}
        onPressFunction={() => handleEditRoute(id, data)}
        mainText={`${data?.firstName} ${
          data?.beneficiaryRelationship === BeneficiaryRelationship.CHARITY
            ? ''
            : data?.surname
        }`}
        rightText={formatPercentageAmount(data.beneficiaryProportion * 100, 2)}
      />
    )
  })
  return (
    <View>
      {
        beneficiariesIsLoading ? <Loading message={getLookingLoadingMessages('Loading your beneficiaries...')} /> :
        beneficiaryRows
      }
      {
        nominations?.length >= 4 ? <></> : 
        <Row
          pressable={true}
          onPressFunction={handleAddRoute}
          mainText="Add Beneficiary"
          iconName={'plus'}
        />
      }
      <Row
        mainText="Total Allocated"
        rightText={nominations?.length ? '100%' : '0%'}
        otherStyle={{...Typography.fontWeight.bold}}
      />
    </View>
  )
}
