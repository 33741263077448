import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import React from 'react'
import { Image, View } from "react-native"
import { RetirementAssetDto } from "store/dto/retirement-asset.dto"
import { Sizing } from "styles"
import { ContentDivider } from "components/Layout/ContentDivider"

type RetirementAssetAbandonedModalContentProps = {
  asset: RetirementAssetDto
  imageSource: any
  isSelected: boolean
}


export const RetirementAssetAbandonedModalContent = (props: RetirementAssetAbandonedModalContentProps) => {
  const { asset, imageSource, isSelected} = props
  const { name, currentValue } = asset

  return (
    <>
      <View style={{
        alignSelf: 'center',
        paddingVertical: Sizing.x20,
      }}>
        <Image source={imageSource} style={{
          width: scaleNormalizer(100),
          height: scaleNormalizer(100),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Headline>{name}</Headline>
      <Subheading>{`Estimated Value: ${formatCurrencyAmount(currentValue)}`}</Subheading>
      <ContentDivider />
      <Paragraph>{`You previously chose to abandon the transfer of this pension`}</Paragraph>
      <Paragraph>{`If you are now interested in transferring it, you can start a new transfer from the Retirement Assets area`}</Paragraph>
    </>
  )
}

