import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { ModelGoalIdentifier, useGuidanceContext } from 'providers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetRetirementProfileQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'

export const Contributions_03_Goals = ({ route, navigation }) => {
  const { nextScreen, existingRegularContribution } = route.params

  const dispatch = useAppDispatch()
  const { currentGoals, currentModel, currentModelIsLoading, currentModelIsFetching, currentModelError, refetchCurrentModel } = useGuidanceContext()

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()

  const isLoading = rpIsLoading || currentModelIsLoading || currentModelIsFetching
  const error: any = rpError || currentModelError

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const existingRegularAmount = existingRegularContribution?.amount || 0

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const formObj = useForm<{ goal: ModelGoalIdentifier }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      goal: workingSuggestionData?.goal
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const goalOptions: ManagedSimpleChoiceItem[] = currentGoals.map(goal => {
    return {
      value: goal.id,
      label: goal.title
    }
  })

  const onSubmit = (attributes) => {
    dispatch(updateWorkingSuggestion(attributes))
    switch (attributes.goal) {
      case ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE:
        navigation.navigate('SuggestionsAchieveTRA')
        break
      case ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE:
        navigation.navigate('AgeChoiceReduceVRA')
        break
      case ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS:
        navigation.navigate('StrategyChoiceIncreaseSurplus')
        break
      case ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE:
        navigation.navigate('AgeChoiceReduceTRA')
        break
      default:
        navigation.navigate(nextScreen)
    }    
  }

  const goToManual = () => {
    hideDialog()
    navigation.navigate('ManualSetup')
  }

  const reduceViableGoal = currentGoals ? currentGoals.find(goal => {
    return goal.id === ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE
  }) : undefined

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={currentModelError ? refetchCurrentModel : refetchRp}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`Ok, what goal shall we aim for?`}
      subHeading={'Jarvis suggests relevant goals depening on your current forecast'}
      allowTextButton={true}
      textButtonTitle={existingRegularAmount ? `Change contributions manually` : `Set up contributions manually`}
      textButtonAction={showDialog}
    >  
    <ManagedSimpleChoiceInput
      name={'goal'}
      formObj={formObj}
      options={goalOptions}
      submitHandler={handleSubmit(onSubmit)}
      required={true}
    />
    {
      reduceViableGoal && currentModel ?
      <>
        <Subheading>{`Your current achievable age is ${currentModel?.output?.viableRetirementAge}`}</Subheading>
        <Paragraph>{`Choose the option to 'Reduce' if you want to aim for an age somewhere between ${retirementProfile?.targetRetirementAge} and ${currentModel?.output?.viableRetirementAge}.`}</Paragraph>
      </>
      
      : <></>
    }
    <ConfirmationDialog
      visible={dialogVisible}
      title={'Are you sure?'}
      content={`You can always choose to contribute any amount, however you will only see the impact after your changes`}
      onCancel={hideDialog}
      onConfirm={goToManual}
      confirmLabel={'Set up manually'}
    />
    </ProcessScreen>

  )
}