import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import MultiSlider from "@ptomasroos/react-native-multi-slider"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { SegmentBadge } from "components/Typography/SegmentBadge"
import { Text } from "components/Typography/Text"
import { format } from "date-fns"
import { getScreenAppWidth, scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount, formatPercentageAmount } from "lib/generalHelpers"
import { floor, maxBy, min, minBy, range, round, sumBy } from 'lodash'
import React, { useState } from 'react'
import { Image, ScrollView, StyleSheet, View } from "react-native"
import { Portal } from "react-native-paper"
import { useGetAssetsQuery } from 'store/apiSlice'
import { AssetDto } from "store/dto/asset.dto"
import { ClientClientSegmentDto } from "store/dto/client.dto"
import { AssetAllocationDto, AccountDto } from 'store/dto/account.dto'
import { Colors, Flex, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { FooterButton } from "./FooterButton"
import { InvestmentAssetModalContent } from "./InvestmentAssetModalContent"
import { TieredChargeTable } from "./TieredChargeTable"
import { UnborderedTable, UnborderedTableRow } from "./UnborderedTable"
import { ContentDivider } from "components/Layout/ContentDivider"
import { AppIllustration } from "./AppIllustration"

type PensionAssetAllocationModalContentProps = {
  assetAllocation: AssetAllocationDto[]
  accountDescriptor: string
  segment: ClientClientSegmentDto
}

type ChargeCalculation = {
  platformRate: number
  fundRate: number
  totalRate: number
  platformAmount: number
  fundAmount: number
  totalAmount: number
}

type AllocationWithAsset = {
  allocation: AssetAllocationDto
  asset: AssetDto
}

const MAX_SLIDER_VALUE = 500000
const SLIDER_VALUE_STEP = 5000
const DEFAULT_SLIDER_VALUE = 50000

export const PensionAssetAllocationModalContent = (props: PensionAssetAllocationModalContentProps) => {
  const { assetAllocation, accountDescriptor, segment } = props

  const [showAsset, setShowAsset] = useState(undefined)
  
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()

  const allocationsWithAssets: AllocationWithAsset[] = assets && assetAllocation ? assetAllocation.map(allocation => {
    const asset = assets.find(asset => {
      return asset.id === allocation.assetId
    })
    return {
      allocation,
      asset,
    }
  }) : []

  const weightedAnnualFeePercentage = sumBy(allocationsWithAssets, function(allocationWithAsset: AllocationWithAsset) {
    const assetAnnualFundCharge = allocationWithAsset?.asset?.annualFeePercentage || 0
    return assetAnnualFundCharge * allocationWithAsset?.allocation?.proportion || 0
  })

  const weightedInitialFeePercentage = sumBy(allocationsWithAssets, function(allocationWithAsset: AllocationWithAsset) {
    const assetInitialFundCharge = allocationWithAsset?.asset?.initialFeePercentage || 0
    return assetInitialFundCharge * allocationWithAsset?.allocation?.proportion || 0
  })
  
  const platformChargeTiers = segment?.platformCharge?.tiers || []
  const discount = segment?.platformChargeDiscount || 1
  const maxPlatformFee = maxBy(platformChargeTiers, 'rate')
  const minPlatformFee = minBy(platformChargeTiers, 'rate')
  const maxStandardTotalFee = weightedAnnualFeePercentage + (maxPlatformFee?.rate || 0)
  const minStandardTotalFee = weightedAnnualFeePercentage + (minPlatformFee?.rate || 0)
  const maxDiscountedTotalFee = weightedAnnualFeePercentage + floor((maxPlatformFee?.rate || 0) * discount, 2)
  const minDiscountedTotalFee = weightedAnnualFeePercentage + floor((minPlatformFee?.rate || 0) * discount, 2)

  const segmentHasConditions = segment?.requiredBalance && segment?.monthsToReachRequiredBalance && segment?.dateToReachRequiredBalance
  
  const calculateApplicableCharge = (value): ChargeCalculation => {
    if (value === 0) {
      return {
        fundRate: 0,
        platformRate: 0,
        totalRate: 0,
        fundAmount: 0,
        platformAmount: 0,
        totalAmount: 0,
      }
    }
    let totalAmount = 0
    platformChargeTiers.forEach(tier => {
      const { fromAmount, toAmount, rate } = tier
      const discountedRate = floor(rate * discount, 2)
      if (fromAmount < value) {
        const chargeable = (toAmount ? min([toAmount, value]) : value) - fromAmount
        const charge = chargeable * ((discountedRate + weightedAnnualFeePercentage) / 100)
        totalAmount += charge
      }
    })
    const totalRate = round((totalAmount / value) * 100, 2)
    const platformRate = totalRate - weightedAnnualFeePercentage
    const fundAmount = value * (weightedAnnualFeePercentage / 100)
    const platformAmount = totalAmount - fundAmount
    return {
      fundRate: weightedAnnualFeePercentage,
      platformRate,
      totalRate,
      fundAmount,
      platformAmount,
      totalAmount,
    }
  }
  
  const [sliderValue, setSliderValue] = useState(DEFAULT_SLIDER_VALUE)
  const [chargeCalculations, setChargeCalculations] = useState<ChargeCalculation>(calculateApplicableCharge(DEFAULT_SLIDER_VALUE))

  const onValuesChange = position => {
    setSliderValue(position[0])
    const calcs = calculateApplicableCharge(position[0])
    setChargeCalculations(calcs)
  }

  function SliderMarker() {
    return (
      <View style={localStyles.sliderMarkerOuter}>
        <View style={localStyles.sliderMarkerInner}>
          <MaterialIcons
            name="keyboard-arrow-left"
            size={Sizing.x25}
            color={Colors.neutral.white}
            style={localStyles.sliderMarkerIcon}
          />
          <MaterialIcons
            name="keyboard-arrow-right"
            size={Sizing.x25}
            color={Colors.neutral.white}
            style={localStyles.sliderMarkerIcon}
          />
        </View>
      </View>
    )
  }

  const allocationTableData: UnborderedTableRow[] = allocationsWithAssets.map(allocationWithAsset => {
    return {
      label: <Text style={{ textDecorationLine: 'underline' }}>{allocationWithAsset?.asset?.shortName || 'Unknown'}</Text>,
      value: formatPercentageAmount(allocationWithAsset?.allocation.proportion * 100, 2) || '',
      linkFunction: () => setShowAsset(allocationWithAsset?.asset),
    }
  })
  allocationTableData.push({
    label: `Total`,
    value: formatPercentageAmount(100),
    isTotal: true
  })

  return (
    <>

      <Headline style={localStyles.title}>{`Investment Summary`}</Headline>

      <ContentDivider />
      <View style={{
        alignSelf: 'center',
      }}>
        <AppIllustration filename={'control_your_financial_future.png'} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Paragraph style={localStyles.text}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Fund Allocation'}</Subheading>
      <Paragraph style={{ textAlign: 'left' }}>{`Your ${accountDescriptor} is currently invested in the following funds:`}</Paragraph>
      <UnborderedTable
        data={allocationTableData}
      />
      <Paragraph style={localStyles.smallText}>{`* Your exact allocations may vary, but our goal is to invest your assets as close as possible to these allocations.`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'All-in Annual Charge'}</Subheading>
      {
        segment?.platformChargeDiscount !== 0
          ? <>
              <Paragraph style={localStyles.text}>
                <Paragraph style={{ textDecorationLine: 'line-through' }}>{`${formatPercentageAmount(minStandardTotalFee, 2)} - ${formatPercentageAmount(maxStandardTotalFee, 2)}`}</Paragraph>
                <Paragraph style={{ fontWeight: '900', textDecorationLine: 'none' }}>{` ${formatPercentageAmount(minDiscountedTotalFee, 2)} - ${formatPercentageAmount(maxDiscountedTotalFee, 2)}`}</Paragraph>
              </Paragraph>
              
            </>
          : <Paragraph style={[localStyles.text, { fontWeight: '900' }]}>{`${formatPercentageAmount(minStandardTotalFee, 2)} - ${formatPercentageAmount(maxStandardTotalFee, 2)}`}</Paragraph>
      }

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Charges Breakdown'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Monthly Subscription Fee`,
            value: <Text style={{ fontWeight: '900' }}>{formatCurrencyAmount(0, 0)}</Text>
          },
          {
            label: <Text style={{ fontWeight: '900' }}>{`Annual Platform Charges`}</Text>,
            value: ``
          }
        ]}
      />
      {
        segment?.isPromotion
        ? <>
            <Paragraph>
              {`You receive a `}
              <Paragraph style={{ fontWeight: '900' }}>{formatPercentageAmount(segment?.platformChargeDiscount * 100, 0)}</Paragraph>
              {` discount on our standard platform charges. ${segmentHasConditions ? `Conditions Apply *` : ''}`}
            </Paragraph>
            <SegmentBadge
              segment={segment}
              withoutInfoModal={true}
              containerStyle={{
                paddingVertical: Sizing.x10,
              }}
            />
          </>
        : <></>
      }
      <TieredChargeTable
        tiers={platformChargeTiers}
        discount={segment?.platformChargeDiscount || 0}
      />
      <UnborderedTable
        data={[
          {
            label: <Text style={{ fontWeight: '900' }}>{`Fund Charges`}</Text>,
            value: ``
          }
        ]}
      />
      <UnborderedTable
        data={[
          {
            label: `Initial Charge (Weighted)`,
            value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(weightedInitialFeePercentage, 2)}</Text>
          },
          {
            label: `Annual Charge (Weighted)`,
            value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(weightedAnnualFeePercentage, 2)}</Text>
          },
        ]}
      />
      {
        segmentHasConditions
        ? <Paragraph style={localStyles.smallText}>{`* To continue benefiting from the ${segment?.badgeName} discount, your Jarvis balance needs to reach at least ${formatCurrencyAmount(segment?.requiredBalance, 2)} within ${segment?.monthsToReachRequiredBalance} months of becoming a client (by ${format(new Date(segment?.dateToReachRequiredBalance),'do MMMM yyyy')})`}</Paragraph>
          : <></>
      }
      
      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Charges Calculator'}</Subheading>
      <Paragraph style={localStyles.text}>{`Use the slider below to see the total applicable annual charge for different fund values.`}</Paragraph>
      <Paragraph>{`Value of your ${accountDescriptor}:`}</Paragraph>
      <Headline style={{fontWeight: '900'}}>{formatCurrencyAmount(sliderValue,0)}</Headline>
      <MultiSlider
        values={[sliderValue]}
        onValuesChange={onValuesChange}
        optionsArray={range(0, MAX_SLIDER_VALUE, SLIDER_VALUE_STEP)}
        sliderLength={getScreenAppWidth() * 3 / 4}
        customMarker={SliderMarker}
        unselectedStyle={localStyles.sliderTrack}
        selectedStyle={localStyles.sliderTrack}
        containerStyle={localStyles.sliderContainer}
      />
      <Paragraph>{'Total Applicable Annual Charges:'}</Paragraph>
      <Headline style={{fontWeight: '900'}}>{formatPercentageAmount(chargeCalculations?.totalRate || 0, 2)}</Headline>
      <Paragraph style={{ textAlign: 'left' }}>{'Consisting of:'}</Paragraph>
      <UnborderedTable
        data={[
          {
            label: `Platform charges (${formatPercentageAmount(chargeCalculations?.platformRate || 0, 2)})`,
            value: <Text>{formatCurrencyAmount(chargeCalculations?.platformAmount || 0, 2)}</Text>
          },
          {
            label: `Fund charges (${formatPercentageAmount(chargeCalculations?.fundRate || 0, 2)})`,
            value: <Text>{formatCurrencyAmount(chargeCalculations?.fundAmount || 0, 2)}</Text>
          },
          {
            label: `Total`,
            value: formatCurrencyAmount(chargeCalculations?.totalAmount || 0, 2),
            isTotal: true
          }
        ]}
      />
      <Paragraph style={localStyles.smallText}>{`* These charges are applicable to the proportion of your assets invested in this fund. When building your own portfolio from multiple funds, different fund charges may apply to different proportions of your investment. However, platform charges will be calculated on the overall total.`}</Paragraph>
      <ContentDivider />
      {
        showAsset ?
        <Portal>
          <CardModal visible={showAsset} onDismiss={() => setShowAsset(undefined)} >
            <View style={layoutStyles.cardModalTitleContainer}>
              <View></View>
              <View style={layoutStyles.cardModalTitleTextContainer}></View>
              <View>
                <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={() => setShowAsset(undefined)} color={Colors.brand.purple1} />
              </View>
            </View>
            <View style={layoutStyles.cardModalContentContainer}>
              <ScrollView
                contentContainerStyle={{
                  paddingVertical: Sizing.x20,
                }}
                showsVerticalScrollIndicator={true}
              >
                <View style={layoutStyles.cardModalContentContainer}>
                  <InvestmentAssetModalContent
                    accountDescriptor={accountDescriptor}
                    asset={showAsset}
                    segment={segment}
                    omitPlatformCharges={true}
                  />
                </View>
              </ScrollView>
              <FooterButton onPress={() => setShowAsset(undefined)} mode={'text'} >{'Close'}</FooterButton>
            </View>
          </CardModal>
        </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  sliderOuterContainer: {
    width: '100%',
  },
  sliderContainer: {
    width: '100%',
    height: Sizing.x70,
    alignItems: 'center',
  },
  sliderTrack: {
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(8),
    borderRadius: scaleNormalizer(4)
  },
  sliderMarkerOuter: {
    marginTop: scaleNormalizer(7),
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(40),
    width: scaleNormalizer(40),
    borderRadius: scaleNormalizer(20)
  },
  sliderMarkerInner: {
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.purple2,
    height: scaleNormalizer(30),
    width: scaleNormalizer(30),
    borderRadius: Sizing.x15,
  },
  sliderMarkerIcon: {
    padding: scaleNormalizer(-8),
    margin: scaleNormalizer(-8)
  },
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
    textAlign: 'left',
  },
})