import { ManagedAddressInput } from 'components/Inputs/ManagedAddressInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { isObject } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { Address } from 'store/dto/base.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_03_AddressSearch = ({ route, navigation }) => {
  const { nextScreen, client }: { nextScreen: string, client: ClientMeDto } = route?.params || {}
  const dispatch = useAppDispatch()

  const workingClientData = useAppSelector(workingClient)

  const { data: user, isLoading, error, refetch } = useGetCurrentUserQuery()

  //Setup form
  const formObj = useForm<{ addressDetail: Address }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      addressDetail: workingClientData?.addressDetail || client?.addressDetail
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid} } = formObj

  const handleEnterManually=()=>{
    navigation.navigate(nextScreen, {
      manualEntry: true
    })

  }
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  //Function for ManagedAddressInput to use to call onSubmit without params
  const onSelect = () => {
    onSubmit(watch())
  }
 
  const addressDetail = watch('addressDetail')

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isObject(addressDetail)}
      headline={`And what's your home address?`}
      subHeading={`We need your UK address to make sure you get the right tax relief on your pension`}
      allowTextButton={true}
      textButtonTitle={'Enter Manually'}
      textButtonAction={handleEnterManually}
    >
      <ManagedAddressInput
        onSelect={onSelect}
        googlePlacesApiKey={user?.gpak}
        formObj={formObj}
        autoFocus={true}
        name={'addressDetail'}
        countryRestriction={'gb'}
        required={true}
      />
    </ProcessScreen>
  )
}
