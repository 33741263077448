import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { Calculator_01_Disclaimer, Calculator_99_Result, Calculator_02_ReportEmail1, Calculator_31_BudgetResults, Calculator_60_Jarvis, Calculator_10_DateOfBirth, Calculator_11_Gender, Calculator_12_LifeExpectancy, Calculator_20_SpouseChoice, Calculator_21_SpouseDateOfBirth, Calculator_22_SpouseGender, Calculator_23_CombinedLifeExpectancy, Calculator_30_Budget, Calculator_40_StatePensions, Calculator_41_Income, Calculator_42_TotalAssets, Calculator_43_TotalMonthlyContributions, Calculator_50_Forecast, Calculator_51_ViableAge, Calculator_70_ReportEmail2 } from './Screens'

export const CalculatorProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='Calculator'
      processTitle='Find Your Retirement Age'
      previousRoute='CalculatorIntroScreen'
      nextRoute='CalculatorHeroScreen'
      initialRouteName={route?.params?.initialRouteName}
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Disclaimer',
          component: Calculator_01_Disclaimer,
        },
        { 
          navigationId: 'ReportEmail1',
          component: Calculator_02_ReportEmail1,
        },
        {
          navigationId: 'Age',
          component: Calculator_10_DateOfBirth,
        },
        {
          navigationId: 'Gender',
          component: Calculator_11_Gender,
        },
        {
          navigationId: 'LifeExpectancy',
          component: Calculator_12_LifeExpectancy,
        },
        {
          navigationId: 'SpouseChoice',
          component: Calculator_20_SpouseChoice,
        },
        {
          navigationId: 'SpouseAge',
          component: Calculator_21_SpouseDateOfBirth,
        },
        {
          navigationId: 'SpouseGender',
          component: Calculator_22_SpouseGender,
        },
        {
          navigationId: 'CombinedLifeExpectancy',
          component: Calculator_23_CombinedLifeExpectancy,
        },
        {
          navigationId: 'Budget',
          component: Calculator_30_Budget,
        },
        {
          navigationId: 'BudgetResults',
          component: Calculator_31_BudgetResults,
        },
        {
          navigationId: 'StatePensions',
          component: Calculator_40_StatePensions,
        },
        {
          navigationId: 'Income',
          component: Calculator_41_Income,
        },
        {
          navigationId: 'TotalAssets',
          component: Calculator_42_TotalAssets,
        },
        {
          navigationId: 'TotalMonthlyContributions',
          component: Calculator_43_TotalMonthlyContributions,
        },
        {
          navigationId: 'Forecast',
          component: Calculator_50_Forecast,
        },
        {
          navigationId: 'ViableAge',
          component: Calculator_51_ViableAge,
        },
        {
          navigationId: 'Jarvis',
          component: Calculator_60_Jarvis,
        },
        {
          navigationId: 'ReportEmail2',
          component: Calculator_70_ReportEmail2,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: Calculator_99_Result,
      }}
    />
  )
}
