import React, { ComponentProps } from 'react'
import { StyleSheet, View } from "react-native"
import { Flex, Sizing } from "styles"
import { Button } from './Button'

type FooterButtonProps = ComponentProps<typeof Button>

export const FooterButton = (props: FooterButtonProps) => {
  const { children, mode, confirmation } = props
  
  return (
    <View style={{
      ...localStyles.footer,
      height: mode === 'text' ? Sizing.x50 : Sizing.x60,
    }}>
      <View style={localStyles.buttonContainer}>
        <Button
          {...props}
          mode={mode || 'contained'}
        >
          {children}
        </Button>
      </View>
    </View>
  )
}

const localStyles = StyleSheet.create({
  footer: {    
    ...Flex.override.bottom,
    ...Flex.column.start,
    height: Sizing.x50,
    paddingTop: Sizing.x5,
    paddingBottom: Sizing.x5,
  },
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
})