import { View, Text } from 'react-native'
import React from 'react'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { backgroundStyles } from 'styles/common'
import { Loading } from 'components/Utility/Loading'
import { getLoginLoadingMessages } from 'lib/loadingHelpers'

export const Authentication_99_Results = () => {

  return (
    <View style={backgroundStyles.pageContainer}>
      <BackgroundGradientPurple>
        <Loading message={getLoginLoadingMessages()} messageNoLoop={true} useHolidayGraphics={true} />
      </BackgroundGradientPurple>
    </View>
  )
}