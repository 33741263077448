import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedAutoCompleteMultipleInput } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { isNilOrEmptyString } from 'lib/generalHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { orderBy, sortBy, startCase, lowerCase } from 'lodash'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetGroupPortfoliosQuery, useGetGroupSchemeQuery, useGetPensionBrandsQuery, useUpdateGroupSchemeMutation } from 'store/apiSlice'
import { GroupSchemeRequestType, PaymentMethod } from 'store/dto/account.dto'
import { GroupOrganizationMandateStatus } from 'store/dto/group-organization.dto'
import { GroupSchemeEnrolmentPayrollFrequency, GroupSchemeOption, GroupSchemeSalarySacrificeMode, GroupSchemeStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupSchemeDataId, setCurrentAdminGroupSchemeDataId, setCurrentAdminGroupSchemeSetRolesDataId } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

export const AdminEditGroupSchemesModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentGroupScheme = useAppSelector(currentAdminGroupSchemeDataId)

  const currentGroupSchemeId = useAppSelector(currentAdminGroupSchemeDataId)

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false)
  const [discardDialogVisible, setDiscardDialogVisible] = useState(false)

  const { data: groupScheme, isLoading: groupSchemeIsLoading, error: groupSchemeError } = useGetGroupSchemeQuery(currentGroupSchemeId, { skip: !currentGroupSchemeId } )
  const [updateGroupScheme, { data: updatedGroupScheme, isLoading: groupSchemeUpdateIsLoading, error: groupSchemeUpdateError, reset: groupSchemeUpdateReset }] = useUpdateGroupSchemeMutation()
  const [activateGroupScheme, { data: activatedGroupScheme, isLoading: groupSchemeActivationIsLoading, error: groupSchemeActivationError }] = useUpdateGroupSchemeMutation()
  const [inactivateGroupScheme, { data: inactivatedGroupScheme, isLoading: groupSchemeInactivationIsLoading, error: groupSchemeInactivationError }] = useUpdateGroupSchemeMutation()

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()

  const { data: groupPortfolios, isLoading: groupPortfoliosIsLoading, error: groupPortfoliosError, refetch: refetchGroupPortfolios } = useGetGroupPortfoliosQuery()

  const isWeb = platformIsWeb()

  const mandatedRequestTypes: GroupSchemeRequestType[] = [
    GroupSchemeRequestType.OPT_OUT,
    GroupSchemeRequestType.OPT_IN,
    GroupSchemeRequestType.CEASE_MEMBERSHIP,
  ]

  const availableRequestTypes: GroupSchemeRequestType[] = [
    GroupSchemeRequestType.OPT_IN,
    GroupSchemeRequestType.OPT_OUT,
    GroupSchemeRequestType.CEASE_MEMBERSHIP,
    GroupSchemeRequestType.SET_GROSS_CONTRIBUTION,
  ]

  const formObj = useForm<{
    name: string
    tprLetterCode: string
    dutiesStartDate?: string
    defaultEmployerContributionPercentage: number
    defaultEmployeeContributionPercentage: number
    salarySacrificeMode: GroupSchemeSalarySacrificeMode
    isAutoEnrolment: boolean
    defaultPayrollFrequency: GroupSchemeEnrolmentPayrollFrequency
    defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
    defaultGroupPortfolioId: string
    defaultEnrolmentName: string
    defaultTreatContributionsAsRegular: boolean
    previousPensionProviderBrandKey?: string
    previousPensionProviderBrandName?: string
    enabledRequestTypes?: GroupSchemeRequestType[]
    schemeOptions?: GroupSchemeOption[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, setError, watch, reset, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (groupScheme) {
      reset({
        name: groupScheme?.name,
        tprLetterCode: groupScheme?.tprLetterCode,
        dutiesStartDate: groupScheme?.dutiesStartDate,
        defaultEmployerContributionPercentage: groupScheme?.defaultEmployerContributionPercentage,
        defaultEmployeeContributionPercentage: groupScheme?.defaultEmployeeContributionPercentage,
        salarySacrificeMode: groupScheme?.salarySacrificeMode,
        isAutoEnrolment: groupScheme?.isAutoEnrolment,
        defaultPayrollFrequency: groupScheme?.defaultPayrollFrequency,
        defaultContributionFrequency: groupScheme?.defaultContributionFrequency,
        defaultGroupPortfolioId: groupScheme?.defaultGroupPortfolioId,
        defaultEnrolmentName: groupScheme?.defaultEnrolmentName,
        defaultTreatContributionsAsRegular: groupScheme?.defaultTreatContributionsAsRegular,
        previousPensionProviderBrandKey: groupScheme?.previousPensionProviderBrandKey,
        previousPensionProviderBrandName: groupScheme?.previousPensionProviderBrandName,
        enabledRequestTypes: groupScheme?.enabledRequestTypes || [],
        schemeOptions: groupScheme?.schemeOptions
      })
    }
  }, [groupScheme])

  //Form refs for focussing
  const nameRef = useRef(null)
  const tprLetterCodeRef = useRef(null)
  const dutiesStartDateRef = useRef(null)
  const defaultEmployerContributionPercentageRef = useRef(null)
  const defaultEmployeeContributionPercentageRef = useRef(null)
  const defaultEnrolmentNameRef = useRef(null)

  const onSubmit = async attributes => {
    const {
      defaultEmployerContributionPercentage,
      defaultEmployeeContributionPercentage,
      dutiesStartDate,
      ...remaining
    } = attributes
    await updateGroupScheme({
      id: groupScheme.id,
      dutiesStartDate: dutiesStartDate && dutiesStartDate !== '' ? dutiesStartDate : undefined,
      defaultEmployerContributionPercentage: defaultEmployerContributionPercentage && defaultEmployerContributionPercentage !== ''
        ? parseFloat(defaultEmployerContributionPercentage)
        : undefined,
      defaultEmployeeContributionPercentage: defaultEmployeeContributionPercentage && defaultEmployeeContributionPercentage !== ''
        ? parseFloat(defaultEmployeeContributionPercentage)
        : undefined,
      ...remaining,
    })
    setUpdateDialogVisible(false)
  }

  const handleSetActive = async () => {
    await activateGroupScheme({
      id: groupScheme.id,
      status: GroupSchemeStatus.ACTIVE,
    })
  }
    
  const handleSetInactive = async () => {
    await inactivateGroupScheme({
      id: groupScheme.id,
      status: GroupSchemeStatus.INACTIVE,
    })
  }

  useEffect(() => {
    if (updatedGroupScheme) {
      close()
    }
  }, [updatedGroupScheme])

  const close = () => {
    dispatch(setCurrentAdminGroupSchemeDataId(undefined))
  }

  const isLoading = groupPortfoliosIsLoading || groupSchemeIsLoading || brandsIsLoading || groupSchemeUpdateIsLoading || groupSchemeActivationIsLoading || groupSchemeInactivationIsLoading
  const error: any = groupPortfoliosError || groupSchemeError || brandsError || groupSchemeUpdateError || groupSchemeActivationError || groupSchemeInactivationError

  const { colors: themeColors } = Paper.useAppTheme()

  const groupPortfolioOptions: AutoCompleteItem[]  = groupPortfolios ? sortBy(groupPortfolios.map((groupPortfolio): AutoCompleteItem => {
    return {
      value: groupPortfolio.id,
      label: groupPortfolio.name,
      description: groupPortfolio.description,
      icon: () => <Image source={{ uri: groupPortfolio?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
    }
  }), 'name')  : []

  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  const schemeOptionDescriptionMap = {
    [GroupSchemeOption.AUTO_CONFIRM]: `Automatically confirm payments from contribution jobs completing without error.`,
    [GroupSchemeOption.AUTO_ARCHIVE]: `Automatically archive jobs completing without error.`,
    [GroupSchemeOption.IGNORE_NIL_RETURNS]: `For contribution jobs, ignore errors where employer/employee amounts are both zero.`,
  }
  const schemeOptionOptions: ManagedMultipleChoiceInputOption[] = Object.keys(GroupSchemeOption).map((key) => ({
    value: GroupSchemeOption[key],
    label: startCase(lowerCase(GroupSchemeOption[key])),
    description: schemeOptionDescriptionMap[GroupSchemeOption[key]]
  }))

  const defaultTreatContributionsAsRegularOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Treat as one-off contributions'
    },
    {
      value: true,
      label: 'Treat as regular contributions',
    },
  ]
  
  const isAutoEnrolmentOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ]

  const isNumeric = (value: string) => {
    if (isNilOrEmptyString(value)) {
      return true
    }
    return isNaN(parseFloat(value)) ? 'Must be a number': true
  }

  const isInRange = (value: string) => {
    if (isNilOrEmptyString(value)) {
      return true
    }
    const numValue = parseFloat(value)
    const result = numValue <= 100 && numValue >= 0
    return result ? true : 'Must be between 0 and 100'
  }

  const goToUserRolesView = () => {
    dispatch(setCurrentAdminGroupSchemeDataId(undefined))
    dispatch(setCurrentAdminGroupSchemeSetRolesDataId(currentGroupSchemeId))
  }

  return (
    <ModalEditScreen
      formTitle='Edit Group Scheme Details'
      onDismiss={() => dispatch(setCurrentAdminGroupSchemeDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this group scheme?'}
      error={error}
      errorCancel={groupSchemeUpdateError ? groupSchemeUpdateReset : close}
      errorTryAgain={
        groupSchemeUpdateError ? handleSubmit(onSubmit)
        : groupPortfoliosError ? refetchGroupPortfolios
        : brandsError ? refetchBrands
        : groupSchemeActivationError ? handleSetActive
        : groupSchemeInactivationError ? handleSetInactive
        : undefined}
      isLoading={isLoading}
      loadingMessage={groupSchemeUpdateIsLoading ? ['Saving group scheme...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={() => setUpdateDialogVisible(true)}
      showButton={true}
      enableButton={isDirty && isValid}
      allowTextButton={true}
      textButtonTitle={'Manage Users & Roles'}
      textButtonAction={isDirty ? () => setDiscardDialogVisible(true) : goToUserRolesView}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupScheme?.id,
            copyableValue: true,
          },
          {
            label: `Pension Scheme Reference`,
            value: groupScheme?.employerPensionSchemeReference,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Current Status`,
            value: groupScheme?.status,
            copyableValue: true,
          },
          {
            label: `Explanation`,
            value:
              groupScheme?.status === GroupSchemeStatus.ACTIVE ? `Employer has access and scheme is fully live.`
              : groupScheme?.status === GroupSchemeStatus.ONBOARDING ? `Employer has access to enrol members silently.`
              : `Scheme is inactive and cannot be accessed by the employer.`,
          }
        ]}
        noContentDivider={true}
      />
      <Button
          onPress={groupScheme?.status === GroupSchemeStatus.ACTIVE ? handleSetInactive : handleSetActive}
          mode={'text'}
          confirmation={{
            requiresConfirmation: true,
            dialogTitle: 'Are you sure?',
            dialogContent:
              groupScheme?.status === GroupSchemeStatus.ACTIVE ? 'This will prevent access by the employer to this scheme.\n\nThis change must also be communicated to Digi.'
              : groupScheme?.status === GroupSchemeStatus.ONBOARDING ? 'This will send notifications to all members that have been enrolled in an Active status.\n\nEmployer users with access to this scheme will be able to start submitting contributions.'
              : 'This will enable access by the employer to this scheme.\n\nNotifications will be sent to all users with access to this scheme.\n\nThis change must also be communicated to Digi.'
          }}
        >
          {
            groupScheme?.status === GroupSchemeStatus.ACTIVE ? 'Deactivate Scheme'
            : groupScheme?.status === GroupSchemeStatus.ONBOARDING ? 'Set Scheme Live'
            : 'Activate Scheme'
          }
        </Button>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Owning Organization`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupScheme?.organization?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: groupScheme?.organization?.name,
          },
          {
            label: `Company No`,
            value: groupScheme?.organization?.companyNo,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Scheme name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
        }}/>
      <ManagedTextInput
        ref={tprLetterCodeRef}
        name={'tprLetterCode'}
        formObj={formObj}
        label={'TPR Letter Code'}
        placeholder={'The Pensions Regulator Letter Code'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => dutiesStartDateRef.current?.focus()}
        rules={{
          required: false,
          minLength: 10,
          maxLength: 10,
      }}/>
      <Paragraph>{'Auto Enrolment Duties Start Date'}</Paragraph>
      <ManagedDateInput
        ref={dutiesStartDateRef}
        name={'dutiesStartDate'}
        formObj={formObj}
        blurOnSubmit={true}
        required={false}
        mustBeInPast={true}
      />
      <Paragraph>{'Contribution Configuration'}</Paragraph>
      <ManagedTextInput
        ref={defaultEmployerContributionPercentageRef}
        name={'defaultEmployerContributionPercentage'}
        formObj={formObj}
        label={'Default Employer Contribution Percentage'}
        blurOnSubmit={false}
        returnKeyType={'done'}
        keyboardType={'numeric'}
        submitHandler={() => defaultEmployeeContributionPercentageRef.current?.focus()}
        rules={{
          required: false,
          validate: {
            isNumeric,
            isInRange,
          }
        }}
      />
      <ManagedTextInput
        ref={defaultEmployeeContributionPercentageRef}
        name={'defaultEmployeeContributionPercentage'}
        formObj={formObj}
        label={'Default Employee Contribution Percentage'}
        blurOnSubmit={false}
        returnKeyType={'done'}
        keyboardType={'numeric'}
        rules={{
          required: false,
          validate: {
            isNumeric,
            isInRange,
          }
        }}
      />
      <ManagedAutoCompleteInput
        name={'salarySacrificeMode'}
        formObj={formObj}
        label={'Salary Sacrifice Mode'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(GroupSchemeSalarySacrificeMode)}
      />
      <Paragraph>{'Is an Auto Enrolment Scheme?'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'isAutoEnrolment'}
        formObj={formObj}
        options={isAutoEnrolmentOptions}
        required={true}
    />
      <Paragraph>{'Payroll Configuration'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'defaultPayrollFrequency'}
        formObj={formObj}
        label={'Default Payroll Frequency'}
        selectOnlyMode={true}
        required={false}
        dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
      />
      <ManagedAutoCompleteInput
        name={'defaultContributionFrequency'}
        formObj={formObj}
        label={'Default Contribution Frequency'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
      />
      <Paragraph>{'Investment Configuration'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'defaultGroupPortfolioId'}
        formObj={formObj}
        label={'Default Group Portfolio'}
        modalTitle={'Select Default Group Portfolio'}
        placeholder={'Select an default portfolio for new accounts'}
        searchPlaceholder={'Search for a group portfolio'}
        dataSet={groupPortfolioOptions}
        required={true}
      />
      <Paragraph>{'Member Enrolment Configuration'}</Paragraph>
      <ManagedTextInput
        ref={defaultEnrolmentNameRef}
        name={'defaultEnrolmentName'}
        formObj={formObj}
        label={'Default Account Name'}
        placeholder={'Default name for new accounts visible to end users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 30,
      }}/>
      <Paragraph>{'Allowed request types from members'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'enabledRequestTypes'}
        options={enumToAutocompleteOptions(GroupSchemeRequestType, mandatedRequestTypes, availableRequestTypes)}
      />
      <Paragraph>{'Forecasting Default Contribution Treatment'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'defaultTreatContributionsAsRegular'}
        formObj={formObj}
        options={defaultTreatContributionsAsRegularOptions}
        required={true}
      />
      <Paragraph>{'Previous Pension Provider Brand'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'previousPensionProviderBrandKey'}
        placeholder={'Tap to search...'}
        textCaptureFieldName={'previousPensionProviderBrandName'}
        allowFreeText={false}
        formObj={formObj}
        modalTitle={'Find Pension Brand'}
        required={false}
        dataSet={brandOptions}
        />
      <Paragraph>{'Scheme Workflow Options'}</Paragraph>
      <ManagedAutoCompleteMultipleInput
        name={'schemeOptions'}
        formObj={formObj}
        label={'Workflow Options for Scheme'}
        modalTitle={'Select Options'}
        placeholder={'Workflow options influencing job and payment workflow'}
        searchPlaceholder={'Search for options'}
        dataSet={schemeOptionOptions}
        required={false}
      />
      <ConfirmationDialog
        visible={updateDialogVisible}
        title={'Are you sure?'}
        content={`Any relevant changes must be communicated to Digi.`}
        onCancel={() => setUpdateDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
      <ConfirmationDialog
        visible={discardDialogVisible}
        onCancel={() => setDiscardDialogVisible(false)}
        title={'Are you sure?'}
        content={`Discard changes and edit user roles instead?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Discard Changes'}
        onConfirm={goToUserRolesView}
      />
    </ModalEditScreen>
  )
}

