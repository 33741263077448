import { BaseDatedDto, Gender, Title } from "./base.dto"
import { UserMetadata } from "./user.dto"

export enum InviteStatus {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  REVOKED = 'revoked',
}

export enum InviteSearchStatus {
  ANY = 'Any',
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  REVOKED = 'revoked',
}

export interface InviteDto extends BaseDatedDto {
  userId: string
  asSpouse: boolean
  email: string
  firstName: string
  surname: string
  status: InviteStatus
  from?: InviteFrom
  metadata?: UserMetadata
}

export interface InviteFrom {
  firstName?: string
  surname?: string
  email?: string
}


export interface CreateInviteDto {
  asSpouse: boolean
  email: string
  firstName: string
  surname: string
}

export interface AcceptInviteDto {
  id: string
  assetIdsToRetain?: string[]
  incomeIdsToRetain?: string[]
}