import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetInviteAssetsQuery, useGetInviteIncomesQuery, useGetInviteQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptInvite, workingAcceptInvite } from 'store/tempDataSlice'

export const SpouseAdd_11_Assets = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  const dispatch = useAppDispatch()
  
  const workingAcceptInviteData: any = useAppSelector(workingAcceptInvite)

  const { data: invite, error: inviteError, isLoading: inviteIsLoading, refetch: refetchInvites } = useGetInviteQuery(workingAcceptInviteData?.id, { skip: !workingAcceptInviteData?.id })
  const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch: refetchInviteAssets } = useGetInviteAssetsQuery(workingAcceptInviteData?.id, { skip: !workingAcceptInviteData?.id })
  const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchInviteIncomes } = useGetInviteIncomesQuery(workingAcceptInviteData?.id, { skip: !workingAcceptInviteData?.id })

  const refetchAll = () => {
    refetchInvites()
    refetchInviteAssets()
    refetchInviteIncomes()
  }

  const isLoading = inviteIsLoading || assetsIsLoading || incomesIsLoading
  const error: any = inviteError || assetsError || incomesError

  //Setup form
  const formObj = useForm<{
    assetIdsToRetain: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      assetIdsToRetain: workingAcceptInviteData?.assetIdsToRetain
        ? workingAcceptInviteData?.assetIdsToRetain
        : assets
          ? assets.map(asset => asset.id)
          : []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingAcceptInvite(attributes))
      if (incomes && incomes.length) {
        navigation.navigate('Incomes')
      } else {
        navigation.navigate('Results', { useInvite: true })
      }      
  }

  const options: ManagedMultipleChoiceInputOption[] = assets ? assets.map(asset => {
    return {
      value: asset.id,
      label: `${asset.name} (${formatCurrencyAmount(asset.currentValue)}) `,
    }
  }) : []

  const count = watch('assetIdsToRetain').length
  const hasSelected = count > 0

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={hasSelected ? `Retain ${count} Item${count > 1 ? 's' : ''}` : `Don't Retain Any`}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      headline={`Keep pensions and assets that ${invite?.from?.firstName || 'your partner'} added?`}
      subHeading={`Only deselect any you have already recorded in your Jarvis account`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.OTHER_PENSIONS} buttonTitle={`About Retirement Assets`} />}
    >
    <ManagedMultipleChoiceInput
      formObj={formObj}
      name={'assetIdsToRetain'}
      options={options}
    />
    </ModalProcessScreen>
  )
}
