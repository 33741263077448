import { ContentDivider } from 'components/Layout/ContentDivider'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { IncomeTabScreenName, RetirementIncomesList } from 'features/MenuSections/RegularIncomes/Components/RetirementIncomesList'
import { RetirementIncomesAddIncomeProcessStack } from 'features/ModalProcesses/RetirementIncomeAdd/RetirementIncomesAddIncomeProcessStack'
import { RetirementIncomesEditIncomeModal } from 'features/ModalProcesses/RetirementIncomeEdit/RetirementIncomesEditIncomeModal'
import { SpouseAddProcessStack } from 'features/ModalProcesses/SpouseAdd/SpouseAddProcessStack'
import { RetirementIncomesAddStatePensionProcessStack } from 'features/ModalProcesses/StatePensionAdd/RetirementIncomesAddStatePensionProcessStack'
import { RetirementIncomesEditStatePensionModal } from 'features/ModalProcesses/StatePensionEdit/RetirementIncomesEditStatePensionModal'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { isArray, sumBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Subheading } from 'react-native-paper'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { enableHeader, onIncomeSpouseView, setOnIncomeSpouseView } from 'store/uxSlice'
import { Flex, Sizing, Typography } from 'styles'

export const RetirementIncomeSetup_06_Incomes = ({ route, navigation }) => {
  const { nextScreen } =  route?.params || {}

  const dispatch = useAppDispatch()

  const [addSpouse, setAddSpouse] = useState(false)
  const [addIncomeClient, setAddIncomeClient] = useState(undefined)
  const [editIncome, setEditIncome] = useState(undefined)
  const [addSpClient, setAddSpClient] = useState(undefined)
  const [editSpClient, setEditSpClient] = useState(undefined)

  const [hasIncomes, setHasIncomes] = useState(false)

  const [showDialog, setShowDialog] = useState(false)

  const { data: me, isLoading: meIsLoading, error: meError, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError, isFetching: incomesIsFetching, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const refetchAll = () => {
    refetchMe()
    refetchSpouse()
    refetchIncomes()
  }

  // const disableHeader = !!addSpouse || !!addIncomeClient || !!editIncome || !!addSpClient || !!editSpClient
  // dispatch(enableHeader(!disableHeader))

  const isLoading = meIsLoading || incomesIsLoading || spouseIsLoading
  const error: any = meError || incomesError

  const onSubmit = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  //Update hasIncomes when incomes change
  useEffect(() => {
    setHasIncomes(incomes && isArray(incomes) && incomes.length ? true : false)
  }, [incomes])  

  //Reset tab on entry
  useEffect(() => {
    dispatch(setOnIncomeSpouseView(false))
  }, [])

  const handleIncomeListNavigation = (data) => {
    const index = data?.state?.index
    const routeNames = data?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    dispatch(setOnIncomeSpouseView(currentName === IncomeTabScreenName.SPOUSE))
  }

  return (
    <>
      {
        addSpouse ?
        <SpouseAddProcessStack
          visible={addSpouse}
          onDismiss={() => setAddSpouse(false)}
        />
        : addIncomeClient ?
        <RetirementIncomesAddIncomeProcessStack
          client={addIncomeClient}
          onDismiss={() => setAddIncomeClient(undefined)}
        />
        : editIncome ?
        <RetirementIncomesEditIncomeModal
          income={editIncome}
          onDismiss={() => setEditIncome(undefined)}
        />
        : addSpClient ?
        <RetirementIncomesAddStatePensionProcessStack
          client={addSpClient}
          onDismiss={() => setAddSpClient(undefined)}
        />
        : editSpClient ?
        <RetirementIncomesEditStatePensionModal
          client={editSpClient}
          onDismiss={() => setEditSpClient(undefined)}
        />
        :
        <ProcessScreen 
          isLoading={isLoading}
          error={error}
          errorTryAgain={refetchAll}
          buttonTitle={'Finish'}
          buttonAction={onSubmit}
          showButton={true}
          enableButton={!incomesIsLoading && !incomesIsFetching && hasIncomes}
          allowTextButton={!hasIncomes}
          textButtonTitle={spouse ? `We don't have any`: `I don't have any`}
          textButtonAction={() => setShowDialog(true)}
          headline={`Will you have any other incomes in retirement?`}
          subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_INCOMES} buttonTitle={`What should I add?`}/>}
        >
          <RetirementIncomesList
            addIncomeStateFunction={(client: ClientBaseDto) => setAddIncomeClient(client)}
            editIncomeStateFunction={(income: RetirementIncomeDto) => setEditIncome(income)}
            addStatePensionStateFunction={(client: ClientBaseDto) => setAddSpClient(client)}
            editStatePensionStateFunction={(client: ClientBaseDto) => setEditSpClient(client)}
            addSpouseStateFunction={(visible: boolean) => setAddSpouse(visible)}
            onNavigateFunction={handleIncomeListNavigation}
          />
          <ConfirmationDialog
            visible={showDialog}
            onCancel={() => setShowDialog(false)}
            title={'Are you sure?'}
            content={`You can add retirement incomes later from the menu`}
            onConfirm={onSubmit}
          />
        </ProcessScreen>
      }
    </>
  )
}