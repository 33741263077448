import { BaseDatedDto } from './base.dto';

export interface PensionProviderDto extends BaseDatedDto {
  id: string
  origoId: string
  name: string
  description?: string
  notes?: string
  primaryBrandKey?: string
  isEnabled: boolean
  providesWorkplacePensions: boolean
  providesPersonalPensions: boolean
  websiteUrl?: string
  contactInformation?: PensionProviderContactInformation
  referenceFormatInformation?: PensionProviderReferenceFormatInformation
  relationships?: PensionProviderRelationship[]
}

export interface PensionProviderFilterDto {
  search?: string
  origoId?: string
  primaryBrandKey?: string
}

export interface CreatePensionProviderDto {
  origoId: string
  name: string
  description?: string
  notes?: string
  primaryBrandKey?: string
  isEnabled?: boolean
  providesWorkplacePensions?: boolean
  providesPersonalPensions?: boolean
  websiteUrl?: string
  contactInformation?: PensionProviderContactInformation
  referenceFormatInformation?: PensionProviderReferenceFormatInformation
  relationships?: PensionProviderRelationship[]
}

export interface UpdatePensionProviderDto {
  id: string
  name?: string
  description?: string
  notes?: string
  primaryBrandKey?: string
  isEnabled?: boolean
  providesWorkplacePensions?: boolean
  providesPersonalPensions?: boolean
  websiteUrl?: string
  contactInformation?: PensionProviderContactInformation
  referenceFormatInformation?: PensionProviderReferenceFormatInformation
  relationships?: PensionProviderRelationship[]
}

export interface PensionProviderRelationship {
  relationshipType: PensionProviderRelationshipType
  source: string
  sourceId: string
  minYear?: number
  maxYear?: number
}

export enum PensionProviderRelationshipType {
  EMPLOYER = 'employer',
  BRAND = 'brand',
  SCHEME = 'scheme',
}

export class PensionProviderContactInformation {
  notes?: string
  contactDetails?: PensionProviderContactDetail[]
}

export interface PensionProviderContactDetail {
  telephone?: string
  email?: string
  contactUrl?: string
  portalUrl?: string
  notes?: string
}

export interface PensionProviderReferenceFormatInformation {
  summary?: string
  formats?: PensionProviderReferenceFormatDetail[]
}

export interface PensionProviderReferenceFormatDetail {
  pattern?: string
  example?: string
}

export type WrappedPensionProviderContactDetail = {
  id?: string,
  data: PensionProviderContactDetail
}

export type WrappedPensionProviderReferenceFormatDetail = {
  id?: string,
  data: PensionProviderReferenceFormatDetail
}
