import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatNationalInsuranceNumber, unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { JAR_NAME_ALL, NATIONAL_INSURANCE_NO_MASK } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCheckNiNumberQuery } from 'store/apiSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_07_NationalInsuranceNo = ({ route, navigation }) => {
  const { nextScreen, client }: { nextScreen: string, client: ClientMeDto } = route?.params || {}

  const dispatch = useAppDispatch()

  const workingClientData = useAppSelector(workingClient)
  const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
  const [available, setAvailable] = useState(undefined)
  // const [cursorPosition, setCursorPosition] = useState(0)

  //Setup form
  const formObj = useForm<{ nationalInsuranceNo: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: formatNationalInsuranceNumber(workingClientData?.nationalInsuranceNo || client?.nationalInsuranceNo),
    },
  })
  const { handleSubmit, watch, trigger, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient({
      nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo)
    }))
    navigation.navigate(nextScreen)
  }

  //Check the NI number availability only if 9 characters
  const { data: niCheckResult, error, isLoading, isFetching, refetch } = useCheckNiNumberQuery({
    nationalInsuranceNo: niNumberToCheck,
  }, { skip: !niNumberToCheck})

  const nationalInsuranceNo = watch('nationalInsuranceNo')

  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setAvailable(undefined)
    setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
  }, [nationalInsuranceNo])

  //Force refetch when NI number to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (niNumberToCheck) {
      refetch()
    }    
  }, [niNumberToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (error || isLoading || isFetching) {
      setAvailable(undefined)
    } else if (niCheckResult) {
      setAvailable(niCheckResult.available)
    }
  }, [niCheckResult, error, isLoading, isFetching])

  //Trigger validation when available changes
  useEffect(() => {
    trigger('nationalInsuranceNo')
  }, [available])

  const isValidNiNumber = (value: string) => {
    const result = value.match(/^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-D]$/)
    return result ? true : 'Invalid NI Number - please double check'
  }

  const isAvailableNiNumber = () => {
    if (available === undefined) {
      return 'Verifying...'
    }
    return available ? true : 'Already registered.'
  }

  return (
    <ProcessScreen
      buttonTitle={'Start Verification'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Finally, your National Insurance Number`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.NATIONAL_INSURANCE_NO} buttonTitle={'Why do you need this?'} />}
    >
      <ManagedTextInput
        name={'nationalInsuranceNo'}
        autoFocus={true}
        formObj={formObj}
        // label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        // onSelectionChange={(event) => {
        //   if (event?.nativeEvent?.selection?.start) {
        //     setCursorPosition(event?.nativeEvent?.selection?.start)
        //   }
        // }}
        // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
        // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
        autoCapitalize={'characters'}
        forceCapitals={true}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
            isAvailableNiNumber,
          }
      }} />
    {
      available === false ? 
      <Paragraph>{`That National Insurance Number is associated with an existing ${JAR_NAME_ALL}. If you are sure that you have entered it correctly, please contact support.`}</Paragraph>
      : <></>
    }
    </ProcessScreen>
  )
}
