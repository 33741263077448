import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { ClientSignatureModal } from 'features/MenuSections/Profile/Components/ClientSignatureModal'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetSignatureQuery, useUpdateSignatureMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingBulkTransfer } from 'store/tempDataSlice'
import { Colors, Paper, Sizing } from 'styles'
import { ampli } from 'src/ampli'

export const BulkTransfer_03_Signature = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const dispatch = useAppDispatch()
  const workingBulkTransferData = useAppSelector(workingBulkTransfer)

  const { data: meSignature, error: meSignatureError, isLoading: meSignatureIsLoading, isFetching: meSignatureIsFetching, refetch: refetchSignature } = useGetSignatureQuery()
  const [ updateSignature, { error: updateError } ] = useUpdateSignatureMutation()
  const isLoading = meSignatureIsLoading || meSignatureIsFetching
  const error = updateError

  const [showSignatureModal, setShowSignatureModal] = useState(false)

  const next = () => {
    
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'transfer_signature',
      isRetransfer: false,
      outcome: 'success',
    })

    navigation.navigate(nextScreen)
  }

  const handleUpdateSignature = (signature) => {
    Logger.debug(`Saving signature...`)
    updateSignature({
      signature
    })
    setShowSignatureModal(false)
  }

  const signature = meSignature?.signature
  
  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={handleUpdateSignature}
      buttonTitle={signature ? 'Next' : 'Provide Signature'}
      buttonAction={signature ? next : () => setShowSignatureModal(true)}
      enableButton={true}
      headline={`Please provide us with your signature`}
      subHeading={`In order to find your pension${workingBulkTransferData?.tracingCount > 1 ? 's' : ''} on your behalf we need your signature`}
      allowTextButton={!!signature}
      textButtonTitle={`Change Signature`}
      textButtonAction={() => setShowSignatureModal(true)}
    >
      {
        signature
          ?
            <>
              <Paragraph>{`This is how your signature looks:`}</Paragraph>
              <View style={localStyles.signatureContainer}>
                <Image source={{ uri: meSignature?.signature }} style={{
                  width: getScreenAppWidth() - Sizing.x100,
                  height: scaleNormalizer(120),
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }} />
              </View>
            </>
          : <>
              <MaterialCommunityIcons style={{ alignSelf: 'center' }} name={'information-outline'} size={Sizing.x100} color={themeColors.accent} />
              <Paragraph>{`Whilst we can electronically transfer pensions, in order to find pensions, we need to prove to the old provider our authority to act on your behalf.`}</Paragraph>
            </>
      }
      <Paragraph>{`We will not use your signature for any purpose other than finding pensions.`}</Paragraph>
      <Portal>
        <ClientSignatureModal
          clientSignature={meSignature} 
          visible={showSignatureModal}
          onDismiss={() => setShowSignatureModal(false)}
          onSave={handleUpdateSignature}
        />
      </Portal>
    </ProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  signatureContainer: {
    paddingHorizontal: Sizing.x30,
    paddingVertical: Sizing.x10,
    marginVertical: Sizing.x10,
    backgroundColor: 'white',
    borderRadius: Sizing.x10,
  }
})