import { Logger } from 'lib/logger'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { ComponentProps, useCallback, useEffect, useRef } from 'react'
import { Animated } from 'react-native'

type ShakeBoxProps = ComponentProps<typeof Animated.View> & {
  disabled?: boolean
  initialDelayMs?: number
  loopDelayMs?: number
  distance?: number
  duration?: number
  iterations?: number
  vertical?: boolean
}

export const ShakeBox = (props: ShakeBoxProps) => {
  const { disabled, initialDelayMs, loopDelayMs, distance, duration, iterations, vertical, children, ...otherProps } = props

  const anim = useRef(new Animated.Value(0))

  const isWeb = platformIsWeb()

  const shake = useCallback(() => {
    if (!disabled) {
      Animated.loop(
        Animated.sequence([
          // shift element to the left by 2 units
          Animated.timing(anim.current, {
            toValue: distance * -1,
            duration,
            useNativeDriver: !isWeb,
          }),
          // shift element to the right by 2 units
          Animated.timing(anim.current, {
            toValue: distance,
            duration,
            useNativeDriver: !isWeb,
          }),
          // bring the element back to its original position
          Animated.timing(anim.current, {
            toValue: 0,
            duration,
            useNativeDriver: !isWeb,
          }),
          // delay at end of loop
          Animated.delay(loopDelayMs || 0)
        ]),
        // loops the above animation a specific number of times
        { iterations: iterations || -1 }
      ).start()
    }
  }, [])


  useEffect(() => {
    if (!disabled) {
      setTimeout(shake, initialDelayMs || 0)
    }
  }, [])

  const transform = vertical
    ? [{ translateY: anim.current }]
    : [{ translateX: anim.current }]

  return (
    <Animated.View
      {...otherProps}
      style={{ transform }}>
        {children}
    </Animated.View>
  )
}