import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { BulletItem } from 'components/Typography/BulletItem'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery } from 'store/apiSlice'
import { Typography } from 'styles'

export const InvestmentChoice_01_Conditions = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch } = useGetMeQuery()
  
  //Setup form
  const formObj = useForm<{ criteriaChoice: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    if (attributes.criteriaChoice) {
      if (client?.segment?.isPromotion) {
        navigation.navigate('Promotion') //Show promo screen
      } else {
        navigation.navigate('InvestmentTypeChoice') //Skip to investment plan choice
      }
    } else {
      //Go to sorry screen
      navigation.navigate(nextScreen)
    }
  }

  const criteriaOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes, I meet all criteria',
    },
    {
      value: false,
      label: 'No'
    }
  ]

  const hasChosen = watch('criteriaChoice')
  return (
    <ProcessScreen
      isLoading={clientIsLoading}
      error={clientError}
      errorTryAgain={refetch}
      buttonTitle={hasChosen ? 'Next' : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Let's check a few details...`}
      subHeading={`Do you meet all the eligibility criteria listed below?`}
    >
      <ManagedSimpleChoiceInput
        name={'criteriaChoice'}
        formObj={formObj}
        options={criteriaOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      <BulletItem style={{ textAlign: 'left', ...Typography.fontSize.x15 }}>{`I am a UK National`}</BulletItem>
      <BulletItem style={{ textAlign: 'left', ...Typography.fontSize.x15 }}>{`I am a UK Resident`}</BulletItem>
      <BulletItem style={{ textAlign: 'left', ...Typography.fontSize.x15 }}>{`I only pay tax in the UK`}</BulletItem>
      <BulletItem style={{ textAlign: 'left', ...Typography.fontSize.x15 }}>{`I have not started to take money from any pension`}</BulletItem>
      <BulletItem style={{ textAlign: 'left', ...Typography.fontSize.x15 }}>{`I have not triggered the Money Purchase Annual Allowance (MPAA) rules`}</BulletItem>
      <NamedInformationButton name={NamedInformation.MPAA}/>
    </ProcessScreen>
  )
}
