import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile } from "store/tempDataSlice"
import { editRetirementBudgetVisible, setEditRetirementBudgetVisible } from 'store/uxSlice'
import { ChangeRetirementBudget_00_Intro, ChangeRetirementBudget_01_SpouseChoice, ChangeRetirementBudget_02_LondonChoice, ChangeRetirementBudget_03_LevelChoice, ChangeRetirementBudget_04_FineTuneChoice, ChangeRetirementBudget_99_Result } from "./Screens"

export const ChangeRetirementBudgetProcessStack = () => {

  const dispatch = useAppDispatch()
  const visible = !!useAppSelector(editRetirementBudgetVisible)

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingRetirementProfile(undefined))
  }, []
  )
  
  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={() => dispatch(setEditRetirementBudgetVisible(false))}
      dismissConfirmationMessage={`Cancel making changes to your retirement budget?`}
      processTitle='Change Retirement Budget'
      initialRouteName='Intro'
      screens={[
        {
          navigationId: 'Intro',
          title: 'Change Budget',
          component: ChangeRetirementBudget_00_Intro,
        },
        {
          navigationId: 'SpouseChoice',
          title: 'Spouse Choice',
          component: ChangeRetirementBudget_01_SpouseChoice,
        },
        {
          navigationId: 'LondonChoice',
          title: 'London Choice',
          component: ChangeRetirementBudget_02_LondonChoice,
        },
        {
          navigationId: 'LevelChoice',
          title: 'Level Choice',
          component: ChangeRetirementBudget_03_LevelChoice,
        },
        {
          navigationId: 'FineTuneChoice',
          title: 'Fine Tune Choice',
          component: ChangeRetirementBudget_04_FineTuneChoice,
        },
        {
          navigationId: 'Results',
          title: '',
          component: ChangeRetirementBudget_99_Result,
        },
      ]}
    />
  )
}

