import { MaterialCommunityIcons } from '@expo/vector-icons'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Sizing } from 'styles'


export type PasswordlessErrorScreenProps = {
  reset: any
}

export const PasswordlessErrorScreen = (props: PasswordlessErrorScreenProps) => {
  const { reset } = props || {}

  return (
    <BackgroundGradientPurple>
    <SafeAreaView style={{
      ...Flex.column.center,
      alignItems: 'center'
    }}>
      <View style={{
        alignItems: 'center',
        paddingHorizontal: Sizing.x20,
        width: '100%',
      }}>
        <MaterialCommunityIcons name={'block-helper'} size={Sizing.x100} color={Colors.brand.red3} />
        <View style={{ paddingVertical: Sizing.x20 }}>
          <Headline>{`Sorry, your login link has expired or is invalid`}</Headline>
          <Subheading>{`Please try to login again`}</Subheading>
        </View>
        <Button
          mode={'contained'}
          onPress={reset}
        >{`Return to Login`}</Button>
      </View>
    </SafeAreaView>
  </BackgroundGradientPurple>
  )}

