import React from 'react'
import { Text as PaperText } from "react-native-paper"
import { Typography } from "styles"
import { TypographyProps } from './typography.props'

export const Text = (props: TypographyProps) => {

  const styles = [
    Typography.defined.text,
    props.style,
  ]

  return (
    <PaperText {...props} allowFontScaling={false} style={styles}>
      {props.children}
    </PaperText>
  )
}