import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import React, { ComponentProps, useState } from 'react'
import { View } from "react-native"
import { ScrollView } from 'react-native'
import { Portal } from "react-native-paper"
import { Colors, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { Button } from '../../../../components/Utility/Button'
import { FooterButton } from '../../../../components/Utility/FooterButton'

type ProviderInfoModalProps = {
  content: React.ReactNode
  visible: boolean
  onDismiss: any
}

const ProviderInfoModal = (props: ProviderInfoModalProps) => {
  const { onDismiss, content, visible } = props

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >
          <View>{content}</View>
        </ScrollView>
        <FooterButton onPress={onDismiss} mode={'text'} >{'Close'}</FooterButton>
      </View>
    </CardModal>
  )
}


type ProviderInfoButtonProps = ComponentProps<typeof Button> & {
  modalContent?: React.ReactNode
}
export const ProviderInfoButton = (props: ProviderInfoButtonProps) => {
  const { children, modalContent } = props

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      <Button
        onPress={showModal}
        mode={'text'}
        icon={() => <MaterialCommunityIcons name={'information-outline'} size={Sizing.x20} color={themeColors.accent} />}
      >
        {children || 'More Information'}
      </Button>
      <Portal>
        <ProviderInfoModal
          content={modalContent}
          onDismiss={hideModal}
          visible={modalVisible}
        />
      </Portal>
    </>
  )
}
