import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ContributionRecord, EmployerContributionSetChoice } from 'features/ModalProcesses/EmployerAddContributionJob/EmployerAddContributionJobModal'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenSizeConstraints } from 'lib/scaleHelpers'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Dialog, Portal, ThemeProvider } from "react-native-paper"
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { ButtonSet } from '../../../../components/Utility/ButtonSet'
import { UnborderedTable } from '../../../../components/Utility/UnborderedTable'
import { getActionDate } from 'lib/dateHelpers'
import { MAX_WORKPLACE_CONTRIBUTION_AMOUNT } from 'lib/constants'

type EmployerContributionEditDialogProps = {
  contributionRecord: ContributionRecord
  onClose: any
  onSave: any
  visible: boolean
}


export const EmployerContributionEditDialog = (props: EmployerContributionEditDialogProps) => {
  const { visible, onClose, onSave, contributionRecord } = props || {}

  const formObj = useForm<{
    choice: EmployerContributionSetChoice,
    employerContributionAmount: number,
    employeeContributionAmount: number,
    lastEmployerContributionAmount: number,
    lastEmployeeContributionAmount: number,
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      choice: contributionRecord?.choice,
      employerContributionAmount: contributionRecord?.employerContributionAmount,
      employeeContributionAmount: contributionRecord?.employeeContributionAmount,
      lastEmployerContributionAmount: contributionRecord?.lastEmployerContributionAmount,
      lastEmployeeContributionAmount: contributionRecord?.lastEmployeeContributionAmount,
    },
  })

  const { handleSubmit, setValue, setError, trigger, watch, formState: { isValid, errors } } = formObj

  const employerContributionAmountRef = useRef(null)
  const employeeContributionAmountRef = useRef(null)

  const isWeb = platformIsWeb()

  const theme = isWeb ? {
    colors: {
      backdrop: 'transparent',
    },
  } : undefined

  const constraintStyle: any = getScreenSizeConstraints()
  const maxContentHeight = getScreenAppHeight() - Sizing.x200

  const handleChoiceChange = (value) => {
    setValue(`choice`, value)
  }

  const onSubmit = (attributes) => {
    const { choice } = attributes
    const employerContributionAmount =
      choice === EmployerContributionSetChoice.NIL ? 0
      : choice === EmployerContributionSetChoice.LAST ? attributes?.lastEmployerContributionAmount
      : attributes?.employerContributionAmount
    const employeeContributionAmount =
      choice === EmployerContributionSetChoice.NIL ? 0
      : choice === EmployerContributionSetChoice.LAST ? attributes?.lastEmployeeContributionAmount
      : attributes?.employeeContributionAmount
    onSave(choice, employerContributionAmount, employeeContributionAmount)
  }

  const choice = watch('choice')
  const lastEmployerContributionAmount = watch('lastEmployerContributionAmount')
  const lastEmployeeContributionAmount = watch('lastEmployeeContributionAmount')
  const employerContributionAmount = watch('employerContributionAmount')
  const employeeContributionAmount = watch('employeeContributionAmount')

  const allowSave =
    choice === EmployerContributionSetChoice.NIL
      || choice === EmployerContributionSetChoice.LAST
      || (employerContributionAmount || employeeContributionAmount)

  const hasLastAmounts = lastEmployerContributionAmount || lastEmployeeContributionAmount

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <Dialog
          visible={visible}
          onDismiss={onClose}
          style={{
            ...constraintStyle,
            minWidth: Sizing.x600,
          }}
          theme={theme}
        >
          <Dialog.Title style={{
            color: Colors.brand.purple1,
            textAlign: 'center',
          }}>{`Adjust Contributions`}</Dialog.Title>
          <Dialog.Content
          style={{
            maxHeight: maxContentHeight,
          }}
          >
            <ScrollView
              nestedScrollEnabled
              style={layoutStyles.scrollContainer}
              contentContainerStyle={layoutStyles.scrollContainerContent}
              keyboardShouldPersistTaps='handled'
              showsVerticalScrollIndicator={true}
            >
              <UnborderedTable
                data={[
                  {
                    label: `Name`,
                    value: `${contributionRecord?.firstName} ${contributionRecord?.surname}`
                  },
                  {
                    label: `National Insurance No`,
                    value: formatNationalInsuranceNumber(contributionRecord?.nationalInsuranceNo),
                  },
                ]}
                noContentDivider={true}
              />
              <View style={{height: Sizing.x300 }}>
                <View style={{
                  paddingVertical: Sizing.x20,
                }}>
                  <ButtonSet
                    value={choice}
                    onValueChange={handleChoiceChange}
                    buttons={[
                      {
                        icon: 'history',
                        value: EmployerContributionSetChoice.LAST,
                        label: 'Last Amounts',
                        disabled: !hasLastAmounts || contributionRecord?.inOptOutWindow,
                      },
                      {
                        icon: 'square-edit-outline',
                        value: EmployerContributionSetChoice.NEW,
                        label: 'New Amounts',
                        disabled: contributionRecord?.inOptOutWindow,
                      },
                      {
                        icon: 'cancel',
                        value: EmployerContributionSetChoice.NIL,
                        label: 'No contributions',
                      },
                    ]}
                  />
                </View>
                {
                  choice === EmployerContributionSetChoice.NIL ?
                    <View style={{
                      paddingVertical: Sizing.x20
                    }}>
                      {
                        contributionRecord?.inOptOutWindow
                          ? <>
                              <Paragraph>{`This member is within their Out Out Window${contributionRecord?.autoEnrolmentWindowOptOutDate ? ` until ${getActionDate(contributionRecord?.autoEnrolmentWindowOptOutDate)}` : ``}.`}</Paragraph>
                              <Paragraph>{`You will be able to start making contributions after this date.`}</Paragraph>
                            </>
                          : <>
                              <Paragraph>{`No contributions will be made for this member in this submission.`}</Paragraph>
                            </>
                      }
                    </View>
                  : choice === EmployerContributionSetChoice.LAST ?
                    <>
                      <ManagedCurrencyInput
                        name={`lastEmployerContributionAmount`}
                        label={`Employer Amount`}
                        formObj={formObj}
                        placeholder={'Employer contribution'}
                        disabled={true}
                        forceZeroValueOnEmpty={true}
                        withSubUnit={true}
                        required={true}
                        blurOnSubmit={false}
                      />
                      <ManagedCurrencyInput
                        name={`lastEmployeeContributionAmount`}
                        label={`Employee Amount`}
                        formObj={formObj}
                        placeholder={'Employee contribution'}
                        disabled={true}
                        forceZeroValueOnEmpty={true}
                        withSubUnit={true}
                        required={true}
                        blurOnSubmit={false}
                      />
                    </>
                  : <>
                      <ManagedCurrencyInput
                        ref={employerContributionAmountRef}
                        name={`employerContributionAmount`}
                        label={`Employer Amount`}
                        autoFocus={true}
                        returnKeyType="next"
                        submitHandler={() => employeeContributionAmountRef.current?.focus()}
                        formObj={formObj}
                        placeholder={'Employer contribution'}
                        forceZeroValueOnEmpty={true}
                        withSubUnit={true}
                        minValue={0}
                        maxValue={MAX_WORKPLACE_CONTRIBUTION_AMOUNT}
                        required={true}
                        blurOnSubmit={false}
                        onChange={() => { trigger() }}
                        clearButtonFunction={trigger}
                      />
                      <ManagedCurrencyInput
                        ref={employeeContributionAmountRef}
                        name={`employeeContributionAmount`}
                        label={`Employee Amount`}
                        autoFocus={false}
                        returnKeyType='done'
                        submitHandler={handleSubmit(onSubmit)}
                        formObj={formObj}
                        placeholder={'Employee contribution'}
                        forceZeroValueOnEmpty={true}
                        withSubUnit={true}
                        minValue={0}
                        maxValue={MAX_WORKPLACE_CONTRIBUTION_AMOUNT}
                        required={true}
                        blurOnSubmit={false}
                        onChange={() => { trigger() }}
                        clearButtonFunction={trigger}
                      />
                      {
                        !employerContributionAmount && !employeeContributionAmount ?
                          <View style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}>
                            {/* Input errors are based around employeeContributionAmount */}
                            <InputErrorMessages
                              formObj={formObj}
                              name={`employeeContributionAmount`}
                              informationMessage={`Please enter at least one value`}
                              informationMessageIsError={true}
                            />
                          </View>
                          : <></>
                      }
                    </>

                }
              </View>
            </ScrollView>
          </Dialog.Content>
          <Dialog.Actions>
            <Button mode='text' onPress={onClose}>
              <Text style={localStyles.buttonText}>{'Cancel'}</Text>
            </Button>
            <Button mode='text' onPress={handleSubmit(onSubmit)} disabled={!allowSave || contributionRecord?.inOptOutWindow}>
              <Text style={localStyles.buttonText}>{'Save'}</Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </ThemeProvider>
    </Portal>
  )
}

const localStyles = StyleSheet.create({
  buttonText: {
    color: Colors.brand.purple1
  },
})


