import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useGetRetirementAssetsQuery, useGetSpouseQuery, useSetInterestRetirementAssetsMutation, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAssetInterest, workingRetirementAssetInterest } from 'store/tempDataSlice'
import { sumBy } from 'lodash'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { isOfTransferableType } from 'lib/retirementAssetHelpers'

export const RetirementAssetSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingRetirementAssetInterestData = useAppSelector(workingRetirementAssetInterest)
  const [reportingFinished, setReportingFinished] = useState(false)

  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching } = useGetRetirementAssetsQuery()

  const [ updateClient, { data: client, error: clientError, isLoading: clientIsLoading } ] = useUpdateMeMutation()
  const [ updateTransferInterest, { data: interest, error: interestError, isLoading: interestIsLoading } ] = useSetInterestRetirementAssetsMutation()

  const isLoading = interestIsLoading || clientIsLoading
  const error: any = interestError || clientError

  //Save on enter
  useEffect(() => {
    if (workingRetirementAssetInterestData) {
      saveInterest()
    } else {
      saveClient()
    }
  }, [])

  //Save client when transfer interest set
  useEffect(() => {
    if (interest) {
      saveClient()
    }
  }, [interest])

  //Amplitude reporting
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }
    
    if (!spouseIsLoading && assets) {
      const clientAssetCount = sumBy(assets, function(asset: RetirementAssetDto) {
        return asset.clientId === spouse?.id ? 0 : 1
      })
      const spouseAssetCount = sumBy(assets, function(asset: RetirementAssetDto) {
        return asset.clientId === spouse?.id ? 1 : 0
      })
      const transferableAssets = assets ? assets.filter(asset => {
        return asset.clientId !== spouse?.id && isOfTransferableType(asset)
      }) : []

      const nonTransferablePensionCount = transferableAssets?.length - (workingRetirementAssetInterestData?.assetIds?.length || 0)
      ampli.retirementAssetSetup({
        withSpouse: !!spouse,
        clientAssetCount,
        spouseAssetCount,
        nonTransferablePensionCount,
      })
      setReportingFinished(true)
    } else if (assetsError) {
      setReportingFinished(true)
    }
  }, [spouseIsLoading, assets, assetsError, reportingFinished])

  //Navigate when client saved and reporting done
  useEffect(() => {
    if (client && reportingFinished) {
      end()
    }
  }, [client, reportingFinished])
    
  const saveInterest = () => {
    Logger.info(`Updating transfer interest...`)
    updateTransferInterest({
      isTransferable: false,
      assetIds: workingRetirementAssetInterestData.assetIds,
    })
  }

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        assets: true,
      },
    })
  }

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingRetirementAssetInterest(undefined))
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={interest ? saveClient : saveInterest}
      isLoading={isLoading}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
