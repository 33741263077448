import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerInvitesListScreen } from './Screens/EmployerInvitesListScreen'

export const EmployerInvitesSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Invite Employees'}
    >
      <EmployerInvitesListScreen />
    </SectionSimpleContainer>
  )
}

