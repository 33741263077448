import React from 'react'
import { DimensionValue, Image } from 'react-native'

type JarvisIconProps = {
  size: DimensionValue
  color?: "white" | "black"  
}
export const JarvisIcon = (props: JarvisIconProps) => {
  const { color, size } = props

  const jarvisLogo = color === 'black'
    ? require('assets/brand/jarvis-symbol-blk-4x.png')
    : require('assets/brand/jarvis-symbol-wht-4x.png')

  return (
    <Image source={jarvisLogo} style={{
      width: size,
      height: size,
      alignSelf: 'center',
    }} />
  )
}