import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { concat } from 'lodash'

export const RetirementIncomeSetupHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: incomes, isLoading: incomesIsLoading, error: incomesError } = useGetRetirementIncomesQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const isLoading = incomesIsLoading || clientIsLoading || spouseIsLoading
  const error: any = incomesError || clientError

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const hasStatePension = client?.statePensionAmount || spouse?.statePensionAmount
  const hasMultipleStatePension = client?.statePensionAmount && spouse?.statePensionAmount
  
  const carouselScreens: ProcessHeroCarouselData[] = concat(
    hasStatePension ? [
      {
        illustrationFilename: 'record_an_existing_pension.png',
        headline: `A little help is always appreciated`,
        subHeading: `By recording your state pension${hasMultipleStatePension ? 's' : ''}, you'll know how much of your retirement lifestyle is already funded`,
      },
    ] : [
      {
        illustrationFilename: 'record_an_existing_pension.png',
        headline: `Double check your entitlement`,
        subHeading: `State pensions can help fund your retirement lifestyle, so if you do find you are entitled, record them in Jarvis to understand the impact`,
      },
    ],
    incomes?.length ? [
      {
        illustrationFilename: 'capture_retirement_income.png',
        headline: `You're already part way there`,
        subHeading: `By recording your other ongoing incomes, you'll know how much of your annual retirement budget is covered`,
      },
    ] : [
      {
        illustrationFilename: 'capture_retirement_income.png',
        headline: `Consider all sources of ongoing income`,
        subHeading: `If things change, be sure to record other incomes in Jarvis to see the impact on your future`,
      },
    ],
    hasStatePension || incomes?.length ? [
      {
        illustrationFilename: 'financial_forecasting.png',
        headline: `Financial Forecasting`,
        subHeading: `We'll use your ongoing income to offset your retirement budget, reducing the amount you need to save today`,
      },
      {
        illustrationFilename: 'task_list.png',
        headline: `Keep things updated`,
        subHeading: `If there are any changes to your circumstances, remember to update your incomes to ensure accurate forecasting`,
      },  
    ] :
    []
  )

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
