import { DrawerContentScrollView } from '@react-navigation/drawer'
import { DrawerDescriptorMap, DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { DrawerNavigationState, ParamListBase } from '@react-navigation/native'
import { JarvisLogoType } from 'components/Brand/JarvisLogoType'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Subheading } from 'components/Typography'
import { AppContextSwitcher } from 'components/Utility/AppContextSwitcher'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { UserInfoModal } from 'components/Utility/UserInfoModal'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import CustomDrawerItemList, { DrawerScreen } from 'navigation/components/CustomDrawerItemList'
import React, { useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import { Divider, Portal, ThemeProvider } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext } from 'store/authSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

type AdminAppStackDrawerContentProps = {
  screens: DrawerScreen[],
  screenGroupMap: any,
  state: DrawerNavigationState<ParamListBase>,
  descriptors: DrawerDescriptorMap,
  navigation: DrawerNavigationHelpers,
}
export const AdminAppStackDrawerContent = (props: AdminAppStackDrawerContentProps) => {
  const { screens, screenGroupMap, state, navigation, descriptors } = props

  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()
          
  const insets = useSafeAreaInsets()

  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showUserInfoModal, setShowUserInfoModal] = useState(null)
  
  const isWeb = platformIsWeb()

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightPurple : Paper.darkThemeOnLightGrey}>
      <View style={{
        backgroundColor: isWeb ? Colors.brand.purple4 : Colors.brand.grey4,
        flex: 1,
      }}>
        <DrawerContentScrollView
          {...props}
          style={{
            marginRight: isWeb ? Sizing.x0 : Sizing.x1,
          }}
        >
          <Pressable
            onPress={() => setShowInfoModal(true)}
            style={{
              paddingVertical: Sizing.x20,
              alignSelf: 'center',
              width: Sizing.x200,
          }}>
            <JarvisLogoType size={Sizing.x30} color='black' />
          </Pressable>
          <AppContextSwitcher currentAppContext={AppContext.ADMIN} />
          <ContentDivider />
          <CustomDrawerItemList
            {...props}
            screenGroupMap={screenGroupMap}
          />
        </DrawerContentScrollView>
        <View style={{
          marginRight: isWeb ? Sizing.x0 : Sizing.x1,
          paddingBottom: insets.bottom,
        }}>
          <ContentDivider />
          <View style={{
            paddingVertical: Sizing.x10,
            ...Flex.row.center,
            }}>
              <Pressable
                style={localStyles.profileContainer}
                onPress={() => setShowUserInfoModal(true)}
              >
                <View style={localStyles.smallProfileImageContainer}>
                  <Image source={{ uri: user?.picture }} style={localStyles.smallProfileImage}/>
                </View>
                <View>
                  <Subheading>{`${user?.metadata?.firstName} ${user?.metadata?.surname}`}</Subheading>
                </View>
              </Pressable>
          </View>
        </View>
        <Portal>
          <AppInfoModal
            user={user}
            visible={showInfoModal}
            onDismiss={() => setShowInfoModal(false)}
          />
          <UserInfoModal
            user={user}
            visible={showUserInfoModal}
            onDismiss={() => setShowUserInfoModal(false)}
          />
        </Portal>
      </View>
    </ThemeProvider>
    )
  }

  const localStyles = StyleSheet.create({
    profileContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingHorizontal: Sizing.x10,
    },
    smallProfileImageContainer: {
    },
    smallProfileImage: {
      alignSelf: 'center',
      height: scaleNormalizer(60),
      width: scaleNormalizer(60),
      borderRadius: scaleNormalizer(30),
    },
  })
  