import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { NavigationContainer } from '@react-navigation/native'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { WrappedRetirementProfileCategoryBudget } from 'components/Utility/BudgetTable'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { MAX_BUDGET_CUSTOM_CATEGORY_AMOUNT } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { round, upperCase } from 'lodash'
import { default as React, useEffect, useState } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { BudgetDto, BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { Flex, Paper, Sizing, Typography } from 'styles'

const Tab = createMaterialTopTabNavigator()

type RetirementBudgetCategoryEditModalProps = {
  formObj: UseFormReturn<any>
  budget: BudgetDto
  onDismiss: any
}

export const RetirementBudgetCategoryEditModal = (props: RetirementBudgetCategoryEditModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: RetirementBudgetCategoryEditModalProps) => {

  const { formObj, budget, onDismiss } = props
  const { watch, handleSubmit, setValue, control, formState: { isValid} } = formObj
  const { update } = useFieldArray({
    control,
    name: "expenses",
  })
    
  const findClosestLevel = () => {
    const levels = budget.levels
    //Find using level if available
    if (currentLevel) {
      const exactMatch = levels.find(level => {
        return level.id === currentLevel
      })
      if (exactMatch) {
        return exactMatch.id
      }
    }
    let bestMatch = levels[0]
    //See if any other levels are closer
    for (let i = 1; i < levels.length; i++) {
      const currentDiff = Math.abs((bestMatch.value) - currentValue)
      const thisDiff = Math.abs((levels[i].value) - currentValue)
      if (thisDiff < currentDiff) {
        bestMatch = levels[i]
      }
    }
    return bestMatch.id    
  }

  const watchedExpenses: WrappedRetirementProfileCategoryBudget[] = watch(`expenses`)
  const expenseIndex = watchedExpenses.findIndex(e => {
    return budget.id === e.data.id
  })
  const currentValue = watchedExpenses[expenseIndex].data.selectedCost
  const currentLevel = watchedExpenses[expenseIndex].data.selectedLevel

  const closestLevel = findClosestLevel()

  const [isCustom, setIsCustom] = useState(currentLevel === BudgetLevelIdentifier.CUSTOM)


  //Set customAmount on entry
  useEffect(() => {
    setValue('customAmount', round(currentValue / 12, 0))
  }, []
  )

  const [selectedLevel, setSelectedLevel] = useState(closestLevel)
  const { colors: themeColors } = Paper.useAppTheme()

  const updateLevelFromState = (e) => {
    const index = e?.state?.index
    const routeNames = e?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    setSelectedLevel(currentName)
  }

  const onSubmit = () => {
    const level = budget.levels.find(level => {
      return level.id === selectedLevel
    })

    if (level) {
      update(expenseIndex, { data: {
        id: watchedExpenses[expenseIndex].data.id,
        selectedLevel: level.id,
        selectedCost: level.value,
      }})
    }

    onDismiss()
  }

  const submitCustom = (attributes) => {
    const data = {
      id: watchedExpenses[expenseIndex].data.id,
      selectedLevel: BudgetLevelIdentifier.CUSTOM,
      selectedCost: attributes.customAmount * 12,
    }
    update(expenseIndex, { data })

    onDismiss()
  }

  const LevelTabScreen = ({ route, navigation }) => {
    const { level }  = route?.params || {}
    return (
      <>
        <View style={{ ...Flex.column.start, alignItems: 'center', paddingVertical: Sizing.x20 }}>
          <Headline style={{ textAlign: 'center'}} >{formatCurrencyAmount(level.value / 12)} / month</Headline>
          <Subheading style={{ textAlign: 'center'}} >Summary: {level.summary}</Subheading>
          <Paragraph style={{ textAlign: 'center'}} >Example: {level.description}</Paragraph>
        </View>
      </>
    )
  }

  return (
    <ModalEditScreen
      formTitle={budget.name}
      onDismiss={onDismiss}
      buttonTitle={isCustom ? `Set Custom Amount` : `Choose ${upperCase(selectedLevel)}`}
      buttonAction={isCustom ? handleSubmit(submitCustom) : onSubmit}
      enableButton={isCustom ? isValid : true}
      allowTextButton={true}
      textButtonAction={isCustom ? () => setIsCustom(false) : () => setIsCustom(true)}
      textButtonTitle={isCustom ? `Pick a level` : `Enter custom amount`}
    >
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Headline>{isCustom ? `Enter Custom Amount`: `Choose a Level`}</Headline>
      </View>
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Subheading>{isCustom ? `Enter your own monthly amount`: `Pick between three suggested levels, or enter a custom amount`}</Subheading>
        {
          isCustom ? undefined : <NamedInformationButton name={NamedInformation.RETIREMENT_BUDGET} />
        }
      </View>
      {
        isCustom ?
        <ManagedCurrencyInput
          name={'customAmount'}
          formObj={formObj}
          placeholder={'Enter amount'}
          required={true}
          minValue={0}
          maxValue={MAX_BUDGET_CUSTOM_CATEGORY_AMOUNT}
          blurOnSubmit={true}
        />
        :
        <View style={{ height: scaleNormalizer(350) }}>
          <NavigationContainer
            documentTitle={{
              enabled: false,
            }}      
            independent={true}
          >
            <Tab.Navigator
              screenListeners={{
                state: (e) => updateLevelFromState(e.data)
              }}
              initialRouteName={selectedLevel}
              sceneContainerStyle={{ backgroundColor: 'transparent' }}
              screenOptions={{
                tabBarActiveTintColor: themeColors.accent,
                tabBarInactiveTintColor: themeColors.primary,
                tabBarStyle: {
                  backgroundColor: 'transparent',
                  elevation: 0,
                },
                tabBarLabelStyle: {
                  ...Typography.fontSize.x10,
                },
                tabBarIndicatorStyle: {
                  backgroundColor: themeColors.accent,
                },
              }} 
            >
              {
                budget.levels.map((level, idx) => {
                  return (
                    <Tab.Screen key={idx} name={level.id} component={LevelTabScreen} initialParams={{ level }}/>
                  )
                })
              }
            </Tab.Navigator>
          </NavigationContainer>
        </View>
      }
    </ModalEditScreen>
  )
}
