import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandQuery, useUpdatePensionBrandMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminPensionBrandDataId, setCurrentAdminPensionBrandDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditPensionBrandsModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()
  const currentPensionBrand = useAppSelector(currentAdminPensionBrandDataId)

  const currentPensionBrandId = useAppSelector(currentAdminPensionBrandDataId)

  const { data: brand, isLoading: brandIsLoading, error: brandError } = useGetPensionBrandQuery(currentPensionBrandId, { skip: !currentPensionBrandId } )
  const [updatePensionBrand, { data: updatedPensionBrand, isLoading: brandUpdateIsLoading, error: brandUpdateError, reset: brandUpdateReset }] = useUpdatePensionBrandMutation()

  const formObj = useForm<{
    name: string,
    description: string,
    raindropId: string,
    raindropName: string,
    logo: string,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, register, setValue, setError, reset, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')

  useEffect(() => {
    if (brand) {
      reset({
        name: brand?.name,
        description: brand?.description,
        raindropId: brand?.raindropId,
        raindropName: brand?.raindropName,
        logo: brand?.logo,
      })
    }
  }, [brand])

  //Form refs for focussing
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const raindropIdRef = useRef(null)
  const raindropNameRef = useRef(null)

  const onSubmit = async attributes => {
    await updatePensionBrand({ id: brand.id, ...attributes })
  }

  useEffect(() => {
    if (updatedPensionBrand) {
      close()
    }
  }, [updatedPensionBrand])

  const close = () => {
    dispatch(setCurrentAdminPensionBrandDataId(undefined))
  }

  const isLoading = brandIsLoading ||  brandUpdateIsLoading
  const error: any = brandError || brandUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <ModalEditScreen
      formTitle='Edit Pension Brand Details'
      onDismiss={() => dispatch(setCurrentAdminPensionBrandDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this pension brand?'}
      error={error}
      errorTryAgain={brandUpdateError ? handleSubmit(onSubmit) : undefined}
      errorCancel={brandUpdateError ? brandUpdateReset : close}
      isLoading={isLoading}
      loadingMessage={brandUpdateIsLoading ? ['Saving brand...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: brand?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: brand?.name,
          },
          {
            label: `Brand Key`,
            value: brand?.key,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Optional description shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => raindropIdRef.current?.focus()}
        rules={{
          required: false,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={raindropIdRef}
        name={'raindropId'}
        formObj={formObj}
        label={'Raindrop Id'}
        placeholder={'Identifier in Raindrop system'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => raindropNameRef.current?.focus()}
        rules={{
          required: false,
          minLength: 36,
          maxLength: 36,
      }}/>
      <ManagedTextInput
        ref={raindropNameRef}
        name={'raindropName'}
        formObj={formObj}
        label={'Raindrop Name'}
        placeholder={'Brand name in Raindrop system'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: false,  
          minLength: 2,
          maxLength: 100,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
    </ModalEditScreen>
  )
}

