import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'
import { useAppDispatch } from 'store/hooks'

export const BeneficiariesAddBeneficiary_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss } = route?.params || {}

  const next = () => {
    navigation.navigate(nextScreen)
  }
  const dispatch = useAppDispatch()

  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'girl_with_long_hair.png'}
      headline={'Add a new beneficiary'}
      subHeading={'We just need to record a few details about them'}
    >
    </ModalProcessIntroScreen>
  )
}
