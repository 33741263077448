import { ContentDivider } from "components/Layout/ContentDivider"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { AppIllustration } from "components/Utility/AppIllustration"
import { scaleNormalizer } from "lib/scaleHelpers"
import { SurplusStrategy } from "providers"
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { ScenarioModelRepresentationDto } from "store/dto/model.dto"
import { Colors, Sizing, Paper } from "styles"

type SuggestionIncreaseSurplusModalContentProps = {
  surplusStrategy: SurplusStrategy
  title: string
  description: string
  model: ScenarioModelRepresentationDto
  illustrationFilename: string
}

export const SuggestionIncreaseSurplusModalContent = (props: SuggestionIncreaseSurplusModalContentProps) => {
  const { model, illustrationFilename, title, description } = props || {}

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      <Headline style={localStyles.title}>{`Increase Legacy Surplus`}</Headline>

      <ContentDivider />
      <View style={{
        alignSelf: 'center',
      }}>
        <AppIllustration filename={illustrationFilename} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Subheading style={localStyles.text}>{title}</Subheading>
      <Paragraph style={localStyles.text}>{description}</Paragraph>
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    // textAlign: 'left',
  },
  subTitle: {
    // textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    // textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})