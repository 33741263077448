import { Subheading } from "components/Typography"
import { createBranchHandOffLink, createBranchLinkForLinkParams } from "lib/linkHelpers"
import React, { useState } from 'react'
import { View } from "react-native"
import Collapsible from "react-native-collapsible"
import QRCode from "react-native-qrcode-svg"
import { linkParams, loginParams } from "store/authSlice"
import { useAppSelector } from "store/hooks"
import { Sizing } from "styles"
import { Button } from "./Button"
import { ExternalLinkImage } from "./ExternalLinkImage"
import { AUTHTYPE } from "store/dto/base.dto"

export interface WebAppDownloadContentProps {
  noTitle?: boolean
  forceShowButtons?: boolean
  handOffEmailAddress?: string
  authType?: AUTHTYPE
}

export const WebAppDownloadContent = (props: WebAppDownloadContentProps) => {
  const { noTitle, forceShowButtons, handOffEmailAddress, authType } = props || {}
  const linkConfig = useAppSelector(linkParams)

  const downloadUrl = handOffEmailAddress
    ? createBranchHandOffLink(handOffEmailAddress, authType, linkConfig)
    : createBranchLinkForLinkParams(linkConfig)

  const [showButtons, setShowButtons] = useState(false)

  return (
    <>
      {
        forceShowButtons || showButtons
          ? <>
              {
                noTitle ? <></> : <Subheading>{`Use the links to go to the relevant app store`}</Subheading>
              }
              <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                paddingVertical: Sizing.x20,
              }}>
                <ExternalLinkImage
                  url={downloadUrl}
                  directToBrowser={true}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x50,
                    alignSelf: 'center',
                  }}
                  resizeMode={'contain'}
                  source={require('assets/app-icons/playstore.png')}
                />
                <ExternalLinkImage
                  url={downloadUrl}
                  directToBrowser={true}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x50,
                    alignSelf: 'center',
                  }}
                  resizeMode={'contain'}
                  source={require('assets/app-icons/appstore.png')}
                />
              </View>
            </>
          : <>
              {
                noTitle ? <></> : <Subheading>{`Scan the QR code with your phone`}</Subheading>
              }
              <View style={{
                alignSelf: 'center',
                paddingVertical: Sizing.x20,
              }}>
                <QRCode
                  value={downloadUrl}
                  size={Sizing.x150}
                />
              </View>
            </>
      }
      {
        forceShowButtons === undefined
          ? <Button
              mode='text'
              onPress={showButtons ? () => setShowButtons(false) : () => setShowButtons(true)}
            >
            {showButtons ? 'Show QR Code Instead' : 'Viewing on your mobile device?'}
          </Button>
          : <></>
      }
    </>
  )
}
