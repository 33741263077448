import { Text } from 'components/Typography/Text'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { SuggestionCard } from 'components/Utility/SuggestionCard'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { orderBy } from 'lodash'
import { useSuggestionsContext } from 'providers/suggestions.context'
import React, { useRef } from 'react'
import { StyleSheet } from 'react-native'
import { View } from 'react-native'
import { Flex, Sizing, Typography } from 'styles'

type SuggestionCardSetProps = {
  screen: MainAppNavScreen
}

export const SuggestionCardSet = (props: SuggestionCardSetProps) => {
  const { screen } = props
  
  const { getSuggestionsForScreen } = useSuggestionsContext()
  const suggestions = getSuggestionsForScreen(screen)

  const sliderWidth = getScreenAppWidth() - Sizing.x30

  const orderedSuggestions = orderBy(suggestions, ['priority'], ['asc'])

  const carouselRef = useRef()
  
  const snapToNext = () => {
    carouselRef?.current?.next()
  }

  const snapToPrevious = () => {
    carouselRef?.current?.prev()
  }

  const handleDismiss = (index: number) => {
    if (orderedSuggestions?.length > 1) {
      if (index === 0) {
        snapToNext()
      } else {
        snapToPrevious()
      }
    }
  }
  
  const renderSuggestionCard = ({ item, index }) => {
    return (
      <SuggestionCard
        index={index}
        suggestion={item}
        onDismissal={handleDismiss}
      />
    )
  }

  return (
    <>
      {
        orderedSuggestions.length ?
        <View style={{paddingVertical: Sizing.x20}}>
          <View style={Flex.row.center} >
            <View style={{ width: Sizing.x30, paddingRight: Sizing.x10 }}>
            </View>
            <Text style={localStyles.sectionTitle}>{'Suggested Actions'}</Text>
            <View style={{ width: Sizing.x30, paddingLeft: Sizing.x10 }}>
              <NamedInformationButton name={NamedInformation.SUGGESTED_ACTIONS} iconOnly={true} iconOnlySize={Sizing.x15} />
            </View>
          </View>
          <ManagedCarousel
            ref={carouselRef}
            loop={false}
            width={sliderWidth}
            height={Sizing.x120}
            data={orderedSuggestions}
            renderItem={renderSuggestionCard}
          />
        </View>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  sectionTitle: {
    ...Typography.fontSize.x15,
    paddingBottom: Sizing.x10,
  },
})