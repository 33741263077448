import { MaterialCommunityIcons } from '@expo/vector-icons'
import { LoopingText } from 'components/Typography/LoopingText'
import { Text } from 'components/Typography/Text'
import { platformIsWeb } from 'lib/platformHelpers'
import { floor } from 'lodash'
import { useCalendarContext } from 'providers/calendar.context'
import React, { useEffect, useRef, useState } from 'react'
import { Animated, Easing, Image, StyleSheet, View } from "react-native"
import { Colors, Flex, Paper, Sizing, Typography } from "styles"

export type LoadingProps = {
  message?: string[]
  messageCycleDelayMs?: number
  messageCycleTransitionMs?: number
  messageNoLoop?: boolean
  size?: number
  useHolidayGraphics?: boolean
}
const DEFAULT_SIZE = Sizing.x70
const DEFAULT_CYCLE_DELAY_MS = 6000
const DEFAULT_CYCLE_TRANSITION_MS = 250
const HOLIDAY_ICON_SCALE_FACTOR = 0.8
const SPIN_DURATION = 2000
const FADE_DURATION = 200

export const Loading = (props: LoadingProps) => {
  const { message, size, messageCycleDelayMs, messageCycleTransitionMs, messageNoLoop, useHolidayGraphics } = props
  const { colors: themeColors } = Paper.useAppTheme()

  const { calendarPeriod } = useCalendarContext()
  const { icons } = calendarPeriod || {}

  const iconCount = icons ? icons.length : 0
  const multipleIcons = icons ? icons?.length > 1 : false

  const jarvisWhiteLogo = require('assets/brand/jarvis-symbol-wht-4x.png')
  const jarvisBlackLogo = require('assets/brand/jarvis-symbol-blk-4x.png')

  const isWeb = platformIsWeb()

  const [imageIndex, setImageIndex] = useState(0)
  const [useLogo, setUseLogo] = useState(true)

  const getAnimationValue = (spinDuraction, fadeduration, callBack) => {
    let isMounted = true
    const spinValue = useRef(new Animated.Value(0)).current
    const opacityValue = useRef(new Animated.Value(0)).current
    const spinImage = () => {
      Animated.sequence([
        Animated.timing(
          spinValue,
          {
            toValue: 0,
            duration: 0,
            useNativeDriver: !isWeb
          }
        ),
        Animated.timing(
          opacityValue,
          {
            toValue: 1,
            duration: multipleIcons ? fadeduration : 0,
            useNativeDriver: !isWeb
          }
        ),
        Animated.timing(
          spinValue,
          {
            toValue: 1,
            duration: spinDuraction,
            easing: Easing.bezier(0.75, 0.0655, 0.355, 0.8),
            useNativeDriver: !isWeb
          }
        ),
        Animated.timing(
          opacityValue,
          {
            toValue: multipleIcons ? 0 : 1,
            duration: multipleIcons ? fadeduration : 0,
            useNativeDriver: !isWeb,
          }
        ),
      ]).start(() => {
        if (isMounted) {
          spinValue.setValue(0)
          opacityValue.setValue(1)
          callBack()
          spinImage()
        }
      })
    }
    useEffect(() => {
      spinImage()
      return () => {
        spinValue.stopAnimation()
        opacityValue.stopAnimation()
        isMounted = false
      }
    }, [])
    return ([spinValue, opacityValue])
  }

  const [spinValue, opacityValue] = getAnimationValue(SPIN_DURATION, FADE_DURATION, () => {
    setUseLogo((prevLogo) => {
      return !prevLogo
    })
    setImageIndex((prevIndex) => {
      return prevIndex > iconCount - 1 ? 0 : prevIndex + 0.5 //Add half as only used every other iteration
    })
  })

  return (
    <View style={localStyles.container}>
      {/* <ActivityIndicator size="large" color={themeColors.primary} /> */}
      <Animated.View style={{
        width: size || DEFAULT_SIZE,
        height: size || DEFAULT_SIZE,
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        transform: [{ rotate: spinValue.interpolate({
          inputRange: [0, 1, 2],
          outputRange: ['0deg', '360deg', '0deg']
        }) }],
        opacity: opacityValue,
      }}>
        {
          useLogo || !iconCount
          ? <Image source={themeColors.primary === Colors.neutral.white ? jarvisWhiteLogo : jarvisBlackLogo} style={{
              width: size || DEFAULT_SIZE,
              height: size || DEFAULT_SIZE,
            }} />
          : <MaterialCommunityIcons
              name={(icons ? icons[floor(imageIndex)] : '') as any}
              size={size * HOLIDAY_ICON_SCALE_FACTOR || DEFAULT_SIZE * HOLIDAY_ICON_SCALE_FACTOR}
              color={themeColors.primary === Colors.neutral.white ? Colors.neutral.white : Colors.neutral.black}
              style={{
                alignSelf: 'center'
              }}
            />
        }
      </Animated.View>
      {message && message.length === 1 ?
        <View style={{
          paddingTop: Sizing.x20,
        }}>
          <Text>{message[0]}</Text>
        </View>
        : message ?
          <View style={{
            paddingTop: Sizing.x20,
          }}>
            <LoopingText style={[
              Typography.defined.paragraph,
              {
                color: themeColors.primary,
                alignSelf: 'center',
              }]}
              textArray={message}
              delay={messageCycleDelayMs || DEFAULT_CYCLE_DELAY_MS}
              duration={messageCycleTransitionMs || DEFAULT_CYCLE_TRANSITION_MS}
              noLoop={messageNoLoop}
            />
          </View>
          : <></>
      }
    </View>
  )
}

const localStyles = StyleSheet.create({
  container: {
    ...Flex.column.center,
    flex: 1,
    // paddingVertical: Sizing.x10,
  },
})
