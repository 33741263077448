import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminAssetCategoryListScreen } from './Screens/AdminAssetCategoryListScreen'

export const AdminAssetCategorySectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Asset Categories'}
    >
      <AdminAssetCategoryListScreen />
    </SectionSimpleContainer>
  )
}

