import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { getEntryScreenForAppContext } from 'lib/navigationHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { AppContext } from 'store/authSlice'

export const EnrolmentOptOutHeroScreen = ({ route }) => {

  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'envelope_with_report.png',
      headline: `Ok, we've got your Opt Opt decision`,
      subHeading: `We've sent you a confirmation email with the details`,
    },
    {
      illustrationFilename: 'regular_contributions.png',
      headline: `We've also let your employer know`,
      subHeading: `They should return any pension contributions they've deducted`,
    },
  ]

  const finishRoute = getEntryScreenForAppContext(AppContext.CLIENT)
  const finish = () => {
    rootNavigate(finishRoute)
  }

  return (
    <>
      <ProcessHeroScreen
        carouselScreens={carouselScreens}
        onFinish={finish}
        finishButtonLabel={`Finish`}
      />
    </>
  )
}
