import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { SpouseAddProcessStack } from 'features/ModalProcesses/SpouseAdd/SpouseAddProcessStack'
import { SpouseEditModal } from 'features/ModalProcesses/SpouseEdit/SpouseEditModal'
import { Logger } from 'lib/logger'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { enableModalHeader } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

export const ChangeRetirementBudget_01_SpouseChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  const [addSpouse, setAddSpouse] = useState(false)
  const [editSpouse, setEditSpouse] = useState(false)

  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()

  const disableModalHeader = addSpouse || editSpouse

  const dispatch = useAppDispatch()

  dispatch(enableModalHeader(!disableModalHeader))

  const isLoading = spouseIsLoading || rpIsLoading
  const error: any = rpError
  
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  let asCouple: boolean
  if (spouse && (workingRetirementProfileData?.asCouple === true || retirementProfile?.asCouple === true)) {
    asCouple = true
  } else if (!spouse && (workingRetirementProfileData?.asCouple === false || retirementProfile?.asCouple === false)) {
    asCouple = false
  }

  const noSpouseAlert = !spouse && retirementProfile?.asCouple === true
  const spouseAlert = spouse && retirementProfile?.asCouple === false

  //Setup form
  const formObj = useForm<{  asCouple: boolean}>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      asCouple,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    if (attributes.asCouple && !spouse) {
      setAddSpouse(true)
    } else {
      dispatch(updateWorkingRetirementProfile(attributes))
      navigation.navigate(nextScreen)
    }
  }
  
  const spouseOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'On your own'
    },
    {
      value: true,
      label: spouse ? `With ${spouse?.firstName}` : 'With a partner',
    },
  ]

  return (
    <>
      {
        addSpouse
        ? <SpouseAddProcessStack
          visible={addSpouse}
          onDismiss={() => setAddSpouse(false)}
          theme={Paper.darkThemeOnLightGrey}
          preventHeaderDisable={true}
        />
        : editSpouse ?
        <SpouseEditModal
          onDismiss={() => setEditSpouse(false)}
        />
        :
        <ModalProcessScreen
          isLoading={isLoading}
          error={error}
          errorTryAgain={refetchRp}
          buttonTitle={'Next'}
          buttonAction={handleSubmit(onSubmit)}
          enableButton={isValid}
          headline={`With whom will you be retiring?`}
          subHeading={`Retiring alone or with someone else affects the kind of budget you'll need`}
        >
          <ManagedSimpleChoiceInput
            name={'asCouple'}
            formObj={formObj}
            options={spouseOptions}
            submitHandler={handleSubmit(onSubmit)}
            required={true}
          />
          {
            spouse ?
            <Button mode='text' onPress={() => setEditSpouse(true)} >Change {spouse?.firstName}</Button>
            : <></>
          }
          {
            spouseAlert ?
            <View style={Flex.column.start}>
              <View style={Flex.row.center}>
                <MaterialCommunityIcons name={'alert-rhombus'} size={Sizing.x50} color={Colors.warning.s400}/>
              </View>
              <Paragraph>{`You previously defined your budget without a partner, but have since added one.`}</Paragraph>
              <Paragraph>{`We recommend choosing 'With ${spouse?.firstName}' to get the most realistic suggested budgets.`}</Paragraph>
            </View>
            : <></>
          }
          {
            noSpouseAlert ?
            <View style={Flex.column.start}>
              <View style={Flex.row.center}>
                <MaterialCommunityIcons name={'alert-rhombus'} size={Sizing.x50} color={Colors.warning.s400}/>
              </View>
              <Paragraph>{`You previously defined your budget with a partner, but have since removed them.`}</Paragraph>
              <Paragraph>{`We recommend choosing 'On your own' to get the most realistic suggested budgets.`}</Paragraph>
            </View>
            : <></>
          }
        </ModalProcessScreen>
      }
    </>
  )
}
