import { JarvisIcon } from 'components/Brand/JarvisIcon'
import React, { ComponentProps, useRef, useState } from 'react'
import { View, StyleSheet, Animated, Image, ImageSourcePropType } from 'react-native'
import { Sizing } from 'styles'
import { pick } from 'lodash'

type ProgressiveImageProps = ComponentProps<typeof Image> & {
    thumbnailSource?: ImageSourcePropType
}
  
export const ProgressiveImage = (props: ProgressiveImageProps) => {
  const { source, thumbnailSource, style, ...remainingProps } = props || {}
  
  const [imageReady, setImageReady] = useState(false)

  const thumbnailAnimated = useRef(new Animated.Value(0)).current

  const handleThumbnailLoad = () => {
    Animated.timing(thumbnailAnimated, {
      useNativeDriver: false,
      toValue: 1,
    }).start()
  }

  return (
    <View style={style}>
      <Animated.Image
        {...remainingProps}
        source={thumbnailSource}
        style={[localStyles.imageOverlay, { opacity: thumbnailAnimated }, style, { display: imageReady ? 'none' : undefined}]}
        onLoad={handleThumbnailLoad}
        blurRadius={1}
      />
      <Animated.Image
        {...remainingProps}
        source={source}
        style={[localStyles.imageOverlay, style]}
        onLoad={() => setImageReady(true)}
      />
    </View>
  )
}

const localStyles = StyleSheet.create({
  imageOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
})
  
  