import { MaterialCommunityIcons } from '@expo/vector-icons'
import { CardModal } from 'components/Layout/CardModal'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { FooterButton } from 'components/Utility/FooterButton'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

type ActionCardModalProps = {
  content: Function
  visible: boolean
  onDismiss: any
  onProceed: any
  onCancel?: any
  proceedLabel?: string
  cancelLabel?: string
  cancelConfirmationMessage?: string
  noModalProceedButton?: boolean
}

export const ActionCardModal = (props: ActionCardModalProps) => {

  const { onDismiss, onProceed, onCancel, visible, content, proceedLabel, cancelLabel, noModalProceedButton, cancelConfirmationMessage } = props

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const handleCancel = () => {
    hideDialog()
    if (onCancel) {
      onCancel()
    }
  }

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >

          <View style={layoutStyles.cardModalContentContainer}>
            {content()}
          </View>
        </ScrollView>
        {
          cancelLabel ?
            <View style={{paddingTop: Sizing.x20}}>
              <FooterButton onPress={onCancel ? cancelConfirmationMessage ? showDialog : handleCancel : onDismiss} mode={'text'}>{cancelLabel}</FooterButton>
            </View>
          : <></>
        }
        { !noModalProceedButton ?
          <FooterButton onPress={onProceed} mode={'contained'} >{proceedLabel}</FooterButton>
          : <></>
        }
      </View>
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={cancelConfirmationMessage}
        onCancel={hideDialog}
        onConfirm={handleCancel}
      />
    </CardModal>
  )
}