import { logger, mapConsoleTransport } from "react-native-logs"
import { InteractionManager } from 'react-native'
import { envVariables } from 'environment'

const { minimumLogSeverity } = envVariables

const config = {
  async: true,
  asyncFunc: InteractionManager.runAfterInteractions,
  transport: mapConsoleTransport,
  severity: minimumLogSeverity,
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  transportOptions: {
    mapLevels: {
      debug: 'debug',
      info: 'info',
      warn: 'warn',
      error: 'error',
    },
    colors: {
      debug: 'white',
      info: 'blueBright',
      warn: 'yellowBright',
      error: 'redBright',
    },
  },
  dateFormat: 'utc',
}

var Logger = logger.createLogger<'debug' | 'info' | 'warn' | 'error'>(config)

export { Logger }