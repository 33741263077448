import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { Loading } from 'components/Utility/Loading'
import { hexColorWithOpactity } from 'lib/colorHelpers'
import React from 'react'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { Colors, Flex, Sizing, Paper } from 'styles'

export type DashboardSectionProps = {
  visible?: boolean
  error?: any
  isLoading?: boolean
  isFetching?: boolean
  title: string
  titleInfoName?: NamedInformation
  content: React.ReactNode
  contentStyle?: ViewStyle
  refreshFunction?: any
}

type DashboardSectionFullProps = DashboardSectionProps & {
  previousColor: string
}


export const DashboardSection = (props: DashboardSectionFullProps) => {
  const { error, visible, isLoading, isFetching, title, titleInfoName, previousColor, content, contentStyle, refreshFunction } = props

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      { visible ?
        <View style={[localStyles.dashboardSectionBackground,
          {
            backgroundColor: previousColor,
          }
          ]}>
            <View style={[localStyles.dashboardSectionBody,
            {
              backgroundColor: themeColors.pageBackground,
            }
            ]}>
              <View style={[localStyles.dashboardSectionHeader,
              ]}>
                <View style={[localStyles.dashboardSectionHeaderContent,
                {
                  borderBottomColor: hexColorWithOpactity(themeColors.themeType === 'dark' ? Colors.neutral.black : Colors.neutral.white, 0.5),
                }
                ]}>
                  <View style={{
                    width: Sizing.x30,
                    height: Sizing.x30,
                    paddingTop: Sizing.x10,
                    paddingLeft: Sizing.x5,
                    paddingRight: Sizing.x10,
                  }}>
                    {
                      titleInfoName !== undefined ?
                      <NamedInformationButton name={titleInfoName} iconOnly={true} iconOnlySize={Sizing.x15} iconColor={themeColors.headlineText} />
                      : <></>
                    }
                  </View>
                  <Subheading style={{
                    color: themeColors.headlineText,
                    textAlign: 'center',
                  }}>{title}</Subheading>
                  {
                    isFetching ?
                    <View style={{
                      width: Sizing.x40,
                      marginTop: -Sizing.x20,
                    }}>
                      <Loading size={Sizing.x20}/>
                    </View>
                    : refreshFunction ?
                    <Pressable
                      onPress={refreshFunction}
                      style={{
                        width: Sizing.x40,
                        height: Sizing.x30,
                        paddingTop: Sizing.x5,
                        paddingLeft: Sizing.x10,
                      }}>
                      <MaterialCommunityIcons name="refresh" size={Sizing.x20} color={themeColors.headlineText} />
                    </Pressable>
                    : <View style={{
                      width: Sizing.x30,
                      height: Sizing.x30,
                      paddingTop: Sizing.x5,
                      paddingRight: Sizing.x10,
                    }}>
                    </View>
                  }
                </View>
              </View>
              {
                isLoading ?
                  <View style={{ height: Sizing.x150}}>
                    <Loading size={Sizing.x20} />
                  </View>
                  : error ?
                  <View style={{ height: Sizing.x150}}>
                    <ErrorScreen noDashboardButton={true} iconOnly={true} error={error?.data} />
                  </View>
                  :
                  <View style={[
                    localStyles.dashboardSectionContent, 
                    contentStyle || {}
                  ]}>
                    {content}
                  </View>
              }
            </View>
          </View>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  dashboardSectionBackground: {
    ...Flex.override.fill,
    marginTop: -1 //Get rid of thin line in rendernig
  },
  dashboardSectionBody: {
    ...Flex.column.start,
    borderTopLeftRadius: Sizing.x20,
    borderTopRightRadius: Sizing.x20,
    minHeight: Sizing.x50,
    paddingVertical: Sizing.x20,
  },
  dashboardSectionHeader: {
    height: Sizing.x50,
    paddingHorizontal: Sizing.x30,
  },
  dashboardSectionHeaderContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: Sizing.x50,
    borderBottomWidth: 0.5,
  },
  dashboardSectionContent: {
    paddingTop: Sizing.x10,
    paddingBottom: Sizing.x30,
    paddingHorizontal: Sizing.x0,
  },
})