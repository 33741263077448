import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { RetirementAssetsScreen } from './Screens/RetirementAssetsScreen'

export const OtherPensionsSectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Retirement Assets'}
    >
      <RetirementAssetsScreen />
    </SectionSimpleContainer>
  )
}

