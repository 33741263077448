import { ProcessScreen } from 'components/Layout'
import { Headline, Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { Logger } from 'lib/logger'
import { goToDashboard, rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { Image } from 'react-native'
import { useAcceptGroupSchemeInviteMutation, useDeclineGroupSchemeInviteMutation, useUpdateMeMutation } from 'store/apiSlice'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptSchemeInvite, workingAcceptSchemeInvite } from 'store/tempDataSlice'
import { Paper, Sizing } from 'styles'

export const SchemeInviteAccept_99_Result = ({ route, navigation }) => {
  const { nextRoute, schemeInvite, client }: { nextRoute: string, schemeInvite: PublicGroupSchemeInviteDto, client: ClientMeDto }  = route?.params || {}

  const verifiedNationalInsuranceNo = client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const workingAcceptSchemeInviteData: any = useAppSelector(workingAcceptSchemeInvite)

  const [ acceptSchemeInvite, { data: acceptedSchemeInvite, isLoading: acceptSchemeIsLoading, error: acceptSchemeError} ] = useAcceptGroupSchemeInviteMutation()
  const [ declineSchemeInvite, { data: declinedSchemeInvite, isLoading: declineSchemeIsLoading, error: declineSchemeError} ] = useDeclineGroupSchemeInviteMutation()
  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()

  const isLoading = acceptSchemeIsLoading || declineSchemeIsLoading || clientUpdateIsLoading
  const error: any = acceptSchemeError || declineSchemeError || clientUpdateError

  const wrongNino = acceptSchemeError && acceptSchemeError?.status === 403

  //Save client onboardingFlags on enter
  useEffect(() => {
    if (workingAcceptSchemeInviteData) {
      saveClient()
    }
  }, [])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        aceptedschemeinvite: true,
      },
    })
  }

  //Process invite when client saved
  useEffect(() => {
    if (updatedClient) {
      process()
    }
  }, [updatedClient])

  const process = () => {
    if (workingAcceptSchemeInviteData?.accept) {
      Logger.info(`Accepting invite...`)
      acceptSchemeInvite({
        groupSchemeId: schemeInvite.groupScheme?.id,
        inviteId: schemeInvite.id,
        ...workingAcceptSchemeInviteData,
      })
    } else {
      Logger.info(`Declining invite...`)
      declineSchemeInvite({
        groupSchemeId: schemeInvite.groupScheme?.id,
        inviteId: schemeInvite.id,
      })
    }
  }

  //End when invite processed
  useEffect(() => {
    if (acceptedSchemeInvite) {
      end()
    }
  }, [acceptedSchemeInvite])

  //End client when declined
  useEffect(() => {
    if (declinedSchemeInvite) {
      end()
    }
  }, [declinedSchemeInvite])
    
  const end = () => {
    //Clear temp data
    dispatch(updateWorkingAcceptSchemeInvite(undefined))
    rootNavigate(nextRoute, { schemeInvite, accept: workingAcceptSchemeInviteData?.accept })
  }

  const cancel = () => {
    //Clear temp data
    dispatch(updateWorkingAcceptSchemeInvite(undefined))
    goToDashboard()
  }

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <ProcessScreen
      headline={wrongNino ? `Sorry, we couldn't verify you` : ''}
      subHeading={wrongNino
        ? verifiedNationalInsuranceNo
          ? `The National Insurance Number associated with your Jarvis account doesn't match`
          : `The National Insurance Number you entered doesn't match`
        : ''
      }
      buttonTitle={wrongNino
        ? verifiedNationalInsuranceNo
          ? `Cancel`
          : `Go back and change it`
        : ''
      }
      buttonAction={verifiedNationalInsuranceNo ? cancel : goBack}
      showButton={wrongNino}
      error={error && !wrongNino}
      errorTryAgain={acceptSchemeError || declineSchemeError ? process : saveClient}
      isLoading={isLoading}
      loadingMessage={workingAcceptSchemeInviteData?.accept ? ['Accepting invite...'] : ['Declining invite...']}
      allowTextButton={wrongNino}
      textButtonAction={cancel}
      textButtonTitle={'Cancel for now'}
    >
      {
        wrongNino ?
          <>
            <AppIllustration filename={'magnifying_glass.png'} style={[
              {
                width: Sizing.x100,
                height: Sizing.x100,
                resizeMode: 'contain',
                alignSelf: 'center'
              },
            ]} />
            <Paragraph>{`Your employer's record has a differnet National Insurance Number.`}</Paragraph>
            <Paragraph>{verifiedNationalInsuranceNo
              ? `Your National Insurance Number on our records:`
              : `National Insurance Number your entered:`
            }</Paragraph>
            <Headline style={{ color: themeColors.accent }}>{formatNationalInsuranceNumber(workingAcceptSchemeInviteData?.nationalInsuranceNo)}</Headline>
            <Paragraph>{`Please double-check - if yours is correct, you may need to speak to your employer to check their records.`}</Paragraph>
          </>
          : <></>
      }
    </ProcessScreen>
  )
}
