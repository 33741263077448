import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { MainAppHeaderTitle } from 'components/Typography'
import { MainAppSubHeaderTitle } from 'components/Typography/MainAppSubHeaderTitle'
import { platformIsWeb } from 'lib/platformHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing } from 'styles'

export type FunctionalAreaProps = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
  sectionTitle: string
  sectionSubTitle?: string
  exitRoute: string
  routeParams: any
}

export const FunctionalAreaHeader = (props: FunctionalAreaProps) => {
  const { navigationRef, sectionTitle, sectionSubTitle, exitRoute, routeParams } = props
  
  const goBack = () => {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack()
    } else {
      rootNavigate(exitRoute, routeParams)
    }
  }

  const isWeb = platformIsWeb()
  const { colors: themeColors } = Paper.useAppTheme()

  const insets = useSafeAreaInsets()

  return (
    <>
      <View style={{
        ...localStyles.processHeaderContainer,
        backgroundColor: isWeb ? Colors.brand.grey4 : Colors.brand.purple0,
        paddingTop: insets.top
      }}>
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            <MaterialCommunityIcons name="chevron-left" size={Sizing.x40} color={themeColors.primary} onPress={goBack} />
          </View>
          <View style={localStyles.headerTitleContainer}>
            <MainAppHeaderTitle>{sectionTitle}</MainAppHeaderTitle>
            {
              sectionSubTitle
                ? <MainAppSubHeaderTitle style={{ color: themeColors.accent }}>{sectionSubTitle}</MainAppSubHeaderTitle>
                : <></>
            }
          </View>
          <View style={localStyles.headerIconContainer}>
          </View>
        </View>
      </View>
    </>
  )
}

const localStyles = StyleSheet.create({
  processHeaderContainer: {
    ...Flex.column.center,
    height: Sizing.x120,
    paddingTop: Sizing.x10,
  },
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    height: Sizing.x40,
    width: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    alignSelf: 'center',
    // flex: 1,
    paddingHorizontal: Sizing.x20,
  }
})