import ModalProcessStackBuilder from 'navigation/stacks/ModalProcessStackBuilder'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse } from 'store/tempDataSlice'
import { setAddSpouseVisible, addSpouseVisible } from 'store/uxSlice'
import { SpouseAdd_00_Intro, SpouseAdd_01_InviteCheck, SpouseAdd_02_Consent, SpouseAdd_03_FirstName, SpouseAdd_04_Surname, SpouseAdd_05_Title, SpouseAdd_06_Gender, SpouseAdd_07_DateOfBirth, SpouseAdd_10_InviteChoice, SpouseAdd_11_Assets, SpouseAdd_12_Incomes, SpouseAdd_20_DeclineTransition, SpouseAdd_99_Result } from './Screens'

export type SpouseAddProcessStackProps = {
  visible?: boolean
  onDismiss?: any
  theme?: any
  preventHeaderDisable?: boolean
}

export const SpouseAddProcessStack = (props: SpouseAddProcessStackProps) => {

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setAddSpouseVisible(false))
  
  const visible = props.visible !== undefined ? props.visible : useAppSelector(addSpouseVisible)
  const onDismiss = props.onDismiss || globalDismiss

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingSpouse(undefined))
  }, []
  )
  
  return (
    <ModalProcessStackBuilder
      theme={props.theme}
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding a partner?`}
      processTitle='Your Partner'
      initialRouteName='Intro'
      preventHeaderDisable={props.preventHeaderDisable}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Your Partner',
          component: SpouseAdd_00_Intro,
        },
        {
          navigationId: 'InviteCheck',
          title: 'Invite Check',
          component: SpouseAdd_01_InviteCheck,
        },
        {
          navigationId: 'Consent',
          title: 'Consent',
          component: SpouseAdd_02_Consent,
        },
        { 
          navigationId: 'FirstName',
          title: 'First Name',
          component: SpouseAdd_03_FirstName,
        },
        {
          navigationId: 'Surname',
          title: 'Surname',
          component: SpouseAdd_04_Surname,
        },
        {
          navigationId: 'Title',
          title: 'Title',
          component: SpouseAdd_05_Title,
        },
        {
          navigationId: 'Gender',
          title: 'Gender',
          component: SpouseAdd_06_Gender,
        },
        {
          navigationId: 'DateOfBirth',
          title: 'Date of Birth',          
          component: SpouseAdd_07_DateOfBirth,
        },
        {
          navigationId: 'InviteChoice',
          title: 'Invite Choice',
          component: SpouseAdd_10_InviteChoice,
        },
        {
          navigationId: 'Assets',
          title: 'Assets',
          component: SpouseAdd_11_Assets,
        },
        {
          navigationId: 'Incomes',
          title: 'Incomes',
          component: SpouseAdd_12_Incomes,
        },
        {
          navigationId: 'DeclineTransition',
          title: 'Decline Transition',
          component: SpouseAdd_20_DeclineTransition,
        },
        {
          navigationId: 'Results',
          title: 'All Done!',
          component: SpouseAdd_99_Result,
        },
      ]}
    />
  )
}
