import { ProcessScreen } from 'components/Layout'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import { omit } from 'lodash'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useAddInviteMutation, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingInvite, workingInvite } from 'store/tempDataSlice'

export const SpouseInvite_99_Result = ({ navigation, route }) => {
  const { nextRoute }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingInviteData: any = useAppSelector(workingInvite)

  const [ updateClient, { data: client, error: clientUpdateError, isLoading: clientUpdateIsLoading } ] = useUpdateMeMutation()
  const [ createInvite, { data: createdInvite, isLoading: inviteCreateIsLoading, error: inviteCreateError} ] = useAddInviteMutation()

  const isLoading = inviteCreateIsLoading || clientUpdateIsLoading
  const error: any = inviteCreateError || clientUpdateError
  //Save on enter
  useEffect(() => {
    if (workingInviteData) {
      saveInvite()
    } else {
      saveClient()
    }
  }, [])

    //Save client when spouse saved
    useEffect(() => {
      if (createdInvite) {
        saveClient()
      }
    }, [createdInvite])

  //Navigate if successful
  useEffect(() => {
    if (client) {
      ampli.spouseInviteSetup({
        skipped: false,
      })
      ampli.inviteSend({
        email: createdInvite?.email,
        invitorUserId: createdInvite?.userId
      })
      end()
    }
  }, [client])

  const saveInvite = () => {
    Logger.info(`Creating invite...`)
    createInvite({
      asSpouse: true,
      ...omit(workingInviteData, ['checkDetailsChoice'])
    })
  }

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        invitespouse: true,
      },
    })
  }

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingInvite(undefined))
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      buttonAction={() => {}}
      showButton={false}
      error={error}
      errorTryAgain={createdInvite  ? saveClient : saveInvite}
      isLoading={isLoading}
      loadingMessage={['Sending invite...']}
    />
  )
}
