import { ManagedCardMultipleChoiceInput, ManagedCardMultipleChoiceInputOption } from 'components/Inputs/ManagedCardMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { RetirementAssetTransferModalContent } from 'components/Utility/RetirementAssetTransferModalContent'
import { RetirementAssetUntransferableModalContent } from 'components/Utility/RetirementAssetUntransferableModalContent'
import { RetirementAssetsAddAssetProcessStack } from 'features/ModalProcesses/RetirementAssetAdd/RetirementAssetsAddAssetProcessStack'
import { RetirementAssetsEditAssetModal } from 'features/ModalProcesses/RetirementAssetEdit/RetirementAssetsEditAssetModal'
import { RetirementAssetTransferProcessStack } from 'features/ModalProcesses/RetirementAssetTransfer/RetirementAssetTransferProcessStack'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { Logger } from 'lib/logger'
import { filterAssetsInScopeForTransfer, clientAddressableUntransferableReasons, untransferableReasonsNeedingEdit } from 'lib/retirementAssetHelpers'
import { compact, difference, intersection, pull, sumBy, union } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SectionList, View } from 'react-native'
import { useGetMeQuery, useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetRetirementAssetsQuery, useGetSignatureQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { RetirementAssetDto, RetirementAssetUntransferableReason } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBulkTransfer, workingBulkTransfer } from 'store/tempDataSlice'
import { Typography, Paper } from 'styles'
import { platformIsWeb } from 'lib/platformHelpers'
import { ampli } from 'src/ampli'

const isWeb = platformIsWeb()

interface ReturnType {
  title: string,
  data: {
    id: 'ready' | 'onHold' | 'transferable' | 'untransferable'
    assets: RetirementAssetDto[]
  }[]
}

export const BulkTransfer_01_Assets = ({ route, navigation }) => {
  const { nextScreen } = route.params

  const dispatch = useAppDispatch()

  const workingBulkTransferData = useAppSelector(workingBulkTransfer)

  const [addAssetClient, setAddAssetClient] = useState(undefined)  
  const [editAsset, setEditAsset] = useState(undefined)
  const [prepareAssetData, setPrepareAssetData] = useState(undefined)
  const [doneDefaulting, setDoneDefaulting] = useState(false)
  const [deselectedPensionIds, setDeselectedPensionIds] = useState([])

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const { data: me, isLoading: meIsLoading, error: meError, refetch: refetchMe } = useGetMeQuery()
  const { data: meSignature, isLoading: meSignatureIsLoading, refetch: refetchSignature } = useGetSignatureQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const refetchAll = () => {
    refetchBrands()
    refetchProviders()
    refetchMe()
    refetchAssets()
    refetchSignature()
  }
  
  const isLoading = meIsLoading || meSignatureIsLoading || assetsIsLoading || brandsIsLoading || providersIsLoading || assetsIsFetching
  const error: any = meError || assetsError || brandsError || providersError

  //Setup form
  const formObj = useForm<{
    assetIdsToTransfer: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      assetIdsToTransfer: workingBulkTransferData?.assetIdsToTransfer || []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid } } = formObj

  const usableAssets = filterAssetsInScopeForTransfer(assets, me)

  const filterReadyAssets = (assets: RetirementAssetDto[] ) => {
    return assets.filter(asset => {
      return !asset?.untransferableReason
    })
  }

  //Update the asset IDs only one time...
  useEffect(() => {
    if (!doneDefaulting) {
      //First pass - set everything ready as selected
      setValue('assetIdsToTransfer', filterReadyAssets(usableAssets).map(asset => asset.id), { shouldDirty: true })
      setDoneDefaulting(true)
    } else {
      //Subsequent passes
      const readyAssetIds = filterReadyAssets(usableAssets).map(asset => asset.id)
      const readyAndPreviouslySelected = intersection(readyAssetIds, selectedAssets)
      const otherReadyAndNotDeselected = difference(readyAssetIds, selectedAssets, deselectedPensionIds)
      const newSelected = union(readyAndPreviouslySelected, otherReadyAndNotDeselected)
      setValue('assetIdsToTransfer', newSelected, { shouldDirty: true })
    }
  }, [assets])

  const hasAssets = usableAssets && usableAssets.length
  const selectedAssets = watch('assetIdsToTransfer')

  const onSubmit = (attributes) => {
    const assetIdsToTransfer = compact(attributes.assetIdsToTransfer)

    const transferAssets = usableAssets ? usableAssets?.filter(asset => {
      return assetIdsToTransfer.includes(asset.id)
    }) : []

    const regularContributionGrossValue = sumBy(transferAssets, function(asset: RetirementAssetDto) {
      if (!asset.monthlyContributionAmount) {
        return 0
      }
      return asset.contributionSource === ContributionSource.PERSONAL
        ? asset.monthlyContributionAmount * (1 + PERSONAL_TAX_RELIEF_FACTOR)
        : asset.monthlyContributionAmount      
    })
    const totalTransferValue = sumBy(transferAssets, function(asset: RetirementAssetDto) {
      return asset.currentValue
    })

    const tracingAssets = transferAssets.filter(asset => {
      return asset.tracingRequested
    })
    const tracingCount = tracingAssets.length
    const executionAssets = transferAssets.filter(asset => {
      return !asset.tracingRequested
    })
    const executionCount = executionAssets.length
    
    dispatch(updateWorkingBulkTransfer({
      assetIdsToTransfer,
      totalTransferValue,
      tracingCount,
      tracingAssetIds: tracingAssets.map(asset => asset.id),
      executionCount,
      executionAssetIds: executionAssets.map(asset => asset.id),
      regularContributionGrossValue,
    }))

    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'transfer_submit',
      isRetransfer: false,
      outcome: 'success',
      tracingCount,
      executionCount,
    })

    navigation.navigate(nextScreen)
  }

  const assetIsOnHold = (asset: RetirementAssetDto) => {
    //On hold if...
    //1. tracing was requested but shortDuration
    //2. tracing not requested and client name mismatch
    //3. tracing not requested, missing data but has provider
    let onHold: boolean = false
    if (asset?.tracingRequested && asset?.employerShortDuration) {
      onHold = true
    }
    if (!asset?.tracingRequested && asset?.untransferableReason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH) {
      onHold = true
    }
    if (!asset?.tracingRequested && asset?.untransferableReason === RetirementAssetUntransferableReason.MISSING_DATA && asset?.pensionProviderId) {
      onHold = true
    }
    return onHold
  }

  const buildAssetsList = (): ReturnType[] => {
    let ready = []
    let onHold = []
    let transferable = []
    let untransferable = []
    if (usableAssets && me) {
      usableAssets.forEach(asset => {
        //On hold if client name mismatch OR missing data but has provider
        const assetOnHold = assetIsOnHold(asset)
        if (!asset?.untransferableReason) {
          ready.push(asset)
        } else if (assetOnHold) {
          onHold.push(asset)
        } else if (
          asset?.untransferableReason === RetirementAssetUntransferableReason.MISSING_DATA
          || asset?.untransferableReason === RetirementAssetUntransferableReason.MISSING_EXECUTION_DATA
        ) {
          transferable.push(asset)
        } else {
          untransferable.push(asset)
        }
      })
      return [
        { title: 'Ready', data: [{ id: 'ready', assets: ready}] },
        { title: 'On Hold', data: [{ id: 'onHold', assets: onHold}] },
        { title: 'Transferable', data: [{ id: 'transferable', assets: transferable}] },
        { title: 'Not Transferable', data: [{ id: 'untransferable', assets: untransferable}] },
      ]
    }
    return []
  }

  const assetsList = buildAssetsList()

  const selectAsset = (asset: RetirementAssetDto) => {
    //Remove from deselected list and add to selected array
    setDeselectedPensionIds(difference(deselectedPensionIds, [asset.id]))
    setValue('assetIdsToTransfer', union(selectedAssets, [asset.id]), { shouldDirty: true })
  }

  const deselectAsset = (asset: RetirementAssetDto) => {
    //Put in deselected list and remove from selected array
    setDeselectedPensionIds(union(deselectedPensionIds, [asset.id]))
    setValue('assetIdsToTransfer', pull(selectedAssets, asset.id), { shouldDirty: true })
  }

  const AssetSection = ({ item }) => {
    const { id, assets } = item
    const isReady = id === 'ready'

    const options: ManagedCardMultipleChoiceInputOption[] = assets.map(asset => {

      const isSelected = selectedAssets.includes(asset.id)

      const provider = providers ? providers.find(provider => {
        return provider.id === asset?.pensionProviderId
      }) : undefined

      const assetBrand = brands ? brands.find(brand => {
        return brand.key === asset?.pensionProviderBrandKey
      }) : undefined

      const providerBrand = provider && brands ? brands.find(brand => {
        return brand.key === provider.primaryBrandKey
      }) : undefined

      //Use provider brand logo, or asset logo, or dummy
      const imageSource = providerBrand?.logo
        ? { uri: providerBrand?.logo }
          : assetBrand?.logo
            ? { uri: assetBrand?.logo }
            : require('assets/icons/default_provider.png')

      const base: Partial<ManagedCardMultipleChoiceInputOption> = {
        value: isReady ? asset.id : undefined,
        title: asset.name,
        imageSource,
      }

      switch (id) {
        case 'ready':
          return {
            ...base,
            description: isSelected
              ? `Ready for ${asset.tracingRequested ? 'finding' : 'transfer'}. Tap to deselect`
              : `Tap to select for ${asset.tracingRequested ? 'finding' : 'transfer'}`,
            onPress: isSelected ? () => deselectAsset(asset) : () => selectAsset(asset),
            modalContentFunction: () => <RetirementAssetTransferModalContent
              asset={asset}
              provider={provider}
              imageSource={imageSource}
              isSelected={isSelected}
              editAssetAction={() => setPrepareAssetData({ asset, client: me})}
            />,
            modalProceedLabel: isSelected
              ? asset.tracingRequested ? `Don't Find` : `Don't Transfer`
              : asset.tracingRequested ? `Select For Finding` : `Select For Transfer`,
            modalCancelLabel: isSelected ? 'Leave selected' : 'Leave unselected',
          }
        case 'onHold':
          return {
            ...base,
            description: 'Tap to finish preparation',
            onPress: () => setPrepareAssetData({ asset, client: me}),
          }
        case 'transferable':
          return {
            ...base,
            description: 'Tap to add details for transfer',
            onPress: () => setPrepareAssetData({ asset, client: me}),
          }
        default:
          const reason = asset?.untransferableReason
          const fixableReason = clientAddressableUntransferableReasons.includes(reason)
          const fixableReasonNeedingEdit = untransferableReasonsNeedingEdit.includes(reason)
          return {
            ...base,
            description: 'Tap for more information',
            onPress: fixableReasonNeedingEdit ? () => setEditAsset(asset) : () => setPrepareAssetData(asset),
            modalContentFunction: () => <RetirementAssetUntransferableModalContent asset={asset} imageSource={imageSource} isSelected={isSelected}/>,
            modalProceedLabel: fixableReason ? fixableReasonNeedingEdit ? 'Update Details' : 'Prepare for Transfer' : 'Close',
            modalCancelLabel: fixableReason ? 'Leave as-is' : undefined,
          }
      }
    })  

    return (
      <View key={id}>
        {
        assets?.length ?
          <ManagedCardMultipleChoiceInput
            formObj={formObj}
            name={'assetIdsToTransfer'}
            options={options}
          />
          : <></>
        }
      </View>
      )
    }


  const { colors: themeColors } = Paper.useAppTheme()
  const transferCount = watch('assetIdsToTransfer').length

  const renderHeader = (section: any) => {
    return <Subheading style={Typography.defined.sectionListHeader}>{section?.title || ''} </Subheading>
  }

  const readyAssetCount = filterReadyAssets(usableAssets).length

  return (
    <>
      {
        addAssetClient ?
          <RetirementAssetsAddAssetProcessStack
            client={addAssetClient}
            onDismiss={() => setAddAssetClient(undefined)}
            forTransfer={true}
          />
          : editAsset ?
          <RetirementAssetsEditAssetModal
            asset={editAsset}
            onDismiss={() => setEditAsset(undefined)}
          />
          : prepareAssetData ?
          <RetirementAssetTransferProcessStack
            asset={prepareAssetData?.asset}
            client={prepareAssetData?.client}
            onDismiss={() => setPrepareAssetData(undefined)}
          /> :
          <ProcessScreen
            isLoading={isLoading}
            loadingMessage={['Loading your pensions...']}
            error={error}
            errorTryAgain={refetchAll}
            buttonTitle={hasAssets
              ? selectedAssets?.length ? `Proceed with ${transferCount} Pension${transferCount === 1 ? '' : 's'}` : readyAssetCount ?  `Select at least one` : `Prepare at least one`
              : `Add Pension`
            }
            buttonAction={hasAssets
              ? handleSubmit(onSubmit)
              : () => setAddAssetClient(me)
            }
            showButton={true}
            enableButton={!hasAssets || !!selectedAssets.length}
            allowTextButton={!!hasAssets}
            textButtonTitle={`Add Another Pension`}
            textButtonAction={() => setAddAssetClient(me)}
            headline={`Choose old pensions`}
            subHeading={hasAssets
              ? `Use the cards to prepare old pensions you want to find or transfer. Capital at risk.`
              : `Add old pensions to transfer them quickly and easily into your ${JAR_NAME_PERSONAL}. Capital at risk.`
            }
            subHeadingInfo={<NamedInformationButton name={NamedInformation.CONSOLIDATION_OPTIONS} buttonTitle={'What can I consolidate?'} />}
          >
            <SectionList
              showsVerticalScrollIndicator={true}
              stickySectionHeadersEnabled={false}
              sections={assetsList}
              // keyExtractor={({ id }, index) => id}
              renderSectionHeader={({ section }) => (
                section?.data[0]?.assets?.length > 0 ? renderHeader(section) : <></>
              )}
              renderItem={({ item }) => <AssetSection item={item} />}
            />
          </ProcessScreen>
      }
    </>
  )
}