import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { PublicGroupSchemeDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingPreviousPension, workingPreviousPension } from 'store/tempDataSlice'

export const SchemeEnrolmentPreviousPensionAdd_04_Name = ({ route, navigation }) => {
  const { nextScreen, groupScheme }: { nextScreen: string, groupScheme: PublicGroupSchemeDto }  = route?.params || {}

  const {
    organizationDisplayName
  } = groupScheme || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingPreviousPensionData = useAppSelector(workingPreviousPension)

  //Setup form
  const formObj = useForm<{ name: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: workingPreviousPensionData?.name || `${organizationDisplayName} ${workingPreviousPensionData?.pensionProviderBrandName} Pension`
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingPreviousPension(attributes))
    navigation.navigate(nextScreen)
  }
  
  return (
    <ModalProcessScreen
      buttonTitle={'Add Previous Pension'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Give it a memorable name`}
    >
      <ManagedTextInput
        name={'name'}
        formObj={formObj}
        // label={'Name'}
        placeholder={'Enter a name'}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ModalProcessScreen>
  )
}
