import { RetirementIncomeDto, RetirementIncomeType } from "store/dto/retirement-income.dto"
import { orderBy } from 'lodash'
import { ClientMeDto, SpouseDto } from "store/dto/client.dto"
import { ManagedSimpleChoiceItem } from "components/Inputs/ManagedSimpleChoiceInput"

export enum RetirementIncomeCategory {
  STATE, //Used for state pensions
  GUARANTEED_INCOME,
  PERSONAL,
  OTHER,
  PENSIONS, //Used for drawdown of retirement assets
}

export const getIncomeTypeOptions = (): ManagedSimpleChoiceItem[] => {
  return [
    {
      value: RetirementIncomeType.DEFINED_BENEFIT,
      label: 'Defined benefit pension ',
    },
    {
      value: RetirementIncomeType.PROPERTY,
      label: 'Rental income'
    },
    {
      value: RetirementIncomeType.EMPLOYMENT,
      label: 'Continued employment'
    },
    {
      value: RetirementIncomeType.OTHER,
      label: 'Something else'
    },
  ]
}

export const getIncomeCategory = (incomeType: RetirementIncomeType): RetirementIncomeCategory => {
  if ([RetirementIncomeType.DEFINED_BENEFIT].includes(incomeType)) {
    return RetirementIncomeCategory.GUARANTEED_INCOME
  } else if ([RetirementIncomeType.EMPLOYMENT].includes(incomeType)) {
    return RetirementIncomeCategory.PERSONAL
  }
  return RetirementIncomeCategory.OTHER
}

export const getIncomeIconName = (incomeCategory: RetirementIncomeCategory, incomeType?: RetirementIncomeType): string => {
  switch (incomeType) {
    case RetirementIncomeType.PROPERTY:
      return 'home-city'
  }
  switch (incomeCategory) {
    case RetirementIncomeCategory.STATE:
      return 'town-hall'
    case RetirementIncomeCategory.GUARANTEED_INCOME:
      return 'cash-fast'
    case RetirementIncomeCategory.PERSONAL:
      return 'account-cash'
    case RetirementIncomeCategory.PENSIONS:
      return 'piggy-bank-outline'
  }
  return 'dots-horizontal'
}

export const suggestName = (incomeType: RetirementIncomeType) => {
  switch (incomeType) {
    case RetirementIncomeType.DEFINED_BENEFIT:
      return 'Defined Benefit Pension'
    case RetirementIncomeType.PROPERTY:
      return 'Rental Income'
    case RetirementIncomeType.EMPLOYMENT:
      return 'Employment'
    case RetirementIncomeType.OTHER:
      return 'Other Income'
    default:
      return ''
  }
}

export const sortIncomes = (incomes : RetirementIncomeDto[]): RetirementIncomeDto[] => {
  const typeOrder = Object.values(RetirementIncomeType)
  return orderBy(incomes, [function(i) { return typeOrder.indexOf(i.incomeType) }, 'annualIncomeAmount'], ['asc', 'desc'])
}

export const filterEditableIncomes = (incomes: RetirementIncomeDto[], client: ClientMeDto, spouse: SpouseDto) => {
  const spouseIsUser = !!spouse?.userId
  return incomes && client ? incomes.filter(income => {
    //Don't include if spouse is real and income doesn't belong to client
    if (spouseIsUser && income?.clientId !== client?.id) {
      return false
    }

    return true
  }) : []
}
