import { AutoCompleteItem } from 'components/Inputs/ManagedAutoCompleteInput'
import { startCase, lowerCase, intersection } from 'lodash'

export const enumToAutocompleteOptions = (enumType, disabledOptions?: any[], filterOptions?: any[]): AutoCompleteItem[] => {
  const allKeys = Object.keys(enumType)
  return allKeys.map((key) => ({
    value: enumType[key],
    label: startCase(lowerCase(enumType[key])),
    disabled: disabledOptions ? disabledOptions.includes(enumType[key]) : false,
  })).filter(option => {
    return !filterOptions || filterOptions.includes(option.label)
  })
}

export const enumToArray = (enumType): string[] => {
  return Object.keys(enumType).map(key => key)
}