import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { useGetMeQuery, useGetRetirementAssetsQuery, useGetSpouseQuery } from 'store/apiSlice'

export const RetirementAssetSetupIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const isLoading = clientIsLoading || spouseIsLoading || assetsIsLoading
  const error: any = clientError || assetsError

  const hasLinkedSpouse = spouse && spouse?.userId
  const hasOwnAssets = client && assets ? assets.filter(asset => { return asset?.clientId === client?.id}).length : false
      
  const next = () => {
    rootNavigate('RetirementAssetSetupProcessStack', route?.params)
  }
  
  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={clientError ? refetchClient : refetchAssets}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      cancelButtonText={fromOnboarding ? 'Back to Tasks' : 'Back To Dashboard'}
      illustrationFilename={'capture_old_pensions.png'}
      headline={hasLinkedSpouse && hasOwnAssets ? `Review old pensions and assets` : `Capture all your old pensions in one place`}
      subHeading={`See your complete future, taking into account all of your retirement planning`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.OTHER_PENSIONS} />}
    >
      {
        hasLinkedSpouse
          ? hasOwnAssets
            ? <Paragraph>{`${spouse?.firstName} has already added some on your behalf, but it's best to quickly review to check they are accurate`}</Paragraph>
            : <Paragraph>{`${spouse?.firstName} has already added their old pensions and assets, but you may have more you wish to add`}</Paragraph>
            : <></>
      }

    </ProcessIntroScreen>
  )
}
