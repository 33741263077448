import { differenceInYears } from 'date-fns'
import { ANALYTICS_KEY_NAME } from 'lib/constants'
import { formatISODateOrUndefined } from 'lib/dateHelpers'
import { getJsonItemFromStore, setJsonItemInStore } from 'lib/localStoreHelpers'
import { Logger } from 'lib/logger'
import { filterAssetsInScopeForTransfer } from 'lib/retirementAssetHelpers'
import { isEmpty, isEqual, isNil, mapValues, pick, pickBy, sumBy } from 'lodash'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { IdentifyProperties, ampli } from 'src/ampli'
import { useGetMeQuery, useGetUserAccountsQuery, useGetRetirementAssetsQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { AmlStatus } from 'store/dto/client.dto'
import { UserDto } from 'store/dto/user.dto'
import * as Application from 'expo-application'
import { platformIsAndroid, platformIsIos } from 'lib/platformHelpers'
import * as Updates from 'expo-updates'
import { AccountType } from 'store/dto/account.dto'
import { getGroupPension, getPersonalPension } from 'lib/accountHelpers'

export type AnalyticsContextType = {
  userAttributes: IdentifyProperties
}

const BASE_CONTEXT: AnalyticsContextType = {
  userAttributes: {}
}

export const AnalyticsContext = createContext<AnalyticsContextType>(BASE_CONTEXT)

export const AnalyticsProvider = (props: { user: UserDto, ready: boolean, children: ReactNode }) => {
  const { user, ready, children } = props

  const isAndroid = platformIsAndroid()
  const isIos = platformIsIos()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [userAttributes, setUserAttributes] = useState<IdentifyProperties>({})

  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery(undefined, { skip: !(ready && user && loaded) })

  const skip = !(user && loaded && client)

  const { data: spouse, isLoading: spouseIsLoading, error: spouseError } = useGetSpouseQuery(undefined, { skip })
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading } = useGetUserAccountsQuery(undefined, { skip })
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading } = useGetRetirementProfileQuery(undefined, { skip })
  const { data: assets, isLoading: assetsIsLoading, error: assetsError } = useGetRetirementAssetsQuery(undefined, { skip })

  //Populate last-known properties from secure store on load
  useEffect(() => {
    const loadData = async () => {
      const storedData = await getJsonItemFromStore(ANALYTICS_KEY_NAME)
      if (storedData) {
        Logger.debug(`Loaded analytics data from store`, storedData)
        setUserAttributes(storedData)
      } else {
        Logger.debug(`No previous analytics data in store`)
      }
      setLoaded(true)
    }
    loadData()
  },[])

  const updateUserAttributes = async (patch: Partial<IdentifyProperties>): Promise<void> => {
    if (!user) { return }

    const flattenedPatch = mapValues(patch, function(val) {
      return isNil(val) ? "-" : val
    })

    const merged = {
      ...userAttributes,
      ...flattenedPatch,
    }
    const changed = pickBy(merged, (v, k) => !isEqual(userAttributes[k], v))
    setUserAttributes(merged)
    await setJsonItemInStore(ANALYTICS_KEY_NAME, merged)
    publishAnalyticsChanges(changed)
  }

  const publishAnalyticsChanges = async (changes: IdentifyProperties): Promise<void> => {
    if (!isEmpty(changes)) {
      Logger.debug('Pushing analytics changes', changes)
      ampli.identify(user.id, changes)
    }
  }

  //Handle client changes
  useEffect(() => {
    if (client) {
      const age = differenceInYears(new Date(), new Date(client.birthDate))
      updateUserAttributes({
        clientProfileCreatedDate: formatISODateOrUndefined(client?.createdAt),
        clientIsVerified: client.amlStatus === AmlStatus.PASS,
        age,
        affiliateId: client?.affiliate?.id,
        affiliateRef: client?.affiliate?.ref,
        affiliateName: client?.affiliate?.name,
        segmentName: client?.segment?.name || null,
        segmentBadgeName: client?.segment?.badgeName || null,
        preferredOnboardingPhase: client?.preferredOnboardingPhase,
        hasCompletedBeneficiarySetup: !!client?.onboardingFlags?.beneficiaries,
        hasCompletedIncomeSetup: !!client?.onboardingFlags?.incomes,
        hasCompletedAssetSetup: !!client?.onboardingFlags?.assets,
        hasCompletedSpouseSetup: !!client?.onboardingFlags?.addspouse,
        hasCompletedConsolidationSetup: !!client?.onboardingFlags?.consolidate,
        hasCompletedContributionSetup: !!client?.onboardingFlags?.contribute,
        pensionContributionSource: client?.contributionConfiguration?.source,
        birthDate: formatISODateOrUndefined(client?.birthDate),
        lastSurvivorDeathDate: formatISODateOrUndefined(client?.lastSurvivorDeathDate),
        statePensionDate: formatISODateOrUndefined(client?.statePensionDate),
        ...pick(client, [
          'email',
          'title',
          'firstName',
          'surname',
          'gender',
          'employmentStatus',
          'maritalStatus',
          'lastSurvivorDeathAge',
          'statePensionAge',
          'statePensionAmount',
        ]),
        iosBuildNumber: isIos ? Application.nativeBuildVersion : undefined,
        iosOtaRevision: isIos ? Updates?.updateId : undefined,
        iosOtaDate: isIos ? Updates?.createdAt : undefined,
        androidBuildNumber: isAndroid ? Application.nativeBuildVersion : undefined,
        androidOtaRevision: isAndroid ? Updates?.updateId : undefined,
        androidOtaDate: isAndroid ? Updates?.createdAt : undefined,
      })
    }
  }, [client])

  //Handle spouse changes
  useEffect(() => {
    if (spouse || spouse === null) {
      updateUserAttributes({
        hasSpouse: !!spouse,
        spouseIsConnected: !!spouse?.userId,
        spouseBirthDate: formatISODateOrUndefined(spouse?.birthDate),
        spouseTitle: spouse?.title,
        spouseFirstName: spouse?.firstName,
        spouseSurname: spouse?.surname,
        spouseGender: spouse?.gender,
        spouseStatePensionAmount: spouse?.statePensionAmount,
      })
    }
  }, [spouse])
  
  //Handle retirement profile changes
  useEffect(() => {
    if (retirementProfile) {
      const age = differenceInYears(new Date(), new Date(client.birthDate))
      updateUserAttributes({
        retirementBudgetLastUpdatedDate: formatISODateOrUndefined(retirementProfile?.budgetDate),
        targetRetirementAge: retirementProfile?.targetRetirementAge,
        targetRetirementDate: formatISODateOrUndefined(retirementProfile?.targetRetirementDate),
        targetRetirementYearsUntil: retirementProfile?.targetRetirementAge - age,
        targetRetirementYearsAfter: client.lastSurvivorDeathAge - retirementProfile?.targetRetirementAge,
        retirementBudgetAsCouple: retirementProfile?.asCouple,
        retirementBudgetInsideLondon: retirementProfile?.insideLondon,
        retirementBudgetBaseLevel: retirementProfile?.baseBudgetLevel,
        retirementBudgetNetTotalAmount: retirementProfile?.expensesNetTotalAmount,
        retirementBudgetGrossTotalAmount: retirementProfile?.expensesGrossTotalAmount,
        retirementProfileCreatedDate: formatISODateOrUndefined(retirementProfile?.createdAt),
      })
    }
  }, [retirementProfile])

  //Handle accounts changes
  useEffect(() => {
    if (accounts) {
      const personalPension = getPersonalPension(accounts)
      const groupPension = getGroupPension(accounts)

      const pensionPlanName = personalPension?.investmentPlan?.name || personalPension?.groupPortfolio?.name || 'Custom'

      updateUserAttributes({
        pensionCreatedDate: formatISODateOrUndefined(personalPension?.createdAt),
        pensionInvestmentStrategy: personalPension?.investmentPlanId ? 'Plan'
          : personalPension?.groupPortfolioId ? 'Group Portfolio'
          : 'Custom',
        pensionPlanName,
        pensionValuationDate: formatISODateOrUndefined(personalPension?.valuationDate),
        pensionCurrentValue: personalPension?.currentValue,
        pensionContributionTotalAmount: personalPension?.valueReport?.contributionsTotal,
        pensionContributionLastDate: formatISODateOrUndefined(personalPension?.valueReport?.lastContributionDate),
        pensionTransferInTotalAmount: personalPension?.valueReport?.transfersInTotal,
        pensionTransferInLastDate: formatISODateOrUndefined(personalPension?.valueReport?.lastTransferInDate),
        pensionMonthlyContributionAmount: personalPension?.regularContribution?.amount || 0,
        workplacePensionCreatedDate: formatISODateOrUndefined(groupPension?.createdAt),
        workplacePensionInvestmentStrategy: groupPension?.usePersonalPensionInvestmentChoice ? 'Follow Personal'
          : groupPension?.investmentPlanId ? 'Plan'
          : groupPension?.groupPortfolioId ? 'Group Portfolio'
          : 'Custom',
        workplacePensionPlanName: groupPension?.usePersonalPensionInvestmentChoice ? pensionPlanName : groupPension?.investmentPlan?.name || groupPension?.groupPortfolio?.name || 'Custom',
        workplacePensionValuationDate: formatISODateOrUndefined(groupPension?.valuationDate),
        workplacePensionCurrentValue: groupPension?.currentValue,
        workplacePensionContributionTotalAmount: groupPension?.valueReport?.contributionsTotal,
        workplacePensionContributionLastDate: formatISODateOrUndefined(groupPension?.valueReport?.lastContributionDate),
      })
    }
  }, [accounts])

  //Handle retirement asset changes
  useEffect(() => {
    if (assets) {
      const transferableAssets = filterAssetsInScopeForTransfer(assets, client)
      const transferablePensionCount = transferableAssets.length
      const transferablePensionValue = sumBy(transferableAssets, 'currentValue')
      const transferablePensionMonthlyContributionAmount = sumBy(transferableAssets, 'monthlyContributionAmount')
      updateUserAttributes({
        transferablePensionCount,
        transferablePensionValue,
        transferablePensionMonthlyContributionAmount: transferablePensionMonthlyContributionAmount || 0,
      })
    }
  }, [assets])

  //Function to check if all entities have finished loading
  //TODO - should this be being used? At present, do the updates go in multiple
  //parts, one for each useEffect? Is that ok, does it matter?
  const allEntitiesLoaded = () => {
    const result = (!!client || !!clientError)
      && (spouse || spouse === null || !!spouseError) //Null to account for when server returns 204 for no spouse
      && (!!accounts || !!accountsError)
      && (!!retirementProfile || !!rpError)
      && (!!assets)
    return result
  }

  return (
    <AnalyticsContext.Provider value={{
      userAttributes,
    }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalyticsContext = () => useContext(AnalyticsContext)
