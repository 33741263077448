import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { ContentDivider } from "components/Layout/ContentDivider"
import { Paragraph, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import { platformIsWeb } from "lib/platformHelpers"
import React, { useCallback, useEffect, useState } from 'react'
import { Image, ScrollView, View } from "react-native"
import { GroupSchemeStatus, UserGroupSchemeDto } from "store/dto/group-scheme.dto"
import { useAppDispatch } from "store/hooks"
import { setSnackbarData } from "store/uxSlice"
import { Colors, Flex, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { DetailedListItem } from "./DetailedListItem"
import { OptionalSectionList } from "./OptionalSectionList"
import { ProgressiveImage } from "./ProgressiveImage"
import { AutoCompleteItem, ManagedAutoCompleteInput } from "components/Inputs/ManagedAutoCompleteInput"
import { useForm } from "react-hook-form"
import { useLazyGetMeGroupSchemesQuery } from "store/apiSlice"
import { Button } from "./Button"
import { Logger } from "lib/logger"

type EmployerSchemeInfoSwitchModalProps = {
  currentScheme?: UserGroupSchemeDto
  otherSchemes?: UserGroupSchemeDto[]
  visible: boolean
  onDismiss: any
  changeSchemeFunction: Function
  showSchemeSearch?: boolean
}

export const EmployerSchemeInfoSwitchModal = (props: EmployerSchemeInfoSwitchModalProps) => {
  const { visible, onDismiss, currentScheme, otherSchemes, changeSchemeFunction, showSchemeSearch } = props

  const dispatch = useAppDispatch()

  const hasOtherSchemes = otherSchemes?.length > 0

  const { colors: themeColors } = Paper.useAppTheme()

  const { name: schemeName, organizationDetails, userSchemeRole, employerPensionSchemeReference } = currentScheme || {}
  const { logoPath, displayName } = organizationDetails || {}

  const handleSchemeSwitch = (groupScheme: UserGroupSchemeDto) => {
    dispatch(setSnackbarData({
      message: `You have switched scheme`,
      subMessage: `You are now working in scheme '${groupScheme.name}' for organisation '${displayName}'`,
      iconName: 'alert-circle-outline',
      duration: 5000,
    }))
    changeSchemeFunction(groupScheme)
    onDismiss()
  }

  const otherSchemeList = () => {
    let schemes = []
    if (otherSchemes?.length) {
      otherSchemes.map((scheme, id) => {
        schemes.push(scheme)
      })
      return [
        { title: 'Switch Scheme', data: schemes },
      ]
    }
    return []
  }

  //Setup form
  const formObj = useForm<{ groupSchemeId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, watch, setValue, formState: { isValid} } = formObj
  
  const [ getSchemes, { data: schemes, error: schemesError, isLoading: schemesIsLoading, isFetching: schemesIsFetching }] = useLazyGetMeGroupSchemesQuery()
  const [searchTerm, setSearchTerm] = useState<string>(undefined)
  const [schemeOptions, setSchemeOptions] = useState<AutoCompleteItem[]>([])

  //Trigger getSchemes...
  const getSuggestions = useCallback(async q => {
    setSearchTerm(q)
    const search = q.toLowerCase()
    if (typeof search !== 'string' || search.length < 3) {
      setSchemeOptions([])
      return
    }
    getSchemes({ search }) //Get schemes, preferring cache
  }, [])

    //Update schemeOptions when schemes changes
    useEffect(() => {
      let options: AutoCompleteItem[] = []
      if (schemes) {
        options = schemes.map(scheme => ({
          value: scheme.id,
          label: scheme.name,
          description: scheme?.organizationDetails?.displayName,
          icon: () => <Image source={{ uri: scheme?.organizationDetails?.logoPath}} style={{
              width: Sizing.x40,
              height: Sizing.x40,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
        }))
      }
      setSchemeOptions(options)
    }, [schemes])

  const onSubmit = attributes => {
    const { groupSchemeId } = attributes
    const selectedScheme = schemes.find(scheme => {
      return scheme.id === groupSchemeId
    })
    changeSchemeFunction(selectedScheme)
    onDismiss()
  }
  
  const renderItem = ({ item }) => {
    const { name: schemeName, status, organizationDetails, employerPensionSchemeReference } = item as UserGroupSchemeDto
    const { logoPath, displayName, companyNo } = organizationDetails || {}
    
    const subTitle = `${displayName} / ${companyNo}`

    return (
      <DetailedListItem
        icon={logoPath
          ? <ProgressiveImage
              source={{ uri: logoPath }}
              style={{
                width: Sizing.x25,
                height: Sizing.x25,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
          : <MaterialCommunityIcons name={'dots-horizontal'} size={Sizing.x25} color={themeColors.primary} />
        }
        title={schemeName}
        titleStyle={{ color: themeColors.primary }}
        titleRight={status}
        titleRightStyle={{ color: themeColors.primary }}
        subTitle={subTitle}
        subTitleStyle={{ color: Colors.neutral.black }}
        subTitleRight={employerPensionSchemeReference}
        subTitleRightStyle={{ color: Colors.neutral.black }}
        // subTitleStyle={{ color: themeColors.primary }}
        onPress={() => handleSchemeSwitch(item)}
      />
    )
  }
  

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            {
              currentScheme
                ? <>
                    <Subheading style={{ color: themeColors.primary }}>{`Current Scheme`}</Subheading>
                    <View>
                      <View style={{
                        paddingVertical: Sizing.x20,
                      }}>
                        <ProgressiveImage
                          source={{ uri: logoPath}}
                          style={{
                            width: Sizing.x100,
                            height: Sizing.x100,
                            resizeMode: 'contain',
                            alignSelf: 'center',
                          }} />
                          <Subheading>{displayName}</Subheading>
                      </View>
                    </View>
                    <Paragraph>{`Scheme Name: ${schemeName}`}</Paragraph>
                    <Paragraph>{`Employer Pension Scheme Reference: ${employerPensionSchemeReference}`}</Paragraph>
                    <Paragraph>{`Your Role: ${userSchemeRole}`}</Paragraph>
                  </>
                : <></>
            }
            {
              hasOtherSchemes
                ? <>
                    <ContentDivider />
                    <OptionalSectionList
                      sections={otherSchemeList()}
                      renderItem={renderItem}
                      noDataMessage={`No other schemes available`}
                      noRefresh={true}
                    />
                  </>
                : <></>
            }
            {
              showSchemeSearch
                ? <>
                    <ContentDivider />
                    <Subheading style={{ color: themeColors.primary }}>{`Scheme Search`}</Subheading>                    
                    <Paragraph>{`To switch to a different scheme, search below`}</Paragraph>
                    <ManagedAutoCompleteInput
                      name={'groupSchemeId'}
                      placeholder={'Tap to search...'}
                      searchPlaceholder={'Enter 3 or more characters...'}
                      formObj={formObj}
                      modalTitle={'Find Scheme'}
                      required={true}
                      dataSet={schemeOptions}
                      dataTriggerFunction={getSuggestions}
                      isLoading={schemesIsLoading || schemesIsFetching}
                      minSearchTermLength={3}
                      searchTermRequiredMessage='Tap above to search for a scheme'
                      noResultsMessage={'No matching schemes found'}
                    />
                    <Button onPress={handleSubmit(onSubmit)} disabled={!isValid || schemesIsLoading || schemesIsFetching}>{'Switch Scheme'}</Button>
                  </>
                : <></>
            }
          </ScrollView>
          <FooterButton mode={'text'} onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}
