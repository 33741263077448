import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { scaleNormalizer } from 'lib/scaleHelpers'
import React, { ComponentProps, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files"
import { View } from 'react-native'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { Button } from './Button'
import { Loading } from './Loading'
import { ExternalLinkButton } from './ExternalLinkButton'

export enum FileUploadStatus {
  IDLE = 'IDLE',
  READING = 'READING',
  ERROR_READ = 'ERROR_READ',
  ERROR_UPLOAD = 'ERROR_UPLOAD',
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE',
}

/**
 * FileUploader props
    name,
    hoverTitle,
    types,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    onTypeError,
    disabled,
    label,
    multiple,
    required,
    onDraggingStateChange
    dropMessageStyle
 */
type FileUploaderProps = ComponentProps<typeof FileUploader> & {
  title?: string
  iconName?: string
  dragDropMessage?: string
  clickMessage?: string
  additionalInformation?: string
  submitHandler: Function
  handleRemove: Function
  fileUploadStatus: FileUploadStatus
  color: string
}

export const FileUploadArea = (props: FileUploaderProps) => {
  const { title, color, iconName, fileUploadStatus, dragDropMessage, clickMessage, additionalInformation, fileOrFiles, dropMessageStyle, submitHandler, handleRemove } = props

  //Clear a completed file after 10 seconds
  useEffect(() => {
    if (fileUploadStatus === FileUploadStatus.COMPLETE) {
      setTimeout(() => handleRemove(), 10000)
    }
  }, [fileUploadStatus])

  const { colors: themeColors } = Paper.useAppTheme()

  const selectedFile = fileOrFiles

  const dropMessageStyleToUse = dropMessageStyle ? dropMessageStyle : {
    fontFamily: 'LabGrotesque-Light',
    fontSize: Sizing.x30,
    zIndex: 1000,
  }

  const disabledUploadStatuses: FileUploadStatus[] = [
    FileUploadStatus.IDLE,
    FileUploadStatus.ERROR_READ,
    FileUploadStatus.ERROR_UPLOAD,
  ]

  const mainColor =
    fileUploadStatus === FileUploadStatus.COMPLETE ? Colors.brand.green2
    : fileUploadStatus === FileUploadStatus.ERROR_READ ? Colors.warning.s400
    : fileUploadStatus === FileUploadStatus.ERROR_UPLOAD ? Colors.warning.s400
    : color ? color
    : themeColors.primary

  return (
    <View style={{
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}>
      <FileUploader
        {...props}
        disabled={!!selectedFile && disabledUploadStatuses.includes(fileUploadStatus)}
        dropMessageStyle={dropMessageStyleToUse}
      >
        <View style={{
          minHeight: Sizing.x300,
          width: '100%',
          ...Flex.column.center,
          borderRadius: Sizing.x10,
          borderStyle: 'dashed',
          borderColor: Colors.neutral.s400,
          borderWidth: Sizing.x2,
          padding: Sizing.x10,
        }}>
          {
            fileUploadStatus === FileUploadStatus.READING
            ? <Loading message={['Reading file...']} size={Sizing.x100}/>
            : fileUploadStatus === FileUploadStatus.UPLOADING
            ? <Loading message={['Uploading file...']} size={Sizing.x100}/>
            :
              <>
                <Headline style={{
                  color: mainColor
                }}>{title ? title : 'Upload File'}</Headline>
                <MaterialCommunityIcons
                  name={
                    fileUploadStatus === FileUploadStatus.COMPLETE ? 'file-check-outline'
                    : fileUploadStatus === FileUploadStatus.ERROR_READ ? 'file-hidden'
                    : fileUploadStatus === FileUploadStatus.ERROR_UPLOAD ? 'file-alert-outline'
                    : iconName ? iconName
                    : 'file-document-outline'}
                  size={Sizing.x100}
                  color={mainColor}
                  style={{
                    alignSelf: 'center',
                  }}
                />
                <Subheading style={{
                  fontWeight: selectedFile && fileUploadStatus !== FileUploadStatus.IDLE ? 'bold' : undefined,
                }}>{
                    fileUploadStatus === FileUploadStatus.COMPLETE ? 'Successfully Uploaded!'
                    : fileUploadStatus === FileUploadStatus.ERROR_READ ? 'Could not read file!'
                    : fileUploadStatus === FileUploadStatus.ERROR_UPLOAD ? `Error uploading file!`
                    : selectedFile
                      ? selectedFile?.name || `File added!`
                      : dragDropMessage
                        ? dragDropMessage
                        : 'Drag and drop a file, or click to select'
                  }</Subheading>
                <View style={{
                  paddingTop: Sizing.x5,
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                  {
                    fileUploadStatus === FileUploadStatus.COMPLETE
                      ? <>
                          <Paragraph style={{
                            textDecorationLine: 'underline'
                          }}>
                            {'Drag another file or click to browse'}
                          </Paragraph>
                          {
                            additionalInformation
                              ? <Paragraph style={{
                                  fontSize: scaleNormalizer(12)
                                }}>
                                  {additionalInformation}
                                </Paragraph> 
                              : <></>
                          }
                        </>
                    : selectedFile || fileUploadStatus === FileUploadStatus.ERROR_READ || fileUploadStatus === FileUploadStatus.ERROR_UPLOAD
                      ? <>
                          <Button
                            mode={'contained'}
                            onPress={submitHandler}
                            backgroundColor={mainColor}
                          >
                            {
                              fileUploadStatus === FileUploadStatus.ERROR_READ || fileUploadStatus === FileUploadStatus.ERROR_UPLOAD
                              ? 'Try Again'
                              : 'Upload Now'
                            }
                          </Button>
                          <Button
                            mode={'text'}
                            onPress={handleRemove}
                          >
                            {'Change File'}
                          </Button>
                        </>
                      : <>
                          <Paragraph style={{
                            textDecorationLine: 'underline'
                          }}>
                            {clickMessage ? clickMessage : 'Or browse for a file'}
                          </Paragraph>
                          {
                            additionalInformation
                              ? <Paragraph style={{
                                  fontSize: scaleNormalizer(12)
                                }}>
                                  {additionalInformation}
                                </Paragraph> 
                              : <></>
                          }
                        </>
                  }
                </View>
              </>
          }
        </View>
      </FileUploader>
    </View>
  )
}
