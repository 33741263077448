import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'

export const InvestmentAssetAdd_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss } = route?.params || {}

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'control_your_financial_future.png'}
      headline={'Select an investment fund'}
      subHeading={`Let's find a fund in which to invest`}
    >
    </ModalProcessIntroScreen>
  )
}
