import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { differenceInYears } from 'date-fns'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetRetirementProfileQuery } from 'store/apiSlice'
import { useAppSelector } from 'store/hooks'
import { workingIncome } from 'store/tempDataSlice'

export const RetirementIncomesAddIncome_03_DateChoice = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}

  const workingIncomeData = useAppSelector(workingIncome)

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const error: any = rpError || meError
  const isLoading = rpIsLoading || meIsLoading

  const incomeStartAgeRef = useRef()

  //Setup form
  const formObj = useForm<{ incomeStartAgeChoice: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      incomeStartAgeChoice: workingIncomeData?.incomeStartAgeChoice
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    //Skip contribution screen if same date
    navigation.navigate(attributes.incomeStartAgeChoice ? 'Name' : nextScreen)
  }

  const targetMeAge = retirementProfile?.targetRetirementAge || me.statePensionAge
  //Age to show
  const ageDifference = client?.isSpouse ? differenceInYears(new Date(me.birthDate), new Date(client?.birthDate)) : 0
  const relevantAge = targetMeAge + ageDifference

  const incomeStartAgeOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes`,
    },
    {
      value: false,
      label: `No, at a specfic age`
    }
  ]

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={meError ? refetchMe : refetchRp}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Is the income available at retirement?`}
      subHeading={`Will ${client?.isSpouse ? client?.firstName : `you`} already be receving it at the point ${client?.isSpouse ? `they` : `you`} retire, whenever that may be?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_INCOME_START_DATE}/>}
    >      
      <ManagedSimpleChoiceInput
        name={'incomeStartAgeChoice'}
        formObj={formObj}
        options={incomeStartAgeOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
