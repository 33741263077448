import { GROSS_TOTAL_BUDGET_ID, NET_TOTAL_BUDGET_ID } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { concat, startCase } from 'lodash'
import React from 'react'
import { Image } from 'react-native'
import { BudgetDto } from 'store/dto/reference-data.dto'
import { RetirementProfileCategoryBudget } from 'store/dto/retirement-profile.dto'
import { Sizing } from 'styles'
import { UnborderedTable, UnborderedTableRow } from './UnborderedTable'

export type BudgetTableProps = {
  error?: any
  isLoading?: boolean
  isFetching?: boolean
  expenses: RetirementProfileCategoryBudget[]
  rows: BudgetTableRowProps[]
  netMonthlyTotal: number
  showLevels?: boolean
}

export type BudgetTableRowProps = {
  budget: BudgetDto
  onPress?: any
}

export type WrappedRetirementProfileCategoryBudget = {
  id?: string,
  data: RetirementProfileCategoryBudget
}

export const BudgetTable = (props: BudgetTableProps) => {
  const { rows, expenses, netMonthlyTotal, error, isLoading, isFetching, showLevels } = props
  const filteredRows = rows ? rows.filter(row => {
    return row.budget?.id !== NET_TOTAL_BUDGET_ID && row.budget?.id !== GROSS_TOTAL_BUDGET_ID
  }) : []

  const getCategoryImageSource = (id: number): any => {
    switch (id) {
      case 2:
        return require('assets/icons/budget/category_house.png')
      case 3:
        return require('assets/icons/budget/category_food.png')
      case 4:
        return require('assets/icons/budget/category_transport.png')
      case 5:
        return require('assets/icons/budget/category_holidays.png')
      case 6:
        return require('assets/icons/budget/category_clothing.png')
      case 7:
        return require('assets/icons/budget/category_helping.png')
    }
  }  

  let tableData: UnborderedTableRow[] = []

  tableData.push({
    label: 'Category',
    value: showLevels ? 'Budget Level' : 'Monthly Budget',
    isHeader: true,
  })

  tableData = concat(tableData, filteredRows.map((row, idx) => {
    const expense = expenses ? expenses.find(e => {
      return row.budget?.id === e.id
    }) : undefined
    const iconSource = <Image
      source={getCategoryImageSource(row.budget?.id)}
      style={{
        width: Sizing.x20,
        height: Sizing.x20,
        resizeMode: 'contain',
        alignSelf: 'center',
    }}/>

    return {
      label: row.budget?.name,
      value: showLevels
        ? startCase(expense?.selectedLevel || 'custom')
        : formatCurrencyAmount(expense ? expense.selectedCost / 12 : 0),
      valueEditIcon: !!row.onPress,
      iconSource,
      isTotal: false,
      linkFunction: row.onPress,
    }
  }))

  //Add total rows
  if (!showLevels) {
    tableData.push({
      label: 'Monthly Total',
      value: formatCurrencyAmount(netMonthlyTotal),
      isTotal: true,
    })
    tableData.push({
      label: 'Annually',
      value: formatCurrencyAmount(netMonthlyTotal * 12),
      isTotal: true,
    })
  }

  return (
    <UnborderedTable
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      data={tableData}
    />
  )
}
