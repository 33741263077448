import { MaterialCommunityIcons } from '@expo/vector-icons'
import { JarvisIcon } from 'components/Brand/JarvisIcon'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Portal, ThemeProvider } from 'react-native-paper'
import { Colors, Paper, Sizing, Typography } from 'styles'
import { WebAppDownloadModal } from './WebAppDownloadModal'
import { Button } from './Button'
import { scaleNormalizer } from 'lib/scaleHelpers'

export type FeatureBoxProps = {
  noTitle?: boolean
  data: FeatureBoxRow[]
}

export type FeatureBoxRow = {
  label: string
}

const isWeb = platformIsWeb()

const FeatureContent = (props: FeatureBoxProps) => {
  const { data, noTitle } = props
  const { colors: themeColors } = Paper.useAppTheme()

  const [showDownloadModal, setShowDownloadModal] = useState(null)

  return (
    <View style={[
      localStyles.container,
    ]}>
      <View style={localStyles.tableContainer}>
        {
          noTitle === true ? <></> :
            <View style={localStyles.headerContainer}>
              <Text style={{
                ...localStyles.tableHeader,
                color: isWeb ? themeColors.primary : themeColors.secondary,
              }}>{`With the full Jarvis App`}</Text>
            </View>
        }

        <View
          style={localStyles.dataContainer}>
          {
            data.map((row, idx) => {
              return (
                <View key={idx} style={localStyles.itemContainer}>
                  <View style={localStyles.iconContainer}>
                      <MaterialCommunityIcons name="check-circle-outline" size={Sizing.x20} color={Colors.brand.green2} />
                  </View>
                  <View style={localStyles.labelContainer}>
                    <Paragraph style={{
                      ...localStyles.tableItemLabel,
                      color: isWeb ? themeColors.primary : themeColors.secondary,
                    }}>{row.label}</Paragraph>
                  </View>
                </View>
              )
            })
          }
        </View>
      </View>
      <Portal>
        <WebAppDownloadModal
          visible={showDownloadModal}
          onDismiss={() => setShowDownloadModal(false)}
        />
      </Portal>
    </View>
  )
}

export const FeatureBox = (props: FeatureBoxProps) => {

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightGrey : Paper.darkTheme}>
      <FeatureContent {...props} />
    </ThemeProvider>
  )
}

const localStyles = StyleSheet.create({
  container: {
    // marginVertical: Sizing.x20,
    // paddingHorizontal: Sizing.x20,
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: scaleNormalizer(800)
  },
  tableContainer: {
    alignSelf: 'center',
  },
  dataContainer: {
    flexDirection: 'column',
    paddingHorizontal: Sizing.x10,
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    paddingTop: Sizing.x5,
    alignSelf: 'center',
  },
  headerContainer: {
    paddingVertical: Sizing.x5,
    alignSelf: 'center',
    paddingHorizontal: Sizing.x10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tableHeader: {
    paddingLeft: Sizing.x10,
    ...Typography.fontSize.x15,
    fontWeight: '400',
  },
  itemContainer: {
    // paddingBottom: Sizing.x5,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',   
  },
  labelContainer: {
    textAlign: 'left',
    alignSelf: 'center',
    paddingTop: Sizing.x2,
    flex: 1,
  },
  iconContainer: {
    paddingLeft: Sizing.x0,
    paddingRight: Sizing.x5,
    alignSelf: 'center',
  },
  tableItemLabel: {
    ...Typography.fontSize.x15,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'left'
  },
})