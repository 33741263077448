import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { addDays, format, formatISO, isBefore, startOfToday } from 'date-fns'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import { getPushNotificationToken, isPushNotificationsEnabled } from 'lib/notificationHelpers'
import { goToLogout } from 'lib/RootNavigation'
import { default as React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { ampli } from 'src/ampli'
import { useAddTokenForCurrentUserMutation, useDeleteCurrentUserSupportAccessMutation, useDeleteMeMutation, useGetCurrentUserQuery, useGetCurrentUserSupportAccessQuery, useGetStatusQuery, useUpdateCurrentUserMutation, useUpdateCurrentUserSupportAccessMutation } from 'store/apiSlice'
import { setPushToken } from 'store/authSlice'
import { NotificationMethod } from 'store/dto/notification.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editMeVisible, setEditCurrentUserVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'

const SUPPORT_ACCESS_DAYS = 7

export const UserEditModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditCurrentUserVisible(false))

  const onDismiss = globalDismiss
  
  const [pushEnabled, setPushEnabled] = useState(true)

  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery()
  const { data: status, isLoading: statusIsLoading, refetch: refetchStatus } = useGetStatusQuery()
  const { isSuccess: supportAccessIsSuccess, data: supportAccess, error: userSupportAccessError, isLoading: userSupportAccessIsLoading, refetch: refetchUserSupportAccess } = useGetCurrentUserSupportAccessQuery()
  const [updateCurrentUser, { data: updated, isLoading: updateIsLoading, error: updateError, reset: updateReset } ] = useUpdateCurrentUserMutation()
  const [addPushToken] = useAddTokenForCurrentUserMutation()
  const [updateCurrentUserSupportAccess, { data: updatedSupportAccess, isLoading: updateSupportAccessIsLoading, error: updateSupportAccessError }] = useUpdateCurrentUserSupportAccessMutation()
  const [deleteCurrentUserSupportAccess, { data: deletedSupportAccess, isLoading: deleteSupportAccessIsLoading, error: deleteSupportAccessError }] = useDeleteCurrentUserSupportAccessMutation()
  const [deleteClient] = useDeleteMeMutation()

  const supportAccessGranted = supportAccessIsSuccess && supportAccess?.supportAccessUntilDate && isBefore(new Date, new Date(supportAccess?.supportAccessUntilDate))

  const defaultMethods = currentUser?.preferredNotificationMethods || [NotificationMethod.EMAIL, NotificationMethod.PUSH]

  //Setup form
  const formObj = useForm<{
    preferredNotificationMethods: NotificationMethod[]
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, trigger, reset, watch, formState: { isDirty, isValid} } = formObj
  
  //Re-initialize form when have asset
  useEffect(() => {
    if (currentUser) {
      reset({
        preferredNotificationMethods: defaultMethods,
      })
    }
  }, [currentUser])
  
  const onSubmit = attributes => {
    Logger.info(`Updating user...`)
    updateCurrentUser(attributes)
  }

  useEffect(() => {
    if (updated) {
      const preferredNotificationMethods = updated.preferredNotificationMethods
      //Record amplitude event
      ampli.identify(currentUser?.id, {
        preferEmail: !preferredNotificationMethods || preferredNotificationMethods.includes(NotificationMethod.EMAIL),
        preferPush: !preferredNotificationMethods || preferredNotificationMethods.includes(NotificationMethod.PUSH),
      })
      ampli.userUpdate({
        preferredNotificationMethods,
      })
      close()
    }
  }, [updated])


  const close = () => {
    onDismiss()
  }

  const grantSupportAccess = () => {
    updateCurrentUserSupportAccess({
      supportAccessUntilDate: formatISO(addDays(startOfToday(), SUPPORT_ACCESS_DAYS + 1 )) //+1 to take until end of day 
    })
  }

  const revokeSupportAccess = () => {
    deleteCurrentUserSupportAccess()
  }

  const handleResetUser = async () => {
    await deleteClient()
    goToLogout()
  }

  const isLoading = currentUserIsLoading || statusIsLoading || updateIsLoading || userSupportAccessIsLoading || updateSupportAccessIsLoading || deleteSupportAccessIsLoading
  const error: any = updateError

  useEffect(() => {
    isPushNotificationsEnabled().then(answer => {
      if (!answer) {
        setPushEnabled(false)
      }
    })
  }, [])

  const enablePushNotifications = async () => {
    getPushNotificationToken(true).then(token => {
      if (token) {
        setPushEnabled(true)
        dispatch(setPushToken(token))
        addPushToken({
          method: NotificationMethod.PUSH,
          token,
        })
      }
    })
  }

  return (
      <ModalEditScreen
        formTitle={'Account Settings'}
        onDismiss={onDismiss}
        isDirty={isDirty}
        dismissDialogText={'Discard changes to your settings?'}
        error={error}
        errorTryAgain={updateError ? handleSubmit(onSubmit) : undefined}
        errorCancel={updateError ? updateReset : close}
        isLoading={isLoading}
        loadingMessage={updateIsLoading ? ['Saving...'] : undefined}
        buttonTitle={'Save'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ContentDivider />
        <Subheading style={localStyles.subTitle}>{'Support Access'}</Subheading>
        {
          supportAccessGranted
            ? <Paragraph>{`Support has access to your account until ${format(new Date(supportAccess?.supportAccessUntilDate), 'do MMMM')}.`}</Paragraph>
            : <Paragraph>{`In order to better assist you, Jarvis support may request access to view your account data.`}</Paragraph>
        }
        <Button
          mode='text'
          confirmation={{
            requiresConfirmation: true,
            dialogTitle: supportAccessGranted ? 'Revoke Support Access?' : 'Grant Support Access?',
            dialogContent: supportAccessGranted
            ? `Remove Jarvis support access to your account?`
            : `Grant Jarvis support access to your account until ${format(addDays(startOfToday(), SUPPORT_ACCESS_DAYS), 'do MMMM')}?`
          }}
          onPress={supportAccessGranted ? revokeSupportAccess : grantSupportAccess}>
          {supportAccessGranted ? 'Revoke Support Access' : 'Grant Support Access'}
        </Button>
        <ContentDivider />
        {
          status?.testMode ?
          <>
            <Subheading style={localStyles.subTitle}>{'Test Mode'}</Subheading>
            <Paragraph>{'When testing Jarvis, you may reset your account to start again as a new client.'}</Paragraph>
            <Button
              mode='text'
              confirmation={{
                requiresConfirmation: true,
                dialogContent: 'Resetting your profile will destroy all your data and log you out of Jarvis. On next login, you will be returned to the onboarding experience. Note that reset is only possible in test environments.'
              }}
              onPress={handleResetUser}>
              {'Reset Profile'}
            </Button>
            <ContentDivider />
          </>
          : <></>
        }
        <Subheading style={localStyles.subTitle}>{'Notifications'}</Subheading>
        <Paragraph>{'Choose your preferred methods for notifications from Jarvis'}</Paragraph>
        <ManagedMultipleChoiceInput
          formObj={formObj}
          name={'preferredNotificationMethods'}
          options={enumToAutocompleteOptions(NotificationMethod)}
          informationMessage={`We will always send you any important updates about your account. Your preference will be used for any other updates.`}
        />
        {
          pushEnabled ? <></> :
            <Button
              mode='text'
              onPress={enablePushNotifications}>
              {'(Re)enable Push Notifications'}
            </Button>
        }
      </ModalEditScreen>
  )
}

const localStyles = StyleSheet.create({
  subTitle: {
    paddingTop: Sizing.x15,
    color: Colors.brand.purple1,
  },
})