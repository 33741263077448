import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { createBranchLink } from 'lib/linkHelpers'
import React from 'react'
import { Image } from 'react-native'
import { InvestmentPlanDto } from 'store/dto/investment-plan.dto'
import { Sizing, Paper } from 'styles'

type AdminInvestmentPlanListItemProp = {
  investmentPlan: InvestmentPlanDto
  onPressFunction?: () => void
}
export const AdminInvestmentPlanListItem = (props: AdminInvestmentPlanListItemProp) => {
  const { investmentPlan, onPressFunction } = props
  const { name, code, logo, shortDescription } = investmentPlan

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = code
  const subTitle = shortDescription
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
