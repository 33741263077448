import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { SchemeEnrolmentPreviousPensionAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentPreviousPensionAdd/SchemeEnrolmentPreviousPensionAddProcessStack'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto, VerifySchemeEnrolmentPreviousPensionDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Flex, Paper, Sizing } from 'styles'

export const SchemeEnrolmentSetup_06_PreviousPension = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const [addPreviousPension, setAddPreviousPension] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const { groupScheme } = enrolment || {}
  const {
    previousPensionProviderBrandKey,
    previousPensionProviderBrandName,
    organizationName,
  } = groupScheme || {}

  const dispatch = useAppDispatch()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const { previousPension } = workingSetupSchemeEnrolmentData || {}

  const next = () => {
    navigation.navigate('Results')
  }

  const handleSetPreviousPension = (previousPension: VerifySchemeEnrolmentPreviousPensionDto) => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      previousPension,
    }))
    setAddPreviousPension(false)
  }

  const handleRemovePreviousPension = () => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      previousPension: undefined,
    }))
  }

  const handleConfirm = () => {
    setShowDialog(false)
    next()
  }

  return (
    <>
      {
        addPreviousPension ?
          <SchemeEnrolmentPreviousPensionAddProcessStack
            visible={addPreviousPension}
            onDismiss={() => setAddPreviousPension(false)}
            onComplete={handleSetPreviousPension}
            groupScheme={groupScheme}
          />
        :
          <ProcessScreen
            buttonTitle={previousPension
              ? 'Finish'
              : `Add Previous Pension`
            }
            buttonAction={previousPension
              ? next
              : () => setAddPreviousPension(true)
            }
            allowTextButton={true}
            textButtonTitle={previousPension
              ? 'Remove previous pension'
              : `Skip / I didn't have one`
            }
            textButtonAction={previousPension
              ? handleRemovePreviousPension
              : () => setShowDialog(true)
            }
            enableButton={true}
            headline={`Do you have a previous workplace pension here?`}
            subHeading={previousPensionProviderBrandName
              ? `${organizationName} told us they previously used ${previousPensionProviderBrandName}`
              : `${organizationName} may have previous used another provider`
            }
          >
            <View>
              {
                previousPensionProviderBrandName || previousPension
                  ? <View>
                      <Pressable style={localStyles.itemContainer} onPress={() => setAddPreviousPension(true)}>
                        <View style={localStyles.iconContainer} >
                          <MaterialCommunityIcons name={'office-building'} size={Sizing.x20} color={themeColors.primary}/>
                        </View>
                        <View style={{flex:1}}>
                          <Subheading numberOfLines={1} style={{textAlign:'left' }} >{previousPension ? previousPension?.name : `${previousPensionProviderBrandName} Pension`}</Subheading>
                        </View>
                        <View style={localStyles.amountContainer}>
                          <Subheading>{previousPension?.currentValue ? formatCurrencyAmount(previousPension?.currentValue) : '£ ???'}</Subheading>
                        </View>
                      </Pressable>
                      <ContentDivider />
                    </View>
                  : <View>
                      <Pressable style={localStyles.itemContainer} onPress={() => setAddPreviousPension(true)}>
                        <View style={localStyles.iconContainer} >
                          <MaterialCommunityIcons name={'plus-circle'} size={Sizing.x30} color={themeColors.primary}/>
                        </View>
                        <View>
                          <Subheading>{'Add previous pension'}</Subheading>
                        </View>
                        <View style={localStyles.amountContainer}>
                          <Subheading> </Subheading>
                        </View>
                      </Pressable>
                      <ContentDivider />
                    </View>
              }
            </View>
            <View style={{ paddingTop: Sizing.x20 }}>
              <BulletItem style={{ textAlign: 'left' }}>{`Adding previous pensions helps Jarvis understand your wider planning, and accurately predict when you can retire.`}</BulletItem>
              <BulletItem style={{ textAlign: 'left' }}>{`It can also help you consolidate those pensions when you are ready to do so.`}</BulletItem>
              <BulletItem style={{ textAlign: 'left' }}>{`If you have already recorded the previous pension from this employer in your Jarvis account, there's no need to do so again.`}</BulletItem>
            </View>
            <ConfirmationDialog
              visible={showDialog}
              onCancel={() => setShowDialog(false)}
              title={'Are you sure?'}
              content={`You can add other pensions at any time in the Jarvis app to improve predictions and help you consolidate when you are ready to do so.`}
              confirmLabel={`I'm sure, Finish`}
              onConfirm={handleConfirm}
            />
          </ProcessScreen>
        }
    </>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start,
  },
  amountContainer: {
    ...Flex.override.right,
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between,
  },
})