import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import { envVariables } from "environment"
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from "lib/constants"
import { platformIsWeb } from "lib/platformHelpers"
import React from 'react'
import { Image, ScrollView, StyleSheet, View } from "react-native"
import { ClientMeDto } from "store/dto/client.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { ContentDivider } from "components/Layout/ContentDivider"

type LegalDocumentsModalProps = {
  client?: ClientMeDto
  visible: boolean
  onDismiss: any
}

const isWeb = platformIsWeb()

export const LegalDocumentsModal = (props: LegalDocumentsModalProps) => {
  const { client, onDismiss, visible } = props || {}
  const { segment } = client || {}
  const { termsUrl: segmentTermsUrl, name: segmentName } = segment || {}

  const {
    termsUrl,
    privacyUrl,
    personalPensionTermsUrl,
    personalPensionKeyFeaturesUrl,
    personalPensionMemberDeclarationUrl,
    genericPensionIllustrationUrl,
    groupPensionTermsUrl,
    groupPensionpersonalPensionKeyFeaturesUrl,
    pensionFindingTermsUrl,
    raindropPrivacyUrl,
    moneyhubTermsUrl,
    moneyhubPrivacyUrl,
  } = envVariables

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>          
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}          
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
              <Headline style={{ fontSize: Sizing.x20 }}>{`Important Legal Documents`}</Headline>
              <Image
                source={require('assets/external/fscs-logo1-purp.png')}
                style={{
                  marginVertical: Sizing.x10,
                  width: Sizing.x80,
                  height: Sizing.x80,
                  alignSelf: 'center',
                }}
                resizeMode={'contain'}
              />
              <Paragraph style={localStyles.text}>{`Here you can find links to all the important legal documents and information relating to your ${JAR_NAME_ALL}.`}</Paragraph> 
            </View>

            <ContentDivider />
            <Subheading style={localStyles.subTitle}>{'General Documents'}</Subheading>
            <Paragraph style={localStyles.text}>{`These links and documents relate to your overall ${JAR_NAME_ALL} and relationship with us.`}</Paragraph>
            <ExternalLinkButton
              url={termsUrl}
              directToBrowser={true}
            >
              {`Terms & Conditions`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={privacyUrl}
            >
              {`Privacy Policy`}
            </ExternalLinkButton>
            
            <ContentDivider />
            <Subheading style={localStyles.subTitle}>{'Personal Jar Documents'}</Subheading>
            <Paragraph style={localStyles.text}>{`These links and documents relate to your ${JAR_NAME_PERSONAL}, if you have chosen to open one with us.`}</Paragraph>
            <ExternalLinkButton
              url={personalPensionTermsUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Terms & Conditions`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={personalPensionKeyFeaturesUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Key Features Document`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={personalPensionMemberDeclarationUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Member Declaration`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={genericPensionIllustrationUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Pension Illustration`}
            </ExternalLinkButton>
            {
              segmentTermsUrl ? 
                <ExternalLinkButton
                  url={segmentTermsUrl}
                  directToBrowser={true}
                  iconName={'file-document-outline'}
                >
                  {`${segmentName} Terms`}
                </ExternalLinkButton>
                : <></>
            }

            <ContentDivider />
            <Subheading style={localStyles.subTitle}>{'Workplace Jar Documents'}</Subheading>
            <Paragraph style={localStyles.text}>{`These links and documents relate to your ${JAR_NAME_GROUP}, if you have been enrolled in a workplace pension scheme by your employer.`}</Paragraph>
            <ExternalLinkButton
              url={groupPensionTermsUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Terms & Conditions`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={groupPensionpersonalPensionKeyFeaturesUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Key Features Document`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={genericPensionIllustrationUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Pension Illustration`}
            </ExternalLinkButton>

            <ContentDivider />
            <Subheading style={localStyles.subTitle}>{'Transfer Documents'}</Subheading>
            <Paragraph style={localStyles.text}>{`These links and documents relate to the transfer of other pensions into your ${JAR_NAME_PERSONAL}, including pension finding services offered by ourselves via our partner Raindrop.`}</Paragraph>
            <ExternalLinkButton
              url={pensionFindingTermsUrl}
              directToBrowser={true}
              iconName={'file-document-outline'}
            >
              {`Pension Finding Terms`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={raindropPrivacyUrl}
            >
              {`Raindrop Privacy Policy`}
            </ExternalLinkButton>

            <ContentDivider />
            <Subheading style={localStyles.subTitle}>{'Contribution Documents'}</Subheading>
            <Paragraph style={localStyles.text}>{`These links and documents relate to making contributions into your ${JAR_NAME_PERSONAL} using our partner MoneyHub.`}</Paragraph>
            <ExternalLinkButton
              url={moneyhubTermsUrl}
            >
              {`MoneyHub Terms & Conditions`}
            </ExternalLinkButton>
            <ExternalLinkButton
              url={moneyhubPrivacyUrl}
            >
              {`MoneyHub Privacy Policy`}
            </ExternalLinkButton>
          </ScrollView>
          <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'center',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})