import { MaskService } from 'react-native-masked-text'

import { MIN_TARGET_RETIREMENT_AGE, MODEL_HORIZON_YEARS, NATIONAL_INSURANCE_NO_MASK, SORTCODE_MASK } from './constants'
import { ClientMeDto } from 'store/dto/client.dto'
import { addYears, differenceInYears, getYear, isAfter, isBefore, setYear, subDays } from 'date-fns'

export const formatNationalInsuranceNumber = (unformatted: string = '') => {
  if (!unformatted) {
    return ''
  }
  return MaskService.toMask('custom', unformatted, {
    mask: NATIONAL_INSURANCE_NO_MASK
  })
}

export const unformatNationalInsuranceNumber = (formatted: string = '') => {
  if (!formatted) {
    return ''
  }
  return formatted.toUpperCase().replaceAll(' ', '')
}

export const unformatSortCode = (value: string) => {
  return value.replaceAll('-', '')
}

export const formatSortCode = (unformatted: string = '') => {
  return MaskService.toMask('custom', unformatted, {
    mask: SORTCODE_MASK
  })
}

export const getNextClientBirthday = (client: ClientMeDto): Date => {
  const today = new Date()
  const birthdayThisYear = setYear(new Date(client.birthDate), getYear(today))
  const nextBirthday = isBefore(birthdayThisYear, today)
    ? addYears(birthdayThisYear, 1)
    : birthdayThisYear
  return nextBirthday
}

//This method mirrors the logic at the backend to ensure that the minimum target retirement age/date
//selectable by the user is adjusted up from MIN_TARGET_RETIREMENT_AGE, in cases where
//the user is young and the underlying financial modelling cannot project for the full length of their life
//In these cases the backend performs some additional processing, and this affects the lower limit of 
//target retirement age that can be supported
export const deriveMinimumTargetRetirementAge = (client: ClientMeDto) => {
  const now = new Date()
  const { lastSurvivorDeathDate } = client

  const lastSurvivorDeathDateDate = new Date(lastSurvivorDeathDate)

  const modelReferenceDate = getNextClientBirthday(client)

  const hadBirthdayThisYear =
    getYear(modelReferenceDate) === getYear(new Date())

  //modelHorizonDate is the date we calculate as being the max that EV
  //will tolerate for backsolves, which appears to be:
  //If birthday has already been this year => 75 years from next birthday minus 1 day
  //Else birthday today or not yet this year => 74 years from next birthday minus 1 day
  const modelHorizonDate = subDays(
    addYears(
      modelReferenceDate,
      hadBirthdayThisYear
        ? MODEL_HORIZON_YEARS
        : MODEL_HORIZON_YEARS - 1,
    ),
    1,
  )

  //Return 0 if lastSurvivorDeathDate is no further away than modelHorizonDate
  if (!isAfter(lastSurvivorDeathDateDate, modelHorizonDate)) {
    return MIN_TARGET_RETIREMENT_AGE
  }

  //Else derive the answer...
  const lsddThisYear = setYear(
    lastSurvivorDeathDateDate,
    getYear(now),
  )
  const mhdThisYear = setYear(modelHorizonDate, getYear(now))
  const extraYears = isAfter(lsddThisYear, mhdThisYear) ? 1 : 0

  const adjustmentYears =
    extraYears +
    differenceInYears(
      lastSurvivorDeathDateDate,
      modelHorizonDate,
    )
  return MIN_TARGET_RETIREMENT_AGE + adjustmentYears
}