import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { createBranchLink } from 'lib/linkHelpers'
import React from 'react'
import { Image } from 'react-native'
import { AssetDto } from 'store/dto/asset.dto'
import { Sizing, Paper } from 'styles'

type AdminAssetListItemProp = {
  asset: AssetDto
  onPressFunction?: () => void
}
export const AdminAssetListItem = (props: AdminAssetListItemProp) => {
  const { asset, onPressFunction } = props
  const { shortName, isin, logo, longName } = asset

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = isin
  const subTitle = longName
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={shortName}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
