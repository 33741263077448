import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useBulkTransferRetirementAssetsMutation, useGetPensionProvidersQuery, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBulkTransfer, workingBulkTransfer } from 'store/tempDataSlice'
import { pick } from 'lodash'
import { getAmplitudeRetirementAssetAttributes } from 'lib/amplitudeHelpers'
import { RetirementAssetTracingStatus } from 'store/dto/retirement-asset.dto'

export const BulkTransfer_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}
  const dispatch = useAppDispatch()

  const [ updateClient, { data: client, error: clientError, isLoading: clientIsLoading } ] = useUpdateMeMutation()

  const workingBulkTransferData: any = useAppSelector(workingBulkTransfer)
  const [ requestBulkTransfer, { data: transferredAssets, error: bulkTransferError, isLoading: bulkTransferIsLoading } ] = useBulkTransferRetirementAssetsMutation()

  const isLoading = bulkTransferIsLoading || clientIsLoading
  const error: any = bulkTransferError || clientError

  //Save on enter
  useEffect(() => {
    if (workingBulkTransferData) {
      saveBulkTransfer()
    } else {
      saveClient()
    }
  }, [])

  //Update client when transfer successful
  useEffect(() => {
    if (transferredAssets) {
      transferredAssets.forEach(asset => {
        ampli.transferInstruct({
          ...getAmplitudeRetirementAssetAttributes(asset),
          isRetransfer: false,
          useTracing: asset?.tracingStatus === RetirementAssetTracingStatus.COMPLETED
        })
      })
      saveClient()
    }
  }, [transferredAssets])

  //Navigate when client saved
  useEffect(() => {
    if (client) {
      ampli.transfersSetup({
        skipped: false,
        transferredCount: workingBulkTransferData?.assetIdsToTransfer?.length || 0,
        ...pick(workingBulkTransferData, [
          'totalTransferValue',
        ])
      })
      end()
    }
  }, [client])

  const saveBulkTransfer = () => {
    Logger.info(`Creating bulk transfer...`)
    requestBulkTransfer(workingBulkTransferData)
  }
  
  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        consolidate: true,
      },
    })
  }

  const end = () => {
    //Clear temp data
    const { regularContributionGrossValue } = workingBulkTransferData || {}
    dispatch(updateWorkingBulkTransfer(undefined))

    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'transfer_finish',
      isRetransfer: false,
      outcome: 'success',
    })

    rootNavigate(nextRoute, {
      ...route?.params || {},
      regularContributionGrossValue
    })
  }

  return (
    <ProcessScreen
      buttonTitle={''}
      buttonAction={() => {}}
      showButton={false}
      error={error}
      errorTryAgain={transferredAssets ? saveClient : saveBulkTransfer}
      isLoading={isLoading}
      loadingMessage={[`Submitting your request${workingBulkTransferData?.assetIdsToTransfer?.length === 1 ? '' : 's'}...`]}
    />
  )
}
