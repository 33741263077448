import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem } from 'components/Inputs/ManagedAutoCompleteInput'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { PensionProviderDto } from 'store/dto/pension-provider.dto'
import { Colors, Paper, Sizing } from 'styles'

type AdminPensionProviderListItemProp = {
  provider: PensionProviderDto
  brandOption: AutoCompleteItem
  onPressFunction?: () => void
}
export const AdminPensionProviderListItem = (props: AdminPensionProviderListItemProp) => {
  const { provider, brandOption, onPressFunction } = props
  const { name, description, origoId } = provider
  const { icon, label } = brandOption || {}

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <DetailedListItem
      icon={icon ? icon() : <MaterialCommunityIcons name={'dots-horizontal'} size={Sizing.x30} color={Colors.neutral.black} />}
      onPress={onPressFunction}
      title={name}
      titleRight={origoId}
      subTitle={description}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
