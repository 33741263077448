import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput';
import { ModalProcessScreen } from 'components/Layout';
import { addDays, addMonths, formatISO, getDate, startOfDay, startOfMonth } from 'date-fns';
import { getAutoCompleteDateOptions, formatOrdinalDay } from 'lib/dateHelpers';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateWorkingContributionSetupPayment, workingContributionPayment } from 'store/tempDataSlice';

const STANDING_ORDER_MIN_DAYS_AHEAD = 5

export const MakeContributions_23_RegularDayOfMonth = ({ route, navigation }) => {
  const dispatch = useAppDispatch()
  const firstPaymentDateRef = useRef()
  const workingContributionPaymentData = useAppSelector(workingContributionPayment)
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}
  const { existingRegularContribution } = contributionsData || {}

  let earliestDate = addDays(startOfDay(new Date()), STANDING_ORDER_MIN_DAYS_AHEAD)
  if (getDate(earliestDate) > 28) {
    earliestDate = addMonths(startOfMonth(earliestDate), 1)
  }

  const dateOptions = getAutoCompleteDateOptions(earliestDate, addMonths(earliestDate, 1), 28) || []

  const existingRegularNextDateItem = existingRegularContribution?.dayOfMonth
    ? dateOptions.find(dateItem => {
      return getDate(new Date(dateItem.value)) === existingRegularContribution?.dayOfMonth
    })
    : undefined

  const formObj = useForm<{
    firstPaymentDate: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstPaymentDate: workingContributionPaymentData?.firstPaymentDate
        || existingRegularNextDateItem?.value
        || formatISO(earliestDate, { representation: 'date' })
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingContributionSetupPayment(attributes))
    navigation.navigate(nextScreen)
  }
  return (
    <ModalProcessScreen
      headline={'When should the first amount be collected?'}
      buttonTitle={'Next'}
      subHeading={`Future payments will be on the same day each month`}
      buttonAction={handleSubmit(onSubmit)}
      // showButton={true}
      enableButton={isValid}
    >
      <ManagedAutoCompleteInput
        name={'firstPaymentDate'}
        formObj={formObj}
        modalTitle={'Choose Date'}
        selectOnlyMode={true}
        dataSet={dateOptions}
        required={true}
        informationMessage={existingRegularNextDateItem ? `Current contributions are on ${formatOrdinalDay(new Date(existingRegularNextDateItem?.value))} of the month` : undefined}
      />
    </ModalProcessScreen>
  )
}

