import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { AmlStatus, ClientAnyDto } from 'store/dto/client.dto'
import { Sizing, Paper } from 'styles'

type AdminClientListItemProp = {
  client: ClientAnyDto
  onPressFunction?: () => void
}
export const AdminClientListItem = (props: AdminClientListItemProp) => {
  const { client, onPressFunction } = props
  const { email, firstName, surname, amlStatus } = client

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 'account'
  const title = `${firstName} ${surname}`
  const titleRight = amlStatus === AmlStatus.PASS ? 'Pass' : amlStatus === AmlStatus.REFER ? 'Refer' : amlStatus === AmlStatus.FAIL ? 'Fail' : 'N/A'
  const titleRightStyle = {
    color: amlStatus === AmlStatus.PASS ? themeColors.primary : amlStatus === AmlStatus.REFER ? themeColors.accent : amlStatus === AmlStatus.FAIL ? themeColors.error : themeColors.disabled
  }
  
  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={titleRight}
      titleRightStyle={titleRightStyle}
      subTitle={email}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
    />
  )
}
