import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { rootNavigate } from 'lib/RootNavigation'
import { filterRejectedOrModifiedPensionAssets } from 'lib/retirementAssetHelpers'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { useGetMeQuery, useGetRetirementAssetsQuery } from 'store/apiSlice'
import { Colors } from 'styles'
import { TransfersList } from '../Components/TransfersList'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'

export const TransfersScreen = () => {

  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetRetirementAssetsQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()

  const isLoading = assetsIsLoading || clientIsLoading
  const error = assetsError || clientError

  const rejectedTransferAssets = filterRejectedOrModifiedPensionAssets(assets, client)
  
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={assetsError ? refetchAssets : refetchClient} /> :
        <OnboardingGuard
          explanation={`Your Transfers screen will show you the progress on the transfer of old pensions into your ${JAR_NAME_PERSONAL}`}
          subExplanation={`You can start finding and consolidating old pensions once you have verified your identity and opened your ${JAR_NAME_PERSONAL}.`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
            OnboardingStateStepIdentifier.PLAN_CHOICE,
            OnboardingStateStepIdentifier.CONSOLIDATE,
          ]}
        >
          <>
            <TransfersList />
            {
              rejectedTransferAssets?.length ? 
              <FooterButton
                mode={'text'}
                color={Colors.brand.red3}
                onPress={() => rootNavigate('BulkRetransferIntroScreen')}
              >{`Resolve Rejected Transfers`}
              </FooterButton>
              : <></>
            }
            <FooterButton
              onPress={() => rootNavigate('BulkTransferIntroScreen')}
            >{`Start New Transfer`}
            </FooterButton>
          </>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

