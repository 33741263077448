import { ActionCard } from 'components/Utility/ActionCard'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedCardChoiceInputProps = {
  formObj: UseFormReturn<any>
  name: string
  submitHandler?: any
  required?: boolean
  options: ManagedCardChoiceInputOption[]
}

export type ManagedCardChoiceInputOption = {
  value: string
  title: string
  description: string
  modalContentFunction?: Function
  modalProceedLabel?: string
  modalCancelLabel?: string
  illustrationFilename?: string
  imageSource?: any
  disabled?: boolean
}

type ManagedCardChoiceProps = {
  formObj: UseFormReturn<any>
  name: string
  submitHandler?: any
  option: ManagedCardChoiceInputOption
}

const CardChoice = (props: ManagedCardChoiceProps) => {
  const { formObj, name, submitHandler, option } = props
  const { title, description, imageSource, illustrationFilename, value, modalContentFunction, modalProceedLabel, modalCancelLabel, disabled } = option
  const { watch, trigger, setValue } = formObj

  const currentValue = watch(name)
  const selected = currentValue === undefined ? undefined : currentValue === value

  const setInputValue = () => {
    if (!disabled) {
      setValue(name, value, { shouldDirty: true, shouldValidate: true })
      trigger(name)
      if(submitHandler) {
        submitHandler()
      }
    }
  }
  
  return (
    <ActionCard
      illustrationFilename={illustrationFilename}
      imageSource={imageSource}
      title={title}
      description={description}
      size={'small'}
      onPress={setInputValue}
      buttonLabel={''}
      confirmationModalContentFunction={modalContentFunction}
      confirmationModalProceedLabel={modalProceedLabel || `Select`}
      confirmationModalCancelLabel={modalCancelLabel}
      noModalProceedButton={disabled}
      selected={selected}
      unavailable={disabled}
    />
  )
}

export const ManagedCardChoiceInput = (props: ManagedCardChoiceInputProps) => {
  const { formObj, options, required, name, submitHandler } = props
  const { control } = formObj

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          required,
        }}
        render={({ field: { onChange, value } }) => (
          <>
            {
              options.map((option, idx) => {
                return <CardChoice
                  key={idx}
                  formObj={formObj}
                  name={name}
                  submitHandler={submitHandler}
                  option={option}
                />
              })
            }
            <InputErrorMessages formObj={formObj} name={name} />
          </>
          )}
        name={props.name}
      />
    </View>
  )
}
