import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { truncate } from 'lodash'
import React from 'react'
import { Image } from 'react-native'
import { GroupPortfolioDto } from 'store/dto/group-portfolio.dto'
import { Paper, Sizing } from 'styles'

type AdminGroupPortfolioListItemProp = {
  groupPortfolio: GroupPortfolioDto
  onPressFunction?: () => void
}
export const AdminGroupPortfolioListItem = (props: AdminGroupPortfolioListItemProp) => {
  const { groupPortfolio, onPressFunction } = props
  const { name, logo, description, secclModelId } = groupPortfolio

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = secclModelId
  const subTitle = truncate(description, { length: 120 })
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
