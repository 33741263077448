import { isAfter, isBefore, isSameDay, set, startOfDay, startOfToday } from 'date-fns'
import { Logger } from 'lib/logger'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useGetMeQuery } from 'store/apiSlice'
import { UserDto } from 'store/dto/user.dto'

export enum CalendarPeriodName {
  BIRTHDAY = 'birthday',
  NEW_YEARS_DAY = 'new_years_day',
  VALENTINES = 'valentine',
  APRIL_FOOL = 'april_fools',
  HALLOWEEN = 'halloween',
  BONFIRE_NIGHT = 'bonfire_night',
  CHRISTMAS = 'christmas',
  NEW_YEARS_EVE = 'new_years_wvw',
}

export interface CalendarPeriod {
  name: CalendarPeriodName
  salutation: string
  icons: string[]
}

export type CalendarContextType = {
  calendarPeriod: CalendarPeriod
}

const BASE_CONTEXT: CalendarContextType = {
  calendarPeriod: undefined,
}

export const CalendarContext = createContext<CalendarContextType>(BASE_CONTEXT)

export const CalendarProvider = (props: { user: UserDto, ready: boolean, children: ReactNode }) => {
  const { user, ready, children } = props

  const today = startOfToday()

  const [calendarPeriod, setCalendarPeriod] = useState<CalendarPeriod>(undefined)

  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery(undefined, { skip: !(ready && user) })
  
  const deriveCalendarPeriod = (): CalendarPeriod => {

    //Client birthday
    if (client) {
      const clientBirthDate = new Date(client?.birthDate)
      if (today === clientBirthDate) {
        return {
          name: CalendarPeriodName.BIRTHDAY,
          salutation: 'Happy Birthday!',
          icons: [
            'cake-variant',
            'gift',
            'party-popper',
          ]
        }
      }
    }

    //New Year's Day
    if (isSameDay(today, set(today, { month: 0, date: 1}))) {
      return {
        name: CalendarPeriodName.NEW_YEARS_DAY,
        salutation: `Happy New Year!`,
        icons: [
          'party-popper',
          'glass-flute',
          'calendar-today',
          'glass-wine',
        ]
      }
    }

    //Valentines
    if (isSameDay(today, set(today, { month: 1, date: 14}))) {
      return {
        name: CalendarPeriodName.VALENTINES,
        salutation: `Happy Valentine's Day!`,
        icons: [
          'heart',
          'flower-tulip',
          'heart-broken',
          'gift',
        ]
      }
    }

    //April Fool's
    if (isSameDay(today, set(today, { month: 3, date: 1}))) {
      return {
        name: CalendarPeriodName.APRIL_FOOL,
        salutation: `Happy April Fool's!`,
        icons: [
          'emoticon-tongue-outline',
          'drama-masks',
          'hat-fedora',
        ]
      }
    }

    //Halloween
    if (isSameDay(today, set(today, { month: 9, date: 31}))) {
      return {
        name: CalendarPeriodName.HALLOWEEN,
        salutation: `Happy Halloween!`,
        icons: [
          'pumpkin',
          'ghost',
          'broom',
          'skull',
          'cat',
        ]
      }
    }

    //Bonfire Night
    if (isSameDay(today, set(today, { month: 9, date: 5}))) {
      return {
        name: CalendarPeriodName.BONFIRE_NIGHT,
        salutation: `Happy Bonfire Night!`,
        icons: [
          'fire',
          'firework',
          'guy-fawkes-mask',
        ]
      }
    }

    //Christmas
    if (isAfter(today, set(today, { month: 11, date: 17})) && isBefore(today, set(today, { month: 11, date: 27}))) {
      return {
        name: CalendarPeriodName.CHRISTMAS,
        salutation: `Merry Christmas!`,
        icons: [
          'pine-tree',
          'snowman',
          'gift',
          'string-lights',
          'snowflake',
          'food-turkey',
        ]
      }
    }

    //New Year's Eve
    if (isSameDay(today, set(today, { month: 11, date: 31}))) {
      return {
        name: CalendarPeriodName.NEW_YEARS_EVE,
        salutation: `Happy Holidays!`,
        icons: [
          'party-popper',
          'glass-cocktail',
          'dance-ballroom',
        ]
      }
    }

    return undefined
  }


  //Recalculate calendarIcons on change
  useEffect(() => {
    const cp = deriveCalendarPeriod()
    setCalendarPeriod(cp)
  },[client])

  //Calculate calendarIcons on entry
  useEffect(() => {
    const cp = deriveCalendarPeriod()
    setCalendarPeriod(cp)
  },[])

  return (
    <CalendarContext.Provider value={{
      calendarPeriod
    }}>
      {children}
    </CalendarContext.Provider>
  )}

export const useCalendarContext = () => useContext(CalendarContext)
