import { ProcessStackBuilder, ProcessStackBuilderScreen } from 'navigation/stacks/ProcessStackBuilder'
import { ModelGoalIdentifier, useGuidanceContext } from 'providers'
import React from 'react'
import { ContributionConfiguration } from 'store/dto/client.dto'
import { RegularContribution } from 'store/dto/account.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSuggestion } from 'store/tempDataSlice'
import { Contributions_01_ContributionSource, Contributions_03_Goals, Contributions_90_ManualSetup, Contributions_10_SuggestionsAchieveTRA, Contributions_20_AgeChoiceReduceVRA, Contributions_21_SuggestionsReduceVRA, Contributions_30_StrategyChoiceIncreaseSurplus, Contributions_31_SuggestionsIncreaseSurplus, Contributions_40_AgeChoiceReduceTRA, Contributions_41_SuggestionsReduceTRA, Contributions_99_Execute, Contributions_02_GuidanceChoice } from './Screens'

export type ContributionsProcessStackProps = {
  existingContributionConfiguration: ContributionConfiguration
  existingRegularContribution: RegularContribution
  goal: ModelGoalIdentifier
  route?: any
}

export const ContributionsProcessStack = ({ route, navigation }) => {
  const { goal, modelAvailable, existingContributionConfiguration } = route?.params || {}

  const dispatch = useAppDispatch()
  //Note there is no "check" here that the model is loaded, because it will only get used
  //if prop 'goal' is present, which implicitly means the currentGoals will be available too
  const { currentGoals } = useGuidanceContext()

  const processScreens: ProcessStackBuilderScreen[] = []

  //If a contribution source is provided, set in temp state
  //Else use screen
  if (existingContributionConfiguration?.source) {
    dispatch(updateWorkingSuggestion({
      contributionSource: existingContributionConfiguration?.source
    }))
  } else {
    processScreens.push({ 
      navigationId: 'ContributionSource',
      component: Contributions_01_ContributionSource,
    })
  }

  //Include the choice between guided and manual unless a goal has been provided
  if (!goal) {
    processScreens.push({ 
      navigationId: 'GuidanceChoice',
      component: Contributions_02_GuidanceChoice,
    })
  }

  //Include goal screens only if current model is available
  if (modelAvailable) {
    const goalIdsToInclude = goal ? [goal] : currentGoals.map(goal => goal.id)

    //If a goal is provided, set in temp state
    //Else offer choice
    if (goal) {
      dispatch(updateWorkingSuggestion({
        goal,
      }))
    } else {
      processScreens.push({ 
        navigationId: 'Goals',
        component: Contributions_03_Goals,
      })
    }
    
    //Include relevant screen based on goalsToInclude
    if (goalIdsToInclude.includes(ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE)) {
      processScreens.push({ 
        navigationId: 'SuggestionsAchieveTRA',
        component: Contributions_10_SuggestionsAchieveTRA,
      })
    }
  
    if (goalIdsToInclude.includes(ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE)) {
      processScreens.push({ 
        navigationId: 'AgeChoiceReduceVRA',
        component: Contributions_20_AgeChoiceReduceVRA,
      })
      processScreens.push({ 
        navigationId: 'SuggestionsReduceVRA',
        component: Contributions_21_SuggestionsReduceVRA,
      })
    }
  
    if (goalIdsToInclude.includes(ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS)) {
      processScreens.push({ 
        navigationId: 'StrategyChoiceIncreaseSurplus',
        component: Contributions_30_StrategyChoiceIncreaseSurplus,
      })
      processScreens.push({ 
        navigationId: 'SuggestionsIncreaseSurplus',
        component: Contributions_31_SuggestionsIncreaseSurplus,
      })
    }
  
    if (goalIdsToInclude.includes(ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE)) {
      processScreens.push({ 
        navigationId: 'AgeChoiceReduceTRA',
        component: Contributions_40_AgeChoiceReduceTRA,
      })
      processScreens.push({ 
        navigationId: 'SuggestionsReduceTRA',
        component: Contributions_41_SuggestionsReduceTRA,
      })
    }
  }

  //Always include manual
  processScreens.push({ 
    navigationId: 'ManualSetup',
    component: Contributions_90_ManualSetup,
  })


  return (
    <ProcessStackBuilder
      navigatorId='Contributions'
      processTitle='Contributions'
      previousRoute='ContributionsIntroScreen'
      nextRoute='ContributionsHeroScreen'
      initialParams={route?.params}
      processScreens={processScreens}
      resultScreen={{
        navigationId: 'Execute',
        component: Contributions_99_Execute,
      }}
    />
  )
}
