import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Pressable, StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import DropDownPicker, { ItemType } from 'react-native-dropdown-picker'
import { TouchableOpacity } from 'react-native'
import { Colors, Flex, Sizing, Typography, Paper } from 'styles'
import { applyOpacity } from 'styles/colors'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'
import { useDebounce } from 'use-debounce'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { DEFAULT_DEBOUNCE_MS } from 'lib/constants'

export const CURRENT_SEARCH_TERM_OPTION_VALUE = '#search_value#'
export const CURRENT_ENTITY_OPTION_VALUE = '#current_value#'

export type AutoCompleteItem = ItemType<any> & {
  description?: string
  custom?: boolean
  extraData?: any
}

export type ManagedAutoCompleteInputProps = {
  formObj: UseFormReturn<any>
  name: string
  dataSet: AutoCompleteItem[]
  textCaptureFieldName?: string
  allowFreeText?: boolean
  unknownItemUseDescription?: string
  unknownItemSelectedDescription?: string
  required?: boolean
  label?: string
  modalTitle?: string
  placeholder?: string
  searchPlaceholder?: string
  style?: TextStyle
  selectOnlyMode?: boolean
  disabled?: boolean
  informationMessage?: string
  informationMessageIsError?: boolean
  // useImageSource?: boolean
  // imageSourceFallbackIconName?: string
  dataTriggerFunction?: Function
  dataTriggerFunctionDebounceMs?: number
  selectTriggerFunction?: Function
  isLoading?: boolean
  minSearchTermLength?: number
  searchTermRequiredMessage?: string
  noResultsMessage?: string
}

const INPUT_HEIGHT = 64

export const isSpecialValue = (value: string) => {
  return [CURRENT_SEARCH_TERM_OPTION_VALUE, CURRENT_ENTITY_OPTION_VALUE].includes(value)
}

const isWeb = platformIsWeb()

export const ManagedAutoCompleteInput = (props: ManagedAutoCompleteInputProps) => {
  const { isLoading, dataTriggerFunction, dataTriggerFunctionDebounceMs, selectTriggerFunction, formObj, name, textCaptureFieldName, allowFreeText, unknownItemUseDescription, unknownItemSelectedDescription, label, modalTitle, informationMessage, informationMessageIsError, placeholder, style, selectOnlyMode, required, dataSet, disabled, searchPlaceholder, minSearchTermLength, searchTermRequiredMessage, noResultsMessage } = props
  const { control, trigger, watch, setValue, clearErrors, formState: { errors, isValid } } = formObj
  const { colors: themeColors } = Paper.useAppTheme()

  const value = watch(name)

  const useRemoteData = !!dataTriggerFunction
  const addCustomItem = allowFreeText && !useRemoteData

  //State
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)
  const [items, setItems] = useState<AutoCompleteItem[]>(dataSet)
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [debouncedSearchText] = useDebounce(searchText, dataTriggerFunctionDebounceMs || DEFAULT_DEBOUNCE_MS)

  const updateMatchingItems = useCallback(async q => {
    const matches = !q || q === '' ? dataSet : dataSet.filter((item: AutoCompleteItem) => {
      const combined = item.description ? `${item.label} ${item.description}` : item.label
      return combined.toLowerCase().includes(q.toLowerCase())
    })

    if (
      (matches.length === 0 ||
        matches.findIndex(
          (item) =>
            String(item[label]).toLowerCase() ===
            q.toLowerCase(),
        ) === -1) &&
      addCustomItem
    ) {
      matches.push({
        label: q,
        value: CURRENT_SEARCH_TERM_OPTION_VALUE,
        custom: true,
      });
    }

    setItems(matches)
  }, [])
  

  //Set selectedValue when value changes
  useEffect(() => {
    setSelectedValue(value)
    trigger(name)
  }, [value])

  //Call data trigger function after debounce
  useEffect(() => {
    if (dataTriggerFunction) {
      dataTriggerFunction(debouncedSearchText)
    }
  }, [debouncedSearchText])

  //Filter items on search text change
  useEffect(() => {
    if (!dataTriggerFunction) {
      updateMatchingItems(searchText)
    }
  }, [searchText])

  const startingItem = dataSet ? dataSet.find(item => {
    return item?.value === value
  }) : undefined
  const [selectedItem, setSelectedItem] = useState<AutoCompleteItem>(startingItem)

  //Update loading from parent
  useEffect(() => {    
    setLoading(isLoading)
  }, [isLoading])

  //Update state when dataSet changes
  useEffect(() => {
    if (useRemoteData) {
      setItems(dataSet)
    }
  }, [dataSet])

  const openModal = () => {
    setOpen(true)
  }

  //Function to check if the item is from the list
  const isFromList = (currentValue: string) => {
    const match = currentItem(currentValue)
    const result = match
      ? true
      : useRemoteData
        ? `Please search for an option`
        : `Please pick from the available options`
    return result
  }

  //Function to check if the item has any value, custom or real
  const hasValue = () => {
    const result = textCaptureFieldName && watch(textCaptureFieldName) !== ''
    return result
  }

  //Function to get the value from the data set
  const currentItem = (currentValue: string) => {
    return dataSet ? dataSet.find(item => {
      return item?.value === currentValue
    }) : undefined
  }

  const customValidatorObj: any = required
    ? textCaptureFieldName && allowFreeText
      ? { hasValue }
      : { isFromList }
    : {}

  //Derive the most relevant informationMessage
  let infoMessage = informationMessage
  if (!infoMessage) {
    if (textCaptureFieldName && selectedItem) {
      if (selectedItem?.custom !== undefined || selectedItem?.value === CURRENT_SEARCH_TERM_OPTION_VALUE) {
        infoMessage = unknownItemSelectedDescription || `Custom value`
      } else {
        infoMessage = selectedItem?.description
      }
    }
  }

  const clearValue = () => {
    setSelectedValue(null)
    setSelectedItem(null)
    updateFormValue(null)
    setItems(useRemoteData ? []  : dataSet)
  }

  const isCustomOrSpecialItem = (selectedItem: AutoCompleteItem ) => {
    return selectedItem?.custom !== undefined || isSpecialValue(selectedItem?.value)
  }
  
  //Update form state when selectedItem changes
  const updateFormValue = (item?: AutoCompleteItem) => {
    //Update field and trigger validation
    const fieldValue = item ? item?.value : null
    setValue(name, fieldValue, { shouldDirty: true, shouldValidate: true })
    trigger(name)

    //Update text field and trigger validation, if needed
    if (textCaptureFieldName) {
      const textFieldValue = textCaptureFieldName ? item?.label : null
      setValue(textCaptureFieldName, textFieldValue, { shouldDirty: true, shouldValidate: true })
      trigger(textCaptureFieldName)
    }


    //Call custom function with selectedItem, if provided
    if (selectTriggerFunction) {
      selectTriggerFunction(item)
    }
  }

  const renderListItem = ({
    rtl,
    item,
    label,
    value,
    parent,
    selectable,
    disabled,
    props,
    custom,
    isSelected,
    IconComponent,
    TickIconComponent,
    listItemContainerStyle,
    listItemLabelStyle,
    listChildContainerStyle,
    listParentContainerStyle,
    listChildLabelStyle,
    listParentLabelStyle,
    customItemContainerStyle,
    customItemLabelStyle,
    selectedItemContainerStyle,
    selectedItemLabelStyle,
    disabledItemContainerStyle,
    disabledItemLabelStyle,
    containerStyle,
    labelStyle,
    categorySelectable,
    onPress,
    setPosition,
    THEME,  
  }) => {
    /**
     * The tick icon component.
     * @returns {JSX|null}
     */
    const _TickIconComponent = useMemo(() => isSelected && (
      <TickIconComponent />
    ), [isSelected, TickIconComponent]);

    /**
     * The list category container style.
     * @returns {object}
     */
    const _listParentChildContainerStyle = useMemo(() => (parent !== null ? [
      THEME.listChildContainer,
      ...[listChildContainerStyle].flat()
    ] : [
      THEME.listParentContainer,
      ...[listParentContainerStyle].flat()
    ]), [THEME, rtl, listChildContainerStyle, listParentContainerStyle, parent])

    /**
     * The selected item container style.
     * @returns {object}
     */
    const _selectedItemContainerStyle = useMemo(() => isSelected && selectedItemContainerStyle, [isSelected, selectedItemContainerStyle]);

    /**
     * The disabled item container style.
     * @returns {object}
     */
    const _disabledItemContainerStyle = useMemo(() => disabled && disabledItemContainerStyle, [disabled, disabledItemContainerStyle]);

    /**
     * The custom container item style.
     * @returns {JSX}
     */
    const _customItemContainerStyle = useMemo(() => custom && ([
      THEME.customItemContainer,
      ...[customItemContainerStyle].flat()
    ]), [THEME, custom, customItemContainerStyle]);

    /**
     * The list item container style.
     * @returns {object}
     */
    const _listItemContainerStyle = useMemo(() => ([
      ...[listItemContainerStyle].flat(),
      ...[_listParentChildContainerStyle].flat(),
      ...[containerStyle].flat(),
      ...[_selectedItemContainerStyle].flat(),
      ...[_customItemContainerStyle].flat(),
      ...[_disabledItemContainerStyle].flat(),
    ]), [listItemContainerStyle, _listParentChildContainerStyle, _selectedItemContainerStyle, _customItemContainerStyle, _disabledItemContainerStyle, containerStyle]);

    /**
     * The list category label style.
     * @returns {object}
     */
    const _listParentChildLabelStyle = useMemo(() => (parent !== null ? [
      THEME.listChildLabel,
      ...[listChildLabelStyle].flat(),
    ] : [
      THEME.listParentLabel,
      ...[listParentLabelStyle].flat(),
    ]), [THEME, listChildLabelStyle, listParentLabelStyle, parent]);

    /**
     * The selected item label style.
     * @returns {object}
     */
    const _selectedItemLabelStyle = useMemo(() => isSelected && selectedItemLabelStyle, [isSelected, selectedItemLabelStyle]);

    /**
     * The disabled item label style.
     * @returns {object}
     */
    const _disabledItemLabelStyle = useMemo(() => disabled && disabledItemLabelStyle, [disabled, disabledItemLabelStyle]);

    /**
     * The custom label item style.
     * @returns {JSX}
     */
    const _customItemLabelStyle = useMemo(() => custom && ([
      THEME.customItemLabel,
      ...[customItemLabelStyle].flat()
    ]), [THEME, custom, customItemLabelStyle]);

    /**
     * The list item label style.
     * @returns {object}
     */
    const _listItemLabelStyle = useMemo(() => ([
      ...[listItemLabelStyle].flat(),
      ...[_listParentChildLabelStyle].flat(),
      ...[labelStyle].flat(),
      ...[_selectedItemLabelStyle].flat(),
      ...[_customItemLabelStyle].flat(),
      ...[_disabledItemLabelStyle].flat(),
    ]), [listItemLabelStyle, _listParentChildLabelStyle, _selectedItemLabelStyle, _customItemLabelStyle, _disabledItemLabelStyle, labelStyle]);

    /**
     * onPress.
     */
    const __onPress = useCallback(() => {
      if (parent === null && !categorySelectable && selectable !== true) {
        return;
      }

      onPress(item, custom);
    }, [onPress, parent, categorySelectable, custom]);

    /**
     * onLayout.
     */
    const onLayout = useCallback(({ nativeEvent: { layout: { y } } }) => {
      setPosition(value, y);
    }, [value]);

    const { description, icon } = item || {}

    return (
      <TouchableOpacity style={_listItemContainerStyle} onPress={__onPress} onLayout={onLayout} {...props} disabled={selectable === false || disabled} testID={item.testID}>
        {IconComponent}
        <Pressable
          onPress={__onPress}
          style={{
            flexGrow: 1,
            ...Flex.column.center,
          }}
        >
          <Subheading style={{
            ...Typography.defined.input,
            color: Colors.neutral.black,
            textAlign: icon ? 'left' : 'center',
            // textAlign: 'left',
          }}>{isCustomOrSpecialItem(item) ? `'${label}'` : label}</Subheading>
          {
            description || custom
              ? <Text
                  style={{
                    ...Typography.defined.inputFooter,
                    textAlign: icon ? 'left' : 'center',
                    color: themeColors.disabled,
                    paddingBottom: Sizing.x5,
                    paddingLeft: Sizing.x5,
                    // textAlign: 'left',
                  }}
                >
                  {custom ? unknownItemUseDescription || 'Use this value' : description}
                </Text>
            : <></>
          }
        </Pressable>
        {_TickIconComponent}
      </TouchableOpacity>
    );
  }

  const webModalContainerStyle: StyleProp<ViewStyle> = isWeb ? {
    width: getScreenAppWidth() + Sizing.x10,
    height: getScreenAppHeight() - Sizing.x20,
    alignSelf: 'center',
    borderRadius: Sizing.x10,
    marginTop: Sizing.x10,
    marginBottom: Sizing.x10,
    borderColor: Colors.brand.grey2,
    borderWidth: Sizing.x1,
  } : {}

  return (
    <View style={[
      layoutStyles.inputContainer,
      label ? { paddingTop: Sizing.x15 } : {}
    ]}>
      <Controller
        control={control}
        rules={{
          validate: customValidatorObj,
        }}
        render={({ field: { onChange, onBlur, value, } }) => {
          return (
            <>
              {
                label ? <Paragraph style={{
                  ...Typography.defined.inputLabel,
                  color: applyOpacity(themeColors.text, 0.7),
                  position: 'absolute',
                  top: Sizing.x10,
                  left: Sizing.x5,
                }}>{label}</Paragraph> : <></>
              }
              <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                borderWidth: 0,
                borderBottomWidth: disabled ? 0 : Sizing.x1,
                borderBottomColor: applyOpacity(themeColors.disabled, 0.8),
              }}>
                <View style={{ flex: 1 }}>
                  <DropDownPicker
                    //Core settings
                    listMode={'MODAL'}
                    dropDownDirection={'BOTTOM'}
                    renderListItem={renderListItem}
                    addCustomItem={addCustomItem}
                    searchable={!selectOnlyMode}
                    disableLocalSearch={true} //Handled by updateMatchingItems which searches label and description
                    disabled={disabled}
                    scrollViewProps={{
                      nestedScrollEnabled: true,
                    }}
                    loading={loading}
                    //Core state control
                    open={open}
                    value={selectedValue}
                    items={items}
                    setOpen={setOpen}
                    setValue={setSelectedValue}
                    setItems={setItems}
                    onOpen={() => {}}
                    // onClose={() => {
                    //   if (selectedItem) {
                    //     //Check for existence of custom key - seems to be false here for some reason?
                    //     onChange(selectedItem?.custom !== undefined ? null : selectedItem?.value)
                    //     if (selectTriggerFunction) {
                    //       selectTriggerFunction(selectedItem)
                    //     }
                    //   } else {
                    //     onChange(null)
                    //   }
                    // }}
                    //Integration with useForm
                    onSelectItem={(item) => {
                      setSelectedItem(item)
                      updateFormValue(item)
                      setSelectedValue(item?.value)
                    }}
                    onChangeSearchText={(text) => {
                      setSearchText(text)
                    }}

                    //Translation
                    translation={{
                      NOTHING_TO_SHOW: searchText?.length >= minSearchTermLength
                      ? noResultsMessage || `No matching results`
                      : searchTermRequiredMessage || `Please tap above to search`
                    }}

                    //Modal and styling
                    modalTitle={modalTitle || 'Please choose...'}
                    modalProps={{
                      transparent: isWeb ? true : false,
                    }}
                    modalContentContainerStyle={{
                      backgroundColor: Colors.brand.grey4,
                      ...webModalContainerStyle,
                    }}
                    modalTitleStyle={{
                      ...Typography.defined.processHeaderTitle,
                      paddingLeft: Sizing.x30,
                    }}
                    modalAnimationType={'fade'}

                    //Placeholder and styling
                    placeholder={placeholder}
                    searchPlaceholder={searchPlaceholder || 'Type to search...'}
                    placeholderStyle={{
                      color: themeColors.disabled,
                      ...Typography.defined.input,
                    }}

                    //Main input component styling
                    showArrowIcon={false}
                    style={{
                      height: Sizing.x60,
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                    }}
                    labelStyle={{
                      ...Typography.defined.input,
                      fontWeight: '200',
                      color: disabled ? applyOpacity(themeColors.inputText, 0.5) : themeColors.inputText,
                    }}
                    labelProps={{
                      numberOfLines: 1,
                    }}
                    //List item styling
                    listItemContainerStyle={{
                      height: Sizing.x50
                    }}
                    itemSeparator={true}
                    itemSeparatorStyle={{
                      backgroundColor: Colors.brand.grey3,
                    }}
                    //Search styling
                    searchContainerStyle={{
                      height: Sizing.x80
                    }}
                    searchTextInputProps={{
                      autoCorrect: false,
                      autoCapitalize: 'words',
                    }}
                    searchTextInputStyle={{
                      borderWidth: 0,
                      ...Typography.defined.input,
                      height: INPUT_HEIGHT,
                    }}
                  />
                </View>
                <View style={{ width: Sizing.x30, marginRight: scaleNormalizer(4), flexDirection: 'column', justifyContent: 'center' }}>
                  {
                    disabled
                    ? <></>
                    : selectedItem
                      ? <MaterialCommunityIcons name='close-circle' size={Sizing.x20} color={themeColors.primary} onPress={disabled ? undefined : clearValue }/>
                      : selectOnlyMode
                        ? <Ionicons name={'caret-down'} size={Sizing.x20} color={themeColors.primary}  onPress={openModal}/>
                        : <MaterialIcons name='search' size={Sizing.x20} color={themeColors.primary} onPress={openModal}/>
                  }                  
                </View>
              </View>
              <InputErrorMessages
                formObj={formObj}
                name={name}
                informationMessage={infoMessage}
                informationMessageIsError={informationMessage ? informationMessageIsError : false}
              />
            </>
          )
        }}
        name={name}
      />
    </View>
  )
}
