import React, { ComponentProps, forwardRef, useEffect, useState } from 'react'
import { View } from 'react-native'
import PaginationDot from 'react-native-animated-pagination-dot'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import Carousel from 'react-native-reanimated-carousel'
import { Flex, Paper, Sizing } from 'styles'
import { Loading } from './Loading'

export type ManagedCarouselProps = ComponentProps<typeof Carousel> & {
  onCompletion?: any
  hidePagination?: boolean
  height?: number
  isLoading?: boolean
  isFetching?: boolean
}

export const ManagedCarousel = forwardRef((props: ManagedCarouselProps, ref: any ) => {
  const { onCompletion, hidePagination, data, height, width, loop, isLoading, isFetching } = props
  
  const [carouselComplete, setCarouselComplete] = useState(false)

  const [activeSlide, setActiveSlide] = useState(0)

  const { colors: themeColors } = Paper.useAppTheme()

  //Keep track of when last slide is seen
  useEffect(() => {
    if (activeSlide === data.length - 1) {
      setCarouselComplete(true)
    }
  }, [activeSlide])

  //Call onCompletion 
  useEffect(() => {
    if (carouselComplete && onCompletion) {
      onCompletion()
    }
  }, [carouselComplete])
  
  return (
    <>
    <View style={{
        ...Flex.column.start,
        ...Flex.override.fill,
        alignItems: 'center',
      }}>
      <View style={{
        height: height || Sizing.x400,
        width,
      }}>
        { isLoading || isFetching
          ? <Loading size={Sizing.x20} />
          : <GestureHandlerRootView>
              <Carousel
                {...props}
                loop={loop || false}
                ref={ref}
                onSnapToItem={index => setActiveSlide(index)}
                panGestureHandlerProps={{
                  activeOffsetX: [-10, 10],
                }}
              />
            </GestureHandlerRootView>
        }
      </View>
      <View style={{
        ...Flex.override.bottom,
        ...Flex.row.center,
        paddingVertical: Sizing.x20,
        opacity: isLoading || isFetching ? 0 : 1,
      }}>
        {
          data.length > 1 ?
          <PaginationDot
              activeDotColor={themeColors.accent}
              inactiveDotColor={themeColors.disabled}
              curPage={activeSlide}
              maxPage={data.length}
          />
          : <></>
        }
      </View>
    </View>
  </>
  )
})