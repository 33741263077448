import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { differenceInYears } from 'date-fns'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { pick, startCase, uniqBy } from 'lodash'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useGetMeQuery, useGetSpouseQuery, useGetSpouseRetirementProfileQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { Colors, Flex, Sizing, Typography } from 'styles'

export const RetirementProfileSetup_00_CopyChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const [showDialog, setShowDialog] = React.useState(false)
  const [showDeclineDialog, setShowDeclineDialog] = React.useState(false)

  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  //Load user
  const { data: me, isLoading: meIsLoading, error: meError, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, error: spouseError, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: spouseRp, error: spouseRpError, isLoading: spouseRpIsLoading, isFetching: spouseRpIsFetching, refetch: refetchSpouseRp } = useGetSpouseRetirementProfileQuery()

  const refetchAll = () => {
    refetchMe()
    refetchSpouse()
    refetchSpouseRp()
  }

  //Refetch on enter (in case spouses doing in parallel)
  useEffect(() => {
    refetchSpouse()
    refetchSpouseRp()
  }, [])

  const isLoading = meIsLoading || spouseIsLoading || spouseRpIsLoading || spouseIsFetching || spouseRpIsFetching
  const error: any = meError || spouseError
  
  const adjustSpouseTargetRetirementAge = (age: number, me: ClientBaseDto, spouse: ClientBaseDto) => {
    const clientBirthDate = new Date(me?.birthDate)
    const spouseBirthDate = new Date(spouse?.birthDate)
    return age - differenceInYears(clientBirthDate, spouseBirthDate)
  }

  const adjustedTargetRetirementAge = adjustSpouseTargetRetirementAge(spouseRp?.targetRetirementAge, me, spouse)

  const handleDeclineCopy = () => {
    setShowDeclineDialog(false)
    dispatch(updateWorkingRetirementProfile({
      copyChoice: false,
    }))
    navigation.navigate(nextScreen)
  }  

  const onSubmit = () => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementProfile({
      copyChoice: true,
      ...pick(spouseRp, [
        'asCouple',
        'insideLondon',
        'expenses',
        'baseBudgetLevel'
      ]),
      targetRetirementAge: adjustedTargetRetirementAge
    }))
    navigation.navigate('Results')
  }

  const spouseHasMixedBudget = spouseRp && uniqBy(spouseRp?.expenses, 'selectedLevel').length > 1

  const spouseRpTableData: UnborderedTableRow[] = spouse && spouseRp ? [
    {
      label: `Retiring in London?`,
      value: spouseRp?.insideLondon ? 'Yes' : 'No'
    },
    {
      label: `Retiring as a Couple?`,
      value: spouseRp?.asCouple ? 'Yes' : 'No'
    },
    {
      label: `Budget Lifestyle`,
      value: spouseHasMixedBudget ? 'Custom' : startCase(spouseRp?.baseBudgetLevel)
    },
    {
      label: <Text style={{color: Colors.brand.red3, ...Typography.fontWeight.bold }}>{`Annual Budget Amount`}</Text>,
      value: <Text style={{color: Colors.brand.red3, ...Typography.fontWeight.bold }}>{formatCurrencyAmount(spouseRp?.expensesNetTotalAmount)}</Text>
    },
  ] : []

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={refetchAll}
      isLoading={isLoading}
      headline={spouseRp
        ? `${spouse?.firstName} has already set up a retirement profile`
        : `About Retirement Profile Alignment`
      }
      subHeading={spouseRp
        ? `They said you wanted to retire at the following ages`
        : `${spouse?.firstName} has not yet set up their retirement profile and budget`
      }
      buttonTitle={spouseRp ? `Copy Retirement Profile` : 'Next'}
      buttonAction={spouseRp ? () => setShowDialog(true) : () => navigation.navigate(nextScreen)}
      enableButton={true}
      allowTextButton={!!spouseRp}
      textButtonTitle={'Set up separately instead'}
      textButtonAction={() => setShowDeclineDialog(true)}
    >
      { spouseRp ?
        <>
          <View style={{
            alignContent: 'center',
            ...Flex.row.around,
            paddingHorizontal: Sizing.x10,
          }}>
            <View>
              <Subheading>{'You'}</Subheading>
              <Headline style={{ color: Colors.brand.red3 }}>{adjustedTargetRetirementAge}</Headline>
            </View>
            <View>
              <Subheading>{spouse?.firstName || 'Partner'}</Subheading>
              <Headline style={{ color: Colors.brand.red3 }}>{spouseRp?.targetRetirementAge}</Headline>
            </View>
          </View>
          <UnborderedTable
            data={spouseRpTableData}
          />
          <Paragraph>{`We recommend copying this profile to keep your forecast aligned with ${spouse?.firstName}. Alternatively you can set up a separate profile.`}</Paragraph>
          {/* <Paragraph>{`${spouse?.firstName} said you are planning on retiring ${spouseRp?.asCouple ? 'as a couple' : `independently`}, ${spouseRp?.insideLondon ? `in London` : `somewhere other than London`}, when they are aged ${spouseRp?.targetRetirementAge} and you would be ${adjustedTargetRetirementAge}`}</Paragraph>
          <Paragraph>{`They ${spouseHasMixedBudget ? 'set up a custom budget' : `chose the '${startCase(spouseRp?.baseBudgetLevel)}' budget level`}, with a total annual amount of:`}</Paragraph>
          <Headline>{formatCurrencyAmount(spouseRp?.expensesNetTotalAmount)}</Headline> */}
          <ConfirmationDialog
            visible={showDialog}
            onCancel={() => setShowDialog(false)}
            title={'Please confirm'}
            content={`Copy the retirement profile and budget that ${spouse?.firstName} already set up. I will change it later if needed.`}
            onConfirm={onSubmit}
          />
          <ConfirmationDialog
            visible={showDeclineDialog}
            onCancel={() => setShowDeclineDialog(false)}
            title={'Please confirm'}
            content={`I want to set up my own retirement profile. I understand that my financial forecasts may not align with ${spouse?.firstName}'s.`}
            onConfirm={handleDeclineCopy}
          />
        </>
        :
        <>
          <Paragraph>{`We recommend working together to define the lifestyle you are looking for in retirement.`}</Paragraph>
          <Paragraph>{`Once you have set up your retirement profile, ${spouse?.firstName} will be offered the choice to copy your budget when they set up theirs.`}</Paragraph>
        </>
      }
    </ProcessScreen>
  )
}
