import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Text } from 'components/Typography/Text'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { NotificationDot } from 'components/Utility/NotificationDot'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { useSuggestionsContext } from 'providers/suggestions.context'
import React from 'react'
import {
  StatusBar, StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useGetMeQuery } from 'store/apiSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { TabBarWithTimeline } from './Components/TabBarWithTimeline'
import { DashboardLegacy } from './Screens/DashboardLegacy'
import { DashboardRetirement } from './Screens/DashboardRetirement'
import { DashboardToday } from './Screens/DashboardToday'

const Tab = createBottomTabNavigator()
const TAB_ICON_SIZE = scaleNormalizer(25)

export const getTodayTabIcon = ({ focused, color, size }) => {
  return getTabIcon('calendar-today', focused)
}
export const getRetirementTabIcon = ({ focused, color, size }) => {
  return getTabIcon('nature-people', focused)
}
export const getLegacyTabIcon = ({ focused, color, size }) => {
  return getTabIcon('grave-stone', focused)
}

export const getTabIcon = (name, focused) => {
  const iconName = tabIcons[name]
  return (
    <MaterialCommunityIcons
      name={iconName}
      size={TAB_ICON_SIZE}
      color={focused ? Colors.brand.purple2 : Colors.brand.grey3}
    />
  )
}


const tabIcons = {
  [MainAppNavScreen.DASHBOARD_TODAY]: 'calendar-today',
  [MainAppNavScreen.DASHBOARD_RETIREMENT]: 'nature-people',
  [MainAppNavScreen.DASHBOARD_LEGACY]: 'grave-stone'
}

const tabIndexMap: MainAppNavScreen[] = [
  MainAppNavScreen.DASHBOARD_TODAY,
  MainAppNavScreen.DASHBOARD_RETIREMENT,
  MainAppNavScreen.DASHBOARD_LEGACY
]

// Some config
export const Dashboard = ({ navigation, route }) => {
  const { screen } = route?.params || {}
  const { data: client, error: clientError, isLoading: clientIsLoading } = useGetMeQuery()

  const { getSuggestionCountPerScreen } = useSuggestionsContext()

  const isLoading = clientIsLoading
  const error: any = clientError

  // //Tab Icons
  // function getTodayTabIcon({ focused, color, size }) {
  //   return getTabIcon('calendar-today', focused)
  // }
  // function getRetirementTabIcon({ focused, color, size }) {
  //   return getTabIcon('nature-people', focused)
  // }
  // function getLegacyTabIcon({ focused, color, size }) {
  //   return getTabIcon('grave-stone', focused)
  // }

  // function getTabIcon(name, focused) {
  //   const iconName = tabIcons[name]
  //   return (
  //     <MaterialCommunityIcons
  //       name={iconName}
  //       size={TAB_ICON_SIZE}
  //       color={focused ? Colors.brand.purple2 : Colors.brand.grey3}
  //     />
  //   )
  // }

  function TabBar({ state, descriptors, navigation, notifications }) {
    const insets = useSafeAreaInsets()
    return (
      <TabBarWithTimeline
        state={state}
        navigation={navigation}
        descriptors={descriptors}
        notifications={notifications}
        client={client}
        tabIndex={state?.index}
        tabIndexMap={tabIndexMap}
      />
      // <View>
      //   <TimelineWindow
      //     client={client}
      //     tabIndex={state?.index}
      //     tabIndexMap={tabIndexMap}
      //   />
      //   <View style={[localStyles.tabBarContainer, {
      //     paddingBottom: Sizing.x10 + insets.bottom,
      //   }]}>
      //     {state.routes.map((route, index) => {
      //       const { options } = descriptors[route.key]
      //       const label =
      //         options.tabBarLabel !== undefined
      //           ? options.tabBarLabel
      //           : options.title !== undefined
      //             ? options.title
      //             : route.name

      //       const notificationCount = notifications[route.name]

      //       const isFocused = state?.index === index

      //       const onPress = () => {
      //         const event = navigation.emit({
      //           type: 'tabPress',
      //           target: route.key,
      //           canPreventDefault: true
      //         })

      //         if (!isFocused && !event.defaultPrevented) {
      //           // The `merge: true` option makes sure that the params inside the tab screen are preserved
      //           navigation.navigate({ name: route.name, merge: true })
      //         }
      //       }

      //       const onLongPress = () => {
      //         navigation.emit({
      //           type: 'tabLongPress',
      //           target: route.key
      //         })
      //       }

      //       return (
      //         <TouchableOpacity
      //           key={index}
      //           accessibilityRole="button"
      //           accessibilityState={isFocused ? { selected: true } : {}}
      //           accessibilityLabel={options.tabBarAccessibilityLabel}
      //           testID={options.tabBarTestID}
      //           onPress={onPress}
      //           onLongPress={onLongPress}
      //           style={{ flex: 1 }}>
      //           <View style={{ alignItems: 'center' }}>
      //             {notificationCount ?
      //               <NotificationDot
      //                 top={scaleNormalizer(-5)}
      //                 left={scaleNormalizer(65)}
      //                 count={notificationCount}
      //               />
      //               : <></>
      //             }
      //             {getTabIcon(route.name, isFocused)}
      //           </View>
      //           <Text
      //             style={[
      //               localStyles.tabBarLabel,
      //               {
      //                 color: isFocused
      //                   ? Colors.brand.purple2
      //                   : Colors.brand.grey3
      //               }
      //             ]}>
      //             {label}
      //           </Text>
      //         </TouchableOpacity>
      //       )
      //     })}
      //   </View>
      // </View>
    )
  }

  const isWeb = platformIsWeb()

  const notifications = getSuggestionCountPerScreen()

  return (
    <ThemeProvider theme={isWeb ? Paper.darkTheme : Paper.lightTheme}>
      <View style={localStyles.dashboardContainer}>
        <StatusBar barStyle={'light-content'} />
        {isLoading ? <Loading message={['Preparing your dashboard...']} useHolidayGraphics={true} /> : error ? <ErrorScreen noDashboardButton={true} error={error?.data} />
          : <Tab.Navigator
            id="Dashboard"

            initialRouteName={screen ? screen : MainAppNavScreen.DASHBOARD_TODAY}
            tabBar={props => <TabBar {...props} notifications={notifications} />}>
            <Tab.Group
              screenOptions={{
                lazy: false,
                headerShown: false,
                tabBarLabelStyle: localStyles.tabBarLabel,
                tabBarActiveTintColor: Colors.brand.purple2,
                tabBarInactiveTintColor: Colors.brand.grey2,
                tabBarItemStyle: localStyles.tabBarItem,
                tabBarStyle: localStyles.tabBar
              }}>
              <Tab.Screen
                name={MainAppNavScreen.DASHBOARD_TODAY}
                component={DashboardToday}
                options={{
                  tabBarLabel: 'Today',
                  tabBarIcon: getTodayTabIcon,
                }}
              />
              <Tab.Screen

                name={MainAppNavScreen.DASHBOARD_RETIREMENT}
                component={DashboardRetirement}
                options={{
                  tabBarLabel: 'Retirement',
                  tabBarIcon: getRetirementTabIcon
                }}
              />
              <Tab.Screen
                name={MainAppNavScreen.DASHBOARD_LEGACY}
                component={DashboardLegacy}
                options={{
                  tabBarLabel: 'Legacy',
                  tabBarIcon: getLegacyTabIcon
                }}
              />
            </Tab.Group>
          </Tab.Navigator>
        }
      </View>
    </ThemeProvider>
  )
}

const localStyles = StyleSheet.create({
  dashboardContainer: {
    ...Flex.column.start,
    backgroundColor: Colors.brand.purple0,
  },
  tabBarLabel: {
    ...Typography.defined.dashboardTabLabel
  },
  tabBar: {
    backgroundColor: Colors.brand.grey4,
    height: Sizing.x70,
  },
  tabBarItem: {},
  // tabBarContainer: {
  //   zIndex: 998,
  //   backgroundColor: Colors.brand.grey4,
  //   flexDirection: 'row',
  //   paddingTop: Sizing.x15,
  // },
})
