import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetSpouseQuery, useGetStateBenefitQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const RetirementIncomeSetup_01_StatePensionChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: stateBenefit, error: stateBenefitError, isLoading: stateBenefitIsLoading, refetch: refetchBenefits } = useGetStateBenefitQuery({
    birthDate: me?.birthDate,
    gender: me?.gender,
  }, {
    skip: !me
  })

  const refetchAll = () => {
    refetchMe()
    refetchSpouse()
    refetchBenefits()
  }

  const isLoading = meIsLoading || spouseIsLoading || stateBenefitIsLoading
  const error: any = meError || stateBenefitError

  const getBestChoice = () => {
    return me?.statePensionAmount === stateBenefit?.maxAmount ? 'full' : me?.statePensionAmount ? 'partial' : undefined
  }

  //Setup form
  const formObj = useForm<{ statePensionChoice: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionChoice: workingClientData?.statePensionChoice || getBestChoice()
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  useEffect(() => {
    if (me && stateBenefit && !workingClientData?.statePensionChoice) {
      setValue('statePensionChoice', getBestChoice(), { shouldDirty: true })
    }
  }, [me, stateBenefit])
  
  const onSubmit = attributes => {
    const statePensionChoice = attributes.statePensionChoice
    const statePensionAmount =
      statePensionChoice === 'full'
        ? stateBenefit?.maxAmount
        : statePensionChoice === 'partial'
          ? undefined
          : 0
    dispatch(updateWorkingClient({
      statePensionChoice,
      statePensionAmount,
    }))
    //Navigate based on choice
    if (statePensionChoice === 'partial') {
      navigation.navigate(nextScreen) //Go to next screen to customise
    } else {
      if (spouse && !spouse?.userId) {
        navigation.navigate('SpouseStatePensionChoice') //Skip to spouse choice
      } else {
        navigation.navigate('StatePensionSave') //Skip to save
      }
    } 
  }

  const statePensionOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 'full',
      label: `Full amount (${formatCurrencyAmount(stateBenefit?.maxAmount)} p.a.)`
    },
    {
      value: 'partial',
      label: `Yes, but only some`
    },
    {
      value: 'none',
      label: `No`,
    },
  ]

  const forecastUrl = 'https://www.gov.uk/check-state-pension'

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={refetchAll}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Do you expect to get a UK state pension?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.STATE_PENSION} buttonTitle={'About State Pensions'}/>}
    >
      <ManagedSimpleChoiceInput
        name={'statePensionChoice'}
        formObj={formObj}
        options={statePensionOptions}
        required={true}
        submitHandler={handleSubmit(onSubmit)}
      />
      <Paragraph>{`The annual amount you could receive depends on your National Insurance contribution history. The amount and conditions are set by the UK Government and policy may change before or after retirement.`}</Paragraph>
      <Paragraph>{`A free online service exists to check your personal forecast`}</Paragraph>
      <ExternalLinkButton directToBrowser={true} url={forecastUrl}>{`State Pension Forecast Service`}</ExternalLinkButton>
    </ProcessScreen>
  )
}
