import React from 'react'
import { Text } from "react-native-paper"
import { Typography } from "styles"
import { TypographyProps } from './typography.props'

export const MainAppSubHeaderTitle = (props: TypographyProps) => {

  const styles = [
    Typography.defined.mainAppSubHeaderTitle,
    props.style,
  ]

  return (
    <Text {...props} allowFontScaling={false} style={styles}>
      {props.children}
    </Text>
  )
}