import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { useGetMeQuery } from 'store/apiSlice'
import { Typography } from 'styles'

export const ClientSetupHeroScreen = ({ route }) => {
  const { onFinish } = route?.params || {}

  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery()
  const isLoading = meIsLoading
  const error: any = meError

  const clientBirthDate = new Date(me?.birthDate)
  const lastSurvivorDeathDate = new Date(me?.lastSurvivorDeathDate)

  const end = () => {
    if (onFinish) {
      onFinish()
    }
    goToMainAppStack()
  }

  const carouselScreens: ProcessHeroCarouselData[] = [
      {
        illustrationFilename: 'retirement_timeline.png',
        headline: `Life Expectancy`,
        subHeading:
          <Subheading>
            {`Based on your age and gender, your life expectancy is around `}
            <Subheading style={Typography.fontWeight.bold}>{`${me?.lastSurvivorDeathAge} years!`}</Subheading>
          </Subheading>,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
      },
      {
        illustrationFilename: 'capture_retirement_income.png',
        headline: `UK State Pension`,
        subHeading:
          <Subheading>
            {`If you're eligible, you would receive your state pension from the UK government at `}
            <Subheading style={Typography.fontWeight.bold}>{`age ${me?.statePensionAge}`}</Subheading>
          </Subheading>,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.STATE_PENSION} buttonTitle={'About State Pensions'} />
      },
      {
        illustrationFilename: 'relax_for_now.png',
        headline: `Your Retirement`,
        subHeading:
          <Subheading>
            {`And if you retire at ${me?.statePensionAge}, based on your life expectancy you'll enjoy around `}
            <Subheading style={Typography.fontWeight.bold}>{`${me?.lastSurvivorDeathAge - me?.statePensionAge} years`}</Subheading>
            {` in retirement.`}
          </Subheading>,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
      },
      {
        illustrationFilename: 'control_your_financial_future.png',
        headline: `What's next?`,
        subHeading: `Jarvis can guide you every step of the way to take control of your future`,
      },
      {
        illustrationFilename: 'task_list.png',
        headline: `Let's get started!`,
        subHeading: `Head to the dashboard and open the first checklist to find out when you can achieve financial freedom`,
      },
    ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={end}
      finishButtonLabel={'Go to my Dashboard'}
    />
  )
}
