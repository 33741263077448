import { JAR_NAME_GROUP } from 'lib/constants'
import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { GroupPensionScreen } from './Screens/GroupPensionScreen'

export const GroupPensionSectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={JAR_NAME_GROUP}
    >
      <GroupPensionScreen />
    </SectionSimpleContainer>
  )
}