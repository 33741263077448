import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import { JAR_NAME_GROUP } from 'lib/constants'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { concat } from 'lodash'
import React from 'react'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { WorkingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentSetupHeroScreen = ({ route, navigation }) => {
  const { enrolment, requests }: { enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, requests: WorkingSchemeEnrolmentRequest[] } =  route?.params || {}

  const optOutRequest = requests ? requests.find(request => {
    return request.requestType === GroupSchemeRequestType.OPT_OUT
  }) : undefined

  const ceaseRequest = requests ? requests.find(request => {
    return request.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP
  }) : undefined

  const setContributionRequest = requests ? requests.find(request => {
    return request.requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
  }) : undefined

  const wasAdopted = !optOutRequest && !ceaseRequest

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const carouselScreens: ProcessHeroCarouselData[] = wasAdopted
    ? concat([
        {
          illustrationFilename: 'thanks.png',
          headline: `That's everything set up!`,
          subHeading: `Your employer will appear in your ${JAR_NAME_GROUP} screen`,
        },
        {
          illustrationFilename: 'record_an_existing_pension.png',
          headline: `Everything in one place`,
          subHeading: `Your ${JAR_NAME_GROUP} can receive contributions from many employers`,
        },
        {
          illustrationFilename: 'regular_contributions.png',
          headline: `See employer contributions`,
          subHeading: `When they run payroll, we'll let you as contributions arrive`,
        },
      ],
      setContributionRequest ? [
        {
          illustrationFilename: 'envelope_with_star.png',
          headline: `About your contribution request`,
          subHeading: `We've sent a request to your employer to set your pension contribution level`,
        },
        {
          illustrationFilename: 'clock.png',
          headline: `This may take time to set up`,
          subHeading: `Please contact your employer to check when the change will be made`,
        }
      ]: [
        {
          illustrationFilename: 'calculator.png',
          headline: `About employer contributions`,
          subHeading: `The contribution frequency and amount you receive depends on your employment contract `,
        }
      ]
      )
    : optOutRequest ? [
      {
        illustrationFilename: 'envelope_with_star.png',
        headline: `About your Opt Out decision`,
        subHeading: `We've notified your employer of your decision to opt out of their pension scheme`,
      },
      {
        illustrationFilename: 'simplify_your_retirement.png',
        headline: `Over to your employer`,
        subHeading: `They should action it in line with the UK Government's rules on Automatic Enrolment`,
      },
      {
        illustrationFilename: 'clock.png',
        headline: `About Re-enrolment`,
        subHeading: `Please note that your employer may be obliged to re-enrol you in the future`,
      },
      {
        illustrationFilename: 'support.png',
        headline: `If you change your mind...`,
        subHeading: `Please contact your employer for more information, or if you want to re-enrol`,
      },
    ]
    : ceaseRequest ? [
      {
        illustrationFilename: 'envelope_with_star.png',
        headline: `About your request to leave`,
        subHeading: `We've sent a request to your employer to cease your membership in the pension scheme`,
      },
      {
        illustrationFilename: 'simplify_your_retirement.png',
        headline: `Over to your employer`,
        subHeading: `They should action it in line with the UK Government's rules`,
      },
      {
        illustrationFilename: 'clock.png',
        headline: `About Re-enrolment`,
        subHeading: `Please note that your employer may be obliged to re-enrol you in the future`,
      },
      {
        illustrationFilename: 'support.png',
        headline: `If you change your mind...`,
        subHeading: `Please contact your employer for more information, or if you want to re-enrol`,
      },
    ]
    : [
      {
        illustrationFilename: 'thanks.png',
        headline: `Thanks!`,
        subHeading: `We've made those changes`,
      },
    ]

  return (
    <ProcessHeroScreen
      isLoading={accountsIsLoading}
      error={accountsError}
      errorTryAgain={refetchAccounts}
      carouselScreens={carouselScreens}
      onFinish={() => mainAppNavigate(MainAppNavScreen.GROUP_PENSION)}
      finishButtonLabel={'Go to Workplace Jar'}
    />
  )
}
