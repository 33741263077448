import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import { DrawerDescriptorMap, DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { DrawerNavigationState, ParamListBase } from '@react-navigation/native'
import { JarvisLogoType } from 'components/Brand/JarvisLogoType'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { AppContextSwitcher } from 'components/Utility/AppContextSwitcher'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { Button } from 'components/Utility/Button'
import { UserInfoModal } from 'components/Utility/UserInfoModal'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { truncate } from 'lodash'
import CustomDrawerItemList, { DrawerScreen } from 'navigation/components/CustomDrawerItemList'
import React, { useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import { Portal, ThemeProvider } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useGetCurrentUserQuery, useGetGroupSchemeJobsQuery, useGetGroupSchemePaymentsQuery, useGetGroupSchemeRequestsQuery, useGetMeGroupSchemesQuery } from 'store/apiSlice'
import { AppContext } from 'store/authSlice'
import { GroupSchemeRequestStatus } from 'store/dto/account.dto'
import { useAppSelector } from 'store/hooks'
import { currentGroupScheme } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { userCanConfirmPaymentsForScheme, userCanExecutePaymentsForScheme, userCanManageJobsForScheme, userCanManageRequestsForScheme } from 'lib/groupSchemeHelpers'
import { ProgressiveImage } from 'components/Utility/ProgressiveImage'
import { useSelector } from 'react-redux'
import { hasAnyPermission, UserPermissions } from 'lib/authHelpers'
import { EmployerSchemeInfoSwitchModal } from 'components/Utility/EmployerSchemeInfoSwitchModal'

type EmployerAppStackDrawerContentProps = {
  screens: DrawerScreen[],
  changeSchemeFunction: Function,
  screenGroupMap: any,
  state: DrawerNavigationState<ParamListBase>,
  descriptors: DrawerDescriptorMap,
  navigation: DrawerNavigationHelpers,
}
export const EmployerAppStackDrawerContent = (props: EmployerAppStackDrawerContentProps) => {
  const { screens, screenGroupMap, changeSchemeFunction, state, navigation, descriptors } = props
  const currentScheme = useAppSelector(currentGroupScheme)

  const authUser = useSelector((state: any) => state.auth.user)
  const isAdmin = hasAnyPermission(authUser, [UserPermissions['administer:groupSchemes']])

  const userCanManageRequests = userCanManageRequestsForScheme(currentScheme)
  const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  const userCanConfirmPayments = userCanConfirmPaymentsForScheme(currentScheme)
  const userCanExecutePayments = userCanExecutePaymentsForScheme(currentScheme)
  const userCanUtilizePayments = userCanConfirmPayments || userCanExecutePayments

  const { id, name: schemeName, organizationDetails, userSchemeRole } = currentScheme || {}
  const { logoPath, displayName } = organizationDetails || {}
  
  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()

  const { data: schemes, isLoading: schemesIsLoading, error: schemesError, isFetching: schemesIsFetching, refetch: refetchSchemes } = useGetMeGroupSchemesQuery(undefined, { skip: isAdmin })
  const { data: requests, isLoading: requestsIsLoading } = useGetGroupSchemeRequestsQuery({
    groupSchemeId: id,
    status: GroupSchemeRequestStatus.PENDING, 
  }, { skip: !currentScheme || !userCanManageRequests })

  const { data: enrolmentJobs, isLoading: enrolmentJobsIsLoading, isFetching: enrolmentJobsIsFetching, error: enrolmentJobsError, refetch: refetchEnrolmentJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.MEMBER,
  }, { skip: !currentScheme || !userCanManageJobs })
  
  const { data: contributionJobs, isLoading: contributionJobsIsLoading, isFetching: contributionJobsIsFetching, error: contributionJobsError, refetch: refetchContributionJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.CONTRIBUTION,
  }, { skip: !currentScheme || !userCanManageJobs })

  const { data: payments, isLoading: paymentsIsLoading } = useGetGroupSchemePaymentsQuery({
    groupSchemeId: id,
    incomplete: true
  }, { skip: !currentScheme || !userCanUtilizePayments })
  
  const insets = useSafeAreaInsets()

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showUserInfoModal, setShowUserInfoModal] = useState(null)
  const [showSchemeSwitchModel, setShowSchemeSwitchModel] = useState(false)

  const isWeb = platformIsWeb()

  const hasMultipleSchemes = schemes?.length > 1
  const otherSchemes = hasMultipleSchemes && currentScheme ? schemes.filter(scheme => {
    return scheme.id !== currentScheme.id
  }) : []

  const routeNotificationMap = {
    [EmployerAppNavScreen.ENROLMENTS]: enrolmentJobs?.length,
    [EmployerAppNavScreen.CONTRIBUTIONS]: contributionJobs?.length,
    [EmployerAppNavScreen.PAYMENTS]: payments?.length,
    [EmployerAppNavScreen.REQUESTS]: requests?.length,
  }

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightPurple : Paper.darkThemeOnLightGrey}>
      <View style={{
        backgroundColor: isWeb ? Colors.brand.purple4 : Colors.brand.grey4,
        flex: 1,
      }}>
        <DrawerContentScrollView
          {...props}
          style={{
            marginRight: isWeb ? Sizing.x0 : Sizing.x1,
          }}
        >
          <Pressable
            onPress={() => setShowInfoModal(true)}
            style={{
              paddingVertical: Sizing.x20,
              alignSelf: 'center',
              width: Sizing.x200,
          }}>
            <JarvisLogoType size={Sizing.x30} color='black' />
            <Portal>
              <AppInfoModal
                user={user}
                visible={showInfoModal}
                onDismiss={() => setShowInfoModal(false)}
              />
            </Portal>
          </Pressable>
          <AppContextSwitcher currentAppContext={AppContext.EMPLOYER} />
          <ContentDivider />
          <CustomDrawerItemList
            state={state}
            navigation={navigation}
            descriptors={descriptors}
            routeNotificationMap={routeNotificationMap}
            screenGroupMap={screenGroupMap}
          />
        </DrawerContentScrollView>
        <View style={{
            // backgroundColor: Colors.brand.grey4,
            marginRight: isWeb ? Sizing.x0 : Sizing.x1,
            paddingBottom: insets.bottom,
          }}>
          <ContentDivider />
          {
            currentScheme
              ? <>
                  <Pressable
                    onPress={isWeb ? () => {} : () => setShowInfoModal(true)}
                    style={{
                      alignSelf: 'center',
                      width: Sizing.x200,
                      paddingTop: Sizing.x10,
                  }}>
                    <ProgressiveImage
                      source={{ uri: logoPath}}
                      style={{
                        width: Sizing.x50,
                        height: Sizing.x50,
                        resizeMode: 'contain',
                        alignSelf: 'center',
                      }} 
                    />
                    <Subheading>{displayName}</Subheading>
                  </Pressable>
                  <Button
                    mode={'text'}
                    onPress={() => setShowSchemeSwitchModel(true)}
                    icon={() => <MaterialCommunityIcons
                      name={isAdmin ? 'home-switch-outline' : hasMultipleSchemes ? 'menu-down' : 'information-outline'}
                      size={Sizing.x15}
                      color={Colors.brand.purple1}/>
                    }>
                      {truncate(schemeName, { length: 100 })}
                  </Button>
                </>
              : <></>
          }
          <ContentDivider />
          <View style={{
            paddingVertical: Sizing.x10,
            // backgroundColor: Colors.brand.grey4,
            ...Flex.row.center,
            }}>
              <Pressable
                style={localStyles.profileContainer}
                onPress={() => setShowUserInfoModal(true)}
              >
                <View style={localStyles.smallProfileImageContainer}>
                  <Image source={{ uri: user?.picture }} style={localStyles.smallProfileImage}/>
                </View>
                <View>
                  <Subheading>{`${user?.metadata?.firstName} ${user?.metadata?.surname}`}</Subheading>
                  <Paragraph>{userSchemeRole}</Paragraph>
                </View>
              </Pressable>
          </View>
        </View>
        <Portal>
          <AppInfoModal
            user={user}
            visible={showInfoModal}
            onDismiss={() => setShowInfoModal(false)}
          />
          <UserInfoModal
            user={user}
            visible={showUserInfoModal}
            onDismiss={() => setShowUserInfoModal(false)}
          />
          <EmployerSchemeInfoSwitchModal
            currentScheme={currentScheme}
            otherSchemes={otherSchemes}
            visible={showSchemeSwitchModel}
            onDismiss={() => setShowSchemeSwitchModel(false)}
            changeSchemeFunction={changeSchemeFunction}
            showSchemeSearch={isAdmin}
          />
        </Portal>
      </View>
    </ThemeProvider>
    )
  }

  const localStyles = StyleSheet.create({
    profileContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingHorizontal: Sizing.x10,
    },
    smallProfileImageContainer: {
    },
    smallProfileImage: {
      alignSelf: 'center',
      height: scaleNormalizer(60),
      width: scaleNormalizer(60),
      borderRadius: scaleNormalizer(30),
    },
  })
  