import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { getAssetCategory, getAssetIconName, sortAssets } from 'lib/retirementAssetHelpers'
import { max } from 'lodash'
import React, { useRef } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementAssetsQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto, RetirementAssetTransferStatus } from 'store/dto/retirement-asset.dto'
import { useAppSelector } from 'store/hooks'
import { onAssetSpouseView } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

const Tab = createMaterialTopTabNavigator()

export enum AssetTabScreenName {
  CLIENT = 'CLIENT',
  SPOUSE = 'SPOUSE',
}

interface RetirementAssetsListProps {
  addAssetStateFunction: Function
  editAssetStateFunction: Function
  addSpouseStateFunction: Function
  onNavigateFunction: Function
}

export const RetirementAssetsList = (props: RetirementAssetsListProps) => {
  const { addAssetStateFunction, editAssetStateFunction, addSpouseStateFunction, onNavigateFunction } = props

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const error: any = meError || assetsError
  const isLoading = meIsLoading || assetsIsLoading || spouseIsLoading

  const { colors: themeColors } = Paper.useAppTheme()

  const onSpouseTab = useAppSelector(onAssetSpouseView)

  const assetCount = assets?.length || 0

  const clientAssets = assets.filter(asset => {
    return asset.clientId === me?.id
  })

  const clientAssetCount = clientAssets?.length || 0
  const spouseAssetCount = assetCount - clientAssetCount
  const tableRows = max([clientAssetCount, spouseAssetCount]) + 1

  //Calculate navigator height
  //Tabs, table rows and top padding
  const rawNavigatorHeight = Sizing.x50 + (Sizing.x50 * tableRows) + Sizing.x20
  const navigatorHeight =
    error || isLoading ? Sizing.x400 //Loading
    : !spouse ? max([rawNavigatorHeight, Sizing.x300]) //Min height for spouse add
    : rawNavigatorHeight

  type AssetListProps = {
    client: ClientBaseDto
    assets: RetirementAssetDto[]
  }
  const AssetList = (props: AssetListProps) => {
    const { client, assets } = props

    const clientAssets = assets.filter(asset => {
      return asset.clientId === client?.id
    })

    const scrollRef = useRef()

    return (
      <View style={localStyles.listContainer}>
        {
            clientAssets ? sortAssets(clientAssets).map((asset, idx) => {
              return (
                <RetirementAssetRow
                  key={idx}
                  asset={asset}
                  onPress={() => editAssetStateFunction(asset)}
                />                    
              )
            }) : <></>
          }
          { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
            <AddRow
              title={'Add a pension/asset'}
              onPress={() => addAssetStateFunction(client)}
            /> : <></>
          }
      </View>
    )
  }

  type AddRowProps = {
    title: string
    onPress?: any
  }
  const AddRow = (props: AddRowProps) => {
    const { title, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={'plus-circle'} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View>
            <Subheading>{title}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading> </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  type RetirementAssetRowProps = {
    asset: RetirementAssetDto
    onPress?: any
  }
  const RetirementAssetRow = (props: RetirementAssetRowProps) => {
    const { asset, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const assetCategory = getAssetCategory(asset.assetType)
    const iconName: any = getAssetIconName(asset.assetType, assetCategory)
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={asset?.id ? onPress : undefined}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{asset.name}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>{formatCurrencyAmount(asset.currentValue)}</Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  const ClientScreen = () => {
    
    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchAssets()
    }

    const nonTransferredAssets = assets.filter(asset => {
      return asset.transferStatus !== RetirementAssetTransferStatus.COMPLETED
    })

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          <AssetList 
            client={me}
            assets={nonTransferredAssets}
          />
        }
      </>
    )
  }

  const SpouseScreen = () => {

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchAssets()
    }
    
    const nonTransferredAssets = assets.filter(asset => {
      return asset.transferStatus !== RetirementAssetTransferStatus.COMPLETED
    })

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          spouse ?
          <AssetList 
            client={spouse}
            assets={nonTransferredAssets}
          />
          :
          <View style={{...Flex.column.start }} >
            <View style={{
              marginTop: Sizing.x10,
              marginHorizontal: Sizing.x15,
            }}>
              <Paragraph style={{textAlign: 'center', paddingVertical: Sizing.x20}}>Add your partner to start creating your shared retirement universe together</Paragraph>
              <MaterialCommunityIcons style={{ alignSelf: 'center' }} onPress={() => addSpouseStateFunction(true)} name={'plus-circle'} size={Sizing.x60} color={Colors.brand.red3}/>
              <Button mode='text' onPress={() => addSpouseStateFunction(true)} color={Colors.brand.red3}>Add Partner</Button>              
            </View>
          </View>          

        }
      </>
    )
  }

  return (
    <View style={{ height: navigatorHeight }}>
      <Tab.Navigator
        screenListeners={{
          state: (e) => onNavigateFunction(e.data)
        }}
        initialRouteName={onSpouseTab ? AssetTabScreenName.SPOUSE : AssetTabScreenName.CLIENT}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }} 
      >
        <Tab.Screen name={AssetTabScreenName.CLIENT} component={ClientScreen} options={{ title: 'You'}} />
        <Tab.Screen name={AssetTabScreenName.SPOUSE} component={SpouseScreen} options={{ title: spouse?.firstName || 'Partner' }} />
      </Tab.Navigator>
    </View>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
    ...Flex.override.fill,
  },
  header: {
    ...Flex.row.between,
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10,
  },
  rowContainer: {
    height: Sizing.x50,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start,
  },
  amountContainer: {
    ...Flex.override.right,
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between,
  },
})