import { ContentDivider } from "components/Layout/ContentDivider"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { AppIllustration } from "components/Utility/AppIllustration"
import { scaleNormalizer } from "lib/scaleHelpers"
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { Colors, Paper, Sizing } from "styles"

type GuidanceGuidedModalContentProps = {
  modelAvaiable: boolean
  title: string
  description: string
  illustrationFilename: string
}

export const GuidanceManualModalContent = (props: GuidanceGuidedModalContentProps) => {
  const { modelAvaiable, title, description, illustrationFilename } = props || {}

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      <Headline style={localStyles.title}>{title}</Headline>

      <ContentDivider />
      <View style={{
        alignSelf: 'center',
      }}>
        <AppIllustration filename={illustrationFilename} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Subheading style={localStyles.text}>{description}</Subheading>
      <Paragraph style={localStyles.text}>{`Choose this option if you already know how much you want to contribute regularly, or have a fixed amount in mind that you want to invest today.`}</Paragraph>
      {
        modelAvaiable
        ? <Paragraph style={localStyles.text}>{`Once you've made or set up your contributions, Jarvis will immediately recalculate when you can retire.`}</Paragraph>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    // textAlign: 'left',
  },
  subTitle: {
    // textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    // textAlign: 'left',
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})