import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { useAppSelector } from 'store/hooks'
import { workingRetirementAsset } from 'store/tempDataSlice'
import { useGetRetirementAssetQuery } from 'store/apiSlice'

export const RetirementAssetTransfer_10_ReferenceIntro = ({route, navigation}) => {
  const { nextScreen, onDismiss, asset, isRetransfer }  = route?.params || {} 

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const tasks: RetirementAssetTransferStepIdentifier[] = [
    RetirementAssetTransferStepIdentifier.PROVIDER,
    RetirementAssetTransferStepIdentifier.REFERENCE,
    RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS,
  ]
  if (workingRetirementAssetData?.tracingRequested) {
    tasks.push(RetirementAssetTransferStepIdentifier?.TRACING)
  }

  //Determine pendingSteps
  const pendingSteps: RetirementAssetTransferStepIdentifier[] = []
  if (!latestAsset?.pensionProviderId) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.PROVIDER)
  }

  return (
    <ModalProcessIntroScreen
      isLoading={latestAssetIsLoading || latestAssetIsFetching}
      error={latestAssetError}
      errorTryAgain={refetchLatestAsset}
      onDismiss={onDismiss}
      buttonTitle={'Continue'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={isRetransfer
        ? `Ok, let's check your pension reference`
        : `Great, that's the most important part done!`
      }
      subHeading={isRetransfer
        ? `Old providers often need this to match their records exactly`
        : `The next step is finding your personal pension reference`
      }
    >
      <TaskList
        asset={asset}
        allTasks={tasks}
        pendingSteps={pendingSteps}
        nextTask={RetirementAssetTransferStepIdentifier.REFERENCE}
        nextTaskAction={next}
        isRetransfer={isRetransfer}
      />
    </ModalProcessIntroScreen>
  )
}

