import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { differenceInYears } from 'date-fns'
import { DashboardMeter } from 'features/Dashboard/Components/DashboardMeter'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useLazyGetCalculatorTimelineQuery } from 'store/apiSlice'
import { CalculatorTimelineRequestDto } from 'store/dto/calculator.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'
import { layoutStyles } from 'styles/common'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton, InformationModalLink, NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { calculateAgeNow } from 'lib/dateHelpers'

export const Calculator_12_LifeExpectancy = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const [ getCalculatorTimeline, { data: timeline, error: timelineError, isLoading: timelineIsLoading }] = useLazyGetCalculatorTimelineQuery()

  //Get calculator timeline on load
  useEffect(() => {
    getTimeline()
  }, [])

  const getTimeline = () => {
    const request: CalculatorTimelineRequestDto = {
      client: workingCalculatorRequestData?.client
    }
    getCalculatorTimeline(request, false)
  }

  const next = () => {
    dispatch(updateWorkingCalculatorRequest({
      timeline,
    }))
    navigation.navigate(nextScreen)
  }

  const {
    statePensionAge,
    lastSurvivorDeathAge,
    retirementDate,
    birthDate,
    ageAtLastSurvivorDeathDate,
  }  = timeline || {}

  const clientAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(birthDate),
  )

  const isLoading = timelineIsLoading || !timeline
  const error: any = timelineError

  const features: FeatureBoxRow[] = [
    { label: `Set your personal target retirement age` },
    { label: `See how long you have to get prepared` },
  ]

  const informationTexts: string[] = [
    `Jarvis derives life expectancy from UK mortality tables, taking the most likely (50% probability) outcome, which sets the future date until which you will need to support yourself.`,
    `Your 'State Pension Age' is the age at which the UK Government would currently provide eligible individuals with a state pension. Eligibility and the exact amount you could receive depends on your National Insurance contribution history.`,
    `The exact age at which you could receive the state pension varies according to your current age, and historically according to your gender, although for most people it is now the same age.`,
    `Your timeline shows the period of your future life divided into the portions before and after retirement. For simplicity, this calculator assumes retirement at your state pension age, however the full Jarvis app allows you to set your own target age, and understand how close you are to achieving it.`,
    `Please note these figures are for an average person in the UK and may be affected by personal health conditions and family history.`,
  ]

  const informationLinks: InformationModalLink[] = [
    {
      title: `About the New State Pension`,
      url: `https://www.gov.uk/new-state-pension`,
    },
    {
      title: 'Check Your State Pension',
      url: 'https://www.gov.uk/check-state-pension',
    }
  ]

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Building your timeline...']}
      error={error}
      errorTryAgain={getTimeline}
      buttonTitle={'Continue'}
      buttonAction={next}
      enableButton={true}
      headline={`Great! That helps us build your timeline`}
      subHeading={`Your life expectancy is ${lastSurvivorDeathAge} and if eligible, you would get a UK state pension at age ${statePensionAge}`}
      subHeadingInfo={<InformationButton title={`Timeline Explained`} texts={informationTexts} links={informationLinks} illustrationFilename={'retirement_timeline.png'} buttonTitle={`How is this calculated?`} />}
      footerInfo={<FeatureBox data={features} />}
    >
      <DashboardMeter
        viableAge={clientAgeAtRetirementDate}
        currentAge={calculateAgeNow(birthDate)}
        targetAge={clientAgeAtRetirementDate}
        expectedDeathAge={ageAtLastSurvivorDeathDate}
        withLabels={true}
      />
    </ProcessScreen>
  )
}
