import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { ExternalLinkText } from "components/Utility/ExternalLinkText"
import { FooterButton } from "components/Utility/FooterButton"
import { UnborderedTable, UnborderedTableRow } from "components/Utility/UnborderedTable"
import { formatCurrencyAmount, formatPercentageAmount } from "lib/generalHelpers"
import { floor } from 'lodash'
import React from 'react'
import { ScrollView, StyleSheet, View } from "react-native"
import { ClientMeDto, PlatformChargeTier } from "store/dto/client.dto"
import { AccountDto } from "store/dto/account.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { useGetAssetQuery } from "store/apiSlice"
import { Loading } from "components/Utility/Loading"
import { ErrorScreen } from "components/Utility/ErrorScreen"
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from "lib/constants"
import { TieredChargeTable } from "components/Utility/TieredChargeTable"
import { SegmentBadge } from "components/Typography/SegmentBadge"
import { format } from "date-fns"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

type TransferInfoModalProps = {
  client: ClientMeDto
  pension: AccountDto
  visible: boolean
  onDismiss: any
}

export const TransferAssistanceModal = (props: TransferInfoModalProps) => {
  const { client, pension, visible, onDismiss } = props || {}

  const { segment } = client || {}

  const isInvestmentPlan = pension?.investmentPlan
  const isSingleFund = !isInvestmentPlan && !isInvestmentPlan && pension?.assetAllocation?.length === 1
  const singleFundAssetId = isSingleFund ? pension?.assetAllocation[0]?.assetId : undefined

  const { data: singleFundAsset, isLoading: singleFundAssetIsLoading, error: singleFundAssetError, refetch: refetchSingleFundAsset } = useGetAssetQuery(singleFundAssetId, { skip: !singleFundAssetId })
  const isLoading = singleFundAssetIsLoading 
  const error: any = singleFundAssetError

  const singleFundName = singleFundAsset?.shortName

  const platformChargeTiers: PlatformChargeTier[] = segment?.platformCharge?.tiers || []
  const discount = segment?.platformChargeDiscount || 1

  const segmentHasConditions = segment?.requiredBalance && segment?.monthsToReachRequiredBalance && segment?.dateToReachRequiredBalance

  const fundManager = isInvestmentPlan
    ? pension?.investmentPlan?.asset?.manager
    : isSingleFund
      ? singleFundAsset?.manager
      : `Bespoke Portfolio`

  const fundName = isInvestmentPlan
    ? pension?.investmentPlan?.asset?.shortName
    : isSingleFund
      ? singleFundName
      : `Bespoke Portfolio`
    
  const factSheelUrl = isInvestmentPlan
    ? pension?.investmentPlan?.asset?.kiidDocumentUrl
    : isSingleFund
      ? singleFundAsset?.kiidDocumentUrl
      : `Bespoke Portfolio`
    
  const initialCharge = isInvestmentPlan
    ? formatPercentageAmount(pension?.investmentPlan?.asset?.initialFeePercentage, 2)
    : isSingleFund
      ? formatPercentageAmount(singleFundAsset?.initialFeePercentage, 2)
      : `Bespoke Portfolio`
        
  const annualCharge = isInvestmentPlan
    ? formatPercentageAmount(pension?.investmentPlan?.asset?.annualFeePercentage, 2)
    : isSingleFund
      ? formatPercentageAmount(singleFundAsset?.annualFeePercentage, 2)
      : `Bespoke Portfolio`
              
  const administratorData: UnborderedTableRow[] = [
    {
      label: `Administrator Name`,
      value: `Seccl Custody Limited`,
      copyableValue: `Seccl Custody Limited`,
    },
    {
      label: `FCA Registration No`,
      value: `793200`,
      copyableValue: `793200`,
    },
    {
      label: `Company Reg No`,
      value: `10430958`,
      copyableValue: `10430958`,
    },
    {
      label: `Address`,
      value: `20 Manvers Street, Bath, BA1 1JW`,
      copyableValue: `20 Manvers Street, Bath, BA1 1JW`,
    },
    {
      label: `Scheme Established`,
      value: `3rd Sep 2022`,
      copyableValue: `3rd Sep 2022`,
    },
  ]

  const bankAccountData: UnborderedTableRow[] = [
    {
      label: `Sort Code`,
      value: `308012`,
      copyableValue: `308012`,
    },
    {
      label: `Account`,
      value: `17190968`,
      copyableValue: `17190968`,
    },
    {
      label: `Account Name`,
      value: `Digital Pension Trustees Limited`,
      copyableValue: `Digital Pension Trustees Limited`,
    },
    {
      label: `Account Address`,
      value: `20 Manvers Street, Bath, BA1 1JW`,
      copyableValue: `20 Manvers Street, Bath, BA1 1JW`,
    },
  ]

  const investmentData: UnborderedTableRow[] = [
    {
      label: `Pension Type`,
      value: `SIPP`,
    },
    {
      label: `Fund Manager`,
      value: fundManager,
      copyableValue: fundManager,
    },
    {
      label: `Fund Name`,
      value: fundName,
      copyableValue: fundName,
    },
    {
      label: `Fund Fact Sheet URL`,
      value: <ExternalLinkText
        url={factSheelUrl}
        directToBrowser={true}
      >{'Link'}</ExternalLinkText>,
      copyableValue: factSheelUrl,
    },
  ]

  const fundChargeData: UnborderedTableRow[] = [
    {
      label: `Initial Charge`,
      value: initialCharge,
    },
    {
      label: `Ongoing Annual Charge`,
      value: annualCharge,
    },
  ]
  
  const platformChargeData: UnborderedTableRow[] = [
    {
      label: `Initial Charge`,
      value: formatPercentageAmount(0, 2),
    },
  ]

  if (platformChargeTiers.length) {
    platformChargeData.push(    {
      label: `Ongoing Annual Platform Charges`,
      value: ``,
    },)
  }  

  platformChargeTiers.map(tier => {
    const { fromAmount, toAmount, rate } = tier
    const applicableRate = floor(rate * (1 - (discount || 0)), 2)
    const label = fromAmount === 0 && toAmount === undefined
      ? '  All balances'
      : fromAmount === 0
        ? `  Balances up to ${formatCurrencyAmount(toAmount, 0)}`
        : toAmount === undefined
          ? `  Balances above ${formatCurrencyAmount(fromAmount, 0)}`
          : `  Balances between ${formatCurrencyAmount(fromAmount, 0)} and ${formatCurrencyAmount(toAmount, 0) }`

    platformChargeData.push({
      label,
      value: formatPercentageAmount(applicableRate, 2)
    })
  })

  return (
      <CardModal visible={visible} onDismiss={onDismiss} >
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            {
              isLoading ? <Loading /> : error ? <ErrorScreen  error={error?.data} /> :
              <>
                <View style={{paddingBottom: Sizing.x20}}>
                  <Headline>{`Useful Information`}</Headline>
                </View>
                <View style={{
                  alignSelf: 'center',
                  paddingTop: Sizing.x0,
                  paddingBottom: Sizing.x20,
                  width: '100%',
                }}>
                  <ContentDivider />
                  <Subheading>{`Below is information about the ${JAR_NAME_ALL} administrator, scheme and the specific investment plan for your ${JAR_NAME_PERSONAL}.`}</Subheading>
                  <Paragraph>{`You may find it useful when communicating with your old provider about the transfer of your pension.`}</Paragraph>
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{'Pension Adminsistrator'}</Subheading>
                  <UnborderedTable
                    data={administratorData}
                  />
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{'Scheme Banking Information'}</Subheading>
                  <UnborderedTable
                    data={bankAccountData}
                  />
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{'Investment Information'}</Subheading>
                  <UnborderedTable
                    data={investmentData}
                  />
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{'Fund Charges'}</Subheading>
                  <UnborderedTable
                    data={fundChargeData}
                  />
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{'Platform Charges'}</Subheading>
                  {
                    segment?.isPromotion
                    ? <>
                        <Paragraph>
                          {`You receive a `}
                          <Paragraph style={{ fontWeight: '900' }}>{formatPercentageAmount(segment?.platformChargeDiscount * 100, 0)}</Paragraph>
                          {` discount on our standard Platform charges. ${segmentHasConditions ? `Conditions Apply *` : ''}`}
                        </Paragraph>
                        <SegmentBadge
                          segment={segment}
                          withoutInfoModal={true}
                          containerStyle={{
                            paddingVertical: Sizing.x10,
                          }}
                        />
                      </>
                    : <></>
                  }
                  <TieredChargeTable
                    tiers={platformChargeTiers}
                    discount={segment?.platformChargeDiscount || 0}
                  />
                  {
                    segmentHasConditions
                    ? <Paragraph style={localStyles.smallText}>{`* To continue benefiting from the ${segment?.badgeName} discount, your ${JAR_NAME_PERSONAL} balance needs to reach at least ${formatCurrencyAmount(segment?.requiredBalance, 2)} within ${segment?.monthsToReachRequiredBalance} months of becoming a client (by ${format(new Date(segment?.dateToReachRequiredBalance),'do MMMM yyyy')})`}</Paragraph>
                      : <></>
                  }
                </View>
              </>
            }
          </ScrollView>
          <FooterButton onPress={onDismiss} mode={'text'}>{'Close'}</FooterButton>
        </View>
      </CardModal>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    paddingTop: Sizing.x20,
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
    textAlign: 'left',
  },
})
