import { Paragraph, Subheading } from 'components/Typography'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Pressable, StyleProp, StyleSheet, TextStyle, View } from 'react-native'
import { Checkbox } from 'react-native-paper'
import { Colors, Paper, Sizing } from 'styles'
import { applyOpacity } from 'styles/colors'

type DetailedListItemProp = {
  selected?: boolean
  icon?: React.ReactNode
  title: string
  subTitle: string  
  titleStyle?: StyleProp<TextStyle>
  titleRight?: string
  titleRightStyle?: StyleProp<TextStyle>
  subTitleStyle?: StyleProp<TextStyle>
  subTitleRight?: string | React.ReactNode
  subTitleRightStyle?: StyleProp<TextStyle>
  onPress?: any
  adjustsFontSizeToFit?: boolean
}

const isWeb = platformIsWeb()
const iconWidth = isWeb ? Sizing.x40 : Sizing.x30

export const DetailedListItem = (props: DetailedListItemProp) => {
  const { selected, icon, title, titleStyle, titleRight, titleRightStyle, subTitle, subTitleStyle, subTitleRight, subTitleRightStyle, onPress, adjustsFontSizeToFit } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const opacity = selected ? themeColors.themeType === 'light' ? 0.2 : 0.1 : undefined
  const backgroundColor = selected ? applyOpacity(themeColors.divider, opacity) : undefined

  return (
    <View style={localStyles.itemWrap}>
      <Pressable
        style={({ pressed }) => [
          localStyles.itemContainer,
          { opacity: pressed ? 0.5 : 1.0 },
          { backgroundColor }
        ]}
        onPress={onPress}>
        {
          icon ?
            <View style={localStyles.iconContainer}>
              {icon}
            </View>
          : <></>
        }
        <View
          style={localStyles.dataContainer}
        >
          <View style={localStyles.rowsContainer}>
            <View style={localStyles.textRowContainer}>
              <View style={localStyles.leftTextContainer}>
                <Subheading numberOfLines={1} style={[localStyles.mainTextStyle, titleStyle]}>{title}</Subheading>
              </View>
              <View style={localStyles.rightTextContainer}>
                <Subheading numberOfLines={1} style={[localStyles.mainTextStyle, titleRightStyle]}>{titleRight}</Subheading>
              </View>
            </View>


            <View style={localStyles.textRowContainer}>
              <View style={localStyles.leftTextContainer}>
                <Paragraph numberOfLines={1} adjustsFontSizeToFit={adjustsFontSizeToFit} style={[localStyles.subTextStyle, subTitleStyle]}>{subTitle}</Paragraph>
              </View>
              <View style={localStyles.rightTextContainer}>
                {
                  typeof subTitleRight === 'string'
                    ? <Paragraph numberOfLines={1} adjustsFontSizeToFit={adjustsFontSizeToFit} style={[localStyles.subTextRightStyle, subTitleRightStyle]}>{subTitleRight}</Paragraph>
                    : <View style={{ flexDirection: 'row', flexGrow: 1 }}>
                        {subTitleRight ? subTitleRight : <></>}
                      </View>
                }
              </View>
            </View>
          </View>
        </View>
      </Pressable>

    </View>
  )
}

// const listWidth = getScreenAppWidth() - (Sizing.x30 * 2)

const localStyles = StyleSheet.create({
  itemWrap: {
    paddingTop: Sizing.x10,
  },
  itemContainer: {
    // width: listWidth,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: Sizing.x2,
    borderRadius: Sizing.x2
  },
  iconContainer: {
    width: iconWidth,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataContainer: {
    flexGrow: 1,
    // width: listWidth - Sizing.x40,
  },
  rowsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  textRowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  leftTextContainer: {
    flexBasis: 0,
    flexGrow: 1,
  },
  rightTextContainer: {
    marginLeft: 'auto',
  },
  mainTextStyle: {
    color: Colors.neutral.white,
    textAlign: 'left',
  },
  subTextStyle: {
    color: Colors.neutral.white,
    textAlign: 'left',
  },
  subTextRightStyle: {
    color: Colors.neutral.white,
    marginLeft: 'auto',
  },
})
