import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { TransfersScreen } from './Screens/TransfersScreen'

export const TransfersSectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Transfers'}
    >
      <TransfersScreen />
    </SectionSimpleContainer>
  )
}