import ModalProcessStackBuilder from 'navigation/stacks/ModalProcessStackBuilder';
import React, { useEffect } from 'react';
import { InvestmentAssetAdd_00_Intro, InvestmentAssetAdd_01_CategoryChoice, InvestmentAssetAdd_02_FundChoice, InvestmentAssetAdd_03_Split, InvestmentAssetAdd_04_Adjust, InvestmentAssetAdd_99_Result } from './Screens';
import { AssetAllocationDto } from 'store/dto/account.dto';
import { updateWorkingAssetAllocations } from 'store/tempDataSlice';
import { useAppDispatch } from 'store/hooks';

export type InvestmentAssetAddProcessStackProps = {
  onFinish: Function
  onDismiss: Function
  accountDescriptor: string
  currentAssetAllocation: AssetAllocationDto[]
}

export const InvestmentAssetAddProcessStack = (props: InvestmentAssetAddProcessStackProps) => {  
  const { onDismiss, currentAssetAllocation } = props
  const dispatch = useAppDispatch()

  //Initialize on start
  useEffect(() => {
    dispatch(updateWorkingAssetAllocations({
      existingAllocations: currentAssetAllocation,
    }))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={true}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding this fund?`}
      processTitle={'Add Fund'}
      initialRouteName="Intro"
      initialParams={
        {...props}
      }
      screens={[
        {
          navigationId: 'Intro',
          title: 'Your Partner',
          component: InvestmentAssetAdd_00_Intro
        },

        {
          navigationId: 'CategoryChoice',
          title: 'Category Choice',
          component: InvestmentAssetAdd_01_CategoryChoice
        },
        {
          navigationId: 'FundChoice',
          title: 'Fund Choice',
          component: InvestmentAssetAdd_02_FundChoice
        },
        {
          navigationId: 'Split',
          title: 'Split',
          component: InvestmentAssetAdd_03_Split
        },
        {
          navigationId: 'Adjust',
          title: 'Adjust',
          component: InvestmentAssetAdd_04_Adjust
        },
        {
          navigationId: 'Results',
          title: 'Results',
          component: InvestmentAssetAdd_99_Result
        },
      ]}
    />
  )
}
