import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { ComponentProps } from 'react'
import { Linking } from 'react-native'
import { Sizing, Paper } from 'styles'
import { Button } from './Button'

type EmailLinkButtonProps = ComponentProps<typeof Button> & {
  email: string
}

export const EmailLinkButton = (props: EmailLinkButtonProps) => {
  const { email, children } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const openMail = () => { 
      Linking.openURL(`mailto:${email}`)
  }

  return (
    <>
      <Button
        {...props}
        labelStyle={{
          textDecorationLine: 'underline',
        }}
        mode='text'
        icon={() => <MaterialCommunityIcons name={'email-outline'} size={Sizing.x20} color={themeColors.accent} />}
        onPress={openMail}
      >{children}</Button>
    </>
  )
}