import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SecureStore from 'expo-secure-store'
import { Logger } from 'lib/logger'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

// Gets a JSON item from the store
export const getJsonItemFromStore = async (key) => {
  let raw
  try {
    if (isWeb) {
      raw = await AsyncStorage.getItem(key)
    } else {
      raw = await SecureStore.getItemAsync(key)
    }
  } catch (error) {
    Logger.error({ key, isWeb, error}, `Error retrieving JSON item from store`)
  }
  
  if (raw) {
    // Logger.debug({ raw }, `Raw value from store`)
    try {
      const parsed = JSON.parse(raw)
      // Logger.debug({ parsed }, `Returning value from store`)
      return parsed
    } catch (error) {
      Logger.error({ key, raw, isWeb, error}, `Error parsing JSON item from store.`)
    }
  }
  // Logger.debug(`Returning null value from store`)
  return null
}

// Sets a JSON item in the store
export const setJsonItemInStore = async (key, data) => {
  const stringified = JSON.stringify(data)

  if (isWeb) {
    await AsyncStorage.setItem(key, stringified)  
  } else {
    await SecureStore.setItemAsync(key, stringified)  
  }
}

// Clears an item from the store store
export const removeJsonItemFromStore = async (key) => {
  if (isWeb) {
    await AsyncStorage.removeItem(key)
  } else {
    await SecureStore.deleteItemAsync(key)
  }
}
