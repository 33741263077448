import { ProcessIntroScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { WebAppDownloadContent } from 'components/Utility/WebAppDownloadContent'
import { goToLogout } from 'lib/RootNavigation'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { Sizing } from 'styles'

export const WebHoldingScreen = () => {
  //State
  const [showButtons, setShowButtons] = useState(false)

  const { data: user, error, isLoading, refetch } = useGetCurrentUserQuery()

  return (
    <>
      <ProcessIntroScreen
        isLoading={isLoading}
        error={error}
        errorTryAgain={refetch}
        showButton={true}
        buttonTitle={'Logout'}
        buttonAction={goToLogout}
        imageSize={Sizing.x50}
        imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
        headline={`Great to have you onboard!`}
        subHeading={`Our web app isn't quite ready for prime time yet.`}
        cancelButtonText={showButtons ? 'Show QR Code Instead' : 'Viewing on your mobile device?'}
        cancelButtonAction={showButtons ? () => setShowButtons(false) : () => setShowButtons(true)}
      >
        <Paragraph>{`To continue with in mobile app, please scan the QR Code below with your mobile device to download the Jarvis App, and then login with a single click.`}</Paragraph>
        <View style={showButtons ? { paddingVertical: Sizing.x30 } : {} }>
          <WebAppDownloadContent
            noTitle={true}
            forceShowButtons={showButtons}
            handOffEmailAddress={user?.email}
            authType={AUTHTYPE.LOGIN}
          />
        </View>
      </ProcessIntroScreen>

    </>
  )
}
