import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ClientMeDto, MaritalStatus } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_05_MaritalStatus = ({ route, navigation }) => {
  const { nextScreen, client }: { nextScreen: string, client: ClientMeDto } = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ maritalStatus: MaritalStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      maritalStatus: workingClientData?.maritalStatus || client?.maritalStatus,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What's your marital status?`}
      subHeading={`We're legally obliged to ask this for our records`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.MARITAL_STATUS} buttonTitle={'About Marital Status'} />}
    >
      <ManagedSimpleChoiceInput
        name={'maritalStatus'}
        formObj={formObj}
        options={enumToAutocompleteOptions(MaritalStatus)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
