import { RetirementBudgetTab } from 'components/Layout/RetirementBudgetTab'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { GROSS_TOTAL_BUDGET_ID, NET_TOTAL_BUDGET_ID } from 'lib/constants'
import React, { useState } from 'react'
import { useGetBudgetsQuery } from 'store/apiSlice'
import { BudgetDto, BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
const DEFAULT_BUDGET_LEVEL = BudgetLevelIdentifier.MODERATE

export const RetirementProfileSetup_04_LevelChoice = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}
  let selectedLevel = DEFAULT_BUDGET_LEVEL

  const dispatch = useAppDispatch()

  const [currentLevel, setCurrentLevel] = useState<BudgetLevelIdentifier>(selectedLevel)

  //Temp state
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)
  // let netTotalBudget: BudgetDto
  let grossTotalBudget: BudgetDto
  let categoryBudgets: BudgetDto[] = []

  const { data: budgets, error, isLoading, refetch } = useGetBudgetsQuery({
    asCouple: workingRetirementProfileData?.asCouple,
    insideLondon: workingRetirementProfileData?.insideLondon,
  }, { skip: !workingRetirementProfileData })

  const updateLevelFromState = (e) => {
    const index = e?.state?.index
    const routeNames = e?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    selectedLevel = currentName
    setCurrentLevel(selectedLevel)
  }

  const onSubmit = () => {
    const expenses = budgets.filter(budget => {
      return budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID
    }).map(budget => {
      //Extract budget level
      const budgetLevel = budget.levels.find(l => {
        return l.id === currentLevel
      })
      //Construct expense item
      return {
        id: budget.id,
        selectedLevel: budgetLevel?.id || BudgetLevelIdentifier.CUSTOM, 
        selectedCost: budgetLevel?.value || 0
      }
    })
    //Update the working retirement profile
    dispatch(updateWorkingRetirementProfile({
      baseBudgetLevel: currentLevel,
      expenses,
    }))
    //Navigate
    navigation.navigate(nextScreen)
  }

  //Extract budgets when available
  if (budgets) {
    const temp = []
    budgets.forEach(budget => {
      if (budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID) {
        temp.push(budget)
      }
    })
    categoryBudgets = temp
  }

  const netTotalBudget = budgets ? budgets.find(budget => {
    return budget.id === NET_TOTAL_BUDGET_ID
  }) : undefined

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Getting budget options...']}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={`Choose ${currentLevel}`}
      buttonAction={onSubmit}
      showButton={true}
      headline={`Ok, first choose a high-level budget`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_BUDGET} />}
    >
      <RetirementBudgetTab
        netTotalBudget={netTotalBudget}
        categoryBudgets={categoryBudgets}
        initialLevel={DEFAULT_BUDGET_LEVEL}
        stateListenerFunction={updateLevelFromState}
      />      
    </ProcessScreen>
  )
}
