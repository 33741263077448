import { indexOf, isArray, last } from 'lodash'
import { AppContext } from 'store/authSlice'

export const SECTION_MAIN_SCREEN_NAME = 'SECTION_MAIN_SCREEN'
export enum MainAppNavScreen {
  //Profiles
  PROFILE = 'PROFILE',
  SPOUSE = 'SPOUSE',

  //Dashboard
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_TODAY = 'DASHBOARD_TODAY',
  DASHBOARD_RETIREMENT = 'DASHBOARD_RETIREMENT',
  DASHBOARD_LEGACY = 'DASHBOARD_LEGACY',

  //Jarvis Pension
  PERSONAL_PENSION = 'PERSONAL_PENSION',
  GROUP_PENSION = 'GROUP_PENSION',
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  TRANSFERS = 'TRANSFERS',

  //Planning
  RETIREMENT_ASSETS = 'RETIREMENT_ASSETS',
  REGULAR_INCOMES = 'REGULAR_INCOMES',
  RETIREMENT_LIFESTYLE = 'RETIREMENT_LIFESTYLE',
  RETIREMENT_LEGACY = 'RETIREMENT_LEGACY',

  //Documents
  DOCUMENTS = 'DOCUMENTS',
  STATEMENTS = 'STATEMENTS',
}


export enum EmployerAppNavScreen {
  //Main
  DASHBOARD = 'DASHBOARD',
  INVITES = `INVITES`,
  ENROLMENTS = 'ENROLMENTS',
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  PAYMENTS = 'PAYMENTS',
  REQUESTS = `REQUESTS`,
  MEMBERS = 'MEMBERS',

  //History
  HISTORY_ENROLMENTS = 'HISTORY_ENROLMENTS',
  HISTORY_CONTRIBUTIONS = 'HISTORY_CONTRIBUTIONS',
  HISTORY_PAYMENTS = 'HISTORY_PAYMENTS',
  HISTORY_REQUESTS = 'HISTORY_REQUESTS',
}

export enum AdminAppNavScreen {
  //Dashboard
  DASHBOARD = 'DASHBOARD',
  
  //Financials
  FINANCIALS = 'FINANCIALS',

  //Users
  USERS = 'USERS',

  //Clients
  CLIENTS = 'CLIENTS',

  //Transfers
  TRANSFERS = 'TRANSFERS',
   
  //BRANDS
  BRANDS = 'BRANDS',

  //PROVIDERS
  PROVIDERS = 'PROVIDERS',

  //Affiliates
  AFFILIATES = 'AFFILIATES',

  //Assets
  ASSETS = 'ASSETS',
    
  //Asset Categories
  ASSET_CATEGORIES = 'ASSET_CATEGORIES',
    
  //Investment Plans
  INVESTMENT_PLANS = 'INVESTMENT_PLANS',

  //Group Portfolios
  GROUP_PORTFOLIOS = 'GROUP_PORTFOLIOS',

  //Bank Connections
  BANK_CONNECTIONS = 'BANK_CONNECTIONS',

  //Features
  FEATURES = 'FEATURES',

  //Group Organizations
  GROUP_ORGANIZATIONS = 'GROUP_ORGANIZATIONS',

  //Group Schemes
  GROUP_SCHEMES = 'GROUP_SCHEMES',

  //Exceptions
  EXCEPTIONS = 'EXCEPTIONS'
}

export type ProcessProgress = {
  // currentRouteKey: string
  currentRouteIndex: number
  totalRouteCount: number
  progress: number
  isFirst: boolean
  isLast: boolean
}

export const getProcessProgress = (navState: any, noIntro?: boolean): ProcessProgress => {
  if (!navState) {
    return undefined
  }

  const adjust = noIntro ? 0 : 1
  
  let currentRouteIndex = 0
  // let currentRouteKey = routeWithState.key
  const routeNames = navState.routeNames
  const totalRouteCount = routeNames?.length || 0
  const subRoutes = navState.routes

  if (subRoutes && subRoutes.length && routeNames) {    
    const currentSubRoute = last(subRoutes)
    const index = indexOf(routeNames, currentSubRoute.name)
    currentRouteIndex = index >= 0 ? index : 0
    // currentRouteKey = currentSubRoute.key
  }
  const isFirst = currentRouteIndex === 0
  const isLast = currentRouteIndex === totalRouteCount - 1

  const mainRouteCount = totalRouteCount - 1 - adjust > 0 ? totalRouteCount - 1 - adjust : 0
  const mainRouteIndex = currentRouteIndex - adjust > 0 ? currentRouteIndex - adjust : 0
  const progress = mainRouteCount && mainRouteIndex ? mainRouteIndex / mainRouteCount : 0
  
  return {
    // currentRouteKey,
    currentRouteIndex,
    totalRouteCount,    
    progress,
    isFirst,
    isLast
  }
}

export const getRouteKeyFromName = (navState: any, name: string): string => {
  if (!navState) {
    return undefined
  }
  const routes = navState.routes
  if (!isArray(routes)) {
    return undefined
  }
  const targetRoute = routes.find(route => {
    return route.name === name
  })
  return targetRoute?.key
}

export const getRouteDetails = (navState:any) => {
  return navState?.state
}

export const getStackScreenForAppContext = (appContext: AppContext) => {
  return appContext === AppContext.ADMIN ? 'AdminAppStack'
  : appContext === AppContext.EMPLOYER ? 'EmployerAppStack'
  :'MainAppStack'
}

export const getEntryScreenForAppContext = (appContext: AppContext) => {
  return appContext === AppContext.ADMIN ? 'EntryPointAdminScreen'
  : appContext === AppContext.EMPLOYER ? 'EntryPointEmployerScreen'
  :'EntryPointDefaultScreen'
}

export const getAppDescriptionForAppContext = (appContext: AppContext) => {
  return appContext === AppContext.ADMIN ? 'Admin Portal'
  : appContext === AppContext.EMPLOYER ? 'Employer Portal'
  :'Client App'
}

export const getIconForAppContext = (appContext: AppContext) => {
  return appContext === AppContext.ADMIN ? 'application-cog-outline'
  : appContext === AppContext.EMPLOYER ? 'briefcase-outline'
  :'account-outline'
}