import {View, Text, Pressable, StyleSheet} from 'react-native';
import React from 'react';
import { Paper } from 'styles'

import {Colors, Flex, Sizing, Typography} from 'styles';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {Subheading} from 'components/Typography';
import { ContentDivider } from 'components/Layout/ContentDivider';
interface RowProp {
  mainText: string;
  iconName?: string | any;
  onPressFunction?: () => void;
  rightText?: string;
  otherStyle?: any;
  pressable?: boolean;
}
const Row = ({
  mainText,
  iconName,
  onPressFunction,
  rightText,
  otherStyle,
  pressable
}: RowProp) => {
  const {colors: themeColors} = Paper.useAppTheme();

  return (
    <View>
      <Pressable
        style={({pressed}) => [
          localStyles.itemContainer,
          {opacity: pressed && pressable ? 0.5 : 1.0}
        ]}
        onPress={onPressFunction}>
        {(iconName) && (
          <View style={localStyles.iconContainer}>
            {iconName && (
              <MaterialCommunityIcons
                name={iconName}
                size={Sizing.x30}
                color={themeColors.primary}
              />
            )}
          </View>
        )}
        <View style={localStyles.titleContainer}>
          <Subheading style={[{ textAlign: 'left' }, {...otherStyle}]} numberOfLines={1} >{mainText}</Subheading>
        </View>
        <View style={localStyles.amountContainer}>
          <Subheading style={[{ textAlign: 'right' }, {...otherStyle}]}>{rightText}</Subheading>
        </View>
      </Pressable>
      <ContentDivider />
    </View>
  );
};

export default Row;
const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20
  },
  header: {
    ...Flex.row.between
  },
  iconContainer: {
    // paddingHorizontal: Sizing.x10
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start
  },
  titleContainer: {
    ...Flex.override.fill,
  },
  amountContainer: {
    ...Flex.override.right,
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between
  },
  initialsStyle: {
    ...Flex.column.center,
    backgroundColor: Colors.neutral.white,
    borderRadius: Sizing.x100,
    height: Sizing.x30,
    width: Sizing.x30,
  }
});
