import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetMeQuery, useGetUserAccountsQuery, useGetRetirementAssetsQuery } from 'store/apiSlice'
import { RetirementAssetTransferStatus } from 'store/dto/retirement-asset.dto'
import { TransferInfoModal } from './TransferInfoModal'
import { TransferListItem } from './TransferListItem'
import { AccountType } from 'store/dto/account.dto'
import { getPersonalPension } from 'lib/accountHelpers'

export const TransfersList = () => {
  const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch } = useGetRetirementAssetsQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching } = useGetUserAccountsQuery()
  
  const personalPension = getPersonalPension(accounts)

  const [refreshing, setRefreshing] = useState(false)

  //Filter on assets that have a transferStatus AND belong to the client
  const transferAssets = assets ? assets.filter(asset => {
    return asset.transferStatus && asset?.clientId === client?.id
  }) : []

  const isLoading = assetsIsLoading || clientIsLoading || accountsIsLoading
  const error: any = assetsError || clientError || accountsError

  const [modalItem, setModalItem] = useState(undefined)

  const transfersList = () => {
    let inProgress: any = []
    let completed: any = []

    const inProgressStatuses: RetirementAssetTransferStatus[] = [
      RetirementAssetTransferStatus.TRACING,
      RetirementAssetTransferStatus.INITIATED,
      RetirementAssetTransferStatus.ACTIVE,
      RetirementAssetTransferStatus.WITH_SUPPORT,
      RetirementAssetTransferStatus.REJECTED,
      RetirementAssetTransferStatus.MODIFIED,
    ]

    transferAssets.map((asset, id) => {
      if (inProgressStatuses.includes(asset.transferStatus)) {
        inProgress.push(asset)
      } else if (asset.transferStatus === RetirementAssetTransferStatus.COMPLETED) {
        if (asset.completedTransferAcknowledged) {
          completed.push(asset)
        } else {
          inProgress.push(asset)
        }
      }
    })

    return [
      {title: 'In Progress', data: inProgress },
      {title: 'Completed', data: completed },
    ]
  }

  const Item = ({item})=>{
    return (
      <TransferListItem
        asset={item}
        onPressFunction={() => setModalItem(item)}
      />
    )
  }

  const onRefresh = async () => {
    await refreshUsingFunctions(
      [refetch],
      refreshing,
      setRefreshing,
    )
  }

  return (
    <>
      {
        isLoading
          ? <Loading  message={getLookingLoadingMessages('Loading your transfers...')} messageNoLoop={true} />
          : error ? <ErrorScreen error={error?.data} errorTryAgain={refetch} /> :
        <>
          <View style={{ flex: 1 }} >
            <OptionalSectionList
              sections={transfersList()}
              renderItem={({item})=><Item item={item} />}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`You haven't yet made any transfers`}
            />
          </View>
          <Portal>
            <TransferInfoModal
              asset={modalItem}
              client={client}
              pension={personalPension}
              visible={!!modalItem}
              onDismiss={() => setModalItem(undefined)}
            />
          </Portal>            
        </>
      } 
    </>
  )
}