import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { MainAppHeaderTitle } from 'components/Typography'
import { ImpersonationBanner } from 'components/Utility/ImpersonationBanner'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { NotificationDot } from 'components/Utility/NotificationDot'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { openIntercom } from 'lib/intercomHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Platform, StatusBar, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import { intercomUnreadMessageCount } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { MIN_WIDTH_FOR_PERMANENT_MENU } from 'lib/constants'

const screenWidth = getScreenAppWidth()

export type MainAppHeaderProps = {
  sectionTitle: string
  sectionTitleInfo?: NamedInformation
}

export const MainAppHeader = (props: MainAppHeaderProps) => {
  const navigation = useNavigation()

  const intercomUnreadMessages = useAppSelector(intercomUnreadMessageCount)

  const authUser = useSelector((state: any) => state.auth.user)
  const impersonation = authUser?.impersonation
  
  const { sectionTitle, sectionTitleInfo } = props

  const handleMenuPress = () => {
    //@ts-ignore
    navigation.openDrawer()
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const isWeb = platformIsWeb()

  const insets = useSafeAreaInsets()

  return (
    <View style={[localStyles.headerContainer, {
      paddingTop: insets.top
    }]}>
      <StatusBar barStyle={'light-content'} />
      <View style={{
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}>
        {
          impersonation ? <ImpersonationBanner /> : <></>
        }
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            { !isWeb || screenWidth < MIN_WIDTH_FOR_PERMANENT_MENU ?  
              <MaterialCommunityIcons
                name="menu"
                size={Sizing.x25}
                color={themeColors.primary}
                onPress={handleMenuPress}
              />
              : <></>
            }
          </View>
          <View style={localStyles.headerTitleContainer}>
            <View style={Flex.row.center} >
              <View style={{ width: Sizing.x30, paddingRight: Sizing.x10 }}></View>
              <MainAppHeaderTitle>{sectionTitle}</MainAppHeaderTitle>
              <View style={{ width: Sizing.x30, paddingLeft: Sizing.x10 }}>
                { sectionTitleInfo !== undefined ?
                  <NamedInformationButton name={sectionTitleInfo} iconOnly={true} iconOnlySize={Sizing.x15} />
                : <></>
                }
              </View>
            </View>
          </View>
          <View style={localStyles.headerIconContainer}>
            { !isWeb ? 
              <>
                <MaterialIcons name={'contact-support'} size={Sizing.x25} color={themeColors.primary} onPress={openIntercom}/>
                {
                  intercomUnreadMessages > 0
                    ? <NotificationDot
                        top={scaleNormalizer(0)}
                        left={scaleNormalizer(27)}
                        count={intercomUnreadMessages}
                      />
                    : <></>
                }
              </>
              : <></>
            }
          </View>
        </View>
      </View>
    </View>
  )
}

const localStyles = StyleSheet.create({
  headerContainer: {
    // ...Flex.column.center,
    paddingTop: Sizing.x10,
    backgroundColor: Platform.select({
      web: Colors.brand.grey4,
      default: Colors.brand.purple0,
    }),
  },
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
    height: Sizing.x40,

  },
  headerIconContainer: {
    ...Flex.column.start,
    width: Sizing.x50,
    paddingTop: Sizing.x5,
    height: Sizing.x60,
    alignItems: 'center'
  },
  headerIconSubContainer: {
    position: 'absolute',
    top: Sizing.x10,
  },
  headerTitleContainer: {
    ...Flex.column.start,
    flex: 1,
    paddingTop: Sizing.x10,
    height: Sizing.x60,
    paddingHorizontal: Sizing.x20,
  },
})