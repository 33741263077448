import { MakeContributionsStepIdentifier } from "store/uxSlice"
import { sample } from 'lodash'

export const getHeadlineAndSubHeading = (allSteps: MakeContributionsStepIdentifier[], nextStep: MakeContributionsStepIdentifier): { headline: string, subHeading: string, buttonTitle: string } => {
  const stepCount = allSteps.length
  if (!stepCount) {
    return {
      headline: `Looks like you're all done!`,
      subHeading: `You didn't elect to make any changes`,
      buttonTitle: 'Finish',
    }
  }
  const isFirst = allSteps[0] === nextStep
  const isOnly = isFirst && stepCount === 1

  //Only step
  if (isOnly) {
    return {
      headline: `Ok, let's make that change`,
      subHeading: `It'll only take a couple of minutes`,
      buttonTitle: 'Start',
    }
  }

  //First step
  if (isFirst) {
    return {
      headline: `Ok, let's make those changes`,
      subHeading: `We'll lead you through to get everything set up`,
      buttonTitle: 'Start',
    }
  }

  const isLast = allSteps[stepCount -1 ] === nextStep
  //Last step
  if (isLast) {
    return {
      headline: `You're nearly there`,
      subHeading: `Just one more step to complete`,
      buttonTitle: 'Continue',
    }
  }

  //In the middle
  return {
    headline: sample([`Great job!`, `Excellent job!`, ]),
    subHeading: sample([`Let's complete the remaining steps`, `Keep going to complete the steps`, ]),
    buttonTitle: 'Continue',
  }
}
