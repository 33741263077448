import React from 'react'
import { Text } from "react-native-paper"
import { Typography, Paper } from "styles"
import { TypographyProps } from './typography.props'

export const Headline = (props: TypographyProps) => {

  const { colors: themeColors } = Paper.useAppTheme()

  const styles = [
    Typography.defined.headline,
    {
      color: themeColors.headlineText,
    },
    props.style,
  ]

  return (
    <Text {...props} allowFontScaling={false} style={styles}>
      {props.children}
    </Text>
  )
}