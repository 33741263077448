import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getScenarioGenerationMessages } from 'lib/loadingHelpers'
import { Logger } from 'lib/logger'
import { last, compact } from 'lodash'
import { GoalScenariosInputs, SurplusStrategy, useGuidanceContext } from 'providers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLazyGetScenariosQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { ScenarioModelRepresentationDto } from 'store/dto/model.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'
import { SuggestionIncreaseSurplusModalContent } from '../Components/SuggestionIncreaseSurplusModalContent'

export const Contributions_31_SuggestionsIncreaseSurplus = ({ route, navigation }) => {
  const { nextScreen, existingContributionConfiguration, existingRegularContribution, existingTargetRetirementAge } = route.params

  const dispatch = useAppDispatch()
  const { getGoalScenarios, currentModel, currentModelError, currentModelIsLoading, currentModelIsFetching, refetchCurrentModel } = useGuidanceContext()

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const surplusStrategy = workingSuggestionData?.surplusStrategy

  const [getScenarios, { data: scenarios, error: scenariosError, isLoading: scenariosIsLoading, isFetching: scenariosIsFetching }] = useLazyGetScenariosQuery()
  const isLoading = scenariosIsLoading || currentModelIsLoading || currentModelIsFetching
  const error: any = scenariosError || currentModelError
  
  //Get scenarios on load
  useEffect(() => {
    getSuggestions()
  }, [])

  const getSuggestions = () => {
    const props: GoalScenariosInputs = {
      goal: workingSuggestionData?.goal,
      surplusStrategy,
      proposedContributionSource: existingContributionConfiguration?.contributionSource || workingSuggestionData?.contributionSource || ContributionSource.PERSONAL,
    }
    const scenarioRequest = getGoalScenarios(props)
    getScenarios({ scenarioRequest, setId: workingSuggestionData?.goal })
  }

  const formObj = useForm<{ scenarioId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      // scenarioId: undefined
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const isEmployer = workingSuggestionData?.contributionSource === ContributionSource.EMPLOYER
  const existingRegularAmount = existingRegularContribution?.amount || 0

  let options: ManagedCardChoiceInputOption[] = []

  const filterModels = (): ScenarioModelRepresentationDto[] => {
    if (!scenarios) {
      return []
    }

    //No filtering at present
    const usefulModels = scenarios

    //Create an array of useful models
    return usefulModels
  }

  const allModels: ScenarioModelRepresentationDto[] = filterModels()

  const buildOptions = (models: ScenarioModelRepresentationDto[]) => {
    const newOptions: ManagedCardChoiceInputOption[] = allModels.map(model => {
      const { proposedOneOffContribution, proposedMonthlyContributions, proposedRetirementAge, identifier } = model?.scenario || {}

      const lastTerm = last(model?.terms)
      const surplus = lastTerm?.startValue || 0

      const title = `Leave around ${formatCurrencyAmount(surplus, 0)}`
      let description: string = ''
      const illustrationFilename = 'choose_beneficiaries.png'
      switch (surplusStrategy) {
        case SurplusStrategy.INCREASE_REGULAR:
          description = existingRegularAmount
            ? `Increase monthly amount by ${formatCurrencyAmount(proposedMonthlyContributions - existingRegularAmount, 2)} to ${formatCurrencyAmount(proposedMonthlyContributions, 2)}`
            : `Start monthly contributions of ${formatCurrencyAmount(proposedMonthlyContributions, 2)}`
          break
        case SurplusStrategy.LUMP_SUM:
          description = `Make a one-off contribution of ${formatCurrencyAmount(proposedOneOffContribution, 2)}`
          break
        case SurplusStrategy.RETIRE_LATER:
          description = `Defer retirement until you are aged ${proposedRetirementAge}`
          break
        default:
          break
      }

      return {
        value: identifier,
        title,
        description,
        illustrationFilename,
        modalContentFunction: () => <SuggestionIncreaseSurplusModalContent
          surplusStrategy={surplusStrategy}
          title={title}
          description={description}
          model={model}
          illustrationFilename={illustrationFilename}
        />,
        modalProceedLabel: 'Set up Now',
        modalCancelLabel: 'Choose Another',
      }

    })    
    options = compact(newOptions)
  }

  //Build options when available
  if (scenarios) {
    buildOptions(scenarios)
  }

  const onSubmit = (attributes) => {
    const { scenarioId } = attributes
    //Find the scenario by identifier
    const selectedModel = allModels.find(model => {
      return model.scenario.identifier === attributes.scenarioId
    })

    if (!selectedModel) {
      Logger.error(`No matching model found for scenarioId`, scenarioId)
      return
    }

    const proposedOneOffContribution = selectedModel?.scenario?.proposedOneOffContribution
    const proposedMonthlyContributions = selectedModel?.scenario?.proposedMonthlyContributions
    //Note this is setting the TARGET to be modified in MakeContributionsProcessStack
    const proposedTargetRetirementAge = selectedModel?.scenario?.proposedRetirementAge

    dispatch(updateWorkingSuggestion({
      proposedOneOffContribution,
      proposedMonthlyContributions,
      proposedTargetRetirementAge,
    }))
    navigation.navigate('Execute')
  }

  const goToManual = () => {
    hideDialog()
    navigation.navigate('ManualSetup')
  }

  const hasSuggestions = options.length

  return (    
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={getScenarioGenerationMessages()}
      buttonTitle={error ? 'Try Again' : 'Next'}
      buttonAction={error
        ? currentModelError
          ? refetchCurrentModel
          : getSuggestions
        : handleSubmit(onSubmit)
      }
      showButton={true}
      enableButton={error || isValid}
      headline={hasSuggestions && !error
        ? `Ok, here's a few options for you`
        : `Sorry, we couldn't find any answers`
      }
      subHeading={hasSuggestions && !error
          ? surplusStrategy === SurplusStrategy.INCREASE_REGULAR ? `The suggestions below would leave additional surplus by ${existingRegularAmount ? 'increasing' : 'starting'} regular contribtuions`
            : surplusStrategy === SurplusStrategy.LUMP_SUM ? `The suggestions below would leave additional surplus by making a one-off contribtuion`
            : `The suggestions below would leave additional surplus by retiring later`
          : `We couldn't generate any suggestions, but you can still set up contributions manually`
      }
      allowTextButton={true}
      textButtonTitle={existingRegularAmount ? `Change contributions manually` : `Set up contributions manually`}
      textButtonAction={hasSuggestions ? showDialog : goToManual}
    >
      {
        error
          ? <></>
          : <ManagedCardChoiceInput
              formObj={formObj}
              name={'scenarioId'}
              submitHandler={handleSubmit(onSubmit)}
              required={true}
              options={options}
            />
      }
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`You can always choose to contribute any amount, however you will only see the impact after your changes`}
        onCancel={hideDialog}
        onConfirm={goToManual}
        confirmLabel={'Set up Manually'}
      />
    </ProcessScreen>

  )
}