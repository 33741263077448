import { ClientVerification_01_Shortcut, ClientVerification_02_Nationality, ClientVerification_03_AddressSearch, ClientVerification_04_SetAddress, ClientVerification_05_MaritalStatus, ClientVerification_06_EmploymentStatus, ClientVerification_07_NationalInsuranceNo, ClientVerification_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder, ProcessStackBuilderScreen } from 'navigation/stacks/ProcessStackBuilder'
import { ClientMeDto } from 'store/dto/client.dto'
import { concat } from 'lodash'

export const ClientVerificationProcessStack = ({ route, navigation }) => {
  const { client }: { client: ClientMeDto } = route?.params || {}

  const hasAllDetails = client?.nationality
    && client?.addressDetail
    && client?.maritalStatus
    && client?.employmentStatus
    && client?.nationalInsuranceNo

  const processScreens: ProcessStackBuilderScreen[] = concat(
    hasAllDetails ? [
      { 
        navigationId: 'Shortcut',
        component: ClientVerification_01_Shortcut,
      },
    ] : [],
    [
      {
        navigationId: 'Nationality',
        component: ClientVerification_02_Nationality,
      },
      {
        navigationId: 'AddressSearch',
        component: ClientVerification_03_AddressSearch,
      },
       {
        navigationId: 'AddressSetup',
        component: ClientVerification_04_SetAddress,
      },
      {
        navigationId: 'MaritalStatus',
        component: ClientVerification_05_MaritalStatus,
      },
      {
        navigationId: 'EmploymentStatus',
        component: ClientVerification_06_EmploymentStatus,
      },
      {
        navigationId: 'NationalInsuranceNo',
        component: ClientVerification_07_NationalInsuranceNo,
      },
    ]
  )

  return (
    <ProcessStackBuilder
      navigatorId='ClientVerification'
      processTitle='Identity Verification'
      previousRoute='ClientVerificationIntroScreen'
      nextRoute='ClientVerificationHeroScreen'
      initialParams={route?.params}
      processScreens={processScreens}
      resultScreen={{
        navigationId: 'Results',
        component: ClientVerification_99_Result,
      }}
    />
  )
}
