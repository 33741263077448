import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeForecastTreatment_03_CustomEmployerAmount = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}

  const dispatch = useAppDispatch()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const { customPayrollFrequency } = workingSetupSchemeEnrolmentData

  //Setup form
  const formObj = useForm<{ customEmployerContributionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      customEmployerContributionAmount: workingSetupSchemeEnrolmentData?.customEmployerContributionAmount
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSetupSchemeEnrolment(attributes))
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const eachPhrase =
    customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.ANNUALLY ? ' each year'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.BI_ANNUALLY ? ' every 6 months'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.QUARTERLY ? ' each quarter'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.MONTHLY ? ' each month'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.FOUR_WEEKLY ? ' every 4 weeks'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.FORTNIGHTLY ? ' each fortnight'
    : customPayrollFrequency === GroupSchemeEnrolmentPayrollFrequency.WEEKLY ? ' each week'
    : ''

  return (
    <ModalProcessScreen
      buttonTitle={`Finish`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={true}
      headline={`And the average pension contribution?`}
      subHeading={`The total gross contribution${eachPhrase} into your pension from this employer`}
    >
      <ManagedCurrencyInput
        name={'customEmployerContributionAmount'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        // label={'Current Value'}
        placeholder={'Enter gross amount'}
        required={true}
        minValue={1}
        maxValue={10000}
        blurOnSubmit={true}
      />
    </ModalProcessScreen>
  )
}
