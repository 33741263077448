import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetMeQuery } from 'store/apiSlice'
import { AmlStatus } from 'store/dto/client.dto'

export const ClientVerificationHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: client, error, isLoading, isFetching } = useGetMeQuery()

  const amlStatus = client?.amlStatus

  const passed = amlStatus === AmlStatus.PASS

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)
  
  const carouselScreens: ProcessHeroCarouselData[] = passed ?
    [
      {
        illustrationFilename: 'had_a_feeling_it_was_you.png',
        headline: `We had a feeling it was you!`,
        subHeading: `We were able to verify your identity electronically`,
      },
      {
        illustrationFilename: 'good_to_go.png',
        headline: `You're good to go`,
        subHeading: `You can now choose your Jarvis Plan and start investing in your future`,
      },
    ]
  : amlStatus === AmlStatus.FAIL ?
    [
      {
        illustrationFilename: 'verify_your_identity.png',
        headline: `We're sorry...`,
        subHeading: `We were unable to verify your identity electronically`,
      },
      {
        illustrationFilename: 'support.png',
        headline: `Please contact support`,
        subHeading: `Please contact our team to discuss`,
      },
    ]
  :
    [
      {
        illustrationFilename: 'verify_your_identity.png',
        headline: `We're sorry...`,
        subHeading: `We were unable to verify your identity electronically`,
      },
      {
        illustrationFilename: 'magnifying_glass.png',
        headline: `Leave it with us`,
        subHeading: `Our support team will check your details and try to verify you`,
      },
      {
        illustrationFilename: 'support.png',
        headline: `We'll be in touch`,
        subHeading: `We'll let you know if we need any further information from you`,
      },
    ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading || isFetching}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
