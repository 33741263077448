import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerPaymentsListScreen } from './Screens/EmployerPaymentsListScreen'

export const EmployerPaymentsSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Pending Payments'}
    >
      <EmployerPaymentsListScreen />
    </SectionSimpleContainer>
  )
}

