import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getActionDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeRequestType, SchemeEnrolmentChangeRequestDto } from 'store/dto/account.dto'
import { Paper, Sizing } from 'styles'

type RequestListItemProp = {
  request: SchemeEnrolmentChangeRequestDto
  onPressFunction?: () => void
}
export const RequestListItem = (props: RequestListItemProp) => {
  const { request, onPressFunction } = props
  const { requestType, status, createdAt, actionDate, actionMessage } = request || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName =
    requestType === GroupSchemeRequestType.OPT_OUT
    ? 'account-cancel-outline'
    : requestType === GroupSchemeRequestType.OPT_IN
    ? 'account-arrow-left-outline'
    : requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP
    ? 'account-arrow-right-outline'
    : requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
    ? 'account-cash-outline'
    : 'account-cog-outline'

  const title =
    requestType === GroupSchemeRequestType.OTHER
    ? 'Other Request'
    : requestType

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      // titleRight={status}
      subTitle={getActionDate(createdAt, true)}
      titleStyle={{ color: themeColors.accent }}
      subTitleRight={<Text>{status}</Text>}
    />
  )
}
