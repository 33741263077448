import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { AdminAffiliateSectionScreen } from 'features/AdminSections/Affiliates/AdminAffiliateSectionScreen'
import { AdminAssetCategorySectionScreen } from 'features/AdminSections/AssetCategories/AdminAssetCategorySectionScreen'
import { AdminAssetSectionScreen } from 'features/AdminSections/Assets/AdminAssetSectionScreen'
import { AdminBankConnectionSectionScreen } from 'features/AdminSections/BankConnections/AdminBankConnectionSectionScreen'
import { AdminClientsSectionScreen } from 'features/AdminSections/Clients/AdminClientsSectionScreen'
import { AdminDashboardSectionScreen } from 'features/AdminSections/Dashboard/AdminDashboardSectionScreen'
import { AdminExceptionSectionScreen } from 'features/AdminSections/Exceptions/AdminExceptionSectionScreen'
import { AdminFeatureSectionScreen } from 'features/AdminSections/Features/AdminFeatureSectionScreen'
import { AdminFinancialsSectionScreen } from 'features/AdminSections/Financials/AdminFinancialsSectionScreen'
import { AdminGroupOrganizationSectionScreen } from 'features/AdminSections/GroupOrganizations/AdminGroupSchemeSectionScreen'
import { AdminGroupPortfolioSectionScreen } from 'features/AdminSections/GroupPortfolios/AdminGroupPortfolioSectionScreen'
import { AdminGroupSchemeSectionScreen } from 'features/AdminSections/GroupSchemes/AdminGroupSchemeSectionScreen'
import { AdminInvestmentPlanSectionScreen } from 'features/AdminSections/InvestmentPlans/AdminInvestmentPlanSectionScreen'
import { AdminPensionBrandSectionScreen } from 'features/AdminSections/PensionBrands/AdminPensionBrandSectionScreen'
import { AdminPensionProviderSectionScreen } from 'features/AdminSections/PensionProviders/AdminPensionProviderSectionScreen'
import { AdminTransfersSectionScreen } from 'features/AdminSections/Transfers/AdminTransfersSectionScreen'
import { AdminUserSectionScreen } from 'features/AdminSections/Users/AdminUserSectionScreen'
import { ModalProcesses } from 'features/ModalProcesses/ModalProcesses'
import { changeAppContext, goToLogout } from 'lib/RootNavigation'
import { userIsAdministrator } from 'lib/authHelpers'
import { getScreenAppWidth, scaleNormalizer, webMaxWidth } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { AdminAppNavScreen } from 'lib/navigationHelpers'
import { DrawerScreen, DrawerScreenGroup } from 'navigation/components/CustomDrawerItemList'
import React from 'react'
import { Image, StyleProp, StyleSheet, TextStyle, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { Colors, Flex, Sizing, Typography } from 'styles'
import { AdminAppStackDrawerContent } from './Components/AdminAppStackDrawerContent'
import { LoadingScreen } from './Gates'
import { platformIsWeb } from 'lib/platformHelpers'
import { useFocusEffect } from '@react-navigation/native'
import { MIN_WIDTH_FOR_PERMANENT_MENU } from 'lib/constants'
import { AppIllustration } from 'components/Utility/AppIllustration'

const screenWidth = getScreenAppWidth()

const Drawer = createDrawerNavigator()
const DRAWER_ICON_SIZE = 20

export const AdminAppStack = () => {
  Logger.info(`#### Stack: AdminApp`)

  const dispatch = useAppDispatch()

  //Determine if user is admin
  const authUser = useSelector((state: any) => state.auth.user)
  const userIsAdmin = userIsAdministrator(authUser)
  
  const { data: user, error, isLoading, refetch } = useGetCurrentUserQuery()

  //Determine if user has group organizations
  const userHasGroupOrganizations = user?.isGroupOrganizationUser

  const isWeb = platformIsWeb()
  
  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.ADMIN, user, dispatch, false)
  })

  const dashboardDrawerScreenGroup: DrawerScreenGroup = {
    id: '01_dashboard',
    title: 'Dashboard',
    renderFlat: true,
  }

  const clientDataDrawerScreenGroup: DrawerScreenGroup = {
    id: '02_client_data',
    title: 'Client Data',
    defaultExpanded: true,
    renderFlat: false,
  }

  const transfersDrawerScreenGroup: DrawerScreenGroup = {
    id: '03_transfers',
    title: 'Transfers',
    renderFlat: false,
  }

  const partnershipsDrawerScreenGroup: DrawerScreenGroup = {
    id: '04_partnerships',
    title: 'Partnerships',
    renderFlat: false,
  }
  
  const investmentChoiceDrawerScreenGroup: DrawerScreenGroup = {
    id: '05_investment_choice',
    title: 'Investment Choice',
    renderFlat: false,
  }
  
  const referenceDataDrawerScreenGroup: DrawerScreenGroup = {
    id: '06_reference_data',
    title: 'Reference Data',
    renderFlat: false,
  }
    
  const groupPensionDrawerScreenGroup: DrawerScreenGroup = {
    id: '07_gsipp',
    title: 'Group Pension',
    renderFlat: false,
  }

  const systemDrawerScreenGroup: DrawerScreenGroup = {
    id: '08_system',
    title: 'System',
    renderFlat: false,
  }

  const screenGroupMap = {
    [AdminAppNavScreen.DASHBOARD]: dashboardDrawerScreenGroup,
    [AdminAppNavScreen.FINANCIALS]: dashboardDrawerScreenGroup,
    [AdminAppNavScreen.USERS]: clientDataDrawerScreenGroup,
    [AdminAppNavScreen.CLIENTS]: clientDataDrawerScreenGroup,
    [AdminAppNavScreen.TRANSFERS]: transfersDrawerScreenGroup,
    [AdminAppNavScreen.BRANDS]: transfersDrawerScreenGroup,
    [AdminAppNavScreen.PROVIDERS]: transfersDrawerScreenGroup,
    [AdminAppNavScreen.AFFILIATES]: partnershipsDrawerScreenGroup,
    [AdminAppNavScreen.INVESTMENT_PLANS]: investmentChoiceDrawerScreenGroup,
    [AdminAppNavScreen.GROUP_PORTFOLIOS]: investmentChoiceDrawerScreenGroup,
    [AdminAppNavScreen.ASSETS]: investmentChoiceDrawerScreenGroup,
    [AdminAppNavScreen.ASSET_CATEGORIES]: investmentChoiceDrawerScreenGroup,
    [AdminAppNavScreen.BANK_CONNECTIONS]: referenceDataDrawerScreenGroup,
    [AdminAppNavScreen.FEATURES]: referenceDataDrawerScreenGroup,
    [AdminAppNavScreen.GROUP_ORGANIZATIONS]: groupPensionDrawerScreenGroup,
    [AdminAppNavScreen.GROUP_SCHEMES]: groupPensionDrawerScreenGroup,
    [AdminAppNavScreen.EXCEPTIONS]: systemDrawerScreenGroup,
  }

  const screens: DrawerScreen[] = [
    {
      name: AdminAppNavScreen.DASHBOARD,
      component: AdminDashboardSectionScreen,
      title: 'Dashboard',
      icon: 'view-dashboard',
    },
    {
      name: AdminAppNavScreen.FINANCIALS,
      component: AdminFinancialsSectionScreen,
      title: 'Financials',
      icon: 'finance',
    },
    {
      name: AdminAppNavScreen.USERS,
      component: AdminUserSectionScreen,
      title: 'Users',
      icon: 'account-lock',
    },
    {
      name: AdminAppNavScreen.CLIENTS,
      component: AdminClientsSectionScreen,
      title: 'Clients',
      icon: 'account-group',
    },
    {
      name: AdminAppNavScreen.TRANSFERS,
      component: AdminTransfersSectionScreen,
      title: 'Inward Transfers',
      icon: 'consolidate',
    },
    {
      name: AdminAppNavScreen.BRANDS,
      component: AdminPensionBrandSectionScreen,
      title: 'Pension Brands',
      icon: 'police-badge-outline',
    },
    {
      name: AdminAppNavScreen.PROVIDERS,
      component: AdminPensionProviderSectionScreen,
      title: 'Pension Providers',
      icon: 'bank-transfer',
    },
    {
      name: AdminAppNavScreen.AFFILIATES,
      component: AdminAffiliateSectionScreen,
      title: 'Affiliates',
      icon: 'link-variant',
    },
    {
      name: AdminAppNavScreen.INVESTMENT_PLANS,
      component: AdminInvestmentPlanSectionScreen,
      title: 'Investment Plans',
      icon: 'file-chart-outline',
    },
    {
      name: AdminAppNavScreen.GROUP_PORTFOLIOS,
      component: AdminGroupPortfolioSectionScreen,
      title: 'Group Portfolios',
      icon: 'briefcase-variant-outline',
    },
    {
      name: AdminAppNavScreen.ASSETS,
      component: AdminAssetSectionScreen,
      title: 'Assets',
      icon: 'chart-bar',
    },
    {
      name: AdminAppNavScreen.ASSET_CATEGORIES,
      component: AdminAssetCategorySectionScreen,
      title: 'Asset Categories',
      icon: 'format-list-bulleted-type',
    },
    {
      name: AdminAppNavScreen.BANK_CONNECTIONS,
      component: AdminBankConnectionSectionScreen,
      title: 'Bank Connections',
      icon: 'bank',
    },
    {
      name: AdminAppNavScreen.FEATURES,
      component: AdminFeatureSectionScreen,
      title: 'User Features',
      icon: 'list-status',
    },
    {
      name: AdminAppNavScreen.GROUP_ORGANIZATIONS,
      component: AdminGroupOrganizationSectionScreen,
      title: 'Organizations',
      icon: 'office-building',
    },
    {
      name: AdminAppNavScreen.GROUP_SCHEMES,
      component: AdminGroupSchemeSectionScreen,
      title: 'Schemes',
      icon: 'briefcase',
    },
    {
      name: AdminAppNavScreen.EXCEPTIONS,
      component: AdminExceptionSectionScreen,
      title: 'Exceptions',
      icon: 'clipboard-alert-outline',
    },
  ]

  function getDrawerIcon(iconName, focused) {
    return (
      <MaterialCommunityIcons
        name={iconName}
        size={DRAWER_ICON_SIZE}
        color={focused ? Colors.brand.purple2 : Colors.brand.grey2}
      />
    )
  }

  return (
    <>
      {
        isLoading ? <LoadingScreen messages={[`Preparing the Admin Portal...`]} />
        :
          <Portal.Host>
            {
              userIsAdmin ?
                <>
                  <Drawer.Navigator
                    id="AdminApp"
                    initialRouteName={AdminAppNavScreen.DASHBOARD}
                    drawerContent={(props) => <AdminAppStackDrawerContent
                      {...props}
                      screens={screens}
                      screenGroupMap={screenGroupMap}
                    />}
                    screenOptions={{
                      headerShown: false,
                      headerShadowVisible: false,
                      drawerActiveTintColor: Colors.brand.purple2,
                      drawerType: isWeb ? screenWidth < MIN_WIDTH_FOR_PERMANENT_MENU ? 'front' : 'permanent' : 'slide',
                      drawerStyle: {
                        backgroundColor: Colors.brand.purplex0,
                      },
                      drawerLabelStyle: Typography.defined.mainAppDrawerItem,
                      sceneContainerStyle: isWeb ? {
                        backgroundColor: isWeb ? Colors.brand.grey4 : undefined,
                        padding: Sizing.x20,
                        maxWidth: webMaxWidth,
                      } : undefined
                    }}>
                    <Drawer.Group>
                      {
                        screens.map((screen, idx) => {

                          const headerLabelStyle: StyleProp<TextStyle> =  {
                            color: Colors.brand.purple1,
                            textAlign: 'center',
                            paddingLeft: Sizing.x20,
                          }

                          return (
                            <Drawer.Screen
                              key={idx}
                              name={screen.name}
                              component={screen.component}
                              options={{
                                title: screen.title,
                                drawerIcon: ({ focused, color, size }) => getDrawerIcon(screen.icon, focused),
                                drawerItemStyle: {
                                  display: screen.hide ? 'none' : undefined,
                                },
                              }}
                            />
                          )
                        })
                      }
                    </Drawer.Group>
                  </Drawer.Navigator>
                  <ModalProcesses/>
                </>
              :
                <BackgroundGradientPurple>
                  <SafeAreaView style={{
                    ...Flex.column.center,
                    ...Flex.override.fill
                  }}>
                    <View style={{
                      paddingTop: Sizing.x30,
                      paddingHorizontal: Sizing.x30,
                      ...Flex.column.center,
                      ...Flex.override.fill,
                    }}>
                      <Headline>{`Sorry, you don't have access`}</Headline>
                      <Subheading>{`Access to the Jarvis Administrator Portal is restricted.`}</Subheading>
                      <AppIllustration filename={'magnifying_glass.png'} style={{
                        width: '100%',
                        height: Sizing.x200,
                        resizeMode: 'contain',
                        alignSelf: 'center'
                      }}/>
                      {
                        user ?
                        <>
                          <Subheading>{`Looking for the Jarvis Client App?`}</Subheading>
                          <Button
                            mode='text'
                            icon={() => <MaterialCommunityIcons name={'exit-to-app'} size={Sizing.x20} color={Colors.brand.purple1} />}
                            onPress={() => changeAppContext(AppContext.CLIENT, user, dispatch, true)}>
                            {'Switch to Client App'}
                          </Button>
                          {
                            userHasGroupOrganizations ?
                              <>
                                <Subheading>{`Or the Jarvis Employer Portal?`}</Subheading>
                                <Button
                                  mode='text'
                                  icon={() => <MaterialCommunityIcons name={'exit-to-app'} size={Sizing.x20} color={Colors.brand.purple1} />}
                                  onPress={() => changeAppContext(AppContext.EMPLOYER, user, dispatch, true)}>
                                  {'Switch to Employer Portal'}
                                </Button>
                              </>
                              : <></>
                          }
                          </>
                          : <></>
                      }
                      <FooterButton
                        mode='contained'
                        onPress={goToLogout}
                      >
                        {user ? 'Switch User' : 'Go to Login'}
                      </FooterButton>
                    </View>
                </SafeAreaView>
              </BackgroundGradientPurple> 
            }
          </Portal.Host>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  profileContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: Sizing.x10,
  },
  smallProfileImageContainer: {
  },
  smallProfileImage: {
    alignSelf: 'center',
    height: scaleNormalizer(60),
    width: scaleNormalizer(60),
    borderRadius: scaleNormalizer(30),
  },
})
