import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Headline, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer, getScreenWidthConstraints } from 'lib/scaleHelpers'
import React, { forwardRef } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Flex, Sizing } from 'styles'

export type NewUserCarouselProps = {
  onCompletion?: any
  data: NewUserCarouselData[]
  skipped: boolean
}

export type NewUserCarouselData = {
  illustrationFilename?: string
  imageSource?: any
  headline: string
  subHeading?: string | React.ReactNode
  subHeadingInfo?: React.ReactNode
}

export const NewUserCarousel = forwardRef((props: NewUserCarouselProps, ref: any ) => {
  const { data, onCompletion, skipped } = props

  const isWeb = platformIsWeb()
  const screenWidth = getScreenAppWidth()
  const screenHeight = getScreenAppHeight()
  const useHorizontalLayout = isWeb && screenWidth >= 1000
  const insets = useSafeAreaInsets()
  const verticalInset = insets.bottom + insets.top
  const sliderWidth = screenWidth - (Sizing.x30 * 2)
  const carouselWidth = sliderWidth - (useHorizontalLayout ? scaleNormalizer(200) : 0)
  const carouselHeight = useHorizontalLayout ? scaleNormalizer(screenHeight - 320) : scaleNormalizer(screenHeight - verticalInset - 300)

  const renderItem = ({ item, index }) => {
    const { imageSource, illustrationFilename, headline, subHeading } = item

    function CarouselHeadline() {
      return (
        <View style={{
          paddingBottom: Sizing.x10,
        }}>
          {headline ?
            <Headline
              adjustsFontSizeToFit
              numberOfLines={3}
              style={{
                color: Colors.brand.purple1,
                paddingTop: Sizing.x10,
                fontSize: Sizing.x30,
                lineHeight: Sizing.x30,
                textAlign: useHorizontalLayout ? 'left' : 'center',
              }}
            >{headline}</Headline>
            : <></>
          }
        </View>
      )
    }

    function CarouselSubHeading() {
      return (
        <View style={{
        }}>
          {
            subHeading ?
              <Subheading
                numberOfLines={3}
                adjustsFontSizeToFit={true}
                style={{
                  paddingTop: Sizing.x10,
                  fontSize: Sizing.x15,
                  lineHeight: Sizing.x15,
                  textAlign: useHorizontalLayout ? 'left' : 'center',
                }}
              >{subHeading}</Subheading>
              : <></>
          }
        </View>
      )
    }

    function CarouselImage() {
      return (
        <View style={{
          paddingVertical: Sizing.x10,
        }}>
          {
            imageSource ?
            <Image source={imageSource} style={{
              width: useHorizontalLayout ? screenHeight - scaleNormalizer(250) : scaleNormalizer(280),
              height: useHorizontalLayout ? (screenWidth - scaleNormalizer(300))/2 : carouselHeight / 2,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} /> : illustrationFilename ?
            <AppIllustration filename={illustrationFilename} style={{
              width: useHorizontalLayout ? screenHeight - scaleNormalizer(250) : scaleNormalizer(280),
              height: useHorizontalLayout ? (screenWidth - scaleNormalizer(300))/2 : carouselHeight / 2,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} /> : 
            <></>
          }
        </View>
      )
    }

    return (
      <>
        {
          useHorizontalLayout
          ? <View style={{
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}>
              <View style={{
                ...Flex.row.between,
              }}>
                <View style={{
                  ...Flex.column.center,
                  flex: 1,
                }}>
                  <CarouselHeadline />
                  <CarouselSubHeading />
                </View>
                <View style={{
                  flex: 1,
                }}>
                  <CarouselImage />
                </View>
              </View>
            </View>
          : <View style={{
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: carouselHeight,
            }}>
              <CarouselImage />
              <CarouselHeadline />
              <CarouselSubHeading />
            </View>
        }
      </>
    )
  }
  
  const snapToPrevious = () => {
    ref?.current?.prev()
  }

  const snapToNext = () => {
    ref?.current?.next()
  }

  const viewConstraints: any = getScreenWidthConstraints()

  return (
    <View style={[
      {
        flexDirection: 'row',
        justifyContent: 'center',
      },
      viewConstraints,
    ]}>
      {
        useHorizontalLayout ? <View style={localStyles.buttonGutter}>
          <MaterialCommunityIcons name={'chevron-left-circle'} size={Sizing.x50} color={Colors.brand.purple1} onPress={snapToPrevious} />
        </View>
        : <></>
      }
      <ManagedCarousel
        autoPlay={skipped}
        autoPlayInterval={3000}
        loop={false}
        ref={ref}
        data={data}
        onCompletion={onCompletion}
        renderItem={renderItem}
        width={carouselWidth}
        height={carouselHeight}
      />
      {
        useHorizontalLayout ? <View style={localStyles.buttonGutter}>
          <MaterialCommunityIcons name={'chevron-right-circle'} size={Sizing.x50} color={Colors.brand.purple1} onPress={snapToNext} />
        </View>
        : <></>
      }
    </View>
  )
})

const localStyles = StyleSheet.create({
  buttonGutter: {
    ...Flex.column.center,
    alignItems: 'center',
    width: Sizing.x100,
  }
})