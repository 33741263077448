import { ViewStyle } from "react-native"

type Row = "start" | "end" | "center" | "around" | "between"
export const row: Record<Row, ViewStyle> = {
  start: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  end: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  center: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  around: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  between: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

type Column = "start" | "end" | "center" | "around" | "between"
export const column: Record<Column, ViewStyle> = {
  start: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  end: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  center: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  around: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  between: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}

type Override = "top" | "bottom" | "left" | "right" | "fill"
export const override: Record<Override, ViewStyle> = {
  top: {
    marginTop: 'auto',
  },
  bottom: {
    marginBottom: 'auto',
  },
  left: {
    marginRight: 'auto',
  },
  right: {
    marginLeft: 'auto',
  },
  fill: {
    flex: 1,
    flexGrow: 1,
  },
}
