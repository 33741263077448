import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetRetirementAssetsQuery } from 'store/apiSlice'

export const BulkTransferHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding, regularContributionGrossValue }: { fromOnboarding: OnboardingPhase, regularContributionGrossValue: number }  = route?.params || {}

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'support.png',
      headline: `Over to us`,
      subHeading: `Our team will start tracking down your old pensions with the pension providers`,
    },
    {
      illustrationFilename: 'magnifying_glass.png',
      headline: `About finding pensions`,
      subHeading: `If you have opted for our Pension Finding Service, we may be in touch by email if we need more details`,
    },
    {
      illustrationFilename: 'clock.png',
      headline: `Bear with us`,
      subHeading: `It can take 2-6 weeks to transfer pensions from old providers`,
    },
  ]

  if (regularContributionGrossValue) {
    carouselScreens.push({
      illustrationFilename: 'regular_contributions.png',
      headline: `Don't forget your regular contributions!`,
      subHeading: `When your transfers complete, you may want to adjust monthly contributions into your ${JAR_NAME_PERSONAL}`,
    })
  }

  return (
    <ProcessHeroScreen
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
