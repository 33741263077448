import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminAssetsListScreen } from './Screens/AdminAssetListScreen'

export const AdminAssetSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Assets'}
    >
      <AdminAssetsListScreen />
    </SectionSimpleContainer>
  )
}

