  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { Paragraph } from 'components/Typography'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { View } from 'react-native'
import { Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { useAppTheme } from 'styles/paper'
import { Button } from './Button'
import { BulletItem } from 'components/Typography/BulletItem'

type PensionProviderReferenceFormatInformationSet = {
  formObj: UseFormReturn<any>
  name: string
}

const isWeb = platformIsWeb()

export const PensionProviderReferenceFormatInformationSet = (props: PensionProviderReferenceFormatInformationSet) => {
  const { formObj, name } = props
  const { control } = formObj
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name,
  })

  const { colors: themeColors } = useAppTheme()

  const fieldCount = fields.length

  return (
    <View style={layoutStyles.inputContainer}>
      <Paragraph>{`Add one or more sets of reference format information to help users get in contact with other pension providers.`}</Paragraph>
      <Paragraph>{`When constructing patterns, use the following, which are shown to end users`}</Paragraph>
      <BulletItem style={{ textAlign: 'left' }}>'a' means any letter</BulletItem>
      <BulletItem style={{ textAlign: 'left' }}>'d' means any number</BulletItem>
      <BulletItem style={{ textAlign: 'left' }}>'x' means a single letter or number</BulletItem>
      <BulletItem style={{ textAlign: 'left' }}>'...' means any quantity of letters or numbers</BulletItem>
      <BulletItem style={{ textAlign: 'left' }}>Uppercase letters, numbers or any other symbol mean exactly that character</BulletItem>
      {
        fields.map((field, index) => {
          return (
            <View
              key={field.id}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <View style={{ flex: 1 }}>
                  <ManagedTextInput
                    name={`${name}.${index}.data.pattern`}
                    formObj={formObj}
                    label={'Pattern'}
                    placeholder={'The pattern describing the format'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 30,
                  }}/>
                  <ManagedTextInput
                    name={`${name}.${index}.data.example`}
                    formObj={formObj}
                    label={'Example'}
                    placeholder={'An example of a valid reference matching the format'}
                    returnKeyType={'next'}
                    blurOnSubmit={false}
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 30,
                  }}/>
                </View>
                <View style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: isWeb ? Sizing.x60 : undefined,
                }}>
                  <MaterialCommunityIcons name='delete-outline' size={Sizing.x20} color={themeColors.primary} onPress={() => remove(index) }/>
                </View>
              </View>
              { fieldCount === 1 || index === fieldCount - 1 ? <></> :
                <View style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}>
                  <MaterialCommunityIcons name='arrow-up-thin' size={Sizing.x20} color={themeColors.primary} onPress={() =>swap(index, index + 1)}/>
                  <MaterialCommunityIcons name='arrow-down-thin' size={Sizing.x20} color={themeColors.primary} onPress={() =>swap(index, index + 1)}/>
                </View>
              }
            </View>
          )
        })
      }
      <Button
        mode={'text'}
        onPress={() => append({ data: {}})}
        >{fieldCount === 0 ? `Add format information set`: `Add another set`}</Button>
    </View>
  )
}
