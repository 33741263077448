import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { rootNavigate } from 'lib/RootNavigation'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useRequestCalculatorReportMutation } from 'store/apiSlice'
import { linkParams } from 'store/authSlice'
import { CalculatorSendReportRequestDto } from 'store/dto/calculator.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_99_Result = ({ route, navigation }) => {
  const { nextRoute, loggedInUserId }  = route?.params || {}
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)
    
  const linkConfig = useAppSelector(linkParams)
  const { affiliateClientRef, affiliateCode, affiliateRef } = linkConfig || {}

  const {
    baseBudgetLevel,
    email,
    netBudgetTotalAmount,
    grossBudgetTotalAmount,
    sendReport,
    timeline,
    viableRetirementAge,
    asset,
    client,
    spouse,
    income,
  } = workingCalculatorRequestData || {}

  const [ requestReport, { data: report, error: reportError, isLoading: reportIsLoading } ] = useRequestCalculatorReportMutation()

  //Save or navigate on enter
  useEffect(() => {
    if (sendReport) {
      ampli.calculatorUsage({
        processStep: 'report',
        outcome: 'sent',
        loggedInUserId,
        affiliateCode: linkConfig?.affiliateCode,
        affiliateRef: linkConfig?.affiliateRef,
      })
      doRequestReport()
    } else {
      ampli.calculatorUsage({
        processStep: 'report',
        outcome: 'none',
        loggedInUserId,
        affiliateCode: linkConfig?.affiliateCode,
        affiliateRef: linkConfig?.affiliateRef,
      })
      end()
    }
    
  }, [])

  const doRequestReport = () => {
    if (!workingCalculatorRequestData) {
      return
    }
    Logger.info(`Requesting report...`)
    const body: CalculatorSendReportRequestDto = {
      asset,
      baseBudgetLevel,
      client,
      email,
      affiliateCode,
      affiliateRef,
      affiliateClientRef,
      grossBudgetTotalAmount,
      netBudgetTotalAmount,
      income,
      timeline,
      spouse,
      viableRetirementAge
    }
    requestReport(body)
  }

  //Navigate when report requested
  useEffect(() => {
    if (report) {
      end()
    }
  }, [report])

  const end = () => {
    rootNavigate(nextRoute, route?.params)
    //Reset for the next go...
    navigation.popToTop()
  }

  return (
    <ProcessScreen
      buttonTitle={''}
      error={reportError}
      errorTryAgain={doRequestReport}
      isLoading={reportIsLoading}
      loadingMessage={['Sending your report...']}
      buttonAction={() => {}}
      showButton={false}
    />
  )
}
