import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useCloseMultipleExecptionsMutation, useGetExceptionsQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { getActionDate } from 'lib/dateHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { difference, pull, union } from 'lodash'
import { useForm } from 'react-hook-form'
import { Checkbox, Subheading } from 'react-native-paper'
import { ExceptionDto, ExceptionSystemAreaSearch, SecclNotificationStatus, SystemExceptionSourceSearch } from 'store/dto/exception.dto'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminExceptionDataId } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { BulkSelectToolbar } from 'components/Utility/BulkSelectToolbar'

const isWeb = platformIsWeb()
const iconWidth = isWeb ? Sizing.x40 : Sizing.x30

export const AdminExceptionsListScreen = () => {
  const [closeMultpleExceptions, { isLoading: closeMultipleIsLoading, error: closeMultipleError }] = useCloseMultipleExecptionsMutation()

  const [bulkSelectMode, setBulkSelectMode] = useState(false)

  const enableBulkSelect = () => {
    setBulkSelectMode(true)
  }

  const cancelBulkSelect = () => {
    setBulkSelectMode(false)
    unselectAllRecords()
  }

  const [closeDialogVisible, setCloseDialogVisible] = useState(false)

  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowExceptionDetails = (id: string) => {
    dispatch(setCurrentAdminExceptionDataId(id))
  }

  type OpenExceptionProps = {
    exception: ExceptionDto
  }
  const OpenException = (props: OpenExceptionProps) => {
    const { exception } = props
    const { id, source, message, systemArea, systemAreaAction, createdAt } = exception || {}
  
    const title = `${systemArea} - ${systemAreaAction}`
    const subTitle = message
    const titleRight = getActionDate(createdAt, false, true)
    const subTitleRight = source
    
    const selected = selectedExceptionIds.includes(id)

    const style = { color: themeColors.accent }

    return (
      <DetailedListItem
        icon={bulkSelectMode
          ? <Checkbox.Android
              color={themeColors.accent}
              uncheckedColor={themeColors.primary}
              status={selected ? 'checked' : 'unchecked'}
            />
          : <MaterialCommunityIcons name={'alert-circle-outline'} size={Sizing.x25} color={themeColors.error} />}
        selected={selected}
        title={title}
        titleRight={titleRight}
        titleRightStyle={style}
        subTitle={subTitle}
        subTitleStyle={style}
        titleStyle={style}
        subTitleRight={subTitleRight}
        subTitleRightStyle={style}
        onPress={bulkSelectMode
          ? selected
            ? () => unselectRecord(id)
            : () => selectRecord(id)
          : () => handleShowExceptionDetails(id)}
      />
    )
  }
  
  const formObj = useForm<{
    source: SystemExceptionSourceSearch,
    systemArea: ExceptionSystemAreaSearch,
    selectedExceptionIds: string[],
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      source: SystemExceptionSourceSearch.ANY,
      systemArea: ExceptionSystemAreaSearch.ANY,
      selectedExceptionIds: [],
    },
  })
  
  const { handleSubmit, watch, reset, setValue } = formObj

  const source = watch('source')
  const systemArea = watch('systemArea')

  const { data: exceptions, isLoading: exceptionsIsLoading, error: exceptionsError, isFetching: exceptionsIsFetching, refetch: refetchExceptions } = useGetExceptionsQuery({
    source: source === SystemExceptionSourceSearch.ANY ? undefined : source,
    systemArea: systemArea === ExceptionSystemAreaSearch.ANY ? undefined : systemArea,
    status: SecclNotificationStatus.OPEN
  })

  const allExceptionIds = exceptions ? exceptions.map(exception => {
    return exception.id
  }) : [] 

  const selectedExceptionIds = watch('selectedExceptionIds')
  const remainingExceptionIds = difference(allExceptionIds, selectedExceptionIds)
  
  const anySelected = !!selectedExceptionIds.length

  const handleCloseMultiple = async () => {
    closeMultpleExceptions({
      exceptionIds: selectedExceptionIds,
    })
    setValue('selectedExceptionIds', [], { shouldValidate: true, shouldDirty: false })
    setCloseDialogVisible(false)
    setBulkSelectMode(false)
  }

  const unselectRecord = (id: string) => {
    setValue('selectedExceptionIds', pull(selectedExceptionIds, id))
  }
  
  const selectRecord = (id: string) => {
    setValue('selectedExceptionIds', union(selectedExceptionIds, [id]))
  }

  const unselectAllRecords = () => {
    setValue('selectedExceptionIds', [])
  }

  const selectAllRecords = () => {
    setValue('selectedExceptionIds', allExceptionIds)
  }

  const isLoading = exceptionsIsLoading || exceptionsIsFetching || closeMultipleIsLoading
  const error: any = exceptionsError

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(refetchExceptions)
    setRefreshing(false)
  }


  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:system']]}
      >
        <View style={ Flex.column.start } >
          <View style={{ paddingHorizontal: Sizing.x30 }} >
            <ManagedAutoCompleteInput
              name={'source'}
              label={'Source System'}
              selectOnlyMode={true}
              formObj={formObj}
              dataSet={enumToAutocompleteOptions(SystemExceptionSourceSearch)}
            />
            <ManagedAutoCompleteInput
              name={'systemArea'}
              label={'System Area'}
              selectOnlyMode={true}
              formObj={formObj}
              dataSet={enumToAutocompleteOptions(ExceptionSystemAreaSearch)}
            />
          </View>
          {
            isLoading ? <Loading message={closeMultipleIsLoading ? ['Closing exceptions...'] : getLookingLoadingMessages('Finding exceptions...')} /> :
            error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data} /> :
              <>
                <ScrollView
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  showsVerticalScrollIndicator={true}
                >
                  <View style={{ paddingHorizontal: Sizing.x30 }} >
                    {
                      exceptions?.length
                        ? <>
                            <BulkSelectToolbar
                              enabled={bulkSelectMode}
                              anySelected={anySelected}
                              enableFunction={enableBulkSelect}
                              cancelFunction={cancelBulkSelect}
                              selectAllFunction={selectAllRecords}
                              unselectAllFunction={unselectAllRecords}
                              bulkActionFunction={() => setCloseDialogVisible(true)}
                              bulkActionTitle={'Mark As Resolved'}
                            />
                            {
                              exceptions.map((exception, idx) => {
                                return (
                                  <OpenException
                                    key={idx}
                                    exception={exception}
                                  />
                                )
                              })
                            }
                          </>
                        : <>
                            <Paragraph>{'No exceptions found!'}</Paragraph>
                            <Button
                              mode={'text'}
                              onPress={refetchExceptions}
                            >{'Refresh'}</Button>
                          </>
                    }
                  </View>
                </ScrollView>
                <ConfirmationDialog
                  visible={closeDialogVisible}
                  title={'Are you sure?'}
                  content={`This action cannot be undone!\n\nPlease confirm that you want to close ${selectedExceptionIds.length} exception${selectedExceptionIds.length > 1 ? 's' : ''}.`}
                  onCancel={() => setCloseDialogVisible(false)}
                  onConfirm={handleCloseMultiple}
                  confirmLabel={`Mark ${selectedExceptionIds.length} Exception${selectedExceptionIds.length > 1 ? 's' : ''} as Actioned`}
                  cancelLabel={'Go Back'}
                />
              </>
          }
        </View>
      </AdminGuard>
    </MainAppScreen>
  )
}
