import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_08_ContributionSource = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  
  //Setup form
  const formObj = useForm<{ contributionSource: ContributionSource }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contributionSource: workingRetirementAssetData?.contributionSource,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset(attributes))
    navigation.navigate(nextScreen)
  }

  const options: ManagedSimpleChoiceItem[] = [
    {
      value: ContributionSource.PERSONAL,
      label: `${client?.isSpouse ? `${client?.firstName}` : `Me`}, personally`
    },
    { 
      value: ContributionSource.EMPLOYER,
      label: `${client?.isSpouse ? `${client?.firstName}'s` : `My`} limited company`
    },
  ]

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Great! Who makes the contributions?`}
    >
      <ManagedSimpleChoiceInput
        name={'contributionSource'}
        formObj={formObj}
        options={options}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
