import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { InviteAccept_01_Choice, InviteAccept_02_Assets, InviteAccept_03_Incomes, InviteAccept_99_Result } from './Screens'

export const InviteAcceptProcessStack = ({ route, navigation }) => {
  return (
    <ProcessStackBuilder
      navigatorId='InviteAccept'
      processTitle='Accept Invite'
      previousRoute='InviteAcceptIntroScreen'
      nextRoute='InviteAcceptHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Choice',
          title: 'Choice',
          component: InviteAccept_01_Choice,
        },
        {
          navigationId: 'Assets',
          title: 'Assets',
          component: InviteAccept_02_Assets,
        },
        {
          navigationId: 'Incomes',
          title: 'Incomes',
          component: InviteAccept_03_Incomes,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: InviteAccept_99_Result,
      }}
    />
  )
}
