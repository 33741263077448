import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import React from 'react'
import { concat } from 'lodash'
import { useGuidanceContext } from 'providers'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getModelChangeMessages } from 'lib/loadingHelpers'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'

export const OnboardingPhaseConsolidateHeroScreen = ({ route, navigation }) => {

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching, currentViableAgeError, refetchCurrentViableAge } = useGuidanceContext()
  const isLoading = currentViableAgeIsLoading || currentViableAgeIsFetching
  const error: any = currentViableAgeError

  const carouselScreens: ProcessHeroCarouselData[] = concat([
    {
      illustrationFilename: 'record_an_existing_pension.png',
      headline: `Nicely done!`,
      subHeading: `Consolidating old pensions is a great way to get clarity on your future`,
    },
    {
      illustrationFilename: 'magnifying_glass.png',
      headline: `Simple and clear`,
      subHeading: `Once transfers complete, you'll see the transferred value as part of your ${JAR_NAME_PERSONAL} balance`,
    },
  ],
  currentViableAge ? [
    {
      illustrationFilename: 'financial_forecasting.png',
      headline: `Always-live financial forecasting`,
      subHeading: `Jarvis can then use live balance data to give you confidence in your retirement planning`,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.FINANCIAL_FORECASTING} buttonTitle={'What do you mean?'} />
    }
  ] : [
    {
      illustrationFilename: 'financial_forecasting.png',
      headline: `But don't stop now!`,
      subHeading: `Complete the 'Plan Your Retirement' card to unlock Jarvis' superpowers`,
    },
    {
      illustrationFilename: 'gamified_approach.png',
      headline: `See into the future!`,
      subHeading: `Jarvis can show you how all your pensions will grow`,
    },
    {
      illustrationFilename: 'relax_for_now.png',
      headline: `But more importantly...`,
      subHeading: `It can show when you can achieve the retirement you want, and how to get there`,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.FINANCIAL_FORECASTING} buttonTitle={'What do you mean?'} />
    },
  ])


  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      loadingMessage={getModelChangeMessages()}
      error={error}
      errorTryAgain={refetchCurrentViableAge}
      errorCancel={goToDashboard}
      carouselScreens={carouselScreens}
      onFinish={goToDashboard}
      finishButtonLabel={'Return to my Dashboard'}
    />
  )
}
