import { Text } from 'components/Typography/Text'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { floor } from 'lodash'
import React from 'react'
import { PlatformChargeTier } from 'store/dto/client.dto'
import { UnborderedTable, UnborderedTableRow } from './UnborderedTable'
import { Sizing } from 'styles'
import { StyleSheet } from 'react-native'
import { JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { Paragraph } from 'components/Typography'

export type TieredChargeTableProps = {
  tiers: PlatformChargeTier[]
  discount: number
}

export const TieredChargeTable = (props: TieredChargeTableProps) => {
  const { tiers, discount } = props

  let tableData: UnborderedTableRow[] = []

  tableData = tiers.map((tier, idx) => {
    const { fromAmount, toAmount, rate } = tier
    const applicableRate = floor(rate * (1 - (discount || 0)), 2)
    const label = fromAmount === 0 && toAmount === undefined
      ? 'All balances'
      : fromAmount === 0
        ? `Balances up to ${formatCurrencyAmount(toAmount, 0)}`
        : toAmount === undefined
          ? `Balances above ${formatCurrencyAmount(fromAmount, 0)}`
          : `Balances between ${formatCurrencyAmount(fromAmount, 0)} and ${formatCurrencyAmount(toAmount, 0) }`
    return {
      label,
      value: discount
        ? <Text>{``}
            <Text style={{textDecorationLine: 'line-through'}}>{`${formatPercentageAmount(rate, 2)}` }</Text>
            {' '}
            <Text style={{fontWeight: '900', textDecorationLine: 'none'}}>{formatPercentageAmount(applicableRate, 2)}</Text>
          </Text>            
        : formatPercentageAmount(rate, 2),
      isTotal: false,
    }
  })

  return (
    <>
      <UnborderedTable
        data={tableData}
      />
      <Paragraph style={localStyles.smallText}>{`* If you hold both a ${JAR_NAME_PERSONAL} and ${JAR_NAME_GROUP} with us, the balance thresholds are applied independently to each.`}</Paragraph>
    </>
  )
}

const localStyles = StyleSheet.create({
  smallText: {
    textAlign: 'left',
    fontSize: Sizing.x10,
  },
})