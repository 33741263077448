import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetGroupSchemeEnrolmentRequestsQuery } from 'store/apiSlice'
import { GroupSchemeRequestStatus, SchemeEnrolmentChangeRequestDto } from 'store/dto/account.dto'
import { RequestInfoModal } from './RequestInfoModal'
import { RequestListItem } from './RequestListItem'

interface ReturnType {
  title: string,
  data: SchemeEnrolmentChangeRequestDto[]
}

type RequestsListProps = {
  enrolmentId: string
}

export const RequestsList = (props: RequestsListProps) => {
  const { enrolmentId } = props || {}
  
  const [refreshing, setRefreshing] = useState(false)

  const { data: requests, error: requestsError, isLoading: requestsIsLoading, refetch: refetchRequests } = useGetGroupSchemeEnrolmentRequestsQuery(enrolmentId)

  const [modalItem, setModalItem] = useState(undefined)

  const onRefresh = async () => {
    await refreshUsingFunctions(
      [refetchRequests],
      refreshing,
      setRefreshing,
    )
  }

  const isLoading = requestsIsLoading
  const error: any = requestsError

  const requestsList = (): ReturnType[] => {
    let pending = []
    let completed = []
    let rejected = []
    let cancelled = []

    //Actual transactions
    if (requests) {
      requests.map((data, id) => {
        if (data.status === GroupSchemeRequestStatus.PENDING) {
          pending.push(data)
        } else if (data.status === GroupSchemeRequestStatus.COMPLETED) {
          completed.push(data)
        } else if (data.status === GroupSchemeRequestStatus.REJECTED) {
          rejected.push(data)
        } else if (data.status === GroupSchemeRequestStatus.CANCELLED) {
          cancelled.push(data)
        }
      })
      return [
        { title: 'Pending Requests', data: pending },
        { title: 'Completed Requests', data: completed },
        { title: 'Rejected Requests', data: rejected },
        { title: 'Cancelled Requests', data: cancelled },
      ]
    }
    return []
  }
  const Item = ({ item }) => {
    return (
      <RequestListItem
        request={item}
        onPressFunction={() => setModalItem(item)}
      />
    )
  }

  return (
    <>
      {
        isLoading
          ? <Loading message={getLookingLoadingMessages('Loading your requests...')} messageNoLoop={true} />
          : error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data}/> :
        <>
          <View style={{ flex: 1 }} >
            <OptionalSectionList
              sections={requestsList()}
              renderItem={({item})=><Item item={item}/>}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`You haven't yet made any requests`}
            />
          </View>
          <Portal>
            <RequestInfoModal
              visible={!!modalItem}
              request={modalItem}
              onDismiss={() => setModalItem(undefined)}
            />
          </Portal>
        </>
      }
    </>
  )
}

