import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminDashboardScreen } from './Screens/AdminDashboardScreen'

export const AdminDashboardSectionScreen = ({route, navigation}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Dashboard'}
    >
      <AdminDashboardScreen />
    </SectionSimpleContainer>
  )
}

