import { getBestName } from 'lib/userHelpers'
import { PaymentMethod } from 'store/dto/account.dto'
import { GroupSchemePaymentCollectionStatus, GroupSchemePaymentDto, GroupSchemePaymentStatus } from 'store/dto/group-scheme.dto'
import { formatCurrencyAmount } from './generalHelpers'

export const getGroupSchemePaymentListInfo = (payment: GroupSchemePaymentDto): {
  iconName: string,
  reference: string,
  amountString: string,
  status: string,
  statusMessage: string
} => {
  const { status, reference, amount } = payment
  return {
    iconName: getGroupSchemePaymentIconName(payment),
    reference,
    amountString: formatCurrencyAmount(amount, 2),
    status,
    statusMessage: getGroupSchemePaymentActionMessage(payment),
  }
}

export const getGroupSchemePaymentIconName = (payment: GroupSchemePaymentDto): string => {
  const { status, collectionStatus } = payment || {}
  switch(status) {
    case GroupSchemePaymentStatus.AWAITING_CONFIRMATION:
      return 'progress-question'
    case GroupSchemePaymentStatus.AWAITING_PAYMENT:
      switch (collectionStatus) {
        case GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS:
        case GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED:
          return 'credit-card-lock-outline'
        default:
          return 'credit-card-plus-outline'

      }
    case GroupSchemePaymentStatus.PAID:
    case GroupSchemePaymentStatus.DECLARED:
      return 'credit-card-clock-outline'
    case GroupSchemePaymentStatus.COMPLETED:
      return 'credit-card-check-outline'
    case GroupSchemePaymentStatus.CANCELLED:
      return 'credit-card-remove-outline'
    default:
      return 'credit-card-outline'
  }  
}

export const getGroupSchemePaymentActionMessage = (payment: GroupSchemePaymentDto): string => {
  const { status, collectionStatus, payer, contributionMethod } = payment || {}
  return collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS || collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED
    ? 'Click for Details'
    : status === GroupSchemePaymentStatus.AWAITING_CONFIRMATION ? `Awaiting confirmation`
    : status === GroupSchemePaymentStatus.AWAITING_PAYMENT ? `Awaiting payment`
    : status === GroupSchemePaymentStatus.COMPLETED ? `Funds distributed to members`
    : status === GroupSchemePaymentStatus.CANCELLED ? `Payment has been cancelled`
    : status === GroupSchemePaymentStatus.DECLARED ? `Paid by BACS Transfer${payer ? ` by ${getBestName(payer)}` : ``}`
    : status === GroupSchemePaymentStatus.PAID
      ? contributionMethod === PaymentMethod.DIRECT_DEBIT
        ? collectionStatus === GroupSchemePaymentCollectionStatus.AWAITING_COLLECTION ? `Waiting to be collected by Direct Debit`
        : collectionStatus === GroupSchemePaymentCollectionStatus.COMPLETED ? `Collected by Direct Debit`
        : `Direct Debit collection in progress`
      : `Paid${payer ? ` by ${getBestName(payer)}` : ``}`
    : ``
}

