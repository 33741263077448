import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { BankConnectionDto } from 'store/dto/bank-connection.dto'
import { Paper, Sizing } from 'styles'
import { startCase } from 'lodash'

type AdminBankConnectionListItemProp = {
  bank: BankConnectionDto
  onPressFunction?: () => void
}
export const AdminBankConnectionListItem = (props: AdminBankConnectionListItemProp) => {
  const { bank, onPressFunction } = props
  const { name, logo, description, moneyHubId, paymentTypes, contributionSources } = bank

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = moneyHubId ? paymentTypes.map(pt => { return startCase(pt) }).join(', ') : 'No Open Banking'
  const subTitleRight = contributionSources.map(cs => { return startCase(cs) }).join(', ')
  const subTitle = description
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      subTitleRight={subTitleRight}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
      subTitleRightStyle={{ color: themeColors.primary }}
    />
  )
}
