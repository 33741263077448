import { AssetAllocationDto } from './account.dto';
import { BaseDatedDto } from './base.dto';

export interface GroupPortfolioDto extends BaseDatedDto {
  name: string
  description: string
  investmentObjectives: string
  secclModelId: string
  allowedAsDefaultInvestment: boolean
  riskRating: number
  riskRatingScaleMax: number
  logo?: string
  priority: number
  annualFeePercentage: number
  manager: string
  assetAllocation: AssetAllocationDto[]
  cashAllocation: number
}

export interface GroupPortfolioFilterDto {
  search?: string
  secclModelId?: string
  allowedAsDefaultInvestment?: boolean
}

export interface CreateGroupPortfolioDto {
  name: string
  description: string
  investmentObjectives: string
  allowedAsDefaultInvestment: boolean
  riskRating: number
  riskRatingScaleMax: number
  logo?: string
  priority: number
  manager: string
  assetAllocation: AssetAllocationDto[]
  cashAllocation: number
}

export interface UpdateGroupPortfolioDto {
  id: string
  name?: string
  description?: string
  investmentObjectives?: string
  allowedAsDefaultInvestment?: boolean
  riskRating?: number
  riskRatingScaleMax?: number
  logo?: string
  priority?: number
  manager?: string
  assetAllocation?: AssetAllocationDto[]
  cashAllocation?: number
}

export enum GroupPortfolioSearchDefaultStatus {
  ALLOWED = 'Allowed',
  NOT_ALLOWED = 'Not Allowed',
  ANY = 'Any'
}