import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { Headline, Subheading } from 'components/Typography'
import React from 'react'
import { View } from 'react-native'
import { Flex } from 'styles'

export const ChangeRetirementBudget_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}
  
  const next = () => {
    navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'set_your_budget.png'}
      headline={`Update your retirement budget`}
      subHeading={`Update if your circumstances have changed to ensure that your Jarvis predictions are accurate`}
      subHeadingLines={3}
    >
    </ModalProcessIntroScreen>
  )
}
