import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { getActionDate } from "lib/dateHelpers"
import { formatCurrencyAmount, formatPercentageAmount } from "lib/generalHelpers"
import { getContributionStatus, getContributionStatusDescription, getTransactionDescription, getTransactionTitle } from "lib/transactionHelpers"
import React from 'react'
import { ScrollView, View } from "react-native"
import { AccountTransactionStatus, ContributionDto, TransactionSubType } from "store/dto/account.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { FooterButton } from '../../../../components/Utility/FooterButton'
import { PERSONAL_TAX_RELIEF_FACTOR } from "lib/constants"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"

const isWeb = platformIsWeb()

type ContributionInfoModalProps = {
  contribution: ContributionDto
  visible: boolean
  onDismiss: any
}

export const ContributionInfoModal = (props: ContributionInfoModalProps) => {
  const { onDismiss, visible, contribution } = props
  const { employerName, displayDate, transactionDate, intendedSettlementDate, completedDate, transactionSubType, isRecurring, amount, status } = contribution || {}

  const title = getTransactionTitle(contribution)  
  const description = getTransactionDescription(contribution, employerName)

  const statusTitle = getContributionStatus(contribution)
  const statusDescription = getContributionStatusDescription(contribution)

  const taxBasis =
    transactionSubType === TransactionSubType.EMPLOYER ? 'Gross'
    : transactionSubType === TransactionSubType.EMPLOYEE ? 'Net'
    : undefined

  const expectedTaxReclaimPercentage =
    transactionSubType === TransactionSubType.EMPLOYER ? 'n/a'
    : transactionSubType === TransactionSubType.EMPLOYEE ? formatPercentageAmount(PERSONAL_TAX_RELIEF_FACTOR * 100)
    : undefined

  const expectedTaxReclaimAmount =
    transactionSubType === TransactionSubType.EMPLOYEE ? formatCurrencyAmount(amount * PERSONAL_TAX_RELIEF_FACTOR, 2)
    : undefined

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >
          <View style={layoutStyles.cardModalContentContainer}>
            <View style={{ paddingBottom: Sizing.x20 }}>
              <Headline>{title}</Headline>
              <Subheading>{statusTitle}</Subheading>
              <Paragraph>({statusDescription})</Paragraph>
            </View>
            <ContentDivider />
            {
              status === AccountTransactionStatus.COMPLETED
              ? <Subheading>{`Received: ${getActionDate(completedDate || displayDate, true)}`}</Subheading>
              : status === AccountTransactionStatus.DELETED
              ? <Subheading>{`Original Date: ${getActionDate(transactionDate, true)}`}</Subheading>
              : <>
                  <Subheading>{`Created: ${getActionDate(transactionDate, true)}`}</Subheading>
                  {
                    intendedSettlementDate
                      ? <Subheading>{`Expected: ${getActionDate(intendedSettlementDate), true}`}</Subheading>
                      : <></>
                  }
                </>
            }
            <Subheading>{`Amount: ${formatCurrencyAmount(amount, 2)}`}</Subheading>
            <Subheading>{`Source: ${description}`}</Subheading>
            {
              taxBasis
                ? <Subheading>{`Tax Basis: ${taxBasis}`}</Subheading>
                : <></> 
            }
            {
              expectedTaxReclaimPercentage
                ? <Subheading>{`Reclaim Rate: ${expectedTaxReclaimPercentage}`}</Subheading>
                : <></> 
            }
            {
              expectedTaxReclaimAmount
                ? <Subheading>{`Expected Reclaim: ${expectedTaxReclaimAmount}`}</Subheading>
                : <></> 
            }
          </View>
        </ScrollView>
        <FooterButton onPress={onDismiss}>{'Close'}</FooterButton>
      </View>

    </CardModal>
  )
}
