import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'

export const SpouseAdd_20_DeclineTransition = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const goToSetup = attributes => {
    navigation.navigate('Consent')
  }

  const goToResults = () => {
    navigation.navigate('Results', { declinedAll: true })
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Yes, Add Partner Now'}
      buttonAction={goToSetup}
      enableButton={true}
      headline={'Ok, still add a partner?'}
      subHeading={`Do you want to continue now and add a partner?`}
      allowTextButton={true}
      textButtonTitle={'No, not right now'}
      textButtonAction={goToResults}
      enableTextButton={true}
    >
    </ModalProcessScreen>
  )
}
