import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import React from 'react'

export const BulkRetransferIntroScreen = ({ route, navigation }) => {
  const { } =  route?.params || {}

  const next = () => {
    rootNavigate('BulkRetransferProcessStack', route?.params)
  }

  return (      
    <ProcessIntroScreen
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={goToMainAppStack}
      illustrationFilename={'simplify_your_portfortlio.png'}
      headline={`Resolve rejected pension transfers`}
      subHeading={`Review and resolve issues with previous transfer attempts`}
    >
    </ProcessIntroScreen>
  )
}
