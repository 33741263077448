// RootNavigation.js
//Function to help route to anywhere at the RootStack level
import { createNavigationContainerRef, DrawerActions, getActionFromState, getStateFromPath } from '@react-navigation/native'
import { AdminAppNavScreen, EmployerAppNavScreen, getEntryScreenForAppContext, getStackScreenForAppContext, MainAppNavScreen } from './navigationHelpers'
import { parse } from 'expo-linking'
import { getLinkingMap, parseRealPathFromUrl } from './linkingHelpers'
import { Logger } from 'lib//logger'
import { OnboardingPhase } from 'providers'
import { setLastUserContext } from './authHelpers'
import { AppContext, setAppContext } from 'store/authSlice'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { UserDto } from 'store/dto/user.dto'

export const rootNavigationRef = createNavigationContainerRef() as any

export function rootNavigate(name?: any, params?: any) {
  if (rootNavigationRef.isReady()) {
    rootNavigationRef.navigate(name, params)
  }
}

export function rootNavigateBack() {
  if (rootNavigationRef.isReady() && rootNavigationRef.canGoBack()) {
    rootNavigationRef.goBack()
  }
}

export function goToAutoLogin() {
  rootNavigate('AuthenticationAutoLoginScreen')
}

export function goToLogout() {
  rootNavigate('AuthenticationLogoutScreen')
}

export function changeAppContext(
  appContext: AppContext,
  user: UserDto,
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>,
  withNavigation: boolean,
) {
  Logger.debug({ appContext, hasUser: !!user }, `Switching app context`)
  
  //Set current appContext
  dispatch(setAppContext(appContext))

  //Persist appContext
  setLastUserContext(appContext)

  if (withNavigation) {
    //Navigate to entry screen or app stack, depending on whether we have a user
    if (user) {
      rootNavigate(getStackScreenForAppContext(appContext))
    } else {
      rootNavigate(getEntryScreenForAppContext(appContext))
    }
  }
}

export function goToMainAppStack() {
  rootNavigate('MainAppStack')
}

export function goToOnboardingTasks(onboardingPhase: OnboardingPhase) {
  rootNavigate('OnboardingStatusScreen', { onboardingPhase })
}

export function goToDashboard(params?: any) {
  if (rootNavigationRef.isReady()) {
    rootNavigationRef.navigate('MainAppStack', { screen: MainAppNavScreen.DASHBOARD, params })
  } else {
    Logger.warn('Root navigator Not ready!')
  }
}

export function mainAppNavigate(name: MainAppNavScreen, params?: any) {
  if (rootNavigationRef.isReady()) {
    rootNavigationRef.navigate('MainAppStack', { screen: name, params })
  } else {
    Logger.warn('Main App navigator not ready!')
  }
}

export function employerAppNavigate(name: EmployerAppNavScreen, params?: any) {
  if (rootNavigationRef.isReady()) {
    rootNavigationRef.navigate('EmployerAppStack', { screen: name, params })
  } else {
    Logger.warn('Empoyer App navigator not ready!')
  }
}

export function adminAppNavigate(name: AdminAppNavScreen, params?: any) {
  if (rootNavigationRef.isReady()) {
    rootNavigationRef.navigate('AdminAppStack', { screen: name, params })
  } else {
    Logger.warn('Admin App navigator not ready!')
  }
}

export function dispatch(action) {
  rootNavigationRef.current?.dispatch(action);
}

export function linkTo(path, config = null) {
  var state = getStateFromPath(path, config);
  if (!state) {
    Logger.error(`linkTo - No state!`)
    return
  }
  var action = getActionFromState(state);
  if (action !== undefined) {
    dispatch(action)
  }
}

export function navigateFromUrl(url) {
  Logger.info(`Navigating with URL: ${url}`)

  const path = parseRealPathFromUrl(url)
  if (!path) {
    return
  }

  const linking = getLinkingMap()
  var state = getStateFromPath(path, linking.config)

  if (!state) {
    Logger.error(`navigateFromUrl - No state!`)
    return
  }

  var action = getActionFromState(state);
  
  if (action === undefined) {
    return
  }
  dispatch(action)
}


// add other navigation functions that you need and export them
