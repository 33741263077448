import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { FooterButton } from 'components/Utility/FooterButton'
import React from 'react'
import { ScrollView, View } from "react-native"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { WebAppDownloadContent } from "./WebAppDownloadContent"

type WebAppDownloadModalProps = {
  visible: boolean
  onDismiss: any
}

export const WebAppDownloadModal = (props: WebAppDownloadModalProps) => {
  const { visible, onDismiss } = props

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}          
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
            </View>
            <WebAppDownloadContent />
          </ScrollView>
          <FooterButton mode={'text'} onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}
