import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getScenarioGenerationMessages } from 'lib/loadingHelpers'
import { Logger } from 'lib/logger'
import { concat } from 'lodash'
import { GoalScenariosInputs, useGuidanceContext } from 'providers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLazyGetScenariosQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { ScenarioModelRepresentationDto } from 'store/dto/model.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSuggestion, workingSuggestion } from 'store/tempDataSlice'
import { SuggestionAchieveTRAModalContent } from '../Components/SuggestionAchieveTRAModalContent'

export const REGULAR_ONLY_IDENTIFIER = 'regular_only'

export const Contributions_10_SuggestionsAchieveTRA = ({ route, navigation }) => {
  const { nextScreen, existingContributionConfiguration, existingRegularContribution, existingTargetRetirementAge } = route.params || {}

  const dispatch = useAppDispatch()
  const { getGoalScenarios, currentModel, currentModelError, currentModelIsLoading, currentModelIsFetching, refetchCurrentModel } = useGuidanceContext()

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const workingSuggestionData = useAppSelector(workingSuggestion)

  const [getScenarios, { data: scenarios, error: scenariosError, isLoading: scenariosIsLoading, isFetching: scenariosIsFetching }] = useLazyGetScenariosQuery()
  const isLoading = scenariosIsLoading || currentModelIsLoading || currentModelIsFetching
  const error: any = scenariosError || currentModelError
  
  //Get scenarios on load
  useEffect(() => {
    getSuggestions()
  }, [])

  const getSuggestions = () => {
    const props: GoalScenariosInputs = {
      goal: workingSuggestionData?.goal,
      proposedContributionSource: existingContributionConfiguration?.contributionSource || workingSuggestionData?.contributionSource || ContributionSource.PERSONAL,
    }
    const scenarioRequest = getGoalScenarios(props)
    getScenarios({ scenarioRequest, setId: workingSuggestionData?.goal }, true)
  }

  const formObj = useForm<{ scenarioId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      // scenarioId: undefined
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const isEmployer = workingSuggestionData?.contributionSource === ContributionSource.EMPLOYER
  const existingRegularAmount = existingRegularContribution?.amount || 0

  let options: ManagedCardChoiceInputOption[] = []

  const filterModels = (): ScenarioModelRepresentationDto[] => {
    if (!scenarios) {
      return []
    }
    let usefulModels = scenarios.filter(model => {
      const { requiredPersonalMonthlyContributions, requiredGrossMonthlyContributions } = model?.output || {}
      const required = isEmployer ? requiredGrossMonthlyContributions : requiredPersonalMonthlyContributions
      //Check we have a required value (else EV issue finding a solution)
      if (required === undefined) {
        Logger.debug(`Removing model as required amount is undefined`)
        return false
      }
      //Check we're not reducing regulars
      else if (existingRegularAmount > required) {
        Logger.debug(`Removing model as contributions less than existing`)
        return false
      }
      return true
    })
    //Create an array of useful models, including the "regular only" from the currentModel
    //only if we have the necessary value from the currentModel
    const regularOnlyModelRequired = isEmployer ? currentModel?.output?.requiredGrossMonthlyContributions : currentModel?.output?.requiredPersonalMonthlyContributions
    if (regularOnlyModelRequired !== undefined) {
      usefulModels = concat([{
        ...currentModel,
        scenario: {
          identifier: REGULAR_ONLY_IDENTIFIER,
        }
      }],
      usefulModels)
    }
    return usefulModels
  }

  const allModels: ScenarioModelRepresentationDto[] = filterModels()

  const buildOptions = (models: ScenarioModelRepresentationDto[]) => {
    const newOptions: ManagedCardChoiceInputOption[] = allModels.map(model => {
      const { proposedOneOffContribution, identifier } = model?.scenario || {}
      const { requiredPersonalMonthlyContributions, requiredGrossMonthlyContributions } = model?.output || {}      
      const required = isEmployer ? requiredGrossMonthlyContributions : requiredPersonalMonthlyContributions

      const title = proposedOneOffContribution
        ? `Contribution of ${formatCurrencyAmount(proposedOneOffContribution)}`
        : `Monthly contributions only`
      const description = proposedOneOffContribution
        ? existingRegularAmount
          ? `And increase monthly amount by ${formatCurrencyAmount(required - existingRegularAmount)} to ${formatCurrencyAmount(required)}`
          : `Then start monthly contributions of ${formatCurrencyAmount(required)}`
        : existingRegularAmount
          ? `Increase monthly amount by ${formatCurrencyAmount(required - existingRegularAmount)} to ${formatCurrencyAmount(required)}`
          : `Start monthly contributions of ${formatCurrencyAmount(required)}`
      const illustrationFilename = proposedOneOffContribution
        ? 'lump_sum.png'
        : 'regular_contributions.png'
      return {
        value: identifier,
        title,
        description,
        illustrationFilename,
        modalContentFunction: () => <SuggestionAchieveTRAModalContent
          title={title}
          description={description}
          model={model}
          illustrationFilename={illustrationFilename}
        />,
        modalProceedLabel: 'Set up Now',
        modalCancelLabel: 'Choose Another',
      }
    })

    options = newOptions
  }

  //Build options when available
  if (scenarios && currentModel) {
    buildOptions(scenarios)
  }

  const onSubmit = (attributes) => {
    const { scenarioId } = attributes
    //Find the scenario by identifier
    const selectedModel = allModels.find(model => {
      return model.scenario.identifier === attributes.scenarioId
    })

    if (!selectedModel) {
      Logger.error(`No matching model found for scenarioId`, scenarioId)
      return
    }

    const proposedOneOffContribution = selectedModel?.scenario?.proposedOneOffContribution
    const proposedMonthlyContributions = workingSuggestionData?.contributionSource === ContributionSource.EMPLOYER
      ? selectedModel?.output?.requiredGrossMonthlyContributions
      : selectedModel?.output?.requiredPersonalMonthlyContributions

    dispatch(updateWorkingSuggestion({
      proposedOneOffContribution,
      proposedMonthlyContributions,
    }))
    navigation.navigate('Execute')
  }

  const goToManual = () => {
    hideDialog()
    navigation.navigate('ManualSetup')
  }

  const hasSuggestions = options.length

  return (    
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={getScenarioGenerationMessages()}
      buttonTitle={error ? 'Try Again' : 'Next'}
      buttonAction={error
        ? currentModelError
          ? refetchCurrentModel
          : getSuggestions
        : handleSubmit(onSubmit)
      }
      showButton={true}
      enableButton={error || isValid}
      headline={hasSuggestions && !error
        ? `Ok, here are some ways to get there`
        : `Sorry, we couldn't find any answers`
      }
      subHeading={hasSuggestions && !error
        ? `The suggestions below give you a few options to hit your target age of ${existingTargetRetirementAge}`
        : `We couldn't generate any suggestions, but you can still set up contributions manually`
      }
      allowTextButton={true}
      textButtonTitle={existingRegularAmount ? `Change contributions manually` : `Set up contributions manually`}
      textButtonAction={hasSuggestions ? showDialog : goToManual}
    >
    {
      error
        ? <></>
        : <ManagedCardChoiceInput
            formObj={formObj}
            name={'scenarioId'}
            submitHandler={handleSubmit(onSubmit)}
            required={true}
            options={options}
          />
    }
    <ConfirmationDialog
      visible={dialogVisible}
      title={'Are you sure?'}
      content={`You can always choose to contribute any amount, however you will only see the impact after your changes`}
      onCancel={hideDialog}
      onConfirm={goToManual}
      confirmLabel={'Set up Manually'}
    />
    </ProcessScreen>

  )
}