import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { createBranchLink } from 'lib/linkHelpers'
import React from 'react'
import { Image } from 'react-native'
import { AffiliateDto } from 'store/dto/affiliate.dto'
import { Sizing, Paper } from 'styles'

type AdminAffiliateListItemProp = {
  affiliate: AffiliateDto
  onPressFunction?: () => void
}
export const AdminAffiliateListItem = (props: AdminAffiliateListItemProp) => {
  const { affiliate, onPressFunction } = props
  const { name, code, siteUrl, logo, isInternal, apiKey, apiDisabled } = affiliate

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 'link-variant'
  const titleRight = isInternal === false ? 'Partner' : 'Internal'
  const subTitle = code
  const apiStatus = apiKey && !apiDisabled
    ? `API Access Enabled`
    : apiKey && apiDisabled
    ? `API Access Disabled`
    : `No API Access`
  
  return (
    <DetailedListItem
      icon={logo
        ? <Image source={{ uri: logo }} style={{
            width: Sizing.x25,
            height: Sizing.x25,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
        : <MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />
      }
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{apiStatus}</Text>}
    />
  )
}
