import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { useAppSelector } from 'store/hooks'
import { workingRetirementAsset } from 'store/tempDataSlice'
import { useGetRetirementAssetQuery } from 'store/apiSlice'

export const RetirementAssetTransfer_20_PersonalDetailsIntro = ({route, navigation}) => {
  const { nextScreen, onDismiss, asset, isRetransfer }  = route?.params || {} 

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)

  const next = () => {
    navigation.navigate(isRetransfer ? 'PersonalDetailsSetAddress' : nextScreen)
  }

  const tasks: RetirementAssetTransferStepIdentifier[] = [
    RetirementAssetTransferStepIdentifier.PROVIDER,
    RetirementAssetTransferStepIdentifier.REFERENCE,
    RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS,
  ]
  if (workingRetirementAssetData?.tracingRequested) {
    tasks.push(RetirementAssetTransferStepIdentifier?.TRACING)
  }

  //Determine pendingSteps
  const pendingSteps: RetirementAssetTransferStepIdentifier[] = []
  if (workingRetirementAssetData?.tracingRequested || !latestAsset?.pensionProviderId) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.PROVIDER)
  }
  if (workingRetirementAssetData?.tracingRequested || !latestAsset?.externalProviderReference) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.REFERENCE)
  }

  return (
    <ModalProcessIntroScreen
    isLoading={latestAssetIsLoading || latestAssetIsFetching}
    error={latestAssetError}
    errorTryAgain={refetchLatestAsset}
      onDismiss={onDismiss}
      buttonTitle={'Continue'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={isRetransfer
        ? `Finally, let's double check your details`
        : workingRetirementAssetData?.tracingRequested
          ? `Ok, let's double check your details`
          : `Ok, we're nearly there`
      }
      subHeading={isRetransfer
        ? `If your address or name has changed, this can prevent transfer`
        : workingRetirementAssetData?.tracingRequested
          ? `We need your personal details as known by the old provider`
          : `Finally, let's check your personal details as known by the old provider`
      }
    >
      <TaskList
        asset={asset}
        allTasks={tasks}
        pendingSteps={pendingSteps}
        nextTask={RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS}
        nextTaskAction={next}
        isRetransfer={isRetransfer}
      />
    </ModalProcessIntroScreen>
  )
}

