import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { MAX_ASSET_REGULAR_CONTRIBUTIONS, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_09_ContributionAmount = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const personalContribution = workingRetirementAssetData?.contributionSource === ContributionSource.PERSONAL

  const factor = personalContribution ? 1 + PERSONAL_TAX_RELIEF_FACTOR : 1

  //Setup form
  const formObj = useForm<{ monthlyContributionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      monthlyContributionAmount: workingRetirementAssetData?.monthlyContributionAmount ? workingRetirementAssetData?.monthlyContributionAmount / factor : undefined
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset(attributes))
    navigation.navigate(nextScreen)
  }

  const monthlyContributionAmount = watch('monthlyContributionAmount')  
  const percentage = `${PERSONAL_TAX_RELIEF_FACTOR * 100}%`

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How much each month?`}
      subHeading={personalContribution
        ? `Please don't include any top-ups from the government.`
        : workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE
          ? `Total amount contributed by ${client?.isSpouse ? client?.firstName : 'you'} and ${client?.isSpouse ? 'their' : 'your'} employer.`
          : `The amount ${client?.isSpouse ? 'their' : 'your'} employer/limited company pays each month.`
      }
    >
      <ManagedCurrencyInput
        name={'monthlyContributionAmount'}
        autoFocus={true}
        formObj={formObj}
        // label={'Monthly Contribution'}
        placeholder={'Enter monthly contribution amount'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={0}
        maxValue={MAX_ASSET_REGULAR_CONTRIBUTIONS}
        returnKeyType="next"
        blurOnSubmit={false}
        informationMessage={personalContribution && monthlyContributionAmount ? `Including government topup: ${formatCurrencyAmount(monthlyContributionAmount * factor, 2)}` : undefined}
      />
    </ModalProcessScreen>
  )
}
