import { Headline, Paragraph, Subheading } from 'components/Typography'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { SimpleSuggestion } from 'providers/suggestions.context'
import React, { useRef } from 'react'
import { Image, View } from 'react-native'
import { useUpdatePreferencesMutation } from 'store/apiSlice'
import { Sizing } from "styles"
import { ActionCard } from './ActionCard'
import { Animated } from 'react-native'
import { Easing } from 'react-native-reanimated'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { AppIllustration } from './AppIllustration'

type SuggestionCardProps = {
  index: number
  suggestion: SimpleSuggestion
  onDismissal: Function
}

export const SuggestionCard = (props: SuggestionCardProps) => {

  const [ updatePreferences ] = useUpdatePreferencesMutation()  

  const { index, suggestion, onDismissal } = props
  const {
    identifier,
    buttonLabel,
    imageSource,
    illustrationFilename,
    notificationCount,
    title,
    description,
    shortDescription,
    onProceed,
    proceedButtonLabel,
    dismissalAction,
    dismissalValue,
    dismissalConfirmationMessage,
    dismissalButtonLabel,
    dismissalOnProceed,
    dismissalValueForProceed,
   } = suggestion

  const handleCancel = () => {
    //Hide card
    hideCard()
    //Delay for animation then call parent onDismissal
    setTimeout(() => {
      onDismissal(index)
      if (dismissalAction) {
        dismissalAction()
      } else if (dismissalValueForProceed) {
        updatePreferences({ dismissals: {
          [identifier]: dismissalValueForProceed,
        }})
      } else if (dismissalValue) {
        updatePreferences({ dismissals: {
          [identifier]: dismissalValue,
        }})
      }
    }, 500)
  }

  const handleProceed = () => {
    onProceed()
    if (dismissalOnProceed) {
     handleCancel() 
    }
  }

  const cardOpacity = useRef(new Animated.Value(1)).current

  const showCard = () => {
    Animated.timing(cardOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: 300,
      easing: Easing.linear,
    }).start()
  }

  const hideCard = () => {
    Animated.timing(cardOpacity, {
      useNativeDriver: false,
      toValue: 0,
      duration: 500,
      easing: Easing.linear,
    }).start()
  }

  function ActionCardModalContent() {
    return (
      <>
        <View style={{
          alignSelf: 'center',
        }}>
          {
            imageSource ?
              <Image source={imageSource} style={{
                width: scaleNormalizer(100),
                height: scaleNormalizer(100),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            :
              <AppIllustration filename={illustrationFilename || 'info.png'} style={{
                width: scaleNormalizer(100),
                height: scaleNormalizer(100),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />

          }
        </View>
        <Headline>{title}</Headline>
        <Subheading>{shortDescription}</Subheading>
        <ContentDivider />
        
        <View style={{ paddingTop: Sizing.x20}}>
          {
            typeof description === 'string'
              ? <Paragraph>{description}</Paragraph>
              : description
          }      
        </View>
      </>
    )
  }

  return (
    <Animated.View style={{
      paddingHorizontal: Sizing.x10,
      opacity: cardOpacity,
    }}>
      <ActionCard
        illustrationFilename={illustrationFilename}
        imageSource={imageSource}
        notificationCount={notificationCount ? notificationCount : 1}
        title={title}
        description={shortDescription}
        size={'small'}
        onPress={handleProceed}
        onCancel={handleCancel}
        buttonLabel={buttonLabel || 'Start'}
        confirmationModalContentFunction={ActionCardModalContent}
        confirmationModalProceedLabel={proceedButtonLabel}
        confirmationModalCancelLabel={dismissalButtonLabel}
        dismissalAction={handleCancel}
        dismissalConfirmationMessage={dismissalConfirmationMessage}
      />
    </Animated.View>
  )
}