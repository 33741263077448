import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddAssetCategoryMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddAssetCategoryVisible, showAdminAddAssetCategoryVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddAssetCategoriesModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    //Show required fields on load
    useEffect(() => {
      trigger()
    }, [])

    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddAssetCategoryVisible)

    const [addAssetCategoryMutation, { data: addedAssetCategory, isLoading: addAssetCategoryIsLoading, error: addAssetCategoryError, reset: addAssetCategoryReset }] = useAddAssetCategoryMutation()
  
    const formObj = useForm<{
      name: string
      code: string
      shortDescription: string
      longDescription: string
      priority: number
      logo: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: '',
        code: '',
        shortDescription: '',
        longDescription: '',
        priority: undefined,
        logo: '',
      },
    })
    //Form refs for focussing
    const nameRef = useRef(null)
    const codeRef = useRef(null)
    const shortDescriptionRef = useRef(null)
    const longDescriptionRef = useRef(null)
    const priorityRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
  
    const onSubmit = async attributes => {
      addAssetCategoryMutation(attributes)
    }
  
    useEffect(() => {
      if (addedAssetCategory) {
        close()
      }
    }, [addedAssetCategory])
  
    const close = () => {
      dispatch(setShowAdminAddAssetCategoryVisible(false))
    }
  
    const isLoading = addAssetCategoryIsLoading
    const error: any = addAssetCategoryError
  
    return (
      <ModalEditScreen
        formTitle='Add New Asset Category'
        onDismiss={() => dispatch(setShowAdminAddAssetCategoryVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new asset category?'}
        error={error}
        errorTryAgain={addAssetCategoryError ? handleSubmit(onSubmit) : undefined}
        errorCancel={addAssetCategoryError ? addAssetCategoryReset : close}
        isLoading={isLoading}
        loadingMessage={['Saving asset category...']}
        buttonTitle={'Create Asset Category'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name visible to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => codeRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 20,
          }}/>
        <ManagedTextInput
          ref={codeRef}
          name={'code'}
          formObj={formObj}
          label={'Code'}
          placeholder={'Internal unique code'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => shortDescriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 10,
          }}/>
        <ManagedTextInput
          ref={shortDescriptionRef}
          name={'shortDescription'}
          formObj={formObj}
          label={'Short Description'}
          placeholder={'Short Description for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => longDescriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 100,
          }}/>
        <ManagedTextInput
          ref={longDescriptionRef}
          name={'longDescription'}
          formObj={formObj}
          label={'Long Description'}
          placeholder={'Long Description for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 200,
          }}/>
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
        <Paragraph>{'Priority (for display order)'}</Paragraph>
        <ManagedIntegerInput
          ref={priorityRef}
          name={'priority'}
          formObj={formObj}
          label={'Priority'}
          returnKeyType={'done'}
          blurOnSubmit={true}
          required={true}
          minValue={1}
          maxValue={1000}
        />
      </ModalEditScreen>
    )
  }
    

