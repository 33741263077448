import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { JAR_NAME_PERSONAL } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { filterAssetsInScopeForTransfer } from 'lib/retirementAssetHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { sumBy } from 'lodash'
import { OnboardingPhase, OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementAssetsQuery, useGetSpouseQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch } from 'store/hooks'
import { setAddRetirementAssetClient, setAddSpouseVisible, setEditRetirementAsset, setOnAssetSpouseView } from 'store/uxSlice'
import { Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { AssetTabScreenName, RetirementAssetsList } from '../Components/RetirementAssetsList'

export const RetirementAssetsScreen = () => {
  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching } = useGetUserAccountsQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const transferableAssets = filterAssetsInScopeForTransfer(assets, me)

  const isLoading = meIsLoading || spouseIsLoading || accountsIsLoading || assetsIsLoading
  const error: any = assetsError || meError

  const handleAssetListNavigation = (data) => {
    const index = data?.state?.index
    const routeNames = data?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    dispatch(setOnAssetSpouseView(currentName === AssetTabScreenName.SPOUSE))
  }

  const overallAmount = sumBy(assets, 'currentValue')
  const transferableAmount = sumBy(transferableAssets, 'currentValue')
  const transferableCount = transferableAssets?.length || 0
  
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchSpouse} /> :
        <OnboardingGuard
          explanation={`Your Retirement Assets screen will help you track other pensions and transfer them into your ${JAR_NAME_PERSONAL}`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.ASSETS,
          ]}
        >
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.pageContainer,
            ]} >
            <RefreshableScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
              refreshFunctions={[
                refetchAssets,
              ]}
            >
              <Subheading style={Typography.defined.sectionListHeader}>{'Other Assets Total'} </Subheading>
              <View style={{ paddingTop: Sizing.x10 }}>
                <Paragraph
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={{
                    fontFamily: 'LabGrotesque-Medium',
                    fontWeight: '400',
                    lineHeight: scaleNormalizer(40),
                    fontSize: scaleNormalizer(40),
                    textAlign: 'center',
                  }}>
                    {formatCurrencyAmount(overallAmount)}
                </Paragraph>
                  {
                    transferableAmount > 0
                      ? <>
                          <Paragraph>{`Transferable: ${formatCurrencyAmount(transferableAmount, 0)}`}</Paragraph>
                          <Paragraph>{`You have ${transferableCount} old pension${transferableCount > 1 ? `s` : ``} that can be consolidated into your ${JAR_NAME_PERSONAL}.`}</Paragraph>
                          <View style={{ paddingVertical: Sizing.x10 }}>
                            <Button
                              // mode={'text'}
                              // color={themeColors.accent}
                              onPress={me?.onboardingFlags?.consolidate
                                ? () => rootNavigate('BulkTransferIntroScreen')
                                : () => rootNavigate('OnboardingStatusScreen', { onboardingPhase: OnboardingPhase.CONSOLIDATE })
                              }
                            >
                              {`Consolidate Now`}
                            </Button>
                          </View>
                        </>
                      : <></>
                  }
              </View>
              <ContentDivider />

              <Subheading style={Typography.defined.sectionListHeader}>{'Other Assets'} </Subheading>
              <Paragraph>{`This table shows all the other pensions and assets you have recorded, which contribute to Jarvis forecasting.`}</Paragraph>
              <View style={{ paddingTop: Sizing.x10 }}>
                <RetirementAssetsList
                  addAssetStateFunction={(client: ClientBaseDto) => dispatch(setAddRetirementAssetClient(client))}
                  editAssetStateFunction={(asset: RetirementAssetDto) => dispatch(setEditRetirementAsset(asset))}
                  addSpouseStateFunction={(visible: boolean) => dispatch(setAddSpouseVisible(visible))}
                  onNavigateFunction={handleAssetListNavigation}
                />
              </View>
              <View style={{ height: Sizing.x30 }}></View>
              <ContentDivider />
            </RefreshableScrollView>
          </View>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})