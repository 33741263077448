import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton } from 'components/Utility/InformationButton'
import { PieChart, PieChartCategory, generatePieChartInputs } from 'components/Utility/PieChartWithLegend'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { max, startCase } from 'lodash'
import Victory from 'platform-lib/victory'
import React from 'react'
import { Image, View } from 'react-native'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingCalculatorRequest } from 'store/tempDataSlice'
import { Sizing, Paper } from 'styles'
import { AppIllustration } from 'components/Utility/AppIllustration'

export const Calculator_41_Income = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)
  
  const { colors: themeColors } = Paper.useAppTheme()

  const {
    statePensionChoice,
    income,
    grossBudgetTotalAmount,
    baseBudgetLevel,
    netBudgetTotalAmount,
  } = workingCalculatorRequestData || {}

  const {
    statePensionAmount,
    spouseStatePensionAmount,
  } = income || {}

  const withSpouse = workingCalculatorRequestData?.withSpouse

  const statePensionTotal = statePensionAmount + spouseStatePensionAmount

  const remaining = max([grossBudgetTotalAmount - statePensionTotal, 0])
  const hasStatePension = statePensionTotal > 0
  const hasMultipleStatePensions = statePensionAmount > 0 && spouseStatePensionAmount > 0

  const percentageCovered = (statePensionTotal / grossBudgetTotalAmount) * 100
  const allCovered = percentageCovered >= 100

  const categories: PieChartCategory[] = []
  
  if (statePensionAmount) {
    categories.push({
      name: `${spouseStatePensionAmount ? `Your ` : ``}State Pension`,
      items: [
        {
          x: '',
          y: statePensionAmount
        }
      ]
    })
  }

  if (spouseStatePensionAmount) {
    categories.push({
      name: `Partner's State Pension`,
      items: [
        {
          x: '',
          y: spouseStatePensionAmount
        }
      ]
    })
  }
  
  categories.push({
    name: `Income Shortfall`,
    items: [
      {
        x: '',
        y: remaining
      }
    ]
  })

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const { items, colors, legends } = generatePieChartInputs(categories, themeColors.primary)

  const isWeb = platformIsWeb()

  const informationTexts: string[] = [
    `Whilst you may be working less or not at all during retirement, you still need an income to support your lifestyle.`,
    `In general, this also means you need to pay tax to the UK government depending on the size of that income.`,
    `Your '${startCase(baseBudgetLevel)}' budget requires a gross income, before tax of ${formatCurrencyAmount(grossBudgetTotalAmount, 0)}.`,
  ]

  if (allCovered) {
    informationTexts.push(`With your state pension${hasMultipleStatePensions ? 's totalling' : ' of'} ${formatCurrencyAmount(statePensionTotal, 0)}, your lifestyle is covered from the point you receive your state pension${hasMultipleStatePensions ? 's' : ''}.`)
  } else if (hasStatePension) {
    informationTexts.push(`With your state pension${hasMultipleStatePensions ? 's totalling' : ' of'} ${formatCurrencyAmount(statePensionTotal, 0)}, you'll need enough savings to generate the annual Income Shortfall of ${formatCurrencyAmount(remaining)}, for the period you are retired.`)
  } else {
    informationTexts.push(`Without state pension${withSpouse ? 's' : ''}, you'll need enough savings to generate this annual income, for the period you are retired.`)
  }

  if (hasStatePension) {
    informationTexts.push(`If you plan to retirement before you receive your state pension${hasMultipleStatePensions ? 's' : ''}, you would need additional savings to cover your income needs until the state pension${hasMultipleStatePensions ? 's' : ''} become available.`)
    informationTexts.push(`The full Jarvis app let's you set a target retirement age helps you understand the right amount to save to prepare for your retirement, at whatever age you choose.`)
  }

  const features: FeatureBoxRow[] = [
    { label: `Capture your exact expected state pension amounts` },
    { label: `Add defined benefit pensions and other sources of income` },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={hasStatePension
        ? `Great, this covers ${allCovered ? 'all' : formatPercentageAmount(percentageCovered)} of your budget`
        : `Ok, you'll need to fund your retirement by other means`
      }
      subHeading={hasStatePension
        ? `You have covered ${formatCurrencyAmount(statePensionTotal, 0)} of the ${formatCurrencyAmount(grossBudgetTotalAmount, 0)} gross income you need`
        : `You'll need to build up savings to provide a future annual income of ${formatCurrencyAmount(grossBudgetTotalAmount, 0)}`}
      subHeadingInfo={<InformationButton title={`Retirement Income Explained`} texts={informationTexts} illustrationFilename={'financial_forecasting.png'} buttonTitle={`How is this calculated?`} />}
      footerInfo={<FeatureBox data={features} />}
    >
      {
        allCovered
        ? <>
            <AppIllustration filename={withSpouse ? 'joint_planning.png' : 'relax_for_now.png'} style={{
              width: Sizing.x150,
              height: Sizing.x150,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />  
          </>
        : hasStatePension ?
          isWeb ?
            <>
              {/* marginRight below - nasty hack to center align pie chart and legend */}
              <View style={{ flexDirection: 'row', marginRight: scaleNormalizer(180) * -1 }}>
                <View style={{ width: '30%' }}>
                  <Victory.VictoryPie
                    data={items}
                    labels={() => null}
                    innerRadius={Sizing.x40}
                    padding={Sizing.x20}
                    animate={true}
                    colorScale={colors}
                  />
                </View>
                <View style={{ width: '70%', flexDirection: 'column', justifyContent: 'center' }}>
                  <View style={{
                    height: scaleNormalizer(legends.length * 35) + 20,
                  }}>
                    <Victory.VictoryLegend
                      height={scaleNormalizer(legends.length * 35) + 20}
                      padding={0}
                      gutter={Sizing.x20}
                      itemsPerRow={1}
                      title={``}
                      centerTitle
                      orientation={`horizontal`}
                      data={legends}
                    />
                  </View>
                </View>
              </View>
            </>
            : <>
                <View style={{ marginLeft: Sizing.x30 * -1}}>
                  <Victory.VictoryPie
                    data={items}
                    labels={() => null}
                    innerRadius={Sizing.x30}
                    height={scaleNormalizer(240)}
                    padding={Sizing.x20}
                    animate={true}
                    colorScale={colors}
                  />
                </View>
                {/* marginRight below - nasty hack to center align pie chart and legend */}
                <View style={{
                  height: scaleNormalizer(legends.length * 35) + 20,
                  paddingLeft: scaleNormalizer(50),
                }}>
                  <Victory.VictoryLegend
                    height={scaleNormalizer(legends.length * 35) + 20}
                    gutter={Sizing.x20}
                    itemsPerRow={1}
                    title={``}
                    centerTitle
                    orientation={`horizontal`}
                    data={legends}
                  />
                </View>
            </>
        : <>
            <AppIllustration filename={'man_saving_in_piggy_bank.png'} style={{
              width: Sizing.x150,
              height: Sizing.x150,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />  
          </>
      }
    </ProcessScreen>
  )
}
