import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { useGetPensionBrandsQuery, useGetRetirementAssetQuery } from 'store/apiSlice'
import { RetirementAssetDto, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppSelector } from 'store/hooks'
import { workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_30_TracingIntro = ({route, navigation}) => {
  const { nextScreen, onDismiss, asset, isRetransfer }: { nextScreen: string, onDismiss: any, asset: RetirementAssetDto, isRetransfer: boolean }  = route?.params || {}

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)
  const isLoading = brandsIsLoading || latestAssetIsLoading || latestAssetIsFetching
  const error = brandsError || latestAssetError

  const currentlySelectedBrand = brands ? brands.find(brand => {
    return brand?.key === workingRetirementAssetData?.pensionProviderBrandKey
  }) : undefined

  const next = () => {
    const screen = asset?.assetType === RetirementAssetType.WORKPLACE
      ? 'TracingEmployerStartDate'
      : currentlySelectedBrand?.raindropId
        ? 'TracingProvider'
        : nextScreen
    navigation.navigate(screen)
  }

  const tasks: RetirementAssetTransferStepIdentifier[] = [
    RetirementAssetTransferStepIdentifier.PROVIDER,
    RetirementAssetTransferStepIdentifier.REFERENCE,
    RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS,
    RetirementAssetTransferStepIdentifier.TRACING,
  ]

  //Determine pendingSteps
  const pendingSteps: RetirementAssetTransferStepIdentifier[] = []
  if (workingRetirementAssetData?.tracingRequested || !latestAsset?.pensionProviderId) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.PROVIDER)
  }
  if (workingRetirementAssetData?.tracingRequested || !latestAsset?.externalProviderReference) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.REFERENCE)
  }

  return (
    <ModalProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={brandsError ? refetchBrands : refetchLatestAsset}
      onDismiss={onDismiss}
      buttonTitle={'Continue'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={`Finally, some details to help find your pension`}
      subHeading={asset?.assetType === RetirementAssetType.WORKPLACE
        ? `We need to know a little about your employment with ${asset?.employerName}`
        : `This is to give our team the best chance of success`
      }
    >
      <TaskList
        asset={asset}
        allTasks={tasks}
        pendingSteps={pendingSteps}
        nextTask={RetirementAssetTransferStepIdentifier.TRACING}
        nextTaskAction={next}
        isRetransfer={isRetransfer}
      />
    </ModalProcessIntroScreen>
  )
}

