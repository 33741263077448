import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import React from 'react'

export const SchemeEnrolmentChangeForecastTreatment_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}

  const next = () => {
     navigation.navigate(nextScreen)
  } 
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'regular_contributions.png'}
      headline={`Configure Employment Income`}
      subHeading={`Ensure accurate forecasting by defining the type of income for this employment`}
    >
    </ModalProcessIntroScreen>
  )
}
