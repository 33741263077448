import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { ampli } from 'src/ampli'
import { Sizing } from 'styles'

export const RetirementAssetsAddAsset_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, forTransfer, client }  = route?.params || {}

  const next = () => {
    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_start',
        isRetransfer: false,
        outcome: 'success',
      })
    }
     navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (      
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'record_an_existing_pension.png'}
      headline={`Record an existing pension ${forTransfer ? '' : 'or asset'}`}
      subHeading={forTransfer
        ? `This can be any contribution-based pension owned by ${client?.isSpouse ? `${client?.firstName}` : 'you'}`
        : `This can be any type of asset that ${client?.isSpouse ? `${client?.firstName} is` : 'you are'} keeping for retirement`
      }
      subHeadingInfo={forTransfer ? undefined : <NamedInformationButton name={NamedInformation.OTHER_ASSET_TYPES} />}
    >
      {
        forTransfer
          ? <>
              <AppIllustration filename={'info.png'} style={{
                width: Sizing.x40,
                height: Sizing.x40,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
              <Paragraph>{`Once you've added the pension, we'll guide you to add the additional details needed for transfer or tracing`}</Paragraph>
            </>
          : <></>
      }
    </ModalProcessIntroScreen>
  )
}
