import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const RetirementAssetTransfer_25_PersonalDetailsPreviousFirstName = ({ route, navigation }) => {
  const { nextScreen, client }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ previousFirstName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      previousFirstName: workingClientData?.previousFirstName || client?.previousFirstName || client?.firstName,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={`Next`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, tell us your previous first name`}
      subHeading={`Your legal name by which the old provider would know you`}
    >
      <ManagedTextInput
        name={'previousFirstName'}
        autoFocus={true}
        formObj={formObj}
        placeholder={'Enter previous first name'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }} />
    </ModalProcessScreen>
  )
}
