import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { SchemeInviteAccept_01_About, SchemeInviteAccept_02_Choice, SchemeInviteAccept_03_Verify, SchemeInviteAccept_99_Result } from './Screens'

export const SchemeInviteAcceptProcessStack = ({ route, navigation }) => {
  return (
    <ProcessStackBuilder
      navigatorId='SchemeInviteAccept'
      processTitle='Accept Scheme Invite'
      previousRoute='SchemeInviteAcceptIntroScreen'
      nextRoute='SchemeInviteAcceptHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'About',
          title: 'About',
          component: SchemeInviteAccept_01_About,
        },
        {
          navigationId: 'Choice',
          title: 'Choice',
          component: SchemeInviteAccept_02_Choice,
        },
        {
          navigationId: 'Verify',
          title: 'Verify',
          component: SchemeInviteAccept_03_Verify,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: SchemeInviteAccept_99_Result,
      }}
    />
  )
}
