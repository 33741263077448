import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getHeadlineAndSubHeading } from 'lib/contributionFlowHelpers'
import React, { useState } from 'react'
import { batch } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingContributionSetupPayment, updateWorkingLumpSumContributionPayment } from 'store/tempDataSlice'
import { MakeContributionsStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'

export const MakeContributions_20_RegularIntro = ({route, navigation}) => {
  const dispatch = useAppDispatch()
  const {nextScreen, onDismiss, contributionsData }  = route?.params || {} 
  const [showDialog, setShowDialog] = useState(false)

  const { makeContributionSteps, existingRegularContribution } = contributionsData || {}

  //Update the working data sets
  batch(() => {
    dispatch(updateWorkingContributionSetupPayment({
      amount: contributionsData?.newMonthlyContributions
    }))
  })
  
  const needsConfirmation = existingRegularContribution?.amount

  const next = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  const { headline, subHeading, buttonTitle } = getHeadlineAndSubHeading(makeContributionSteps, MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION)
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={buttonTitle}
      buttonAction={needsConfirmation ? () => setShowDialog(true) : next}
      showButton={true}
      illustrationFilename={'task_list.png'} 
      headline={headline}
      subHeading={subHeading}
    >
      <TaskList
        contributionsData={contributionsData}
        nextTask={MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION}
        nextTaskAction={next}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'I have cancelled the existing standing order with my bank'}
        content={'Jarvis will help you set up a new standing order with your bank. You must manually cancel the existing standing order.'}
        confirmLabel={`Yes, I've done this`}
        onConfirm={next}
      />
    </ModalProcessIntroScreen>
  )
}

