import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { getLastUserEmail } from 'lib/authHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ampli } from 'src/ampli'
import { linkParams, loginParams, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'

export const Authentication_11_HandOffEmail = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}
  const dispatch = useDispatch()
    
  const linkConfig = useAppSelector(linkParams)
  const { affiliateCode, affiliateRef } = linkConfig || {}

  const loginConfig = useAppSelector(loginParams)
  const { emailAddress, authType, fromCalculator } = loginConfig || {}
  
  useEffect(()=>{
    if (fromCalculator) {
      ampli.calculatorUsage({
        processStep: 'handoff_email',
        affiliateCode,
        affiliateRef,
      })
    }
  },[])

  //Setup email login form
  const formObj = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: emailAddress || '',
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid } } = formObj

  const email = watch('email')

  //On load, look for lastUserEmail
  useEffect(()=>{
    const lookForLastUserEmail = async () => {
      //Look for token in SecureStore/AsyncStorage
      const lastUserEmail = await getLastUserEmail()
      if (!email && !emailAddress && lastUserEmail) {
        setValue('email', lastUserEmail, { shouldValidate: true, shouldDirty: true })
      } else {
        Logger.debug(`No last user email address.`)
      }
    }
    lookForLastUserEmail()
  },[])

  const isLogin = authType === AUTHTYPE.LOGIN ? true : false

  const onSubmit = (attributes) => {
    const { email } = attributes
    dispatch(setLoginParams({ emailAddress: email }))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={isLogin
        ? `Enter your registered email`
        : emailAddress
          ? `Confirm your email address`
          : `Enter your preferred email address`
      }
      subHeading={isLogin
        ? `This should be the email associated with your Jarvis account`
        : emailAddress
          ? `You can use the email shown or change it to something else`
          : `This will become your Jarvis App login`
      }
    >
      <ManagedTextInput
        name={'email'}
        placeholder={'Enter email'}
        autoFocus={true}
        formObj={formObj}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        rules={{
          required: true, 
          pattern: {
            value: /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address"
          }
      }} />
    </ProcessScreen>
  )
}