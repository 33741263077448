import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getHeadlineAndSubHeading } from 'lib/contributionFlowHelpers'
import React from 'react'
import { batch } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingLumpSumContributionPayment } from 'store/tempDataSlice'
import { MakeContributionsStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'

export const MakeContributions_10_LumpSumIntro = ({route, navigation}) => {
  const dispatch = useAppDispatch()
  const {nextScreen, onDismiss, contributionsData }  = route?.params || {} 

  const { makeContributionSteps } = contributionsData || {}

  //Update the working data sets
  batch(() => {
    dispatch(updateWorkingLumpSumContributionPayment({
      amount: contributionsData?.newOneOffContribution
    }))
  })
  
  const next = () => {
    navigation.navigate(nextScreen)
  }

  const { headline, subHeading, buttonTitle } = getHeadlineAndSubHeading(makeContributionSteps, MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION)
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={buttonTitle}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={headline}
      subHeading={subHeading}
    >
      <TaskList
        contributionsData={contributionsData}
        nextTask={MakeContributionsStepIdentifier.LUMP_SUM_CONTRIBUION}
        nextTaskAction={next}
      />
    </ModalProcessIntroScreen>
  )
}

