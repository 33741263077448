import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { addDays, isAfter, startOfDay } from 'date-fns'
import { platformIsWeb } from 'lib/platformHelpers'
import { rootNavigate, rootNavigateBack } from 'lib/RootNavigation'
import React from 'react'
import { useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppDispatch } from 'store/hooks'
import { formatUkDate } from 'lib/dateHelpers'
import { View } from 'react-native'
import { Sizing } from 'styles'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { getEntryScreenForAppContext } from 'lib/navigationHelpers'

const isWeb = platformIsWeb()

export const EnrolmentOptOutIntroScreen = ({ route, navigation }) => {
  const { enrolmentId } = route?.params || {}

  const dispatch = useAppDispatch()

  const { data: pendingEnrolment, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, isFetching: pendingEnrolmentIsFetching, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(enrolmentId, { skip: !enrolmentId })

  const { groupScheme, autoEnrolmentWindowOptOutDate, employeeEmail } = pendingEnrolment || {}
  const { logo, organizationDisplayName } = groupScheme || {}

  const now = new Date()
  const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
  const withinOptOutWindow = !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)


  const next = () => {
    rootNavigate('EnrolmentOptOutProcessStack', {
      enrolmentId,
    })
  }
  
  const finishRoute = getEntryScreenForAppContext(AppContext.CLIENT)
  const finish = () => {
    rootNavigate(finishRoute)
  }

  const goToRegister = () => {
    dispatch(setLoginParams({ authType: AUTHTYPE.REGISTER, emailAddress: employeeEmail, appContext: AppContext.CLIENT }))
    rootNavigate('AuthenticationProcessStack')
  }

  return (
    <ProcessIntroScreen
      isLoading={pendingEnrolmentIsLoading}
      buttonTitle={pendingEnrolmentError ? 'Close' : withinOptOutWindow ? 'Start' : 'Create Jarvis Account'}
      buttonAction={pendingEnrolmentError ? finish : withinOptOutWindow ? next : goToRegister}
      showButton={true}
      cancelButtonText={pendingEnrolmentError ? undefined : 'Cancel'}
      cancelButtonAction={pendingEnrolmentError ? undefined : () => rootNavigateBack()}
      imageSource={pendingEnrolmentError
        ? require('assets/brand/jarvis-logo-blk-4x.png')
        : { uri: logo }
      }
      headline={pendingEnrolmentError
        ? `Sorry, your link is no longer valid`
        : withinOptOutWindow
          ? `Opt out of your employer's pension scheme`
          : `Sorry, your opt out window has now expired`
      }
      subHeading={pendingEnrolmentError
        ? `You may have already opted out of this workplace pension`
        : withinOptOutWindow
          ? `Let ${organizationDisplayName} know you wish to opt out`
          : `You can no longer opt out of this workplace pension scheme`
      }
      subHeadingInfo={pendingEnrolmentError ? undefined : <NamedInformationButton name={NamedInformation.AUTOMATIC_ENROLMENT} buttonTitle={'About Automatic Enrolment'} />}
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        <AppIllustration
          filename={pendingEnrolmentError || !withinOptOutWindow ? 'magnifying_glass.png' : 'good_to_go.png'}
          style={{
            width: Sizing.x200,
            height: Sizing.x200,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
      </View>
      {
        pendingEnrolmentError ? <></> :
        withinOptOutWindow
          ? <>
              <Subheading>{`You can opt Oput of this pension scheme until your 'Opt Out Period' closes${autoEnrolmentWindowOptOutDate ? ` on ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))}` : ''}.`}</Subheading>
            </>
          : <>
              <Subheading>{`Your 'Opt Out' period ${autoEnrolmentWindowOptOutDate ? `ended on ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))}` : 'has already ended'}.`}</Subheading>
              <Paragraph>{`Your employer may already have started contributing to your Jarvis Pension account. If you still wish to leave the scheme, you can either contact your employer to discuss leaving the scheme, or create a Jarvis account to manage your connected employers, and make a request from there to leave the scheme`}</Paragraph>
            </>
      }
    </ProcessIntroScreen>
  )
}
