import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { PensionBrandDto } from 'store/dto/pension-brand.dto'
import { Paper, Sizing } from 'styles'

type AdminPensionBrandListItemProp = {
  brand: PensionBrandDto
  onPressFunction?: () => void
}
export const AdminPensionBrandListItem = (props: AdminPensionBrandListItemProp) => {
  const { brand, onPressFunction } = props
  const { name, description, logo, key } = brand

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={key}
      subTitle={description}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
