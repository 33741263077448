import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ClientMeDto, EmploymentStatus } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_06_EmploymentStatus = ({ route, navigation }) => {
  const { nextScreen, client }: { nextScreen: string, client: ClientMeDto } = route?.params || {}

  const alreadyHasSecclCient = client?.secclClientId
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ employmentStatus: EmploymentStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employmentStatus: workingClientData?.employmentStatus || client?.employmentStatus,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    //If already has a seccl client, skip national insrurance screen
    if (alreadyHasSecclCient) {
      dispatch(updateWorkingClient({
        ...attributes,
        nationalInsuranceNo: client?.nationalInsuranceNo,
      }))
      navigation.navigate('Results')
    } else {
      dispatch(updateWorkingClient(attributes))
      navigation.navigate(nextScreen)
    }
  }

  return (
    <ProcessScreen
      buttonTitle={alreadyHasSecclCient ? 'Start Verification' : 'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And your main source of income?`}
      subHeading={`We're legally obliged to ask this for our records`}
    >
      <ManagedSimpleChoiceInput
        name={'employmentStatus'}
        formObj={formObj}
        options={enumToAutocompleteOptions(EmploymentStatus)}
        submitHandler={alreadyHasSecclCient ? undefined : handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
