  import { Paragraph, Subheading } from 'components/Typography'
import React, { useState } from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { DataTable, Portal } from 'react-native-paper'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { AdminGroupSchemeUserRolesTableRow } from './AdminGroupSchemeUserRolesTableRow'
import { useAddGroupOrganizationUserMutation } from 'store/apiSlice'
import { GroupSchemeDto } from 'store/dto/group-scheme.dto'
import { AdminGroupOrganizationUserAddDialog } from '../../AdminGroupOrganizationSetUserRoles/Components/AdminGroupOrganizationUserAddDialog'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { SimpleUserDto } from 'store/dto/base.dto'
import { GroupSchemeUserRoleAssertion } from '../AdminGroupSchemeSetUserRolesModal'
import { GroupSchemeRole } from 'store/dto/group-organization.dto'

type AdminGroupSchemeUserRolesTableProps = {
  groupScheme: GroupSchemeDto
  formObj: UseFormReturn<any>
  name: string
}

export const AdminGroupSchemeUserRolesTable = (props: AdminGroupSchemeUserRolesTableProps) => {
  const { groupScheme, formObj, name } = props
  const { control } = formObj
  const { fields, append } = useFieldArray({
    control,
    name,
  })

  const [showAddModal, setShowAddModal] = useState(false)

  const appendUser = (user: SimpleUserDto) => {
    const { id, email, firstName, surname } = user
    const newUserRoleAssertion: GroupSchemeUserRoleAssertion = {
      userId: id,
      userEmail: email,
      userName: `${firstName} ${surname}`,
      schemeRole: GroupSchemeRole.NONE,
      schemeRoleIsForOrganization: false,
    }
    append(newUserRoleAssertion)
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <View style={{ width: Sizing.x40 }}>
              </View>
              <Subheading style={localStyles.tableTitleText}>{'Name'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
             <Subheading style={localStyles.tableTitleText}>{'Email'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title numeric textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <Subheading style={localStyles.tableTitleText}>{'Scheme Role'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <Subheading style={localStyles.tableTitleText}>{'Actions / Notes'}</Subheading>
            </View>
          </DataTable.Title>
        </DataTable.Header>
        {
          fields.map((field, index) => {
            return (
              <View
                key={field.id}
              >
                <AdminGroupSchemeUserRolesTableRow
                  formObj={formObj}
                  name={name}
                  index={index}
                />
              </View>
            )
          })
        }
        <DataTable.Row onPress={() => setShowAddModal(true)}>
          <DataTable.Cell style={{ flex: 1 }}>
            <View style={localStyles.titleWrap}>
              <View style={{ width: Sizing.x40 }}>
                <MaterialCommunityIcons
                  name={'account-plus-outline'}
                  size={Sizing.x25} />
              </View>
              <Paragraph style={{
                textAlign: 'left',
              }}>{`Add User to Organisation`}</Paragraph>
            </View>
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      {
        showAddModal ?
          <Portal>
            <AdminGroupOrganizationUserAddDialog
              visible={showAddModal}
              onClose={() => setShowAddModal(false)}
              onSave={appendUser}
              organizationId={groupScheme.organizationId}
            />
          </Portal>
        : <></>
      }
    </View>
  )
}

const localStyles = StyleSheet.create({
  title: {
    height: Sizing.x30,
    maxHeight: Sizing.x30,
  },
  tableTitleText: {
    color: Colors.brand.purple1,
  },
  titleWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Sizing.x5,
    flex: 1,
  },
})


