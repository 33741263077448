import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import React from 'react'
import { Image, View } from "react-native"
import { RetirementAssetDto, RetirementAssetUntransferableReason } from "store/dto/retirement-asset.dto"
import { Sizing } from "styles"
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from "lib/constants"
import { ContentDivider } from "components/Layout/ContentDivider"

type RetirementAssetUntransferableModalContentProps = {
  asset: RetirementAssetDto
  imageSource: any
  isSelected: boolean
}


export const RetirementAssetUntransferableModalContent = (props: RetirementAssetUntransferableModalContentProps) => {
  const { asset, imageSource } = props
  const { name, currentValue } = asset

  const reason = asset?.untransferableReason

  return (
    <>
      <View style={{
        alignSelf: 'center',
        paddingVertical: Sizing.x20,
      }}>
        <Image source={imageSource} style={{
          width: scaleNormalizer(100),
          height: scaleNormalizer(100),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Headline>{name}</Headline>
      <Subheading>{`Estimated Value: ${formatCurrencyAmount(currentValue)}`}</Subheading>
      <ContentDivider />
      {
        reason === RetirementAssetUntransferableReason.WORKPLACE_CONTRIBUTIONS
          ? <>
              <Paragraph>{`This pension cannot be transferred because you have recorded it as a workplace pension with active monthly contributions.`}</Paragraph>
              <Paragraph>{`We don't recommend transfers in this case as you would likely lose out on contributions from your employer.`}</Paragraph>
              <Paragraph>{`If the contributions have now ceased, you can update the details so that it can be transferred.`}</Paragraph>
            </>
          : reason === RetirementAssetUntransferableReason.CLIENT_DECLARED ?
          <>
            <Paragraph>{`You previously marked this pension as being one you don't want to transfer.`}</Paragraph>
            <Paragraph>{`If you are now interested in transferring it, you can update the details so that it can be transferred.`}</Paragraph>
          </>
          : reason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH ?
          <>
            <Paragraph>{`You told us that the old provider knows you by your previous name.`}</Paragraph>
            <Paragraph>{`If you have now updated your name with the old provider, you can now prepare it for transfer.`}</Paragraph>
          </>
          : reason === RetirementAssetUntransferableReason.PUBLIC_SECTOR ?
          <>
            {
              asset?.employerId
              ? <>
                  <Paragraph>{`Our records indicate that your employer '${asset?.employerName}' was a public sector employer.`}</Paragraph>
                  <Paragraph>{`If you believe this is incorrect, please update the employer to set the name you know them by, so that it can be transferred.`}</Paragraph>
                </>
              : <>
                  <Paragraph>{`You told us that your employer '${asset?.employerName}' was a public sector employer.`}</Paragraph>
                  <Paragraph>{`If this is incorrect, your can update the details so that it can be transferred.`}</Paragraph>
                </>
            }
          </>
          : reason === RetirementAssetUntransferableReason.TRANSFER_ACTIVE ?
          <>
            <Paragraph>{`This pension is already in the process of being transferred to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
          </>
          : reason === RetirementAssetUntransferableReason.TRANSFER_COMPLETE ?
          <>
            <Paragraph>{`This pension has already been transferred to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
          </>
          : reason === RetirementAssetUntransferableReason.NOT_PENSION ?
          <>
            <Paragraph>{`You have not recorded this asset is being a pension, and therfore it cannot be transferred to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
          </>
          :<>
            <Paragraph>{`Please contact support to understand more about why this pension cannot be transferred.`}</Paragraph>
          </>
      }
    </>
  )
}

