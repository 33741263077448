import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import { map } from 'lodash'
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { ClientClientSegmentDto } from "store/dto/client.dto"
import { InvestmentPlanDto } from "store/dto/investment-plan.dto"
import { Colors, Flex, Sizing } from "styles"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { InvestmentAssetModalContent } from './InvestmentAssetModalContent'
import { envVariables } from "environment"
import { ContentDivider } from "components/Layout/ContentDivider"

type InvestmentPlanModalContentProps = {
  accountDescriptor: string
  investmentPlan: InvestmentPlanDto
  segment: ClientClientSegmentDto
}

export const InvestmentPlanModalContent = (props: InvestmentPlanModalContentProps) => {
  const { accountDescriptor, investmentPlan, segment } = props
  const {
    name,
    shortDescription,
    longDescription,
    sustainabilityRating,
    assetId,
    asset,
    logo,
  } = investmentPlan || {}

  const { genericPensionIllustrationUrl } = envVariables

  // const sustainabilityRatingArray = map(new Array(5), (el, i) => i < sustainabilityRating)

  return (
    <>
      <Headline style={localStyles.title}>{name}</Headline>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{shortDescription}</Subheading>
      <View style={{
        alignSelf: 'center',
      }}>
        <Image source={{ uri: logo }} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Paragraph style={localStyles.text}>{longDescription}</Paragraph>
      <Paragraph style={localStyles.text}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Paragraph>
      
      <InvestmentAssetModalContent
        accountDescriptor={accountDescriptor}
        asset={asset}
        segment={segment}
        forPlan={true}
      />

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Illustration'}</Subheading>
      <Paragraph style={localStyles.text}>{`Pension illustration for our plans:`}</Paragraph>
      <ExternalLinkButton
        url={genericPensionIllustrationUrl}
        directToBrowser={true}
      >
        {`Illustration Document`}
      </ExternalLinkButton>

    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})