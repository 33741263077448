import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetRetirementProfileQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'

export const ChangeRetirementBudget_02_LondonChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const { data: retirementProfile, error, isLoading, refetch } = useGetRetirementProfileQuery()

  //Setup form
  const formObj = useForm<{ insideLondon: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      insideLondon: workingRetirementProfileData?.insideLondon || retirementProfile?.insideLondon
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementProfile(attributes))
    navigation.navigate(nextScreen)
  }

  const londonOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'In London',
    },
    {
      value: false,
      label: 'Elsewhere'
    }
  ]

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Where do you think you'll retire?`}
      subHeading={`Jarvis can propose a realistic budget based on where you plan to retire`}
    >
      <ManagedSimpleChoiceInput
        name={'insideLondon'}
        formObj={formObj}
        options={londonOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      <Paragraph>{`As London is a disproportionately expensive city in the UK, we can propose budget options that reflect the higher cost of living`}</Paragraph>
    </ModalProcessScreen>
  )
}
