import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useGetBeneficiariesQuery, useUpdateMeMutation } from 'store/apiSlice'

export const BeneficiariesSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute }  = route?.params || {}
  
  const [reportingFinished, setReportingFinished] = useState(false)
  
  const { data: beneficiaries, error: beneficiariesError} = useGetBeneficiariesQuery()
  const [ updateClient, { data: client, error: clientError, isLoading: clientIsLoading } ] = useUpdateMeMutation()

  const isLoading = clientIsLoading
  const error: any = clientError

  //Contine on enter (beneficiaries already saved)
  useEffect(() => {
    save()
  }, [])

  //Amplitude reporting
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }
    
    if (beneficiaries) {
      const nominations = beneficiaries?.nominations || []
      ampli.beneficiariesSetup({
        skipped: false,
        beneficiaryCount: nominations ? nominations.length : 0,
      })
      setReportingFinished(true)
    } else if (beneficiariesError) {
      setReportingFinished(true)
    }
  }, [beneficiaries, beneficiariesError])

  //Navigate when client saved and reporting done
  useEffect(() => {
    if (client && reportingFinished) {
      end()
    }
  }, [client, reportingFinished])

  const save = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        beneficiaries: true,
      },
    })
  }

  const end = () => {
    rootNavigate(nextRoute, route?.params)
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={save}
      isLoading={isLoading}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
