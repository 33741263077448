import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { ClientAnyDto } from 'store/dto/client.dto'

export const RetirementIncomesAddStatePension_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, client }: { nextScreen: string, onDismiss: any, client: ClientAnyDto }  = route?.params || {}

  const next = () => {
    navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (      
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'capture_retirement_income.png'} 
      headline={`Add ${client?.isSpouse ? `${client?.firstName}'s` : `your`} state pension`}
      subHeading={`The State Pension is provided to individuals who have a qualifying National Insurance record.`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.STATE_PENSION} />}
    >
    </ModalProcessIntroScreen>
  )
}
