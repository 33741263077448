  import { Subheading } from 'components/Typography'
import React, { useState } from 'react'
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { Checkbox, DataTable } from 'react-native-paper'
import { layoutStyles } from 'styles/common'
import { EmployerContributionTableRow } from './EmployerContributionTableRow'
import { Colors, Sizing } from 'styles'

type EmployerContributionTableProps = {
  formObj: UseFormReturn<any>
  name: string
  bulkSelectMode: boolean
  selectAllFunction: () => void
  unselectAllFunction: () => void
}

export const EmployerContributionTable = (props: EmployerContributionTableProps) => {
  const { formObj, name, bulkSelectMode, selectAllFunction, unselectAllFunction } = props
  const { control } = formObj
  const { fields } = useFieldArray({
    control,
    name,
  })

  const [selectAll, setSelectAll] = useState(false)
  
  const handleSelectAll = () => {
    setSelectAll(true)
    selectAllFunction()
  }

  const handleUnselectAll = () => {
    setSelectAll(false)
    unselectAllFunction()
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <View style={{ width: Sizing.x40 }}>
                {
                  bulkSelectMode
                    ? <Checkbox.Android
                    color={Colors.brand.purple1}
                    uncheckedColor={Colors.brand.purple1}
                    status={selectAll ? 'checked' : 'unchecked'}
                    onPress={() => {
                      if (selectAll) {
                        handleUnselectAll()
                      } else {
                        handleSelectAll()
                      }
                    }}
                      />
                    : <></>
                }
              </View>
              <Subheading style={localStyles.tableTitleText}>{'Name'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
             <Subheading style={localStyles.tableTitleText}>{'National Insurance No'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title numeric textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <Subheading style={localStyles.tableTitleText}>{'Employer Amount'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title numeric textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <Subheading style={localStyles.tableTitleText}>{'Employee Amount'}</Subheading>
            </View>
          </DataTable.Title>
          <DataTable.Title textStyle={localStyles.title}>
            <View style={localStyles.titleWrap}>
              <Subheading style={localStyles.tableTitleText}>{'Actions / Notes'}</Subheading>
            </View>
          </DataTable.Title>
        </DataTable.Header>
        {
          fields.map((field, index) => {
            return (
              <View
                key={field.id}
              >
                <EmployerContributionTableRow
                  formObj={formObj}
                  name={name}
                  index={index}
                  bulkSelectMode={bulkSelectMode}
                />
              </View>
            )
          })
        }
      </DataTable>
    </View>
  )
}

const localStyles = StyleSheet.create({
  title: {
    height: Sizing.x30,
    maxHeight: Sizing.x30,
  },
  tableTitleText: {
    color: Colors.brand.purple1,
  },
  titleWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Sizing.x5,
    flex: 1,
  },
})


