import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { EmailLinkButton } from 'components/Utility/EmailLinkButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import React from 'react'
import { View } from 'react-native'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { Sizing } from 'styles'

export const SchemeInviteAccept_01_About = ({ route, navigation }) => {
  const { nextScreen, schemeInvite }: { nextScreen: string, schemeInvite: PublicGroupSchemeInviteDto }  = route?.params || {}

  const { groupScheme } = schemeInvite || {}
  const { organizationName, schemeName, companyNo, primaryContactUser } = groupScheme || {}
  const { email, firstName, surname } = primaryContactUser || {}

  const schemeInfo: UnborderedTableRow[] = [
    {
      label: `Employer`,
      value: organizationName,
    },
    {
      label: `Company No.`,
      value: companyNo,
    },
    {
      label: `Scheme Name`,
      value: schemeName
    },
    {
      label: `Primary Contact`,
      value: `${firstName} ${surname}`
    },
  ]

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={`About the Scheme`}
      subHeading={`The details of the workplace scheme are shown below`}
    >
      <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={schemeInfo?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View>
      <Paragraph>{`Please review the details, and if you have any queries, get in touch with the primary contact for your employer.`}</Paragraph>
      <EmailLinkButton
          email={email}
        >{`Email Primary Contact`}</EmailLinkButton>
    </ProcessScreen>
  )
}
