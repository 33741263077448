import { InvestmentChoice_01_Conditions, InvestmentChoice_02_Sorry, InvestmentChoice_03_Promotion, InvestmentChoice_10_InvestmentTypeChoice, InvestmentChoice_11_InvestmentTypeUnfollowChoice, InvestmentChoice_12_InvestmentPlanChoice, InvestmentChoice_13_AssetAllocation, InvestmentChoice_14_GroupPortfolioChoice, InvestmentChoice_15_FollowPersonalPension, InvestmentChoice_20_OptOut, InvestmentChoice_21_Drawdown, InvestmentChoice_30_Terms, InvestmentChoice_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder, ProcessStackBuilderScreen } from 'navigation/stacks/ProcessStackBuilder'
import { concat } from 'lodash'
import { AccountDto, AccountType } from 'store/dto/account.dto'

export const InvestmentChoiceProcessStack = ({ route, navigation }) => {
  const { contextAccount }: { contextAccount: AccountDto } = route?.params || {}

  const contextAccountIsGroupPension = contextAccount && contextAccount.accountType === AccountType.GROUP_PENSION

  const processScreens: ProcessStackBuilderScreen[] = concat(
    //If no contextAccount, include conditions, show promotion
    contextAccount ? [] : [
      { 
        navigationId: 'Conditions',
        component: InvestmentChoice_01_Conditions
      },
      { 
        navigationId: 'Sorry',
        component: InvestmentChoice_02_Sorry,
      },
      { 
        navigationId: 'Promotion',
        component: InvestmentChoice_03_Promotion,
      },
    ],
    //Always include investment choice screens
    [
      { 
        navigationId: 'InvestmentTypeChoice',
        component: InvestmentChoice_10_InvestmentTypeChoice,
      },
    ],
    //If following group pension, include unfollow choice
    contextAccountIsGroupPension && contextAccount.usePersonalPensionInvestmentChoice ? [
      { 
        navigationId: 'InvestmentTypeUnfollowChoice',
        component: InvestmentChoice_11_InvestmentTypeUnfollowChoice,
      }, 
    ] : [],
    //Always include the actual choice screens
    [
      {
        navigationId: 'PlanChoice',
        component: InvestmentChoice_12_InvestmentPlanChoice,
      },
      {
        navigationId: 'AssetAllocation',
        component: InvestmentChoice_13_AssetAllocation,
      },
      {
        navigationId: 'GroupPortfolioChoice',
        component: InvestmentChoice_14_GroupPortfolioChoice,
      },
    ],
    //If non-following group pension, include follow
    contextAccountIsGroupPension && !contextAccount.usePersonalPensionInvestmentChoice ? [
      { 
        navigationId: 'FollowPersonalPension',
        component: InvestmentChoice_15_FollowPersonalPension,
      }, 
    ] : [],
    //If no contextAccount, include opt out, drawdown
    contextAccount ? [] : [
      { 
        navigationId: 'OptOut',
        component: InvestmentChoice_20_OptOut,
      }, 
      { 
        navigationId: 'Drawdown',
        component: InvestmentChoice_21_Drawdown,
      }, 
    ],
    //Always include terms/confirmation
    [
      { 
        navigationId: 'Terms',
        component: InvestmentChoice_30_Terms,
      },  
    ]
  )

  return (
    <ProcessStackBuilder
      navigatorId={'InvestmentChoice'}
      processTitle={contextAccount ? 'Change Investment Strategy' : 'Choose Jarvis Plan'}
      previousRoute='InvestmentChoiceIntroScreen'
      nextRoute='InvestmentChoiceHeroScreen'
      initialParams={route?.params}
      initialRouteName={route?.params?.initialRouteName}
      processScreens={processScreens}
      resultScreen={{
        navigationId: 'Results',
        component: InvestmentChoice_99_Result,
      }}
    />
  )
}
