export enum NotificationMethod {
  EMAIL = 'email',
  PUSH = 'push',
}

export enum NotificationStatus {
  PENDING = 'pending',
  OK = 'ok',
  ERROR = 'error',
  STALE = 'stale',
}

export enum NotificationChannel {
  YOUR_ACCOUNT = 'Your Account',
}

export enum NotificationRefreshAction {
  CLIENT = 'client',
  CLIENT_PENSION = 'client_pension',
  SPOUSE = 'spouse',
  SPOUSE_PENSION = 'spouse_pension',
  RETIREMENT_ASSETS = 'retirement_assets',
  MESSAGES = 'messages',
  CONTRIBUTIONS = 'contributions',
  INVITES = 'invites',
  SCHEME_INVITES = 'scheme_invites',
  SCHEME_REQUESTS = 'scheme_requests',
}
