import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { InformationButton } from 'components/Utility/InformationButton'
import { differenceInYears } from 'date-fns'
import { calculateAgeNow } from 'lib/dateHelpers'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { getModelGenerationMessages } from 'lib/loadingHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { last, max, ceil } from 'lodash'
import Victory from 'platform-lib/victory'
import React, { useEffect } from 'react'
import { Image, View } from 'react-native'
import { ampli } from 'src/ampli'
import { useLazyGetCalculatorModelQuery } from 'store/apiSlice'
import { CalculatorModelRequestDto } from 'store/dto/calculator.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'
import { Colors, Paper, Sizing } from 'styles'
import { linkParams } from 'store/authSlice'
import { AppIllustration } from 'components/Utility/AppIllustration'

const VictoryAxis = Victory.VictoryAxis
const VictoryBar = Victory.VictoryBar
const VictoryChart = Victory.VictoryChart
const VictoryTheme = Victory.VictoryTheme

export const Calculator_50_Forecast = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  //Report progress
  useEffect(()=>{
    ampli.calculatorUsage({
      processStep: 'results',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })
  },[])
  
  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)
  
  const [ getCalculatorModel, { data: calculatorModel, error: calculatorModelError, isLoading: calculatorModelIsLoading } ] = useLazyGetCalculatorModelQuery()
  
  const error = calculatorModelError
  const isLoading = calculatorModelIsLoading

  //Get calculator model on load
  useEffect(() => {
    getModel()
  }, [])

  const getModel = () => {
    const request: CalculatorModelRequestDto = {
      client: workingCalculatorRequestData?.client,
      spouse: workingCalculatorRequestData?.spouse,
      expenses: workingCalculatorRequestData?.expenses,
      income: workingCalculatorRequestData?.income, 
      asset: workingCalculatorRequestData?.asset,
    }
    getCalculatorModel(request, false)
  }

  const next = () => {
    viableRetirementAge
    dispatch(updateWorkingCalculatorRequest({
      viableRetirementAge,
      terms: calculatorModel.terms,
    }))
    navigation.navigate(nextScreen)
  }

  const { colors: themeColors } = Paper.useAppTheme()
  

  const {
    client,
    asset,
    timeline,
    income,
    grossBudgetTotalAmount,
  } = workingCalculatorRequestData || {}

  const {
    statePensionAmount,
    spouseStatePensionAmount,
  } = income || {}
  
  const {
    totalCurrentValue,
    totalMonthlyContributionAmount,
  } = asset || {}
  
  const {
    output,
    terms,
  } = calculatorModel || {}

  const {
    viableRetirementAge,
  } = output || {}

  const {
    ageAtLastSurvivorDeathDate,
    retirementDate,
    birthDate,
  } = timeline || {}

  const termData = terms ? terms : []

  const getAssetValueAtRetirement = () => {
    const retirementTerm = terms ? terms.find(term => {
      return term.age === viableRetirementAge
    }) : undefined
    return retirementTerm ? retirementTerm.startValue : 0
  }

  const getAssetValueAtDeath = () => {
    const retirementTerm = terms ? last(terms) : undefined
    return retirementTerm ? retirementTerm.startValue : 0
  }

  const currentAge = calculateAgeNow(birthDate)
  const assetValueAtRetirement = getAssetValueAtRetirement()
  const assetValueAtDeath = getAssetValueAtDeath()
  const hasCurrentSavings = !!totalCurrentValue
  const hasMonthlyContributions = !!totalMonthlyContributionAmount
  const hasAnySavings = hasCurrentSavings || hasMonthlyContributions
  const yearsUntilRetirement = viableRetirementAge - currentAge
  const yearsInRetirement = ageAtLastSurvivorDeathDate - viableRetirementAge

  const clientAgeAtRetirementDate = differenceInYears(
    new Date(retirementDate),
    new Date(birthDate),
  )
  
  const statePensionTotal = statePensionAmount + spouseStatePensionAmount
  const hasStatePension = statePensionTotal > 0
  const hasMultipleStatePensions = statePensionAmount > 0 && spouseStatePensionAmount > 0
  const percentageCovered = (statePensionTotal / grossBudgetTotalAmount) * 100
  const allCovered = percentageCovered >= 100

  const isWeb = platformIsWeb()

  const features: FeatureBoxRow[] =
    hasAnySavings ?
      [
        { label: `Continuous forecasting on how pensions and assets will grow over time` },
        { label: `Understand how additional savings change when you can retire` },
      ]
      : allCovered
        ? [
            { label: `Get started with your Jarvis Lifetime Pension` },
            { label: `See how saving now would you to retire earlier` },
          ]
        : [
            { label: `Get started with your Jarvis Lifetime Pension` },
            { label: `See how saving now can help you achieve your desired lifestyle` },
          ]

  const furtherGrowthInRetirement = assetValueAtDeath > assetValueAtRetirement
  const canRetireBeforeStatePensionAge = viableRetirementAge < clientAgeAtRetirementDate
  
  const informationTexts: string[] = []

  if (hasAnySavings) {
    informationTexts.push(
      hasCurrentSavings && hasMonthlyContributions
        ? `Assuming your contributions continue, your current retirement savings of ${formatCurrencyAmount(totalCurrentValue)} could be worth around ${formatCurrencyAmount(assetValueAtRetirement)} when you are age ${viableRetirementAge}.`
      : hasCurrentSavings
        ? `Your current retirement savings of ${formatCurrencyAmount(totalCurrentValue)} could be worth around ${formatCurrencyAmount(assetValueAtRetirement)} when you are age ${viableRetirementAge}.`
        : `Assuming your current contributions continue, your retirement pot could grow to be worth around ${formatCurrencyAmount(assetValueAtRetirement)} when you are age ${viableRetirementAge}.`
    )

    informationTexts.push(
      furtherGrowthInRetirement
        ? `Your savings could then grow further during retirement to leave around ${formatCurrencyAmount(assetValueAtDeath)} when you are age ${ageAtLastSurvivorDeathDate}.`
        : `Your savings would then be used up during retirement to leave around ${formatCurrencyAmount(assetValueAtDeath)} when you are age ${ageAtLastSurvivorDeathDate}.`
    )

    if (canRetireBeforeStatePensionAge && hasStatePension) {
      informationTexts.push(`Initially, your savings would be used at a greater rate until your state pension${hasMultipleStatePensions ? 's' : ''} are available.`)
    }

    informationTexts.push(`Jarvis always shows forecasts in real terms, meaning your savings may actually fall in value due to the effects of inflation.`)
    informationTexts.push(`In other words, any money saved may be worth less at times in the future, if the rate of investment growth does not keep up with inflation. This calculator uses a standard medium risk profile to model asset growth, with assumed fees of ${formatPercentageAmount(1, 0)}.`)
  } else if (allCovered) {
    informationTexts.push(`Your state pensions should cover the gross income required to support your retirment lifestyle.`)
    informationTexts.push(`To retire any earlier, you would need to put away retirement savings to cover the period of time until your state pension${hasMultipleStatePensions ? 's are' : ' is'} available.`)
  } else {
    informationTexts.push(`Without sufficient regular income to cover your retirement lifestyle, you cannot cover the gross income required to support your retirement lifestyle.`)
    informationTexts.push(`In order to retire with your desired lifestyle, you would need to put away some retirement savings to build up a pot that can provide additional income.`)
  }

  const informationTitle = `Forecast Explained`

  const informationIllustationFilename = hasAnySavings
    ? 'gamified_approach.png'
    : allCovered
      ? 'meditating_man.png'
      : 'regular_contributions.png'

  const yearsForText = calculatorModel ? ceil((yearsInRetirement + yearsUntilRetirement) / 15) : 0
  const showViableAge = calculatorModel ? yearsInRetirement > yearsForText && yearsUntilRetirement > yearsForText : false
  const flexForViableAge = showViableAge ? yearsForText : 0
  const flexUntilRetirement = max([yearsUntilRetirement - 0.5, yearsForText])
  const flexInRetirement = max([yearsInRetirement + 0.5, yearsForText])

  // console.log({
  //   yearsInRetirement,
  //   yearsUntilRetirement,
  //   yearsForText,
  //   showViableAge,
  //   flexForViableAge,
  //   flexUntilRetirement,
  //   flexInRetirement,
  // })

  const labelFontSize = isWeb ? Sizing.x15 : Sizing.x10 

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingUseHolidayGraphics={true}
      loadingMessage={getModelGenerationMessages()}
      error={error}
      errorTryAgain={getModel}
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={hasAnySavings
        ? `Here's how your savings are predicted to grow`
        : allCovered
          ? `You can retire when your state pension${hasMultipleStatePensions ? 's are ' : ' is '}available`
          : `You won't generate any savings to use in retirement`
      }
      subHeading={hasAnySavings
        ? `They need to grow for around ${yearsUntilRetirement} years to then last for ${yearsInRetirement} more in retirement`
        : allCovered
          ? `Without savings, you can't retire until the income from your state pension${hasMultipleStatePensions ? 's are ' : ' is '}available`
          : `You won't be able to support your retirement lifestyle on the state pension${hasMultipleStatePensions ? 's' : ''} you have`
      }
      subHeadingInfo={<InformationButton title={informationTitle} texts={informationTexts} illustrationFilename={informationIllustationFilename} buttonTitle={`Tell me why`} />}
      footerInfo={<FeatureBox data={features} />}
    >
      {
        hasAnySavings
          ?
            <>
              <View style={{
                alignSelf: 'center',
                width: isWeb ? '100%' : getScreenAppWidth() - Sizing.x60,
              }}>
                <VictoryChart
                  padding={{
                    top: Sizing.x10,
                    bottom: Sizing.x10,
                  }}
                  domainPadding={{ x: 5 }}
                  height={scaleNormalizer(100)}
                  width={isWeb ? undefined : getScreenAppWidth() - Sizing.x60}
                  theme={VictoryTheme.material}
                >
                  <VictoryBar
                    data={termData}
                    // animate={{
                    //   duration: 200,
                    //   onLoad: { duration: 200 }
                    // }}
                    style={{

                      data: {
                        fill: ({ datum }) => { return (
                          datum?.age === viableRetirementAge
                          ? themeColors.primary
                          : datum?.age < viableRetirementAge
                            ? Colors.brand.green2
                            : Colors.brand.red2
                        ) }
                      }
                    }}
                    x="age"
                    y="startValue" />
                  <VictoryAxis style={{
                    axis: { stroke: 'none' },
                    ticks: { stroke: 'none' },
                    tickLabels: { fill: 'none' },
                    grid: { stroke: 'transparent' }
                  }} />
                </VictoryChart>
              </View>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: Sizing.x10,
                alignSelf: 'center',
                width: '100%',
              }}>
                <View style={{ flex: flexUntilRetirement - flexForViableAge/2 }}>
                  <Text style={{ fontSize: labelFontSize, lineHeight: labelFontSize, textAlign: 'left', color: themeColors.primary, paddingLeft: Sizing.x5 }}>{currentAge}</Text>
                </View>
                { showViableAge ?
                    <View style={{ flex: flexForViableAge }}>
                      <Text style={{ fontSize: labelFontSize, lineHeight: labelFontSize, textAlign: 'center', color: themeColors.primary, paddingLeft: Sizing.x5 }}>{viableRetirementAge}</Text>
                    </View>
                    : <></>
                }
                <View style={{ flex: flexInRetirement - flexForViableAge/2 }}>
                  <Text style={{ fontSize: labelFontSize, lineHeight: labelFontSize, textAlign: 'right', color: themeColors.primary, paddingLeft: Sizing.x5 }}>{ageAtLastSurvivorDeathDate}</Text>
                </View>
              </View>
            </>
          :  <AppIllustration filename={informationIllustationFilename}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }}
              />
        }
    </ProcessScreen>
  )
}
