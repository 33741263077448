import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { WebAppDownloadContent } from 'components/Utility/WebAppDownloadContent'
import { getEntryScreenForAppContext } from 'lib/navigationHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { AppContext, linkParams, loginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { useSendHandOffLinkMutation } from 'store/passwordlessSlice'
import { Sizing } from 'styles'

export const Authentication_12_HandOffLink = ({ route, navigation }) => {
  const linkConfig = useAppSelector(linkParams)
  const { affiliateCode, affiliateRef } = linkConfig || {}

  const [closeDialogVisible, setCloseDialogVisible] = useState(false)
  const [linkSendDone, setLinkSendDone] = useState(false)

  const [sendHandOffLink, { data: sendHandOffLinkResult, error: sendHandOffLinkError, isLoading: sendHandOffLinkIsLoading }] = useSendHandOffLinkMutation()
  
  const loginConfig = useAppSelector(loginParams)
  const { emailAddress, authType, fromCalculator } = loginConfig || {}
  
  useEffect(()=>{
    if (fromCalculator) {
      ampli.calculatorUsage({
        processStep: 'handoff_link',
        affiliateCode,
        affiliateRef,
      })
    }
  },[])

  const [showButtons, setShowButtons] = useState(false)
  const finishRoute = getEntryScreenForAppContext(AppContext.CLIENT)

  const handleFinish = () => {
    if (linkSendDone) {
      finish()
    } else {
      setCloseDialogVisible(true)
    }
  }

  const finish = () => {
    rootNavigate(finishRoute)
  }
  
  
  const sendLink = () => {
    const linkAttributes = linkConfig || {}
    sendHandOffLink({
      email: emailAddress,
      authType: authType || AUTHTYPE.REGISTER,
      ...linkAttributes,
     })
  }

  useEffect(()=>{
    if (sendHandOffLinkResult) {
      setLinkSendDone(true)
    }
  },[sendHandOffLinkResult])

  return (
    <ProcessScreen
      showButton={true}
      buttonTitle={`Finish`}
      buttonAction={handleFinish}
      enableButton={true}
      headline={`Continue on your mobile device`}
      subHeading={showButtons
        ? `Use the relevant app store link to get the Jarvis App and ${authType === AUTHTYPE.LOGIN ? 'login with one tap' : 'create an account in one tap'}`
        : `Scan the QR code below with your mobile device to get the Jarvis App and ${authType === AUTHTYPE.LOGIN ? 'login with one tap' : 'create an account in one tap'}`}
    >
      <View style={{
        paddingTop: Sizing.x10,
      }}>
        <View style={showButtons ? { paddingVertical: Sizing.x30 } : {} }>
          <WebAppDownloadContent
            noTitle={true}
            forceShowButtons={showButtons}
            handOffEmailAddress={emailAddress}
            authType={authType}
          />
        </View>
        <Button
          mode={'text'}
          onPress={showButtons ? () => setShowButtons(false) : () => setShowButtons(true)}
        >
          {showButtons ? 'Show QR Code Instead' : 'Viewing on your mobile device?'}
        </Button>
        {
          showButtons ? <></> :
          <>
            {
              sendHandOffLinkResult
                ? <Paragraph>{`We've sent a link for you to use on your mobile to ${emailAddress}`}</Paragraph>
                : <>
                    <Paragraph>{'Trouble scanning the QR Code? If you prefer, we can send you an email with a link to continue on mobile:'}</Paragraph>
                    <Button
                      mode={'text'}
                      onPress={sendLink}
                      disabled={sendHandOffLinkIsLoading}
                    >
                      {sendHandOffLinkIsLoading ? 'Sending...' : 'Send me an email'}
                    </Button>
                  </>
            }
          </>
        }
      </View>
      <ConfirmationDialog
        visible={closeDialogVisible}
        title={'All finished?'}
        content={`Have you scanned the QR Code with your mobile device?`}
        onCancel={() => setCloseDialogVisible(false)}
        onConfirm={finish}
        confirmLabel={`Yes, all done`}
        cancelLabel={'Go Back'}
      />
    </ProcessScreen>
  )
}