import React from 'react'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { suggestName } from 'lib/retirementAssetHelpers'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'

export const RetirementAssetsAddAsset_11_Name = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ name: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: workingRetirementAssetData?.name || suggestName(workingRetirementAssetData?.assetType, workingRetirementAssetData?.pensionProviderBrandName, workingRetirementAssetData?.employerName)
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset(attributes))
    navigation.navigate(nextScreen)
  }
  
  const isPension = [RetirementAssetType.PERSONAL, RetirementAssetType.WORKPLACE].includes(workingRetirementAssetData?.assetType)

  return (
    <ModalProcessScreen
      buttonTitle={isPension ? 'Save Pension' : 'Save Asset'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Give it a memorable name`}
    >
      <ManagedTextInput
        name={'name'}
        formObj={formObj}
        // label={'Name'}
        placeholder={'Enter a name'}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ModalProcessScreen>
  )
}
