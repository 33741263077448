import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerHistoryPaymentsListScreen } from './Screens/EmployerHistoryPaymentsListScreen'

export const EmployerHistoryPaymentSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Payment History'}
    >
      <EmployerHistoryPaymentsListScreen />
    </SectionSimpleContainer>
  )
}

