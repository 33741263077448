import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getActionDate } from 'lib/dateHelpers'
import { getMessageBodyPreview, getStatementDescription, getStatementIconName } from 'lib/messageHelpers'
import React from 'react'
import { MessageDto, MessageStatus } from 'store/dto/message.dto'
import { Sizing, Paper } from 'styles'

type StatementListItemProp = {
  message: MessageDto
  onPressFunction?: () => void
}
export const StatementListItem = (props: StatementListItemProp) => {
  const { message, onPressFunction } = props
  const { created, status } = message

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName: any = getStatementIconName(message)
  const description: any = getStatementDescription(message)
  const bodyPreview = getMessageBodyPreview(message)

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={description}
      titleStyle={status === MessageStatus.UNREAD ? { color: themeColors.accent } : undefined}
      titleRight={getActionDate(created)}
      subTitle={bodyPreview}
      subTitleRight={<MaterialCommunityIcons name={'paperclip'} size={Sizing.x20}  color={themeColors.primary} />}
    />
  )
}
