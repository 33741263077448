import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { BankConnectionDto } from 'store/dto/bank-connection.dto'
import { Colors, Flex, Sizing } from 'styles'

export type BankLinkProps = {
  bank: BankConnectionDto
  isManual?: boolean
  isComplete?: boolean
  isError?: boolean
}

export const BankLink = (props: BankLinkProps) => {
  const { bank, isManual, isComplete, isError } = props || {}

  return (
      <View style={localStyles.row}>
        <View style={localStyles.imageContainer}>
          <Image source={require('assets/brand/jarvis-symbol-blk-4x.png')} style={{
            width: Sizing.x40,
            height: Sizing.x40,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
        </View>
        <View style={localStyles.imageContainer}>
          {
            isError
              ? <MaterialCommunityIcons name="alert-rhombus" size={Sizing.x30} color={Colors.warning.s400} />
              : isComplete
                ? <MaterialCommunityIcons name="checkbox-marked-outline" size={Sizing.x30} color={Colors.brand.green2} />
                : isManual
                  ? <MaterialCommunityIcons name="account-arrow-right-outline" size={Sizing.x30} color={Colors.neutral.black} />
                  : <MaterialCommunityIcons name="arrow-u-left-bottom" size={Sizing.x30} color={Colors.neutral.black} />
          }          
        </View>
        <View style={localStyles.imageContainer}>
          <Image source={bank?.logo ? { uri: bank?.logo} : require('assets/icons/default_bank.png')} style={{
            width: Sizing.x50,
            height: Sizing.x50,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
        </View>
      </View>
  )
}


const localStyles = StyleSheet.create({
  row: {
    ...Flex.row.center,
    alignItems: 'center',
  },  
  imageContainer: {
    paddingHorizontal: Sizing.x5,
    alignSelf: 'center',
  },

})