import { Headline, Paragraph, Subheading } from "components/Typography"
import { Text } from "components/Typography/Text"
import { envVariables } from "environment"
import { YAHOO_QUOTE_BASE_URL } from 'lib/constants'
import { formatUkDate } from "lib/dateHelpers"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatPercentageAmount } from "lib/generalHelpers"
import { orderBy, sumBy } from 'lodash'
import React from 'react'
import { Image, StyleSheet, View } from "react-native"
import { AssetDto } from 'store/dto/asset.dto'
import { ClientClientSegmentDto } from "store/dto/client.dto"
import { Colors, Sizing } from "styles"
import { ChargeBreakdown } from "./ChargeBreakdown"
import { ChargeCalculatorSlider } from "./ChargeCalculatorSlider"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { ExternalLinkText } from "./ExternalLinkText"
import { PieChartWithLegend } from "./PieChartWithLegend"
import { RiskRating } from "./RiskRating"
import { UnborderedTable } from "./UnborderedTable"
import { ContentDivider } from "components/Layout/ContentDivider"

type InvestmentAssetModalContentProps = {
  accountDescriptor: string
  asset: AssetDto
  segment: ClientClientSegmentDto
  forPlan?: boolean
  omitPlatformCharges?: boolean
}

export const InvestmentAssetModalContent = (props: InvestmentAssetModalContentProps) => {
  const { accountDescriptor, asset, segment, forPlan, omitPlatformCharges } = props
  const {
    shortName,
    longName,
    isin,
    yahooSymbol,
    riskRating,
    riskRatingScaleMax,
    kiidDocumentUrl,
    manager,
    annualFeePercentage,
    initialFeePercentage,
    referenceSource,
    trackRecordReferenceDate,
    trackRecord,
    holdingsReferenceDate,
    investmentTypePercentages,
    industrySectorPercentages,
    topStockHoldings,
    assetTypeName,
    assetCategories,
    logo,
  } = asset || {}

  const { genericPensionIllustrationUrl } = envVariables

  const trackRecordReferenceDateString = trackRecordReferenceDate ? formatUkDate(new Date(trackRecordReferenceDate)) : ''
  const holdingsReferenceDateString = holdingsReferenceDate ? formatUkDate(new Date(holdingsReferenceDate)) : ''

  const orderedStockHoldings = topStockHoldings ? orderBy(topStockHoldings, ['percentage'], ['desc']) : []
  const totalShownStockHoldings = topStockHoldings ? sumBy(topStockHoldings, 'percentage') : 0
  
  const yahooUrl = `${YAHOO_QUOTE_BASE_URL}${yahooSymbol}`
  
  const imageSource = logo ? { uri: logo } : require('assets/icons/default_provider.png')

  return (
    <>
      {
        forPlan ? <></> :
          <>
            <Headline style={localStyles.title}>{shortName}</Headline>

            <ContentDivider />
            <View style={{
              alignSelf: 'center',
            }}>
              <Image source={imageSource} style={{
                width: scaleNormalizer(200),
                height: scaleNormalizer(200),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            </View>
            <Paragraph style={localStyles.text}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Paragraph>
          </>
      }

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Fund Details'}</Subheading>
      <Paragraph style={[localStyles.text, { fontWeight: '900' }]}>{`Name: ${longName}`}</Paragraph>
      <Paragraph style={localStyles.text}>{`Managed by: ${manager}`}</Paragraph>
      <Paragraph style={localStyles.text}>{`ISIN: ${isin}`}</Paragraph>
      <Paragraph style={localStyles.text}>{`Category: ${assetTypeName}`}</Paragraph>
      <Paragraph style={localStyles.text}>{`Additional information provided by the investment manager:`}</Paragraph>
      <ExternalLinkButton
        url={kiidDocumentUrl}
        directToBrowser={true}
      >
        {`Fund Factsheet`}
      </ExternalLinkButton>
      <ExternalLinkButton
        url={yahooUrl}
      >
        {`More Information`}
      </ExternalLinkButton>

      {
        omitPlatformCharges ?
        <>
          <ContentDivider />
          <Subheading style={localStyles.subTitle}>{'Fund Charges'}</Subheading>
          <UnborderedTable
            data={[
              {
                label: `Initial Charge`,
                value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(initialFeePercentage, 2)}</Text>
              },
              {
                label: `Annual Charge`,
                value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(annualFeePercentage, 2)}</Text>
              },
            ]}
          />
        </> : 
        <>
          <ChargeBreakdown
            accountDescriptor={accountDescriptor}
            segment={segment}
            initialFeePercentage={initialFeePercentage}
            annualFeePercentage={annualFeePercentage}
          />
          <ChargeCalculatorSlider
            accountDescriptor={accountDescriptor}
            segment={segment}
            annualFeePercentage={annualFeePercentage}
          />
        </>
      }

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Your money will be invested in'}</Subheading>
      <Paragraph style={localStyles.text}>{`Portfolio Composition ${holdingsReferenceDateString}`}</Paragraph>
      <View style={{ marginLeft: -Sizing.x30 }}>
        <PieChartWithLegend dataSet={{
          categories: investmentTypePercentages ? investmentTypePercentages.map(investmentType => {
            return {
              name: investmentType.investmentType,
              items: [
                {
                  x: '',
                  y: investmentType.percentage,
                }
              ]
            }
          }) : []
        }} />
      </View>
      <Paragraph style={localStyles.text}>{'Definitions of above for example: stocks means owning bits of companies, bonds means lending money. More stocks generally means more growth potential and up and downs.'}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Stock Holdings'}</Subheading>
      <Paragraph style={localStyles.text}>{`Top ${orderedStockHoldings.length} stock holding${orderedStockHoldings.length > 1 ? 's' : ''} ${holdingsReferenceDateString} (${formatPercentageAmount(totalShownStockHoldings, 2)} of overall assets)`}</Paragraph>
      <UnborderedTable
        data={orderedStockHoldings ? orderedStockHoldings.map(holding => {
          return {
            label: <Text>{`${holding?.holdingName}`} <ExternalLinkText url={`${YAHOO_QUOTE_BASE_URL}${holding?.symbol}`}>{holding?.symbol}</ExternalLinkText></Text>,
            value: `${formatPercentageAmount(holding?.percentage, 2)}`,
          }
        }) : []}
      />
      <Paragraph style={localStyles.text}>{`Source: ${referenceSource}`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Which industries receive my money?'}</Subheading>
      <Paragraph style={localStyles.text}>{`Sector Weightings ${holdingsReferenceDateString}`}</Paragraph>
      <View style={{ marginLeft: -Sizing.x30, marginBottom: Sizing.x10 }}>
        <PieChartWithLegend dataSet={{
          categories: industrySectorPercentages ? industrySectorPercentages.map(sector => {
            return {
              name: sector.sectorName,
              items: [
                {
                  x: '',
                  y: sector.percentage,
                }
              ]
            }
          }) : []
        }} />
      </View>

      <Paragraph style={localStyles.text}>{`Source: ${referenceSource}`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Performance Track Record'}</Subheading>
      <Paragraph style={localStyles.text}>{`Trailing Returns (GBP) ${trackRecordReferenceDateString}`}</Paragraph>
      <UnborderedTable
        data={trackRecord ? trackRecord.map(trackRecord => {
          return {
            label: trackRecord.timeframeName,
            value: `${formatPercentageAmount(trackRecord.percentage, 2)}`
          }
        }) : []}
      />
      <Paragraph style={localStyles.text}>{`Source: ${referenceSource}`}</Paragraph>
      <Paragraph style={localStyles.text}>{`Please note: Past performance is no guarantee of fund results. Your money may go down as well as up in value.`}</Paragraph>

      <RiskRating
        riskRating={riskRating}
        riskRatingScaleMax={riskRatingScaleMax}
      />
      
      {
        forPlan ? <></> :
        <>
          <ContentDivider />
          <Subheading style={localStyles.subTitle}>{'Illustration'}</Subheading>
          <Paragraph style={localStyles.text}>{`Pension illustration:`}</Paragraph>
          <ExternalLinkButton
            url={genericPensionIllustrationUrl}
            directToBrowser={true}
          >
            {`Illustration Document`}
          </ExternalLinkButton>
        </>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})