import { useFocusEffect } from '@react-navigation/native'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { rootNavigate } from 'lib/RootNavigation'
import { logoutUserFromState } from 'lib/authHelpers'
import { getLogoutLoadingMessages } from 'lib/loadingHelpers'
import { Logger } from 'lib/logger'
import { getEntryScreenForAppContext } from 'lib/navigationHelpers'
import Auth0 from 'platform-lib/auth0'
import React, { useState } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { ampli } from 'src/ampli'
import { useDeleteTokenForCurrentUserMutation } from 'store/apiSlice'
import { pushToken } from 'store/authSlice'
import { useAppSelector } from 'store/hooks'
import { Colors, Flex } from 'styles'
import { layoutStyles } from 'styles/common'

const useAuth0 = Auth0.useAuth0

export const AuthenticationLogoutScreen = ({ route, navigation }) => {

  const [deletePushToken] = useDeleteTokenForCurrentUserMutation()
  const pushTokenValue = useAppSelector(pushToken)

  const dispatch = useDispatch()
  const { clearSession, clearCredentials, logout } = useAuth0()

  const appContext = useSelector((state: any) => state.auth.appContext)

  const routeAfterLogout = getEntryScreenForAppContext(appContext)

  const [autoLogoutDone, setAutoLogoutDone] = useState<boolean>(false)
  const [logoutTimedOut, setLogoutTimedOut] = useState<boolean>(false)

  const doLogout = () => {
    Logger.info('Performing logout...')
    ampli.clientLogout()
    if (pushTokenValue) {
      Logger.info('Deleting push token...')
      deletePushToken(pushTokenValue)
    }
    // //Clear last app context for explicit logout
    // //TODO - consider if any implicit use of this function where this is no appropriate?
    // clearLastUserContext()
    logoutUserFromState(dispatch, clearSession, clearCredentials, logout)
    Logger.info({ appContext, routeAfterLogout } , 'Logged user out')
    rootNavigate(routeAfterLogout)
  }

  //Hook to always logout on focus
  useFocusEffect(() => {
    setLogoutTimedOut(false)
    setTimeout(() => timeoutLogout(), 3000)
    if (!autoLogoutDone) {
      setAutoLogoutDone(true)
      doLogout()
    } else {
      Logger.debug(`autoLogoutDone already set!`)
    }
  })

  const timeoutLogout = () => {
    setLogoutTimedOut(true)
  }

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={layoutStyles.mainContentContainer} >
        <View style={{
          ...Flex.override.fill,
          ...Flex.column.center,
        }}>
          <Loading message={getLogoutLoadingMessages()} useHolidayGraphics={true} />
        </View>
        <View style={{ ...Flex.override.bottom }}>
          {
            logoutTimedOut ?
              <FooterButton
                mode='text'
                color={Colors.brand.red3}
                onPress={doLogout}>
                {'Return to Login'}
              </FooterButton>
              : <></>
          }
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}
