import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import { mainAppNavigate } from 'lib/RootNavigation'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import React from 'react'

export const StatementRequest_99_Result = ({ navigation, route }) => {
  const { onDismiss }  = route?.params || {}

  const handleDismiss = () => {
    mainAppNavigate(MainAppNavScreen.STATEMENTS)
    onDismiss()
  }
  
  return (
    <ModalProcessResultScreen
      headline={`We're preparing your statement now`}
      subHeading={`When it is ready, it will be delivered to the Statements area`}      
      illustrationFilename={'clock.png'} 
      buttonTitle={'Finish'}
      buttonAction={handleDismiss}
      showButton={true}
    />
  )
}
