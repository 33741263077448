import { Headline, Subheading } from 'components/Typography'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import React, { ComponentProps, forwardRef } from 'react'
import { Dimensions, Image, View } from 'react-native'
import { Sizing } from 'styles'
import { ManagedCarousel } from './ManagedCarousel'
import { AppIllustration } from './AppIllustration'

export type ModalProcessResultCarouselProps = {
  onCompletion?: any
  data: ModalProcessResultCarouselData[]
}

export type ModalProcessResultCarouselData = {
  illustrationFilename?: string
  imageSource?: any
  headline: string
  subHeading?: string | React.ReactNode
  subHeadingInfo?: React.ReactNode
}

export const ModalProcessResultCarousel = forwardRef((props: ModalProcessResultCarouselProps, ref: any ) => {
  const { data, onCompletion } = props

  const sliderWidth = getScreenAppWidth() - (Sizing.x30 * 2)
  const carouselHeight = getScreenAppHeight() - Sizing.x300

  const renderItem = ({ item, index }) => {
    const { imageSource, illustrationFilename, headline, subHeading, subHeadingInfo } = item
    return (
      <View>
        <View style={{
          alignSelf: 'center',
          paddingVertical: Sizing.x10,
        }}>
          {
            imageSource ?
              <Image source={imageSource} style={{
                width: scaleNormalizer(200),
                height: scaleNormalizer(200),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            : illustrationFilename ?
              <AppIllustration filename={illustrationFilename} style={{
                width: scaleNormalizer(200),
                height: scaleNormalizer(200),
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            : <></>
          }
        </View>
        <View style={{
          alignSelf: 'center',
          paddingBottom: Sizing.x10,
          paddingHorizontal: Sizing.x30,
        }}>
          {headline ?
            <Headline numberOfLines={2} adjustsFontSizeToFit={true}>{headline}</Headline>
            : <></>
          }
        </View>
        <View style={{
          alignSelf: 'center',
          paddingHorizontal: Sizing.x30,
        }}>
          {
            subHeading ?
              <Subheading numberOfLines={3} adjustsFontSizeToFit={true}>{subHeading}</Subheading>
              : <></>
          }
          {
            subHeadingInfo ? subHeadingInfo : <></>
          }
        </View>
      </View>
    )
  }


  return (
    <ManagedCarousel
      ref={ref}
      data={data}
      onCompletion={onCompletion}
      renderItem={renderItem}
      width={sliderWidth}
      height={carouselHeight}
    />
  )
})