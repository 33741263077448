import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { Logger } from 'lib/logger'
import { getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'

export const ContributionsHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding, originalOnboardingFlags, existingRegularContribution, cancelRegularContributions, proposedOneOffContribution, proposedMonthlyContributions, proposedTargetRetirementAge } =  route?.params || {}

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const previouslyCompleted = !!originalOnboardingFlags?.contribute
  
  const carouselScreens: ProcessHeroCarouselData[] = []

  //Intro screen
  if (previouslyCompleted) {
    carouselScreens.push({
      illustrationFilename: 'thanks.png',
      headline: `You're all set!`,
      subHeading: `We've made those changes to your ${JAR_NAME_ALL}`,
    })
  }

  //Mention cancel if done
  if (cancelRegularContributions) {
    carouselScreens.push({
      illustrationFilename: 'info.png',
      headline: `Cancel Standing Order`,
      subHeading: `Remember, you must cancel the standing order manually with your bank to stop the contributions`,
    })
  }

  //Mention one-off if done
  if (proposedOneOffContribution !== undefined) {
    carouselScreens.push({
      illustrationFilename: 'clock.png',
      headline: `Pending contributions`,
      subHeading: `Your one-off contribution will appear as pending until the money reaches your ${JAR_NAME_PERSONAL} in a day or two`,
    })
    if (!previouslyCompleted) {
      carouselScreens.push({
        illustrationFilename: 'lump_sum.png',
        headline: `Keep Saving Spare Cash`,
        subHeading: `You can make further one-off contributions at any time to boost your retirement pot`,
      })
    }
  }

  //Mention regular if done
  if (proposedMonthlyContributions !== undefined) {
    if (existingRegularContribution?.amount) {
      carouselScreens.push({
        illustrationFilename: 'info.png',
        headline: `Cancel Existing Standing Order`,
        subHeading: `Remember, you must cancel the previous standing order manually with your bank to stop those contributions`,
      })
    } else if (!previouslyCompleted) {
      carouselScreens.push({
        illustrationFilename: 'regular_contributions.png',
        headline: `Adjusting Monthly Contributions`,
        subHeading: `You can adjust your monthly contributions at any time to fit your goals`,
      })
    }
  }

  //Mention target retirement age if done
  if (proposedTargetRetirementAge) {
    carouselScreens.push({
      illustrationFilename: 'gamified_approach.png',
      headline: `You have a new target!`,
      subHeading: `You've set yourself a new target to retire at ${proposedTargetRetirementAge}`,
    })
  }

  //Remind one-off if NOT done
  if (!previouslyCompleted && proposedOneOffContribution === undefined) {
    carouselScreens.push({
      illustrationFilename: 'lump_sum.png',
      headline: `Consider Investing Spare Cash`,
      subHeading: `Remember, you can always boost your retirement pot at any time with one-off contributions`,
    })
  }

  //Remind regular if doing a one-off, but NOT done regular and not already set up and not cancelling
  if (proposedOneOffContribution !== undefined && !existingRegularContribution?.amount && proposedMonthlyContributions === undefined && !cancelRegularContributions) {
    if (!existingRegularContribution?.amount) {
      carouselScreens.push({
        illustrationFilename: 'regular_contributions.png',
        headline: `Consider Monthly Contributions`,
        subHeading: `Saving money each month is the best way to build your retirement pot`,
      })
    } else {
      //Nothing to say as no change and already have regulars
    }
  }

  return (
    <ProcessHeroScreen
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
