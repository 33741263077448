import { MaterialCommunityIcons } from '@expo/vector-icons'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { envVariables } from 'environment'
import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useGetStatusQuery } from 'store/apiSlice'
import { Colors, Flex, Sizing } from 'styles'

export const ServerErrorScreen = () => {
  const { refetch } = useGetStatusQuery()
  const { apiUrl } = envVariables

  return (
    <BackgroundGradientPurple>
    <SafeAreaView style={{
      ...Flex.column.center,
      alignItems: 'center'
    }}>
      <View style={{
        alignItems: 'center',
        paddingHorizontal: Sizing.x20,
        width: '100%',
      }}>
        <MaterialCommunityIcons name={'cloud-off-outline'} size={Sizing.x100} color={Colors.brand.red3} />
        <View style={{ paddingVertical: Sizing.x20 }}>
          <Headline>{`Jarvis could not reach the server`}</Headline>
          <Subheading>{`Please try again later`}</Subheading>
        </View>
        <Button
          mode={'contained'}
          onPress={refetch}
        >
          Retry Now
        </Button>
      </View>
    </SafeAreaView>
  </BackgroundGradientPurple>
  )}

