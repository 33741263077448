import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Gender } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse, workingSpouse } from 'store/tempDataSlice'

export const SpouseAdd_06_Gender = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingSpouseData = useAppSelector(workingSpouse)

  //Setup form
  const formObj = useForm<{ gender: Gender }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      gender: workingSpouseData?.gender
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingSpouse(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And their gender?`}
      subHeading={`As specified on their passport or legal ID`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.GENDER} />}
    >    
      <ManagedSimpleChoiceInput
        name={'gender'}
        formObj={formObj}
        options={enumToAutocompleteOptions(Gender)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
