import { Platform, StyleSheet } from 'react-native'
import { Colors, Flex, Sizing, Typography } from 'styles'

export const backgroundStyles = StyleSheet.create({
    pageContainer: {
      ...Flex.column.start,
      ...Flex.override.fill,
    },
    backgroundContainer: {
      flex: 1,
      width: '100%',
      alignItems: 'center',
    },
    dashboardBackground: {
      ...Platform.select({
        web: {
          backgroundColor: Colors.brand.grey4,
        },
        default: {
          backgroundColor: Colors.brand.purple0,
        }
      })
    },
    heroBackground: {
      backgroundColor: Colors.brand.grey4,
    },
  })