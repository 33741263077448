import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { ContributionSource } from './dto/base.dto'
import { ClientBaseDto, ClientMeDto, ContributionConfiguration } from './dto/client.dto'
import { MessageDto } from './dto/message.dto'
import { AccountDto, Nomination, RegularContribution } from './dto/account.dto'
import { RetirementAssetDto } from './dto/retirement-asset.dto'
import { RetirementIncomeDto } from './dto/retirement-income.dto'
import { UserGroupSchemeDto } from './dto/group-scheme.dto'

export type MakeContributionsData = {
  account: AccountDto
  existingContributionConfiguration: ContributionConfiguration
  existingRegularContribution: RegularContribution
  makeContributionSteps: MakeContributionsStepIdentifier[]
  newContributionSource?: ContributionSource
  newTargetRetirementAge?: number
  newMonthlyContributions?: number
  newOneOffContribution?: number
}

export type AddBeneficiaryData = {
  allNominations: Nomination[]
}

export type EditBeneficiaryData = {
  editIndex: number
  currentNomination?: Nomination
  allNominations: Nomination[]
}

export type AdjustBeneficiaryProportionData = {
  nominations: Nomination[]
}

export type PrepareForTransferRetirementData = {
  asset: RetirementAssetDto
  client: ClientMeDto
}

export enum MakeContributionsStepIdentifier {
  CONTRIBUTION_SOURCE,
  CANCEL_CONTRIBUTIONS,
  LUMP_SUM_CONTRIBUION,
  REGULAR_CONTRIBUTION,
  TARGET_AGE,
}

export enum RetirementAssetTransferStepIdentifier {
  PROVIDER,
  REFERENCE,
  PERSONAL_DETAILS,
  TRACING,
}


export type SnackbarData = {
  message: string
  subMessage?: string
  iconName?: string
  duration?: number
  isError?: boolean
}

export interface UxState {
  //Employer Portal
  currentGroupScheme: UserGroupSchemeDto
  //Other
  shareModalVisible: boolean
  snackbarData: SnackbarData
  headerEnabled: boolean
  modalHeaderEnabled: boolean
  subHeaderTitle: string
  onAssetSpouseView: boolean
  onIncomeSpouseView: boolean
  addSpouseVisible: boolean
  editSpouseVisible: boolean
  editCurrentUserVisible: boolean
  editMeVisible: boolean
  editAvatarClient: ClientBaseDto
  editRetirementAgeVisible: boolean
  editRetirementBudgetVisible: boolean
  showMonthlyBudget: boolean
  addRetirementIncomeClient: ClientBaseDto
  editRetirementIncome: RetirementIncomeDto
  addStatePensionClient: ClientBaseDto
  editStatePensionClient: ClientBaseDto
  addRetirementAssetClient: ClientBaseDto
  editRetirementAsset: RetirementAssetDto
  prepareForTransferRetirementData: PrepareForTransferRetirementData
  showPlanAssetsToTransfer: boolean
  addBeneficiaryData: AddBeneficiaryData
  editBeneficiaryData: EditBeneficiaryData
  adjustBeneficiaryProportionData: AdjustBeneficiaryProportionData
  currentMessage: MessageDto,
  statementRequestVisible: boolean,
  //Timeline
  timelinePinned: boolean
  timelineTerm: number
  //Intercom
  intercomLauncherVisible: boolean
  intercomUnreadMessageCount: number
  makeContributionsData: MakeContributionsData
  //Admin
  showAdminAddUserVisible: boolean,
  currentAdminUserDataId: string,
  currentAdminClientDataId: string,
  currentAdminTransferDataId: string,
  showAdminAddAffiliateVisible: boolean,
  currentAdminAffiliateDataId: string,
  showAdminAddAssetVisible: boolean,
  currentAdminAssetDataId: string,
  showAdminAddAssetCategoryVisible: boolean,
  currentAdminAssetCategoryDataId: string,
  showAdminAddInvestmentPlanVisible: boolean,
  currentAdminInvestmentPlanDataId: string,
  showAdminAddGroupPortfolioVisible: boolean,
  currentAdminGroupPortfolioDataId: string,
  showAdminAddGroupOrganizationVisible: boolean,
  currentAdminGroupOrganizationDataId: string,
  currentAdminGroupOrganizationSetRolesDataId: string,
  showAdminAddGroupSchemeVisible: boolean,
  currentAdminGroupSchemeDataId: string,
  currentAdminGroupSchemeSetRolesDataId: string,
  showAdminAddBankConnectionVisible: boolean,
  currentAdminBankConnectionDataId: string,
  currentAdminFeatureDataId: string,
  showAdminAddPensionBrandVisible: boolean,
  currentAdminPensionBrandDataId: string,
  showAdminAddPensionProviderVisible: boolean,
  currentAdminPensionProviderDataId: string,
  currentAdminExceptionDataId: string,
  //Employer
  currentEmployerMemberDataId: string,
  currentEmployerPaymentDataId: string,
  currentEmployerRequestDataId: string,
  currentEmployerJobDataId: string,
  currentEmployerInviteDataId: string,
  showEmployerAddInviteVisible: boolean,
  showEmployerImportInvitesVisible: boolean,
  showEmployerAddEnrolmentJobVisible: boolean,
  showEmployerUpdateEnrolmentJobDataId: string,
  showEmployerChangeEnrolmentStatusJobDataId: string,
  showEmployerAddContributionJobVisible: boolean,
}

export const uxInitialState: UxState = {
  currentGroupScheme: undefined,
  shareModalVisible: false,
  snackbarData: undefined,
  headerEnabled: true,
  modalHeaderEnabled: true,
  subHeaderTitle: null,
  onAssetSpouseView: false,
  onIncomeSpouseView: false,
  addSpouseVisible: false,
  editSpouseVisible: false,
  editCurrentUserVisible: false,
  editMeVisible: false,
  editAvatarClient: undefined,
  editRetirementAgeVisible: false,
  editRetirementBudgetVisible: false,
  showMonthlyBudget: true,
  addRetirementIncomeClient: undefined,
  editRetirementIncome: undefined,
  addStatePensionClient: undefined,
  editStatePensionClient: undefined,
  addRetirementAssetClient: undefined,
  editRetirementAsset: undefined,
  prepareForTransferRetirementData: undefined,
  showPlanAssetsToTransfer: false,
  addBeneficiaryData: undefined,
  editBeneficiaryData: undefined,
  adjustBeneficiaryProportionData: undefined,
  currentMessage: undefined,
  statementRequestVisible: false,
  timelinePinned: false,
  timelineTerm: 0,
  intercomLauncherVisible: false,
  intercomUnreadMessageCount: 0,
  makeContributionsData: undefined,
  //Admin
  showAdminAddUserVisible: false,
  currentAdminUserDataId: undefined,
  currentAdminClientDataId: undefined,
  currentAdminTransferDataId: undefined,
  showAdminAddAffiliateVisible: undefined,
  currentAdminAffiliateDataId: undefined,
  showAdminAddAssetVisible: undefined,
  currentAdminAssetDataId: undefined,
  showAdminAddAssetCategoryVisible: undefined,
  currentAdminAssetCategoryDataId: undefined,
  showAdminAddInvestmentPlanVisible: undefined,
  currentAdminInvestmentPlanDataId: undefined,
  showAdminAddGroupPortfolioVisible: undefined,
  currentAdminGroupPortfolioDataId: undefined,
  showAdminAddGroupOrganizationVisible: undefined,
  currentAdminGroupOrganizationDataId: undefined,
  currentAdminGroupOrganizationSetRolesDataId: undefined,
  showAdminAddGroupSchemeVisible: undefined,
  currentAdminGroupSchemeDataId: undefined,
  currentAdminGroupSchemeSetRolesDataId: undefined,
  showAdminAddBankConnectionVisible: undefined,
  currentAdminBankConnectionDataId: undefined,
  currentAdminFeatureDataId: undefined,
  showAdminAddPensionBrandVisible: undefined,
  currentAdminPensionBrandDataId: undefined,
  showAdminAddPensionProviderVisible: undefined,
  currentAdminPensionProviderDataId: undefined,
  currentAdminExceptionDataId: undefined,
  //Employer
  currentEmployerMemberDataId: undefined,
  currentEmployerPaymentDataId: undefined,
  currentEmployerRequestDataId: undefined,
  currentEmployerJobDataId: undefined,
  currentEmployerInviteDataId: undefined,
  showEmployerAddInviteVisible: undefined,
  showEmployerImportInvitesVisible: undefined,
  showEmployerAddEnrolmentJobVisible: undefined,
  showEmployerUpdateEnrolmentJobDataId: undefined,
  showEmployerChangeEnrolmentStatusJobDataId: undefined,
  showEmployerAddContributionJobVisible: undefined,
}

export const uxSlice = createSlice({
  name: 'ux',
  initialState: uxInitialState,
  reducers: {
    setCurrentGroupScheme: (state, action: PayloadAction<UserGroupSchemeDto>) => {
      state.currentGroupScheme = action.payload
    },
    setShareModalVisible: (state, action: PayloadAction<boolean>) => {
      state.shareModalVisible = action.payload
    },
    setSnackbarData: (state, action: PayloadAction<SnackbarData>) => {
      state.snackbarData = action.payload
    },
    enableHeader: (state, action: PayloadAction<boolean>) => {
      state.headerEnabled = action.payload
    },
    enableModalHeader: (state, action: PayloadAction<boolean>) => {
      state.modalHeaderEnabled = action.payload
    },
    setSubHeaderTitle: (state, action: PayloadAction<string>) => {
      state.subHeaderTitle = action.payload || null
    },
    setOnAssetSpouseView: (state, action: PayloadAction<boolean>) => {
      state.onAssetSpouseView = action.payload
    },
    setOnIncomeSpouseView: (state, action: PayloadAction<boolean>) => {
      state.onIncomeSpouseView = action.payload
    },
    setAddSpouseVisible: (state, action: PayloadAction<boolean>) => {
      state.addSpouseVisible = action.payload
    },
    setEditCurrentUserVisible: (state, action: PayloadAction<boolean>) => {
      state.editCurrentUserVisible = action.payload
    },
    setEditMeVisible: (state, action: PayloadAction<boolean>) => {
      state.editMeVisible = action.payload
    },
    setEditAvatarClient: (state, action: PayloadAction<ClientBaseDto>) => {
      state.editAvatarClient = action.payload
    },
    setEditSpouseVisible: (state, action: PayloadAction<boolean>) => {
      state.editSpouseVisible = action.payload
    },
    setEditRetirementAgeVisible: (state, action: PayloadAction<boolean>) => {
      state.editRetirementAgeVisible = action.payload
    },
    setEditRetirementBudgetVisible: (state, action: PayloadAction<boolean>) => {
      state.editRetirementBudgetVisible = action.payload
    },
    setShowMonthlyBudget: (state, action: PayloadAction<boolean>) => {
      state.showMonthlyBudget = action.payload
    },
    setAddRetirementIncomeClient: (state, action: PayloadAction<ClientBaseDto>) => {
      state.addRetirementIncomeClient = action.payload
    },
    setEditRetirementIncome: (state, action: PayloadAction<RetirementIncomeDto>) => {
      state.editRetirementIncome = action.payload
    },
    setAddStatePensionClient: (state, action: PayloadAction<ClientBaseDto>) => {
      state.addStatePensionClient = action.payload
    },
    setEditStatePensionClient: (state, action: PayloadAction<ClientBaseDto>) => {
      state.editStatePensionClient = action.payload
    },
    setAddRetirementAssetClient: (state, action: PayloadAction<ClientBaseDto>) => {
      state.addRetirementAssetClient = action.payload
    },
    setEditRetirementAsset: (state, action: PayloadAction<RetirementAssetDto>) => {
      state.editRetirementAsset = action.payload
    },
    setPrepareForTransferRetirementData: (state, action: PayloadAction<PrepareForTransferRetirementData>) => {
      state.prepareForTransferRetirementData = action.payload
    },
    setShowPlanAssetsToTransfer: (state, action: PayloadAction<boolean>) => {
      state.showPlanAssetsToTransfer = action.payload
    },
    setAddBeneficiaryData: (state, action: PayloadAction<AddBeneficiaryData>) => {
      state.addBeneficiaryData = action.payload
    },
    setEditBeneficiaryData: (state, action: PayloadAction<EditBeneficiaryData>) => {
      state.editBeneficiaryData = action.payload
    },
    setAdjustBeneficiaryProportionData: (
      state,
      action: PayloadAction<AdjustBeneficiaryProportionData>
    ) => {
      state.adjustBeneficiaryProportionData = action.payload
    },
    setCurrentMessage: (state, action: PayloadAction<MessageDto>) => {
      state.currentMessage = action.payload
    },
    setStatementRequestVisible: (state, action: PayloadAction<boolean>) => {
      state.statementRequestVisible = action.payload
    },
    setTimelinePinned: (state, action: PayloadAction<boolean>) => {
      state.timelinePinned = action.payload
    },
    setTimelineTerm: (state, action: PayloadAction<number>) => {
      state.timelineTerm = action.payload
    },
    setIntercomLauncherVisible: (state, action: PayloadAction<boolean>) => {
      state.intercomLauncherVisible = action.payload
    },
    setIntercomUnreadMessageCount: (state, action: PayloadAction<number>) => {
      state.intercomUnreadMessageCount = action.payload
    },
    setMakeContributionsData: (state, action: PayloadAction<MakeContributionsData>) => {
      state.makeContributionsData = action.payload
    },
    //Admin
    setShowAdminAddUserVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddUserVisible = action.payload
    },
    setCurrentAdminUserDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminUserDataId = action.payload
    },
    setCurrentAdminClientDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminClientDataId = action.payload
    },
    setCurrentAdminTransferDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminTransferDataId = action.payload
    },
    setShowAdminAddAffiliateVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddAffiliateVisible = action.payload
    },
    setCurrentAdminAffiliateDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminAffiliateDataId = action.payload
    },
    setShowAdminAddAssetVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddAssetVisible = action.payload
    },
    setCurrentAdminAssetDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminAssetDataId = action.payload
    },
    setShowAdminAddAssetCategoryVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddAssetCategoryVisible = action.payload
    },
    setCurrentAdminAssetCategoryDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminAssetCategoryDataId = action.payload
    },
    setShowAdminAddInvestmentPlanVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddInvestmentPlanVisible = action.payload
    },
    setCurrentAdminInvestmentPlanDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminInvestmentPlanDataId = action.payload
    },
    setShowAdminAddGroupPortfolioVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddGroupPortfolioVisible = action.payload
    },
    setCurrentAdminGroupPortfolioDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminGroupPortfolioDataId = action.payload
    },
    setShowAdminAddGroupOrganizationVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddGroupOrganizationVisible = action.payload
    },
    setCurrentAdminGroupOrganizationDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminGroupOrganizationDataId = action.payload
    },
    setCurrentAdminGroupOrganizationSetRolesDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminGroupOrganizationSetRolesDataId = action.payload
    },
    setShowAdminAddGroupSchemeVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddGroupSchemeVisible = action.payload
    },
    setCurrentAdminGroupSchemeDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminGroupSchemeDataId = action.payload
    },
    setCurrentAdminGroupSchemeSetRolesDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminGroupSchemeSetRolesDataId = action.payload
    },
    setShowAdminAddBankConnectionVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddBankConnectionVisible = action.payload
    },
    setCurrentAdminBankConnectionDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminBankConnectionDataId = action.payload
    },
    setCurrentAdminFeatureDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminFeatureDataId = action.payload
    },
    setShowAdminAddPensionBrandVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddPensionBrandVisible = action.payload
    },
    setCurrentAdminPensionBrandDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminPensionBrandDataId = action.payload
    },
    setShowAdminAddPensionProviderVisible: (state, action: PayloadAction<boolean>) => {
      state.showAdminAddPensionProviderVisible = action.payload
    },
    setCurrentAdminPensionProviderDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminPensionProviderDataId = action.payload
    },
    setCurrentAdminExceptionDataId: (state, action: PayloadAction<string>) => {
      state.currentAdminExceptionDataId = action.payload
    },
    //Employer
    setCurrentEmployerMemberDataId: (state, action: PayloadAction<string>) => {
      state.currentEmployerMemberDataId = action.payload
    },
    setCurrentEmployerPaymentDataId: (state, action: PayloadAction<string>) => {
      state.currentEmployerPaymentDataId = action.payload
    },
    setCurrentEmployerRequestDataId: (state, action: PayloadAction<string>) => {
      state.currentEmployerRequestDataId = action.payload
    },
    setCurrentEmployerJobDataId: (state, action: PayloadAction<string>) => {
      state.currentEmployerJobDataId = action.payload
    },
    setCurrentEmployerInviteDataId: (state, action: PayloadAction<string>) => {
      state.currentEmployerInviteDataId = action.payload
    },
    setShowEmployerAddInviteVisible: (state, action: PayloadAction<boolean>) => {
      state.showEmployerAddInviteVisible = action.payload
    },
    setShowEmployerImportInvitesVisible: (state, action: PayloadAction<boolean>) => {
      state.showEmployerImportInvitesVisible = action.payload
    },
    setShowEmployerAddEnrolmentJobVisible: (state, action: PayloadAction<boolean>) => {
      state.showEmployerAddEnrolmentJobVisible = action.payload
    },
    setShowEmployerUpdateEnrolmentJobDataId: (state, action: PayloadAction<string>) => {
      state.showEmployerUpdateEnrolmentJobDataId = action.payload
    },
    setShowEmployerChangeEnrolmentStatusJobDataId: (state, action: PayloadAction<string>) => {
      state.showEmployerChangeEnrolmentStatusJobDataId = action.payload
    },
    setShowEmployerAddContributionJobVisible: (state, action: PayloadAction<boolean>) => {
      state.showEmployerAddContributionJobVisible = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  setCurrentGroupScheme,
  setShareModalVisible,
  setSnackbarData,
  enableHeader,
  enableModalHeader,
  setSubHeaderTitle,
  setOnAssetSpouseView,
  setOnIncomeSpouseView,
  setAddSpouseVisible,
  setEditCurrentUserVisible,
  setEditMeVisible,
  setEditAvatarClient,
  setEditSpouseVisible,
  setEditRetirementAgeVisible,
  setEditRetirementBudgetVisible,
  setShowMonthlyBudget,
  setAddRetirementIncomeClient,
  setEditRetirementIncome,
  setAddStatePensionClient,
  setEditStatePensionClient,
  setAddRetirementAssetClient,
  setEditRetirementAsset,
  setPrepareForTransferRetirementData,
  setShowPlanAssetsToTransfer,
  setAddBeneficiaryData,
  setAdjustBeneficiaryProportionData,
  setEditBeneficiaryData,
  setCurrentMessage,
  setStatementRequestVisible,
  setTimelinePinned,
  setTimelineTerm,
  setIntercomLauncherVisible,
  setIntercomUnreadMessageCount,
  setMakeContributionsData,
  //Admin
  setShowAdminAddUserVisible,
  setCurrentAdminUserDataId,
  setCurrentAdminClientDataId,
  setCurrentAdminTransferDataId,
  setShowAdminAddAffiliateVisible,
  setCurrentAdminAffiliateDataId,
  setShowAdminAddAssetVisible,
  setCurrentAdminAssetDataId,
  setShowAdminAddAssetCategoryVisible,
  setCurrentAdminAssetCategoryDataId,
  setShowAdminAddInvestmentPlanVisible,
  setCurrentAdminInvestmentPlanDataId,
  setShowAdminAddGroupPortfolioVisible,
  setCurrentAdminGroupPortfolioDataId,
  setShowAdminAddGroupOrganizationVisible,
  setCurrentAdminGroupOrganizationDataId,
  setCurrentAdminGroupOrganizationSetRolesDataId,
  setShowAdminAddGroupSchemeVisible,
  setCurrentAdminGroupSchemeDataId,
  setCurrentAdminGroupSchemeSetRolesDataId,
  setShowAdminAddBankConnectionVisible,
  setCurrentAdminBankConnectionDataId,
  setCurrentAdminFeatureDataId,
  setShowAdminAddPensionBrandVisible,
  setCurrentAdminPensionBrandDataId,
  setShowAdminAddPensionProviderVisible,
  setCurrentAdminPensionProviderDataId,
  setCurrentAdminExceptionDataId,
  //Employer
  setCurrentEmployerMemberDataId,
  setCurrentEmployerPaymentDataId,
  setCurrentEmployerRequestDataId,
  setCurrentEmployerJobDataId,
  setCurrentEmployerInviteDataId,
  setShowEmployerAddInviteVisible,
  setShowEmployerImportInvitesVisible,
  setShowEmployerAddEnrolmentJobVisible,
  setShowEmployerUpdateEnrolmentJobDataId,
  setShowEmployerChangeEnrolmentStatusJobDataId,
  setShowEmployerAddContributionJobVisible,
} = uxSlice.actions

// Export state
export const currentGroupScheme = (state: RootState) => state.ux.currentGroupScheme
export const shareModalVisible = (state: RootState) => state.ux.shareModalVisible
export const snackbarData = (state: RootState) => state.ux.snackbarData
export const headerEnabled = (state: RootState) => state.ux.headerEnabled
export const modalHeaderEnabled = (state: RootState) => state.ux.modalHeaderEnabled
export const subHeaderTitle = (state: RootState) => state.ux.subHeaderTitle
export const onAssetSpouseView = (state: RootState) => state.ux.onAssetSpouseView
export const onIncomeSpouseView = (state: RootState) => state.ux.onIncomeSpouseView
export const addSpouseVisible = (state: RootState) => state.ux.addSpouseVisible
export const editCurrentUserVisible = (state: RootState) => state.ux.editCurrentUserVisible
export const editMeVisible = (state: RootState) => state.ux.editMeVisible
export const editAvatarClient = (state: RootState) => state.ux.editAvatarClient
export const editSpouseVisible = (state: RootState) => state.ux.editSpouseVisible
export const editRetirementAgeVisible = (state: RootState) => state.ux.editRetirementAgeVisible
export const editRetirementBudgetVisible = (state: RootState) => state.ux.editRetirementBudgetVisible
export const showMonthlyBudget = (state: RootState) => state.ux.showMonthlyBudget
export const addRetirementIncomeClient = (state: RootState) => state.ux.addRetirementIncomeClient
export const editRetirementIncome = (state: RootState) => state.ux.editRetirementIncome
export const addStatePensionClient = (state: RootState) => state.ux.addStatePensionClient
export const editStatePensionClient = (state: RootState) => state.ux.editStatePensionClient
export const addRetirementAssetClient = (state: RootState) => state.ux.addRetirementAssetClient
export const editRetirementAsset = (state: RootState) => state.ux.editRetirementAsset
export const prepareForTransferRetirementData = (state: RootState) => state.ux.prepareForTransferRetirementData
export const showPlanAssetsToTransfer = (state: RootState) => state.ux.showPlanAssetsToTransfer
export const addBeneficiaryData = (state: RootState) => state.ux.addBeneficiaryData
export const editBeneficiaryData = (state: RootState) => state.ux.editBeneficiaryData
export const adjustBeneficiaryProportionData = (state: RootState) => state.ux.adjustBeneficiaryProportionData
export const currentMessageData = (state: RootState) => state.ux.currentMessage
export const statementRequestVisible = (state: RootState) => state.ux.statementRequestVisible
export const timelinePinned = (state: RootState) => state.ux.timelinePinned
export const timelineTerm = (state: RootState) => state.ux.timelineTerm
export const intercomLauncherVisible = (state: RootState) => state.ux.intercomLauncherVisible
export const intercomUnreadMessageCount = (state: RootState) => state.ux.intercomUnreadMessageCount
export const makeContributionsData = (state: RootState) => state.ux.makeContributionsData
//Admin
export const showAdminAddUserVisible = (state: RootState) => state.ux.showAdminAddUserVisible
export const currentAdminUserDataId = (state: RootState) => state.ux.currentAdminUserDataId
export const currentAdminClientDataId = (state: RootState) => state.ux.currentAdminClientDataId
export const currentAdminTransferDataId = (state: RootState) => state.ux.currentAdminTransferDataId
export const showAdminAddAffiliateVisible = (state: RootState) => state.ux.showAdminAddAffiliateVisible
export const currentAdminAffiliateDataId = (state: RootState) => state.ux.currentAdminAffiliateDataId
export const showAdminAddAssetVisible = (state: RootState) => state.ux.showAdminAddAssetVisible
export const currentAdminAssetDataId = (state: RootState) => state.ux.currentAdminAssetDataId
export const showAdminAddAssetCategoryVisible = (state: RootState) => state.ux.showAdminAddAssetCategoryVisible
export const currentAdminAssetCategoryDataId = (state: RootState) => state.ux.currentAdminAssetCategoryDataId
export const showAdminAddInvestmentPlanVisible = (state: RootState) => state.ux.showAdminAddInvestmentPlanVisible
export const currentAdminInvestmentPlanDataId = (state: RootState) => state.ux.currentAdminInvestmentPlanDataId
export const showAdminAddGroupPortfolioVisible = (state: RootState) => state.ux.showAdminAddGroupPortfolioVisible
export const currentAdminGroupPortfolioDataId = (state: RootState) => state.ux.currentAdminGroupPortfolioDataId
export const showAdminAddGroupOrganizationVisible = (state: RootState) => state.ux.showAdminAddGroupOrganizationVisible
export const currentAdminGroupOrganizationDataId = (state: RootState) => state.ux.currentAdminGroupOrganizationDataId
export const currentAdminGroupOrganizationSetRolesDataId = (state: RootState) => state.ux.currentAdminGroupOrganizationSetRolesDataId
export const showAdminAddGroupSchemeVisible = (state: RootState) => state.ux.showAdminAddGroupSchemeVisible
export const currentAdminGroupSchemeDataId = (state: RootState) => state.ux.currentAdminGroupSchemeDataId
export const currentAdminGroupSchemeSetRolesDataId = (state: RootState) => state.ux.currentAdminGroupSchemeSetRolesDataId
export const showAdminAddBankConnectionVisible = (state: RootState) => state.ux.showAdminAddBankConnectionVisible
export const currentAdminBankConnectionDataId = (state: RootState) => state.ux.currentAdminBankConnectionDataId
export const currentAdminFeatureDataId = (state: RootState) => state.ux.currentAdminFeatureDataId
export const showAdminAddPensionBrandVisible = (state: RootState) => state.ux.showAdminAddPensionBrandVisible
export const currentAdminPensionBrandDataId = (state: RootState) => state.ux.currentAdminPensionBrandDataId
export const showAdminAddPensionProviderVisible = (state: RootState) => state.ux.showAdminAddPensionProviderVisible
export const currentAdminPensionProviderDataId = (state: RootState) => state.ux.currentAdminPensionProviderDataId
export const currentAdminExceptionDataId = (state: RootState) => state.ux.currentAdminExceptionDataId
//Employer
export const currentEmployerMemberDataId = (state: RootState) => state.ux.currentEmployerMemberDataId
export const currentEmployerPaymentDataId = (state: RootState) => state.ux.currentEmployerPaymentDataId
export const currentEmployerRequestDataId = (state: RootState) => state.ux.currentEmployerRequestDataId
export const currentEmployerJobDataId = (state: RootState) => state.ux.currentEmployerJobDataId
export const currentEmployerInviteDataId = (state: RootState) => state.ux.currentEmployerInviteDataId
export const showEmployerAddInviteVisible = (state: RootState) => state.ux.showEmployerAddInviteVisible
export const showEmployerImportInvitesVisible = (state: RootState) => state.ux.showEmployerImportInvitesVisible
export const showEmployerAddEnrolmentJobVisible = (state: RootState) => state.ux.showEmployerAddEnrolmentJobVisible
export const showEmployerUpdateEnrolmentJobDataId = (state: RootState) => state.ux.showEmployerUpdateEnrolmentJobDataId
export const showEmployerChangeEnrolmentStatusJobDataId = (state: RootState) => state.ux.showEmployerChangeEnrolmentStatusJobDataId
export const showEmployerAddContributionJobVisible = (state: RootState) => state.ux.showEmployerAddContributionJobVisible

export default uxSlice.reducer
