import {
  CommonActions,
  DrawerActions,
  DrawerNavigationState,
  ParamListBase,
  useLinkBuilder,
} from '@react-navigation/native'
import * as React from 'react'
  
  import type { DrawerDescriptorMap, DrawerNavigationHelpers } from '@react-navigation/drawer/src/types'
import { AdminAppNavScreen, EmployerAppNavScreen, MainAppNavScreen } from 'lib/navigationHelpers'
import { groupBy, isArray, keys } from 'lodash'
import { View } from 'react-native'
import { List } from 'react-native-paper'
import { Typography } from 'styles'
import CustomDrawerItem from './CustomDrawerItem'
  
  type Props = {
    state: DrawerNavigationState<ParamListBase>
    navigation: DrawerNavigationHelpers
    descriptors: DrawerDescriptorMap
    routeNotificationMap?: any
    screenGroupMap?: any
  }

  export interface DrawerScreen {
    name: MainAppNavScreen | AdminAppNavScreen | EmployerAppNavScreen
    component: any
    title: string
    icon: string,
    hide?: boolean,
  }

  export interface DrawerScreenGroup {
    id: string
    title: string
    defaultExpanded?: boolean
    renderFlat?: boolean
  }

  /**
   * Component that renders the navigation list in the drawer.
   */
  export default function CustomDrawerItemList({
    state,
    navigation,
    descriptors,
    routeNotificationMap,
    screenGroupMap,
  }: Props) {
  const buildLink = useLinkBuilder()

  const focusedRoute = state.routes[state.index]
  const focusedDescriptor = descriptors[focusedRoute.key]
  const focusedOptions = focusedDescriptor.options

  const {
    drawerActiveTintColor,
    drawerInactiveTintColor,
    drawerActiveBackgroundColor,
    drawerInactiveBackgroundColor,
  } = focusedOptions

  const routesWithGroups = state.routes.map(route => {
    return {
      ...route,
      group: screenGroupMap ? screenGroupMap[route?.name] : undefined
    }
  })

  const groupedRoutes = groupBy(routesWithGroups, function(route) {
      return route.group?.id || null
  })

  const deriveInitialExpandedGroupId = () => {
    if (!screenGroupMap) {
      return undefined
    }
    let groupId: string = undefined

    //Try to find active route
    const allRoutes = state.routes || []
    if (state.index !== undefined) {
      const activeRoute: any = allRoutes[state.index]
      if (activeRoute) {
        const { name } = activeRoute
        const group = screenGroupMap[name]
        const { id, renderFlat } = group || {}
        if (!renderFlat) {
          groupId = id  
        }
      }
    }

    //If still undefined, try to find default
    if (groupId === undefined) {
      keys(screenGroupMap).forEach(key => {
        const groupConfig = screenGroupMap[key]
        if (groupConfig.defaultExpanded) {
          groupId = groupConfig.id
        }
      })
    }

    return groupId
  }

  const [expandedGroupId, setExpandedGroupId] = React.useState(deriveInitialExpandedGroupId())
  const [currentRouteGroupId, setCurrentRouteGroupId] = React.useState(expandedGroupId)

  //Set the currentRouteGroup when state changes 
  React.useEffect(() => {
    if (state) {
      const currentlyFocusedRoute = state.routes[state.index]
      const { name } = currentlyFocusedRoute
      const currentlyFocusedRouteGroup = screenGroupMap ? screenGroupMap[name] : undefined
      if (currentlyFocusedRouteGroup) {
        setCurrentRouteGroupId(currentlyFocusedRouteGroup?.id)
      }
    }
  }, [state])
  
  function DrawerGroup(props) {
    const { routes, routeNotificationMap } = props || {}
    const routesToRender = isArray(routes) ? routes : []
    return routesToRender.map((route, i) => {
      const allRoutes = state?.routes || []
      const focused = allRoutes.findIndex((e) => e.name === route.name) === state.index
      const notificationCount = routeNotificationMap ? routeNotificationMap[route.name] : undefined

      const onPress = () => {
        const event = navigation.emit({
          type: 'drawerItemPress',
          target: route.key,
          canPreventDefault: true,
        })
  
        if (!event.defaultPrevented) {
          navigation.dispatch({
            ...(focused
              ? DrawerActions.closeDrawer()
              : CommonActions.navigate({ name: route.name, merge: true })),
            target: state.key,
          })
        }
      }
  
      const {
        title,
        drawerLabel,
        drawerIcon,
        drawerLabelStyle,
        drawerItemStyle,
        drawerAllowFontScaling,
      } = descriptors[route.key].options
  
      return (
        <CustomDrawerItem
          key={route.key}
          label={
            drawerLabel !== undefined
              ? drawerLabel
              : title !== undefined
              ? title
              : route.name
          }
          icon={drawerIcon}
          focused={focused}
          activeTintColor={drawerActiveTintColor}
          inactiveTintColor={drawerInactiveTintColor}
          activeBackgroundColor={drawerActiveBackgroundColor}
          inactiveBackgroundColor={drawerInactiveBackgroundColor}
          allowFontScaling={drawerAllowFontScaling}
          labelStyle={drawerLabelStyle}
          style={drawerItemStyle}
          to={buildLink(route.name, route.params)}
          onPress={onPress}
          notificationCount={notificationCount}
        />
      )
    }) as React.ReactNode as React.ReactElement
  }

  function DrawerGroupList() {
    return keys(groupedRoutes).sort().map(key => {
      const groupRoutes = groupedRoutes[key]
      const firstRoute = groupRoutes[0]
      const groupConfig: DrawerScreenGroup = firstRoute?.group
      const { id, renderFlat } = groupConfig || {}

      return (
        <View key={key}>
          {
            renderFlat || !id
              ? <DrawerGroup routes={groupRoutes} routeNotificationMap={routeNotificationMap} />
              : <>
                  <List.Accordion
                    id={groupConfig?.id}
                    title={groupConfig?.title}
                    titleStyle={Typography.defined.mainAppDrawerGroupTitle}
                  >
                    <DrawerGroup routes={groupRoutes} routeNotificationMap={routeNotificationMap} />
                  </List.Accordion>
                </>
          }
        </View>
      )
    })
  }

  return (
    <>
      <List.AccordionGroup
        expandedId={expandedGroupId}
        onAccordionPress={(expandedId) => {
          if (expandedGroupId === expandedId) {
            //If NOT the group with the currently-active route, close it
            if (expandedGroupId !== currentRouteGroupId) {
              setExpandedGroupId(undefined)
            } else {

            }
          } else {
            setExpandedGroupId(expandedId.toString())
          }
            
        }}
      >
        <DrawerGroupList />
      </List.AccordionGroup>
    </>
  )
}
