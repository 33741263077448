import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ActionCardModal } from 'features/Dashboard/Components/ActionCardModal'
import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { Card, Portal } from 'react-native-paper'
import { Colors, Flex, Paper, Sizing, Typography } from "styles"
import { ConfirmationDialog } from './ConfirmationDialog'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { NotificationDot } from './NotificationDot'
import { AppIllustration } from './AppIllustration'

export type ActionCardProps = {
  illustrationFilename?: string
  imageSource?: any
  title: string
  description: string
  size: 'small' | 'large'
  onPress: any
  onCancel?: any
  buttonLabel: string
  confirmationModalContentFunction?: Function
  confirmationModalProceedLabel?: string
  confirmationModalCancelLabel?: string
  noModalProceedButton?: boolean
  selected?: boolean
  useCheckbox?: boolean
  notificationCount?: number
  dismissalAction?: any
  dismissalConfirmationMessage?: string
  unavailable?: boolean
}
export const ActionCard = (props: ActionCardProps) => {
  const { illustrationFilename, imageSource, title, description, size, onPress, onCancel, buttonLabel, confirmationModalContentFunction, confirmationModalProceedLabel, confirmationModalCancelLabel, noModalProceedButton, selected, useCheckbox, notificationCount, dismissalAction, dismissalConfirmationMessage, unavailable } = props

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const modalHandleProceed = () => {
    hideModal()
    if (onPress) {
      onPress()
    }
  }

  const modalHandleCancel = () => {
    hideModal()
    if (onCancel) {
      onCancel()
    }
  }
  
  const { colors: themeColors } = Paper.useAppTheme()

  const selectedIconName = selected ? useCheckbox ? 'check-box' : 'radio-button-checked' : useCheckbox ? 'check-box-outline-blank' : 'radio-button-unchecked'
  const selectedIconColor = selected ? themeColors.onSurfaceAccent : themeColors.disabled

  return (
    <>
      {size === 'large' ?
        <Card style={[
          localStyles.card,
          {
            opacity: unavailable ? 0.7 : 1,
          }
        ]}>
          { selected !== undefined ?
            <View style={localStyles.indicatorContainer}>
              <MaterialIcons
                name={selectedIconName}
                size={Sizing.x25}
                color={selectedIconColor}
              />
            </View>
            : notificationCount ?
              <NotificationDot
                top={scaleNormalizer(-15)}
                right={scaleNormalizer(-20)}
                count={notificationCount == 1 ? 0 : notificationCount}
              />
             : <></>
          }
          {
            imageSource
              ? <Card.Cover style={{
                  backgroundColor: "transparent",
                  width: Sizing.x140,
                  height: Sizing.x140,
                  alignSelf: 'center',
                  
                }}
                  resizeMode={'contain'}
                  source={imageSource}
                />
            : <AppIllustration
                style={{
                  width: Sizing.x140,
                  height: Sizing.x140,
                  alignSelf: 'center',
                }}
                resizeMode={'contain'}
                filename={illustrationFilename || 'info.png'}
              />
          }
          <Card.Content style={{
            paddingTop: Sizing.x10,
            height: Sizing.x140,
          }}>
            <Subheading style={[
              localStyles.titleStyle,
              {
                color: themeColors.onSurfaceTitle,
              }
              ]} numberOfLines={2} adjustsFontSizeToFit>{title}</Subheading>
            <Paragraph style={[
              localStyles.descriptionStyle,
              {
                color: themeColors.onSurfaceText,
              }
            ]
            } numberOfLines={4} adjustsFontSizeToFit>{description}</Paragraph>
          </Card.Content>
          <Card.Actions style={{
            ...Flex.row.center,
            height: Sizing.x80,
          }}>
            <View style={{
              width: '100%'
            }}>
              <Button
                backgroundColor={themeColors.onSurfaceButtonBackgroundColor}
                color={themeColors.onSurfaceButtonColor}
                mode='contained'
                onPress={confirmationModalContentFunction ? showModal : onPress}
              >{buttonLabel}
              </Button>
            </View>
          </Card.Actions>
        </Card>
        :
        <Card style={[
          localStyles.smallCard,
          {
            backgroundColor: themeColors.surface,
            opacity: unavailable ? 0.7 : 1,
          }
        ]} onPress={confirmationModalContentFunction ? showModal : onPress}>
          { selected !== undefined ?
            <View style={localStyles.indicatorContainer}>
              <MaterialIcons
                name={selectedIconName}
                size={Sizing.x25}
                color={selectedIconColor}
              />
            </View>
            : notificationCount ?
              <NotificationDot
                top={scaleNormalizer(-15)}
                right={scaleNormalizer(-15)}
                count={notificationCount == 1 ? 0 : notificationCount}
              />
            : <></>
          }
          <View style={{
            ...Flex.row.start,
          }}>
            <View style={{
              padding: Sizing.x10,
            }}>
              {
                imageSource
                  ? <Image
                      style={{
                        width: Sizing.x70,
                        height: Sizing.x70,
                        alignSelf: 'center',
                      }}
                      resizeMode={'contain'}
                      source={imageSource}
                    />
                  : <AppIllustration
                      style={{
                        width: Sizing.x70,
                        height: Sizing.x70,
                        alignSelf: 'center',
                      }}
                      resizeMode={'contain'}
                      filename={illustrationFilename || 'info.png'}
                    />
              }
            </View>
            <View style={{
                  ...Flex.override.fill,
                  ...Flex.column.start,
                  paddingHorizontal: Sizing.x10,
                  paddingVertical: Sizing.x10,
                }}>
              <View style={selected !== undefined ? {
                  paddingRight: Sizing.x25, //Add some room for the 
                } : {}}>
                <Subheading style={[
                  localStyles.titleStyle,
                  localStyles.smallTitleStyle,
                  {
                    color: themeColors.onSurfaceTitle,
                  },
                ]} numberOfLines={2}>{title}</Subheading>
              </View>
              <Text style={[
                localStyles.descriptionStyle,
                localStyles.smallDescriptionStyle,
                {
                  color: themeColors.onSurfaceText,
                }
              ]} numberOfLines={3}>{description}</Text>
            </View>
          </View>
        </Card>
      }
      {
        confirmationModalContentFunction && modalVisible ?
          <Portal>
            <ActionCardModal
              content={confirmationModalContentFunction}
              visible={modalVisible}
              onDismiss={hideModal}
              onProceed={modalHandleProceed}
              proceedLabel={confirmationModalProceedLabel}
              onCancel={modalHandleCancel}
              cancelLabel={confirmationModalCancelLabel}
              noModalProceedButton={noModalProceedButton}
              cancelConfirmationMessage={dismissalConfirmationMessage}
            />
            {/* Removing this as a test - don't think it is required? */}
            {/* { confirmationModalContentFunction } */}
          </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  card: {
    borderRadius: Sizing.x15,
    marginVertical: Sizing.x10,
    paddingHorizontal: Sizing.x20,
    paddingVertical: Sizing.x10,
  },
  smallCard: {
    borderRadius: Sizing.x15,
    marginVertical: Sizing.x5,
    paddingHorizontal: Sizing.x10,
    paddingVertical: Sizing.x10,
  },
  buttonContainer: {
    paddingTop: Sizing.x20,
  },
  titleStyle: {
    ...Typography.fontSize.x20,
    fontSize: Sizing.x15,
    lineHeight: Sizing.x20,
    marginLeft:-Sizing.x5,
    marginTop:Sizing.x2,
    paddingBottom: Sizing.x10,
  },
  smallTitleStyle: {
    textAlign: 'left',
  },
  descriptionStyle: {
    ...Typography.fontSize.x15,
  },
  smallDescriptionStyle: {
    textAlign: 'left',
  },
  indicatorContainer: {
    position: 'absolute',
    zIndex: 1000,
    top: Sizing.x0,
    right: Sizing.x0,
    width: Sizing.x25,
    height: Sizing.x25,    
  },
})