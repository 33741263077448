import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { ContentDivider } from "components/Layout/ContentDivider"
import { Headline, Subheading, Paragraph } from "components/Typography"
import { ExternalLinkText } from "components/Utility/ExternalLinkText"
import { FooterButton } from 'components/Utility/FooterButton'
import { getActionDate } from "lib/dateHelpers"
import { getScreenAppWidth } from "lib/scaleHelpers"
import { getStatementDescription, replaceDoubleNewLineWithBr } from "lib/messageHelpers"
import React, { useEffect } from 'react'
import { Platform, View } from "react-native"
import { ScrollView } from 'react-native-gesture-handler'
import { ThemeProvider } from "react-native-paper"
import RenderHTML from "react-native-render-html"
import { useGetMessageQuery, useUpdateMessageMutation } from "store/apiSlice"
import { MessageDto, MessageStatus } from "store/dto/message.dto"
import { Colors, Flex, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"

type StatementInfoModalProps = {
  message: MessageDto
  visible: boolean
  onDismiss: any
}

export const StatementInfoModal = (props: StatementInfoModalProps) => {
  const { onDismiss, visible, message } = props

  const { data: messageWithDocument, error, isLoading, isFetching, refetch } = useGetMessageQuery(message?.id, { skip: !message })
  
  const [updateMessage] = useUpdateMessageMutation()

  const setStatusAndClose = (status: MessageStatus) => {
    updateMessage({
      id: message?.id,
      status,
      accountId: message?.accountId,
    })
    onDismiss()
  }

  //Refresh message on open (gets a new document URL)
  useEffect(() => {
    refetch()
  }, [])

  const screenWidth = getScreenAppWidth()
  //Calculate contentWidth from screenWidth minus CardModal padding and cardModalContentContainer padding
  const contentWidth = screenWidth - (Sizing.x10 * 2) - (Sizing.x10 * 2)
  const source = {
    html: `<div>${replaceDoubleNewLineWithBr(message?.payload?.body)}</div>`
  }

  const description: any = getStatementDescription(message)

  return (
    <ThemeProvider theme={Paper.darkTheme}>
      <CardModal visible={visible} onDismiss={onDismiss} >
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View style={layoutStyles.cardModalContentContainer}>
              <View style={{ paddingBottom: Sizing.x20 }}>
                <Headline>{description}</Headline>
                <Subheading>{getActionDate(message?.created)}</Subheading>
                {
                  message?.payload?.id
                  ?
                    <View style={{
                      ...Flex.row.center,
                      paddingTop: Sizing.x10,
                    }}>
                      <View style={{ paddingHorizontal: Sizing.x10}}>
                        <MaterialCommunityIcons name={'paperclip'} size={Sizing.x20} />
                      </View>
                        <ExternalLinkText
                          style={{ color: Colors.brand.purple1}}
                          url={messageWithDocument?.payload?.documentUrl}
                          directToBrowser={true}
                          disabled={isLoading || isFetching || !!error}
                        >{
                          isLoading || isFetching ? `Loading...`
                          : error ? `Error retrieving statement`
                          : 'Valuation Statement'
                        }</ExternalLinkText>
                    </View>
                  : <></>
                }
              </View>
              <ContentDivider />
              <View style={{ paddingTop: Sizing.x20 }}>
                <RenderHTML
                  contentWidth={contentWidth}
                  source={source}
                />
              </View>
            </View>
          </ScrollView>
          <FooterButton
            onPress={() => setStatusAndClose(MessageStatus.READ)}
          >
            {'Close'}
          </FooterButton>
        </View>

      </CardModal>
    </ThemeProvider>
  )
}
