import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'

export const RetirementProfileSetup_03_LondonChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)
  //Setup form
  const formObj = useForm<{ insideLondon: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      insideLondon: workingRetirementProfileData?.insideLondon
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementProfile(attributes))
    navigation.navigate(nextScreen)
  }

  const londonOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'In London',
    },
    {
      value: false,
      label: 'Elsewhere'
    }
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Where do you think you'll retire?`}
      subHeading={`Jarvis can propose realistic budget options based on where you plan to retire`}
    >
      <ManagedSimpleChoiceInput
        name={'insideLondon'}
        formObj={formObj}
        options={londonOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      <Paragraph>{`As London is a disproportionately expensive city in the UK, we can propose budget options that reflect the higher cost of living`}</Paragraph>
    </ProcessScreen>
  )
}
