import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { UseFormReturn } from 'react-hook-form'
import { HelperText } from 'react-native-paper'
import { StyleProp, TextStyle } from 'react-native'
import { Logger } from 'lib/logger'
import { get } from 'lodash'

export type InputErrorMessagesProps = {
  formObj: UseFormReturn<any>
  name: string
  informationMessage?: string
  informationMessageIsError?: boolean
  textStyle?: StyleProp<TextStyle>
}

export const InputErrorMessages = (props: InputErrorMessagesProps) => {
  const { informationMessage, informationMessageIsError, name, formObj, textStyle } = props
  const { formState: { errors, isValid } } = formObj
  const fieldErrors = get(errors, name)
  const hasError = fieldErrors ? true : false

  return (
    <HelperText
      type={hasError || informationMessageIsError ? 'error' : 'info'}
      visible={hasError || !!informationMessage}
      style={[
        {
          textAlign: 'center',
        },
        textStyle,
      ]}
    >
      {
        hasError ? 
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => {
              if (message) {
                return message
              }
              switch(fieldErrors?.type) {
                case 'required':
                  return 'Required'
                //Used for ManagedAutoCompleteInput - TODO - find a better way
                case 'hasValue':
                  return 'Please select a value'
                default:
                  return 'Invalid'
              }
            }}
          />
          : informationMessage
      }
    </HelperText>
  )
}