import { ManagedProportionInput } from 'components/Inputs/ManagedProportionInput'
import { ModalProcessScreen } from 'components/Layout'
import { concat, fill, floor, last, round } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { batch } from 'react-redux'
import { BeneficiaryRelationship, Nomination } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { addBeneficiaryData, setAddBeneficiaryData } from 'store/uxSlice'

export const BeneficiariesAddBeneficiary_07_Adjust = ({ route, navigation }) => {
  const addBeneficiaryDataData = useAppSelector(addBeneficiaryData)
  const { allNominations } = addBeneficiaryDataData || {}
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const dispatch = useAppDispatch()
  const { nextScreen } = route?.params || {}

  //Create array of proportion values, adding zero for the new beneficiary
  const startingProportions = allNominations ? concat(allNominations.map(nomination => {
    return nomination.beneficiaryProportion
  }), [0]) : []

  const getBeneficiaryName = (nomination: Partial<Nomination>) => {
    return nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY
      ? nomination.firstName
      : `${nomination.firstName} ${nomination.surname}`
  }

  const labels = allNominations ? concat(allNominations.map(nomination => {
    return getBeneficiaryName(nomination)
  }), [getBeneficiaryName(workingBeneficiaryData)]) : []

  const formObj = useForm<{ proportions: number[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proportions: startingProportions || [],
    }
  })
  const { getValues, setValue, handleSubmit, trigger, watch, formState: { isValid } } = formObj

  const equalizeValues = () => {
    const proportions = watch('proportions')
    const length = proportions?.length || 0
    const otherLength = length - 1
    if (otherLength < 1) {
      return
    }
    const equalShare = floor((1 / length), 2) // Nearest whole number
    const lastShare = 1 - (equalShare * otherLength)
    const newProportions = concat(fill(Array(otherLength), equalShare), [lastShare])
    setValue('proportions', newProportions )
    trigger('proportions')
  }

  const onSubmit = attributes => {
    const newBeneficiaryProportion = round(last(attributes.proportions),2)
    const updatedOtherNominations = allNominations.map((nomination, idx) => {
      return {
        ...nomination,
        beneficiaryProportion: round(attributes.proportions[idx], 2),
      }
    })

    //Update both the new working nomination and the others
    batch(() => {
      dispatch(updateWorkingBeneficiary({
        beneficiaryProportion: newBeneficiaryProportion
      }))
      dispatch(setAddBeneficiaryData({
        allNominations: updatedOtherNominations
      }))
    })

    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Set Proportions'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`Decide how much each will receive`}
      allowTextButton={true}
      textButtonAction={equalizeValues}
      textButtonTitle={'Equalize Proportions'}
    >
      <ManagedProportionInput
        formObj={formObj}
        name={'proportions'}
        labels={labels}
        minValue={0.01}
        maxValue={1}
        informationMessage={'Overall total should be 100%'}
      />
    </ModalProcessScreen>
  )
}

