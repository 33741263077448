import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { rootNavigate } from 'lib/RootNavigation'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
// import { ampli } from 'src/ampli'
import { useCreateMeMutation, useUpdateMeMutation } from 'store/apiSlice'
import { setUserHasClient } from 'store/authSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute, existingClient }: { nextRoute: string, existingClient: ClientMeDto }  = route?.params || {}

  const dispatch = useAppDispatch()

  const [reportingFinished, setReportingFinished] = useState(false)

  const workingClientData: any = useAppSelector(workingClient)
  const [ createMe, { data: createdMe, error: createMeError, isLoading: createMeIsLoading } ] = useCreateMeMutation()
  const [ updateMe, { data: updatedMe, error: updateMeError, isLoading: updateMeIsLoading } ] = useUpdateMeMutation()

  const isLoading = createMeIsLoading || updateMeIsLoading
  const error: any = createMeError || updateMeError

  //Save on enter
  useEffect(() => {
    save()
  }, [])

  //Amplitude reporting
  useEffect(() => {
    //Prevent double reporting
    if (reportingFinished) {
      return
    }

    if (createdMe || updatedMe) {
      ampli.clientSetup()
      setReportingFinished(true)
    }
  }, [createdMe, updatedMe, reportingFinished])

  //Navigate when me created and reporting done
  useEffect(() => {
    if ((createdMe || updatedMe) && reportingFinished) {
      end()
    }
  }, [createdMe, updatedMe, reportingFinished])

  const save = () => {
    if (existingClient) {
      Logger.info(`Updating client...`)
      updateMe(workingClientData)
    } else {
      Logger.info(`Creating client...`)
      createMe(workingClientData)  
    }
  }

  const end = () => {
    //Clear temp data
    dispatch(updateWorkingClient(undefined))
    //Set userHasClient
    dispatch(setUserHasClient(true))
    rootNavigate(nextRoute, route?.params)
  }

  return (
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={save}
      isLoading={isLoading}
      loadingMessage={['Creating your profile...']}
      buttonAction={() => {}}
      showButton={false}    
    />
  )
}
