import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getGroupSchemeJobListInfo } from 'lib/groupSchemeJobHelpers'
import React from 'react'
import { GroupSchemeJobDto } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type EmployerHistoryContributionListItemProp = {
  job: GroupSchemeJobDto
  onPressFunction?: () => void
}
export const EmployerHistoryContributionListItem = (props: EmployerHistoryContributionListItemProp) => {
  const { job, onPressFunction } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const { iconName, description, formatString, uploadedByString, message } = getGroupSchemeJobListInfo(job, true)

  const titleRightStyle = {
    color: themeColors.primary
  }

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName as any} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={description}
      titleRight={formatString}
      titleRightStyle={titleRightStyle}
      subTitle={uploadedByString}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{message}</Text>}
    />
  )
}
