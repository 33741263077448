import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DashboardHero, Paragraph } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { formatISO } from 'date-fns'
import { LinearGradient } from 'expo-linear-gradient'
import { hexColorWithOpactity } from 'lib/colorHelpers'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import React, { useEffect, useRef } from 'react'
import { Animated, Easing, Pressable, StyleSheet, View } from 'react-native'
import { Shadow } from 'react-native-shadow-2'
import { useGetMeQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setTimelinePinned, timelinePinned, timelineTerm } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'
import { DashboardItem } from './DashboardItem'

type DashboardHeroItemProps = {
  error?: any
  errorTryAgain?: any
  isLoading?: boolean
  isFetching?: boolean
  title: string
  footer: string
  heroText: string
  leftNavigationFunction?: any
  rightNavigationFunction?: any
}
export const DashboardHeroItem = (props: DashboardHeroItemProps) => {
  const { title, footer, heroText, errorTryAgain, leftNavigationFunction, rightNavigationFunction } = props

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching } = useGetMeQuery()

  const error: any = clientError || props.error
  const isLoading = clientIsLoading || props.isLoading
  const isFetching = clientIsFetching || props.isFetching
  const isLoadingOrFetching = isLoading || isFetching
  
  const timelineIsPinned = useAppSelector(timelinePinned)
  const currentTimelineTerm = useAppSelector(timelineTerm)

  const titleOpacity = useRef(new Animated.Value(1)).current
  const heroTextOpacity = useRef(new Animated.Value(1)).current

  const dispatch = useAppDispatch()

  const showTitle = () => {
    Animated.timing(titleOpacity, {
      useNativeDriver: false,
      toValue: 0.8,
      duration: 800,
      easing: Easing.linear,
    }).start()
  }

  const hideTitle = () => {
    Animated.timing(titleOpacity, {
      useNativeDriver: false,
      toValue: 0,
      duration: 200,
      easing: Easing.quad,
    }).start()
  }

  const showHeroText = () => {
    Animated.timing(heroTextOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
    }).start()
  }

  const dimHeroText = () => {
    Animated.timing(heroTextOpacity, {
      useNativeDriver: false,
      toValue: 0.1,
      duration: 1000,
      easing: Easing.quad,
    }).start()
  }

  //Animate title when timeline is visible
  useEffect(() => {
    if (timelineIsPinned) {
      hideTitle()
    } else {
      showTitle()
    }
  }, [timelineIsPinned])

  //Animate hero text when loading/fetching
  useEffect(() => {
    if (isLoadingOrFetching) {
      dimHeroText()
    } else {
      showHeroText()
    }
  }, [isLoadingOrFetching])


  //Circle rotation for loading
  const circleRotation = useRef(new Animated.Value(0))
  const rotationAnimation = useRef(
    Animated.loop(
      Animated.timing(
        circleRotation.current,
        {
        toValue: 1,
        duration: 3000,
        easing: Easing.linear,
        useNativeDriver: true
        }
      )
    )
  ).current

  //Start and stop the animation based on the value of the boolean prop
  useEffect(() => {
    if (isLoadingOrFetching) {
      rotationAnimation.start()
    } else {
      //When stopping reset the value to 0 so animated item doesn't stop in a random position
      rotationAnimation.stop()
      circleRotation.current.setValue(0)
    }

    //Return a function from useEffect to stop the animation on unmount
    return () => rotationAnimation.stop()
    //This useEffect should rerun if "running" or "anim" changes (but anim won't change since its a ref we never modify)
  }, [isLoadingOrFetching, rotationAnimation])

  const spin = circleRotation.current.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })
  const reverseSpin = circleRotation.current.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-360deg']
  })

  const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0
  const timelineAge = clientAge + currentTimelineTerm
  
  return (
      <DashboardItem
        error={false}
        isLoading={false}
        isFetching={false}
      >
        <View style={{
          ...Flex.row.center,
        }}>
          <Animated.View style={[localStyles.chevronContainer, { opacity: Animated.divide(titleOpacity, 2) }]}>
            { leftNavigationFunction
                ? <MaterialCommunityIcons
                    name={'chevron-left'}
                    size={Sizing.x40}
                    color={Colors.brand.red4}
                    style={{ transform: [{ scaleY: 2 }]}}
                    onPress={leftNavigationFunction}
                  />
                : <></>
            }
          </Animated.View>
          <View style={{
            marginTop: Sizing.x20 * -1,
            paddingTop: Sizing.x40,
            paddingBottom: Sizing.x20,
          }}>
            <Shadow distance={Sizing.x40} startColor={Colors.gradient.grad_purple1_5} endColor={Colors.brand.purple0}>
              <Animated.View style={{
                ...localStyles.highlightContainer,
                transform: [
                  {
                    rotate: spin,
                  }
                ]
              }}>
                <LinearGradient
                  colors={[
                    Colors.brand.red3,
                    Colors.gradient.grad_purple0,
                  ]}
                  start={{x: 0.9, y: 0.9}}
                  end={{x: 0.5, y: 0.5}}
                  // locations={[0.1, 0.9]}
                  style={{
                    ...localStyles.highlightContainer,
                  }}
                >
                  <LinearGradient
                    colors={[
                      // Colors.gradient.grad_purple0,
                      Colors.gradient.grad_purple1,
                      Colors.gradient.grad_purple2,
                    ]}
                    start={{x: 0.1, y: 0.1}}
                    end={{x: 0.9, y: 0.9}}
                    // locations={[0.1, 0.15, 0.9]}
                    style={localStyles.outerContainer}
                  >
                    <Shadow distance={Sizing.x10} startColor={hexColorWithOpactity(Colors.neutral.black, 0.2)} endColor={hexColorWithOpactity(Colors.neutral.black, 0.25)}>
                      <Animated.View style={{
                        ...localStyles.innerContainer,
                        transform: [
                          {
                            rotate: reverseSpin,
                          }
                        ]
                      }}>
                        {
                          error ? <ErrorScreen noDashboardButton={true} iconOnly={true} error={error?.data} errorTryAgain={errorTryAgain} /> : 
                          <>
                            <Pressable
                              onPress={() => dispatch(setTimelinePinned(!timelineIsPinned))}
                            >
                              <Animated.View style={{
                                height: Sizing.x30,
                                opacity: titleOpacity,
                              }}>
                                <Paragraph style={{color: Colors.neutral.white}}>{
                                title
                                }</Paragraph>
                              </Animated.View>
                              <View>
                                <Animated.View style={{
                                  opacity: heroTextOpacity,
                                  }}>
                                  <DashboardHero style={{
                                    color: Colors.neutral.white,
                                    fontSize: Sizing.x70,
                                  }}>{
                                    timelineIsPinned && timelineAge ? timelineAge.toString() : heroText
                                  }</DashboardHero>
                                </Animated.View>
                              </View>
                              {
                                  isLoadingOrFetching ?
                                    <View style={{
                                      height: Sizing.x30,
                                    }}>
                                      <Loading size={Sizing.x20} />
                                    </View>
                                  : 
                                    <Animated.View style={{
                                      height: Sizing.x30,
                                      opacity: titleOpacity,
                                    }}>
                                      <Paragraph style={{color: Colors.neutral.white}}>{
                                      footer
                                      }</Paragraph>
                                    </Animated.View>
                              }
                            </Pressable>
                          </>
                        }
                      </Animated.View>
                    </Shadow>
                  </LinearGradient>
                </LinearGradient>
              </Animated.View>
            </Shadow>
          </View>
          <Animated.View style={[localStyles.chevronContainer, { opacity: Animated.divide(titleOpacity, 2) }]}>
            { rightNavigationFunction
              ? <MaterialCommunityIcons
                  name={'chevron-right'}
                  size={Sizing.x40}
                  color={Colors.brand.red4}
                  style={{ transform: [{ scaleY: 2 }]}}
                  onPress={rightNavigationFunction}
                />
              : <></>
            }
          </Animated.View>
        </View>
      </DashboardItem>
  )
}

const localStyles = StyleSheet.create({
  chevronContainer: {
    width: Sizing.x60,
    zIndex: 1000,
    padding: Sizing.x10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  highlightContainer: {
    ...Flex.column.between,
    alignItems: 'center',
    justifyContent: 'center',
    height: scaleNormalizer(222),
    width: scaleNormalizer(222),
    borderRadius: scaleNormalizer(111),
  },
  outerContainer: {
    ...Flex.column.between,
    alignItems: 'center',
    justifyContent: 'center',
    height: scaleNormalizer(220),
    width: scaleNormalizer(220),
    borderRadius: scaleNormalizer(110),
    backgroundColor: Colors.brand.purple1,
  },
  innerContainer: {
    ...Flex.column.between,
    paddingVertical: Sizing.x10,
    paddingHorizontal: Sizing.x20,
    height: scaleNormalizer(160),
    width: scaleNormalizer(160),
    borderRadius: scaleNormalizer(80),
    backgroundColor: hexColorWithOpactity(Colors.neutral.black, 0.2),
  },
})