import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminInvestmentPlansListScreen } from './Screens/AdminInvestmentPlanListScreen'

export const AdminInvestmentPlanSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Investment Plans'}
    >
      <AdminInvestmentPlansListScreen />
    </SectionSimpleContainer>
  )
}

