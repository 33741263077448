import { LinearGradient } from 'expo-linear-gradient'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { Colors, Paper } from 'styles'
import { backgroundStyles } from 'styles/common/'

type BackgroundGradientPurple = {
  children: React.ReactNode
}
export const BackgroundGradientPurple = (props: BackgroundGradientPurple) => {
  const {children} = props

  const isWeb = platformIsWeb()

  return (    
    <ThemeProvider theme={ isWeb ? Paper.darkTheme : Paper.lightTheme }>
      {
        isWeb ?
          <View style={backgroundStyles.backgroundContainer}>
            {children}
          </View>
        :
        <LinearGradient
          colors={[
            Colors.gradient.grad_purple3,
            Colors.gradient.grad_purple2,
            Colors.gradient.grad_purple1
          ]}
          locations={[0.1, 0.5, 0.9]}
          style={backgroundStyles.backgroundContainer}>
          {children}
        </LinearGradient>
      }
    </ThemeProvider>
  )
}
