import {View, Text} from 'react-native';
import React, { useEffect } from 'react';
import ModalProcessStackBuilder from 'navigation/stacks/ModalProcessStackBuilder';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import {BeneficiaryRoute} from 'store/dto/account.dto';
import {BeneficiariesAddBeneficiary_00_Intro} from './Screens/BeneficiariesAddBeneficiary_00_Intro';
import {BeneficiariesAddBeneficiary_01_Relationship} from './Screens/BeneficiariesAddBeneficiary_01_Relationship';
import {BeneficiariesAddBeneficiary_02_FirstName} from './Screens/BeneficiariesAddBeneficiary_02_FirstName';
import {BeneficiariesAddBeneficiary_03_Surname} from './Screens/BeneficiariesAddBeneficiary_03_Surname';
import {BeneficiariesAddBeneficiary_04_Title} from './Screens/BeneficiariesAddBeneficiary_04_Title';
import {BeneficiariesAddBeneficiary_05_Charity} from './Screens/BeneficiariesAddBeneficiary_05_Charity';
import {BeneficiariesAddBeneficiary_06_Split} from './Screens/BeneficiariesAddBeneficiary_06_Split';
import {BeneficiariesAddBeneficiary_07_Adjust} from './Screens/BeneficiariesAddBeneficiary_07_Adjust';
import {BeneficiariesAddBeneficiary_99_Result} from './Screens/BeneficiariesAddBeneficiary_99_Result';

import {addBeneficiaryData, setAddBeneficiaryData} from 'store/uxSlice';
import { updateWorkingBeneficiary } from 'store/tempDataSlice';

export type AddBeneficiaryProcessStackProps = {
}

export const AddBeneficiaryProcessStack = (props: AddBeneficiaryProcessStackProps) => {
  const dispatch = useAppDispatch()
  const addBeneficiary = useAppSelector(addBeneficiaryData)

  const handleDismissScreen = () => {
    dispatch(setAddBeneficiaryData(undefined));
  }

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingBeneficiary(undefined))
  }, []
  )
  
  return (
    <ModalProcessStackBuilder
      visible={!!addBeneficiary}
      onDismiss={() => handleDismissScreen()}
      dismissConfirmationMessage={`Cancel adding this beneficiary?`}
      processTitle="Add Beneficiary"
      initialRouteName="Intro"
      screens={[
        {
          navigationId: 'Intro',
          title: 'Your Partner',
          component: BeneficiariesAddBeneficiary_00_Intro
        },

        {
          navigationId: 'Relationship',
          title: 'Your Relationship',
          component: BeneficiariesAddBeneficiary_01_Relationship
        },
        {
          navigationId: 'FirstName',
          title: 'First Name',
          component: BeneficiariesAddBeneficiary_02_FirstName
        },
        {
          navigationId: 'Surname',
          title: 'Surname',
          component: BeneficiariesAddBeneficiary_03_Surname
        },
        {
          navigationId: 'Title',
          title: 'Title',
          component: BeneficiariesAddBeneficiary_04_Title
        },
        {
          navigationId: 'Charity',
          title: 'Charity',
          component: BeneficiariesAddBeneficiary_05_Charity
        },
        {
          navigationId: 'Split',
          title: 'Split Proportion',
          component: BeneficiariesAddBeneficiary_06_Split
        },
        {
          navigationId: 'Adjust',
          title: 'Adjust Proportion',
          component: BeneficiariesAddBeneficiary_07_Adjust
        },
        {
          navigationId: 'Results',
          title: 'All Done!',
          component: BeneficiariesAddBeneficiary_99_Result
        }
      ]}
    />
  );
};
