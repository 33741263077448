import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import React from 'react'
import { StatusBar, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { Flex } from 'styles'
import { LightModal } from './LightModal'

export type ModalEditWrapProps = {
  screen: React.ReactNode
}

export const ModalEditWrap = (props: ModalEditWrapProps) => {
  const { screen } = props
  const viewConstraints: any = getScreenWidthConstraints()
  return (
    <>
      <StatusBar
        barStyle={'dark-content'}
      />
      <Portal>
        <LightModal
          visible={true}
          dismissable={false}
        > 
          <View style={[
            {
            ...Flex.column.start,
            },
            viewConstraints,
          ]}>
            {screen}
          </View>
        </LightModal>
      </Portal>
    </>
  )
}
