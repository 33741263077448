import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { format } from 'date-fns'
import { goToDashboard } from 'lib/RootNavigation'
import { getModelChangeMessages } from 'lib/loadingHelpers'
import React from 'react'
import { useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { Typography } from 'styles'

export const InviteAcceptHeroScreen = () => {

  const { data: client, error: clientError, isLoading: clientIsLoading, isFetching: clientIsFetching } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching } = useGetRetirementProfileQuery()

  const isLoading = clientIsLoading || clientIsFetching || spouseIsLoading || spouseIsFetching || rpIsLoading
  const error: any = clientError

  const useStatePension = !retirementProfile
  const retirementAge = useStatePension ? client?.statePensionAge : retirementProfile.targetRetirementAge

  const sameAges = client?.lastSurvivorDeathAge === spouse?.lastSurvivorDeathAge

  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'add_your_partner.png',
      headline: `A Great Partnership!`,
      subHeading: `We've linked your Jarvis account with ${spouse?.firstName}'s`,
    },
    {
      illustrationFilename: 'combined_retirement_timeline.png',
      headline: `Life Expectancy`,
      subHeading:
        <Subheading>
          {`Based on both of your ages and genders, your combined life expectancy is around `}
          <Subheading style={Typography.fontWeight.bold}>{`${client?.lastSurvivorDeathAge} years`}</Subheading>
        </Subheading>,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.COMBINED_LIFE_EXPECTANCY} buttonTitle={'How is this calculated?'} />
    },
    {
      illustrationFilename: 'joint_planning.png',
      headline: `Joint Retirement Planning`,
      subHeading:
        sameAges
          ? <Subheading>
            {`Your Timeline will run until `}
            <Subheading style={Typography.fontWeight.bold}>{`${format(new Date(client?.lastSurvivorDeathDate), 'MMMM yyyy')}`}</Subheading>
            {`, when you would both be `}
            <Subheading style={Typography.fontWeight.bold}>{client?.lastSurvivorDeathAge}</Subheading>
          </Subheading>
          : <Subheading>
            {`Your Timeline will run until `}
            <Subheading style={Typography.fontWeight.bold}>{`${format(new Date(client?.lastSurvivorDeathDate), ' MMMM yyyy')}`}</Subheading>
            {`, when you would be aged `}
            <Subheading style={Typography.fontWeight.bold}>{client?.lastSurvivorDeathAge}</Subheading>
            {`, and ${spouse?.firstName} `}
            <Subheading style={Typography.fontWeight.bold}>{spouse?.lastSurvivorDeathAge}</Subheading>
          </Subheading>,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.JOINT_PLANNING} buttonTitle={'How is this decided?'} />
    },
    {
      illustrationFilename: 'relax_for_now.png',
      headline: `Retirement Timeline`,
      subHeading:
        <Subheading>
          {`With your ${useStatePension ? 'state pension age' : 'target retirement age'} of `}
          <Subheading style={Typography.fontWeight.bold}>{`${retirementAge} years`}</Subheading>
          {`, you would have around `}
          <Subheading style={Typography.fontWeight.bold}>{`${client?.lastSurvivorDeathAge - retirementAge} years`}</Subheading>
          {` in retirement`}
        </Subheading>,
    },
  ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      loadingMessage={getModelChangeMessages()}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={goToDashboard}
      finishButtonLabel={'Back to Dashboard'}
    />
  )
}
