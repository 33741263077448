import { TypographyProps } from 'components/Typography'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { Linking } from 'react-native'
import { Text as PaperText } from "react-native-paper"
import { Typography, Paper } from 'styles'
import { ExternalLinkModal } from './ExternalLinkModal'

type ExternalLinkTextProps = TypographyProps & {
  url: string
  directToBrowser?: boolean
  disabled?: boolean
}
export const ExternalLinkText = (props: ExternalLinkTextProps) => {
  const { url, children, directToBrowser, disabled } = props
  const [externalVisible, setExternalVisible] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const openWebView = () => {
    setExternalVisible(true)
  }

  const closeWebView = () => {
    setExternalVisible(false)
  }

  const openInBrowser = () => { 
    if (isWeb) {
      window.open(url)
    } else {
      closeWebView()
      Linking.openURL(url)
    }
  }

  const isWeb = platformIsWeb()

  return (
    <>
      <PaperText
        {...props}
        allowFontScaling={false}
        style={[
          Typography.defined.text,
          {
            textDecorationLine: 'underline',
          },
          props.style,
        ]}
        onPress={disabled ? () => {} :isWeb || directToBrowser ? openInBrowser : openWebView}
      >
        {children}
      </PaperText>
      {externalVisible && 
        <ExternalLinkModal
          url={url}
          onDismiss={closeWebView}
        />
      }
    </>
  )
}