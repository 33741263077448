import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { Logger } from 'lib/logger'
import { default as React, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useGetStateBenefitQuery, useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditStatePensionClient } from 'store/uxSlice'

export type RetirementIncomesEditStatePensionModalProps = {
  client: ClientBaseDto
  onDismiss?: any
}

export const RetirementIncomesEditStatePensionModal = (props: RetirementIncomesEditStatePensionModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: RetirementIncomesEditStatePensionModalProps) => {
  const { client } = props

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditStatePensionClient(undefined))

  const visible = !!client
  const onDismiss = props.onDismiss || globalDismiss
  
  const handleDelete = () => {
    const payload = {
      statePensionAmount: 0
    }
    Logger.info(`Deleting state pension...`)
    if (client?.isSpouse) {
      Logger.info(`Updating spouse...`)
      updateSpouse(payload)
    } else {
      Logger.info(`Updating client...`)
      updateMe(payload)
    }
    onDismiss()
  }

  const { data: stateBenefit, error: stateBenefitError, isLoading: stateBenefitIsLoading } = useGetStateBenefitQuery(
    {
      birthDate: client?.birthDate,
      gender: client?.gender
    },
    {skip: !client}
  )

  //Disabled if spouse with a real user
  const disabled = client?.isSpouse && !!client?.userId

  const [updateMe, { data: updatedMe, isLoading: updateMeIsLoading, error: updateMeError }] = useUpdateMeMutation()
  const [updateSpouse, { data: updatedSpouse, isLoading: updateSpouseIsLoading, error: updateSpouseError }] = useUpdateSpouseMutation()

  const isLoading = updateMeIsLoading || updateSpouseIsLoading || stateBenefitIsLoading
  const error: any = updateMeError || updateSpouseError || stateBenefitError

  //Setup form
  const formObj = useForm<{statePensionAmount: number}>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionAmount: client?.statePensionAmount
    }
  })
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: {isDirty, isValid}
  } = formObj

  const onSubmit = attributes => {
    if (client?.isSpouse) {
      Logger.info(`Updating spouse...`)
      updateSpouse(attributes)
    } else {
      Logger.info(`Updating client...`)
      updateMe(attributes)
    }
  }

  useEffect(() => {
    if (updatedMe || updatedSpouse) {
      ampli.statePensionSet({
        amount: updatedSpouse ? updatedSpouse?.statePensionAmount : updatedMe?.statePensionAmount,
        isSpouse: updatedSpouse ? true : false,
      })
      onDismiss()
    }
  }, [updatedMe, updatedSpouse])

  const setFullAmount = () => {
    setValue('statePensionAmount', stateBenefit?.maxAmount, { shouldDirty: true } )
    trigger('statePensionAmount')
  }

  return (
    <ModalEditScreen
      formTitle='Update State Pension'
      onDismiss={onDismiss}
      isDirty={isDirty}
      dismissDialogText={'Discard changes to state pension?'}
      onDelete={disabled ? undefined : handleDelete}
      deleteDialogText='Really delete state pension?'
      error={error}
      isLoading={isLoading}
      loadingMessage={['Saving...']}
      errorTryAgain={handleSubmit(onSubmit)}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      {
        disabled
        ? <Paragraph>{`This information is read only because you have linked your account with ${client?.firstName}.`}</Paragraph>
        : <></>
      }
      <ManagedCurrencyInput
        name={'statePensionAmount'}
        autoFocus={true}
        formObj={formObj}
        // label={'Eligible State Pension'}
        placeholder={'Expected annual amount (or zero)'}
        disabled={disabled}
        required={true}
        minValue={0}
        maxValue={stateBenefit?.maxAmount}
        blurOnSubmit={false}
      />
      { disabled ? <></> :
        <Button mode='text' onPress={setFullAmount}>
          {'Set Full Amount'}
        </Button>
      }
    </ModalEditScreen>
  )
}
