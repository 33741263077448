import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import { Title } from 'store/dto/base.dto'
import { BeneficiaryRelationship } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { addBeneficiaryData } from 'store/uxSlice'

export const BeneficiariesAddBeneficiary_04_Title = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}
  const dispatch = useAppDispatch()
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const addBeneficiaryDataData = useAppSelector(addBeneficiaryData)

  const formObj = useForm<{ title: Title }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title:
        workingBeneficiaryData?.title ? workingBeneficiaryData.title
        : workingBeneficiaryData?.beneficiaryRelationship === BeneficiaryRelationship.HUSBAND ? Title.MR
        : workingBeneficiaryData?.beneficiaryRelationship === BeneficiaryRelationship.SON ? Title.MR
        : workingBeneficiaryData?.beneficiaryRelationship === BeneficiaryRelationship.WIFE ? Title.MRS
        : workingBeneficiaryData?.beneficiaryRelationship === BeneficiaryRelationship.DAUGHTER ? Title.MISS
        : undefined
    }
  })
  const { handleSubmit, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingBeneficiary(attributes))
    //If we already have nominations, go to split else results
    navigation.navigate(addBeneficiaryDataData?.allNominations?.length ? 'Split' : 'Results')
  }

  const softOnSubmit = attributes => {
    dispatch(updateWorkingBeneficiary(attributes))
    //If we already have nominations, go to split else do nothng
    if (addBeneficiaryDataData?.allNominations?.length) {
      navigation.navigate('Split')
    } else {
      //Final screen, don't navigate, wait for user to confirm with button
      Keyboard.dismiss()
    }
  }

  return (
    <ModalProcessScreen
      buttonTitle={isValid ? addBeneficiaryDataData?.allNominations?.length ? 'Next' : 'Save Beneficiary' : 'Make a selection'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What's their preferred title?`}
      subHeading={`If you can't see the right option, just pick the closest fit`}
    >
      <ManagedSimpleChoiceInput
        name={'title'}
        formObj={formObj}
        options={enumToAutocompleteOptions(Title)}
        required={true}
        submitHandler={handleSubmit(softOnSubmit)}
      />
    </ModalProcessScreen>
  )
}
