import { MaterialIcons } from '@expo/vector-icons'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { envVariables } from 'environment'
import { openURL } from 'expo-linking'
import React from 'react'
import { Platform, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Sizing } from 'styles'

export const AppUpgradeScreen = () => {
  const { appStoreUrls } = envVariables
  const url = appStoreUrls[Platform.OS]

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={{
        ...Flex.column.center,
        alignItems: 'center'
      }}>
        <View style={{
          alignItems: 'center',
          paddingHorizontal: Sizing.x20,
          width: '100%',
        }}>
          <MaterialIcons name={'upgrade'} size={Sizing.x100} color={Colors.brand.red3} />
          <View style={{ paddingVertical: Sizing.x20 }}>
            <Headline>{`Your Jarvis app needs updating`}</Headline>
            <Subheading>{`You need to update your app to continue`}</Subheading>
          </View>
          <Button
            mode={'contained'}
            onPress={() => openURL(url)}
          >
            Update Jarvis
          </Button>
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}

