import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Paragraph } from 'components/Typography'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Flex } from 'styles'

export type LoadingScreenProps = {
  messages?: string[]
  reset?: any
}

export const LoadingScreen = (props: LoadingScreenProps) => {
  const { messages, reset } = props || {}
  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={{
        ...Flex.column.center,
        alignItems: 'center'
      }}>
        <Loading message={messages} messageNoLoop={true} useHolidayGraphics={true} />
        {
          reset
            ? <View style={{ ...Flex.override.bottom }}>
                <Paragraph>{`Taking too long?`}</Paragraph>
                <FooterButton mode={'text'} onPress={reset}>{`Return to login`}</FooterButton>
              </View>
            : <></>
        }
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}

