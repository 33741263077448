import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { UserDto, UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_03_Surname = ({ route, navigation }) => {
  const { nextScreen, user, metadata }: { nextScreen: string, user: UserDto, metadata: UserMetadata } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ surname: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      surname: workingClientData?.surname || metadata?.surname || user?.metadata.surname,
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  useEffect(() => {
    if (metadata) {
      setValue('surname', metadata?.surname)
    }
  }, [metadata])
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={metadata?.surname ? `Confirm your surname` : `Thanks ${workingClientData?.firstName} - and your surname?`}
      subHeading={'As it appears on your passport or legal ID'}
    >
      <ManagedTextInput
        name={'surname'}
        autoFocus={true}
        formObj={formObj}
                returnKeyType="next"

        // label={'Last Name'}
        placeholder={'Your legal surname'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }} />
    </ProcessScreen>
  )
}
