import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { createBranchLink } from 'lib/linkHelpers'
import { shareMessage } from 'lib/shareHelpers'
import React, { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { ampli } from 'src/ampli'
import { AppContext, linkParams, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const CalculatorHeroScreen = ({ route }) => {
  const { loggedInUserId } = route?.params || {}
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const linkConfig = useAppSelector(linkParams)

  const dispatch = useDispatch()
  
  const authUser = useSelector((state: any) => state.auth.user)
  const userIsLoggedIn = userIsReady(authUser)

  const [shared, setShared] = useState(false)

  const onShare = () => {
    setShared(true)
  }

  const onShareCancel = () => {
    setShared(false)
  }

  const {
    sendReport,
  } = workingCalculatorRequestData || {}

  const restart = () => {
    ampli.calculatorUsage({
      processStep: 'restart',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })
    dispatch(updateWorkingCalculatorRequest(undefined))
    rootNavigate('CalculatorIntroScreen', route?.params)
  }

  const goToCreateAccount = () => {
    ampli.calculatorUsage({
      processStep: 'register',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    const emailAddress = workingCalculatorRequestData?.email
    batch(() => {
      dispatch(updateWorkingCalculatorRequest(undefined))
      dispatch(setLoginParams({
        emailAddress,
        fromCalculator: true,
        authType: AUTHTYPE.REGISTER,
      }))
    })
    dispatch(updateWorkingCalculatorRequest(undefined))
    
    rootNavigate('AuthenticationProcessStack')
  }


  const carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: sendReport
        ? 'envelope_with_report.png'
        : userIsLoggedIn
          ? 'megaphone.png'
          : 'path_to_freedom.png',
      headline: `Ready to get started with Jarvis?`,
      subHeading: sendReport
        ? `Your report is on it's way, but you can get started with Jarvis right now!`
        : `Get started right now with a free Jarvis account!`,
    },
  ]

  const shareUrl = createBranchLink('share', {
    '~campaign': 'calculator',
    '~channel': 'friend',
  })

  const title = `Jarvis: Pensions without the guesswork`
  const message = `Secure my financial freedom. Use this link to get access: ${shareUrl}`

  const share = () => {
    ampli.calculatorUsage({
      processStep: 'share',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })

    shareMessage({
      message,
      title,
      onComplete: onShare,
      onCancel: onShareCancel,
    })
  }

  const returnToApp = () => {
    goToMainAppStack() 
  }

  return (
    <>
      <ProcessHeroScreen
        carouselScreens={carouselScreens}
        onFinish={userIsLoggedIn ? shared ? returnToApp : share : goToCreateAccount}
        finishButtonLabel={userIsLoggedIn ? shared ? `Close` : `Share Download Link Now` : `Get Started With Jarvis`}
        cancelButtonText={userIsLoggedIn ? shared ? `Share Download Link Again` : `Close` : `Run the calculator again`}
        cancelButtonAction={userIsLoggedIn ? shared ? share : returnToApp : restart}
      />
    </>
  )
}
