import { Breadcrumb } from '@sentry/react-native'
import { CaptureContext, Event } from '@sentry/types'

export enum Severity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
  Critical = "critical"
}

export const addBreadcrumb = (breadcrumb: Breadcrumb) => {}
export const captureException = (exception: any, context?: CaptureContext) => {}
export const captureEvent = (event: Event) => {}
export const setContext = (name: string, context?: { [key: string]: any; }) => {}
