import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { SpouseScreen } from './Screens/SpouseScreen'

export const SpouseSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Partner'}
    >
      <SpouseScreen />
    </SectionSimpleContainer>
  )
}

