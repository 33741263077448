import React, { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'
import { TypographyProps } from './typography.props'
import { concat } from 'lodash'
import { Logger } from 'lib/logger'

export type LoopingTextProps = TypographyProps & {
  textArray: string[],
  delay: number,
  duration: number,
  noLoop?: boolean
}

export const LoopingText = (props: LoopingTextProps) => {
  const { textArray, delay, duration, style, noLoop } = props

  const [count, setCount] = useState(0)

  //If not looping, add an extra empty text (will be cycled over and therefore invisible)
  const textArrayToUse = noLoop ? concat(textArray, ['']) : textArray

  const getAnimationValue = (delay, duration, callBack) => {
    let isMounted = true
    const animationValue = useRef(new Animated.Value(0)).current
    const fadeInOut = () => {
      Animated.sequence([
        Animated.timing(animationValue, {
          delay: 200,
          duration: duration,
          toValue: 0.5,
          useNativeDriver: true,
        }),
        Animated.timing(animationValue, {
          delay: delay,
          duration: duration,
          toValue: 1,
          useNativeDriver: true,
        }),
  
      ]).start(() => {
        if (isMounted) {
          animationValue.setValue(0)
          callBack()
          fadeInOut()
        }
      })
    }
    useEffect(() => {
      fadeInOut()
  
      return () => {
        animationValue.stopAnimation()
        isMounted = false
      }
    }, [])
    return (animationValue)
  }

  const animationValue = getAnimationValue(delay, duration, () => {
    setCount((prevCount) => {
      //Get the next index, resetting to zero if looping or sticking on the last element if noLoop
      return prevCount + 1 < textArrayToUse.length ?
          prevCount + 1 : noLoop ? prevCount : 0
    }
  )})


  return (
    <Animated.Text style={[
      style,
      {
        opacity: animationValue.interpolate({
          inputRange: [0, 0.5, 1],
          outputRange: [0, 1, 0],
        }),
        transform: [
          {
            translateY: animationValue.interpolate({
              inputRange: [0, 0.5, 1],
              outputRange: [-20, 0, 20],
            }),
          },
        ],
      },
    ]}
    >
      {textArrayToUse[count]}
    </Animated.Text>
  )
}
