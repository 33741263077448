import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { SpouseInvite_01_CheckDetails, SpouseInvite_02_FirstName, SpouseInvite_03_Surname, SpouseInvite_07_Email, SpouseInvite_08_Confirm, SpouseInvite_99_Result } from './Screens'

export const SpouseInviteProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='SpouseInvite'
      processTitle='Invite Partner'
      previousRoute='SpouseInviteIntroScreen'
      nextRoute='SpouseInviteHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'CheckDetails',
          title: 'Check Details',
          component: SpouseInvite_01_CheckDetails,
        },
        {
          navigationId: 'FirstName',
          title: 'First Name',
          component: SpouseInvite_02_FirstName,
        },
        {
          navigationId: 'Surname',
          title: 'Surname',
          component: SpouseInvite_03_Surname,
        },
        {
          navigationId: 'Email',
          title: 'Email',          
          component: SpouseInvite_07_Email,
        },
        {
          navigationId: 'Confirm',
          title: 'Confirm',          
          component: SpouseInvite_08_Confirm,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: SpouseInvite_99_Result,
      }}
    />
  )
}
