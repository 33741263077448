import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Headline, Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { changeAppContext, rootNavigate, rootNavigateBack } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext, clientHash, linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Colors, Sizing } from 'styles'

export const CalculatorIntroScreen = ({ route, navigation }) => {
  const { allowCancel, loggedInUserId } = route?.params || {}

  const dispatch = useAppDispatch()

  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  const linkConfig = useAppSelector(linkParams)

  const useCalculator = !!useAppSelector(clientHash)

  const next = () => {
    rootNavigate('CalculatorProcessStack', route?.params) //Go to start of process for invite choice
  }

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  const isWeb = platformIsWeb()

  return (
    <ProcessIntroScreen
      buttonTitle={useCalculator ? 'Get Started' : 'Close'}
      buttonAction={useCalculator ? next : () => rootNavigateBack()  }
      showButton={true}
      cancelButtonText={useCalculator && allowCancel ? 'Cancel' : undefined}
      cancelButtonAction={allowCancel ? () => rootNavigateBack() : undefined}
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      headline={`Find out when you can retire`}
      subHeading={`Our quick calculator can give you an estimate in 2 minutes`}
    >
      {
        useCalculator ?
        <>
          <AppIllustration filename={'financial_forecasting.png'} style={{
            width: Sizing.x200,
            height: Sizing.x200,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
          <Paragraph>{`See when you can retire based on your existing retirement planning.`}</Paragraph>
        </>
        : <>
            <AppIllustration filename={'gamified_approach.png'} style={{
              width: Sizing.x150,
              height: Sizing.x150,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
            <Headline style={{ color: Colors.brand.purple1 }}>{`Coming Soon!`}</Headline>
            <Paragraph>{`Our calculator will be able to give you a rough guide as to the age when you can retire based on your existing retirement planning.`}</Paragraph>
          </>
      }
    </ProcessIntroScreen>
  )
}
