import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import React, { useState } from 'react'
import { Image, View } from "react-native"
import { PensionProviderDto } from "store/dto/pension-provider.dto"
import { RetirementAssetDto } from "store/dto/retirement-asset.dto"
import { Sizing, Typography } from "styles"
import { Button } from "./Button"
import { ConfirmationDialog } from "./ConfirmationDialog"
import { ContentDivider } from "components/Layout/ContentDivider"

type RetirementAssetRetransferModalContentProps = {
  asset: RetirementAssetDto
  isReady: boolean
  provider: PensionProviderDto
  imageSource: any
  isSelected: boolean
  abandonAssetAction: any
  editAssetAction: any
}


export const RetirementAssetRetransferModalContent = (props: RetirementAssetRetransferModalContentProps) => {
  const { asset, isReady, provider, imageSource, isSelected, abandonAssetAction, editAssetAction } = props
  const { name, currentValue, externalProviderReference } = asset

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  return (
    <>
      <View style={{
        alignSelf: 'center',
        paddingVertical: Sizing.x20,
      }}>
        <Image source={imageSource} style={{
          width: scaleNormalizer(100),
          height: scaleNormalizer(100),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Headline>{name}</Headline>
      <Subheading>{`Estimated Value: ${formatCurrencyAmount(currentValue)}`}</Subheading>
      <Subheading>{`Reference: ${externalProviderReference}`}</Subheading>
      <ContentDivider />
      {
        isReady
          ? <>
              <Subheading style={Typography.fontWeight.bold} >{isSelected ? 'Ready and selected for transfer' : 'Not selected for transfer'}</Subheading>
              <Paragraph>{`You have made adjustments to enable a further transfer attempt.`}</Paragraph>
              <Button mode='text' onPress={showDialog}>{`Abandon Transfer`}</Button>
              <Button mode='text' onPress={editAssetAction}>{`Make adjustments for retransfer`}</Button>
            </>
          : <>
              <Subheading style={Typography.fontWeight.bold} >{`This transfer was rejected by ${provider?.name || 'the provider'}`}</Subheading>
              <Paragraph>{`Tap below to make adjustments and re-attempt the transfer. Alternatively, if you no longer wish to transfer the pension and this time, you can Abandon the transfer.`}</Paragraph>
              <Button mode='text' onPress={showDialog}>{`Abandon Transfer`}</Button>
            </>
      }
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`If you abandon the transfer of this asset, you will need to start a new transfer to consolidate in the future`}
        onCancel={hideDialog}
        onConfirm={abandonAssetAction}
      />
    </>
  )
}

