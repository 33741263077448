import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { getNationalityAutocompleteOptions } from 'lib/referenceDataHelpers'
import { sortBy } from 'lodash'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'store/hooks'
import { useGetNationalitiesQuery } from 'store/apiSlice'
import { updateWorkingClient } from 'store/tempDataSlice'

export const ClientVerification_02_Nationality = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  //Form refs for focussing
  const nationalityRef = useRef()

  //Setup form
  const formObj = useForm<{ nationality: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationality: 'GB',
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  //Build autocomplete options, putting GB first
  const { data: nationalities, error, isLoading, refetch } = useGetNationalitiesQuery()
  const nationalityOptions = getNationalityAutocompleteOptions(nationalities)

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={refetch}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Please tell us your nationality`}
      subHeading={`As it appears on your passport or legal ID`}
    >
      <ManagedAutoCompleteInput
        name={'nationality'}
        formObj={formObj}
        modalTitle={'Find Nationality'}
        dataSet={nationalityOptions}
        required={true}
        />
    </ProcessScreen>
  )
}
