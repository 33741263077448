import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminExceptionsListScreen } from './Screens/AdminExceptionListScreen'

export const AdminExceptionSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'System Exceptions'}
    >
      <AdminExceptionsListScreen />
    </SectionSimpleContainer>
  )
}

