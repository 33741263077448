import { SimpleUserDto } from "store/dto/base.dto"

export const getBestName = (user: SimpleUserDto): string => {
  const { firstName, surname, email } = user || {}

  return firstName && surname
    ? `${firstName} ${surname}`
    : firstName
    ? firstName
    : email
    ? email
    : 'n/a'
}

export const getBestNameAndEmail = (user: SimpleUserDto): string => {
  const { email } = user || {}
  const name = getBestName(user)
  return email ? `${name} (${email})` : name
}
