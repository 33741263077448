import { MaterialCommunityIcons } from '@expo/vector-icons'
import { isPending } from '@reduxjs/toolkit'
import { Paragraph } from 'components/Typography'
import { hexColorWithOpactity } from 'lib/colorHelpers'
import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'

export type TaskRowProps = {
  identifier: RetirementAssetTransferStepIdentifier
  title: string
  isLast: boolean
  isPending: boolean
  isComplete: boolean
  isNext: boolean
  nextTaskAction: any
}

export const TaskRow = (props: TaskRowProps) => {
  const { title, isNext, isLast, isComplete, isPending, nextTaskAction } = props

  const { colors: themeColors } = Paper.darkThemeOnLightGrey

  return (
    <Pressable onPress={isNext ? nextTaskAction: undefined}>
      <View style={localStyles.tableRowStyle}>
        <View style={[localStyles.itemContainer,
        !isLast ? [
          localStyles.itemContainerBorder,
          {
            borderBottomColor: hexColorWithOpactity(themeColors.themeType === 'dark' ? Colors.neutral.black : Colors.neutral.white, 0.5)
          }
        ] : {}
        ]}>
          <View style={localStyles.iconContainer}>
            {
              isPending ? <MaterialCommunityIcons name="timer-outline" size={Sizing.x30} color={Colors.neutral.s400} />
              : isComplete ? <MaterialCommunityIcons name="checkbox-marked-outline" size={Sizing.x30} color={Colors.brand.green2} />
                : isNext ? <MaterialCommunityIcons name="checkbox-blank-outline" size={Sizing.x30} color={Colors.brand.purple2} />
                  : <MaterialCommunityIcons name="checkbox-blank-outline" size={Sizing.x30} color={Colors.brand.grey3} />
            }
          </View>
          <View style={localStyles.labelContainer}>
            <Paragraph numberOfLines={1} style={[
              localStyles.stepTitle,
              Typography.fontWeight.bold,
              isNext ? { color: themeColors.primary } : isComplete ? undefined : { color: themeColors.disabled }
            ]}>
              {title}
            </Paragraph>
          </View>
        </View>
      </View>
    </Pressable>
  )
}


const localStyles = StyleSheet.create({
  tableRowStyle: {},
  labelContainer: {
    ...Flex.override.fill,
  },
  iconContainer: {
    paddingHorizontal: Sizing.x10,
    alignSelf: 'center',
  },
  itemContainer: {
    paddingVertical: Sizing.x10,
    ...Flex.row.start,
  },
  itemContainerBorder: {
    borderBottomWidth: 0.5,
  },
  stepTitle: {
    ...Typography.fontSize.x15,
    fontFamily: 'LabGrotesque-Light',
    textAlign: 'left'
  },
})