import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerContributionsListScreen } from './Screens/EmployerContributionsListScreen'

export const EmployerContributionsSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Submit Contributions'}
    >
      <EmployerContributionsListScreen />
    </SectionSimpleContainer>
  )
}

