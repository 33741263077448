import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { ProcessHeaderTitle } from 'components/Typography/ProcessHeaderTitle'
import { getProcessProgress, ProcessProgress } from 'lib/navigationHelpers'
import React, { useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { Modal, ProgressBar } from 'react-native-paper'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { modalHeaderEnabled } from 'store/uxSlice'
import { useAppSelector } from 'store/hooks'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { SafeAreaView } from 'react-native-safe-area-context'

export type ModalProcessHeaderProps = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
  processTitle: string
  onDismiss: any
  dismissConfirmationMessage?: string
  preventDisable?: boolean
}

export const ModalProcessHeader = (props: ModalProcessHeaderProps) => {
  const { navigationRef, processTitle, onDismiss, dismissConfirmationMessage, preventDisable } = props

  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const [processProgress, setProcessProgress] = useState<ProcessProgress>()

  //Update progress when state changes
  navigationRef.addListener('state', (e) => {
    const currentRouteState = navigationRef.getRootState()
    setProcessProgress(getProcessProgress(currentRouteState))
  })

  const goBack = () => {
    navigationRef.goBack()
  }

  const firstOrLast = processProgress && (processProgress.isFirst || processProgress.isLast)
  const progressVisible = !firstOrLast
  const backVisible = !firstOrLast
  const dismissVisible = processProgress && !processProgress.isLast

  const modalHeaderActive = useAppSelector(modalHeaderEnabled)

  const handleClose = () => {
    Keyboard.dismiss()
    if (!firstOrLast) {
      showDialog()
    } else {
      onDismiss()
    }
  }

  return (
    <>
      <View style={[
        layoutStyles.modalProcessHeaderContainer,
        {
          backgroundColor: themeColors.background
        }
      ]}>
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            {backVisible ?
              <MaterialCommunityIcons name="chevron-left" size={Sizing.x40} color={Colors.brand.grey2} onPress={goBack} />
              : <></>
            }
          </View>
          <View style={localStyles.headerTitleContainer}>
            {progressVisible ?
              <ProcessHeaderTitle>{processTitle}</ProcessHeaderTitle>
              : <></>
            }
          </View>
          <View style={localStyles.headerIconContainer}>
            {
              dismissVisible
                ? <MaterialCommunityIcons name="close" size={Sizing.x30} color={Colors.brand.grey2} onPress={handleClose} />
                : <></>
            }
            
          </View>
        </View>
        <View style={localStyles.progressBarContainer}>
          { progressVisible ?
            <ProgressBar
              color={themeColors.progressBar}
              style={[
                localStyles.headerProgressBar,
              {
                backgroundColor: themeColors.progressBarBackground,
              }
            ]}
              progress={processProgress?.progress}
            />
            : <></>
          }
        </View>
      </View>
      {
        preventDisable ? <></> :
          <>
            <Modal visible={!modalHeaderActive} dismissable={false}><></></Modal>
          </>
      }
      <ConfirmationDialog
        visible={dialogVisible}
        // title={confirmation?.dialogTitle}
        content={dismissConfirmationMessage || 'Are you sure you want to cancel this process?'}
        onCancel={hideDialog}
        onConfirm={props.onDismiss}
      />
    </>
  )
}

const localStyles = StyleSheet.create({
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    ...Flex.column.center,
    width: Sizing.x50,
    height: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    height: Sizing.x50,
    ...Flex.column.center,
    flex: 1,
    paddingHorizontal: Sizing.x20,
  },
  headerProgressBar: {
    height: Sizing.x10,
    borderRadius: Sizing.x60,
  },
  progressBarContainer: {
    alignSelf: 'center',
    width: '40%',
  }
})