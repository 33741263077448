import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { JAR_NAME_ALL, JAR_NAME_GROUP } from 'lib/constants'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingAcceptSchemeInvite } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const SchemeInviteAccept_02_Choice = ({ route, navigation }) => {
  const { nextScreen, schemeInvite, client }: { nextScreen: string, schemeInvite: PublicGroupSchemeInviteDto, client: ClientMeDto }  = route?.params || {}

  const { groupScheme } = schemeInvite || {}
  const { organizationName } = groupScheme || {}
  
  const verifiedNationalInsuranceNo = client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)

  //Setup form
  const formObj = useForm<{ accept: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      accept: undefined,
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    setShowDialog(false)
    const { accept } = attributes
    dispatch(updateWorkingAcceptSchemeInvite({
      accept,
      nationalInsuranceNo: verifiedNationalInsuranceNo
    }))

    if (accept && !verifiedNationalInsuranceNo) {
      navigation.navigate('Verify')
    } else {
      navigation.navigate('Results')
    }
  }

  const acceptOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes, let's do it`,
    },
    {
      value: false,
      label: `No thanks`,
    },
  ]

  const accept = watch('accept')

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={accept === undefined ? 'Please Make A Choice' : accept ? 'Accept Invite' : 'Decline Invite'}
      buttonAction={() => setShowDialog(true)}
      enableButton={isValid}
      headline={`Accept the invitation?`}
      subHeading={`By accepting, we'll send a request back to ${organizationName} to get you enrolled`}
    >
      <ManagedSimpleChoiceInput
        name={'accept'}
        formObj={formObj}
        options={acceptOptions}
        required={true}
      />
      <AppIllustration filename={'info.png'} style={{
        width: Sizing.x80,
        height: Sizing.x80,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      <Subheading>{`Once enrolled, contributions from this employer will flow into your ${JAR_NAME_GROUP}.`}</Subheading>
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Please confirm'}
        content={accept
          ? `I want ${organizationName} to enrol me in the workplace scheme and start making contributions to my ${JAR_NAME_GROUP}.`
          : `I don't want to join the scheme at this time. I understand that my employer may still enrol me due to the UK government's Automatic Enrolment pension legislation.`
        }
        onConfirm={handleSubmit(onSubmit)}
      />
    </ProcessScreen>
  )
}
