import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useGetRetirementAssetQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_32_TracingReference = ({ route, navigation }) => {
  const { nextScreen, asset }: { nextScreen: string, asset: RetirementAssetDto }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)

  const [updateAssetReference, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = latestAssetError || updateAssetError
  const isLoading = latestAssetIsLoading || latestAssetIsFetching || updateAssetIsLoading

  const alreadyHadReference = latestAsset?.externalProviderReference

  //Setup form
  const formObj = useForm<{ externalProviderReference: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      externalProviderReference: workingRetirementAssetData?.externalProviderReference || asset?.externalProviderReference,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: true
    }))
    //Skip save if no changed...
    if (asset?.externalProviderReference === attributes?.externalProviderReference) {
      //PA-2009 Analytics
      ampli.transferUsage({
        processStep: 'prep_tracing_reference',
        isRetransfer: false,
        outcome: 'no_change',
        retirementAssetId: asset?.id
      })
      end()
    } else {
      //PA-2009 Analytics
      ampli.transferUsage({
        processStep: 'prep_tracing_reference',
        isRetransfer: false,
        outcome: 'changed',
        retirementAssetId: asset?.id
      })
      
      Logger.info(`Updating asset reference...`)
      updateAssetReference({
        id: asset?.id,
        externalProviderReference: attributes?.externalProviderReference,
      })
    }
  }

  //Navigate on save result
  useEffect(() => {
    if (updatedAsset) {
      end()
    }
  }, [updatedAsset])

  const handleSkip = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: true
    }))
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_tracing_reference',
      isRetransfer: false,
      outcome: 'no_value',
      retirementAssetId: asset?.id
    })
    end()
  }

  const end = () => {
    navigation.navigate('Result')
  }

  const externalProviderReference = watch('externalProviderReference')
  
  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : undefined}
      error={error}
      errorTryAgain={refetchLatestAsset}
      buttonTitle={alreadyHadReference
        ? `Confirm & Finish`
        : `Finish`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={alreadyHadReference
          ? 'Is your pension reference correct?'
          : `Do you know your pension reference?`
      }
      subHeading={`This could help speed up the search for your pension`
      }
      allowTextButton={!externalProviderReference}
      textButtonTitle={`I don't know it`}
      textButtonAction={handleSkip}
    >
      <ManagedTextInput
        name={'externalProviderReference'}
        autoFocus={false}
        formObj={formObj}
        returnKeyType={'next'}
        placeholder={'Enter reference'}
        autoCapitalize={'characters'}
        blurOnSubmit={true}
        informationMessage={`Typically 6-16 characters long, and could contain numbers and/or letters, sometimes with separating dashes or slashes`}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ModalProcessScreen>
  )
}
