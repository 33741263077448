import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { filterStatementMessages } from 'lib/messageHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetMessagesQuery } from 'store/apiSlice'
import { MessageDto, MessageQueryDto, MessageStatus } from 'store/dto/message.dto'
import { MessageInfoModal } from './MessageInfoModal'
import { MessageListItem } from './MessageListItem'
interface ReturnType {
  title: string,
  data: MessageDto[]
}

type MessageListProps = {
  messagesQuery: MessageQueryDto
}

export const MessageList = (props: MessageListProps) => {
  const { messagesQuery } = props || {}
  const { accountId } = messagesQuery || {}

  const { data: messages, isLoading: messagesIsLoading, isFetching: messagesIsFetching, error: messagesError, refetch } = useGetMessagesQuery(messagesQuery || {})
  const [refreshing, setRefreshing] = useState(false)

  const isLoading = messagesIsLoading || messagesIsFetching
  const error: any = messagesError

  const [modalItem, setModalItem] = useState(undefined)

  const normalMessages = filterStatementMessages(messages, true)

  const messageList = (): ReturnType[] => {
    let unread = []
    let read = []
    let archived = []

    if (normalMessages) {
      normalMessages.map((data, id) => {
        if (data.status === MessageStatus.ARCHIVED) {
          archived.push(data)
        } else if (data.status === MessageStatus.READ) {
          read.push(data)
        } else {
          unread.push(data)
        }
      })
      
      return [
        { title: 'Unread', data: unread },
        { title: 'Read', data: read },
        { title: 'Archive', data: archived },
      ]
    }
    return []
  }
  
  const Item = ({ item }) => {
    return (
      <MessageListItem
        message={item}
        onPressFunction={() => setModalItem(item)}
      />
    )
  }

  const onRefresh = async () => {
    await refreshUsingFunctions(
      [refetch],
      refreshing,
      setRefreshing,
    )
  }

  return (
    <>
      {
        isLoading
          ? <Loading  message={getLookingLoadingMessages('Loading your documents...')} messageNoLoop={true} />
          : error ? <ErrorScreen error={error?.data} errorTryAgain={onRefresh} /> :
        <>
          <View style={{ flex: 1 }} >
            <OptionalSectionList
              sections={messageList()}
              renderItem={({item})=><Item item={item}/>}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`You haven't yet received any documents ${accountId ? `for this jar.` : `.`}`}
            />
          </View>
          { modalItem ?
            <Portal>
              <MessageInfoModal
                visible={!!modalItem}
                message={modalItem}
                onDismiss={() => setModalItem(undefined)}
              />
            </Portal>
            : <></>
          }
        </>
      }
    </>
  )
}
