import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers/guidance.context'
import React from 'react'
import { useGetRetirementAssetsQuery } from 'store/apiSlice'

export const RetirementAssetSetupHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: assets, isLoading, error } = useGetRetirementAssetsQuery()

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = assets?.length ? [
    {
      illustrationFilename: 'capture_old_pensions.png',
      headline: `Great work!`,
      subHeading: `With your old pensions and savings recorded, Jarvis can give you the complete financial picture`,
    },
    {
      illustrationFilename: 'financial_forecasting.png',
      headline: `Financial Forecasting`,
      subHeading: `We'll take into account all retirement assets when building your personalised financial forecasts`,
    },
    {
      illustrationFilename: 'consolidate.png',
      headline: `Consolidate when you're ready`,
      subHeading: `By transferring your old pensions to Jarvis, you'll be able to simplify your retirement planning`,
    },
  ] : [
    {
      illustrationFilename: 'capture_old_pensions.png',
      headline: `Another time maybe`,
      subHeading: `If you later remember about old pensions, recording them in Jarvis gives you the complete picture`,
    },
    {
      illustrationFilename: 'financial_forecasting.png',
      headline: `Financial Forecasting`,
      subHeading: `Jarvis can take into account all retirement assets to build your personalised financial forecast`,
    },
    {
      illustrationFilename: 'consolidate.png',
      headline: `Easy consolidation`,
      subHeading: `Once you've recorded old pensions, it's easy to consolidate them into your ${JAR_NAME_PERSONAL}`,
    },

  ]

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      error={!!error}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}

    />
  )
}
