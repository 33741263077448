import { envVariables } from 'environment'
import { keys } from 'lodash'
import { AppContext, LinkParams, LoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'

export const createLink = (base: string, path?: string, queryParams?: Object) => {
  const url = path ? new URL(path, base) : new URL(base)
  if (queryParams) {
    keys(queryParams).forEach(key => {
      const value = queryParams[key]
      if (value !== undefined && value !== null) {
        url.searchParams.append(key, value)
      }
    })
  }
  return url.href
}

export const createBranchLink = (path: string, queryParams?: Object) => {
  const { appStoreUrls } = envVariables
  const base = appStoreUrls.branch
  return createLink(base, path, queryParams)
}

export const createBranchLinkForLinkParams = (linkParams: LinkParams) => {
  const {
    preferredInviteId,
    preferredSchemeInviteId,
    preferredSchemeEnrolmentId,
    affiliateCode,
  } = linkParams || {}

  let path = 'download'
  if (preferredInviteId) {
    path = 'partner-invite'
  } else if (preferredSchemeInviteId) {
    path = 'scheme-invite'
  } else if (preferredSchemeEnrolmentId) {
    path = 'scheme-enrolment'
  } else if (affiliateCode) {
    path = 'affiliate'
  }

  return createBranchLink(path, {
    ...linkParams,
    '~channel': 'web_app',
  })
}

export const createBranchHandOffLink = (autoLogin: string, authType: AUTHTYPE, linkParams: LinkParams) => {
  const linkConfig = linkParams || {}
  return createBranchLink('login', {
    ...linkConfig,
    autoLogin,
    authType,
  })
}

