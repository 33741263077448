import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import React from 'react'
import { Image, View } from "react-native"
import { ContributionSource } from "store/dto/base.dto"
import { PensionProviderDto } from "store/dto/pension-provider.dto"
import { RetirementAssetDto, RetirementAssetType } from "store/dto/retirement-asset.dto"
import { Sizing, Typography } from "styles"
import { Button } from "./Button"
import { format } from "date-fns"
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from "lib/constants"
import { ContentDivider } from "components/Layout/ContentDivider"

type RetirementAssetTransferModalContentProps = {
  asset: RetirementAssetDto
  provider: PensionProviderDto
  imageSource: any
  isSelected: boolean
  editAssetAction: any
}


export const RetirementAssetTransferModalContent = (props: RetirementAssetTransferModalContentProps) => {
  const { asset, provider, imageSource, isSelected, editAssetAction } = props
  const { name, currentValue, externalProviderReference, monthlyContributionAmount, contributionSource } = asset

  const tracingRequested = asset?.tracingRequested
  const isWorkplace = asset.assetType === RetirementAssetType.WORKPLACE

  return (
    <>
      <View style={{
        alignSelf: 'center',
        paddingVertical: Sizing.x20,
      }}>
        <Image source={imageSource} style={{
          width: scaleNormalizer(100),
          height: scaleNormalizer(100),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Headline>{name}</Headline>
      <Subheading>{`Estimated Value: ${formatCurrencyAmount(currentValue)}`}</Subheading>
    <ContentDivider />    
      <Paragraph>{tracingRequested
        ? isWorkplace
          ? `You told us you worked at ${asset?.employerName} between ${format(new Date(asset?.employerStartDate), 'yyyy')} and ${format(new Date(asset?.employerEndDate), 'yyyy')}`
          : `You told us your pension is with ${asset?.pensionProviderBrandName} and ${asset?.externalProviderReference ? `you don't know your pension reference`: `your pension reference is ${asset?.externalProviderReference}`}`
        : `Pension is currently provided by ${provider?.name || 'an unrecognised provider'} with reference number ${externalProviderReference}`}</Paragraph>
      {
        monthlyContributionAmount ?
        <>
          <Paragraph>{`You are currently contributing ${formatCurrencyAmount(monthlyContributionAmount, 2)} a month from your ${contributionSource === ContributionSource.PERSONAL ? 'personal savings' : 'limited company'}.`}</Paragraph>
          <Paragraph>{`If you transfer this pension, we will give you the opportunity to set up the equivalent contributions to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
        </>
        : <></>
      }
      <ContentDivider />
      <Subheading style={Typography.fontWeight.bold} >{
        isSelected
          ? tracingRequested
            ? 'Ready and selected for finding'
            : 'Ready and selected for transfer'
          : tracingRequested
            ? 'Not selected for finding'
            : 'Not selected for transfer'
      }</Subheading>
      <Button mode='text' onPress={editAssetAction}>{`Make adjustments`}</Button>
    </>
  )
}

