import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import 'react-native-get-random-values'; //this must always be before importing uuid
import { useGetMeQuery, useLazyGetContributionsIllustrationQuery } from 'store/apiSlice'
import { useAppSelector } from 'store/hooks'
import { workingContributionPayment } from 'store/tempDataSlice'
import { Sizing } from 'styles'


export const MakeContributions_24_RegularCostDisclosure = ({ route, navigation }) => {
  const { nextScreen, onDismiss } = route?.params || {}
  
  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()
  const [getContributionsIllustration, { data: illustration, error: illustrationError, isLoading: illustrationIsLoading, isFetching: scenariosIsFetching }] = useLazyGetContributionsIllustrationQuery()

  const isLoading = illustrationIsLoading || clientIsLoading
  const error: any = illustrationError || clientError

  const workingContributionPaymentData = useAppSelector(workingContributionPayment)
  
  const { segment } = client || {}
  const { isPromotion, platformChargeDiscount, name } = segment || {}
  
  //Get illustration on load
  useEffect(() => {
    getIllustration()
  }, [])

  const getIllustration = () => {
    getContributionsIllustration({
      investmentAmount: workingContributionPaymentData?.amount
    }, true)
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const years = (illustration?.illustrationPeriodMonths || 0) / 12
  const tableData: UnborderedTableRow[] = illustration ? [
    {
      label: 'Invested Amount (First Month)',
      value: formatCurrencyAmount(workingContributionPaymentData?.amount || 0, 2),
      isTotal: true,
    },
    {
      label: `Growth over ${years} years`,
      value: formatCurrencyAmount(illustration?.totalGrowth || 0, 2),
      isTotal: true,
    },
    {
      label: 'Initial Charges',
      value: `-${formatCurrencyAmount(illustration?.totalInitialCharges || 0, 2)}`,
    },
    {
      label: `Ongoing Charges${isPromotion ? ' *' : ''}`,
      value: `-${formatCurrencyAmount(illustration?.totalOngoingCharges || 0, 2)}`,
    },
    {
      label: `Total Charges over ${years} years${isPromotion ? ' *' : ''}`,
      value: `-${formatCurrencyAmount(illustration?.totalCharges || 0, 2)}`,
      isTotal: true,
    },
    {
      label: `Final Amount after ${years} years`,
      value: formatCurrencyAmount(illustration?.finalAmount || 0, 2),
      isTotal: true,
    },
  ] : []

  const chargesPercent = illustration?.totalChargesPercent ? illustration?.totalChargesPercent * 100 : 0

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={clientError ? refetchClient : getIllustration}
      errorCancel={() => navigation.goBack()}
      isLoading={isLoading}
      loadingMessage={['Getting your illustration...']}
      buttonTitle={'Accept'}
      buttonAction={next}
      enableButton={true}
      headline={`It's important to understand costs`}
      subHeading={`When contributing, we always show you illustrative charges`}
      showButton={true}>
        <Paragraph style={{ textAlign: 'left' }}>{``}
          <Paragraph>{`The below illustration is for a `}</Paragraph>
          <Paragraph style={{ fontWeight: '900' }}>{`single month`}</Paragraph>
          <Paragraph>{` over a ${years}-year period, with an assumed growth rate of ${formatPercentageAmount((illustration?.illustrationGrowthRate || 0) * 100, 0)} and an overall charge of ${formatPercentageAmount(chargesPercent, 2)} (circa ${formatPercentageAmount(chargesPercent / years, 2)} p.a.)${isPromotion ? ' *' : ''}`}</Paragraph>
        </Paragraph>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          isLoading={isLoading}
          error={error}
          data={tableData}
        />
        {
          isPromotion
            ? <Paragraph style={localStyles.smallText}>{`* We always show illustrations using our standard changes. As a member of the ${name}, you receive a ${formatPercentageAmount(platformChargeDiscount * 100)} discount on these illustrative charges.`}</Paragraph>
            : <></>
        }
    </ModalProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  smallText: {
    textAlign: 'left',
    fontSize: Sizing.x12,
  },
})