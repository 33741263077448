import { JAR_NAME_PERSONAL } from 'lib/constants'
import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { PersonalPensionScreen } from './Screens/PersonalPensionScreen'

export const PersonalPensionSectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={JAR_NAME_PERSONAL}
    >
      <PersonalPensionScreen />
    </SectionSimpleContainer>
  )
}