/*****************************
 * environment.ts
 * path: '/environment.ts'
 ******************************/

//NOTE - you cannot use Logger (lib/logger.ts) in this file as
//the logger itself uses this environment file, which creates
//a dependency loop, resulting in immediate app crash

import * as Updates from 'expo-updates'
//@ts-ignore
import { EXPO_RELEASE_CHANNEL, SOURCE_VERSION, LOCAL_DEV_APP_URL, LOCAL_DEV_API_URL, APP_ENV } from '@env'

export enum Environment {
  LOCAL = 'local',
  PROVE = 'prove',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface BaseEnvConfig {
  commitIdentifier: string,
  //Support
  supportUrl: string
  supportEmail: string,
  //URL/Docs
  marketingUrl: string
  termsUrl: string
  privacyUrl: string
  personalPensionTermsUrl: string
  personalPensionKeyFeaturesUrl: string
  personalPensionMemberDeclarationUrl: string
  groupPensionTermsUrl: string,
  groupPensionpersonalPensionKeyFeaturesUrl: string,
  pensionFindingTermsUrl: string
  moneyhubTermsUrl: string
  moneyhubPrivacyUrl: string
  raindropPrivacyUrl: string
  genericPensionIllustrationUrl: string
  sentryDsn: string
  auth0Scopes: string
  disableBiometrics: boolean
  cdnDomain: string
}
export interface EnvConfig extends BaseEnvConfig {
  //Descriptor
  envName: Environment,
  //Frontend
  appUrl: string,
  //Backend
  apiUrl: string,
  //Auth0
  auth0ClientId: string
  auth0Domain: string
  auth0Audience: string
  auth0CustomScheme: string
  //App Stores
  appStoreUrls: {
    android: string
    ios: string
    branch: string
  }
  //SECCL
  secclFirmId: string
  sentryEnabled: boolean
  //Logging
  minimumLogSeverity: 'debug' | 'info' | 'warn' | 'error',
  //Amplitude
  analyticsEnabled: boolean
  smartSearchDashboardUrl: string
  //Auth
}

//Config that applies to all environments
const baseEnvConfig: BaseEnvConfig = {
  commitIdentifier: SOURCE_VERSION,
  supportUrl: 'https://docs.getjarvis.app',
  supportEmail: 'support@pensionjar.com',
  marketingUrl: 'https://getjarvis.app',
  termsUrl: 'https://cdn.getjarvis.app/docs/general/Jarvis-Terms.pdf',
  privacyUrl: 'https://getjarvis.app/privacy',
  personalPensionTermsUrl: 'https://cdn.getjarvis.app/docs/sipp/Jarvis-SIPP-Terms.pdf',
  personalPensionKeyFeaturesUrl: 'https://cdn.getjarvis.app/docs/sipp/Jarvis-SIPP-KFD.pdf',
  personalPensionMemberDeclarationUrl: 'https://cdn.getjarvis.app/docs/sipp/Jarvis-SIPP-Declaration.pdf',
  genericPensionIllustrationUrl: 'https://cdn.getjarvis.app/docs/illustrations/Jarvis-Pension-Illustration.pdf',
  groupPensionTermsUrl: 'https://cdn.getjarvis.app/docs/gsipp/Jarvis-GSIPP-Terms.pdf',
  groupPensionpersonalPensionKeyFeaturesUrl: 'https://cdn.getjarvis.app/docs/gsipp/Jarvis-GSIPP-KFD.pdf',
  pensionFindingTermsUrl: 'https://cdn.getjarvis.app/docs/transfers/Jarvis-Pension-Finding-Terms.pdf',
  moneyhubTermsUrl: 'https://www.moneyhub.com/terms-of-use',
  moneyhubPrivacyUrl: 'https://www.moneyhub.com/privacy-policy-and-cookies',
  raindropPrivacyUrl: 'https://www.myraindrop.co.uk/privacy-policy',
  sentryDsn: 'https://478b326913cf40a3af307ddf2b29e0b2@o1330630.ingest.sentry.io/4504485400543232',
  auth0Scopes: 'openid profile offline_access email',
  disableBiometrics: false,
  cdnDomain: 'https://cdn.getjarvis.app',
}

const environments: EnvConfig[] = [
  {
    envName: Environment.LOCAL,
    ...baseEnvConfig,
    disableBiometrics: false, //set false to test locally 
    auth0ClientId: 'LFi1MZQxXQW4Y1vMhEOXN7Sy11naYTcF',
    auth0Domain: 'pensionjar-development.eu.auth0.com', // without the https://
    auth0Audience: LOCAL_DEV_APP_URL,
    auth0CustomScheme: 'com.pensionjar.getjarvis.auth.dev',
    appUrl: LOCAL_DEV_APP_URL,
    apiUrl: LOCAL_DEV_API_URL,
    appStoreUrls: {
      android: 'https://play.google.com/store/apps/details?id=dev.getjarvis.app',
      ios: 'https://beta.itunes.apple.com/v1/app/1663505797',
      branch: 'https://linkdev.getjarvis.dev'
    },
    supportEmail: 'support@pensionjar.com',
    secclFirmId: 'PJLOC',
    sentryEnabled: true,
    minimumLogSeverity: 'debug',
    // analyticsEnabled: false,
    analyticsEnabled: true,
    smartSearchDashboardUrl: 'https://sandbox.app.smartsearch.com',
  },
  {
    envName: Environment.PROVE,
    ...baseEnvConfig,
    auth0ClientId: 'LFi1MZQxXQW4Y1vMhEOXN7Sy11naYTcF',
    auth0Domain: 'pensionjar-development.eu.auth0.com', // without the https://
    auth0Audience: 'https://appdev.getjarvis.dev',
    auth0CustomScheme: 'com.pensionjar.getjarvis.auth.prove',
    appUrl: 'https://appdev.getjarvis.dev',
    apiUrl: 'https://apidev.getjarvis.dev',
    appStoreUrls: {
      android: 'https://play.google.com/apps/internaltest/4701096925461229842',
      ios: 'https://beta.itunes.apple.com/v1/app/6451263905',
      branch: 'https://linkdev.getjarvis.dev'
    },
    supportEmail: 'support@pensionjar.com',
    secclFirmId: 'PJDV1',
    sentryEnabled: true,
    minimumLogSeverity: 'debug',
    analyticsEnabled: false,
    smartSearchDashboardUrl: 'https://sandbox.app.smartsearch.com',
  },
  {
    envName: Environment.STAGING,
    ...baseEnvConfig,
    auth0ClientId: 'PAQK5rFTPu2jdg2rSM4I0Nwjcwk8XWkI',
    auth0Domain: 'pensionjar-staging.eu.auth0.com', // without the https://
    auth0Audience: 'https://getjarvis.dev',
    auth0CustomScheme: 'com.pensionjar.getjarvis.auth.stag',
    appUrl: 'https://app.getjarvis.dev',
    apiUrl: 'https://api.getjarvis.dev',
    appStoreUrls: {
      android: 'https://play.google.com/store/apps/details?id=dev.getjarvis.app',
      ios: 'https://beta.itunes.apple.com/v1/app/1663505797',
      branch: 'https://link.getjarvis.dev'
    },
    secclFirmId: 'JARVS',
    sentryEnabled: true,
    minimumLogSeverity: 'info',
    analyticsEnabled: true,
    smartSearchDashboardUrl: 'https://sandbox.app.smartsearch.com',
  },
  {
    envName: Environment.PRODUCTION,
    ...baseEnvConfig,
    auth0ClientId: 'kIdlBeFsNdbMUSWl3cFVaY62aRXXVsr2',
    auth0Domain: 'auth.getjarvis.app', // without the https://
    auth0Audience: 'https://getjarvis.app',
    auth0CustomScheme: 'com.pensionjar.getjarvis.auth.prod',
    appUrl: 'https://app.getjarvis.app',
    apiUrl: 'https://api.getjarvis.app',
    appStoreUrls: {
      android: 'https://play.google.com/store/apps/details?id=app.getjarvis.app',
      ios: 'https://apps.apple.com/gb/app/jarvis-lifetime-pension/id1663505362',
      branch: 'https://link.getjarvis.app'
    },
    secclFirmId: 'JARVS',
    sentryEnabled: true,
    minimumLogSeverity: 'info',
    analyticsEnabled: true,
    smartSearchDashboardUrl: 'https://app.smartsearch.com',
  },
]

const selectEnvironment = (channel: Environment, defaultChannel: Environment): EnvConfig => {
  let selected = environments.find(environment => {
    return environment.envName === channel
  })
  if (selected) {
    return selected
  }

  //Fallback to defaultChannel
  selected = environments.find(environment => {
    return environment.envName === defaultChannel
  })
  if (selected) {
    return selected
  }

  throw new Error(`Could not find default environment: ${defaultChannel}`)
}

const buildWorkingEnvVars = () => {
  let channel = Environment.LOCAL

  //Use from .env if available
  if (EXPO_RELEASE_CHANNEL) {
    //Use release channel from local env
    channel = EXPO_RELEASE_CHANNEL
  }

  //Use managed EAS managed channel if available
  const easManagedChannel = Updates.channel
  if (easManagedChannel && easManagedChannel !== 'default') {
    if (easManagedChannel.startsWith('production')) {
      channel = Environment.PRODUCTION
    } else if (easManagedChannel.startsWith('staging')) {
      channel = Environment.STAGING
    } else if (easManagedChannel.startsWith('prove')) {
      channel = Environment.PROVE
    }
  }

  //Select the env (or default to production)
  let workingEnv = selectEnvironment(channel, Environment.PRODUCTION)
  console.log({ workingEnv }, 'Working environment')

  return workingEnv
}


export const envVariables = buildWorkingEnvVars()