import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { truncate } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetAssetCategoriesQuery, useGetMeQuery } from 'store/apiSlice'
import { AssetCategoryDto } from 'store/dto/asset-category.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAssetAllocations, workingAssetAllocations } from 'store/tempDataSlice'

export const InvestmentAssetAdd_01_CategoryChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  let options: ManagedCardChoiceInputOption[] = []
  const dispatch = useAppDispatch()
  const workingAssetAllocationsData = useAppSelector(workingAssetAllocations)
  
  const { data: assetCategories, isLoading: assetCategoriesIsLoading, error: assetCategoriesError, refetch: refetchAssetCategories } = useGetAssetCategoriesQuery()
  const isLoading = assetCategoriesIsLoading
  const error: any = assetCategoriesError

  //Setup form
  const formObj = useForm<{ assetCategoryId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      assetCategoryId: workingAssetAllocationsData?.assetCategoryId
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingAssetAllocations(attributes))
    next()
  }

  const showAll = () => {
    dispatch(updateWorkingAssetAllocations({
      assetCategoryId: null
    }))
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const buildOptions = (assetCategories: AssetCategoryDto[]) => {

    options = assetCategories.map(category => {
      return {
        value: category.id,
        title: category.name,
        description: truncate(category.shortDescription, { length: 120 }),
        illustrationFilename: category.logo ? undefined : 'capture_old_pensions.png',
        imageSource: category.logo ? { uri: category.logo } : undefined,
      }
    })
  }

  //Extract plans when available
  if (assetCategories) {
    buildOptions(assetCategories)
  }

  const selectedCategoryId = watch('assetCategoryId')

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Retrieving options...']}
      error={error}
      errorTryAgain={refetchAssetCategories}
      buttonTitle={selectedCategoryId ? 'Next' : 'Make Selection'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What type of fund are you looking for?`}
      subHeading={`Selecting a category will show you our available funds`}
      allowTextButton={true}
      textButtonTitle={'Show me all funds'}
      textButtonAction={showAll}
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'assetCategoryId'}
        required={true}
        submitHandler={handleSubmit(onSubmit)}
        options={options}
      />
    </ModalProcessScreen>
  )
}
