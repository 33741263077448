import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { ClientAnyDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'

export const RetirementIncomesAddStatePension_99_Result = ({ navigation, route }) => {
  const { nextScreen, onDismiss, client, statePensionAmount }: { nextScreen: string, onDismiss: any, client: ClientAnyDto, statePensionAmount: number }  = route?.params || {}

  const [ updateMe, { data: me, error: meUpdateError, isLoading: meUpdateIsLoading, reset: meUpdateReset } ] = useUpdateMeMutation()
  const [ updateSpouse, { data: spouse, error: spouseUpdateError, isLoading: spouseUpdateIsLoading, reset: spouseUpdateReset } ] = useUpdateSpouseMutation()
  const error: any = meUpdateError || spouseUpdateError
  const isLoading = meUpdateIsLoading || spouseUpdateIsLoading
  const result = me || spouse

  const close = () => {    
    onDismiss()
  }

  //Save on enter
  useEffect(() => {
    saveSp()
  }, [])

  const saveSp = () => {
    const payload = {
      statePensionAmount
    }
    if (client?.isSpouse) {
      Logger.info(`Updating spouse...`)
      updateSpouse(payload)
    } else {
      Logger.info(`Updating client...`)  
      updateMe(payload)
    }
  }

  //Close on save
  useEffect(() => {
    if (result) {
      ampli.statePensionSet({
        amount: spouse ? spouse?.statePensionAmount : me?.statePensionAmount,
        isSpouse: spouse ? true : false,
      })
      close()
    }
  }, [result])

  return (
    <ModalProcessResultScreen
      headline={``}
      buttonTitle={'Continue'}
      error={error}
      errorTryAgain={spouseUpdateError || meUpdateError ? saveSp : undefined}
      errorCancel={
        spouseUpdateError ? spouseUpdateReset
        : meUpdateError ? meUpdateReset
        : () => navigation.gotBack() 
      }
      isLoading={isLoading}
      loadingMessage={['Saving...']}
      buttonAction={close}
      showButton={false}
    >
    </ModalProcessResultScreen>
  )
}
