import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Headline, Subheading } from 'components/Typography'
import { SegmentBadge } from 'components/Typography/SegmentBadge'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { ShareCard } from 'components/Utility/ShareCard'
import { goToLogout } from 'lib/RootNavigation'
import { scaleNormalizer } from 'lib/scaleHelpers'
import React from 'react'
import { Image, Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useGetCurrentUserQuery, useGetMeQuery } from 'store/apiSlice'
import { setEditAvatarClient, setEditCurrentUserVisible, setEditMeVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const ProfileScreen = () => {

  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const refetchAll = () => {
    refetchUser()
    refetchMe()
  }

  const error: any = userError || meError
  const isLoading = userIsLoading || meIsLoading

  const dispatch = useDispatch()

  // const toggleBeta = () => {
  //   updateUser({
  //     isBeta: !user?.isBeta
  //   })
  // }

  return (
    <>
      <MainAppScreen>
        {isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
          <View style={[
            layoutStyles.mainContentContainer
            ]} >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              <View style={localStyles.profileImageContainer}>
                <Pressable onPress={() => dispatch(setEditAvatarClient(me))}>
                  <Image
                    source={{ uri: me?.avatar || me?.picture }}
                    style={localStyles.profileImage}
                  />
                </Pressable>
                {me?.segment?.isPromotion ?
                <SegmentBadge
                  segment={me?.segment}
                  containerStyle={{
                    position: 'absolute',
                    alignSelf: 'center',
                    bottom: 10,
                  }}
                  style={{
                    fontSize: Sizing.x15,
                  }}
                />
                : <></>
              }
              </View>
              <Headline>{`${me?.firstName} ${me?.surname}`}</Headline>
              <Subheading>{`${user.email}`}</Subheading>

              <View style={localStyles.buttonContainer}>
                <Button
                  mode='text'
                  color={Colors.brand.red3}
                  onPress={() => dispatch(setEditMeVisible(true))}>
                  {'Change Details'}
                </Button>
              </View>

              <ShareCard user={user} campaign={'profile_screen'}/>
              {/* <View style={localStyles.profileSection}>
                <Subheading>Beta Functionality</Subheading>
                {user?.isBeta ?
                  <View>
                    <Paragraph>{`Thanks for being a part of our Beta program!`}</Paragraph>
                    <Paragraph>{`We really appreciate your help in bringing new features to Jarvis`}</Paragraph>

                  </View>
                  :
                  <View>
                    <Paragraph>{`Get access to our newest features before everyone else!`}</Paragraph>
                    <Paragraph>{`Please be aware that Beta features may be unstable, and in some cases only partially functional`}</Paragraph>
                  </View>
                }
                <Button
                  mode='text'
                  color={Colors.brand.red3}
                  onPress={toggleBeta}
                  confirmation={{
                    requiresConfirmation: true,
                    dialogContent: user?.isBeta ? 'I want to disable Beta Features.' : 'I understand the risks and want to enable Beta Features.'
                  }}
                >
                  {user?.isBeta ? `Disable Beta Features` : `Enable Beta Features`}
                </Button>
              </View> */}

            </ScrollView>
            <FooterButton
              mode='text'
              color={Colors.brand.red3}
              onPress={() => dispatch(setEditCurrentUserVisible(true))}>
              {'Account Settings'}
            </FooterButton>
            <FooterButton
              onPress={goToLogout}
            >{'Logout'}</FooterButton>
          </View>

        }
      </MainAppScreen>
    </>
  )
}

const localStyles = StyleSheet.create({
  profileSection: {
    paddingTop: Sizing.x10,
  },
  profileImageContainer: {
    paddingVertical: Sizing.x30,
  },
  profileImage: {
    alignSelf: 'center',
    height: scaleNormalizer(200),
    width: scaleNormalizer(200),
    borderRadius: scaleNormalizer(100),
  },
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
})