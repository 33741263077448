import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import { goToLogout } from "lib/RootNavigation"
import { scaleNormalizer } from "lib/scaleHelpers"
import React from 'react'
import { Image, ScrollView, StyleSheet, View } from "react-native"
import { UserDto } from "store/dto/user.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { Button } from "./Button"
import { platformIsWeb } from "lib/platformHelpers"

type UserInfoModalProps = {
  user: UserDto
  visible: boolean
  onDismiss: any
}

const isWeb = platformIsWeb()

export const UserInfoModal = (props: UserInfoModalProps) => {
  const { user, onDismiss, visible } = props

  const handleLogout = () => {
    onDismiss()
    goToLogout()
  }

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View style={localStyles.profileImageContainer}>
              <Image
                source={{ uri: user?.picture }}
                style={localStyles.profileImage}
              />
            </View>
            <Headline>{`${user?.metadata?.firstName} ${user?.metadata?.surname}`}</Headline>
            <Subheading>{`${user?.email}`}</Subheading>
            <Button
              mode={'text'}
              onPress={handleLogout}
              icon={() => <MaterialCommunityIcons name={'logout-variant'} size={Sizing.x20} color={Colors.brand.purple1} />}
            >
              {'Logout'}
            </Button>
          </ScrollView>

          <FooterButton mode={'text'} onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  profileImageContainer: {
    paddingVertical: Sizing.x30,
  },
  profileImage: {
    alignSelf: 'center',
    height: scaleNormalizer(200),
    width: scaleNormalizer(200),
    borderRadius: scaleNormalizer(100),
  },
})