import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModelGoalIdentifier, OnboardingPhase, SurplusStrategy } from 'providers'
import { RootState } from 'store'
import { AssetAllocationDto, CreateContributionDto, GroupSchemeEnrolmentContributionForecastTreatment, GroupSchemeRequestType, Nomination, OptOutSchemeEnrolmentDto, RegularContribution, VerifySchemeEnrolmentPreviousPensionDto } from './dto/account.dto'
import { Address, ContributionSource } from './dto/base.dto'
import { CalculatorModelRequestDto, CalculatorTimelineDto } from './dto/calculator.dto'
import { ContributionConfiguration, CreateClientMeDto, CreateSpouseDto } from './dto/client.dto'
import { AcceptInviteDto, CreateInviteDto } from './dto/invite.dto'
import { ModelRepresentationTerm } from './dto/model.dto'
import { PensionProviderDto } from './dto/pension-provider.dto'
import { BudgetLevelIdentifier } from './dto/reference-data.dto'
import { BulkTransferRetirementAssetDto, CreateRetirementAssetDto, RetirementAssetSetInterestDto, UpdateRetirementAssetDto } from './dto/retirement-asset.dto'
import { CreateRetirementIncomeDto } from './dto/retirement-income.dto'
import { CreateRetirementProfileDto } from './dto/retirement-profile.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from './dto/group-scheme.dto'

export enum ContributionGuidanceType {
  GUIDED = 'GUIDED',
  MANUAL = 'MANUAL',
}

export type WorkingSuggestion = {
  contributionSource?: ContributionSource
  goal?: ModelGoalIdentifier
  guidanceType?: ContributionGuidanceType
  manualSetupSelections?: string[]
  changeRegularContributions?: boolean
  cancelRegularContributions?: boolean
  surplusStrategy?: SurplusStrategy
  proposedTargetRetirementAge?: number
  proposedRetirementAge?: number
  proposedMonthlyContributions?: number
  proposedOneOffContribution?: number
}

export type WorkingLumpSumContribution = null | Partial<CreateContributionDto> & {
  amountChoice: number | null
  manualSetup?: boolean
}

export type WorkingRegularContribution = null | Partial<RegularContribution> & {
  firstPaymentDate: string
  amountChoice: number | null
  manualSetup?: boolean
}

export type WorkingAssetAllocations = {
  existingAllocations: AssetAllocationDto[]
  newAllocation?: Partial<AssetAllocationDto>
  assetCategoryId?: string
  evenSplit?: boolean
}

export type WorkingAccount = {
  accountId: string
  accountDescriptor: string
  investmentTypeChoice?: InvestmentTypeChoice
  investmentPlanId?: string
  groupPortfolioId?: string
  assetAllocation?: AssetAllocationDto[]
  usePersonalPensionInvestmentChoice?: boolean
  investmentPlanName?: string
  groupPortfolioName?: string
  termsAgreed?: boolean
  pensionOptOut?: boolean
}

export type WorkingAcceptSchemeInvite = {
  accept: boolean
  nationalInsuranceNo: string
}

export type WorkingSchemeEnrolmentRequest = {
  requestType: GroupSchemeRequestType
  data: any
}

export type WorkingSetupSchemeEnrolment = {
  nationalInsuranceNo: string  
  contributionForecastTreatment: GroupSchemeEnrolmentContributionForecastTreatment
  customEmployerContributionAmount: number
  customPayrollFrequency: GroupSchemeEnrolmentPayrollFrequency
  requests: WorkingSchemeEnrolmentRequest[],
  previousPension: VerifySchemeEnrolmentPreviousPensionDto
}

export enum InvestmentTypeChoice {
  GROUP_PORTFOLIO = 'group_portfolio',
  PLAN = 'plan',
  ASSET_ALLOCATION = 'asset_allocation',
  FOLLOW_PERSONAL = 'follow_personal',
  UNFOLLOW_PERSONAL = 'unfollow_personal',
}

export enum StatePensionChoice {
  NONE = 'none',
  ONE = 'one',
  BOTH = 'both',
}

export type SessionState = {
  tbc: string
}

export interface TempDataState {
  workingClient: Partial<CreateClientMeDto> & {
    termsAgreed: boolean
    statePensionChoice?: 'string'
    statePensionAmount?: number
    preferredOnboardingPhase?: OnboardingPhase
  }
  workingSpouse: Partial<CreateSpouseDto> & {
    statePensionChoice?: 'string'
    statePensionAmount?: number
  }
  workingInvite: Partial<CreateInviteDto> & {
    checkDetailsChoice: boolean
  }
  workingAcceptInvite: Partial<AcceptInviteDto> & {
    choice: boolean
  }
  workingAcceptSchemeInvite: Partial<WorkingAcceptSchemeInvite>
  workingSetupSchemeEnrolment: Partial<WorkingSetupSchemeEnrolment>
  workingSchemeEnrolmentRequest: Partial<WorkingSchemeEnrolmentRequest>
  workingRetirementProfile: Partial<CreateRetirementProfileDto> & {
    copyChoice: boolean
  }
  workingAssetAllocations: Partial<WorkingAssetAllocations>
  workingAccount: Partial<WorkingAccount>
  workingIncome: Partial<CreateRetirementIncomeDto> & {
    incomeStartAgeChoice: boolean
    providerName: string
  }
  workingRetirementAsset: Partial<CreateRetirementAssetDto | UpdateRetirementAssetDto> & {
    contributionChoice: boolean
    providerName: string
    addressChoice: boolean
    transferAddress: Address
    previousNameChoice: boolean
    previousFirstName: string
    previousSurname: string
    suggestedProviders: PensionProviderDto[]
    tracingRequested?: boolean
  }
  workingRetirementAssetInterest: Partial<{
    assetIds: string[]
  }>
  workingSuggestion: WorkingSuggestion
  workingContributionSetup: Partial<ContributionConfiguration>
  workingContributionPayment: WorkingRegularContribution
  workingLumpSumContributionPayment: WorkingLumpSumContribution
  workingBeneficiary: Partial<Nomination> & {
    id?: number
    evenSplit?: boolean
  }
  workingBulkTransfer: Partial<BulkTransferRetirementAssetDto> & {
    totalTransferValue?: number
    tracingCount?: number
    tracingAssetIds?: string[]
    executionCount?: number
    executionAssetIds?: string[]
    regularContributionGrossValue?: number
  }
  workingRegistration: {
    email: string
  }
  workingCalculatorRequest: Partial<CalculatorModelRequestDto> & {
    withSpouse: boolean
    statePensionChoice: StatePensionChoice
    baseBudgetLevel: BudgetLevelIdentifier
    netBudgetTotalAmount: number
    grossBudgetTotalAmount: number
    timeline: CalculatorTimelineDto
    viableRetirementAge: number
    terms: ModelRepresentationTerm[]
    sendReport: boolean
    email: string
  }
  workingPreviousPension: VerifySchemeEnrolmentPreviousPensionDto
  workingEnrolmentOptOut: OptOutSchemeEnrolmentDto

  //Single session state
  //Used to remember state only for the current session
  sessionState: SessionState
}

export const tempDatainitialState: TempDataState = {
  workingClient: undefined,
  workingSpouse: undefined,
  workingInvite: undefined,
  workingAcceptInvite: undefined,
  workingAcceptSchemeInvite: undefined,
  workingSetupSchemeEnrolment: undefined,
  workingSchemeEnrolmentRequest: undefined,
  workingRetirementProfile: undefined,
  workingAssetAllocations: undefined,
  workingAccount: undefined,
  workingIncome: undefined,
  workingRegistration: undefined,
  workingRetirementAsset: undefined,
  workingRetirementAssetInterest: undefined,
  workingBeneficiary: undefined,
  workingSuggestion: undefined,
  workingContributionSetup: undefined,
  workingContributionPayment: undefined,
  workingLumpSumContributionPayment: undefined,
  workingBulkTransfer: undefined,
  workingCalculatorRequest: undefined,
  workingPreviousPension: undefined,
  workingEnrolmentOptOut: undefined,
  sessionState: {
    tbc: undefined,
  },
}

export const tempDataSlice = createSlice({
  name: 'tempData',
  initialState: tempDatainitialState,
  reducers: {

    updateWorkingClient: (
      state,
      action: PayloadAction<Partial<CreateClientMeDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingClient = undefined //Reset
      } else {
        state.workingClient = {
          ...state.workingClient,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingSpouse: (
      state,
      action: PayloadAction<Partial<CreateSpouseDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingSpouse = undefined //Reset
      } else {
        state.workingSpouse = {
          ...state.workingSpouse,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingInvite: (
      state,
      action: PayloadAction<Partial<CreateInviteDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingInvite = undefined //Reset
      } else {
        state.workingInvite = {
          ...state.workingInvite,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingAcceptInvite: (
      state,
      action: PayloadAction<Partial<AcceptInviteDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingAcceptInvite = undefined //Reset
      } else {
        state.workingAcceptInvite = {
          ...state.workingAcceptInvite,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingAcceptSchemeInvite: (
      state,
      action: PayloadAction<Partial<WorkingAcceptSchemeInvite>>
    ) => {
      if (action.payload === undefined) {
        state.workingAcceptSchemeInvite = undefined //Reset
      } else {
        state.workingAcceptSchemeInvite = {
          ...state.workingAcceptSchemeInvite,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingSetupSchemeEnrolment: (
      state,
      action: PayloadAction<Partial<WorkingSetupSchemeEnrolment>>
    ) => {
      if (action.payload === undefined) {
        state.workingSetupSchemeEnrolment = undefined //Reset
      } else {
        state.workingSetupSchemeEnrolment = {
          ...state.workingSetupSchemeEnrolment,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingSchemeEnrolmentRequest: (
      state,
      action: PayloadAction<Partial<WorkingSchemeEnrolmentRequest>>
    ) => {
      if (action.payload === undefined) {
        state.workingSchemeEnrolmentRequest = undefined //Reset
      } else {
        state.workingSchemeEnrolmentRequest = {
          ...state.workingSchemeEnrolmentRequest,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingRetirementProfile: (
      state,
      action: PayloadAction<Partial<CreateRetirementProfileDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingRetirementProfile = undefined //Reset
      } else {
        state.workingRetirementProfile = {
          ...state.workingRetirementProfile,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingAssetAllocations: (
      state,
      action: PayloadAction<Partial<WorkingAssetAllocations>>
    ) => {
      if (action.payload === undefined) {
        state.workingAssetAllocations = undefined //Reset
      } else {
        state.workingAssetAllocations = {
          ...state.workingAssetAllocations,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingAccount: (
      state,
      action: PayloadAction<Partial<WorkingAccount>>
    ) => {
      if (action.payload === undefined) {
        state.workingAccount = undefined //Reset
      } else {
        state.workingAccount = {
          ...state.workingAccount,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingIncome: (
      state,
      action: PayloadAction<Partial<CreateRetirementIncomeDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingIncome = undefined //Reset
      } else {
        state.workingIncome = {
          ...state.workingIncome,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingRetirementAsset: (
      state,
      action: PayloadAction<Partial<CreateRetirementAssetDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingRetirementAsset = undefined //Reset
      } else {
        state.workingRetirementAsset = {
          ...state.workingRetirementAsset,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingRetirementAssetInterest: (
      state,
      action: PayloadAction<Partial<RetirementAssetSetInterestDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingRetirementAssetInterest = undefined //Reset
      } else {
        state.workingRetirementAssetInterest = {
          ...state.workingRetirementAssetInterest,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingBeneficiary: (
      state,
      action: PayloadAction<Partial<Nomination>>
    ) => {
      if (action.payload === undefined) {
        state.workingBeneficiary = undefined //Reset
      } else {
        state.workingBeneficiary = {
          ...state.workingBeneficiary,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingSuggestion: (
      state,
      action: PayloadAction<Partial<WorkingSuggestion>>
    ) => {
      if (action.payload === undefined) {
        state.workingSuggestion = undefined //Reset
      } else {
        state.workingSuggestion = {
          ...state.workingSuggestion,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingContributionSetup: (
      state,
      action: PayloadAction<Partial<ContributionConfiguration>>
    ) => {
      if (action.payload === undefined) {
        state.workingContributionSetup = undefined //Reset
      } else {
        state.workingContributionSetup = {
          ...state.workingContributionSetup,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingContributionSetupPayment: (
      state,
      action: PayloadAction<Partial<WorkingRegularContribution>>
    ) => {
      if (action.payload === undefined) {
        state.workingContributionPayment = undefined //Reset
      }
      else if (action.payload === null) {
        state.workingContributionPayment = null //For cancelling regular contributions
      } else {
        state.workingContributionPayment = {
          ...state.workingContributionPayment,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingRegistration: (state, action: PayloadAction<Partial<any>>) => {
      if (action.payload === undefined) {
        state.workingRegistration = undefined
      } else {
        state.workingRegistration = {
          ...state.workingRegistration,
          ...action.payload
        }
      }
    },
    updateWorkingLumpSumContributionPayment: (
      state,
      action: PayloadAction<Partial<WorkingLumpSumContribution>>
    ) => {
      if (action.payload === undefined) {
        state.workingLumpSumContributionPayment = undefined //Reset
      } else {
        state.workingLumpSumContributionPayment = {
          ...state.workingLumpSumContributionPayment,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingBulkTransfer: (
      state,
      action: PayloadAction<Partial<BulkTransferRetirementAssetDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingBulkTransfer = undefined //Reset
      } else {
        state.workingBulkTransfer = {
          ...state.workingBulkTransfer,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingCalculatorRequest: (
      state,
      action: PayloadAction<Partial<CalculatorModelRequestDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingCalculatorRequest = undefined //Reset
      } else {
        state.workingCalculatorRequest = {
          ...state.workingCalculatorRequest,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingPreviousPension: (
      state,
      action: PayloadAction<Partial<VerifySchemeEnrolmentPreviousPensionDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingPreviousPension = undefined //Reset
      } else {
        state.workingPreviousPension = {
          ...state.workingPreviousPension,
          ...action.payload
        } //Merge payload
      }
    },
    updateWorkingEnrolmentOptOut: (
      state,
      action: PayloadAction<Partial<OptOutSchemeEnrolmentDto>>
    ) => {
      if (action.payload === undefined) {
        state.workingEnrolmentOptOut = undefined //Reset
      } else {
        state.workingEnrolmentOptOut = {
          ...state.workingEnrolmentOptOut,
          ...action.payload
        } //Merge payload
      }
    },
    updateSessionState: (
      state,
      action: PayloadAction<Partial<SessionState>>
    ) => {
      if (action.payload === undefined) {
        state.sessionState = undefined //Reset
      } else {
        state.sessionState = {
          ...state.sessionState,
          ...action.payload
        } //Merge payload
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  updateWorkingClient,
  updateWorkingSpouse,
  updateWorkingInvite,
  updateWorkingAcceptInvite,
  updateWorkingAcceptSchemeInvite,
  updateWorkingSetupSchemeEnrolment,
  updateWorkingSchemeEnrolmentRequest,
  updateWorkingRetirementProfile,
  updateWorkingAssetAllocations,
  updateWorkingAccount,
  updateWorkingIncome,
  updateWorkingRetirementAsset,
  updateWorkingRetirementAssetInterest,
  updateWorkingBeneficiary,
  updateWorkingSuggestion,
  updateWorkingContributionSetup,
  updateWorkingContributionSetupPayment,
  updateWorkingLumpSumContributionPayment,
  updateWorkingBulkTransfer,
  updateWorkingRegistration,
  updateWorkingCalculatorRequest,
  updateWorkingPreviousPension,
  updateWorkingEnrolmentOptOut,
  updateSessionState,
} = tempDataSlice.actions

// Export state
export const workingClient = (state: RootState) => state.tempData.workingClient
export const workingSpouse = (state: RootState) => state.tempData.workingSpouse
export const workingInvite = (state: RootState) => state.tempData.workingInvite
export const workingAcceptInvite = (state: RootState) => state.tempData.workingAcceptInvite
export const workingAcceptSchemeInvite = (state: RootState) => state.tempData.workingAcceptSchemeInvite
export const workingSetupSchemeEnrolment = (state: RootState) => state.tempData.workingSetupSchemeEnrolment
export const workingSchemeEnrolmentRequest = (state: RootState) => state.tempData.workingSchemeEnrolmentRequest
export const workingRetirementProfile = (state: RootState) => state.tempData.workingRetirementProfile
export const workingAssetAllocations = (state: RootState) => state.tempData.workingAssetAllocations
export const workingAccount = (state: RootState) => state.tempData.workingAccount
export const workingIncome = (state: RootState) => state.tempData.workingIncome
export const workingRetirementAsset = (state: RootState) => state.tempData.workingRetirementAsset
export const workingRetirementAssetInterest = (state: RootState) => state.tempData.workingRetirementAssetInterest
export const workingBeneficiary = (state: RootState) => state.tempData.workingBeneficiary
export const workingSuggestion = (state: RootState) => state.tempData.workingSuggestion
export const workingContributionSetup = (state: RootState) => state.tempData.workingContributionSetup
export const workingContributionPayment = (state: RootState) => state.tempData.workingContributionPayment
export const workingLumpSumContributionPayment = (state: RootState) => state.tempData.workingLumpSumContributionPayment
export const workingBulkTransfer = (state: RootState) => state.tempData.workingBulkTransfer
export const workingRegistration = (state: RootState) =>  state.tempData.workingRegistration
export const workingCalculatorRequest = (state: RootState) =>  state.tempData.workingCalculatorRequest
export const workingPreviousPension = (state: RootState) =>  state.tempData.workingPreviousPension
export const workingEnrolmentOptOut = (state: RootState) =>  state.tempData.workingEnrolmentOptOut
export const sessionState = (state: RootState) =>  state.tempData.sessionState
export default tempDataSlice.reducer
