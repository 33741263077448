import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminPensionBrandsListScreen } from './Screens/AdminPensionBrandListScreen'

export const AdminPensionBrandSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Pension Brands'}
    >
      <AdminPensionBrandsListScreen />
    </SectionSimpleContainer>
  )
}

