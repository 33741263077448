import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { ContributionsList } from 'features/MenuSections/Contributions/Components/ContributionsList'
import React from 'react'
import { useGetGroupSchemeEnrolmentQuery } from 'store/apiSlice'

export const Enrolments_02_Contributions = ({ route, navigation }) => {
  const { enrolmentId }: { enrolmentId: string } = route?.params || {}

  const { data: enrolment, error: enrolmentError, isLoading: enrolmentIsLoading, refetch: refetchEnrolment } = useGetGroupSchemeEnrolmentQuery(enrolmentId)

  const error: any = enrolmentError
  const isLoading = enrolmentIsLoading

  return (
    <MainAppScreen>
      {
        error ? <ErrorScreen errorTryAgain={refetchEnrolment} error={error?.data} />
        : isLoading ? <Loading />
        : <ContributionsList
            includeUpcomingRegularTransaction={false}
            contributionsQuery={{
              companyRegNo: enrolment?.groupScheme?.companyNo,
            }}
          />
      }

    </MainAppScreen>
  )
}
