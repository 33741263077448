import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_03_PublicSectorChoice = ({ route, navigation }) => {
  const { nextScreen, forTransfer, client }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)
  
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ employerIsPublicSector: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerIsPublicSector: workingRetirementAssetData?.employerIsPublicSector
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const checkOnSubmit = attributes => {
    if (forTransfer && attributes.employerIsPublicSector) {
      setShowDialog(true)
    } else {
      onSubmit(attributes)
    }
  }

  const onSubmit = attributes => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementAsset(attributes))

    //PA-2009 Analytics
    if (forTransfer) {
      ampli.transferUsage({
        processStep: 'add_employer_public_sector_choice',
        isRetransfer: false,
        outcome: attributes.employerIsPublicSector ? 'public_sector' : 'success',
      })
    }
        
    navigation.navigate(nextScreen)  
  }

  const publicSectorOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    }
  ]

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(checkOnSubmit)}
      enableButton={isValid}
      headline={`Are they a public sector employer?`}
      subHeading={`Any central or local government organisation`}
    >
      <ManagedSimpleChoiceInput
        name={'employerIsPublicSector'}
        formObj={formObj}
        options={publicSectorOptions}
        submitHandler={handleSubmit(checkOnSubmit)}
        required={true}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={`We're sorry`}
        content={`We cannot currently accept transfers of pensions from the public sector as you may lose out on important benefits. You may still add this pension, but it will not be available for transfer unless you mark it as non-public-sector.`}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}
