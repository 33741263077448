import { ActionCard } from 'components/Utility/ActionCard'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedCardMultipleChoiceInputProps = {
  formObj: UseFormReturn<any>
  name: string
  options: ManagedCardMultipleChoiceInputOption[]
}

export type ManagedCardMultipleChoiceInputOption = {
  value: string
  title: string
  description: string
  modalContentFunction?: Function
  modalProceedLabel?: string
  modalCancelLabel?: string
  onPress?: any
  illustrationFilename?: string
  imageSource?: any
}

type ManagedCardChoiceProps = {
  formObj: UseFormReturn<any>
  name: string
  option: ManagedCardMultipleChoiceInputOption
  selected: boolean
}

const CardMultipleChoice = (props: ManagedCardChoiceProps) => {
  const { formObj, name, option, selected } = props
  const { title, description, imageSource, illustrationFilename, value, onPress, modalContentFunction, modalProceedLabel, modalCancelLabel } = option
  const { watch, trigger, setValue } = formObj

  return (
    <ActionCard
      illustrationFilename={illustrationFilename}
      imageSource={imageSource}
      title={title}
      description={description}
      size={'small'}
      onPress={onPress}
      buttonLabel={''}
      confirmationModalContentFunction={modalContentFunction}
      confirmationModalProceedLabel={modalProceedLabel || `Select`}
      confirmationModalCancelLabel={modalCancelLabel || 'Cancel'}
      selected={selected}
      useCheckbox={true}
    />
  )
}

export const ManagedCardMultipleChoiceInput = (props: ManagedCardMultipleChoiceInputProps) => {
  const { formObj, options, name } = props
  const { control, watch } = formObj
  const currentValues = watch(name)  

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            {
              options.map((option, idx) => {
                const selected = currentValues && currentValues.includes(option.value)
                return <CardMultipleChoice
                  key={idx}
                  formObj={formObj}
                  name={name}
                  option={option}
                  selected={selected}
                />
              })
            }
            <InputErrorMessages formObj={formObj} name={name} />
          </>
          )}
        name={name}
      />
    </View>
  )
}
