import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { ANNUAL_ALLOWANCE_AMOUNT, FOUND_PENSION_DECISION_MIN_VALUE, FOUND_PENSION_HOLD_DAYS, JAR_NAME, JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL, PERSONAL_TAX_RELIEF_FACTOR } from "lib/constants"
import { formatCurrencyAmount } from "lib/generalHelpers"
import { searchIntercomHelpCenter, showIntercomHelpCenter, showIntercomHelpCenterArticle } from "lib/intercomHelpers"
import { getHelpCenterLoadingMessages } from "lib/loadingHelpers"
import { identity, pickBy, floor, isArray } from 'lodash'
import React, { ReactNode, useState } from 'react'
import { Image, Keyboard, View } from "react-native"
import { ScrollView } from 'react-native'
import { Portal } from "react-native-paper"
import { Colors, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { Button } from './Button'
import { ExternalLinkButton } from "./ExternalLinkButton"
import { FooterButton } from './FooterButton'
import { Loading } from "./Loading"
import { platformIsWeb } from "lib/platformHelpers"
import { ContentDivider } from "components/Layout/ContentDivider"
import { AppIllustration } from "./AppIllustration"

type InformationModalProps = {
  title: string
  illustrationFilename?: string
  imageSource?: any
  noImage?: boolean
  texts: string[] | ReactNode[]
  links: InformationModalLink[]
  helpCenterSearchTerms?: string[]
  visible: boolean
  onDismiss: any
}

export type InformationModalLink = {
  title: string
  url: string
}

const InformationModal = (props: InformationModalProps) => {
  const { onDismiss, title, texts, links, visible, imageSource, illustrationFilename, noImage, helpCenterSearchTerms } = props

  const [articlesLoaded, setArticlesLoaded] = useState<boolean>(false)
  const [articlesLoading, setArticlesLoading] = useState<boolean>(false)
  const [articles, setArticles] = useState<any[]>([])

  const handleHelpCenter = async () => {
    setArticlesLoading(true)
    const result = await searchIntercomHelpCenter(helpCenterSearchTerms)
    if (result?.length) {
      setArticles(result)
    }
    setArticlesLoaded(true)
    setArticlesLoading(false)
  }

  const isWeb = platformIsWeb()

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>          
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          showsVerticalScrollIndicator={true}
        >  

          <View style={layoutStyles.cardModalContentContainer}>
              { noImage ? <></> : <>
                <View style={{
                  alignSelf:'center',
                  paddingVertical: Sizing.x20,
                }}>
                  {
                    imageSource
                      ? <Image source={imageSource} style={{
                          width: Sizing.x150,
                          height: Sizing.x150,
                          resizeMode: 'contain',
                          alignSelf: 'center',
                        }} />
                      : <AppIllustration filename={illustrationFilename || 'info.png'} style={{
                          width: Sizing.x150,
                          height: Sizing.x150,
                          resizeMode: 'contain',
                          alignSelf: 'center',
                        }} />
                  }
              </View>
              </>
              }
              <View style={{paddingBottom: Sizing.x20}}>
                <Headline>{title}</Headline>
              </View>
              {
                texts ? texts.map((text, idx) => {
                    return (
                      <View key={idx}>
                        {
                          typeof text === 'string'
                            ? <Paragraph
                                style={{
                                textAlign: 'left'
                                }}>
                                {text}
                              </Paragraph>
                              : text
                        }
                      </View>
                    )
                }) : <></>
              }
              {
                links ? links.map((link, idx) => {
                  return (
                    <ExternalLinkButton
                      key={idx}
                      url={link.url}
                  >{link.title}</ExternalLinkButton>
                  )
                }) : <></>
              }
          </View>
          {
            isWeb || !helpCenterSearchTerms ? <></> :
            <View style={{ paddingTop: Sizing.x20 }}>
              <ContentDivider />
              {
                articles && articles.length
                ?
                  <>
                    <Subheading>{'Help Center Articles'}</Subheading>
                    {
                      articles.map((article, idx) => {
                        return (
                          <Button key={idx} mode={'text'} onPress={() => showIntercomHelpCenterArticle(article.id)}>{article.title}</Button>
                        )
                      })
                    }
                    <ContentDivider />
                    <Button onPress={showIntercomHelpCenter} mode={'text'} >{'Open Help Center'}</Button>
                  </>
                : articlesLoading ?
                <View style={{ paddingTop: Sizing.x20 }}>
                  <Loading message={getHelpCenterLoadingMessages()} messageNoLoop={true} />
                </View>
                : articlesLoaded ?
                  <>
                    <Subheading>{'No articles found'}</Subheading>
                    <Button onPress={showIntercomHelpCenter} mode={'text'} >{'Open Help Center'}</Button>
                  </>
                : <Button onPress={handleHelpCenter} mode={'text'} >{'Search Help Center'}</Button>
              }
            </View>
          }
        </ScrollView>
        <FooterButton mode={'text'} onPress={onDismiss}>{'Close'}</FooterButton>
      </View>
    </CardModal>
  )
}


type InformationButtonProps = {  
  buttonTitle?: string
  iconName?: any
  iconOnly?: boolean
  iconOnlySize?: number
  iconColor?: string
  illustrationFilename?: string
  imageSource?: any
  helpCenterSearchTerms?: string[]
  noImage?: boolean
  title: string
  texts: string[] | ReactNode[]
  links?: InformationModalLink[]
  noStartCase?: boolean
}
export const InformationButton = (props: InformationButtonProps) => {
  const { buttonTitle, iconName, imageSource, illustrationFilename, noImage, title, iconOnly, iconOnlySize, iconColor, texts, links, helpCenterSearchTerms, noStartCase } = props

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => {
    Keyboard.dismiss()
    setModalVisible(true)
  }
  const hideModal = () => setModalVisible(false)

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      { iconOnly
      ? <MaterialCommunityIcons
          name={iconName || 'information-outline'}
          size={iconOnlySize || Sizing.x20}
          color={iconColor || themeColors.accent}
          onPress={showModal}
        />
      : <Button
          onPress={showModal}
          mode={'text'}
          icon={() => <MaterialCommunityIcons name={iconName || 'information-outline'} size={Sizing.x20} color={iconColor || themeColors.accent} />}
          labelStyle={noStartCase ? {
            textTransform: 'none',
          } : undefined}
        >
          {buttonTitle || 'More Information'}
        </Button>
      }
      <Portal>
        <InformationModal
          title={title || 'More Information'}
          illustrationFilename={illustrationFilename}
          imageSource={imageSource}
          noImage={noImage}
          helpCenterSearchTerms={helpCenterSearchTerms}
          texts={texts}
          links={links}
          onDismiss={hideModal}
          visible={modalVisible}
        />
      </Portal>
    </>
  )
}

export enum NamedInformation {
  ACHIEVABLE_RETIREMENT_AGE,
  AML,
  AUTOMATIC_ENROLMENT,
  BENEFICIARIES,
  COMBINED_LIFE_EXPECTANCY,
  CONSOLIDATION_OPTIONS,
  CONSOLIDATION_RATIONALE,
  CONTRIBUTION_COMPANY_NO,
  CONTRIBUTION_SOURCE,
  DATE_OF_BIRTH,
  DRAWDOWN,
  FINANCIAL_FORECASTING,
  FINANCIAL_FORECASTING_FOR_EMPLOYER,
  GENDER,
  GOALS,
  JOINT_PLANNING,
  LIFE_EXPECTANCY,
  MANUAL_STANDING_ORDER,
  MARITAL_STATUS,
  MPAA,
  NATIONAL_INSURANCE_NO,
  OPEN_BANKING_SUPPORT,
  ORGANISE_RETIREMENT,
  OTHER_ASSET_TYPES,
  OTHER_PENSIONS,
  PENSION_ANNUAL_ALLOWANCE,
  PENSION_OPT_OUT,
  PENSION_PROVIDER_CHANGES,
  PENSION_TERMS,
  PERSONAL_INFORMATION,
  REFINING_TARGET,
  RETIREMENT_BUDGET,
  RETIREMENT_BUDGET_ASSUMPTIONS,
  RETIREMENT_INCOMES,
  RETIREMENT_INCOME_START_DATE,
  SPOUSE_ADDITION,
  SPOUSE_DATA_SHARING,
  STATE_PENSION,
  SUGGESTED_ACTIONS,
  SURPLUS_CAPITAL,
  SUSTAINABILITY_RATINGS,
  TARGET_RETIREMENT_AGE,
  TRACING_OUTCOMES,
  TRANSFER_OUTCOMES,
  //Onboarding phases
  ONBOARDING_PHASE_PLAN,
  ONBOARDING_PHASE_CONTRIBUTE,
  ONBOARDING_PHASE_CONSOLIDATE,
  //PA-2033 - Remove Family Onboarding Card
  // ONBOARDING_PHASE_FAMILY,
  //Menu sections
  SECTION_DASHBOARD_TODAY,
  SECTION_DASHBOARD_RETIREMENT,
  SECTION_DASHBOARD_LEGACY,
  //Dashboard sections
  DASHBOARD_TODAY_CURRENT_ASSETS,
  DASHBOARD_TODAY_INVESTMENT_PLAN,
  DASHBOARD_TODAY_ASSET_BREAKDOWN,
  DASHBOARD_TODAY_RECENT_CONTRIBUTIONS,
  DASHBOARD_RETIREMENT_ANNUAL_BUDGET_SUMMARY,
  DASHBOARD_RETIREMENT_TARGET,
  DASHBOARD_RETIREMENT_BUDGET,
  DASHBOARD_RETIREMENT_INCOME_BREAKDOWN,
  DASHBOARD_LEGACY_RETIREMENT_DRAWDOWN,
  DASHBOARD_LEGACY_BENEFICIARIES,
  DASHBOARD_LEGACY_INHERITANCE_BREAKDOWN,
}

type NamedInformationButtonProps = {
  name: NamedInformation
  buttonTitle?: string
  iconOnly?: boolean
  iconOnlySize?: number
  iconColor?: string
  imageSource?: any
  noImage?: boolean
  noStartCase?: boolean
}

export const NamedInformationButton = (props: NamedInformationButtonProps) => {
  const { name, imageSource, iconOnly, iconOnlySize, iconColor, buttonTitle, noImage, noStartCase } = props

  let ibProps: InformationButtonProps

  switch (name) {

    //Info about achievable retirement age
    case NamedInformation.ACHIEVABLE_RETIREMENT_AGE:
      ibProps = {
        title: 'Achievable Retirement Age',
        texts: [
          `Jarvis uses all the information you enter to perform financial forecasting and determine the earliest possible age at which you can retire, and support your desired lifestyle.`,
          `We call this your 'Achievable Retirement Age', and it will always be more than, or equal to, the 'Target Retirement Age' that you have set.`,
          `Assuming you cannot yet achieve your target, the financial forecast will work on the basis that all your savings would be used up during retirement, leaving you with zero (or near zero) at your life expectancy.`,
          `If Jarvis predicts that you could, in principle, retire even earlier that your target, the financial forecast will instead show you how how much surplus capital could be left at your life expectancy.`,
          `In this case, you can either reduce your target age further, or instead think about building up surplus for inheritance or unforeseen circumstances.`
        ],
        helpCenterSearchTerms: ['achievable retirement age', 'forecast', 'target retirement age'],
      }
      break        

    //Info about AML
    case NamedInformation.AML:
      ibProps = {
        title: 'Identity Verification',
        texts: [
          `In order to meet the UK Government's regulations on Money Laundering, and protect all of our users, we need to verify your identity before we can open a ${JAR_NAME_PERSONAL} for you.`,
          `We can usually instantly verify you electronically based on a few simple questions, but should we require further information, our support team will be in touch.`,
          `Please note that you will need to have your UK National Insurance Number to hand to complete identity verification.`,
        ],
        helpCenterSearchTerms: ['identity', 'verification'],
      }
      break

    //Info about automatic enrolment
    case NamedInformation.AUTOMATIC_ENROLMENT:
      ibProps = {
        title: 'Automatic Enrolment',
        texts: [
          `UK Government rules dictate how your employer should handle enrolment into workplace schemes. Employees that meet certain criteria must be automatically enrolled by an employer.`,
          `Employees have the right to 'Opt Out' within a defined time period following enrolment. Your employer cannot ask you or force you to opt out. If you are asked or forced to opt out, you can tell The Pensions Regulator.`,
          `When opting out, any pension contributions made by an employer must be returned to the employee.`,
          `Beyond the defined time period in which 'Opt Out' can occur, employees can instead request to leave the workplace scheme. In this case, any pension contributions already made by your employer will remain in your pension.`,
          `Employers may need to periodically re-enrol employees in the future due to UK Government regulations - currently, this is approximately every 3 years.`,
        ],  
        helpCenterSearchTerms: ['automatic enrolment'],
        links: [
          {
            title: `The Pensions Regulator`,
            url: `https://www.thepensionsregulator.gov.uk/en`,
          }
        ]
      }
      break

    //Info about user beneficiaries
    case NamedInformation.BENEFICIARIES:
      ibProps = {
        title: 'Beneficiaries',
        texts: [
          `Defining beneficiaries for your ${JAR_NAME_ALL} is a way of recording who you would like to inherit any residual capital left in your pension at the time of your death.`,
          `The benefiaries you define will be used in conjunction with any will you hold and applicable laws.`,
        ],
        helpCenterSearchTerms: ['beneficiaries', 'nominees', 'inheritance'],
      }
      break

    //Info about how combined life expectancy works
    case NamedInformation.COMBINED_LIFE_EXPECTANCY:
      ibProps = {
        title: 'Combined Life Expectancy',
        texts: [
          `Jarvis derives life expectancy from UK mortality tables, taking the most likely (50% probability) outcome.`,
          `Combined Life Expectancy means YOUR age at the point which ONE of you would still be expected to remain alive.`,
          `Due to way probabilities work, this age is generally higher than your individual life expectancy - you effectively have 2 "chances" between you to live longer.`,
          `If your partner is younger than you, the age YOU would be when they reach THEIR life expectancy could be significantly higher.`,
          `Please note these figures are for an average person in the UK and may be affected by personal health conditions and family history.`,
        ],
        helpCenterSearchTerms: ['life expectancy'],
      }
      break

    //Info about what can be consolidated
    case NamedInformation.CONSOLIDATION_OPTIONS:
      ibProps = {
        buttonTitle: 'What can I transfer?',
        title: 'Pension Consolidation',
        texts: [
          `Jarvis allows you to consolidate eligible contribution-based pensions into your ${JAR_NAME_PERSONAL}.`,
          `According to the FCA, it is generally not advisable to transfer Defined Benefit pensions as they offer lifelong income with minimal risk.`,
          `If you need more assistance deciding whether or nor consolidate a pension, please contact an independent financial advisor who is suitably qualified.`,
          `Whilst most contribution-based pensions are eligible, there are some cases where we do not accept inward transfers, as this would unlikely be in your best interest.`,
          `This includes 'active' workplace pensions where your employer is making contributions on your behalf.`,
          `When you invest, you put money into things that might go up in value over time. If the value of those things rises or falls, the value of the money you invested does too.`,
        ],
        helpCenterSearchTerms: ['consolidation', 'defined benefit', 'defined contribution', 'workplace pension']
      }
      break

    //Info about why to consolidate
    case NamedInformation.CONSOLIDATION_RATIONALE:
      ibProps = {
        buttonTitle: 'Why consolidate?',
        title: 'Why Consolidate Pensions?',
        texts: [
          `Jarvis paints a picture of your financial future – then helps you get there, day by day.`,
          `Bringing other pensions into your ${JAR_NAME_ALL} creates a single source for everything you need to retire into the lifestyle you want.`,
          `Jarvis can then track progress against your plan to ensure you are on track, and make accurate suggestions if course-correction is needed.`,
          `Consolidating your pensions may not be right for everyone. If you are in doubt you should seek advice from a qualified independent financial adviser.`,
        ],
        helpCenterSearchTerms: ['consolidation']
      }
      break

    //Info about why we need to collect limited company registration number
    case NamedInformation.CONTRIBUTION_COMPANY_NO:
      ibProps = {
        buttonTitle: 'Why do you want this?',
        title: 'Company Registration No',
        texts: [
          `We're legally required to understand the source of funds for contributions to pensions.`,
          `By providing us with your company registration number, we can look up the details against Companies House to save you the trouble of entering information manually.`,
          `If you don't know your company number, you can use the government website to find it.`,
        ],
        links: [{
          title: 'Company Search',
          url: 'https://www.gov.uk/get-information-about-a-company',
        }],
        helpCenterSearchTerms: ['limited company', 'contributions']
      }
      break

    //Info about why we need to know the source of pension contributions
    case NamedInformation.CONTRIBUTION_SOURCE:
      ibProps = {
        buttonTitle: 'Why do you want this?',
        title: 'Contributions',
        texts: [
          `We're legally required to understand the source of funds for contributions to pensions, and this also affects the tax handling.`,
          `Any pension contributions you make from your personal funds will get ${PERSONAL_TAX_RELIEF_FACTOR * 100}% tax relief from the UK government.`,
          `And, if you contribute from your business, any contributions can be used as an expense to reduce your tax bill.`,
          `You should seek advice from a qualified independent financial adviser if you are unsure which is the best approach for you.`,
        ],
        helpCenterSearchTerms: ['limited company', 'contributions']
      }
      break

    //Info about why we need to collect data of birth
    case NamedInformation.DATE_OF_BIRTH:
      ibProps = {
        buttonTitle: 'Why do you need this?',
        title: 'Date Of Birth',
        texts: [
          `Pensions are all about time – how long it is until you retire, and the freedom you'll unlock with some smart financial planning.`,
          `To give you intelligent, accurate information, we use your age for state benefit and life expectancy forecasting.`,
          `We won't use your age for any other reason.`,
        ],
        helpCenterSearchTerms: ['personal information', 'date of birth']
      }
      break      

    //Info about why we need to collect data of birth
    case NamedInformation.DRAWDOWN:
      ibProps = {
        title: 'Pension Drawdown',
        texts: [
          `Pension drawdown refers to taking money from your pension pot to provide an income, whilst still allowing your pension fund to keep on growing.`,
          `It is an alternative approach to buying an annuity, which you could choose to purchase with your entire pension fund to provide a fixed, regular income.`,
          `Your ${JAR_NAME_ALL} does not currently support the Pension Drawdown option.`,
          `However, we're busy building out Jarvis to support people as they move into retirement, and offering unique insights to ensure you retain your financial freedom.`,
          `Part of this will be to allow for drawdown of the money you have invested into your ${JAR_NAME_ALL}.`,
          `As always, you are free to transfer your ${JAR_NAME_ALL} to another provider at any time.`,
        ],
        helpCenterSearchTerms: ['drawdown', 'annuity']
      }
      break

    //Info about how the fianancial forecast works
    case NamedInformation.FINANCIAL_FORECASTING:
      ibProps = {
        buttonTitle: 'Learn more',
        title: 'Jarvis Forecasting',
        texts: [
          `Jarvis uses a complex financial model to predict the future based on your personal circumstances.`,
          `The model is recalculated with every change you make, be that making a contribution, adjusting the value of other pensions or incomes, or changing parameters such as you Retirement Budget or Target Retirement Age.`,
          `There are also factors outside of your control, such as the economy, financial markets and the future value of money based on predicted inflation.`,
          `For this reason, Jarvis constantly recalculates your forecast and gives an up-to-date view.`,
          `This could mean that any plan you made needs adjusting to account for these external factors.`,
          `We would recommend checking and refining your plan at least a couple of times a year.`,
        ],
        helpCenterSearchTerms: ['forecasting', 'economy', 'financial markets']
      }
      break

    //Info about how the fianancial forecast works for employer enrolments
    case NamedInformation.FINANCIAL_FORECASTING_FOR_EMPLOYER:
      ibProps = {
        buttonTitle: 'Learn more',
        title: 'Jarvis Forecasting',
        texts: [
          `Jarvis uses a complex financial model to predict the future based on your personal circumstances.`,
          `The model takes into account decisions you make such as the Retirement Budget you define and your Target Retirement Age.`,
          `It also uses data you provide on other pensions and incomes, and the contributions being made to your ${JAR_NAME_ALL}, by both you and your employer.`,
          `In order to accurately forecast your future, Jarvis needs to understand the type of income you are receiving from different employment, and therefore the pension contributions being received from those employers.`,
          `In some cases, it may be regular income and pension contributions, which can be assumed to recur into the future.`,
          `However, in other cases the income may be ad-hoc or irregular, and it is safer to only consider the contributions that have already arrived into your ${JAR_NAME_GROUP}.`,
        ],
        helpCenterSearchTerms: ['forecasting']
      }
      break

    //Info about why we nede to collect gender
    case NamedInformation.GOALS:
      ibProps = {
        title: 'Jarvis Goals',
        texts: [
          `In order to help you make plans for the future, Jarvis looks at your exact circumstances, and offers relevant Goals you might want to work towards.`,
          `In general, these Goals are used to help you decide how much to contribute to your ${JAR_NAME_ALL}.`,
          `For example, if you cannot yet achieve your Target Retirement Age, Jarvis will offer you options to either close the gap fully, or instead target an intermediate age, which might be more realistic.`,
          `However, if you're already on track with your target, Jarvis will offer you options to build surplus capital or change your target age.`,
          `In all cases, Jarvis can make suggestions, and show you the impact before you implement the change.`
        ],
        helpCenterSearchTerms: ['goals']
      }
      break
          
    //Info about why we nede to collect gender
    case NamedInformation.GENDER:
      ibProps = {
        buttonTitle: 'Why do you need this?',
        title: 'Gender',
        texts: [
          `We understand that gender identity is more complex than male and female – but many elements of retirement legislation are built around these terms.`,
          `We only use your legally defined gender to determine the age you'll be able to get a UK state pension, as well as in our life expectancy forecasts.`,
          `You can find out more on the UK Government's state pensions page.`,
          `We won't use your gender for any other reason.`,
        ],
        links: [{
          title: 'Government State Pension Age',
          url: 'https://www.gov.uk/employment/state-pension-age',
        }],
        helpCenterSearchTerms: ['personal information', 'gender']
      }
      break

    //Info about joint planning
    case NamedInformation.JOINT_PLANNING:
      ibProps = {
        title: 'Joint Retirement Planning',
        texts: [
          `In order to plan for a joint retirement, it's important to understand the likely timeframe during which you need to support yourselves.`,
          `It's an unfortunate reality, but the likelihood is that one of you will outlast the other, and this is where your Combined Life Expectancy comes into play.`,
          `For joint retirment planning, we use your Combined Life Expectancy to calculate the future date until which you will need to support yourselves, and this determines the expected number of years in retirement.`,
          `Within Jarvis, as you both see your Timeline in terms of your own age, this could mean the planning runs until a surprisingly old age.`,
        ],
        helpCenterSearchTerms: ['retirement planning', 'life expctancy']
      }
      break

    //Info about open banking support
    case NamedInformation.OPEN_BANKING_SUPPORT:
      ibProps = {
        title: 'Open Banking Support',
        texts: [
          `Jarvis uses the UK Open Banking Standard to connect to banks and automate pension contributions.`,
          `For that vast majority of banks, this includes both the authorisation of one-off contributions, and also the creation of a standing order to facilitate regular contributions.`,
          `Unfortunately, a handful of UK banks are yet to support standing order creation via Open Banking, so in these cases, a standing order needs to be manually created - we guide you through the details when this is required.`,
        ],
        links: [{
          title: 'More about Open Banking',
          url: 'https://www.openbanking.org.uk/',
        }],
        helpCenterSearchTerms: ['open banking', 'standing order', 'contribution']
      }
      break

    //Info about organizing retirement (onboarding)
    case NamedInformation.ORGANISE_RETIREMENT:
      ibProps = {
        title: 'Organising Your Retirement',
        texts: [
          `Jarvis helps you get your retirement organised and give you peace of mind about the future.`,
          `We've put together a set of checklists to help you understand where you are right now, and to quickly take actions to achieve financial freedom.`,
          `We recommend tackling the checklists in order, starting with 'Plan Your Retirement', as this will unlock Jarvis' superpowers to assist with your decision making.`,
          `However, if you prefer, you can get started right away with setting up a pension and making contributions, or consolidating old pensions into your ${JAR_NAME_PERSONAL}.`,
        ],
        helpCenterSearchTerms: ['organise', 'onboarding']
      }
      break

    //Info about suggestions
    case NamedInformation.SUGGESTED_ACTIONS:
      ibProps = {
        title: 'Suggested Actions',
        texts: [
          `From time to time, Jarvis may suggest relevant actions on your Dashboard screens.`,
          `These may include further actions based on the decisions you have made, or highlight a task that needs your attention.`,
          `Our aim is to assist you in shaping and achieving your retirement goals - if you don't feel the action is relevant, simply tap on 'No Thanks' in the card that opens to dismiss it.`,
        ],
        helpCenterSearchTerms: ['suggestions', 'actions', 'tasks']
      }
      break

    //Info about why we need to collect other pensions
    case NamedInformation.OTHER_ASSET_TYPES:
      ibProps = {
        title: 'Asset Types',
        texts: [
          `Pensions are just one part of the retirement story. From long-term savings to a business you own, your actual retirement fund comes from multiple places.`,
          `That’s why Jarvis lets you track these other assets side-by-side with your pensions. So you always know whether you’re on course and, if not, what you can do to get the retirement lifestyle you want.`,
          `You can include contribution-based pensions either set up personally, through your own limited company, or your employer.`,
          `Please note that you should only include assets from which you plan to take capital to fund your retirement.`,
          `Examples of assets to EXCLUDE are the home you live in and rental properties that will provide income in retirement - these can be added as retirement incomes.`,
        ],
        helpCenterSearchTerms: ['assets']
      }
      break

    //Info about why we nede to collect other pensions
    case NamedInformation.OTHER_PENSIONS:
      ibProps = {
        title: 'Retirement Assets',
        texts: [
          `Let’s be honest: keeping track of various pensions is confusing. It’s a hassle most of us are happy to ignore.`,
          `Jarvis gives you a better way. A single snapshot of every pension. Everything you need to know to plan smarter.`,
          `All in the context of what really matters: when you want to retire, the lifestyle you want to live, and what it will take to get there.`,
          `Jarvis takes into account all your assets to create accurate financial forecasts based on your personal circumstances.`,
          `Consolidating your old pensions into your ${JAR_NAME_PERSONAL} simplifies your planning and enables live forecasting.`,
        ],
        helpCenterSearchTerms: ['assets']
      }
      break

    //Info about life expectancy
    case NamedInformation.LIFE_EXPECTANCY:
      ibProps = {
        title: 'Life Expectancy',
        texts: [
          `Jarvis derives life expectancy from UK mortality tables, taking the most likely (50% probability) outcome.`,
          `Your life expectancy is used to calculate a future date until which you will need to support yourself.`,
          `Depending on when you choose to retire, this determines the expected number of years in retirement.`,
          `Within Jarvis, your Timeline shows your future life, separated into the periods before and during retirement.`,
          `Please note these figures are for an average person in the UK and may be affected by personal health conditions and family history.`,
        ],
        helpCenterSearchTerms: ['life expectancy']
      }
      break

    //Info about marital status
    case NamedInformation.MANUAL_STANDING_ORDER:
      ibProps = {
        title: 'Creating Standing Orders',
        texts: [
          `In cases where your bank does not support the electronic creation of a standing order, you need to do this manually from within your banking app or website.`,
          `The exact process for creating the standing order varies between banks, but may also involve the creation of a new payee as part of the process.`,
          `If you do not use electronic banking, you may need to speak to you bank on the telephone, or in branch to set things up.`,
          `The details of the standing order must match our records exactly, and originate from the bank account you have told us about. This will ensure that your contributions are automatically allocated to your pension each month.`
        ],
        helpCenterSearchTerms: ['standing order', 'open banking']
      }
      break  


    //Info about marital status
    case NamedInformation.MARITAL_STATUS:
      ibProps = {
        title: 'Marital Status',
        texts: [
          `Whilst this doesn't affect how your ${JAR_NAME_ALL} operates, we need to collect your legal marital status for our reporting obligations.`,
          `We understand that you may refer to your marital status differently, however we need to collect the closest match to these options.`,
          `For example, if you think of yourself as 'Separated' (not legally divorced), you should pick the option for 'Married'.`,
          `In this context, the term 'Married' also includes civil partnerships.`,
        ],
        helpCenterSearchTerms: ['money purchase annual allowance', 'mpaa']
      }
      break  


    //Info about why we need to collect national insurance number
    case NamedInformation.MPAA:
      ibProps = {
        buttonTitle: 'About Money Purchase Annual Allowance',
        title: 'Money Purchase Annual Allowance',
        texts: [
          `If you start to take money from a defined contribution pension pot, the amount that can be contributed to your defined contribution pensions while still getting tax relief on might reduce.`,
          `This is known as the Money Purchase Annual Allowance or MPAA.`,
        ],
        links: [{
          title: 'Learn more',
          url: 'https://www.moneyhelper.org.uk/en/pensions-and-retirement/tax-and-pensions/money-purchase-annual-allowance-mpaa',
        }],
        helpCenterSearchTerms: ['money purchase annual allowance', 'mpaa']
      }
      break  

    //Info about why we need to collect national insurance number
    case NamedInformation.NATIONAL_INSURANCE_NO:
      ibProps = {
        buttonTitle: 'Why do you need this?',
        title: 'National Insurance Number',
        texts: [
          `We'll only use your National Insurance number to make sure you get the right tax relief on your ${JAR_NAME_ALL}.`,
          `In short, we'll help ensure you get the ${PERSONAL_TAX_RELIEF_FACTOR * 100}% tax relief on any personal contributions.`,
          `For example, if you contributed £100 into your ${JAR_NAME_PERSONAL} from your personal funds, the government will give you an additional £25 contribution (tax relief).`,
          `You should be able to find your NI number on a payslip, P60, or in your online tax dashboard with HMRC.`,
        ],
        links: [
          {
            title: `Goverment Website`,
            url: `https://www.gov.uk/national-insurance/your-national-insurance-number`,
          }
        ],
        helpCenterSearchTerms: ['national insurance']
      }
      break  

    //Info about personal information
    case NamedInformation.PERSONAL_INFORMATION:
      ibProps = {
        title: 'Your personal details',
        texts: [
          `With just a few pieces of personal information, we'll give you an at-a-glance forecast of what your retirement could look like.`,
          `Your age and gender are key to understanding how long you are likely to have in retirement, and when you could receive benefits such as state pensions.`,
        ],
        helpCenterSearchTerms: ['personal information']
      }
      break

    //Info about UK pension allowances
    case NamedInformation.PENSION_ANNUAL_ALLOWANCE:
      ibProps = {
        title: 'Annual Allowance',
        texts: [
          `Under UK pension law, money invested in a pension can’t usually be accessed until age 55 (planned to change to age 57 from 6th April 2028).`,
          `Investing in a pension is a tax-efficient way of saving for your retirement, and the UK government provides tax incentives for putting money aside in this way.`,
          `This is provided in the form oftax relief on pension contributions up to an annual allowance of ${formatCurrencyAmount(ANNUAL_ALLOWANCE_AMOUNT)}, or 100% of your qualifying earnings, whichever is lower.`,
          `This single allowance covers all of your pensions, whether they are workplace or personal pensions.`,
          `The government tax relief also counts towards the total, so if contributing personally, the amount you can actually contribute before hitting the annual allowance is only ${formatCurrencyAmount(floor(ANNUAL_ALLOWANCE_AMOUNT / ( 1 + PERSONAL_TAX_RELIEF_FACTOR )))}.`,
          `If you contribute more than your allowance, you will not receive tax relief on the excess amount, and you will also face an increase in your tax bill for the year, due to the annual allowance charge.`,
          `It is possible to carry forward any unused allowance for up to 3 years, so you may be able to contribute more in a single year to "catch-up" from previous years.`,
        ],
        links: [{
          title: 'Personal Pension Information',
          url: 'https://www.gov.uk/personal-pensions-your-rights',
        },{
          title: 'Tax on Personal Pensions',
          url: 'https://www.gov.uk/tax-on-your-private-pension/annual-allowance',
        },{
          title: 'Allowance Tax Charge Calculator',
          url: 'https://www.tax.service.gov.uk/pension-annual-allowance-calculator'
        }],
        helpCenterSearchTerms: ['annual allowance', 'pension law']
      }
      break

    //Info about general workplace pension opt out
    case NamedInformation.PENSION_OPT_OUT:
      ibProps = {
        title: 'Workplace Pension Opt-Out',
        texts: [
          `We're obliged to collect this information for our industry legally-required reporting.`,
          `Only answer 'Yes' if you have opted out of your current workplace pension to instead contribute to your ${JAR_NAME_PERSONAL}.`,
          `It doesn't have any bearing on your ${JAR_NAME_ALL}, but helps the government understand pension habits.`,
        ],
        links: [{
          title: 'Workplace Pension Information',
          url: 'https://www.gov.uk/workplace-pensions/if-you-want-to-leave-your-workplace-pension-scheme',
        }],
        helpCenterSearchTerms: ['pension law']
      }
      break

    //Info about pension provider changes
    case NamedInformation.PENSION_PROVIDER_CHANGES:
      ibProps = {
        title: 'Pension Provider Changes',
        texts: [
          `Just like in any other industry, pension businesses can change over the years through mergers and acquisitions.`,
          `This could mean that the pension brand with whom you set up your pension is no longer the current provider.`,
          `A new provider may have retained the brand, merged it with their own book of business, or renamed it to something else.`,
          `Pension brands may also operate multiple lines of business, which are often managed independently.`,
        ],
        helpCenterSearchTerms: ['pension provider', 'merger', 'acquisition']
      }
      break

    //Info about pension terms documents
    case NamedInformation.PENSION_TERMS:
      ibProps = {
        title: 'Pension Documents',
        texts: [
          `There are a few documents you need to read and accept when opening a ${JAR_NAME_PERSONAL}:`,
          `The Terms & Conditions document outlines the key terms for the ${JAR_NAME_PERSONAL} (Self-Invested Personal Pension).`,
          `The Key Features Document contains important information to help you decide if a ${JAR_NAME_PERSONAL} is right for you.`,
          `The Member Declaration outlines your responsibilties to us in providing accurate information and keeping it up to date.`,
          `The Illustration demonstrates how your ${JAR_NAME_PERSONAL} could perform using standard FCA guideline figures.`,
        ],
        helpCenterSearchTerms: ['terms', ' key features', 'illustration', 'member declaration']
      }
      break

    //Info about refining budget
    case NamedInformation.REFINING_TARGET:
      ibProps = {
        title: 'Refining Your Target',
        texts: [
          `We all get carried away, and sometimes our ideas may not align with reality.`,
          `You may find that the target you have set, in terms of your desired future lifestyle and the age you want to get there, seem unachievable.`,
          `This is exactly where Jarvis first help you in refining that target to something you feel comfortable with.`,
          `You can adjust your Retirement Budget and/or your Target Retirement Age from the Retirement screen on your Dashboard.`,
          `As you do so, Jarvis will recalculate the numbers, and you can see the effect on your Achievable Retirement Age.`,
          `Your Retirement Target Meter also gives you a quick idea of the monthly contributions that would be needed to close the gap, helping you to be realistic.`,
          `Remember, retirement planning is an ongoing process - you can start small and change your targets as your circumstances change.`,
          `Finally - all pensions, assets and incomes that you record in Jarvis are used in forecasting, so make sure everything is recorded to ensure the most accurate results.`
        ],
        helpCenterSearchTerms: ['refining target', 'retirement budget', 'target retirement age']
      }
      break

    //Info about retirment budgeting
    case NamedInformation.RETIREMENT_BUDGET:
      ibProps = {
        title: 'Retirement Budget',
        texts: [
          `Retirement is different for everyone. That's the point. You get to decide how to live and how to spend your time and money.`,
          `And, with some smart planning, you get to decide when it starts.`,
          `Creating a realistic budget for your retirement lifestyle is key to effective planning.`,
          `Using the 'Retirement Living Standards' data from the Pension And Lifetime Savings Association (PLSA), Jarvis will help you create a budget that suits your situation and aspirations.`,
          `The budgets shown are in today's terms and are an estimate of the expenses you will would incur for a given lifestyle.`,
        ],
        links: [
          {
            title: `Retirement Living Standards`,
            url: `https://www.retirementlivingstandards.org.uk/`,
          },
          {
            title: `Pension And Lifetime Savings Association`,
            url: 'https://www.plsa.co.uk/'
          }
        ],
        helpCenterSearchTerms: ['budgeting']
      }
      break

    //Info about retirment budgeting
    case NamedInformation.RETIREMENT_BUDGET_ASSUMPTIONS:
      ibProps = {
        title: 'Retirement Budget Assumptions',
        texts: [
          `When building your retirement budget, it's important to tailor the figures to your individual circumstances.`,
          `Our budgets come from the 'Retirement Living Standards' data from the Pension And Lifetime Savings Association (PLSA), which are calculated to cater for the most common scenarios for people in the UK.`,
          `However, it is important to understand the assumptions used for these budgets, which you can find at the link below.`,
          `You should compare these to your own own circumstances and adjust the category amounts accordingly.`,
          `For any additional costs, record the equivalent cost in today's money.`,
        ],
        links: [
          {
            title: `Retirement Living Standard Assumptions`,
            url: `https://www.retirementlivingstandards.org.uk/details#what-you-need-to-know`,
          },
          {
            title: `Pension And Lifetime Savings Association`,
            url: 'https://www.plsa.co.uk/'
          }
        ],
        helpCenterSearchTerms: ['budgeting']
      }
      break

    //Info about retirment incomes
    case NamedInformation.RETIREMENT_INCOMES:
      ibProps = {
        title: 'Retirement Incomes',
        texts: [
          `By recording ongoing incomes in retirement, you'll see how much your lifestyle is already on course to be funded – and some practical steps to closs the gap.`,
          `Aside from state pensions, retirement incomes can include workplace or government pensions with defined benefits, rental income, and ongoing employment.`,
          `Jarvis uses these incomes in financial forecasting, showing you exactly how much you need to save to make up the difference.`,
          `You should only add incomes that you are confident you will be receiving during your retirement.`,
        ],
        helpCenterSearchTerms: ['incomes']
      }
      break      

    //Info about retirment incomes
    case NamedInformation.RETIREMENT_INCOME_START_DATE:
      ibProps = {
        title: 'Income Start Date',
        texts: [
          `Depending on the source of ongoing income, you may or may not already be receiving it at the point you retire.`,
          `For example, income from a rental property that you already own could be assumed to be available as soon as you retire, whenever that may be.`,
          `In other cases, you may know you are not going to receive the income until you reach a certain age.`,
          `Jarvis wil use this information in financial modelling, as you may need to save additional cash to fund your lifestyle until the regular income starts.`,
        ],
        helpCenterSearchTerms: ['incomes']
      }
      break      

    //Info about adding spouse
    case NamedInformation.SPOUSE_ADDITION:
      ibProps = {
        title: 'Adding a Partner',
        texts: [
          `By adding some basic details for your spouse or partner, you can get a more personalised and realistic view of your future.`,
          `This includes understanding your combined life expectancy based on your ages and genders, giving you a more accurate view of your likely time in retirement.`,
          `Jarvis can also suggest retirement budgets suitable for couples living together.`,
          `Finally, you can capture the assets and incomes that you are both pooling to fund your retirement, helping you get the most accurate view on when you can retire.`,
          `Once you have added a partner, you can later invite them to open a Jarvis account and link your accounts to plan your retirement together.`,
        ],
        helpCenterSearchTerms: ['spouse', 'partner']
      }
      break


    //Info about data sharing with spouse
    case NamedInformation.SPOUSE_DATA_SHARING:
      ibProps = {
        title: 'Partner Data Sharing',
        texts: [
          `By setting up a link between your Jarvis account and a partner's, you will share certain aspects of your financial data with each other.`,
          `This includes the value of your ${JAR_NAME_ALL}, including the value of any pending contributions, withdrawals and transfers.`,
          `It also includes the value of any other pensions/savings, and forecasted retirement incomes that you have recorded.`,
          `Finally, whilst you will have the ability to create independent retirement plans, we will share the details of your plan and budget to enable you to align things.`,
          `As the invitor, you will no longer be able to manage the other pensions/savings and incomes - the invitee will have the option to "adopt" these into their account.`,
          `As the invitee, you will have the option to take ownership of the other pensions/savings and incomes set up by the invitor. However, any partner you have already created will be removed, including any other pensions/savings and incomes you have previously created.`,
        ],
        helpCenterSearchTerms: ['spouse', 'partner', 'data sharing']
      }
      break
      
    //Info about UK state pensions
    case NamedInformation.STATE_PENSION:
      ibProps = {
        title: 'UK State Pensions',
        texts: [
          `If you have made sufficient National Insurance Contributions (NICs) during your working life, you are likely to be eligible for a state pension from the UK government.`,
          `The amount you could receive depends on your National Insurance record - you'll generally need 35 qualifying years to get the full state pension, and at least 10 to get anything.`,
          `To find out how much your state pension could be, use the government website to check your State Pension forecast.`,
        ],
        links: [{
          title: 'State Pension Information',
          url: 'https://www.gov.uk/check-state-pension',
        }],
        helpCenterSearchTerms: ['state pension']
      }
      break

    //Info about surplus capital
    case NamedInformation.SURPLUS_CAPITAL:
      ibProps = {
        title: 'Surplus Capital',
        texts: [
          `When Jarvis predicts that you can achieve your target retirement age, the finacial forecast will show the amount of residual savings that would still be available when you reach your life expectancy.`,
          `This is 'spare' capital that could be put to use in different ways.`,
          `A common scenario is to consider this capital as part of your inheritance that can be left to loved ones - once your have defined your beneficiaries, Jarvis shows you how much each person could receive.`,
          `Alternatively, it could be considered your 'rainy day' fund or to be used for unforeseen circumstances.`,
          `Either way, you have comfort knowing that you should have enough to cover your retirement with money left to spare.`
        ],
        helpCenterSearchTerms: ['sustainability']
      }
      break

    //Info about sustainability ratings
    case NamedInformation.SUSTAINABILITY_RATINGS:
      ibProps = {
        title: 'Sustainability Ratings',
        texts: [
          `The Morning Star Sustainability Rating is a measure of how well the portfolio holdings are managing their ESG Risk relative to the portfolio's Global Category peer group.`,
          `The Morning Star Historical Sustainability Score is a weighted average of the trailing 12 months of Morning Star Portfolio Sustainability Scores. Historical portfolio scores are not equal-weighted; rather, more-recent portfolios are weighted more heavily than older portfolios.`,
          `Based on their Morning Star Historical Sustainability Score, investments are assigned absolute category and percent ranks within their Morning Star Global Categories. An investment's Morning Star Sustainability Rating (Globe Rating) is its normally distributed ordinal score and descriptive rank relative to the investment's global category. Higher ratings are better and indicate that an investment has, on average, more of its assets invested in companies that have lower ESG risk as characterized by Sustainalytics.`,
        ],
        helpCenterSearchTerms: ['sustainability']
      }
      break
            
    //Info about target retirement age
    case NamedInformation.TARGET_RETIREMENT_AGE:
      ibProps = {
        title: 'Target Retirement Age',
        texts: [
          `Your Target Retirment Age is a key figure used by Jarvis when building your financial forecasts and predicting your future.`,
          `This figure is the earliest age by which you are looking to achieve financial freedom, and be able to support your retirement lifestyle until your life expectancy.`,
          `When forecasting, Jarvis determines if you can achieve this age, and if not, can make suggestions on ways to close the gap.`,
        ],
        helpCenterSearchTerms: ['target retirement age', 'retirement age']
      }
      break

    //Info about tracing outcomes
    case NamedInformation.TRACING_OUTCOMES:
      ibProps = {
        title: 'Pension Tracing',
        texts: [
          `If you've lost track of your pensions, our Pension Finding Service can help find them for you.`,
          `Once found, we'll send you a message. You'll then have the choice to consider transferring the pension to your Jarvis SIPP so you can always stay up to date with your money.`,
          `You'll have the opportunity to review the policy document of your old pension within the app.`,
          `This service is purely to help you track down details of your pensions. If you wish to move your pension, you should discuss this with a financial adviser to check that this is right for you. If you do not have a financial adviser, you can find one at unbiased.co.uk. A financial adviser may charge for their advice.`,
          `All pension provision and pension consolidation servives are supplied solely by Jarvis, the terms and conditions for which form a separate arrangement and service to the Pension Finding Service.`,
        ],
        links: [{
          title: 'unbiased.co.uk',
          url: 'https://www.unbiased.co.uk',
        }],
        helpCenterSearchTerms: ['transfer', 'consolidate', 'tracing', 'trace', 'pension finding']
      }
      break

    //Info about transfer outcomes
    case NamedInformation.TRANSFER_OUTCOMES:
      ibProps = {
        title: 'Pension Transfers',
        texts: [
          `If the pension you are attempting to consolidate qualifies according to our criteria, you can proceed to transfer it.`,
          `Once confirmed, we'll electronically request your old provider to transfer the funds into your Jarvis SIPP.`,
          `Be mindful of any benefits, restrictions, or protections you may relinquish by proceeding with the transfer.`,
          `If you wish to move your pension, you should discuss this with a financial adviser to check that this is right for you. If you do not have a financial adviser, you can find one at unbiased.co.uk. A financial adviser may charge for their advice.`,
        ],
        links: [{
          title: 'unbiased.co.uk',
          url: 'https://www.unbiased.co.uk',
        }],
        helpCenterSearchTerms: ['transfer', 'consolidate']
      }
      break

    //Onboarding phases...
    case NamedInformation.ONBOARDING_PHASE_PLAN:
      ibProps = {
        title: 'Your Retirement Age',
        texts: [
          `Lack of clarity is one of the key reasons we created Jarvis.`,
          `Simply knowing how much is in your pension today and the income it could prvoide in future doesn't really tell you what you need to know.`,
          `We do things differently: by capturing information on your circumstances, desired future lifestyle and your current planning, Jarvis can tell you exactly when you could expect to be able to retire.`,
          `Furthermore, it can help you understand how to close the gap, showing you the impact of changes before you make them.`,
          `Once you have completed the steps below, Jarvis can build a personalised financial forecast and tell you how close you are to your goals.`,
          `Remember, the more you tell Jarvis, the more accurate your forecast will be. Whilst you could skip through these quickly, we recommend spending a few minutes on each getting your picture straight - you'll only have to do this once!`,
        ],
        helpCenterSearchTerms: []
      }
      break

    case NamedInformation.ONBOARDING_PHASE_CONTRIBUTE:
      ibProps = {
        title: 'Contributing To Your Future',
        texts: [
          `The most important thing you can do today, to prepare for your future, is to start putting aside money that you can use in your later years.`,
          `Once your have completed the 'Plan Your Retirement' checklist, Jarvis can help you understand the contributions needed to achieve your goals, showing you the impact before you start putting money away.`,
          `Of course, you're free to start contributing right away without Jarvis' support, but we would always recommend circling back later to make adjustments.`,
        ],
        helpCenterSearchTerms: ['contribute',]
      }
      break

    case NamedInformation.ONBOARDING_PHASE_CONSOLIDATE:
      ibProps = {
        title: 'Consolidating Pensions',
        texts: [
          `Bringing other pensions into your ${JAR_NAME_PERSONAL} creates a single source for everything you need to retire into the lifestyle you want.`,
          `You'll have a single up-to-date pension balance that you can see at any time.`,
          `What's more, once your have completed the 'Plan Your Retirement' checklist, Jarvis can show you how this money could grow over time.`,
          `It can track progress against your plan to ensure you are on track, and make accurate suggestions if course-correction is needed.`,
          `Consolidating your pensions may not be right for everyone. If you are in doubt you should seek advice from a qualified independent financial adviser.`,

        ],
        helpCenterSearchTerms: ['consolidation']
      }
      break
  
    //PA-2033 - Remove Family Onboarding Card
    // case NamedInformation.ONBOARDING_PHASE_FAMILY:
    //   ibProps = {
    //     title: 'Defining Beneficiaries',
    //     texts: [
    //       `Whilst pensions are personal pots to save for your own retirement, this doesn't mean the money saved is lost in the event of your death.`,
    //       `Defining beneficiaries for any surplus capital in your ${JAR_NAME_ALL} can give peace of mind that your wishes are recorded.`,
    //     ],
    //     helpCenterSearchTerms: ['family', 'beneficiary', 'beneficiaries']
    //   }
    //   break

  
    //Sections...
    case NamedInformation.SECTION_DASHBOARD_TODAY:
      ibProps = {
        title: 'Dashboard / Today',
        texts: [
          `The Today screen shows you everything happening between now and retirement, including current asset values, your Jarvis investment strategy, and recent contributions and transfers of old pensions.`,
        ],
        helpCenterSearchTerms: ['dashboard', 'today']
      }
      break

    case NamedInformation.SECTION_DASHBOARD_RETIREMENT:
      ibProps = {
        title: 'Dashboard / Retirement',
        texts: [
          `The Retirement screen shows you everything about the future life you are working towards, including the age when you can achieve it, the lifestyle you are targeting, and how you are planning to fund it.`,
        ],
        helpCenterSearchTerms: ['dashboard', 'retirement']
      }
      break

    case NamedInformation.SECTION_DASHBOARD_LEGACY:
      ibProps = {
        title: 'Dashboard / Legacy',
        texts: [
          `Your Legacy screen looks at your planning beyond retirement - who will inherit any residual savings, and how much might they expect to receive?`,
        ],
        helpCenterSearchTerms: ['dashboard', 'legacy']
      }
      break

    //Dashboard sections...
    case NamedInformation.DASHBOARD_TODAY_CURRENT_ASSETS:
      ibProps = {
        title: 'Current Assets',
        texts: [
          `This table gives you a quick summary of all your current assets that you are keeping to fund your retirement.`,
          `It includes the balances for ${JAR_NAME_ALL} ${JAR_NAME}s, the total value of any other pensions and assets you have recorded, and if relevant, any pending contributions to your ${JAR_NAME_ALL}.`,
          `If you have linked your account with a partner, it will also show their ${JAR_NAME_ALL} balance, giving you a complete picture of your current assets.`,
        ],
        helpCenterSearchTerms: ['assets']
      }
      break

    case NamedInformation.DASHBOARD_TODAY_INVESTMENT_PLAN:
      ibProps = {
        title: 'Investment Plan',
        texts: [
          `Your Jarvis Investment Strategy is the underlying fund, or set of funds into which you have chosen to invest your pension savings.`,
          `We offer a range of investment plans to suit different risk appetites and priorities.`,
          `You can change your investment plan at any time if your priorities change.`,
        ],
        helpCenterSearchTerms: ['invesment plan', 'risk', 'fund']
      }
      break

    case NamedInformation.DASHBOARD_TODAY_ASSET_BREAKDOWN:
      ibProps = {
        title: 'Current Asset Breakdown',
        texts: [
          `Your Current Asset Breakdown chart shows you how your total current retirement savings are made up from different sources.`,
          `This includes your ${JAR_NAME_ALL} ${JAR_NAME}s, any partner's ${JAR_NAME_ALL} if you have connected accounts, and other pensions and retirement assets you have added.`,
          `It also includes any pending contributions that are on their way to your ${JAR_NAME_ALL}.`,
        ],
        helpCenterSearchTerms: ['asset', 'contribution', 'partner', 'spouse']
      }
      break


    case NamedInformation.DASHBOARD_TODAY_RECENT_CONTRIBUTIONS:
      ibProps = {
        title: 'Recent Contributions',
        texts: [
          `This table shows you the most recent contributions into your ${JAR_NAME_ALL}, including both one-off and regular contributions.`,
          `It also includes any transfers of old pensions into your ${JAR_NAME_PERSONAL}`,
          `Recently completed and pending transactions are shown - you can see all contributions, including upcoming and historic in the Contributions area.`
        ],
        helpCenterSearchTerms: ['transfer', 'contribution']
      }
      break

    case NamedInformation.DASHBOARD_RETIREMENT_ANNUAL_BUDGET_SUMMARY:
      ibProps = {
        title: 'Annual Budget Summary',
        texts: [
          `This table gives you a quick summary of how your annual finances will look in retirement.`,
          `It shows your Gross Lifestyle Cost, being the amount of income required, before tax, to support the budget you have defined for you retirement.`,
          `It then shows how much of that cost is being funded from your Regular Income in retirement such as State Pensions and anything else you have added, such as defined benefit pensions or rental income.`,
          `Note that in some cases, these incomes may not be immediately available at the time you retire - this summary shows you the amount when all incomes are available.`,
          `Finally, the table shows you one of two things: either Required Drawdown, being the amount of money that would need to be taken from your assets annual to bridge the gap; or if your Regular Income is higher than your Gross Lifestyle Cost, it shows the additional Disposable Income you would have each year.`,
        ],
        helpCenterSearchTerms: ['regular income', 'drawdown', 'budget', 'gross lifestyle cost']
      }
      break

    case NamedInformation.DASHBOARD_RETIREMENT_BUDGET:
      ibProps = {
        title: 'Monthly Budget',
        texts: [
          `Your budget in retirement is key to effective retirement planning.`,
          `This card summarises the monthly budget you have defined for your retirement, and helps you make changes if circumstances change.`,
        ],
        helpCenterSearchTerms: ['budget']
      }
      break

    case NamedInformation.DASHBOARD_RETIREMENT_TARGET:
      ibProps = {
        title: 'Retirement Target',
        texts: [
          `The Retirement Target card shows you how you are doing against the Target Retirement Age you set.`,
          `It also helps you understand what would be needed to close the gap, and offer any goals you may want to tackle to reduce your achievable retirement age.`
        ],
        helpCenterSearchTerms: ['target', 'goal', 'retirement age']
      }
      break

    case NamedInformation.DASHBOARD_RETIREMENT_INCOME_BREAKDOWN:
      ibProps = {
        title: 'Income Breakdown',
        texts: [
          `Your Income Breakdown chart shows you how your retirement budget is projected to be funded.`,
          `Any state pensions and retirement incomes are taken into account, showing you the remaining proportion that needs to be funded from the pensions assets that you have built up by the point of retirement.`,
        ],
        helpCenterSearchTerms: ['retirement incomes', 'drawdown', 'state pension']
      }
      break
          
    case NamedInformation.DASHBOARD_LEGACY_RETIREMENT_DRAWDOWN:
      ibProps = {
        title: 'Retirement Drawdown (or Growth)',
        texts: [
          `This table gives you a quick summary of how your Retirement Assets will be depleted (or in some cases, how they will grow) over the period of your retirement.`,
          `It shows the value of your assets at the point your retire, and then either the Estimated Total Spend or Growth During Retirement, depending on your circumstances.`,
          `For most people, your assets will need to be used during retirement to supplement your Regular Income and fund your lifestyle.`,
          `However, if your retirement lifestyle can be funded entirely (or almost entirely) from Regular Income, you may find that your asset value remains stable or even continues to grow during retirement.`,
          `Finally, it shows the Predicted Remaining amount of money when you reach your life expectancy.`,
          `If you Achievable Retirement Age is greater than your Target Age, this will generally be close to zero, as your personalized financial model is using all of your assets to show you the earliest point you can retire.`,
          `However, if Jarvis predicts you can achieve your Target Retirement Age with your current assets and incomes, this figure may be higher and shows the predicted amount that could be left to your beneficiaries.`,
        ],
        helpCenterSearchTerms: ['assets']
      }
      break

    case NamedInformation.DASHBOARD_LEGACY_BENEFICIARIES:
      ibProps = {
        title: 'Beneficiaries',
        texts: [
          `Defining beneficiaries for your ${JAR_NAME_ALL} is a way of recording who you would like to inherit any residual capital left in your pension at the time of your death.`,
          `The benefiaries you define will be used in conjunction with any will you hold and applicable laws.`,
        ],
        helpCenterSearchTerms: ['beneficiaries', 'nominees', 'inheritance'],

      }
      break
      
    case NamedInformation.DASHBOARD_LEGACY_INHERITANCE_BREAKDOWN:
      ibProps = {
        title: 'Inheritance Breakdown',
        texts: [
          `Your Inheritance Breakdown chart shows you how any residual savings in your ${JAR_NAME_ALL} and other assets would be divided between your beneficiaries.`,
          `It serves as a quick reminder of the proportions you have allocated to loved ones or charities.`,
        ],
        helpCenterSearchTerms: ['beneficiaries', 'inheritance']
      }
      break
            
    default:
      ibProps = {
        title: 'Coming Soon',
        texts: [
          `More information coming soon!`,
        ],
      }
  }

  const overrideProps = pickBy({
    imageSource,
    iconOnly,
    iconOnlySize,
    iconColor,
    buttonTitle,
    noImage,
    noStartCase,
  }, identity)

  return (
    <InformationButton
      {...ibProps}
      {...overrideProps}
    />
  )
}