import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { LegacyScreen } from './Screens/LegacyScreen'

export const LegacySectionScreen = ({ route }) => {
  return (
    <SectionSimpleContainer
      sectionTitle={`Legacy Planning`}
    >
      <LegacyScreen />
    </SectionSimpleContainer>
  )
}