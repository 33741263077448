import { BeneficiariesSetup_01_Beneficiaries, BeneficiariesSetup_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'

export const BeneficiariesSetupProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='BeneficiariesSetup'
      processTitle='Beneficiaries'
      previousRoute='BeneficiariesSetupIntroScreen'
      nextRoute='BeneficiariesSetupHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'FirstName',
          component: BeneficiariesSetup_01_Beneficiaries,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: BeneficiariesSetup_99_Result,
      }}
    />
  )
}
