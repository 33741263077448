import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE } from 'lib/constants'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_10_DateOfBirth = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  //Temp state
  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  //Setup form
  const formObj = useForm<{ birthDate: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      birthDate: workingCalculatorRequestData?.client?.birthDate || '',
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingCalculatorRequest({
      client: {
        ...workingCalculatorRequestData?.client,
        birthDate: attributes.birthDate,
      }
    }))
    ampli.calculatorUsage({
      processStep: 'client_dob',
      loggedInUserId,
      affiliateCode: linkConfig?.affiliateCode,
      affiliateRef: linkConfig?.affiliateRef,
    })
    navigation.navigate(nextScreen)
  }

  const isWeb = platformIsWeb()

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, first up - when were you born?`}
      subHeading={'Your age determines both life expectancy, and the remaining time until retirement'}
    >
      <ManagedDateInput
        name={'birthDate'}
        autoFocus={true}
        formObj={formObj}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        required={true}
        mustBeInPast={true}
        notOlderThanYears={MAX_CLIENT_AGE}
        notYoungerThanYears={MIN_CLIENT_AGE}
        tryParseTwoDigitYear={true}
        showCurrentAgeMessage={true}
      />
    </ProcessScreen>
  )
}
