import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminGroupOrganizationsListScreen } from './Screens/AdminGroupOrganizationListScreen'

export const AdminGroupOrganizationSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Group Organizations'}
    >
      <AdminGroupOrganizationsListScreen />
    </SectionSimpleContainer>
  )
}

