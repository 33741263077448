import Constants from 'expo-constants'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Platform } from "react-native"
import { NotificationChannel, NotificationRefreshAction } from 'store/dto/notification.dto'
import { Logger } from 'lib/logger'
import { api } from 'store/apiSlice'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { flatten, compact } from 'lodash'
import { platformIsWeb } from './platformHelpers'

const isWeb = platformIsWeb()

export const dismissAllNotifications = async () => {
  if (isWeb) {
    return
  }
  return Notifications.dismissAllNotificationsAsync()
}

export const isPushNotificationsEnabled = async (): Promise<boolean> => {
  const { status } = await getPushNotificationPermission()
  return status === 'granted'
}

export const getPushNotificationPermission = async (): Promise<any> => {
  return Notifications.getPermissionsAsync()
}

export const getPushNotificationToken = async (force?: boolean): Promise<any> => {
  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync(NotificationChannel.YOUR_ACCOUNT, {
      name: NotificationChannel.YOUR_ACCOUNT,
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  //Do nothing if not a physical device
  if (!Device.isDevice) {
    return
  }

  const { status: existingStatus } = await getPushNotificationPermission()
  let finalStatus = existingStatus

  //Do nothing and return if user has explicitly denied and not forcing
  if (existingStatus === 'denied' && !force) {
    Logger.debug(`User has previously disallowed push notifications.`)
    return
  }

  //User has not granted (therefore is 'undetermined'), so request permissioms
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync({
      ios: {
        allowAlert: true,
        allowBadge: true,
        allowSound: true,
        allowAnnouncements: true,
      }
    })
    finalStatus = status
  }

  //If they have still not granted permission, do nothing and return
  if (finalStatus !== 'granted') {
    Logger.debug(`User has disallowed push notifications.`)
    return
  }

  return Notifications
    .getExpoPushTokenAsync({
      projectId: Constants.expoConfig.extra.eas.projectId,
    })
    .then(token => {
      return token.data
    })
    .catch(e => {
      Logger.error(`Failed to get Expo push token.`)
      return
    })
}

export const handleNotificationRefreshAction = async (
    notification: Notifications.Notification,
    dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>,
  ): Promise<void> => {
  const refreshActionToCacheTagMap = {
    [NotificationRefreshAction.CLIENT]: { type: 'Client', id: 'ME' },
    [NotificationRefreshAction.CLIENT_PENSION]: { type: 'Account', id: 'ME' },
    [NotificationRefreshAction.SPOUSE]: { type: 'Client', id: 'SPOUSE' },
    [NotificationRefreshAction.SPOUSE_PENSION]: { type: 'Pension', id: 'SPOUSE' },
    [NotificationRefreshAction.RETIREMENT_ASSETS]: { type: 'RetirementAsset' },
    [NotificationRefreshAction.MESSAGES]:  { type: 'Message' },
    [NotificationRefreshAction.CONTRIBUTIONS]: { type: 'Contribution' },
    [NotificationRefreshAction.INVITES]: [{ type: 'Invite' }, { type: 'InviteAsTarget' }],
    [NotificationRefreshAction.SCHEME_INVITES]: { type: 'GroupSchemeInvite' },
    [NotificationRefreshAction.SCHEME_REQUESTS]: { type: 'EnrolmentRequests' },
  }
  const refreshActions: NotificationRefreshAction[] = notification.request?.content?.data?.refreshActions || []
  const tagsToInvalidate = flatten(compact(refreshActions.map(refreshAction => {
    return refreshActionToCacheTagMap[refreshAction]
  })))
  dispatch(api.util.invalidateTags(tagsToInvalidate))
}

