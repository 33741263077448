import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingSpouse, updateWorkingSpouse, workingInvite, updateWorkingInvite } from 'store/tempDataSlice'

export const SpouseInvite_07_Email = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingInviteData = useAppSelector(workingInvite)


  //Setup form
  const formObj = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: workingInviteData?.email
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingInvite(attributes))
    navigation.navigate(nextScreen)
  }
  
  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And what's their email address`}
      subHeading={`This is so we can send them an invitation to get set up`}
    >
      <ManagedTextInput
        name={'email'}
        autoFocus={true}
        formObj={formObj}
        returnKeyType={"next"}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        submitHandler={handleSubmit(onSubmit)}
        keyboardType={'email-address'}
        blurOnSubmit={false}
        rules={{
          required: true,
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address",
          },
        }}
        />
    </ProcessScreen>
  )
}
