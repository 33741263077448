import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { AdminFeaturesListScreen } from './Screens/AdminFeatureListScreen'

export const AdminFeatureSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'User Features'}
    >
      <AdminFeaturesListScreen />
    </SectionSimpleContainer>
  )
}

