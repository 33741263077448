import { envVariables } from 'environment'
import React, { ComponentProps } from 'react'
import { Image } from 'react-native'
import { ProgressiveImage } from './ProgressiveImage'

type AppIllustrationProps = Omit<ComponentProps<typeof Image>, 'source'> & {
  filename: string
}

const { cdnDomain } = envVariables
const illustrationBaseUrl = `${cdnDomain}/app-images/illustrations`
  
export const AppIllustration = (props: AppIllustrationProps) => {
  const { filename, ...remaining } = props || {}
  
  return (
    <ProgressiveImage
      {...remaining}
      thumbnailSource={{ uri: `${illustrationBaseUrl}/thumb/${filename}`}}
      source={{ uri: `${illustrationBaseUrl}/full/${filename}`}}
    />
  )
}
