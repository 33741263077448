import { MD2LightTheme, MD2Theme, configureFonts, useTheme } from 'react-native-paper'
import * as Colors from './colors'
import { MD2Colors } from 'react-native-paper/lib/typescript/types'

declare global {
  namespace ReactNativePaper {
    interface ThemeColors {
      themeType: string
      pageBackground: string
      secondary: string
      headlineText: string
      divider: string
      inputText: string
      progressBar: string
      progressBarBackground: string
      onSurfaceTitle: string
      onSurfaceText: string
      onSurfaceButtonColor: string
      onSurfaceButtonBackgroundColor: string
      onSurfaceAccent: string
    }
    // interface Theme {
    //   myOwnProperty: boolean
    // }
  }
}

const fontConfig: any = {
  default: {
    regular: {
      fontFamily: 'LabGrotesque-Medium',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'LabGrotesque-Medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'LabGrotesque-Medium',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'LabGrotesque-Medium',
      fontWeight: 'normal',
    },
  }
}
fontConfig.ios = fontConfig.default
fontConfig.android = fontConfig.default
fontConfig.web = fontConfig.default

//Notes on colour usage across inputs
//primary: Input labels, underlines
//text: Input content
//error: Input errors
//placeholder: Input placeholder - not working?
//background: Input background

export type AppThemeColors = MD2Colors & {
  themeType: string
  pageBackground: string
  secondary: string
  headlineText: string
  divider: string
  inputText: string
  progressBar: string
  progressBarBackground: string
  onSurfaceTitle: string
  onSurfaceText: string
  onSurfaceButtonColor: string
  onSurfaceButtonBackgroundColor: string
  onSurfaceAccent: string
}

export type AppTheme = MD2Theme & {
  colors: AppThemeColors
}

export const useAppTheme = () => useTheme<AppTheme>()

export const baseTheme = {
  ...MD2LightTheme,
  colors: {
    ...MD2LightTheme.colors,
    background: 'transparent',
  },
  fonts: configureFonts(fontConfig),
}

export const lightTheme: AppTheme = {
  ...baseTheme,
  colors: {
    themeType: 'light',
    ...baseTheme.colors,
    primary: Colors.neutral.white,
    secondary: Colors.brand.purple1,
    pageBackground: Colors.brand.purple0,
    accent: Colors.brand.red3,
    text: Colors.neutral.white,
    headlineText: Colors.neutral.white,
    inputText: Colors.brand.red3,
    placeholder: Colors.brand.grey3,
    divider: Colors.neutral.white,
    error: Colors.brand.red2,
    surface: Colors.neutral.white,
    onSurface: Colors.brand.purple1,
    onSurfaceTitle: Colors.brand.purple1,
    onSurfaceText: Colors.brand.grey1,
    onSurfaceButtonColor: Colors.neutral.white,
    onSurfaceButtonBackgroundColor: Colors.brand.purple1,
    onSurfaceAccent: Colors.brand.purple2,
    disabled: Colors.brand.grey3,
    progressBar: Colors.neutral.white,
    progressBarBackground: Colors.brand.purple1,
  },    
}

//Modified light theme with even darker purple background
export const lightThemeOnDarkerPurple: AppTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    pageBackground: Colors.brand.purplex0,
  },    
}

export const darkTheme: AppTheme = {
  ...baseTheme,
  colors: {
    themeType: 'dark',
    ...baseTheme.colors,
    primary: Colors.brand.purple1,
    secondary: Colors.neutral.white,
    pageBackground: Colors.neutral.white,
    accent: Colors.brand.purple2,
    text: Colors.brand.grey1,
    headlineText: Colors.brand.grey1,
    inputText: Colors.brand.purple2,
    placeholder: Colors.brand.grey2,
    divider: Colors.neutral.black,
    error: Colors.brand.red2,
    surface: Colors.brand.grey4,
    onSurface: Colors.brand.purple1,
    onSurfaceTitle: Colors.brand.purple1,
    onSurfaceText: Colors.brand.grey1,
    onSurfaceButtonColor: Colors.neutral.white,
    onSurfaceButtonBackgroundColor: Colors.brand.purple1,
    onSurfaceAccent: Colors.brand.purple2,
    disabled: Colors.brand.grey2,
    progressBar: Colors.brand.purple1,
    progressBarBackground: Colors.brand.grey2,
  },    
}

//Modified dark theme with light grey background
export const darkThemeOnLightGrey = {
  ...darkTheme,
  colors: {
    ...darkTheme.colors,
    pageBackground: Colors.brand.grey4,
    surface: Colors.neutral.white,
  },    
}

//Modified dark theme with light purple background
export const darkThemeOnLightPurple = {
  ...darkTheme,
  colors: {
    ...darkTheme.colors,
    pageBackground: Colors.brand.purple4,
    surface: Colors.neutral.white,
  },    
}