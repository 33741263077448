import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { useLazyGetGroupSchemeInvitesQuery } from 'store/apiSlice'
import { InviteSearchStatus } from 'store/dto/invite.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerInviteDataId, setShowEmployerAddInviteVisible, setShowEmployerImportInvitesVisible } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerInviteListItem } from '../Components/EmployerInviteListItem'
import { platformIsWeb } from 'lib/platformHelpers'
import { userCanManageInvitesForScheme } from 'lib/groupSchemeHelpers'

const isWeb = platformIsWeb()

export const EmployerInvitesListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  
  const userCanManageInvites = userCanManageInvitesForScheme(currentScheme)

  const [getInvitesQuery, { data: invites, isLoading: invitesIsLoading, error: invitesError, isFetching: invitesIsFetching }] = useLazyGetGroupSchemeInvitesQuery()
  const [refreshing, setRefreshing] = useState(false)

  //Swap results when switching scheme
  useEffect(() => {
    if (currentScheme && isValid) {
      onSubmit(watch())
    }
  }, [currentScheme])

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowInviteDetails = (id: string) => {
    dispatch(setCurrentEmployerInviteDataId(id))
  }

  const invitesList = () => {
    let all = []
    if (invites) {
      invites.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerInviteListItem
        invite={item}
        onPressFunction={() => handleShowInviteDetails(item?.id)}
      />
    )
  }
  
  const handleAddInvite = () => {
    dispatch(setShowEmployerAddInviteVisible(true))
  }

  const handleImportInvites = () => {
    dispatch(setShowEmployerImportInvitesVisible(true))
  }

  const formObj = useForm<{
    search: string,
    status: InviteSearchStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      status: InviteSearchStatus.ANY,
    },
  })

  const { handleSubmit, reset, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const status = attributes.status === InviteSearchStatus.ANY ? undefined : attributes.status
    getInvitesQuery({
      groupSchemeId: currentScheme.id,
      status,
      search: attributes.search,
    })
  }

  const isLoading = invitesIsLoading || invitesIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      status: InviteSearchStatus.ANY,
    })
  }
  
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding invites...')} /> :
          <View style={ Flex.column.start } >
            <View style={{ paddingHorizontal: Sizing.x30 }} >
              <ManagedTextInput
                name={'search'}
                label={'Search for invite'}
                autoFocus={false}
                formObj={formObj}
                placeholder={'Enter part of name, email or national insurance number'}
                blurOnSubmit={false}
                submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                rules={{
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'Must be at least 3 characters'
                  },
                }} />
              <Collapsible collapsed={searchCollapsed}>
                <View>
                  <ManagedAutoCompleteInput
                    name={'status'}
                    label={'Invite Status'}
                    selectOnlyMode={true}
                    formObj={formObj}
                    dataSet={enumToAutocompleteOptions(InviteSearchStatus)}
                  />
                  <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                  <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                </View>
              </Collapsible>
              <Button
                mode='text'
                color={themeColors.accent}
                icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                onPress={searchCollapsed ? showAdvanced : hideAdvanced}
              >
                {searchCollapsed ? 'Advanced search...' : 'Hide'}
              </Button>
              <ContentDivider />
            </View>
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              <OptionalSectionList
                noRefresh={true}
                sections={invitesList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`Please search for a invite`}
              />
            </ScrollView>
            {
              userCanManageInvites
                ? <>
                    <FooterButton
                      mode={'text'}
                      onPress={handleImportInvites}
                    >
                      {'Import Invite Data'}
                    </FooterButton>
                    <FooterButton
                      onPress={handleAddInvite}
                    >
                      {'Invite New Member'}
                    </FooterButton>
                  </>
                : <></>
            }
          </View>
      }
    </MainAppScreen>
  )
}
