import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddAssetMutation, useGetAssetCategoriesQuery } from 'store/apiSlice'
import { CreateAssetDto } from 'store/dto/asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddAssetVisible, showAdminAddAssetVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddAssetsModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddAssetVisible)


    const [addAssetMutation, { data: addedAsset, isLoading: addAssetIsLoading, error: addAssetError, reset: addAssetReset }] = useAddAssetMutation()
  
    const { data: assetCategories, isLoading: assetCategoriesIsLoading, error: assetCategoriesError, refetch: refetchAssetCategories } = useGetAssetCategoriesQuery()
  
    //Show required fields when asset caregories loaded
    useEffect(() => {
      if (assetCategories) {
        trigger()
      }
    }, [assetCategories])

    const formObj = useForm<{
      isin: string
      yahooSymbol: string
      shortName: string
      riskRating: number
      riskRatingScaleMax: number
      isEnabled: boolean
      assetTypeName: string
      logo: string
      assetCategoryCodes: string[]
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        isin: '',
        yahooSymbol: '',
        shortName: '',
        riskRating: undefined,
        riskRatingScaleMax: 7,
        isEnabled: true,
        assetTypeName: '',
        logo: '',
        assetCategoryCodes: [],
      },
    })
    //Form refs for focussing
    const isinRef = useRef(null)
    const yahooSymbolRef = useRef(null)
    const shortNameRef = useRef(null)
    const riskRatingRef = useRef(null)
    const riskRatingScaleMaxRef = useRef(null)
    const assetTypeNameRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
    const riskRatingScaleMax = watch('riskRatingScaleMax')
  
    const onSubmit = async attributes => {
      const data: CreateAssetDto = attributes
      addAssetMutation(data)
    }
  
    useEffect(() => {
      if (addedAsset) {
        close()
      }
    }, [addedAsset])
  
    const close = () => {
      dispatch(setShowAdminAddAssetVisible(false))
    }
  
    const isLoading = addAssetIsLoading || assetCategoriesIsLoading
    const error: any = addAssetError || assetCategoriesError
  
    const isEnabledOptions: ManagedSimpleChoiceItem[] = [
      {
        value: true,
        label: 'Available in Fund Choice',
      },
      {
        value: false,
        label: `Not Available in Fund Choice`,
      },
    ]
  
    const categoryOptions: ManagedMultipleChoiceInputOption[] = assetCategories ? assetCategories.map(assetCategory => {
      return {
        value: assetCategory.code,
        label: assetCategory.name,
      }
    }) : []
  
    return (
      <ModalEditScreen
        formTitle={'Add New Asset'}
        onDismiss={() => dispatch(setShowAdminAddAssetVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new asset?'}
        error={error}
        errorTryAgain={addAssetError ? handleSubmit(onSubmit) : refetchAssetCategories}
        errorCancel={addAssetError ? addAssetReset : close}
        isLoading={isLoading}
        loadingMessage={['Saving asset...']}
        buttonTitle={'Create Asset'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <Subheading>{'Availability'}</Subheading>
        <ManagedSimpleChoiceInput
          name={'isEnabled'}
          formObj={formObj}
          options={isEnabledOptions}
          required={true}
        />
        <ManagedTextInput
          ref={isinRef}
          name={'isin'}
          formObj={formObj}
          label={'ISIN'}
          placeholder={'Full ISIN from SECCL'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => yahooSymbolRef.current?.focus()}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z]{2}[0-9A-Z]{9}[0-9]{1}$/,
              message: "Invalid ISIN"
            }
          }}/>
        <ManagedTextInput
          ref={yahooSymbolRef}
          name={'yahooSymbol'}
          formObj={formObj}
          label={'Yahoo Symbol Code'}
          placeholder={'Symbol Code from Yahoo'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => shortNameRef.current?.focus()}
          rules={{
            required: true,
            minLength: 5,
            maxLength: 20,
        }}/>
        <ManagedTextInput
          ref={shortNameRef}
          name={'shortName'}
          formObj={formObj}
          label={'Short Name'}
          placeholder={'Name used for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => assetTypeNameRef.current?.focus()}
          rules={{
            required: true,
            minLength: 3,
            maxLength: 50,
        }}/>
        <ManagedTextInput
          ref={assetTypeNameRef}
          name={'assetTypeName'}
          formObj={formObj}
          label={'Asset Type'}
          placeholder={'Asset Type used for display grouping'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 3,
            maxLength: 50,
        }}/>
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
        <Paragraph>{'Risk Rating'}</Paragraph>
        <ManagedIntegerInput
          ref={riskRatingRef}
          name={'riskRating'}
          formObj={formObj}
          label={'Risk Rating'}
          submitHandler={() => riskRatingScaleMaxRef.current?.focus()}
          returnKeyType={'next'}
          blurOnSubmit={false}
          required={true}
          minValue={1}
          maxValue={riskRatingScaleMax}
        />
        <Paragraph>{'Risk Rating Out Of'}</Paragraph>
        <ManagedIntegerInput
          ref={riskRatingScaleMaxRef}
          name={'riskRatingScaleMax'}
          formObj={formObj}
          label={'Risk Rating Out Of'}
          returnKeyType={'done'}
          blurOnSubmit={true}
          required={true}
          minValue={5}
          maxValue={10}
        />
        <Paragraph>{'Asset Categories'}</Paragraph>
        <ManagedMultipleChoiceInput
          formObj={formObj}
          name={'assetCategoryCodes'}
          options={categoryOptions}
        />
      </ModalEditScreen>
    )
  }
  
  