import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { InvestmentPlanModalContent } from 'components/Utility/InvestmentPlanModalContent'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import { orderBy, truncate } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetInvestmentPlansQuery, useGetMeQuery, useGetUserFeaturesQuery } from 'store/apiSlice'
import { FeatureCode } from 'store/dto/feature.dto'
import { InvestmentPlanDto } from 'store/dto/investment-plan.dto'
import { AccountDto } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAccount, workingAccount } from 'store/tempDataSlice'
import { Text } from 'components/Typography/Text'
import { Sizing } from 'styles'

export const InvestmentChoice_12_InvestmentPlanChoice = ({ route, navigation }) => {
  const { nextScreen, contextAccount } : { nextScreen: string, contextAccount: AccountDto } = route?.params || {}

  const existingInvestmentPlanId = contextAccount?.investmentPlanId

  //Temp state
  let options: ManagedCardChoiceInputOption[] = []
  const dispatch = useAppDispatch()
  const workingAccountData = useAppSelector(workingAccount)

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchMe } = useGetMeQuery()
  const { data: investmentPlans, isLoading: investmentPlansIsLoading, error: investmentPlansError, refetch: refetchInvestmentPlans } = useGetInvestmentPlansQuery()
  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()

  const isLoading = clientIsLoading || investmentPlansIsLoading || featuresIsLoading
  const error: any = clientError || investmentPlansError || featuresError

  const otherChoiceEnabledForUser = featureIsEnabledForUser(features, FeatureCode.FUND_CHOICE) || featureIsEnabledForUser(features, FeatureCode.GROUP_PENSION)

  //Setup form
  const formObj = useForm<{ investmentPlanId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      investmentPlanId: workingAccountData?.investmentPlanId || undefined
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const investmentPlan = investmentPlans ? investmentPlans.find(p => {
      return p.id === attributes.investmentPlanId
    }) : undefined
    dispatch(updateWorkingAccount({
      ...attributes,
      investmentPlanName: investmentPlan?.name, //For amplitude event tracking
    }))
    //Navigate to Opt Out for new pension, else Terms
    navigation.navigate(contextAccount ? 'Terms' : 'OptOut')
  }

  const buildOptions = (investmentPlans: InvestmentPlanDto[]) => {
    const filtered: InvestmentPlanDto[] = investmentPlans.filter(investmentPlan => {
      return !existingInvestmentPlanId || investmentPlan.id !== existingInvestmentPlanId
    })

    const ordered: InvestmentPlanDto[] = orderBy(filtered, ['priority'], ['desc'])
    options = ordered.map(investmentPlan => {
      return {
        value: investmentPlan.id,
        title: investmentPlan.name,
        description: truncate(investmentPlan.shortDescription, { length: 120 }),
        imageSource: { uri: investmentPlan.logo },
        modalContentFunction: () => <InvestmentPlanModalContent
          accountDescriptor={workingAccountData?.accountDescriptor}
          investmentPlan={investmentPlan}
          segment={client?.segment}
        />,
        modalProceedLabel: 'Select Plan',
        modalCancelLabel: 'Choose Another',
      }
    })
  }

  //Extract plans when available
  if (investmentPlans && client) {
    buildOptions(investmentPlans)
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Loading plans...']}
      error={error}
      errorTryAgain={investmentPlansError ? refetchInvestmentPlans : refetchMe}
      buttonTitle={isValid ? 'Next' : 'Make Selection'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      showButton={true}
      headline={contextAccount
        ? `Which plan do you want to switch to?`
        : `Ok, time to pick a plan that suits you`
      }
      subHeading={existingInvestmentPlanId
        ? `You're currently on our '${contextAccount?.investmentPlan?.name}' plan`
        : undefined}
      footerInfo={<Text style={{ fontSize: Sizing.x12 }}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Text>}
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'investmentPlanId'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        options={options}
      />
    </ProcessScreen>
  )
}
