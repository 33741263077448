import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getInviteName } from 'lib/inviteHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useDeclineAllInvitesMutation, useGetCurrentUserQuery, useGetInviteAssetsQuery, useGetInviteIncomesQuery, useGetInvitesAsTargetQuery } from 'store/apiSlice'
import { InviteDto } from 'store/dto/invite.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingAcceptInvite } from 'store/tempDataSlice'

export const SpouseAdd_10_InviteChoice = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)

  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: invites, error: invitesError, isLoading: invitesIsLoading, refetch: refetchInvites } = useGetInvitesAsTargetQuery()

  const [declineAllInvites, { data: declineAllResponse, isLoading: declineAllIsLoading, error: declineAllError }] = useDeclineAllInvitesMutation()

  const hasInvite = invites && invites.length
  const multipleInvites = invites && invites.length > 1
  const singleInvite = hasInvite && !multipleInvites ? invites[0] : undefined


  //Setup form
  const formObj = useForm<{ inviteId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      inviteId: hasInvite
        ? multipleInvites
          ? user?.preferredInviteId
            ? user?.preferredInviteId
            : ''
          : invites[0].id
        : '',
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const getSelectedInvite = (inviteId: string): InviteDto => {
    return invites ? invites.find(invite => {
      return invite.id === inviteId
    }) : undefined
  }

  const onSubmit = attributes => {
    setShowDialog(false)
    const { inviteId } = attributes
    //Decline all
    if (inviteId === '') {
      declineAllInvites()
      //Send decline amplitude event if declining a single invite
      if (!multipleInvites) {
        const declinedInvite = invites ? invites[0] : undefined
        if (declinedInvite) {
          ampli.inviteDecline({
            email: declinedInvite?.email,
            invitorUserId: declinedInvite?.userId
          })
        }
      }
    } else {
      const selectedInvite = getSelectedInvite(attributes.inviteId)
      if (selectedInvite) {
        dispatch(updateWorkingAcceptInvite({
          id: selectedInvite?.id
        }))
        if (hasAssets) {
          navigation.navigate('Assets')
        } else if (hasIncomes) {
          navigation.navigate('Incomes')
        } else {
          navigation.navigate('Results', { useInvite: true })
        }
      }
    }
  }

  const goToTransition = () => {
    navigation.navigate('DeclineTransition')
  }

  //Dismiss on successful decline
  useEffect(() => {
    if (declineAllResponse) {
      goToTransition()
    }
  }, [declineAllResponse])


  const selectedInvite = getSelectedInvite(watch('inviteId'))

  const inviteOptions: ManagedSimpleChoiceItem[] = invites? invites.map(invite => {
    const label = multipleInvites ? getInviteName(invite) : 'Accept'
    return {
      value: invite.id,
      label,
    }
  }) : []
  inviteOptions.push({
    value: '',
    label: multipleInvites ? 'Decline All' : 'Decline',
  })

  const hasSelected = !!watch('inviteId')
  
  const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch: refetchInviteAssets } = useGetInviteAssetsQuery(selectedInvite?.id, { skip: !selectedInvite })
  const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchInviteIncomes } = useGetInviteIncomesQuery(selectedInvite?.id, { skip: !selectedInvite })

  const refetchAll = () => {
    refetchUser()
    refetchInvites()
    refetchInviteAssets()
    refetchInviteIncomes()
  }

  const isLoading = userIsLoading || invitesIsLoading || assetsIsLoading || incomesIsLoading || declineAllIsLoading
  const error: any = userError || invitesError || assetsError || incomesError || declineAllError

  const hasAssets = assets?.length
  const hasIncomes = incomes?.length

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={declineAllIsLoading ? ['Declining invites...'] : undefined}
      error={error}
      errorTryAgain={declineAllError ? handleSubmit(onSubmit) : refetchAll}
      buttonTitle={hasInvite
        ? hasSelected
          ? hasAssets || hasIncomes
            ? 'Next'
            : 'Accept Invitation'
          : multipleInvites
            ? 'Decline All'
            : 'Decline Invitation'
        : 'Next'
      }
      buttonAction={hasInvite ? () => setShowDialog(true) : () => navigation.navigate(nextScreen)}
      enableButton={!declineAllIsLoading && !assetsIsLoading && !incomesIsLoading && (!hasInvite || isValid)}
      headline={multipleInvites ? `Please choose an invitation` : `Accept the invitation?`}
      subHeading={multipleInvites
        ? `You may only choose one to accept - the others will be declined`
        : `It came from ${getInviteName(singleInvite)} (${singleInvite?.from?.email})`
      }
      subHeadingInfo={hasInvite ? <NamedInformationButton name={NamedInformation.SPOUSE_DATA_SHARING} buttonTitle={'About Partner Linking'}/> : undefined}
    >
      <ManagedSimpleChoiceInput
        name={'inviteId'}
        formObj={formObj}
        options={inviteOptions}
        required={false}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Please confirm'}
        content={hasSelected
          ? `I have read the data sharing information and agree to link my account with ${getInviteName(selectedInvite)} (${selectedInvite?.from?.email})`
          : multipleInvites
            ? `I want to decline all invitations and don't want to link my Jarvis account. I understand that I will need to be invited again to link accounts at a later point.`
            : `I want to decline the invitation and don't want to link my Jarvis account. I understand that I will need to be invited again to link accounts at a later point.`
        }
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}
