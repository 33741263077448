import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from 'lib/appInfoHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { AmlStatus } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetup, workingContributionSetup } from 'store/tempDataSlice'
import { Colors } from 'styles'

export const MakeContributions_01_ContributionSource = ({ route, navigation }) => {
  const dispatch = useAppDispatch()

  const { data: client, isLoading, error } = useGetMeQuery()
  const businessBlocked = client?.contributionBusinessAmlStatus === AmlStatus.FAIL

  const workingContributionData = useAppSelector(workingContributionSetup)
  const { nextScreen, contributionsData }  = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}

  const formObj = useForm<{ source: ContributionSource }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      source: workingContributionData?.source
        ? workingContributionData?.source
        : existingContributionConfiguration?.source
          ? existingContributionConfiguration?.source
          : businessBlocked
          ? ContributionSource.PERSONAL
          : undefined,
    }
  })

  const { handleSubmit, formState: { isValid } } = formObj

  const sourceOptions: ManagedSimpleChoiceItem[] = [
    {
      value: ContributionSource.PERSONAL,
      label: `Your personal funds`,
    },
    {
      value: ContributionSource.EMPLOYER,
      label: 'Your limited company',
      disabled: businessBlocked,
    }
  ]

  const onSubmit = attributes => {
    dispatch(updateWorkingContributionSetup(attributes))
    if (attributes.source === ContributionSource.PERSONAL) {
      navigation.navigate('Bank')
    } else {
      navigation.navigate(nextScreen)
    }
  }

  const contactSupport = async () => {
    const informationArray = getDiagnosticInformation(undefined, client)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
    ])
    let initialMessage = `----- Jarvis Limited Company Contributions -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Client limited company verification required.\n`
    initialMessage += `---------------------------------------\n`
    await composeIntercomMessage(initialMessage)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`From where will you make contributions?`}
      subHeading={'The source of funds affects the tax handling for your money you invest'}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.CONTRIBUTION_SOURCE} />}
    >      
      <ManagedSimpleChoiceInput
        name={'source'}
        formObj={formObj}
        options={sourceOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
      {
        businessBlocked
          ? <>
              <Paragraph>{`You're not currently approved to make contributions from your limited company. Please contact support for more information.`}</Paragraph>
              <Button
                mode={'text'}
                color={Colors.brand.red3}
                onPress={contactSupport}
              >
                {'Contact Support'}
              </Button>
            </>
          : <></>
      }
    </ModalProcessScreen>
  )
}

