import React from 'react'
import { StyleSheet, View } from "react-native"
import { Flex, Sizing } from "styles"

export const FooterInfo = ({children}) => {
  
  return (
    <View style={localStyles.footer}>
      <View style={localStyles.infoContainer}>
        {children}
      </View>
    </View>
  )
}

const localStyles = StyleSheet.create({
  footer: {    
    marginBottom: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: Sizing.x5,
    paddingBottom: Sizing.x5,
  },
  infoContainer: {
    paddingHorizontal: Sizing.x30,
  },
})