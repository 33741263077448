import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerDashboardScreen } from './Screens/EmployerDashboardScreen'

export const EmployerDashboardSectionScreen = ({route, navigation}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Dashboard'}
    >
      <EmployerDashboardScreen />
    </SectionSimpleContainer>
  )
}

