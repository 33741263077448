import { ContentDivider } from "components/Layout/ContentDivider"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { AppIllustration } from "components/Utility/AppIllustration"
import { Button } from "components/Utility/Button"
import { ConfirmationDialog } from "components/Utility/ConfirmationDialog"
import { goToOnboardingTasks } from "lib/RootNavigation"
import { scaleNormalizer } from "lib/scaleHelpers"
import { OnboardingPhase } from "providers"
import React, { useState } from 'react'
import { Image, StyleSheet, View } from "react-native"
import { Colors, Paper, Sizing } from "styles"

type GuidanceGuidedModalContentProps = {
  modelAvaiable: boolean
  title: string
  description: string
  illustrationFilename: string
}

export const GuidanceGuidedModalContent = (props: GuidanceGuidedModalContentProps) => {
  const { modelAvaiable, title, description, illustrationFilename } = props || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  return (
    <>
      <Headline style={localStyles.title}>{title}</Headline>

      <ContentDivider />
      <View style={{
        alignSelf: 'center',
      }}>
        <AppIllustration filename={illustrationFilename} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Subheading style={localStyles.text}>{description}</Subheading>
      <Paragraph style={localStyles.text}>{'Decide what you want to achieve, and based on your exact personal circumstances, Jarvis can propose different ways to get there via one-off and regular contributions.'}</Paragraph>
      {
        modelAvaiable ? <></> :
        <>
          <Paragraph style={localStyles.boldText}>{`To enable Jarvis Superpowers, first complete 'Plan Your Retirement'.`}</Paragraph>
          <Button
            mode={'text'}
            onPress={showDialog}
          >{`Go to Plan Your Retirement`}</Button>
        </>
      }
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`Exit contribution setup and go to 'Plan Your Retirement' now?\n\nOnce completed, simply return to contributions to take advantage of the Jarvis Superpowers.`}
        onCancel={hideDialog}
        onConfirm={() => goToOnboardingTasks(OnboardingPhase.PLAN)}
        confirmLabel={'Go Now'}
      />
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    // textAlign: 'left',
  },
  subTitle: {
    // textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    // textAlign: 'left',
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})