import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph, Subheading } from "components/Typography"
import { SegmentBadge } from 'components/Typography/SegmentBadge'
import { formatPercentageAmount } from 'lib/generalHelpers'
import React from 'react'
import { useGetMeQuery } from 'store/apiSlice'
import { Sizing } from 'styles'

export const InvestmentChoice_03_Promotion = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, refetch } = useGetMeQuery()

  const segment = client?.segment
  const segmentHasConditions = segment?.requiredBalance && segment?.monthsToReachRequiredBalance && segment?.dateToReachRequiredBalance

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      isLoading={clientIsLoading}
      error={clientError}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={`Thanks for helping us grow!`}
      subHeading={`You've been confirmed as part of the ${segment?.name}`}
    >
      <SegmentBadge
        segment={segment}
        containerStyle={{
          paddingVertical: Sizing.x20,
        }}
        style={{
          fontSize: Sizing.x30,
        }}
      />
      <Subheading>
        {`You receive a `}
        <Subheading style={{ fontWeight: '900' }}>{formatPercentageAmount(segment?.platformChargeDiscount * 100, 0)}</Subheading>
        {` discount on our standard Platform Charges.`}
      </Subheading>
      <Paragraph>{`${segmentHasConditions ? `Conditions Apply. ` : ''}Tap on the badge above for more information.`}</Paragraph>
    </ProcessScreen>
  )
}
