import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { JAR_NAME_ALL } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import { Sizing } from 'styles'


export const InvestmentChoice_21_Drawdown = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

   //Setup form
   const formObj = useForm<{ drawdownAccepted: string[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      drawdownAccepted: []
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    navigation.navigate(nextScreen)
  }

  const options: ManagedMultipleChoiceInputOption[] = [{
    value: 'yes',
    label: `I understand`,
  }]

  const hasConfirmed = watch('drawdownAccepted').includes('yes')


  return (
    <ProcessScreen
    buttonTitle={hasConfirmed ? 'Next' : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmed}
      headline={`And just for clarity...`}
      subHeading={`We're busy building, but Jarvis doesn't yet offer a drawdown facility`}
      subHeadingInfo={<NamedInformationButton buttonTitle={'What does this mean?'} name={NamedInformation.DRAWDOWN} />}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'drawdownAccepted'}
        options={options}
      />
      <Paragraph>{`As always, you are free to transfer your ${JAR_NAME_ALL} to another provider at any time.`}</Paragraph>
      <AppIllustration
        filename={'meditating_man.png'} style={[
        {
          width: '100%',
          height: Sizing.x200,
          resizeMode: 'contain',
          alignSelf: 'center'
        },
      ]} />
    </ProcessScreen>
  )
}
