import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Button } from 'components/Utility/Button'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetSpouseQuery, useGetStateBenefitQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse, workingSpouse } from 'store/tempDataSlice'
import { Colors } from 'styles'

export const RetirementIncomeSetup_04_SpouseStatePensionAmount = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()
  const workingSpouseData = useAppSelector(workingSpouse)

  const { data: spouse, error: spouseError, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: stateBenefit, error: stateBenefitError, isLoading: stateBenefitIsLoading, refetch: refetchBenefits } = useGetStateBenefitQuery({
    birthDate: spouse?.birthDate,
    gender: spouse?.gender,
  }, {
    skip: !spouse
  })

  const refetchAll = () => {
    refetchSpouse()
    refetchBenefits()
  }

  const isLoading = spouseIsLoading || stateBenefitIsLoading
  const error: any = spouseError || stateBenefitError

  //Setup form
  const formObj = useForm<{ statePensionAmount: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionAmount: workingSpouseData?.statePensionAmount || spouse?.statePensionAmount || 0,
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSpouse(attributes))
    navigation.navigate('StatePensionSave') //Skip to save
  }

  const setFullAmount = () => {
    setValue('statePensionAmount', stateBenefit?.maxAmount, { shouldDirty: true })
    trigger('statePensionAmount')
  }

  const isFullAmount = watch('statePensionAmount') === stateBenefit?.maxAmount

  const forecastUrl = 'https://www.gov.uk/check-state-pension'

  return (
    <ProcessScreen
      error={error}
      errorTryAgain={refetchAll}
      isLoading={isLoading}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`How much are they expecting to receive?`}
      subHeading={`This should be the annual amount they are expecting from the UK government`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.STATE_PENSION} buttonTitle={'About State Pensions'}/>}
    >      
      <ManagedCurrencyInput
        name={'statePensionAmount'}
        autoFocus={true}
        formObj={formObj}
        returnKeyType="next"
        placeholder={'Expected amount p.a.'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        minValue={1}
        maxValue={stateBenefit?.maxAmount}
        blurOnSubmit={true}
        informationMessage={`Maximum amount they could receive p.a. is ${formatCurrencyAmount(stateBenefit?.maxAmount)}`}
      />
      {
        !isFullAmount ?
        <Button color={Colors.brand.red3} mode='text' onPress={setFullAmount} >Set full amount</Button>
        : <></>
      }
    </ProcessScreen>
  )
}
