import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerHistoryRequestsListScreen } from './Screens/EmployerHistoryRequestsListScreen'

export const EmployerHistoryRequestSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Request History'}
    >
      <EmployerHistoryRequestsListScreen />
    </SectionSimpleContainer>
  )
}

