import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { isNilOrEmptyString } from 'lib/generalHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { orderBy, sortBy } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddGroupSchemeMutation, useGetGroupOrganizationsQuery, useGetGroupPortfoliosQuery, useGetPensionBrandsQuery } from 'store/apiSlice'
import { GroupSchemeRequestType } from 'store/dto/account.dto'
import { GroupOrganizationStatus } from 'store/dto/group-organization.dto'
import { GroupSchemeEnrolmentPayrollFrequency, GroupSchemeSalarySacrificeMode, GroupSchemeStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddGroupSchemeVisible, showAdminAddGroupSchemeVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'

export const AddGroupSchemesModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {


    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddGroupSchemeVisible)


    const [addGroupSchemeMutation, { data: addedGroupScheme, isLoading: addGroupSchemeIsLoading, error: addGroupSchemeError, reset: addGroupSchemeReset }] = useAddGroupSchemeMutation()

    const { data: organizations, error: organizationsError, isLoading: organizationsIsLoading, refetch: refetchGroupOrganizations } = useGetGroupOrganizationsQuery({
      status: GroupOrganizationStatus.ACTIVE,
    })
    const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  
    const { data: groupPortfolios, isLoading: groupPortfoliosIsLoading, error: groupPortfoliosError, refetch: refetchGroupPortfolios } = useGetGroupPortfoliosQuery()
  
    //Show required fields when brands, orgs and portfolios loaded
    useEffect(() => {
      if (brands && organizations && groupPortfolios) {
        trigger()
      }
    }, [brands, organizations, groupPortfolios])

    const mandatedRequestTypes: GroupSchemeRequestType[] = [
      GroupSchemeRequestType.OPT_OUT,
      GroupSchemeRequestType.OPT_IN,
      GroupSchemeRequestType.CEASE_MEMBERSHIP,
    ]
  
    const availableRequestTypes: GroupSchemeRequestType[] = [
      GroupSchemeRequestType.OPT_IN,
      GroupSchemeRequestType.OPT_OUT,
      GroupSchemeRequestType.CEASE_MEMBERSHIP,
      GroupSchemeRequestType.SET_GROSS_CONTRIBUTION,
    ]
  
  
    const formObj = useForm<{
      name: string
      tprLetterCode: string
      dutiesStartDate?: string
      defaultEmployerContributionPercentage: number
      defaultEmployeeContributionPercentage: number
      salarySacrificeMode: GroupSchemeSalarySacrificeMode
      isAutoEnrolment: boolean
      defaultPayrollFrequency: GroupSchemeEnrolmentPayrollFrequency
      defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
      organizationId: string
      defaultGroupPortfolioId: string
      defaultEnrolmentName: string
      defaultTreatContributionsAsRegular: boolean
      previousPensionProviderBrandKey?: string
      previousPensionProviderBrandName?: string
      enabledRequestTypes?: GroupSchemeRequestType[]
      status: GroupSchemeStatus
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: '',
        tprLetterCode: undefined,
        dutiesStartDate: undefined,
        defaultEmployerContributionPercentage: undefined,
        defaultEmployeeContributionPercentage: undefined,
        salarySacrificeMode: GroupSchemeSalarySacrificeMode.ELECTIVE,
        isAutoEnrolment: undefined,
        defaultPayrollFrequency: undefined,
        defaultContributionFrequency: undefined,
        organizationId: '',
        defaultGroupPortfolioId: undefined,
        defaultEnrolmentName: '',
        defaultTreatContributionsAsRegular: undefined,
        previousPensionProviderBrandKey: '',
        previousPensionProviderBrandName: '',
        enabledRequestTypes: mandatedRequestTypes,
        status: undefined
      },
    })
    //Form refs for focussing
    const nameRef = useRef(null)
    const tprLetterCodeRef = useRef(null)
    const dutiesStartDateRef = useRef(null)
    const defaultEmployerContributionPercentageRef = useRef(null)
    const defaultEmployeeContributionPercentageRef = useRef(null)
    const defaultEnrolmentNameRef = useRef(null)
  
    const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    const onSubmit = async attributes => {
      const {
        defaultEmployerContributionPercentage,
        defaultEmployeeContributionPercentage,
        dutiesStartDate,
        ...remaining
      } = attributes
      addGroupSchemeMutation({
        dutiesStartDate: dutiesStartDate && dutiesStartDate !== '' ? dutiesStartDate : undefined,
        defaultEmployerContributionPercentage: defaultEmployerContributionPercentage && defaultEmployerContributionPercentage !== ''
          ? parseFloat(defaultEmployerContributionPercentage)
          : undefined,
        defaultEmployeeContributionPercentage: defaultEmployeeContributionPercentage && defaultEmployeeContributionPercentage !== ''
          ? parseFloat(defaultEmployeeContributionPercentage)
          : undefined,
        ...remaining,
      })
    }
  
    useEffect(() => {
      if (addedGroupScheme) {
        close()
      }
    }, [addedGroupScheme])
  
    const close = () => {
      dispatch(setShowAdminAddGroupSchemeVisible(false))
    }
  
    const isLoading = groupPortfoliosIsLoading || organizationsIsLoading || brandsIsLoading || addGroupSchemeIsLoading
    const error: any = organizationsError || brandsError || groupPortfoliosError || addGroupSchemeError
  
    const groupPortfolioOptions: AutoCompleteItem[]  = groupPortfolios ? sortBy(groupPortfolios.map((groupPortfolio): AutoCompleteItem => {
      return {
        value: groupPortfolio.id,
        label: groupPortfolio.name,
        description: groupPortfolio.description,
        icon: () => <Image source={{ uri: groupPortfolio?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      }
    }), 'name')  : []
  
    const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
      return {
        value: brand.key,
        label: brand.name,
        description: brand.description,
        icon: brand?.logo
        ? () => <Image source={{ uri: brand?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
      }
    }) : []
  
    
    const organizationOptions: AutoCompleteItem[] = organizations ? orderBy(organizations, ['name'], ['asc']).map(organization => {
      return {
        value: organization?.id,
        label: organization?.name,
        icon: organization?.logo
        ? () => <Image source={{ uri: organization?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'office-building'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
      }
  
    }) : []
  
    const defaultTreatContributionsAsRegularOptions: ManagedSimpleChoiceItem[] = [
      {
        value: false,
        label: 'Treat as one-off contributions'
      },
      {
        value: true,
        label: 'Treat as regular contributions',
      },
    ]
  
    const isAutoEnrolmentOptions: ManagedSimpleChoiceItem[] = [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ]
  
    const isNumeric = (value: string) => {
      if (isNilOrEmptyString(value)) {
        return true
      }
      return isNaN(parseFloat(value)) ? 'Must be a number': true
    }
  
    const isInRange = (value: string) => {
      if (isNilOrEmptyString(value)) {
        return true
      }
      const numValue = parseFloat(value)
      const result = numValue <= 100 && numValue >= 0
      return result ? true : 'Must be between 0 and 100'
    }
  
    const initialStatusOptions: ManagedSimpleChoiceItem[] = [
      {
        value: GroupSchemeStatus.ONBOARDING,
        label: 'Onboarding (Silent member enrolment)',
      },
      {
        value: GroupSchemeStatus.ACTIVE,
        label: 'Active (Fully Live)',
      },
      {
        value: GroupSchemeStatus.INACTIVE,
        label: `Inactive (Employer not notified)`,
      },
    ]

    return (
      <ModalEditScreen
        formTitle='Add New Group Scheme'
        onDismiss={() => dispatch(setShowAdminAddGroupSchemeVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new group scheme?'}
        error={error}
        errorTryAgain={
          addGroupSchemeError ? handleSubmit(onSubmit)
          : groupPortfoliosError ? refetchGroupPortfolios
          : organizationsError ? refetchGroupOrganizations
          : brandsError ? refetchBrands
          : undefined
        }
        errorCancel={addGroupSchemeError ? addGroupSchemeReset : close}
        isLoading={isLoading}
        loadingMessage={['Saving group scheme...']}
        buttonTitle={'Create Group Scheme'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <Subheading>{'Basic Details'}</Subheading>
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Scheme name visible to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
          }}/>
        <ManagedAutoCompleteInput
          name={'organizationId'}
          formObj={formObj}
          label={'Group Organization'}
          modalTitle={'Select Group Organization'}
          placeholder={'Select an owning organization for the new group scheme'}
          searchPlaceholder={'Search for an organization'}
          dataSet={organizationOptions}
          required={true}
        />
        <Subheading>{'Scheme Identification'}</Subheading>
        <ManagedTextInput
          ref={tprLetterCodeRef}
          name={'tprLetterCode'}
          formObj={formObj}
          label={'TPR Letter Code'}
          placeholder={'The Pensions Regulator Letter Code'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => dutiesStartDateRef.current?.focus()}
          rules={{
            required: false,
            minLength: 10,
            maxLength: 10,
          }}/>
        <Paragraph>{'Auto Enrolment Duties Start Date'}</Paragraph>
        <ManagedDateInput
          ref={dutiesStartDateRef}
          name={'dutiesStartDate'}
          formObj={formObj}
          blurOnSubmit={true}
          required={false}
          mustBeInPast={true}
        />
        <Subheading>{'Contribution Configuration'}</Subheading>
        <ManagedTextInput
          ref={defaultEmployerContributionPercentageRef}
          name={'defaultEmployerContributionPercentage'}
          formObj={formObj}
          label={'Default Employer Contribution Percentage'}
          blurOnSubmit={false}
          returnKeyType={'done'}
          keyboardType={'numeric'}
          submitHandler={() => defaultEmployeeContributionPercentageRef.current?.focus()}
          rules={{
            required: false,
            validate: {
              isNumeric,
              isInRange,
            }
          }}
        />
        <ManagedTextInput
          ref={defaultEmployeeContributionPercentageRef}
          name={'defaultEmployeeContributionPercentage'}
          formObj={formObj}
          label={'Default Employee Contribution Percentage'}
          blurOnSubmit={false}
          returnKeyType={'done'}
          keyboardType={'numeric'}
          rules={{
            required: false,
            validate: {
              isNumeric,
              isInRange,
            }
          }}
        />
        <ManagedAutoCompleteInput
          name={'salarySacrificeMode'}
          formObj={formObj}
          label={'Salary Sacrifice Mode'}
          selectOnlyMode={true}
          required={true}
          dataSet={enumToAutocompleteOptions(GroupSchemeSalarySacrificeMode)}
        />
        <Paragraph>{'Is an Auto Enrolment Scheme?'}</Paragraph>
        <ManagedSimpleChoiceInput
          name={'isAutoEnrolment'}
          formObj={formObj}
          options={isAutoEnrolmentOptions}
          required={true}
        />
        <Subheading>{'Payroll Configuration'}</Subheading>
        <ManagedAutoCompleteInput
          name={'defaultPayrollFrequency'}
          formObj={formObj}
          label={'Default Payroll Frequency'}
          selectOnlyMode={true}
          required={false}
          dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
        />
        <ManagedAutoCompleteInput
          name={'defaultContributionFrequency'}
          formObj={formObj}
          label={'Default Contribution Frequency'}
          selectOnlyMode={true}
          required={true}
          dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
        />
        <Subheading>{'Investment Configuration'}</Subheading>
        <ManagedAutoCompleteInput
          name={'defaultGroupPortfolioId'}
          formObj={formObj}
          label={'Default Group Portfolio'}
          modalTitle={'Select Default Group Portfolio'}
          placeholder={'Select an default portfolio for new accounts'}
          searchPlaceholder={'Search for a group portfolio'}
          dataSet={groupPortfolioOptions}
          required={true}
        />
        <Subheading>{'Member Enrolment Configuration'}</Subheading>
        <ManagedTextInput
          ref={defaultEnrolmentNameRef}
          name={'defaultEnrolmentName'}
          formObj={formObj}
          label={'Default Account Name'}
          placeholder={'Default name for new accounts visible to end users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 3,
            maxLength: 30,
        }}/>
        <Paragraph>{'Allowed request types from members'}</Paragraph>
        <ManagedMultipleChoiceInput
          formObj={formObj}
          name={'enabledRequestTypes'}
          options={enumToAutocompleteOptions(GroupSchemeRequestType, mandatedRequestTypes, availableRequestTypes)}
        />
        <Paragraph>{'Forecasting Default Contribution Treatment'}</Paragraph>
        <ManagedSimpleChoiceInput
          name={'defaultTreatContributionsAsRegular'}
          formObj={formObj}
          options={defaultTreatContributionsAsRegularOptions}
          required={true}
        />
        <Subheading>{'Previous Scheme Configuration'}</Subheading>
        <Paragraph>{'Previous provider brand'}</Paragraph>
        <ManagedAutoCompleteInput
          name={'previousPensionProviderBrandKey'}
          placeholder={'Tap to search...'}
          textCaptureFieldName={'previousPensionProviderBrandName'}
          allowFreeText={false}
          formObj={formObj}
          modalTitle={'Find Pension Brand'}
          required={false}
          dataSet={brandOptions}
        />
        <Subheading>{'Initial Status'}</Subheading>
        <Paragraph>{'Initial scheme status'}</Paragraph>
        <ManagedSimpleChoiceInput
          name={'status'}
          formObj={formObj}
          options={initialStatusOptions}
          required={true}
        />
      </ModalEditScreen>
    )
  }
  
  