import { concat, indexOf, last, take } from 'lodash'
import React from 'react'
import { View } from 'react-native'
import { RetirementAssetDto, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskRow, TaskRowProps } from './TaskRow'

export type TaskListProps = {
  asset: RetirementAssetDto
  allTasks: RetirementAssetTransferStepIdentifier[]
  nextTask: RetirementAssetTransferStepIdentifier
  nextTaskAction: any
  pendingSteps?: RetirementAssetTransferStepIdentifier[]
  allComplete?: boolean
  isRetransfer: boolean
}

export const TaskList = (props: TaskListProps) => {
  const { asset, allTasks, nextTask, nextTaskAction, pendingSteps, allComplete, isRetransfer } = props

  const nextTaskIndex = indexOf(allTasks, nextTask)

  const getStepTitle = (
    identifier: RetirementAssetTransferStepIdentifier,
    asset: RetirementAssetDto,
  ): string => {
    switch (identifier) {
      case RetirementAssetTransferStepIdentifier.PROVIDER:
        return isRetransfer ? 'Review Pension Provider' : 'Identify Pension Provider'
      case RetirementAssetTransferStepIdentifier.REFERENCE:
        return isRetransfer ? 'Review Pension Reference' : 'Find Pension Reference'
      case RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS:
        return isRetransfer ? 'Review Personal Details' : 'Confirm Personal Details'
      case RetirementAssetTransferStepIdentifier.TRACING:
        return asset?.assetType === RetirementAssetType.WORKPLACE ? 'Confirm Employment Details' : 'Confirm Pension Details'
      default:
        return `Unknown Task`
    }
  }


  const newTasks: TaskRowProps[] = allTasks.map((task, idx) => {
    const isPending = pendingSteps ? pendingSteps.includes(task) : false
    return {
      identifier: task,
      title: getStepTitle(task, asset),
      isLast: false,
      isNext: idx === nextTaskIndex,
      isPending, 
      isComplete: allComplete || idx < nextTaskIndex, 
      nextTaskAction: idx === nextTaskIndex ? nextTaskAction : undefined,
    }
  })

  //Set isLast on the final item
  const tasks: TaskRowProps[] = newTasks.length
  ? concat(take(newTasks, newTasks.length - 1), [{
      ...last(newTasks),
      isLast: true
    }])
  : newTasks

    return (
      <View>
        {
          tasks.map((taskProps, idx) => {
            return <TaskRow
              key={idx}
              {...taskProps}
            />
          })
        }
      </View>
    )    
}
