import chroma from "chroma-js"
import { Logger } from 'lib/logger'
export const hexColorWithOpactity = (hex, opacity) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`
  }
  Logger.error(`Bad hex value!`)
  return `rgba(0,0,0,0)`
}

export const middleColor = (firstColor: string, secondColor: string) => {
  const threeColors = generateColor(firstColor, secondColor, 3)
  return threeColors[1]
}

export const generateColor = (firstColor: string, secondColor: string, quantity: number) => {
  //firstColor: first color starting point
  //secondColor: ending color point
  //colorSet: Number of colors to generate
  if (quantity === 0) {
    return []
  }
  if (quantity === 1) {
    return [middleColor(firstColor, secondColor)]
  }
  return (
    chroma.scale([firstColor, secondColor])
      .mode('lch').colors(quantity)
  )
}
// '#fafa6e'
// '#efef0a'