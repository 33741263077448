import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { differenceInYears } from 'date-fns'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getModelGenerationMessages } from 'lib/loadingHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { concat, last } from 'lodash'
import { useGuidanceContext } from 'providers'
import React from 'react'
import { useGetRetirementProfileQuery } from 'store/apiSlice'
import { Typography } from 'styles'

const MAX_AGE_DIFFERENCE_FOR_CLOSE = 5
const MAX_SURPLUS_FOR_SMALL = 30000

export const OnboardingPhasePlanHeroScreen = ({ route, navigation }) => {

  const { currentModel, currentModelIsLoading, currentModelIsFetching, currentModelError, refetchCurrentViableAge } = useGuidanceContext()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching, refetch: refetchProfile } = useGetRetirementProfileQuery()

  const isLoading = !currentModel || currentModelIsLoading || currentModelIsFetching || rpIsLoading
  const error = currentModelError || rpError

  const viableRetirementAge = currentModel?.output?.viableRetirementAge
  const targetRetirementAge = retirementProfile?.targetRetirementAge
  const targetRetirementDate = currentModel?.basis?.targetRetirementAge ? new Date(currentModel.basis.targetRetirementDate) : new Date()
  const modelRetirementDate = currentModel?.timeline?.modelRetirementDate ? new Date(currentModel.timeline.modelRetirementDate) : new Date()

  const targetRetirementAgeIsMet = currentModel?.output?.targetRetirementAgeIsMet

  const getSurplusAmount = () => {
    if (!currentModel || !targetRetirementAgeIsMet) {
      return 0
    }
    const lastTerm = last(currentModel.terms)
    return lastTerm.startValue
  }

  const surplusAmount = getSurplusAmount()
  const surplusIsSmall = surplusAmount <= MAX_SURPLUS_FOR_SMALL

  const difference = currentModel && targetRetirementDate ? differenceInYears(modelRetirementDate, targetRetirementDate) : 0
  const differenceIsClose = difference <= MAX_AGE_DIFFERENCE_FOR_CLOSE

  const requiredPersonalMonthlyContributions = currentModel?.output?.requiredPersonalMonthlyContributions
  
  let carouselScreens: ProcessHeroCarouselData[] = [
    {
      illustrationFilename: 'gamified_approach.png',
      headline: `Your Achievable Retirement Age`,
      subHeading:
        <Subheading>
          {`Based on everything you've told us, Jarvis predicts you could retire at `}
          <Subheading style={Typography.fontWeight.bold}>{`age ${viableRetirementAge}`}</Subheading>
        </Subheading>,
      subHeadingInfo: <NamedInformationButton name={NamedInformation.ACHIEVABLE_RETIREMENT_AGE} buttonTitle={'What does this mean?'} />
    },
  ]

  //Screens for targetRetirementAgeIsMet or not
  if (targetRetirementAgeIsMet) {
    carouselScreens = concat(carouselScreens, [
      {
        illustrationFilename: 'good_to_go.png',
        headline: `You're already on the right path!`,
        subHeading: `This means you are on track to achieve financial freedom at your target age of ${retirementProfile?.targetRetirementAge}`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.TARGET_RETIREMENT_AGE} buttonTitle={'About Target Age'} />
      },
      {
        illustrationFilename: 'spare_cash.png',
        headline: surplusIsSmall ? `You may even have a little spare!` : `You may even have some left over!`,
        subHeading: `Based on your life expectancy, you could have around ${formatCurrencyAmount(surplusAmount)} in residual assets`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.SURPLUS_CAPITAL} buttonTitle={'Tell me more'} />
      },
      {
        illustrationFilename: 'relax_for_now.png',
        headline: `So, what to do next?`,
        subHeading: `Jarvis can help you understand the impact of saving more or retiring at a different age`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.GOALS} buttonTitle={'About Jarvis Goals'} />
      },
      {
        illustrationFilename: 'choose_beneficiaries.png',
        headline: `The choice is yours!`,
        subHeading: `You may want to build up an inheritance pot for your loved ones, or retire even earlier`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.GOALS} buttonTitle={'About Jarvis Goals'} />
      },
    ])
  } else {
    carouselScreens = concat(carouselScreens, [
      {
        illustrationFilename: 'clock.png',
        headline: differenceIsClose
          ? `You're close, but not quite there`
          : `You're a little way off your target`,
        subHeading:
          <Subheading>
            {`This puts you about `}
            <Subheading style={Typography.fontWeight.bold}>{`${difference} year${difference === 1 ? '' : 's'}`}</Subheading>
            {` away from your target age of `}
            <Subheading style={Typography.fontWeight.bold}>{targetRetirementAge}</Subheading>
          </Subheading>,
        // subHeading: `This puts you about ${difference} year${difference === 1 ? '' : 's'} away from your target of age ${targetRetirementAge}`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.TARGET_RETIREMENT_AGE} buttonTitle={'About Target Age'} />
      },
      {
        illustrationFilename: 'path_to_freedom.png',
        headline: `So, how to close the gap?`,
        subHeading: `Jarvis can help you achieve your goals by defining your own path to freedom`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.GOALS} buttonTitle={'About Jarvis Goals'} />
      },
    ])
    //Add contributions screen if we have the data
    if (requiredPersonalMonthlyContributions) {
      carouselScreens = concat(carouselScreens, [
        {
          illustrationFilename: 'regular_contributions.png',
          headline: `To give just one example...`,
          subHeading:
            <Subheading>
              {`Making personal monthly contributions of `}
              <Subheading style={Typography.fontWeight.bold}>{formatCurrencyAmount(requiredPersonalMonthlyContributions)}</Subheading>
              {` would get you on track to retire at `}
              <Subheading style={Typography.fontWeight.bold}>{`age ${targetRetirementAge}`}</Subheading>
            </Subheading>,
        },
      ])
    }
    //Rethink screen
    carouselScreens = concat(carouselScreens, [
      {
        illustrationFilename: 'set_your_budget.png',
        headline: `Need to rethink?`,
        subHeading: `Refining your retirement budget or target age immediately changes your forecast`,
        subHeadingInfo: <NamedInformationButton name={NamedInformation.REFINING_TARGET} buttonTitle={'How do I refine?'} />
      },
    ])
  }

  //Final screens
  carouselScreens = concat(carouselScreens, [
    {
      illustrationFilename: 'meditating_man.png',
      headline: `You're ready to take control`,
      subHeading: `When ready, use the other checklists to get your retirement on track`
    },
  ])

  return (
    <ProcessHeroScreen
      isLoading={isLoading}
      loadingMessage={getModelGenerationMessages()}
      error={error}
      errorTryAgain={rpError ? refetchProfile : refetchCurrentViableAge}
      errorCancel={goToDashboard}
      carouselScreens={carouselScreens}
      onFinish={goToDashboard}
      finishButtonLabel={'Return to my Dashboard'}
    />
  )
}
