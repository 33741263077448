import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { sumBy } from 'lodash'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppDispatch } from 'store/hooks'
import { setAddRetirementIncomeClient, setAddSpouseVisible, setAddStatePensionClient, setEditRetirementIncome, setEditStatePensionClient, setOnIncomeSpouseView } from 'store/uxSlice'
import { Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { IncomeTabScreenName, RetirementIncomesList } from '../Components/RetirementIncomesList'

export const RetirementIncomesScreen = () => {
  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const isLoading = meIsLoading || spouseIsLoading || incomesIsLoading
  const error: any = incomesError || meError

  const handleIncomeListNavigation = (data) => {
    const index = data?.state?.index
    const routeNames = data?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    dispatch(setOnIncomeSpouseView(currentName === IncomeTabScreenName.SPOUSE))
  }

  const statePensionTotal = (me?.statePensionAmount || 0) + (spouse?.statePensionAmount || 0)
  const overallAmount = sumBy(incomes, 'annualIncomeAmount') + statePensionTotal
  
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchSpouse} /> :
        <OnboardingGuard
          explanation={'Your Retirement Incomes screen will help you keep track of the ongoing incomes you will receive in retirement'}
          subExplanation={`You can record incomes once you have defined your retirement lifestyle and budget.`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
            OnboardingStateStepIdentifier.INCOMES,
          ]}
        >
          
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.pageContainer,
            ]} >
            <RefreshableScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
              refreshFunctions={[
                refetchIncomes,
              ]}
            >
              <Subheading style={Typography.defined.sectionListHeader}>{'Incomes Annual Total'} </Subheading>
              <View style={{ paddingTop: Sizing.x10 }}>
                <Paragraph
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={{
                    fontFamily: 'LabGrotesque-Medium',
                    fontWeight: '400',
                    lineHeight: scaleNormalizer(40),
                    fontSize: scaleNormalizer(40),
                    textAlign: 'center',
                  }}>
                    {formatCurrencyAmount(overallAmount)}
                </Paragraph>
                <Paragraph>{`State Pensions: ${formatCurrencyAmount(statePensionTotal, 0)}`}</Paragraph>
                <Paragraph>{incomes?.length
                  ? `You have recorded ${incomes?.length} other income${incomes?.length > 1 ? `s` : ``} that you will be receiving in retirement.`
                  : `You haven't yet recorded any other retirement incomes `
                }</Paragraph>
                <View style={{ paddingVertical: Sizing.x10 }}>
                </View>
              </View>
              <ContentDivider />

              <Subheading style={Typography.defined.sectionListHeader}>{'Retirement Incomes'} </Subheading>
              <Paragraph>{`This table shows all the retirement incomes you have recorded, which contribute to Jarvis forecasting.`}</Paragraph>
              <View style={{ paddingTop: Sizing.x10 }}>
              <RetirementIncomesList
                  addIncomeStateFunction={(client: ClientBaseDto) => dispatch(setAddRetirementIncomeClient(client))}
                  editIncomeStateFunction={(income: RetirementIncomeDto) => dispatch(setEditRetirementIncome(income))}
                  addStatePensionStateFunction={(client: ClientBaseDto) => dispatch(setAddStatePensionClient(client))}
                  editStatePensionStateFunction={(client: ClientBaseDto) => dispatch(setEditStatePensionClient(client))}
                  addSpouseStateFunction={(visible: boolean) => dispatch(setAddSpouseVisible(visible))}
                  onNavigateFunction={handleIncomeListNavigation}
                />
              </View>
              <View style={{ height: Sizing.x30 }}></View>
              <ContentDivider />
            </RefreshableScrollView>
          </View>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})