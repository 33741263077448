import { Address, BaseDatedDto, ContributionSource } from "./base.dto"
import { PensionProviderDto } from "./pension-provider.dto"
import { AccountTransactionStatus, PensionTransferStatus } from "./account.dto"

export enum RetirementAssetType {
  PERSONAL = 'personal',
  WORKPLACE = 'workplace',
  ISA = 'isa',
  INVESTMENT = 'investment',
  BUSINESS_SALE = 'business_sale',
  OTHER = 'other',
}

export enum RetirementAssetTransferStatus {
  TRACING = 'tracing', //Used to indicate asset is in tracing, with the intention of flowing into transfer execution
  TRACING_FAILED = 'tracing_failed', //Used to indicate that tracing failed before it reached execution
  INITIATED = 'initiated', //Only used after creating transfer in SECCL and awaiting first webhook message
  ACTIVE = 'active', //Reflects SECCL "Pending" status
  WITH_SUPPORT = 'with_support', //TBC - used by client to hand it over to support to investigate and provider help
  COMPLETED = 'completed', //Reflects SECCL "Completed" status with transferStatus "Completed" - i.e. success
  REJECTED = 'rejected', //Reflects SECCL "Completed" status with transferStatus "Rejected" - i.e. failed
  MODIFIED = 'modified', //Status used when client has modified ready for another attempt
  ABANDONED = 'abandoned', //Used by the client to indicate they have "given up" - needs a new transfer to start again
}

export enum RetirementAssetTracingStatus {
  INITIATED = 'initiated', //Tracing request has been submitted to Raindrop
  INVALID = 'invalid', //Tracing has been rejected by Raindrop and deemed invalid
  ACTIVE = 'active', //Tracing is in progress
  NOT_FOUND = 'not_found', //Tracing complete and not found
  FOUND_UNTRANSFERABLE = 'found_untransferable', //Tracing complete, found, but cannot be transferred
  FOUND_DELAY = 'found_delay', //Tracing complete, found, but in "delay" to allow client time to cancel if they want
  FOUND_NEEDS_DECISION = 'found_needs_decision', //Tracing complete, found, but needs decision from client 
  ABANDONED = 'abandoned', //Tracing complete, found, but abandoned by the client
  COMPLETED = 'completed', //Tracing complete, found, moved to transfer execution
}

export enum RetirementAssetUntransferableReason {
  NOT_PENSION = 'not_pension', //Asset is not of a pension type
  MISSING_DATA = 'missing_data', //Asset needs additional data adding for either transfer execution OR tracing
  MISSING_EXECUTION_DATA = 'missing_execution_data', //Asset needs additional execution data adding (because already traced)
  CLIENT_DECLARED = 'client_declared', //Client has declared asset as non-transferable
  WORKPLACE_CONTRIBUTIONS = 'workplace_contributions', //Client has declared type as workplace and monthly contributions set
  PUBLIC_SECTOR = 'public_sector', //Employer is public sector
  CLIENT_NAME_MISMATCH = 'client_name_mismatch', //Client has declared their name does not match that with the ceding provider
  IN_TRACING = 'in_tracing', //A tracing attempt is currently in progress
  TRANSFER_ACTIVE = 'transfer_active', //A transfer attempt is currently in progress
  TRANSFER_COMPLETE = 'transfer_complete', //A transfer attempt has been successfully completed
  //UNKNOWN used for optimistic patch in apiSlice so that we don't temporarily think it is transferable
  UNKNOWN = 'unknown',
}

export enum RaindropPensionFindingStatus {
  INVALID = 'INVALID',
  REQUEST_FINDING = 'REQUEST_FINDING',
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  REDACTED = 'REDACTED',
}

export enum RaindropPensionTransferStatus {
  TRANSFER_REQUESTED = 'TRANSFER_REQUESTED',
  TRANSFER_COMPLETED = 'TRANSFER_COMPLETED',
  TRANSFER_CANCELLED = 'TRANSFER_CANCELLED',
}

export enum RaindropPensionFindingTransferEligibility {
  TRANSFER_ELIGIBLE = 'TRANSFER_ELIGIBLE',
  TRANSFER_INELIGIBLE = 'TRANSFER_INELIGIBLE',
  TRANSFER_MAYBE_ELIGIBLE_SOFT = 'TRANSFER_MAYBE_ELIGIBLE_SOFT',
  TRANSFER_MAYBE_ELIGIBLE_HARD = 'TRANSFER_MAYBE_ELIGIBLE_HARD',
}

export enum RetirementAssetTransferRejectionReason {
  ADDRESS_MISMATCH = 'address_mismatch',
  DOB_MISMATCH = 'dob_mismatch',
  NINO_MISMATCH = 'nino_mismatch',
  POLICY_NO_MISMATCH = 'policy_no_mismatch',
  ALREADY_TRANSFERRED = 'already_transferred',
  WITH_CLIENT = 'with_client',
}

export interface RetirementAssetDto extends BaseDatedDto {
  clientId: string
  name: string
  assetType: RetirementAssetType
  isTransferable?: boolean
  pensionProviderId?: string
  externalProviderReference?: string
  currentValue: number
  valuationDate: string
  monthlyContributionAmount?: number
  contributionSource?: ContributionSource
  currentSecclLinkId?: string
  transferTermsAgreed: boolean
  transferAddress?: Address
  transferStatus?: RetirementAssetTransferStatus
  transferAttempts?: RetirementAssetTransferAttempt[]
  completedTransferAcknowledged?: boolean
  transferProgressConfirmed?: boolean
  employerId?: string
  employerName?: string
  pensionProviderBrandKey?: string
  pensionProviderBrandName?: string
  useClientPreviousNameForTransfer?: boolean
  employerIsPublicSector?: boolean
  untransferableReason?: RetirementAssetUntransferableReason
  tracingAttempts?: RetirementAssetTracingAttempt[]
  employerStartDate?: string
  employerEndDate?: string
  employerShortDuration?: boolean
  isActiveEmployer?: boolean
  raindropPensionId?: string
  tracingStatus?: RetirementAssetTracingStatus
  transferDelayExecutionDate?: string
  tracingRequested?: boolean
  traceCompletionDate?: string
  transferInitiationDate?: string
  transferCompletionDate?: string
  transferNotes?: string
  transferRejectionReason?: RetirementAssetTransferRejectionReason
  transferInformationLastUserId?: string
}

export interface RetirementAssetTransferDto extends RetirementAssetDto {
  clientFirstName: string
  clientSurname: string
  clientEmail: string
  clientBirthDate: string
  clientNationalInsuranceNo: string
  clientCurrentAddress: Address
  pensionProvider?: PensionProviderDto
  transferOpenDays?: number
  transferStaleDays?: number
}

export interface RetirementAssetPolicyDetailsDto {
  filename: string
  url: string
}

export interface RetirementAssetTransferAttempt extends BaseDatedDto {
  id: string,
  retirementAssetId: string,
  transactionDate: string,
  previousProvider: string,
  previousProviderRef: string,
  transferValue: number,
  secclTransactionId: string,
  status: AccountTransactionStatus,
  transferStatus: PensionTransferStatus,
  transferSystem: string,
  transferAddress?: Address
  previousProviderName: string,
  statusChanges: RetirementAssetTransferAttemptChange[],
}

export interface RetirementAssetTracingAttempt extends BaseDatedDto {
  id: string,
  retirementAssetId: string,
  pensionIdentifier: string,
  status: RaindropPensionFindingStatus,
  employerName?: string,
  requestPartnerNotToTransfer?: boolean,
  pensionPolicyReadByUser?: boolean,
  provider?: string,
  scheme?: string,
  isOnOrigo?: boolean,
  origoSchemeId?: string,
  pensionPlanReferenceNumber?: string,
  foundValue?: number,
  hasExitFees?: boolean,
  isDefinedBenefits?: boolean,
  hasOtherSafeguardedBenefits?: boolean,
  hasOtherProtectionsOrRestrictions?: boolean,
  isInDrawdown?: boolean,
  isActive?: boolean,
  transferEligibility?: RaindropPensionFindingTransferEligibility,
  transferStatus?: RaindropPensionTransferStatus,
  transferCompletedDate?: string,
  previousAddress?: Address,
  foundDate?: string,
  foundNotificationDate?: string,
}

export interface RetirementAssetTransferAttemptChange {
  transferStatus?: PensionTransferStatus
  status?: AccountTransactionStatus
  statusDate: string
  description: string
}

export interface CreateRetirementAssetDto {
  type: string
  clientId: string
  name: string
  assetType: RetirementAssetType
  isTransferable?: boolean
  pensionProviderId?: string
  externalProviderReference?: string
  currentValue: number
  monthlyContributionAmount?: number
  contributionSource?: ContributionSource
  transferAddress?: Address
  employerId?: string
  employerName?: string
  pensionProviderBrandKey?: string
  pensionProviderBrandName?: string
  useClientPreviousNameForTransfer?: boolean
  employerIsPublicSector?: boolean
  employerStartDate?: string
  employerEndDate?: string
  employerShortDuration?: boolean
  isActiveEmployer?: boolean
  tracingRequested?: boolean
}

export interface UpdateRetirementAssetDto {
  id: string
  name?: string
  assetType?: RetirementAssetType
  isTransferable?: boolean
  pensionProviderId?: string
  externalProviderReference?: string
  currentValue?: number
  monthlyContributionAmount?: number
  contributionSource?: ContributionSource
  transferAddress?: Address
  transferStatus?: RetirementAssetTransferStatus
  completedTransferAcknowledged?: boolean
  employerId?: string
  employerName?: string
  pensionProviderBrandKey?: string
  pensionProviderBrandName?: string
  useClientPreviousNameForTransfer?: boolean
  employerIsPublicSector?: boolean
  employerStartDate?: string
  employerEndDate?: string
  employerShortDuration?: boolean
  isActiveEmployer?: boolean
  tracingRequested?: boolean
}

export interface UpdateRetirementAssetTransferInformationDto {
  id: string
  transferNotes?: string
  transferRejectionReason?: RetirementAssetTransferRejectionReason
}

export interface TransferRetirementAssetDto {
  id: string
  transferTermsAgreed: boolean
}

export interface BulkTransferRetirementAssetDto {
  transferTermsAgreed: boolean
  assetIdsToTransfer?: string[]
}

export interface RetirementAssetSetInterestDto {
  isTransferable: boolean
  assetIds: string[]
}

export interface RetirementAssetTransferFilterDto {
  search?: string
  transferStatus?: RetirementAssetTransferSearchTransferStatus
  assetType?: RetirementAssetTransferSearchType
  pensionProviderId?: string
  currentSecclLinkId?: string
  minTransferOpenDays?: number
  minTransferStaleDays?: number
}

export enum RetirementAssetTransferSearchTransferStatus {
  ANY = 'any',
  TRACING = 'tracing', //Used to indicate asset is in tracing, with the intention of flowing into transfer execution
  TRACING_FAILED = 'tracing_failed', //Used to indicate that tracing failed before it reached execution
  INITIATED = 'initiated', //Only used after creating transfer in SECCL and awaiting first webhook message
  ACTIVE = 'active', //Reflects SECCL "Pending" status
  WITH_SUPPORT = 'with_support', //TBC - used by client to hand it over to support to investigate and provider help
  COMPLETED = 'completed', //Reflects SECCL "Completed" status with transferStatus "Completed" - i.e. success
  REJECTED = 'rejected', //Reflects SECCL "Completed" status with transferStatus "Rejected" - i.e. failed
  MODIFIED = 'modified', //Status used when client has modified ready for another attempt
  ABANDONED = 'abandoned', //Used by the client to indicate they have "given up" - needs a new transfer to start again
}

export enum RetirementAssetTransferSearchType {
  ANY = 'any',
  PERSONAL = 'personal',
  WORKPLACE = 'workplace',
}